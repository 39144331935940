import { Lesson } from "../../../lessons"
import { networkCategory, securityCategory } from "../../category"

const cNwIntro = `
## <h1><u><strong>Was ist ein Netzwerk</strong></u></h1>

Netzwerke gibt es in allen erdenklichen Größen. Sie reichen von einfachen Netzwerken, bestehend aus zwei Computern, bis zu Netzwerken, die aus Millionen von Geräten bestehen.
Dabei ist es Egal ob es eine Physische Verbindung, durch ein Kabel, oder eine Logische Verbindung, über Funk, handelt.

Einfache Heimnetzwerke ermöglichen das Teilen von Ressourcen, wie z. B. Drucker, Dokumente, Bilder und Musik, zwischen mehreren lokalen Computern.

Heimnetzwerke und kleine Büronetzwerke werden oftmals von Einzelpersonen eingerichtet, die von zu Hause aus oder in einem außerhalb gelegenen Büro arbeiten und auf den Zugriff auf ein Unternehmensnetzwerk oder andere zentralisierte Ressourcen angewiesen sind. Außerdem nutzen viele selbstständige Unternehmer Heimnetzwerke und kleine Büronetzwerke, um Produkte anzubieten und zu verkaufen, Lieferungen zu bestellen und mit Kunden zu kommunizieren.

In Unternehmen und großen Organisationen können Netzwerke in noch größerem Umfang genutzt werden, um Konsolidierung, Speicherung und Zugriff auf Informationen auf dem Netzwerkserver zu ermöglichen. Netzwerke ermöglichen außerdem schnelle Kommunikation wie z. B. E-Mail, Chat und Zusammenarbeit unter Mitarbeitern. Neben den internen Vorteilen nutzen viele Unternehmen ihre Netzwerke dazu, den Kunden Produkte und Dienstleistungen durch die Verbindung mit dem Internet anzubieten.

Das Internet ist das größte existierende Netzwerk. Tatsächlich steht der Begriff „Internet“ für ein „Netzwerk aus Netzwerken“. Das Internet ist wörtlich eine Sammlung von miteinander verbundenen privaten und öffentlichen Netzwerken, wie oben beschrieben.

### <h2><u><strong>Verbindungsmöglichkeiten</strong></u></h2>

Normalerweise wird eine Verbindung zu einem Internet-Service-Provider (ISP) – also einem Internetdienstanbieter –, um auf das Internet zugreifen zu können. Verbindungsmöglichkeiten sind stark abhängig vom ISP und der geografischen Lage.

- <strong>Kabel</strong> – Das Internetdatensignal wird vom gleichen Koaxialkabel übermittelt, welches auch das TV-Signal überträgt und üblicherweise von Kabel-TV-Anbietern bereitgestellt wird. Es garantiert eine hohe Bandbreite und eine permanente Verbindung zum Internet.
- <strong>DSL</strong> – Digital Subscriber Lines garantieren eine hohe Bandbreite und eine permanente Verbindung zum Internet. DSL verwendet eine Telefonleitung. Im Allgemeinen werden Haushalte über asymmetrisches DSL (ADSL) verbunden, was bedeutet, dass die Downloadgeschwindigkeit höher als die Uploadgeschwindigkeit ist.
- <strong>Mobilfunk</strong>-Internetverbindungen nutzen ein Mobilfunknetzwerk zur Verbindung. Wo auch immer man ein Mobilfunksignal empfängt, bekommt man ebenso Zugang zum mobilen Internet. Die Leistung wird beeinträchtigt durch die Kapazität des Handys sowie durch den Funkmast, mit dem es verbunden ist.
- <strong>Satellit</strong> Die Verfügbarkeit von Internetzugriff via Satellit bringt Vorteile für Gebiete, die sonst überhaupt keine Internetverbindung hätten. Satellitenantennen benötigen eine hindernisfreie Visierlinie zum Satelliten.

### <h2><u><strong>Netzwerktypen</strong></u></h2>

Netzwerkinfrastrukturen können sich in folgenden Punkten stark unterscheiden:

- Größe des abgedeckten Bereichs
- Anzahl der verbundenen Benutzer
- Anzahl und Art der verfügbaren Services
- Verantwortungsbereich

| Bezeichnung | Ausgeschrieben                        | Beschreibung                                                                                                                                    |
| ----------- | ------------------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------- |
| PAN         | **P**ersonal **A**rea **N**etwork           | Müssen in unmitelbarer nähe sien, zum Beispiel Bluetoth.                                                                                        |
| (W)LAN      | (**W**ireless) **L**ocal **A**rea **N**etwork | Eine Gruppe von Computern und dazugehörige Geräte, die eine gemeinsame Physischen oder Logische Verbindung Leitung Nutzen.                      |
| MAN         | **M**etropolitan **A**rea **N**etwork       | Mehrere Netzwerke, die geografische beieinander sind, zu einem größeren Gesamt-Netzwerk zusammenschließt, Städte zum Beispiel.                  |
| WAN         | **W**ide **A**rea **N**etwork               | Jedes große Netzwerk, das sich über ein großes geografisches Gebiet erstreckt, zum Beispiel ein Land.                                           |
| GAN         | **G**lobal**A**rea **N**etwork              | Ein Netzwerk was sich über die ganze Welt erstreckt.                                                                                            |
| VPN         | **V**irtual **P**rivate **N**etwork         | Anders als beim LAN sind die verschiedenen Endgeräte bei einem VPN nicht direkt physisch miteinander oder mit einem zentralen Router verbunden. |
| Vlan        | **V**irtuall **L**ocal **A**rea **N**etwork   | Ein logisches Teilnetz Segment/Netzwerk innerhalb eines gesamten physischen Netzwerks.                                                          |

#### <u><strong>Peer-to-Peer-Network</strong></u>

Bei dem Peer to Peer Network Model gibt es zwischen den Unterschidlichen Clients kene Hierachie, die Ressourcen sind für alle Nutzbar.

#### <u><strong>Server-Client-Network</strong></u>

Es gibt einen Zentralen Computer(Server), dieser stellt Dienste zurverfügung und ist denn anderen Clients übergeordnet.
Um Dienste und Daten abfragen zu können muss an denn Server ein Request geschickt werden, dieser wird dann ausgewährtet und ein Antwoert gesendet.

`
const cNwTopology = `
## <h1><u><strong>Topology</strong></u></h1>

Unter einer Topology verstehst du die Art und Weise, wie Geräte miteinander verbunden sind. Einfach gesagt handelt es sich bei der Netzwerktopologie um eine physikalische Verbindung von Geräten in einem Netzwerk.
Die vier <a href="primary-topology">primären Topology</a> sind:

- <a href="#bus-topology">Bus Topology</a>
- <a href="#mesh-topology">Mesh Topology</a>
- <a href="#ring-topology">Ring Topology</a>
- <a href="#star-topology">Star Topology</a>

Andere wären:

- <a href="point-to-point">Point-To-Point Topology</a>
- <a href="point-to-multipoint">Point-To-Multipoint</a>
- <a href="line-topology">Line-Topology</a>
- <a href="tree-topology">Tree-Topology</a>
- <a href="fully-connected-topology">Fully-Connected-Topology</a>

### <h2><u><strong>Primary Topology</strong></u></h2>

#### <h3><u><strong>Bus Topology</strong></u></h3>

Alle Host sind über eine einzige Leitung Verbunden, der Bus dient nur als passives Übertragungsmedium und die Regelung des Ablaufes geschieht über Datenprotokollen.
Sie lässt sich einfach installieren und benötigt nur kurze Leitungen, allerdings ist die Reichweite sehr limitiert und ein Kabelbruch reicht um das ganze Netzwerk lahm zu legen.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/3/32/Netzwerktopologie_Bus.png" alt="Bus"/>

#### <h3><u><strong>Ring Topology</strong></u></h3>

Alle Host sind über eine geschlossene Kabelstrecke ringförmig Verbunden, für die Regelung wird nicht zwangsweise eine aktive Netzwerkkomponente benötigt, sondern es wird primär über Datenprotokollen alles geregelt.
Die Steuerung lässt sich besser verteilen und ein großer Netzausbau ist möglich, allerdings ist die Fehlersuche komplexer, der Verkablungsaufwand höher und bei einer Störung gibt es ein Netzwerkausfall.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/7/71/Netzwerktopologie_Ring.png" alt="Bus"/>

#### <h3><u><strong>Star Topology</strong></u></h3>

Es wird eine Zentrale Netzwerkkomponente benötigt, jeder Host besitz eine eigene Leitung zum Zentralemgerät welches die Daten empfangen und weiterleiten kann.
Durch die Zentraleverwaltung entsteht eine hohe Datenbelastung.
Die Vernetzung und erweiterung ist relativ simple und es bietet eine hohe Ausfallsichertheit.
Bei einer zustarken belastung der Netzwerkkomponente wird es einen Ausfall geben und durch die vielen Verbidungen ist ein hoher Verkablungsaufwand.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Netzwerktopologie_Stern.png" alt="Bus"/>

#### <h3><u><strong>Mesh Topology</strong></u></h3>

Es handelt sich hier bei um ein dezentrales Netzwerk, wobei es auch keine verbindliche Struktur gibt, was dafür sorgt das nicht alle Hosts miteinader verbunden sind.
Bei einer Netzwerkstörung kann die Kommunikation über andere Verbindungen fortgeführt werden.
Fürs Mesh spricht die dezentrale Steuerung, eine hohe Ausfallsichertheit und die beliebige Netzwerkerwiterung.
Allerdings ist es äußerst aufwendig so ein System zu administrieren und sehr Kostspielieg.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/9/91/Netzwerktopologie_vermascht.png" alt="Bus"/>

### <h2><u><strong>Other Topology</strong></u></h2>

#### <h3><u><strong>Point-To-Point</strong></u></h3>

Eine einfache und direkte physikalische Verbindung zwischen zwei Hosts über ein Kabel. Beide Geräte können diese Verbindung für die gegenseitige Kommunikation verwenden.

#### <h3><u><strong>Point-To-Multipoint</strong></u></h3>

Das Netzwerk besteht aus mehreren Hosts, die sich eine Leitung zum Zentralsystem teilen. Die Kommunikation zwischen den Hosts erfolgt in allen Richtungen, in denen eine Verbindung besteht. Das bedeutet, nur bis zum Verzweigungspunkt kann jeder Teilnehmer sein eigenes Übertragungsmedium aufweisen.

#### <h3><u><strong>Line-Topologie</strong></u></h3>

In einer Linien-Topologie sind mehrere Hosts nacheinander in Reihe geschaltet. Die beiden Enden der Linie sind jeweils mit einem Gerät abgeschlossen.

#### <h3><u><strong>Tree-Topology</strong></u></h3>

Ein übergeordnetes Netzwerk-Element wie der Router bildet den Ausgangspunkt, von dem sich ein Stamm mit vielen Verzweigungen bildet.

#### <h3><u><strong>Fully-Connected-Topology</strong></u></h3>

In einer Fully-Connected-Topology sind alle Hosts miteinander verbunden. Das bedeutet, jedes Gerät besitzt eine eigene physikalische Verbindung zu allen anderen Hosts. Die Anzahl der verfügbaren Schnittstellen bei jedem Host ist entsprechend umfangreich.
Theoretisch handelt es sich um ein perfektes Netzwerk, jedoch kommt die vollvermaschte Topologie in der Praxis nicht häufig zum Einsatz. Der Grund dafür ist, dass für die direkte Verbindung zwischen zwei Geräten ein großer Aufwand erforderlich ist.

#### <h3><u><strong>Fabric-Topology</strong></u></h3>

Es gibt keinen zentralen Knoten, alle Hosts sind direkt miteinander verbunden. Diese Netzwerkstruktur bildet die Grundlage zu hochverfügbaren verteilten Systemen.
`
const cNwOsi = `
## <h1><u><strong>OSI-Modell</strong></u></h1>

Das **O**pen **S**ystem **I**nterconection-Modell ist ein Referenzmodell für Netzwerkprotokolle als Schichtenarchitektur.
Dank des OSI Modelle kann Kommunikation in Netzwerken stattfinden, wobei die Hardware und Softwarebedingungen egal sind.
Das Model besteht aus sieben Schichten, die sich in vier TCP/IP und vier Protokoll Gruppen einteilen lassen.

| Layer                          | Name                                                                              | <a href="tcp/ip">TCP/IP Model </a>               | Protokolle                                                   |
| ------------------------------ | --------------------------------------------------------------------------------- | ------------------------------------------------ | ------------------------------------------------------------ |
| <p style="color:	#00FFFF">7</p> | <p style="color:	#00FFFF"><a href="#application-layer">Application Layer</a></p>   | <p style="color:	#00FFFF"> Application Layer </p> | <p style="color:	#00FFFF"> Http, SMTP, FTP, DHCP, Telnet </p> |
| <p style="color:	#00FFFF">6</p> | <p style="color:	#00FFFF"><a href="#presentation-layer">Presentation Layer</a></p> | <p style="color:	#00FFFF"> Application Layer </p> | <p style="color:	#00FFFF"> Http, SMTP, FTP, DHCP, Telnet </p> |
| <p style="color:	#00FFFF">5</p> | <p style="color:	#00FFFF"><a href="#session-layer">Session Layer</a></p>           | <p style="color:	#00FFFF"> Application Layer </p> | <p style="color:	#00FFFF"> Http, SMTP, FTP, DHCP, Telnet </p> |
| <p style="color:	#FFA640">4</p> | <p style="color:	#FFA640"><a href="#transport-layer">Transport Layer</a></p>       | <p style="color:	#FFA640">Trasnsport Layer </p>   | <p style="color:	#FFA640">TCP, UDP </p>                       |
| <p style="color:	#FFEA00">3</p> | <p style="color:	#FFEA00"><a href="#network-layer">Network Layer</a></p>           | <p style="color:	#FFEA00"> Internet Layer </p>    | <p style="color:	#FFEA00"> IP, IPsec, ICMP </p>               |
| <p style="color:	#4C9141">2</p> | <p style="color:	#4C9141"><a href="#data-layer">Data Layer</a></p>                 | <p style="color:	#4C9141"> Network Layer </p>     | <p style="color:	#4C9141">Ethernet</p>                        |
| <p style="color:	#4C9141">1</p> | <p style="color:	#4C9141"><a href="#physical-layer">Physical Layer</a></p>         | <p style="color:	#4C9141"> Netzwork Layer </p>    | <p style="color:	#4C9141">Ethernet</p>                        |

Die Schichten können miteinander kommunizieren und Pakete austauschen.

### <u><strong>Application Layer</strong></u>

Der Application Layer ist nichts anderes als das, was der Nutzer sieht, das **U**ser **I**netrface also.

### <u><strong>Presentation Layer</strong></u>

Hier werden die Datenpakete verschlüsselt, komprimiert und mithilfe der Protokolle einheitlich übersetz, damit jede Hard- und Software die Daten empfangen und lesen kann.

### <u><strong>Session Layer</strong></u>

Es wird eine Verbindung zwischen den Endsystemen hergestellt, wobei immer wieder kleine Checkpoint erreicht werden, damit die Daten nicht verloren gehen und sich Fehler vermeiden lassen.

### <u><strong>Transport Layer</strong></u>

Es werden die Daten in kleiner Pakete aufgeteilt, dann versendet und beim Empfänger wieder zusammen gesetzt. Bei einem Fehler werden die Daten erneut gesendet, dank der Checkpoints aus dem <a href="session-layer">Session Layer</a> müssen nicht alle Daten neu gesendet werden.
Die Verbindung wird solange aufrecht erhalten bis alle Pakete gesendet wurden.

### <u><strong>Network Layer</strong></u>

Sorgt für die Kommunikation zwischen unterschiedlichen Netzwerken, dabei werden die Pakete in kleiner aufgeteilt.

### <u><strong>Data Layer</strong></u>

Auch hier werden die Pakete erneut aufgeteilt und innerhalb des Netzwerkes verschickt.

### <u><strong>Physical Layer</strong></u>

Stellt die Schnittstelle zum Übertragungsmedium her, mithilfe physischer Komponenten. Die Pakete sind hier nur noch Bits, Nullen und Einsen.
`
const cNwMac = `
## <h1><u><strong>MAC-Adressen</strong></u></h1>

**M**edia **A**ccess **C**ontrol Adressen ist die eindeutige Hardwareadresse eines Netzwerkadapters bzw. einer Netzwerkschnittstelle und ermöglicht die eindeutige Identifizierung eines elektronischen Geräts.
Zusätzlich zu IP-Adressen werden MAC-Adressen verwendet, damit in einem Netzwerk ein System das andere findet. Wird innerhalb eines lokalen Netzwerkes ein Datenpaket von einem System an ein anderes geschickt, sucht das System zunächst zur Ziel-IP-Adresse die passende MAC-Adresse. Dieser Vorgang stellt sicher, dass das Datenpaket korrekt versandt wird.
Sie befindet sich auf der <a href="#data-layer">zwieten Ebene</a> des <a href="#osi-modell">OSI-Modells</a>.

Eine MAC-Adresse besteht aus 48 Bits, was sechs Bytes sind, und wird in der Hexadezimalschreibweise dargestellt.
Somit besteht sie aus 12 Stellen, wobei die ersten drei Bytes den Hersteller der <p style="color: #C8E265">Netzwerkkarte identifizieren</p>, **O**rganizationally **U**nique **I**dentifier, und die <p style="color: #954F70">letzten drei</p>, die NIC-Spezifischen, werden vom hersteller frei vergeben.
Beispiel MAC-Adresse: <p style="color: #C8E265">C8-E2-65</p><p style="color: #954F70">-95-4F-70</p>
`
const cNwDarstellungen = `
## <h1><u><strong>Darstellungen</strong></u></h1>

### <h2><u><strong>Bits und bytes</strong></u></h2>

**Bit** steht für binary digit und ist die kleinste binäre Informationseinheit, da sie nur eine 1 oder 0 abbilden.
Alle größeren Dateneinheiten der Digitaltechnik.

Ein **Byte** besteht aus 8 Bits, somit kann ein Byte 256 mögliche erscheinungen haben, 2<sup>8</sup>=256.

### <h2><u><strong>Binary</strong></u></h2>

Das Binärsystem ist ein Zahlensystem, das zur Darstellung von Zahlen nur **1** und **0** benutzt, an und aus.

### <h2><u><strong>Dezimal</strong></u></h2>

Das Dezimalsystem ist ein Zahlensystem, das zur Darstellung die Zahlen von **0** bis **9** benutzt.

### <h2><u><strong>Hexadezimal</strong></u></h2>

Das Hexadezimalensystem ist ein Zahlensystem, das zur Darstellung die Zahlen von **0** bis **9**, deshalb dezimal, und die Buchstarben **A** bis **F**, deshalb Hexa (Hexa stammt aus der griechischen Sprache und bedeutet 6) benutzt.
Die Buchstaben stehen für folgende Zahlen:

- A = 10
- B = 11
- C = 12
- D = 13
- E = 14
- F = 15
`
const cNwTcpIp = `
## <h1><u><strong>TCP/IP</strong></u></h1>

<a href="#tcp">TCP</a> und <a href='#ip'>IP</a> stellen die Grundlagen für viele Netzwerke, wie zum Beispiel des Internets.
Es vermittelt und transportiert die Pakete in und zwischen Netzwerken.
Die Hauptaufgaben sind:

- Sicherstellung der Zustellung von Datenpaketen
- Beseitigung von Problemen bei der Zustellung.

Die Probleme sind allerdings sehr vielseitig und somit ergeben sich fünf Funktionen, die unterschiedlichen Protokolle nutzen.

- <a href="logical-adressing">Logical Adressing</a>
- <a href="#routing">Routing</a>
- <a href="#error-and-flow-control">Error and Flow Control</a>
- <a href="#application-support">Application Support</a>
- <a href="#name-resolution">Name Resolution</a>

Das TCP/IP Modell besteht aus vier Schichten, deren Reihenfolge vom Empfangsgerät und Sendegerät abhängig sind, immer entgegen Gesetz.

| Sendegerät | Layer           | <a href="#osi-modell">OSI Layer</a> | Empfangsgerät |
| ---------- | --------------- | ----------------------------------- | ------------- |
| \/         | Application     | 7-5                                 | /\            |
| \/         | Transport Layer | 4                                   | /\            |
| \/         | Internet Layer  | 3                                   | /\            |
| \/         | Network         | 1 - 2                               | /\            |

### <h2><u><strong>TCP</strong></u></h2>

Das **T**ransmition **C**ontorl **P**rotocol ist ein Standard, der für den Austausch von Daten zwischen Netzwerk Komponenten festlegt und vereinheitlicht.
Die Daten sollen Strukturiert werden und somit Datenverluste zu verhindern.
Es sorgt für eine Integrität, eine sichere Datenverarbeitung und eine Verbindungsorientierung. Somit stellt es im Vergleich zum **U**ser **D**atagram **P**rotocol eine bessere Möglichkeit dar, um Datenpakete zu verschicken, da dort die Bestätigung wegfällt.
Das funktioniert durch die Zerlegung der Pakete beim Senden und beim zusammen setzen beim Empfangen, und ist somit das wichtigste Protokoll der vierten Schicht im OSI Modell (<a href="#transport-layer">Transport Layer</a>).
Mithilfe von <a href='#ip'>IP</a> stellt es die Grundregel des Internets.
TCP besitz folgende fünf primäre Aufgaben:

- <a href="#segmentierung">Segmentierung</a>
- <a herf="#verbindungsmanagment">Verbindungsmanagment</a>
- <a herf="#error-control">Error Control</a>
- <a herf="#flow-control">Flow Control</a>
- <a herf="#application-support">Application Support</a>

#### <h3><u><strong>Segmentierung</strong></u></h3>

Der Datenstrom wird in kleiner Frames aufgeteilt und mit TCP-Header versehen, diese enthalten Steuer- und Kontroll-informationen des Frames.
Beim Routing können die Daten in einer falschen Reihenfolge ankommen, weshalb jedes Frame eine spezifische Sequenznummer erhält, wodurch der Empfänger die Pakete richtig zusammen setzen kann.

#### <h3><u><strong>Verbindungsmanagment</strong></u></h3>

Regelt den Auf- und Abb-bau der Kommunikation. Es wird eine End-to-End-connection aufgebaut, wodurch eine konstante Verbindung vorliegt.

#### <h3><u><strong>Error Control</strong></u></h3>

Um Fehler und Verluste auszuschließen, muss der Empfänger jedes angekommene Datenpaket bestätigen, falls keine Bestätigung erfolgt, wird das Paket erneut gesendet.

#### <h3><u><strong>Flow Control</strong></u></h3>

Regulation von Zeit und Ort, damit eine dynamische Auslastung der Brandweite vorgenommen werden kann.

#### <h3><u><strong>Application Support</strong></u></h3>

Durch unterschiedliche TCP-Ports können unterschiedliche Kommunikationsverbindungen vorgenommen werden und unterschiedliche Dienste angesprochen werden.
So gibt es Globalestandards für die Unterschiedlichen Ports, eine Liste kann <a href="https://de.wikipedia.org/wiki/Liste_der_standardisierten_Ports">hier</a> gefunden werden.

### <h2><u><strong>IP</strong></u></h2>

IP steht für **I**nternet **P**rotocol, es wird benötigt damit Geräte in Netzwerken mit einander kommunizieren können.
IP-Adressen können sowohl physischen Ports als auch virtuellen Schnittstellen zugewiesen werden. Eine virtuelle Schnittstelle ist nicht direkt an Hardware gekoppelt. Die wichtigste Aufgabe von IP ist, dass jeder Host in einem dezentralen TCP/IP-Netzwerk gefunden werden kann. Dazu wird jedem Hardware-Interface (Netzwerkkarte oder -adapter) eine logische IP-Adresse zugeteilt. Die IP-Adresse ist mit den Angaben zu Straße, Hausnummer und Ort einer Anschrift vergleichbar.

#### <h3><u><strong>Logical Adressing</strong></u></h3>

Das Netzwerk wird in unterschiedliche Segmente aufgeteilt und es wird dafür gesorgt, dass Datenpakete nur ins richtige Segment gelangen, wo sich auch der Empfänger befindet.

#### <h3><u><strong>Routing</strong></u><h3>

Durch die Verbindung von unterschiedlichen Netzwerkknoten werden die Daten verschickt.

#### Kategorien von Adressen

Bei den IPv6 Adressen gibt es drei Kategorien von Adressen.

| Name                     | Beschreibung                                                                                                                                       | Beispiel        | Veranschaulichung                                                                                                        |
| ------------------------ | -------------------------------------------------------------------------------------------------------------------------------------------------- | --------------- | ------------------------------------------------------------------------------------------------------------------------ |
| Unicast (one-to-one)     | Nachrichtenübertragung zwischen einem einzigen Sender und einem einzigen Empfänger.                                                                | Telefongespräch | ![unicast](https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Unicast.svg/1200px-Unicast.svg.png)   |
| Mullcast (one-to-many)   | Nachrichtenübertragung von einem Sender zu einer Gruppe von Empfängern.                                                                            | Radiosendung    | ![multicast](https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Unicast.svg/1200px-Unicast.svg.png) |
| Anycast (one-to-nearest) | Nachrichtenübertragung von einem Sender an einen einzelnen Empfänger einer Gruppe. Es antwortet der über die kürzeste Route erreichbare Empfänger. | Notrufnummer    | ![anycast](https://upload.wikimedia.org/wikipedia/en/1/18/Anycast-BM.svg)                                  |

#### <h3><u><strong>IPv4</strong></u></h3>

Die Darstellung einer IPv4-Adresse erfolgt in der so genannten punktierten Dezimalschreibweise das heißt vier Dezimalzahlen im Bereich von 0 bis 255 werden durch Punkte voneinander getrennt. IPv4-Adressen werden an einzelne Geräte in einem Netzwerk vergeben.
Jede IPv4 Adresse ist nur 4 Bytes, das sind 32 Bits, lang. Das bedeutet, dass es theoretisch maximal 2<sup>32</sup> = 4.294.967.296 Adressen geben kann.

Der Computer kann nur mir der Binary version der punktierten Dezimalschreibweise etwas anfangen.
Ein Bit besteht aus 8 Bytes, ein Byte kann nur eine null(aus) oder eine eins(an) sein.
Wie kommt nun allerdings die Zahlen 0 bis 255 zustande?
Jede Kombination repräsentiert eine zahl, zum Beispiel 00000001 ist eine eins, somit ergibt sich wenn man 2(Anzahl der Optionen)<sup>8</sup>(Anzahl an Positionen wo die Zahl eintritt) = 256.
In der Folgenden Tabelle werden einmal die möglichen Zahlen für die Bytekombinationen gezeigt.

|        |               |               |               |               |               |               |               |               |
| ------ | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- |
| Option | 2<sup>7</sup> | 2<sup>6</sup> | 2<sup>5</sup> | 2<sup>4</sup> | 2<sup>3</sup> | 2<sup>2</sup> | 2<sup>1</sup> | 2<sup>0</sup> |
| Bytes  | 128           | 64            | 32            | 16            | 8             | 4             | 2             | 1             |

##### <h4><u><strong>Binary to Decimal</strong></u></h4>

Um die Dezimalzahl zu erechnen, müssen alles Bytes addiert werden, als Beispiel nehmen wir **10100110.00101000.11001100.000000010**

Nach dem Muster ergibt sich:
<u>ersten Block:</u>

| Bytestelle | 1.  | 2.  | 3.  | 4.  | 5.  | 6.  | 7.  | 8.  |
| ---------- | --- | --- | --- | --- | --- | --- | --- | --- |
| Byte       | 1   | 0   | 1   | 0   | 0   | 1   | 1   | 0   |
| Bytwert    | 128 | 0   | 32  | 0   | 0   | 4   | 2   | 0   |
| Addieren   | 128 | 128 | 160 | 160 | 160 | 164 | 166 | 166 |

<u>zweitem Block:</u>

| Bytestelle | 1.  | 2.  | 3.  | 4.  | 5.  | 6.  | 7.  | 8.  |
| ---------- | --- | --- | --- | --- | --- | --- | --- | --- |
| Byte       | 0   | 0   | 1   | 0   | 1   | 0   | 0   | 0   |
| Bytwert    | 0   | 0   | 32  | 0   | 8   | 0   | 0   | 0   |
| Addieren   | 0   | 0   | 32  | 32  | 40  | 40  | 40  | 40  |

<u>dritter Block:</u>

| Bytestelle | 1.  | 2.  | 3.  | 4.  | 5.  | 6.  | 7.  | 8.  |
| ---------- | --- | --- | --- | --- | --- | --- | --- | --- |
| Byte       | 1   | 1   | 0   | 0   | 1   | 1   | 0   | 0   |
| Bytwert    | 128 | 64  | 0   | 0   | 8   | 4   | 0   | 0   |
| Addieren   | 128 | 192 | 192 | 192 | 200 | 204 | 204 | 204 |

<u>dritter Block:</u>

| Bytestelle | 1.  | 2.  | 3.  | 4.  | 5.  | 6.  | 7.  | 8.  |
| ---------- | --- | --- | --- | --- | --- | --- | --- | --- |
| Byte       | 0   | 0   | 0   | 0   | 0   | 0   | 1   | 0   |
| Bytwert    | 0   | 0   | 0   | 0   | 0   | 0   | 2   | 0   |
| Addieren   | 0   | 0   | 0   | 0   | 0   | 0   | 2   | 2   |

Wenn wir nun alle vier Blöcke addieren ergibt sich folgende IP-Adresse:

| Type    | 1. Block | 2. Block | 3. Block | 4. Block  | All in one                           |
| ------- | -------- | -------- | -------- | --------- | ------------------------------------ |
| Binary  | 10100110 | 00101000 | 11001100 | 000000010 | 10100110.00101000.11001100.000000010 |
| Dezimal | 166      | 40       | 204      | 2         | 166.40.204.2                         |

##### <h4><u><strong>Decimal to Binary</strong></u></h4>

Natürlich geht das ganze auch anders herum als Beispiel nehmen wir die IPv4-Adresse **172.16.34.3**.
Wie gehen wir das ganze nun an?
Wir subtrahieren, falls es möglich die Zahl von den Unterschiedlichen Bytwerten.

<u>erster Block:</u>

| Bytewert | Decimal | subtrahieren | Byte |
| -------- | ------- | ------------ | ---- |
| Passt    | in      | ?            |      |
|          |         |              |      |
| 128      | 172     | 172-128=44   | 1    |
| 64       | 44      | Nein         | 0    |
| 32       | 44      | 44-32=12     | 1    |
| 16       | 12      | Nein         | 0    |
| 8        | 12      | 12-84=4      | 1    |
| 4        | 4       | 4-4=0        | 1    |
| 2        | 0       | Nein         | 0    |
| 1        | 0       | Nein         | 0    |

<u>zweiter Block:</u>

| Bytewert | Decimal | subtrahieren | Byte |
| -------- | ------- | ------------ | ---- |
| Passt    | in      | ?            |      |
|          |         |              |      |
| 128      | 16      | Nein         | 0    |
| 64       | 16      | Nein         | 0    |
| 32       | 16      | Nein         | 0    |
| 16       | 16      | 16-16        | 1    |
| 8        | 0       | Nein         | 0    |
| 4        | 0       | Nein         | 0    |
| 2        | 0       | Nein         | 0    |
| 1        | 0       | Nein         | 0    |

<u>dritter Block:</u>

| Bytewert | Decimal | subtrahieren | Byte |
| -------- | ------- | ------------ | ---- |
| Passt    | in      | ?            |      |
|          |         |              |      |
| 128      | 34      | Nein         | 0    |
| 64       | 34      | Nein         | 0    |
| 32       | 34      | 34-32=2      | 1    |
| 16       | 2       | Nein         | 0    |
| 8        | 2       | Nein         | 0    |
| 4        | 2       | Nein         | 0    |
| 2        | 2       | 2-2=0        | 1    |
| 1        | 0       | Nein         | 0    |

<u>vierter Block:</u>

| Bytewert | Decimal | subtrahieren | Byte |
| -------- | ------- | ------------ | ---- |
| Passt    | in      | ?            |      |
|          |         |              |      |
| 128      | 3       | Nein         | 0    |
| 64       | 3       | Nein         | 0    |
| 32       | 3       | Nein         | 0    |
| 16       | 3       | Nein         | 0    |
| 8        | 3       | Nein         | 0    |
| 4        | 3       | Nein         | 0    |
| 2        | 3       | 3-2=1        | 1    |
| 1        | 1       | 1-1=0        | 1    |

| Type    | 1. Block | 2. Block | 3. Block | 4. Block | All in one                          |
| ------- | -------- | -------- | -------- | -------- | ----------------------------------- |
| Binary  | 10101100 | 00010000 | 00100010 | 00000011 | 10101100.00010000.00100010.00000011 |
| Dezimal | 172      | 16       | 34       | 3        | 172.16.34.3                         |

##### <h4><u><strong>Subnet mask</strong></u></h4>

Zu einer IPv4-Adresse gehört notwendigerweise eine Subnetzmaske. In Verbindung mit der IPv4-Adresse bestimmt die Subnetzmaske, zu welchem Subnetz das Gerät gehört. Die Standard-Gateway-Adresse ist die IP-Adresse des Routers, über den der Host auf Remote-Netzwerke zugreift, zum Beispiel auch auf das Internet.
Es gibt zwei Arten eine Subnet Mask zu schriben

1.  Der klassiche weg mit punktierten Dezimalschreibweise **255.255.255.255**
2.  Binary **11111111.11111111.11111111.11111111**

Mithilfe der Subnet mask können größen für Netzwerke festgelegt werden.
Falls eine IPv4-Adress auserhalb des Bereiches fällt, kann der Client nicht ohne hilfe mit dem gewünschten Ziel kommunizieren.
Dann kommt das **Default Gateway** ins Spiel, in der Regel der Router, dieser ermöglicht uns die kommunikation mit anderen IP-Ranges, dabei ausgenommen sind die <a href="#private-ip">Private IP</a> Adress Ranges.
Dabei legt die Subnet mask fest wiviele Zahlen in dem Octet noch verfügbar wären.

Alles was als Byte eine eins ist gehört zu der <p style="color: #EE82EE">Network Portion</p>, alle anderen, die nullen, gehören zu der <p style="color: #71EFFF">Host Portion</p>

|                     | 1. Octet                          | 2. Octet                          | 3. Octet                           | 4. Octet                            | Anzahl an IP-Adressen |
| ------------------- | --------------------------------- | --------------------------------- | ---------------------------------- | ----------------------------------- | --------------------- |
| Own IPv4 Adress     | <p style="color: #EE82EE">192</p> | <p style="color: #EE82EE">168</p> | <p style="color: #EE82EE">1</p>    | <p style="color: #71EFFF">254 </p>  |                       |
| Subnet mask         | <p style="color: #EE82EE">255</p> | <p style="color: #EE82EE">255</p> | <p style="color: #EE82EE">255 </p> | <p style="color: #71EFFF">0 </p>    |                       |
| Default Gateway     | <p style="color: #EE82EE">192</p> | <p style="color: #EE82EE">168</p> | <p style="color: #EE82EE">1</p>    | <p style="color: #71EFFF">1 </p>    |                       |
| Other IPv4 Adresses | <p style="color: #EE82EE">192</p> | <p style="color: #EE82EE">168</p> | <p style="color: #EE82EE">1 </p>   | <p style="color: #71EFFF">2-253</p> | 252                   |

Warum kann keiner die **0** oder **255** haben?
Das liegt daran das die **255** die **Broadcast Adress** ist und die **0** **Network Adress**

###### <h5><u><strong>Prefix</u></strong></u></h5>

| Prefix | Subnetz-Maske dezimal | Anzahl an IPv4 Adressen |
| ------ | --------------------- | ----------------------- |
| /32    | 255.255.255.255       | 1                       |
| /31    | 255.255.255.254       | 2                       |
| /30    | 255.255.255.252       | 4                       |
| /29    | 255.255.255.248       | 8                       |
| /28    | 255.255.255.240       | 16                      |
| /27    | 255.255.255.224       | 32                      |
| /26    | 255.255.255.192       | 64                      |
| /25    | 255.255.255.128       | 128                     |
| /24    | 255.255.255.0         | 256                     |
| /23    | 255.255.254.0         | 512                     |
| /22    | 255.255.252.0         | 1.024                   |
| /21    | 255.255.248.0         | 2.048                   |
| /20    | 255.255.240.0         | 4.096                   |
| /19    | 255.255.224.0         | 8.192                   |
| /18    | 255.255.192.0         | 16.384                  |
| /17    | 255.255.128.0         | 32.768                  |
| /16    | 255.255.0.0           | 65.536                  |
| /15    | 255.254.0.0           | 131.072                 |
| /14    | 255.252.0.0           | 262.144                 |
| /13    | 255.248.0.0           | 524.288                 |
| /12    | 255.240.0.0           | 1.048.574               |
| /11    | 255.224.0.0           | 2.097.150               |
| /10    | 255.192.0.0           | 4.194.304               |
| /9     | 255.128.0.0           | 8.388.608               |
| /8     | 255.0.0.0             | 16.777.216              |
| /7     | 254.0.0.0             | 33.554.428              |
| /6     | 252.0.0.0             | 67.108.856              |
| /5     | 248.0.0.0             | 134.217.712             |
| /4     | 240.0.0.0             | 268.435.424             |
| /3     | 224.0.0.0             | 536.870.848             |
| /2     | 192.0.0.0             | 1.073.741.696           |
| /1     | 128.0.0.0             | 2.147.483.392           |
| /0     | 0.0.0.0               | 4.294.966.784           |

###### <h5><u><strong>Subnetting</u></strong></u></h5>

Als Subnetz wird ein Teilnetz eines Netzwerkes beim Internetprotokoll bezeichnet. Es fasst mehrere aufeinanderfolgende IP-Adressen mittels einer Subnetzmaske an binären Grenzen unter einem gemeinsamen Vorderteil, dem Prefix zusammen.
Die Anzahl der neuen Subnetze lässt sich aus der Anzahl der Bits mit der Wertigkeit 1 festlegen und die Anzahl der Hosts mit der Anzahl der Bits mit der Wertigkeit 0.

<strong>Anzahl der Usable Subnetze</strong>
max. Anzahl der Subnetze = 2<sup>Anzahl der Subnetzbits</sup>-2(Netzadresse+Broadcast)

<strong>Anzahl der Usable Hosts</strong>
max. Anzahl der Hosts = 2<sup>Anzahl der Hostbits</sup>-2(Netzadresse+Broadcast)

Beispiel das eigene Heimnetzwerk Subnetten.

Das Netzwerk soll in vier Bereiche aufgeteilt werden:

- Wireless
- IoT
- Owner
- Guest

Und der ausgangspunkt ist:

- IPv4 Netzwerk: 192.168.1.0/24(24-> actctive bits)
- Subnet mask: 255.255.255.0

1. Wie viele Netzwerke werden benötigt?

Hier für erweitern wir die Byte Tabelle von oben.

| Bit                             | 8             | 7             | 6             | 5             | 4             | 3             | 2             | 1             |
| ------------------------------- | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- | ------------- |
| Option                          | 2<sup>7</sup> | 2<sup>6</sup> | 2<sup>5</sup> | 2<sup>4</sup> | 2<sup>3</sup> | 2<sup>2</sup> | 2<sup>1</sup> | 2<sup>0</sup> |
| Bytes                           | 128           | 64            | 32            | 16            | 8             | 4             | 2             | 1             |
| Höchste Zahl nach der addierung | 256           | 128           | 64            | 32            | 16            | 8             | 4             | 2             |

Nun Suchen wir uns in der letzen Spalte die gewünscht anzahl an Netzwerken und lesen die benötigt Bits Zahl ab, somit werden 2 bits benötigt.

2. Die Subnet mask bestimmen

Somit ergibt sich folgende Subnet mask:

| Type    | 1. Block | 2. Block | 3. Block | 4. Block | all in one                          |
| ------- | -------- | -------- | -------- | -------- | ----------------------------------- |
| Binary  | 11111111 | 11111111 | 11111111 | 11000000 | 11111111.11111111.11111111.11000000 |
| Decimal | 255      | 255      | 255      | 192      | 255.255.255.192                     |

3. Increment herausfinden

Das **Increment** ist das Byte was zum letze active Bit in der Subnet maskgehört
11111111.11111111.11111111.1**1**000000 somit ist unser Increment 64.
Somit hat jedes der vier Netzwerke eine Größe von 64 Host.

4. Netzwerke bestimmen:

| Name     | Range                       |
| -------- | --------------------------- |
| Wireless | 129.168.1.0-192.168.1.63    |
| IoT      | 192.168.1.64-192.168.1.127  |
| Owner    | 192.168.1.128-192.168.1.191 |
| Guest    | 192.168.1.192-192.168.1.255 |

###### Standardmasken

Public

| Name    | Dezimal                       | IP-Bereich Start | IP-Bereich Ende | Prefix                 | Mögliche Hosts               |
| ------- | ----------------------------- | ---------------- | --------------- | ---------------------- | ---------------------------- |
| Class A | 255.0.0.0                     | 1.0.0.1          | 127.255.255.254 | /8                     | 254<sup>3</sup> = 16.387.064 |
| Class B | 255.255.0.0                   | 128.1.0.1        | 191.255.255.254 | /16                    | 254 <sup>2</sup>= 64.516     |
| Class C | 255.255.255.0                 | 192.1.0.1        | 223.255.255.254 | /24                    | 254 <sup>1</sup>= 254        |
| Class D | 224.0.0.0 bis 239.255.255.255 | 224.0.0.1        | 239.255.255.254 | /4                     | 254 <sup>0</sup>= 1          |
| Class E | 240.0.0.0 bis 255.255.255.255 | 240.0.0.1        | 255.255.255.254 | Werden nicht vergeben! | Werden nicht vergeben!       |

Private

| Name     | IP-Bereich Start | IP-Bereich Ende | Prefix | Mögliche Hosts             |
| -------- | ---------------- | --------------- | ------ | -------------------------- |
| Privat A | 10.0.0.0         | 10.255.255.255  | /8     | 2<sup>24</sup> =16.777.216 |
| Privat A | 172.16.0.0       | 172.31.255.255  | /12    | 2<sup>20</sup> =1.048.576  |
| Privat A | 192.168.0.0      | 192.168.255.255 | /16    | 2<sup>16</sup> =65.536     |

##### <h4><u><strong>Private IP</strong></u></h4>

Private Private IP-Adress sind IP-Adressen, die von der **I**nternet **A**ssigned **N**umbers **A**uthority(Behörde für die zugewiesenen Nummern des Internets) nicht im Internet vergeben sind. Sie wurden für die private Nutzung aus dem öffentlichen Adressraum ausgespart, damit sie ohne administrativen Mehraufwand in lokalen Netzwerken genutzt werden können. Als die IPv4 knapp wurden und dadurch eine bewusste Einsparung öffentlicher IP-Adressen notwendig wurde, war es umso wichtiger, private IP-Adressen in lokalen Netzwerken zur Verfügung zu haben, die beliebig oft bzw. in beliebigen Netzwerken genutzt werden können.

| Range                         | Anzahlen an Adressen        | Use Case     |
| ----------------------------- | --------------------------- | ------------ |
| 10.0.0.0 - 10.255.255.255     | 2<sup>2</sup>4 = 16.777.216 | Heimnetzwerk |
| 172.16.0.0 - 172.31.255.255   | 2<sup>2</sup>0 = 1.048.576  | Local Host   |
| 192.168.0.0 - 192.168.255.255 | 2<sup>1</sup>6 = 65.536     | Heimnetzwerk |
| 169.254.0.0 - 169.254.255.255 | 2<sup>1</sup>6 = 65.536     | Heimnetzwerk |

##### <h4><u><strong>NAT</u></strong></u></h4>

Durch **N**etwork **A**ddress **T**ranslation kann jedes Heimnetzwerk nur eine Public-IP-Adresse haben und dennoch mit der ganzen Welt kommunizieren.
Damit die eingehenden Datenpakete dem lokalen Host zugeordnet werden können, speichert der Router zusätzliche die Port-Nummern der TCP-Verbindungen in einer sogenannten NAT-Tabelle.
Dabei wird in vier Kategorien unterschieden.

- <a href="full-cone">Full Cone</a>
- <a href="restricted-cone">Restricted Cone</a>
- <a href="port-restricted-cone">Port Restricted Cone</a>
- <a href="symmetric-nat">Symmetric NAT</a>

##### <h5><u><strong>Full Cone</h5></strong></u>

Das Gateway setzt eine interne Adressen und Ports nach einem statischen Muster in eine externe Adresse und deren Ports um. Es erlaubt insbesondere auch, dass so externe Hosts über die externe Adresse des NAT-Gateways Verbindungen zu internen Hosts aufbauen. Ein Synonym ist **port forwarding**.
Somit kann Konstant mit dem Internen Gerät gesprochen werden.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Full_Cone_NAT.svg/750px-Full_Cone_NAT.svg.png" alt="Bus"/>

##### <h5><u><strong>Restricted Cone</strong></u></h5>

Das Gateway erlaubt die Kontaktaufnahme eines externen mit einem internen Host nur, wenn diesem Verbindungsversuch eine Kontaktaufnahme dieses internen Hosts mit dem externen Host vorausging und dabei der gleiche Zielport verwendet wird.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Restricted_Cone_NAT.svg/750px-Restricted_Cone_NAT.svg.png" alt="Bus"/>

##### <h5><u><strong>Port Restricted Cone</h5></strong></u>

Das Gateway erlaubt die Kontaktaufnahme eines externen mit einem internen Host nur, wenn diesem Verbindungsversuch eine Kontaktaufnahme dieses internen Hosts mit dem externen Host vorausging und dabei der gleiche Zielport und der gleiche Quellport verwendet wird.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Port_Restricted_Cone_NAT.svg/750px-Port_Restricted_Cone_NAT.svg.png" alt="Bus"/>

##### <h5><u><strong>Symmetric NAT</h5></strong></u>

Jede einzelne Verbindung wird mit einem unterschiedlichen Quellport ausgeführt, die Beschränkungen sind wie bei Restricted Cone NAT-Szenario. Dadurch dass jede Verbindung einen eigenen Quellport zugewiesen bekommt ist eine Initiierung von Verbindungen durch externe Hosts nach Intern nicht oder kaum möglich.

<img style="height: 150px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Symmetric_NAT.svg/750px-Symmetric_NAT.svg.png" alt="Bus"/>

##### <h4><u><strong>SNMP</strong></u></h4>

**S**imple **N**etwork **M**anagement **P**rotocol ist ein Netzwerkprotokoll zur Überwachung und Steuerung von Netzwerkgeräten wie <a href="#router">Router</a>, <a href="#switch">Switches</a>, <a href="#firewall">Firewalls</a>, Drucker oder **N**etwork **A**ttached **S**torage Appliances.
Die Architektur von SNMP unterscheidet zwischen zwei Primärenrollen, einmal dem **Agent** und dem **Manager**.
Der Manager schickt an Anfragen an den Agent, und dieser sendet dann seine Antwort.
Es gibt sieben verschiedene Datenpakete, die gesendet werden können:

- GET-REQUEST
  - zum Anfordern eines Management-Datensatzes.
- GETNEXT-REQUEST
  - um den nachfolgenden Datensatz abzurufen (um Tabellen zu durchlaufen).
- GETBULK (ab SNMPv2)
  - um eine angegebene Anzahl an Datensätzen auf einmal abzurufen, ähnelt mehreren GETNEXT-REQUEST.
- SET-REQUEST
  - um einen oder mehrere Datensätze eines Netzelementes zu verändern. Manchmal verlangt ein Netzelement die gleichzeitige Änderung mehrerer Datensätze, um die Konsistenz zu überprüfen. Beispielsweise erfordert die Konfiguration einer IP-Adresse die gleichzeitige Angabe der Netzwerkmaske.
- GET-RESPONSE
  - Antwort auf eines der vorherigen Pakete.
- TRAP
  - unaufgeforderte Nachricht von einem Agenten an den Manager, dass ein Ereignis eingetreten ist. Programme wie Wireshark, die zum Dekodieren von Protokollen wie SNMP benutzt werden, nennen dieses Datenpaket auch REPORT. Ein TRAP kann auch geschickt werden, wenn die in einem SET-REQUEST-Paket beschriebene(n) Datensatzänderung(en) nicht durchgeführt werden konnte(n), und nicht nur, um eine Fehlfunktion (z. B. einen Defekt eines Moduls eines Netzelements) zu melden.
- INFORM-REQUEST
  - aufgebaut wie ein Trap, nur dass dieser vom Empfänger quittiert wird.

Die Daten werden dann im **M**anagement **I**nformation **B**ase gespeichert und sind über die **O**bject **ID** Entifier den unterschidlichen Prozessen zugeordnet.

##### <h4><u><strong>DHCP</strong></u></h4>

**D**ynamic **H**ost **C**onfiguration **P**rotocol ermöglicht die Zuweisung der Netzwerkkonfiguration an Clients durch einen Server. Der DHCP Server weißt unteranderem folgende Einstellungen zu:

- IP-Adresse und Netzwerkmaske
- Default-Gateway
- DNS-Server, DNS Context und DNS Tree
- Sekundärer DNS-Server
- Time- sowie NTP-Server
- WINS-Server (für Microsoft Windows Clients)
- Proxy-Konfiguration via WPAD

Die zuweisung kann automatisch geschehen, oder Manuell mit hilfe der MAC-Adresse.

#### <h3><u><strong>IPv6</u></strong></u></h3>

IPv6 Adressen besitzen eine länge von 128 Bits, das entspricht 2<sup>128</sup>= **340.282.366.920.938.643.463.347.607.431.768.211.456** IPv6 Adressen, und werden in acht Blöcken, jeweils 16 Bit lang, in der Hexerdezimal dargestellt
Aber wie setz sich eine IPv6 Adresse zusammen?
Es besteht aus zwei teilen die jeweils 64 Bit lang sind.

1. Network Prefix
2. <p style="color:	#00FFFF">Interface Identifier</p>

Bei dem Network Prefix legen die ersten 48 Bits denn <p style="color:	#0000ff">Global Routing Prefix</p> feste und die letzten 16 Bits die <p style="color:	#880088">Subnet ID</p> fest.

| <p style="color:	#0000ff">Global Routing Prefix</p> | <p style="color:	#880088">Network Prefix</p> | <p style="color:	#00FFFF">Interface Identifier</p> | Subnet Prefix |
| -------------------------------------------------- | ------------------------------------------- | ------------------------------------------------- | ------------- |
| <p style="color:	#0000ff">2001:0db8:0000:</p>       | <p style="color:	#880088">0001:</p>          | <p style="color:	#00FFFF">0000:0000:0000:0003</p>  | /64           |

##### RFC

###### 2460

IPv6 Header Format

![ipv6_header](https://www.tutorialspoint.com/ipv6/images/IPv6_header.jpg)

|                     |                                                                                                                                                                                              |
| ------------------- | -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| Version             | 4-Bit-Versionsnummer des Internet-Protokolls = 6.                                                                                                                                            |
| Traffic Class       | 8-bit traffic class field.                                                                                                                                                                   |
| Flow Label          | 20-bit flow label.                                                                                                                                                                           |
| Payload Length      | 16-Bit-Ganzzahl ohne Vorzeichen. Länge der IPv6 Nutzlast, d. h. des restlichen Teils des Pakets nach diesem IPv6-Header, in Oktetten.                                                        |
| Next Header         | 8-Bit-Selektor. Identifiziert den Typ des Headers der unmittelbar auf den IPv6-Header folgt. Verwendet die gleichen Werte wie das IPv4-Protokollfeld [RFC-1700 et seq.].                     |
| Hop Limit           | 8-Bit-Ganzzahl ohne Vorzeichen. Vermindert um 1 bei jedem Knoten, der das Paket weiterleitet. Das Paket wird verworfen, wenn Hop Limit auf Null dekrementiert wird. Null dekrementiert wird. |
| Source Address      | 128-Bit-Adresse des Absenders des Pakets.                                                                                                                                                    |
| Destination Address | 128-Bit-Adresse des vorgesehenen Empfängers des Pakets (möglicherweise nicht der endgültige Empfänger, wenn ein Routing-Header vorhanden ist).                                               |

###### 4291 Prefix Adresstypen

Die RFC 4291 legt Prefix Adresstypen fest.

| Adresstyp                  | Binary Prefix     | IPv6 prefix notation | Beschreibung                                                                                                                                                                                                                                                                                                                                                              |
| -------------------------- | ----------------- | -------------------- | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| Unspecified                | 00...0 (128 bits) | ::/128               | Die nicht spezifizierte Adresse zeigt an, dass es keine Adresse gibt, und sie kann niemals einem Host zugewiesen werden. Sie kann von einem IPv6-Host verwendet werden, dem noch keine Adresse zugewiesen wurde.                                                                                                                                                          |
| Loopback                   | 00...1 (128 bits) | ::1/128              | Eine Loopback-Adresse wurde in das IP-Domain-System eingebaut, damit ein Gerät seine eigenen Datenpakete senden und empfangen kann. Loopback-Adressen können bei verschiedenen Arten von Analysen wie Tests und Fehlersuche nützlich sein oder es Routern ermöglichen, auf bestimmte Weise zu kommunizieren.                                                              |
| Multicast                  | 1111 1111         | FF00::/8             | Die IPv6-Multicast-Adresse bietet eine Methode zur Verteilung identischer Informationen oder Dienste an eine bestimmte Gruppe von Schnittstellen, die so genannte Multicast-Gruppe. In der Regel befinden sich die Schnittstellen der Multicast-Gruppe auf verschiedenen Knotenpunkten. Eine Schnittstelle kann zu einer beliebigen Anzahl von Multicast-Gruppen gehören. |
| Link-Local Unicast         | 1111 1110 10      | FE80::/10            | Eine link-local-Adresse ist eine Unicast-Adresse, die auf eine einzige Verbindung, ein einziges Subnetz, beschränkt ist. Link-local-Adressen müssen nur innerhalb der Verbindung (des Subnetzes) und nicht außerhalb der Verbindung eindeutig sein. Daher leiten Router keine Pakete mit einer link-local-Adresse weiter.                                                 |
| Unique Local Unicast (ULA) | 1111 1110         | FC00::/7             | Unique Local Unicast (ULA) sind eindeutige Adressen, die die Kommunikation zwischen Knoten auf verschiedenen Verbindungen innerhalb desselben Standorts oder zwischen Standorten ermöglichen. Sie sind verwaltungstechnisch segmentiert und dienen nicht dem Routing im Internet. Die ULA sind in gewisser Weise mit dem privaten IPv4-Raum vergleichbar.                 |
| Global Unicast             | 001               | 2000::/3             | Global unicast addresses (GUAs) sind im IPv6-Internet global routbar und erreichbar. Sie sind äquivalent zu öffentlichen IPv4-Adressen. Sie spielen eine wichtige Rolle in der IPv6-Adressierungsarchitektur.                                                                                                                                                             |

##### Darstellung

Eine IPv6 Adresse wird in 8 Blöcken, oder auch Hextetten gennnat, dargstelt. Diese bestehen jeweils aus 16 BIT die in einer Hexerdezimalschreibweise dargestellt wird, die Blöcke werden durch **:** getrennt.
Um die länge der Adressen zuverkürzen dürfen führende nullen weggelassen werden, als Beispiel nehmen wir die Adresse: **2001:0db8:0000:0001:0000:0000:0000:0003**.
Führende Nullen sind jewiels die an dem Anfang eines Blockes stehen, in der Beispiel Adresse Währen es:
2001:**0**db8:**000**0:**000**1:**000**0:**000**0:**000**0:**000**3
Diese dürfen ausgelassen werden, somit ergibt sich folgende verkürzte Schreibweise:
**2001:db8:0:1:0:0:0:3**
Der letzte Verkützungsschritt ist die Auslassung einer Nullen Gruppen, dies darf nur einmal pro Adresse gemacht werden, es bietet sich immer an die längere Gruppe zu entfernen.
Aus der verkürzten weise wären es folgende:
2001:db8:**0**:1:**0:0:0**:3
Verkürzt ergibt dies:
**2001:db8:0:1::::3**

##### Privacy Extensions

Dadurch, dass die Mac-Adresse sich in der IPv6 Adresse wiederfinden lässt, entsteht ein enormes Datenschutzproblem.
Es lassen sich folgenden Daten herausfinden:

- Rückschlüsse auf Hardware/Betriebssystem
- User-Tracking (z.B. Wechsel Netzwerk oder Provider, etc.)

Um diese Problematiken zum umgehen wurde die IPv6 Techologie mit dem Privacy Extensions erweitert.
Dabei wird die Interface-ID zufällig generiert und regelmäßig gewchselt, in der Regeltäglich, allerdings kann man bis zu 7 Tage weiterhin auf die alten Adressen Daten empfangen.

Das ganze kann man unter Windows zufolgt nach schauen:

\`\`\`ps1
C:\Windows\system32>netsh interface ipv6 show privacy
Der aktive Status wird abgefragt...
Parameter für temporäre Adressen
------------------------------------------------
Temporäre Adresse verwenden         : enabled
Versuch, doppelte Adr. zu entdecken : 5
Maximale Gültigkeitsdauer           : 7d
Maximale bevorzugte Gültigkeitsdauer: 1d
Regenerationszeit                   : 5s
Maximale Verzögerungszeit           : 10m
Verzögerungszeit                    : 0s
\`\`\`

##### Subnetting

##### Prefix

###### <h4><u><strong>Network Prefix</u></strong></u></h4>

Der Network Prefix kennzeichnet das Netz, Adressbereich, und lässt sich in folgende Bereich aufteilen:

- Provider Netz vom RIR
- Kundennetz
- Network Prefix

aus der obringen IPv6 Adresse würde sich dann folgendes ergeben:

| Provider  | Kunde | Network Prefix |
| --------- | ----- | -------------- |
| 2001:0db8 | 0000  | 0001           |

###### Subnet Prefix

##### <h4><u><strong>Interface Identifier</u></strong></u></h4>

Der Interface Identifier kennzeichnet einen Host in diesem Netz. Er wird aus der 48-Bit-<a href="mac-adressen">Mac-Adresse</a> des Interfaces gebildet und dabei in eine 64-Bit-Adresse umgewandelt.
Damit man den Interface Identifier berechnen kann, muss man zuerst die MAC-Adresse in zweimal 24 Bits aufteilen und asnchließend folgendes 16-Bit feld in der mitte ergänzen: **0xFFFE**.

<img style="height: 150px" src="assets/img/48bit_to_64bit.drawio.svg" alt="64 Bit MAC"/>

#### <h3><u><strong>IPv4 vs IPv6</strong></u></h3>

|                    | IPv4              | IPv6                                                |
| ------------------ | ----------------- | --------------------------------------------------- |
| Bit länge          | 32                | 128                                                 |
| Anzahl an Oktetten | 4                 | 16                                                  |
| Anzahl an Hexetten | nicht definiert   | 8                                                   |
| Anzahl an Adressen | 4.294.967.296     | 340.282.366.920.938.643.463.347.607.431.768.211.456 |
| Schreibweise       | Dezimal           | Hexadezimal                                         |
| Konfiguration      | DHCP oder Manuell | Automatisch                                         |

Durch die direkte kommunikation zwischen zwei Geräte ist IPv6 schneller als IPv4, die über unterschidliche Netzwerkgeräte den Empfänger sucht.
Wenn IPv6 Adressen nicht richtig Konfiguriert sind bieten diese eine schwachstelle in Sicht des Datenschutzes, es lässt mit einer IPv6 Adresse den Standort, der Hersteller und teilweise auch das Gerät bestimmen.
Hingegen lässt sich bei einer Public IPv4 Adresse nur der Standort bestimmen.

IPv6 wird vorallem im GAN genutz werden, da IPv4 für ein LAN komplett ausreicht.
Ein LAN lässt sich mit IPv4 einfacher, individueller und abgeschottener gestallten.

![ip_headers](/netzwerk/assets/img/ip_headers.png)
`
const cNwVpn = `
## VPN/Tunneling

VPN Tunneling erlaubt es, beliebige Datenpakete aus einem LAN über das Internet in ein anderes LAN zu schicken.
Dabei spielt die Adressierung und das im LAN verwendete Übertragungsprotokoll keine Rolle

Dadurch ist es möglich zwei LANs transparent Über das Internet zu verbinden, wobei Transparenz bedeutet, dass die Stationen in den verbundenen LANs nicht mit der Verwaltung und dem Aufbau des Tunnels betraut sind. Das übernimmt ein Tunnel-Server. Die Stationen in den LANs arbeiteten so als ob alle in einem LAN arbeiten würden.

![vpn](https://upload.wikimedia.org/wikipedia/commons/0/00/Virtual_Private_Network_overview.svg)

IP-Tunneling über das Internet beruht darauf, dass dem zu transportierenden Paket ein neuere IP-Header vorangestellt wird. Der Header enthält als Quelladresse dieses Rechners und als Zieladresse einen Server, der den Endpunkt des Tunnels bildet und das transportierte Paket wieder entpackt, also den Tunnelheader entfernt. Dieses Paket wird dann wie gewohnt an den Empfänger im Lan geschickt. Der Tunnel verhält sich also wie eine Direktverbindung zwischen beiden Tunnel-Servern. Tunnel-Server (VPN-Gateways) können von Firmen selbst eingerichtet sein oder vom Provider in Anspruch genommen werden. Bei einem mobilen Zugriff von außen befindet sich der VPN-Client auf dem Host(Laptop).

### Sicherheitsanforderungen

Jeder VPN-Tunnel muss Sicherheitsanforderungen erfüllen. Beim Sicherheitsaspekt gibt es drei Anforderungen zu unterscheiden:

1. Verschlüsselung (Vertraulichkeit) - als Schutz gegen unbefugtes Mitlesen
2. Authentisierung der Nachtricht (Paket-Integrität) - Unverfälschheit der Nachricht
3. Authentisierung des Absenders (Paket-Authentizität) - unzweifelhafte Zuordnung eines Senders/Empfängers

Zur Umsetzung dieser Anforderungen greift VPN auf bewährte Verfahren zurück.

1. Zur Verschlüsselung

- symmetrische Verschlüsselung (DES, 3DES, AES, RC4/WEP)

2. Zur Authentifizierung

- PAP/Chap
- EAP/Radius mit und ohne Zertifikat

3. Zur Paketintegrität

- verschlüsselter Hashwert

4. Zum Schlüsselaustausch

- PKS (Pre Shared Key)
- asymmetrische Verschlüsselungsverfahren

### Layer 2

Der VPN-Tunnel wird durch einen zusätzlichen IP-Header realisiert. Also ist das Tunnelverfahren prinzipiell in Layer3
angesiedelt. Da aber IP keine Sicherheitsmechanismen kennt, greift man bei Layer2 VPN-Tunneln auf Techniken aus Layer2
Protokollen zurück. Hier bietet sich **P**oint-to-**P**oint **P**rotokoll an!

Wie beim PPP zwischen ISSP und User muss zunächst ein Punkt-zu-Punkt Verbindung aufgebaut sein, ehe PPP beginnt. Genau dies muss auch bei einem VPN-Tunnel geschehen. Die Punkt-zu-Punkt Verbindungsstrecke stellt hier den VPN-Tunnel zwischen den beiden Tunnel-Servern dar. Also wird in einem ersten Schritt durch das **P**oint-to-**P**oint **T**unneling **P**rotokoll ein Steuerungskanal für die Verbindung zwischen beiden Tunnelservern aufgebaut. Als Transportmedium dienen IP und TCP.

![pptp](https://wiki.mikrotik.com/images/b/bf/Pptp-rem-offoce.png)

Dieser Steuerungskanal ist logisch getrennt, eigener TCP-Port, vom eigentlichen Datenkanal. Der Aufbau der PPTP-Verbindung verläuft sehr ähnlich wie bei der "echten" PPP-Verbindung. Nachdem die Authentifizierung erfolgt ist, besteht der VPN-Tunnel und es kann die eigentliche VPN-Datenübertragung beginnen.
Die Nutzdaten werden verschlüsselt. Bei PPTP wird der symmetrische Schlüssel aus dem Anmeldepasswort hergeleitet (40 bzw. 128Bit /RC4 Verschlüsselung). Leider ist das nicht sehr sicher, deswegen kann auch eine DES Verschlüsselung ausgehandelt werden. Datenintegrietät (Hashwert) wird nicht gewährleistet!
Um das PPTP-Frame nun vom Tunnelserver A ins Internet zu schicken wird wieder PPP notwendig. Also wird das PPTP-Frame in ein weiteres PPP-Frame eingepackt.

**L**ayer **2** **T**unneling **P**rotokoll
Bei PPTP gibt es einen Kanal zur Steuerung und einen für die Datenübertragung. Das bringt allerdings Nachteile mit sich:

- der Steuerungskanal bei PPTP ist nicht verschlüsselt
- ein zweiter VPN-Tunnel zwischen zwei Tunnel-Servern kann nicht gleichzeitig errichtet werden

L2TP verwendet für die Steuerung und Datenübertragung dieselbe Frame-Struktur = INBAND-Verfahren. Also werden Nutzdaten und Steuerungs- daten wie bei TCP in gleichartige Frames eingepackt. Dadurch werden die Steuerungsdaten ebenfalls verschlüsselt und es können gleichzeitig mehrere VPN-Tunnel zwischen zwei Tunnelservern aufgebaut werden. Weiterhin können mehrere PPP-Verbindungen gleichzeitig innerhalb eines Tunnels aufgebaut werden.

![l2tp](https://systemzone.net/wp-content/uploads/2018/03/Site-to-SIte-L2TP-over-IPsec-Network.jpg)

Das **G**eneric **E**ncapsulation **P**rotokoll ist ein abgespecktes TCP. Da bereits durch das PPTP eine Punkt-zu-Punkt Verbindung zwischen den Tunnelservern aufgebaut ist, sind keine Port-Adressen nötig, außerdem entfällt der Verbindungsaufbau unter TCP (Sync, Ack). Es erfolgt keine Paket-Kontrolle und damit keine Bestätigung. Gehen Pakete verloren, so wird das TCP im Original Paket reagieren.

Der Transport des PPP-Rahmens kann nicht nur über IP erfolgen, sondern über ein beliebiges Medium, das die Übertragung von Punkt-zu-Punkt Datagrammen erlaubt. (z.B. ATM, ISDN, usw.)
Weitere Nachteile von PPTP:
• unsichere Verschlüsselung
• unflexibel bei Neuerungen
werden bei L2TP dadurch beseitigt, dass keine Sicherheitsverfahren festliegen, sondern mit dem Beginn der Übertragung vereinbart werden. So ist u.a. eine Kopplung an IPSEC möglich.

### IPSEC

Das **I**nternet **P**rotocol **Sec**urity arbeitet im Gegensatz zu PPTP und L2TP auf der Netzwerkschicht(Layer3). D.h. die Sicherung der Daten wird nicht mehr durch PPP realisiert, sondern durch spezielle Verfahren auf Layer3 wird das eigentlich unsichere IP gesichert.

IPSEC kann bei Bedarf alle Sicherheitsanforderungen erfüllen:

1. Verschlüsselung (Vertraulichkeit)
2. Authtizität der Nachricht (Paket-Integrität)
3. Authetizität des Absenders ( Paket-Authetizität)

Dabei gestattet IPSEC-Protokoll, alle gängigen Verfahren für die gewünschten Sicherheitsstufen.

Ein IPSEC-Paket mit einem Authentifikation Header stellt sicher, dass das empfangene Paket tatsächlich vom richtigen Absender stammt und dass das Paket nicht verändert wurde. Zur Realisierung wird ein Hashwert über die zu schätzenden Daten (inkl. Header) gebildet und dieser mit dem typischerweise symmetrischen Schlüssel des Absenders verschlüsselt. Diese Info ist als Hashwert im Authentifikation Header enthalten und zusätzlich eine Sequenznummer. Das Datenpaket wird nicht verschlüsselt.

Ein IPSEC-Paket mit einem Encapsulation Security Payload Header stellt sicher, dass die Nutzlast komplett verschlüsselt ist.
Zusätzlich wird Authentifizierung und Datenintegrität wie bei Authentifikation-Header(AH) ermöglicht.
Im ESP-Header sind bei Bedarf zusätzliche Daten für die Entschlüsselung enthalten (Initialisierungsverktor =>siehe WEP und Sequenznummer). Im Trailer ESP-Authetications-Data befindet sich der verschlüsselte Hashwert für die Athentifizierung und Datenintegrität. Bei AH ist das gesamte Paket authentifiziert, bei ESP immer ohne den Tunnel-IP Header.

IPSEC hat gegenüber den anderen Verfahren einen weiteren Vorteil:
Es kann als "normales" Transportprotokoll verwendet werden. Es wird nur die reine Nutzlast authentifiziert (AH) oder zusätzlich verschlüsselt(ESP). Dadurch muss weniger Overhead übermittelt werden, die Bandbreitenbelastung ist geringer.
Beim Transportmodus handelt es sich immer um einen End-to-End VPN-Tunnel, da die IP-Adressen Original-Quelle und Original-Ziel angeben.

Beim Transportmodus handelt es sich immer um einen End-to-End VPN-Tunnel, da die IP-Adressen Original-Quelle und Original-Ziel angeben.

Der IPSEC Tunnelmodus beseitigt den Nachteil im Transportmodus wo jeder Host eine VPN-Software benötigt. Typischerweise werden Firmen-LANs über ein
IPSEC-Tunnel Verbunden, sodass die Hosts ohne VPN-Software auskommen.

Der Aufbau einer IPSEC-Verbindung:

- Der Sicherheitsdienst wird festgelegt. (soll Verschlüsselung, Authentifizierung, Paket-Integrität oder alle verwendet werden)
- es werden die exakten Algorithmen festgelegt (z.B. DES/Verschlüsselung, SHA1/Integrität)
- Sitzungsschlüssel werden ausgetauscht.

#### SA

**S**ecurity **A**ssociations dienen für die Festlegung, welche Sicherheitsanforderungen zu erfüllen sind. SA werden über den Security Parameter Index (SPI) eindeutig gekennzeichnet und von jedem IPSEC-Paket im (AH)- oder (ESP)Header mitgegeben. Zwischen zwei Stationen bestehen immer zwei (SPI)s.

#### IKE

**I**nternet-**K**ey-**E**xchange arbeitet auf UDP Port 500.

Ein heikles Thema bei jeder Verschlüsselung und Authentifizierung ist die Erzeugung und Geheimhaltung der notwendigen Schlüssel. (Schlüsselmanagement).
Sicherer Schlüsselaustausch
Es lässt prinzipiell auf zwei Arten eine sichere Verbindung über IPSEC aufbauen:
• manueller Schlüsseltausch
• automatischer Schlüsseltausch
Bei großen VPNs wird meistens der automatische Schlüsseltausch mittels (IKE) bevorzugt.
Weitere Aufgaben von (IKE)

1. Authentifikation des Kommunikationspartners

IKE bietet drei verschiedene Authentifizierung Methoden:

- Authentifikation mit Pre-shared-Key
- Authentifizierung mit Digitaler-Signatur
- Authentifikation mit Public-Key VerschlÃ¼sselung

2. Austausch der (SA)s
3. Schlüsselerzeugung und Re-Keying

#### NAT-Traversal

Wegen der Authentifizierung der Pakete bei (AH) und (ESP) dürfen Veränderungen am geschätzten IPSEC-Paket nicht vorgenommen werden. Damit kann es Probleme bei NAT/PAT geben.
Für (AH)-Pakete gibt es nur die Lösung, ohne NAT/PAT zu arbeiten. Daraus ergeben sich nur zwei Möglichkeiten:

- Anwendung innerhalb eines LANs
- Einsatz von Provider-VPN (Intra-Provider-Modell)

Für (ESP)-Pakete gibt es NAT-Traversal. Weil bei (ESP) der IP-Header nicht authentisiert ist, lässt dieser sich ändern. (echtes NAT möglich). Bei PAT gibt es Probleme dadurch, dass ggf. die Portnummer geändert wird. Abhilfe schafft ein VPN-Gateway mit NAT-Traversal. Die VPN-Gateways klären vorher ob NAT/PAT angewendet wird.
Wenn das der Fall ist wird das (ESP)-Paket in ein UDP-Paket gekappselt (UDP-Port 4500).

![nat_traversal](https://download.huawei.com/mdl/image/download?uuid=a0c40cb87c274249bd65931003a5d592)
`
const cNwDevices = `
## <h1><u><strong>Network Device</strong></u></h1>

Um die Funktionen und Features der einzelnen Geräte deutlich zumachen werden Simulationen durchgeführt, werden zwei kleine Netzwerke gebaut mit folgenden Geräten:

<h3><u><strong>Noahs Netzwerk</strong></u></h3>

| IP Range              | Subnetmask    | Public IP |
| --------------------- | ------------- | --------- |
| 10.1.1.0 - 10.1.1.255 | 255.255.255.0 | 1.1.1.2   |

| Nutzer  | IP       | Device |
| ------- | -------- | ------ |
| Noah    | 10.1.1.2 | Laptop |
| Danyael | 10.1.1.3 | Laptop |
| Zeumer  | 10.1.1.4 | Laptop |
| Noerkel | 10.1.1.5 | Laptop |
| Emma    | 10.1.1.6 | Tablet |
| Zoe     | 10.1.1.7 | Tablet |
| Hub     |          |        |
| Switch  |          |        |
| Router  | 10.1.1.1 |        |

<h3><u><strong>Finns Netzwerk</strong></u></h3>

| IP Range                | Subnetmask    | Public IP |
| ----------------------- | ------------- | --------- |
| 172.1.1.1 - 172.1.1.255 | 172.255.255.0 | 1.1.1.3   |

| Nutzer | IP        | Device  |
| ------ | --------- | ------- |
| PC     | 172.1.1.2 | Desktop |
| Laptop | 172.1.1.3 | Laptop  |
| Switch |           |         |

### <h2><u><strong>Hub</strong></u></h2>

Der Hub verbindet mehrere Rechner in einem Rechnernetz, damit diese miteinander kommunizieren können. Ein Hub arbeitet ausschließlich auf <a href="#physical-layer">Ebene 1</a> des <a href="#osi-modell">OSI-Modells</a>. Der Nachteil eines Hubs ist, dass er die Informationen an alle angeschlossenen Rechner weiterleitet und Nachrichten entweder nur empfangen oder senden kann.
Als Bespiel sind in diese Szenario die vier Clints mit einen Hub verbunden.
**Noah**, 10.1.1.2, will **Danyael**,10.1.1.3, anpingen, und somit nur einen Client kontaktieren, allerdings werden alle vier kontaktiert.

![Hub](netzwerk/assets/video/hub.mp4)

### <h2><u><strong>Switch</strong></u></h2>

Ein Netzwerk-Switch verbindet Geräte in einem Netzwerk miteinander und ermöglicht ihnen die Kommunikation durch den Austausch von Datenpaketen. Switches können Hardwaregeräte sein, die physische Netzwerke oder softwarebasierte virtuelle Geräte verwalten.

Herkümliche Netzwerk-Switch arbeitet auf <a href="#data-layer">Schicht 2</a> des <a href="#osi-modell">OSI-Modells</a>. In einem LAN, das Ethernet verwendet, bestimmt ein Netzwerk-Switch, wohin jeder eingehende Frame gesendet werden soll, indem er sich die <a href="mac-adressen">Mac-Adresse</a> ansieht. Switches verwalten Tabellen, die jede <a href="mac-adressen">Mac-Adresse</a> mit dem Port abgleichen, der die <a href="mac-adressen">Mac-Adresse</a> empfängt.

Auch im folgendem Szenario will **Noah**, 10.1.1.2, will **Danyael**,10.1.1.3, anpingen.
Hier wird auch die Anfrage an alle geschickt, allerdings wird die Antwort nur an den ursprünglichen Sender geschickt.
Dadurch ist die Netzwerkauslastung geringer und die Kommunikation Sicherer.

![Switch](netzwerk/assets/video/switch.mp4)

### <h2><u><strong>Access Point</strong></u></h2>

Ein Access Point erwitert ein bereits vorhandenes Netzwerk um ein WLAN. Alternativ kann ein Access Point auch dazu eingesetzt werden, ein bereits vorhandenes WLAN zu erweitern und die Reichweite zu erhöhen.
Dafür wird der Access Point mit hilfe eines Ethernet-Kabel mit dem Netzwerk verbunden.
In diesem Szenario will **Noah**,10.1.1.2, **Emma**,10.1.1.6, kontaktieren.

Bis hierher war nie eine Verbindung mit dem Internet nötig, aber was ist wenn wir ausßerhalb unseres Localen Netzwerkes verbindungen herstellen möchten?

![Access Point](netzwerk/assets/video/ap.mp4)

Dafür benötigen wir unteranderem Router und Firewalls.

### <h2><u><strong>Router</strong></u></h2>

Ein Router ermöglicht die kommunikation zwischen unterschidlichen Netzwerken,dabei besitz er mehrere Schnittstellen, zu den unterschiedlichen Netzwerken, und nimmt Datenpakete über diese in Empfang. Anschließend wertet er die Netzwerkadressen der Pakete aus und fällt anhand dieser die Entscheidung, über welche Schnittstelle er das Paket weiterleitet. Für die Entscheidungsfindung nutzt er seine lokale **Routing-Tabelle**.
Eine Routing Table enthält Einträge, die den Weg zu einem bestimmten Ziel im Netz beschreiben. Die Tabelle kann durch statische oder dynamisch gelernte Einträge gefüllt werden. Routingprotokolle sorgen für den automatischen Austausch von Routing-Informationen zwischen Routern.

Im folgenden Szenario lassen wir **Danyael **aus **Noahs** Netzwerk mit **Finns PC** aus **Finns** Netzwerk kommunizieren.

![Router](netzwerk/assets/video/router.mp4)

### <h2><u><strong>Firewall</strong></u></h2>

Auf der Firewall lassen sich gewisse Sicherheitsregeln definieren, zum Beispiel alles was aus der IP-Range von Nord Korea (176.0.0.0/24) kommt gelangt nicht weiter zu unseren Geräten im Netzwerk.
Vereinfacht gesagt trennt die Firewall das eigene Netzwerk von der Außenwelt ab.
`
const cNwDns = `
## <h1><u><strong>DNS</strong></u></h1>

Das **D**omain **N**ame **S**ystem sorgt dafür, dass man IP-Adressen über einen Namen finden kann.
Es gibt vier unsterschidliche DNS Server.

1. Recursor
2. Root
3. TLD
4. Autoritativer

### Rescusive resolve

Bei einem recursor DNS-Lookup kommuniziert ein DNS-Server mit mehreren anderen DNS-Servern, um eine IP-Adresse zu finden und an den Client zurückzugeben

### Root

Der Root Server ist dafür verantwortlich, dass die Zuordnung von IP-Adressen zu Webadressen erfolgreich abläuft. Hier übernimmt der Root Server im Prinzip nur die Verwaltung einer einzigen Datei. Darin enthalten sind alle Informationen, die nötig sind, damit das Domain Name System (DNS) funktioniert.

### TLD

Ein **T**op **L**evel **D**omain-Nameserver verwaltet die Informationen zu allen Domain-Namen, die über die gleiche Erweiterung verfügen, z. B. .com, .net oder eine andere Zeichenfolge nach dem letzten Punkt in einer URL. Beispielsweise enthält ein TLD-Nameserver für .com Informationen zu allen Websites, die auf „.com“ enden. Wenn ein Benutzer nach google.com sucht, sendet der rekursive Resolver, nachdem er eine Antwort von einem Stamm-Nameserver erhalten hat, eine Abfrage an einen für .com zuständigen TLD-Nameserver.

### Authoritative

Wenn ein rekursiver Resolver eine Antwort von einem TLD-Nameserver erhält, verweist ihn die Antwort auf einen autoritativen Nameserver. Der autoritative Nameserver ist für gewöhnlich die letzte Station auf der Reise zu einer IP-Adresse. Der autoritative Nameserver enthält Informationen, die dem Domainnamen (z. B. google.com), für den er zuständig ist, eigen sind. Er kann einem rekursiven Resolver die IP-Adresse des Servers, die im DNS-A-Eintrag enthalten ist, liefern. Wenn die Domain über einen CNAME-Eintrag (Alias) verfügt, stellt er dem rekursiven Resolver eine Alias-Domain bereit. In diesem Fall muss der rekursive Resolver einen ganz neuen DNS-Lookup starten, um sich einen Eintrag von einem autoritativen Nameserver zu beschaffen

![DNS](assets/img/dns.png)
`
const cNwNtp = `
## <h1><u><strong>NTP</strong></u></h1>

**N**etwork **T**ime **P**rotocol ist eines der elementaren Protokolle der Netzwerkkommunikation. Bei der Übertragung funktioniert das Protokoll als Zeitstempel und synchronisiert Uhrzeiten verschiedener Systeme bis auf die Nanosekunde genau.
Eine genaue Zeiterfassung ist sehr nützlich für die Planung von Backups bis hin zur Überwachung von Sicherheitsverletzungen im Computer-Netzwerk.
`
const cNwProxy = `
## <h1><u><strong>Proxy</strong></u></h1>

Ein Proxy ist ein Vermittler innerhalb eines Netzwerks. Er wird zwischen Nutzer und Netzwerk-Ressource geschaltet. Proxys nehmen eine Stellvertreterrolle ein, mit der zum Beispiel die eigene Identität verschleiert wird.
Proxy-Server stellen die Grundlage für Phishing-Angriffe. Nutzerdaten werden über Phishing-Webseiten abgefangen. Diese sehen aus wie die originalen Webseiten, kommunizieren jedoch über den Proxy mit den Servern der Hacker.
Eine bessere alternative um die persönlichen Daten im Internet zu schützen sind VPN oder Tor-Browser

![proxy](assets/img/proxy.png){height=150px}

### Bandbreitenkontrolle

Der Proxy teilt verschiedenen Benutzern und Gruppen je nach Auslastung unterschiedliche Ressourcen zu. Der Proxy-Server Squid beherrscht dieses Verfahren, wobei er ebenso zum Schutz des Servers beitragen kann und Methoden unterstützt, die zu besserer Verfügbarkeit beitragen.

### Aufbereitung von Daten

Proxy-Server können auch gewisse Applikationsfunktionen übernehmen, beispielsweise Daten in ein standardisiertes Format bringen.

### Protokollierung

Viele Proxys erlauben es, Verbindungen, die über sie laufen, zu protokollieren. Das ermöglicht statistische Auswertungen und Erkennen ungewollter Verbindungen.

### Proxy als Anonymisierungsdienst

Der Anonymisierungs-Proxy (z. B. Anonymizer, Tor) leitet die Daten des Clients zum Server weiter, wodurch der Server die IP-Adresse des Clients nicht mehr direkt auslesen kann. Sie werden verwendet, um die Herkunft eines Clients zu verschleiern. So können Internetnutzer versuchen, sich vor Kontrolle zu schützen. In einem anderen Szenarium werden Proxys angeboten – teils frei verfügbar – bei denen man unter der URL des Proxys beliebige Webseiten anfordern kann. Diese Proxys können dazu verwendet werden, um beispielsweise Einschränkungen von Firmen- oder Schulnetzen zu umgehen (manchmal werden allerdings diese, wenn es der Betreiber merkt, gesperrt). Sie sind insofern anonym, als der Zielserver nur die URL des Anbieters sieht.

### Schutz der Clients

Der Proxy kann eine Schnittstelle zwischen dem privaten Netz und dem öffentlichen Netz bilden. Die Clients des privaten Netzes greifen so über den Proxy beispielsweise auf Webserver des öffentlichen Netzes zu. Da das kontaktierte Zielsystem aus dem öffentlichen Netz seine Antwortpakete nicht direkt an den Client schickt, sondern an den Proxy sendet, kann dieser die Verbindung aktiv kontrollieren. Ein unerwünschter Fernzugriff auf den Client (der über die Antwortpakete hinausgeht) wird somit unterbunden oder wenigstens erschwert. Entsprechende Sicherungsmaßnahmen und deren fortwährende Kontrolle beschränken sich so auf einen einzelnen oder einige wenige Proxys, statt auf eine Vielzahl von Clients. Sie lassen sich in einem vorgeschalteten Bastionsnetz auch einfacher und zuverlässiger realisieren. So sind zusätzlich eigene Server besser geschützt, die selbst keinen Zugriff auf das Internet benötigen, aber im selben Segment stehen, wie die durch den Proxy abgeschirmten Clients. Damit bleibt das interne Netz auch bei einer Kompromittierung des Proxys zunächst geschützt und verschafft so der IT-Abteilung zusätzliche Zeit für geeignete Reaktionen auf einen eventuellen Angriff von außen.

### Schutz der Server

Ein Proxyserver kann allgemein dazu verwendet werden, den eigentlichen Server in ein geschütztes Netz zu stellen, wodurch er vom externen Netz aus nur durch den Proxy erreichbar wird. Auf diese Weise versucht man den Server vor Angriffen zu schützen. Die Proxy-Software ist weniger komplex und bietet daher weniger Angriffspunkte. Diese Lösung wird zum Beispiel bei Online-Shops angewendet: Der Webserver befindet sich samt Proxy im Internet und greift auf die Datenbank mit Kundendaten hinter einer Firewall zu.
`

export const lNw = new Lesson('Netzwerk', 'text')
    .addCategory(networkCategory)
    .addContent({
        id: 'network:einleitung',
        index: 1,
        content: cNwIntro
    })
    .addContent({
        id: 'network:topology',
        index: 2,
        content: cNwTopology
    })
    .addContent({
        id: 'network:osi',
        index: 3,
        content: cNwOsi
    })
    .addContent({
        id: 'network:mac',
        index: 4,
        content: cNwMac
    })
    .addContent({
        id: 'network:darstellungen',
        index: 5,
        content: cNwDarstellungen
    })
    .addContent({
        id: 'network:tcp_ip',
        index: 6,
        content: cNwTcpIp
    })
    .addContent({
        id: 'network:vpn',
        index: 7,
        content: cNwVpn
    })
    .addContent({
        id: 'network:devices',
        index: 8,
        content: cNwDevices
    })
    .addContent({
        id: 'network:dns',
        index: 9,
        content: cNwDns
    })
    .addContent({
        id: 'network:dns',
        index: 10,
        content: cNwNtp
    })
    .addContent({
        id: 'network:proxy',
        index: 11,
        content: cNwProxy
    })

