import React, { SVGProps } from 'react'

export default function CropRotateBl(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 14V17C4 19.2091 5.79086 21 8 21H12'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M1.5 16.5L4 14L6.5 16.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20 11L20 5C20 4.44772 19.5523 4 19 4L13 4'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 4H10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 16V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 2L10 13C10 13.5523 10.4477 14 11 14L22 14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
