import React, { SVGProps } from 'react'

export default function Nintendonetwork(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Nintendo Network</title>
      <path d='M19.9645 8.6861a.645.645 0 0 1-.5193-.2664c-2.1109-2.893-5.5554-4.624-9.2154-4.624a11.4744 11.4744 0 0 0-4.6887.9864.6413.6413 0 0 1-.8493-.3375.6563.6563 0 0 1 .3325-.8593 12.7795 12.7795 0 0 1 5.2055-1.0946c4.0672 0 7.9003 1.9265 10.2527 5.1544a.66.66 0 0 1-.137.9128.6326.6326 0 0 1-.381.1282M.9023 8.9788c0-.4919.411-.8892.9178-.8892h2.3313c.2104 0 .3636-.0958.5566-.2291a9.2229 9.2229 0 0 1 5.2677-1.6339c5.01 0 9.0735 3.9415 9.0735 8.802v8.0771a.9078.9078 0 0 1-.9216.8942h-2.9551a.909.909 0 0 1-.924-.8942v-8.0771c0-2.289-1.9116-4.1457-4.274-4.1457-2.3599 0-4.2715 1.8555-4.2715 4.1457v8.0771A.909.909 0 0 1 4.779 24H1.824a.9078.9078 0 0 1-.9216-.8942V8.98M4.302 2.5915a14.5479 14.5479 0 0 1 5.98-1.274c4.822 0 9.0747 2.3288 11.6488 5.8792a.6397.6397 0 0 0 .9041.1408.67.67 0 0 0 .137-.9228C20.155 2.528 15.5162 0 10.281 0a15.7907 15.7907 0 0 0-6.502 1.3873.6625.6625 0 0 0-.33.8693.6401.6401 0 0 0 .8518.3362Z' />
    </svg>
  )
}
