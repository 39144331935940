import { Lesson } from "../../../lessons"
import { storageCategory } from "../../category"

const cFestplattenverbundRaid = `
## Festplattenverbund

### Raid

Bei einem **R**edundant **A**rray of **I**ndependent **D**isc-System werden mehrere Festplatten zu einem physischen Laufwerk gebündelt.

#### Raid 0

Bei einem Raid 0 fungieren die Speichermedien als eine einziges Großes Medium, dies sorgt für eine schnelle lese und schreib funktion, allerdings wenn eine ausfällt sind alle Datem verloren.

![raid0](https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/RAID_0.svg/325px-RAID_0.svg.png)

#### Raid 1

Bei einem Raid 1 können maximal zwei Festplatten mit einander verbunden werden. Es handelt sich hierbei um einen Simplen Spiegel.
Falls eine Platte ausfällt, sind alle noch auf der anderen vorhanden.
Das ganze ist relative schreibe intensiv.

![raid1](https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/RAID_1.svg/220px-RAID_1.svg.png)

##### Raid 1+0

Bei einem Raid 1+0 werden auf der Untersten ebene Raid 1 Patitionen gebildet die alle zusammen in ein Raid 0 gebunden sind.

![raid10](https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/RAID_10.svg/800px-RAID_10.svg.png)

#### Raid 5

Ein Raid 5 besteht aus mindestens drei Festplatten. Einerseits verteilt ein solcher Verbund die zu speichernden Dateien gleichmäßig auf alle eingespannten Platten (Striping).
Außerdem berechnet ein RAID 5 für sämtliche abgelegten Nutzdaten passende <a href="#parität">Parität</a>sinformationen, die ebenfalls auf die verschiedenen Speichermedien verteilt werden. Mittels XOR-Verknüpfung ermöglicht das Speichersystem dann die Rekonstruktion verloren gegangener bzw. beschädigter Datenblöcke.

![raid5](https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/RAID_5.svg/1200px-RAID_5.svg.png)

##### Raid 5+0

Bei einem Raid 5+0 werden auf der Untersten ebene Raid 5 Patitionen gebildet die alle zusammen in ein Raid 0 gebunden sind.

![raid50](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/RAID_50_scheme.svg/1200px-RAID_50_scheme.svg.png)

#### Raid 6

RAID 6 wird oft auch als „RAID mit doppelter Parität“ bezeichnet. Das bedeutet hier werden zwei Parity-Stripes auf jeder Platte verwendet. Damit kann der Ausfall von zwei Festplatten abgefangen werden, ohne dass ein Datenverlust zu befürchten ist.

![raid6](https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/RAID_6.svg/1200px-RAID_6.svg.png)

##### Raid 6+0

Bei einem Raid 6+0 werden auf der Untersten ebene Raid 6 Patitionen gebildet die alle zusammen in ein Raid 0 gebunden sind.
![raid60](https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/RAID_60.png/510px-RAID_60.png)
`

const cFestplattenverbundSpan = `
### SPAN

Ein SPAN ist letzten endlich nichts anderes als ein Raid 0 allerdings wird hierfür kein Raid Controller verwendet.
`

const cFestplattenverbundJbod = `
### JBOD

**J**ust a **B**unch **o**f **D**isks ist ein Festplatten-Array, bei dem die Einzelkapazitäten des konfigurierten Festplattenstapels einem Server präsentiert werden, der dies als eine virtuelle Gesamtkapazität nutzt.
Mit Hilfe von Software kann der Server selbständig entscheiden wie, wo und wie häufig er Daten wo speichern will.

![jbod](https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/JBOD.svg/220px-JBOD.svg.png)
`

export const lSFestplattenverbund = new Lesson('Festplattenverbund', 'text')
    .addCategory(storageCategory)
    .addContent({
        id: 'storage:festplattenverbund:raid',
        index: 1,
        content: cFestplattenverbundRaid
    })
    .addContent({
        id: 'storage:festplattenverbund:span',
        index: 2,
        content: cFestplattenverbundSpan
    })
    .addContent({
        id: 'storage:festplattenverbund:jbod',
        index: 3,
        content: cFestplattenverbundJbod
    })