import React, { SVGProps } from 'react'

export default function CloudDesync(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 17.6073C21.4937 17.0221 23 15.6889 23 13C23 9 19.6667 8 18 8C18 6 18 2 12 2C6 2 6 6 6 8C4.33333 8 1 9 1 13C1 15.6889 2.50628 17.0221 4 17.6073'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.4203 19.4874L14.6525 21.2552C13.0904 22.8173 10.5577 22.8173 8.99564 21.2552L8.64209 20.9016'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0667 21.9623L16.4203 19.4874L13.9454 19.841L16.0667 21.9623Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.58059 16.9016L9.34836 15.1339C10.9105 13.5718 13.4431 13.5718 15.0052 15.1339L15.3588 15.4874'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.93413 14.4268L7.58058 16.9017L10.0554 16.5481L7.93413 14.4268Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
