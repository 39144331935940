import React, { SVGProps } from 'react'

export default function CropRotateTl(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 4H7C4.79086 4 3 5.79086 3 8V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 1.5L10 4L7.5 6.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20 17L20 11C20 10.4477 19.5523 10 19 10L13 10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 10H10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 22V20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 8L10 19C10 19.5523 10.4477 20 11 20L22 20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
