import AccessibilitySign from './iconoir/accessibility-sign'
import AccessibilityTech from './iconoir/accessibility-tech'
import Accessibility from './iconoir/accessibility'
import Activity from './iconoir/activity'
import AdobeAfterEffects from './iconoir/adobe-after-effects'
import AdobeIllustrator from './iconoir/adobe-illustrator'
import AdobeIndesign from './iconoir/adobe-indesign'
import AdobeLightroom from './iconoir/adobe-lightroom'
import AdobePhotoshop from './iconoir/adobe-photoshop'
import AdobeXd from './iconoir/adobe-xd'
import AfricanTree from './iconoir/african-tree'
import Agile from './iconoir/agile'
import AirConditioner from './iconoir/air-conditioner'
import AirplaneHelixFourFivedeg from './iconoir/airplane-helix-45deg'
import AirplaneHelix from './iconoir/airplane-helix'
import AirplaneOff from './iconoir/airplane-off'
import AirplaneRotation from './iconoir/airplane-rotation'
import Airplane from './iconoir/airplane'
import Airplay from './iconoir/airplay'
import Alarm from './iconoir/alarm'
import AlbumCarousel from './iconoir/album-carousel'
import AlbumList from './iconoir/album-list'
import AlbumOpen from './iconoir/album-open'
import Album from './iconoir/album'
import AlignBottomBox from './iconoir/align-bottom-box'
import AlignCenter from './iconoir/align-center'
import AlignHorizontalCenters from './iconoir/align-horizontal-centers'
import AlignHorizontalSpacing from './iconoir/align-horizontal-spacing'
import AlignJustify from './iconoir/align-justify'
import AlignLeftBox from './iconoir/align-left-box'
import AlignLeft from './iconoir/align-left'
import AlignRightBox from './iconoir/align-right-box'
import AlignRight from './iconoir/align-right'
import AlignTopBox from './iconoir/align-top-box'
import AlignVerticalCenters from './iconoir/align-vertical-centers'
import AlignVerticalSpacing from './iconoir/align-vertical-spacing'
import AngleTool from './iconoir/angle-tool'
import AntennaOff from './iconoir/antenna-off'
import AntennaSignalTag from './iconoir/antenna-signal-tag'
import AntennaSignal from './iconoir/antenna-signal'
import Antenna from './iconoir/antenna'
import AppNotification from './iconoir/app-notification'
import AppStore from './iconoir/app-store'
import AppWindow from './iconoir/app-window'
import AppleHalf from './iconoir/apple-half'
import AppleImacTwoZeroTwoOneSide from './iconoir/apple-imac-2021-side'
import AppleImacTwoZeroTwoOne from './iconoir/apple-imac-2021'
import AppleMac from './iconoir/apple-mac'
import AppleShortcuts from './iconoir/apple-shortcuts'
import AppleSwift from './iconoir/apple-swift'
import AppleWallet from './iconoir/apple-wallet'
import Apple from './iconoir/apple'
import ArTag from './iconoir/ar-tag'
import ArcThreedCenterPoint from './iconoir/arc-3d-center-point'
import ArcThreed from './iconoir/arc-3d'
import Arcade from './iconoir/arcade'
import ArcheryMatch from './iconoir/archery-match'
import Archery from './iconoir/archery'
import Archive from './iconoir/archive'
import AreaSearch from './iconoir/area-search'
import ArrowArchery from './iconoir/arrow-archery'
import ArrowDownCircle from './iconoir/arrow-down-circle'
import ArrowDownLeftCircle from './iconoir/arrow-down-left-circle'
import ArrowDownLeftSquare from './iconoir/arrow-down-left-square'
import ArrowDownLeft from './iconoir/arrow-down-left'
import ArrowDownRightCircle from './iconoir/arrow-down-right-circle'
import ArrowDownRightSquare from './iconoir/arrow-down-right-square'
import ArrowDownRight from './iconoir/arrow-down-right'
import ArrowDownTag from './iconoir/arrow-down-tag'
import ArrowDown from './iconoir/arrow-down'
import ArrowEmailForward from './iconoir/arrow-email-forward'
import ArrowEnlargeTag from './iconoir/arrow-enlarge-tag'
import ArrowLeftCircle from './iconoir/arrow-left-circle'
import ArrowLeftTag from './iconoir/arrow-left-tag'
import ArrowLeft from './iconoir/arrow-left'
import ArrowReduceTag from './iconoir/arrow-reduce-tag'
import ArrowRightCircle from './iconoir/arrow-right-circle'
import ArrowRightTag from './iconoir/arrow-right-tag'
import ArrowRight from './iconoir/arrow-right'
import ArrowSeparateVertical from './iconoir/arrow-separate-vertical'
import ArrowSeparate from './iconoir/arrow-separate'
import ArrowUnionVertical from './iconoir/arrow-union-vertical'
import ArrowUnion from './iconoir/arrow-union'
import ArrowUpCircle from './iconoir/arrow-up-circle'
import ArrowUpLeftCircle from './iconoir/arrow-up-left-circle'
import ArrowUpLeftSquare from './iconoir/arrow-up-left-square'
import ArrowUpLeft from './iconoir/arrow-up-left'
import ArrowUpRightCircle from './iconoir/arrow-up-right-circle'
import ArrowUpRightSquare from './iconoir/arrow-up-right-square'
import ArrowUpRight from './iconoir/arrow-up-right'
import ArrowUpTag from './iconoir/arrow-up-tag'
import ArrowUp from './iconoir/arrow-up'
import ArrowsUpFromLine from './iconoir/arrows-up-from-line'
import Asana from './iconoir/asana'
import Asterisk from './iconoir/asterisk'
import AtSignCircle from './iconoir/at-sign-circle'
import AtSign from './iconoir/at-sign'
import Atom from './iconoir/atom'
import Attachment from './iconoir/attachment'
import AugmentedReality from './iconoir/augmented-reality'
import AutoFlash from './iconoir/auto-flash'
import AviFormat from './iconoir/avi-format'
import Axes from './iconoir/axes'
import BackwardOneFiveSeconds from './iconoir/backward-15-seconds'
import BadgeCheck from './iconoir/badge-check'
import Bag from './iconoir/bag'
import Balcony from './iconoir/balcony'
import Bank from './iconoir/bank'
import Barcode from './iconoir/barcode'
import BasketballField from './iconoir/basketball-field'
import Basketball from './iconoir/basketball'
import Bathroom from './iconoir/bathroom'
import BatteryTwoFive from './iconoir/battery-25'
import BatteryFiveZero from './iconoir/battery-50'
import BatterySevenFive from './iconoir/battery-75'
import BatteryCharging from './iconoir/battery-charging'
import BatteryEmpty from './iconoir/battery-empty'
import BatteryFull from './iconoir/battery-full'
import BatteryIndicator from './iconoir/battery-indicator'
import BatterySlash from './iconoir/battery-slash'
import BatteryWarning from './iconoir/battery-warning'
import Bbq from './iconoir/bbq'
import BeachBag from './iconoir/beach-bag'
import BedReady from './iconoir/bed-ready'
import Bed from './iconoir/bed'
import BehanceTag from './iconoir/behance-tag'
import Behance from './iconoir/behance'
import BellNotification from './iconoir/bell-notification'
import BellOff from './iconoir/bell-off'
import Bell from './iconoir/bell'
import Bicycle from './iconoir/bicycle'
import BinFull from './iconoir/bin-full'
import BinHalf from './iconoir/bin-half'
import BinMinusIn from './iconoir/bin-minus-in'
import BinPlusIn from './iconoir/bin-plus-in'
import Bin from './iconoir/bin'
import Binocular from './iconoir/binocular'
import BirthdayCake from './iconoir/birthday-cake'
import Bishop from './iconoir/bishop'
import Bitbucket from './iconoir/bitbucket'
import BitcoinCircle from './iconoir/bitcoin-circle'
import BitcoinRotateOut from './iconoir/bitcoin-rotate-out'
import BluetoothTag from './iconoir/bluetooth-tag'
import Bluetooth from './iconoir/bluetooth'
import BoldSquare from './iconoir/bold-square'
import Bold from './iconoir/bold'
import Bonfire from './iconoir/bonfire'
import BookLock from './iconoir/book-lock'
import BookStack from './iconoir/book-stack'
import Book from './iconoir/book'
import BookmarkBook from './iconoir/bookmark-book'
import BookmarkCircle from './iconoir/bookmark-circle'
import Bookmark from './iconoir/bookmark'
import BorderBl from './iconoir/border-bl'
import BorderBottom from './iconoir/border-bottom'
import BorderBr from './iconoir/border-br'
import BorderInner from './iconoir/border-inner'
import BorderLeft from './iconoir/border-left'
import BorderOut from './iconoir/border-out'
import BorderRight from './iconoir/border-right'
import BorderTl from './iconoir/border-tl'
import BorderTop from './iconoir/border-top'
import BorderTr from './iconoir/border-tr'
import BounceLeft from './iconoir/bounce-left'
import BounceRight from './iconoir/bounce-right'
import BowlingBall from './iconoir/bowling-ball'
import BoxThreedCenter from './iconoir/box-3d-center'
import BoxThreedPoint from './iconoir/box-3d-point'
import BoxThreedThreePoints from './iconoir/box-3d-three-points'
import BoxIso from './iconoir/box-iso'
import Box from './iconoir/box'
import BoxingGlove from './iconoir/boxing-glove'
import BrainElectricity from './iconoir/brain-electricity'
import BrainResearch from './iconoir/brain-research'
import BrainWarning from './iconoir/brain-warning'
import Brain from './iconoir/brain'
import BreadSlice from './iconoir/bread-slice'
import BridgeThreed from './iconoir/bridge-3d'
import BridgeSurface from './iconoir/bridge-surface'
import BrightCrown from './iconoir/bright-crown'
import BrightStar from './iconoir/bright-star'
import BrightnessWindow from './iconoir/brightness-window'
import Brightness from './iconoir/brightness'
import BubbleDownload from './iconoir/bubble-download'
import BubbleIncome from './iconoir/bubble-income'
import BubbleOutcome from './iconoir/bubble-outcome'
import BubbleSearch from './iconoir/bubble-search'
import BubbleStar from './iconoir/bubble-star'
import BubbleUpload from './iconoir/bubble-upload'
import BubbleWarning from './iconoir/bubble-warning'
import BubbleXmark from './iconoir/bubble-xmark'
import Building from './iconoir/building'
import BusGreen from './iconoir/bus-green'
import BusStop from './iconoir/bus-stop'
import Bus from './iconoir/bus'
import CSquare from './iconoir/c-square'
import CableTag from './iconoir/cable-tag'
import Calculator from './iconoir/calculator'
import CalendarMinus from './iconoir/calendar-minus'
import CalendarPlus from './iconoir/calendar-plus'
import Calendar from './iconoir/calendar'
import Camera from './iconoir/camera'
import CandlestickChart from './iconoir/candlestick-chart'
import Car from './iconoir/car'
import CardLock from './iconoir/card-lock'
import CardNoAccess from './iconoir/card-no-access'
import CardReader from './iconoir/card-reader'
import CardShield from './iconoir/card-shield'
import CardWallet from './iconoir/card-wallet'
import CartAlt from './iconoir/cart-alt'
import CartMinus from './iconoir/cart-minus'
import CartPlus from './iconoir/cart-plus'
import Cart from './iconoir/cart'
import Cash from './iconoir/cash'
import CellTwoxTwo from './iconoir/cell-2x2'
import Cellar from './iconoir/cellar'
import CenterAlign from './iconoir/center-align'
import ChatBubbleCheck from './iconoir/chat-bubble-check'
import ChatBubbleEmpty from './iconoir/chat-bubble-empty'
import ChatBubbleQuestion from './iconoir/chat-bubble-question'
import ChatBubbleTranslate from './iconoir/chat-bubble-translate'
import ChatBubbleWarning from './iconoir/chat-bubble-warning'
import ChatBubbleXmark from './iconoir/chat-bubble-xmark'
import ChatBubble from './iconoir/chat-bubble'
import ChatLines from './iconoir/chat-lines'
import ChatMinusIn from './iconoir/chat-minus-in'
import ChatPlusIn from './iconoir/chat-plus-in'
import CheckCircle from './iconoir/check-circle'
import Check from './iconoir/check'
import Chocolate from './iconoir/chocolate'
import ChromecastActive from './iconoir/chromecast-active'
import Chromecast from './iconoir/chromecast'
import ChurchSide from './iconoir/church-side'
import Church from './iconoir/church'
import CigaretteSlash from './iconoir/cigarette-slash'
import CinemaOld from './iconoir/cinema-old'
import CircleSpark from './iconoir/circle-spark'
import Circle from './iconoir/circle'
import City from './iconoir/city'
import ClipboardCheck from './iconoir/clipboard-check'
import ClockRotateRight from './iconoir/clock-rotate-right'
import Clock from './iconoir/clock'
import ClosedCaptionsTag from './iconoir/closed-captions-tag'
import Closet from './iconoir/closet'
import CloudBookmark from './iconoir/cloud-bookmark'
import CloudCheck from './iconoir/cloud-check'
import CloudDesync from './iconoir/cloud-desync'
import CloudDownload from './iconoir/cloud-download'
import CloudSquare from './iconoir/cloud-square'
import CloudSunny from './iconoir/cloud-sunny'
import CloudSync from './iconoir/cloud-sync'
import CloudUpload from './iconoir/cloud-upload'
import CloudXmark from './iconoir/cloud-xmark'
import Cloud from './iconoir/cloud'
import CodeBracketsSquare from './iconoir/code-brackets-square'
import CodeBrackets from './iconoir/code-brackets'
import Code from './iconoir/code'
import Codepen from './iconoir/codepen'
import CoffeeCup from './iconoir/coffee-cup'
import CoinSlash from './iconoir/coin-slash'
import CoinsSwap from './iconoir/coins-swap'
import Coins from './iconoir/coins'
import CollageFrame from './iconoir/collage-frame'
import Collapse from './iconoir/collapse'
import ColorFilter from './iconoir/color-filter'
import ColorPicker from './iconoir/color-picker'
import ColorWheel from './iconoir/color-wheel'
import Combine from './iconoir/combine'
import Commodity from './iconoir/commodity'
import Community from './iconoir/community'
import CompAlignBottom from './iconoir/comp-align-bottom'
import CompAlignLeft from './iconoir/comp-align-left'
import CompAlignRight from './iconoir/comp-align-right'
import CompAlignTop from './iconoir/comp-align-top'
import CompactDisc from './iconoir/compact-disc'
import Compass from './iconoir/compass'
import Component from './iconoir/component'
import CompressLines from './iconoir/compress-lines'
import Compress from './iconoir/compress'
import Computer from './iconoir/computer'
import ConstrainedSurface from './iconoir/constrained-surface'
import Consumable from './iconoir/consumable'
import Contactless from './iconoir/contactless'
import ControlSlider from './iconoir/control-slider'
import Cookie from './iconoir/cookie'
import CoolingSquare from './iconoir/cooling-square'
import Copy from './iconoir/copy'
import Copyright from './iconoir/copyright'
import CornerBottomLeft from './iconoir/corner-bottom-left'
import CornerBottomRight from './iconoir/corner-bottom-right'
import CornerTopLeft from './iconoir/corner-top-left'
import CornerTopRight from './iconoir/corner-top-right'
import CpuWarning from './iconoir/cpu-warning'
import Cpu from './iconoir/cpu'
import CrackedEgg from './iconoir/cracked-egg'
import CreativeCommons from './iconoir/creative-commons'
import CreditCardSlash from './iconoir/credit-card-slash'
import CreditCard from './iconoir/credit-card'
import CreditCards from './iconoir/credit-cards'
import Crib from './iconoir/crib'
import CropRotateBl from './iconoir/crop-rotate-bl'
import CropRotateBr from './iconoir/crop-rotate-br'
import CropRotateTl from './iconoir/crop-rotate-tl'
import CropRotateTr from './iconoir/crop-rotate-tr'
import Crop from './iconoir/crop'
import CrownCircle from './iconoir/crown-circle'
import Crown from './iconoir/crown'
import CssThree from './iconoir/css3'
import CubeBandage from './iconoir/cube-bandage'
import CubeCutWithCurve from './iconoir/cube-cut-with-curve'
import CubeHole from './iconoir/cube-hole'
import CubeReplaceFace from './iconoir/cube-replace-face'
import Cube from './iconoir/cube'
import CursorPointer from './iconoir/cursor-pointer'
import CurveArray from './iconoir/curve-array'
import Cut from './iconoir/cut'
import Cutlery from './iconoir/cutlery'
import Cycling from './iconoir/cycling'
import Cylinder from './iconoir/cylinder'
import DashFlag from './iconoir/dash-flag'
import DashboardDots from './iconoir/dashboard-dots'
import DashboardSpeed from './iconoir/dashboard-speed'
import Dashboard from './iconoir/dashboard'
import DataTransferBoth from './iconoir/data-transfer-both'
import DataTransferCheck from './iconoir/data-transfer-check'
import DataTransferDown from './iconoir/data-transfer-down'
import DataTransferUp from './iconoir/data-transfer-up'
import DataTransferWarning from './iconoir/data-transfer-warning'
import DatabaseBackup from './iconoir/database-backup'
import DatabaseCheck from './iconoir/database-check'
import DatabaseExport from './iconoir/database-export'
import DatabaseMonitor from './iconoir/database-monitor'
import DatabaseRestore from './iconoir/database-restore'
import DatabaseScriptMinus from './iconoir/database-script-minus'
import DatabaseScriptPlus from './iconoir/database-script-plus'
import DatabaseScript from './iconoir/database-script'
import DatabaseSearch from './iconoir/database-search'
import DatabaseSettings from './iconoir/database-settings'
import DatabaseStar from './iconoir/database-star'
import DatabaseStats from './iconoir/database-stats'
import DatabaseTag from './iconoir/database-tag'
import DatabaseWarning from './iconoir/database-warning'
import DatabaseXmark from './iconoir/database-xmark'
import Database from './iconoir/database'
import DeCompress from './iconoir/de-compress'
import DeliveryTruck from './iconoir/delivery-truck'
import Delivery from './iconoir/delivery'
import Depth from './iconoir/depth'
import DesignNib from './iconoir/design-nib'
import DesignPencil from './iconoir/design-pencil'
import Desk from './iconoir/desk'
import Developer from './iconoir/developer'
import DewPoint from './iconoir/dew-point'
import Dialpad from './iconoir/dialpad'
import Diameter from './iconoir/diameter'
import DiceFive from './iconoir/dice-five'
import DiceFour from './iconoir/dice-four'
import DiceOne from './iconoir/dice-one'
import DiceSix from './iconoir/dice-six'
import DiceThree from './iconoir/dice-three'
import DiceTwo from './iconoir/dice-two'
import DimmerSwitch from './iconoir/dimmer-switch'
import DirectorChair from './iconoir/director-chair'
import Discord from './iconoir/discord'
import Dishwasher from './iconoir/dishwasher'
import DisplayFourk from './iconoir/display-4k'
import DivideThree from './iconoir/divide-three'
import Divide from './iconoir/divide'
import Dna from './iconoir/dna'
import Dns from './iconoir/dns'
import DocMagnifyingGlassIn from './iconoir/doc-magnifying-glass-in'
import DocMagnifyingGlass from './iconoir/doc-magnifying-glass'
import DocStarIn from './iconoir/doc-star-in'
import DocStar from './iconoir/doc-star'
import DogecoinCircle from './iconoir/dogecoin-circle'
import DogecoinRotateOut from './iconoir/dogecoin-rotate-out'
import DollarCircle from './iconoir/dollar-circle'
import Dollar from './iconoir/dollar'
import DomoticWarning from './iconoir/domotic-warning'
import Donate from './iconoir/donate'
import DotArrowDown from './iconoir/dot-arrow-down'
import DotArrowLeft from './iconoir/dot-arrow-left'
import DotArrowRight from './iconoir/dot-arrow-right'
import DotArrowUp from './iconoir/dot-arrow-up'
import DoubleCheck from './iconoir/double-check'
import DownloadCircle from './iconoir/download-circle'
import DownloadDataWindow from './iconoir/download-data-window'
import DownloadSquare from './iconoir/download-square'
import Download from './iconoir/download'
import DragHandGesture from './iconoir/drag-hand-gesture'
import Drag from './iconoir/drag'
import Drawer from './iconoir/drawer'
import Dribbble from './iconoir/dribbble'
import DroneChargeFull from './iconoir/drone-charge-full'
import DroneChargeHalf from './iconoir/drone-charge-half'
import DroneChargeLow from './iconoir/drone-charge-low'
import DroneCheck from './iconoir/drone-check'
import DroneLanding from './iconoir/drone-landing'
import DroneRefresh from './iconoir/drone-refresh'
import DroneTakeOff from './iconoir/drone-take-off'
import DroneXmark from './iconoir/drone-xmark'
import Drone from './iconoir/drone'
import DropletCheck from './iconoir/droplet-check'
import DropletHalf from './iconoir/droplet-half'
import Droplet from './iconoir/droplet'
import EaseCurveControlPoints from './iconoir/ease-curve-control-points'
import EaseInControlPoint from './iconoir/ease-in-control-point'
import EaseInOut from './iconoir/ease-in-out'
import EaseIn from './iconoir/ease-in'
import EaseOutControlPoint from './iconoir/ease-out-control-point'
import EaseOut from './iconoir/ease-out'
import EcologyBook from './iconoir/ecology-book'
import EditPencil from './iconoir/edit-pencil'
import Edit from './iconoir/edit'
import Egg from './iconoir/egg'
import Eject from './iconoir/eject'
import ElectronicsChip from './iconoir/electronics-chip'
import ElectronicsTransistor from './iconoir/electronics-transistor'
import Elevator from './iconoir/elevator'
import EllipseThreedThreePoints from './iconoir/ellipse-3d-three-points'
import EllipseThreed from './iconoir/ellipse-3d'
import EmojiBall from './iconoir/emoji-ball'
import EmojiBlinkLeft from './iconoir/emoji-blink-left'
import EmojiBlinkRight from './iconoir/emoji-blink-right'
import EmojiLookDown from './iconoir/emoji-look-down'
import EmojiLookLeft from './iconoir/emoji-look-left'
import EmojiLookRight from './iconoir/emoji-look-right'
import EmojiLookUp from './iconoir/emoji-look-up'
import EmojiPuzzled from './iconoir/emoji-puzzled'
import EmojiQuite from './iconoir/emoji-quite'
import EmojiReally from './iconoir/emoji-really'
import EmojiSad from './iconoir/emoji-sad'
import EmojiSatisfied from './iconoir/emoji-satisfied'
import EmojiSingLeftNote from './iconoir/emoji-sing-left-note'
import EmojiSingLeft from './iconoir/emoji-sing-left'
import EmojiSingRightNote from './iconoir/emoji-sing-right-note'
import EmojiSingRight from './iconoir/emoji-sing-right'
import EmojiSurpriseAlt from './iconoir/emoji-surprise-alt'
import EmojiSurprise from './iconoir/emoji-surprise'
import EmojiTalkingAngry from './iconoir/emoji-talking-angry'
import EmojiTalkingHappy from './iconoir/emoji-talking-happy'
import EmojiThinkLeft from './iconoir/emoji-think-left'
import EmojiThinkRight from './iconoir/emoji-think-right'
import Emoji from './iconoir/emoji'
import EmptyPage from './iconoir/empty-page'
import EnergyUsageWindow from './iconoir/energy-usage-window'
import Enlarge from './iconoir/enlarge'
import Erase from './iconoir/erase'
import EthereumCircle from './iconoir/ethereum-circle'
import EthereumRotateOut from './iconoir/ethereum-rotate-out'
import EuroSquare from './iconoir/euro-square'
import Euro from './iconoir/euro'
import EvChargeAlt from './iconoir/ev-charge-alt'
import EvCharge from './iconoir/ev-charge'
import EvPlugCharging from './iconoir/ev-plug-charging'
import EvPlugXmark from './iconoir/ev-plug-xmark'
import EvPlug from './iconoir/ev-plug'
import EvStation from './iconoir/ev-station'
import EvTag from './iconoir/ev-tag'
import Exclude from './iconoir/exclude'
import ExpandLines from './iconoir/expand-lines'
import Expand from './iconoir/expand'
import Extrude from './iconoir/extrude'
import EyeClosed from './iconoir/eye-closed'
import Eye from './iconoir/eye'
import FSquare from './iconoir/f-square'
import FaceThreedDraft from './iconoir/face-3d-draft'
import FaceId from './iconoir/face-id'
import FacebookTag from './iconoir/facebook-tag'
import Facebook from './iconoir/facebook'
import Facetime from './iconoir/facetime'
import Farm from './iconoir/farm'
import FastArrowDownSquare from './iconoir/fast-arrow-down-square'
import FastArrowDown from './iconoir/fast-arrow-down'
import FastArrowLeftSquare from './iconoir/fast-arrow-left-square'
import FastArrowLeft from './iconoir/fast-arrow-left'
import FastArrowRightSquare from './iconoir/fast-arrow-right-square'
import FastArrowRight from './iconoir/fast-arrow-right'
import FastArrowUpSquare from './iconoir/fast-arrow-up-square'
import FastArrowUp from './iconoir/fast-arrow-up'
import FastDownCircle from './iconoir/fast-down-circle'
import FastLeftCircle from './iconoir/fast-left-circle'
import FastRightCircle from './iconoir/fast-right-circle'
import FastUpCircle from './iconoir/fast-up-circle'
import FavouriteBook from './iconoir/favourite-book'
import FavouriteWindow from './iconoir/favourite-window'
import Female from './iconoir/female'
import Figma from './iconoir/figma'
import FileNotFound from './iconoir/file-not-found'
import FillColor from './iconoir/fill-color'
import FilletThreed from './iconoir/fillet-3d'
import FilterAlt from './iconoir/filter-alt'
import FilterListCircle from './iconoir/filter-list-circle'
import FilterList from './iconoir/filter-list'
import Filter from './iconoir/filter'
import Finder from './iconoir/finder'
import FingerprintCheckCircle from './iconoir/fingerprint-check-circle'
import FingerprintCircle from './iconoir/fingerprint-circle'
import FingerprintLockCircle from './iconoir/fingerprint-lock-circle'
import FingerprintScan from './iconoir/fingerprint-scan'
import FingerprintSquare from './iconoir/fingerprint-square'
import FingerprintWindow from './iconoir/fingerprint-window'
import FingerprintXmarkCircle from './iconoir/fingerprint-xmark-circle'
import Fingerprint from './iconoir/fingerprint'
import FireFlame from './iconoir/fire-flame'
import Fish from './iconoir/fish'
import Fishing from './iconoir/fishing'
import Flare from './iconoir/flare'
import FlashOff from './iconoir/flash-off'
import Flash from './iconoir/flash'
import Flask from './iconoir/flask'
import FlipReverse from './iconoir/flip-reverse'
import Flip from './iconoir/flip'
import FloppyDiskArrowIn from './iconoir/floppy-disk-arrow-in'
import FloppyDiskArrowOut from './iconoir/floppy-disk-arrow-out'
import FloppyDisk from './iconoir/floppy-disk'
import Flower from './iconoir/flower'
import Fog from './iconoir/fog'
import FolderMinus from './iconoir/folder-minus'
import FolderPlus from './iconoir/folder-plus'
import FolderSettings from './iconoir/folder-settings'
import FolderWarning from './iconoir/folder-warning'
import Folder from './iconoir/folder'
import FontQuestion from './iconoir/font-question'
import FootballBall from './iconoir/football-ball'
import Football from './iconoir/football'
import ForwardOneFiveSeconds from './iconoir/forward-15-seconds'
import ForwardMessage from './iconoir/forward-message'
import Forward from './iconoir/forward'
import FrameAltEmpty from './iconoir/frame-alt-empty'
import FrameAlt from './iconoir/frame-alt'
import FrameMinusIn from './iconoir/frame-minus-in'
import FramePlusIn from './iconoir/frame-plus-in'
import FrameSelect from './iconoir/frame-select'
import FrameSimple from './iconoir/frame-simple'
import FrameTool from './iconoir/frame-tool'
import Frame from './iconoir/frame'
import Fridge from './iconoir/fridge'
import FxTag from './iconoir/fx-tag'
import Fx from './iconoir/fx'
import Gamepad from './iconoir/gamepad'
import Garage from './iconoir/garage'
import GasTankDroplet from './iconoir/gas-tank-droplet'
import GasTank from './iconoir/gas-tank'
import Gas from './iconoir/gas'
import GifFormat from './iconoir/gif-format'
import Gift from './iconoir/gift'
import GitBranch from './iconoir/git-branch'
import GitCherryPickCommit from './iconoir/git-cherry-pick-commit'
import GitCommit from './iconoir/git-commit'
import GitCompare from './iconoir/git-compare'
import GitFork from './iconoir/git-fork'
import GitMerge from './iconoir/git-merge'
import GitPullRequestClosed from './iconoir/git-pull-request-closed'
import GitPullRequest from './iconoir/git-pull-request'
import GithubCircle from './iconoir/github-circle'
import Github from './iconoir/github'
import GitlabFull from './iconoir/gitlab-full'
import GlassEmpty from './iconoir/glass-empty'
import GlassFragile from './iconoir/glass-fragile'
import GlassHalfAlt from './iconoir/glass-half-alt'
import GlassHalf from './iconoir/glass-half'
import Glasses from './iconoir/glasses'
import Globe from './iconoir/globe'
import Golf from './iconoir/golf'
import GoogleCircle from './iconoir/google-circle'
import GoogleDocs from './iconoir/google-docs'
import GoogleDriveCheck from './iconoir/google-drive-check'
import GoogleDriveSync from './iconoir/google-drive-sync'
import GoogleDriveWarning from './iconoir/google-drive-warning'
import GoogleDrive from './iconoir/google-drive'
import GoogleHome from './iconoir/google-home'
import GoogleOne from './iconoir/google-one'
import Google from './iconoir/google'
import Gps from './iconoir/gps'
import GraduationCap from './iconoir/graduation-cap'
import GraphDown from './iconoir/graph-down'
import GraphUp from './iconoir/graph-up'
import GridMinus from './iconoir/grid-minus'
import GridPlus from './iconoir/grid-plus'
import GridXmark from './iconoir/grid-xmark'
import Group from './iconoir/group'
import Gym from './iconoir/gym'
import HSquare from './iconoir/h-square'
import HalfCookie from './iconoir/half-cookie'
import HalfMoon from './iconoir/half-moon'
import Hammer from './iconoir/hammer'
import HandBrake from './iconoir/hand-brake'
import HandCard from './iconoir/hand-card'
import HandCash from './iconoir/hand-cash'
import HandContactless from './iconoir/hand-contactless'
import Handbag from './iconoir/handbag'
import HardDrive from './iconoir/hard-drive'
import Hashtag from './iconoir/hashtag'
import Hat from './iconoir/hat'
import HdDisplay from './iconoir/hd-display'
import Hd from './iconoir/hd'
import Hdr from './iconoir/hdr'
import HeadsetBolt from './iconoir/headset-bolt'
import HeadsetHelp from './iconoir/headset-help'
import HeadsetWarning from './iconoir/headset-warning'
import Headset from './iconoir/headset'
import HealthShield from './iconoir/health-shield'
import Healthcare from './iconoir/healthcare'
import HeartArrowDown from './iconoir/heart-arrow-down'
import Heart from './iconoir/heart'
import HeatingSquare from './iconoir/heating-square'
import HeavyRain from './iconoir/heavy-rain'
import HelpCircle from './iconoir/help-circle'
import HelpSquare from './iconoir/help-square'
import Heptagon from './iconoir/heptagon'
import HexagonDice from './iconoir/hexagon-dice'
import HexagonPlus from './iconoir/hexagon-plus'
import Hexagon from './iconoir/hexagon'
import HistoricShieldAlt from './iconoir/historic-shield-alt'
import HistoricShield from './iconoir/historic-shield'
import HomeAltSlimHoriz from './iconoir/home-alt-slim-horiz'
import HomeAltSlim from './iconoir/home-alt-slim'
import HomeAlt from './iconoir/home-alt'
import HomeHospital from './iconoir/home-hospital'
import HomeSale from './iconoir/home-sale'
import HomeSecure from './iconoir/home-secure'
import HomeShield from './iconoir/home-shield'
import HomeSimpleDoor from './iconoir/home-simple-door'
import HomeSimple from './iconoir/home-simple'
import HomeTable from './iconoir/home-table'
import HomeTemperatureIn from './iconoir/home-temperature-in'
import HomeTemperatureOut from './iconoir/home-temperature-out'
import HomeUser from './iconoir/home-user'
import Home from './iconoir/home'
import HorizDistributionLeft from './iconoir/horiz-distribution-left'
import HorizDistributionRight from './iconoir/horiz-distribution-right'
import HorizontalMerge from './iconoir/horizontal-merge'
import HorizontalSplit from './iconoir/horizontal-split'
import HospitalCircle from './iconoir/hospital-circle'
import Hospital from './iconoir/hospital'
import HotAirBalloon from './iconoir/hot-air-balloon'
import Hourglass from './iconoir/hourglass'
import HouseRooms from './iconoir/house-rooms'
import HtmlFive from './iconoir/html5'
import IceCream from './iconoir/ice-cream'
import Iconoir from './iconoir/iconoir'
import Import from './iconoir/import'
import Inclination from './iconoir/inclination'
import Industry from './iconoir/industry'
import Infinite from './iconoir/infinite'
import InfoCircle from './iconoir/info-circle'
import InputField from './iconoir/input-field'
import InputOutput from './iconoir/input-output'
import InputSearch from './iconoir/input-search'
import Instagram from './iconoir/instagram'
import Internet from './iconoir/internet'
import IntersectAlt from './iconoir/intersect-alt'
import Intersect from './iconoir/intersect'
import IosSettings from './iconoir/ios-settings'
import IpAddressTag from './iconoir/ip-address-tag'
import IrisScan from './iconoir/iris-scan'
import ItalicSquare from './iconoir/italic-square'
import Italic from './iconoir/italic'
import Jellyfish from './iconoir/jellyfish'
import JournalPage from './iconoir/journal-page'
import Journal from './iconoir/journal'
import JpegFormat from './iconoir/jpeg-format'
import JpgFormat from './iconoir/jpg-format'
import KanbanBoard from './iconoir/kanban-board'
import KeyBack from './iconoir/key-back'
import KeyCommand from './iconoir/key-command'
import KeyMinus from './iconoir/key-minus'
import KeyPlus from './iconoir/key-plus'
import KeyXmark from './iconoir/key-xmark'
import Key from './iconoir/key'
import KeyframeAlignCenter from './iconoir/keyframe-align-center'
import KeyframeAlignHorizontal from './iconoir/keyframe-align-horizontal'
import KeyframeAlignVertical from './iconoir/keyframe-align-vertical'
import KeyframeMinusIn from './iconoir/keyframe-minus-in'
import KeyframeMinus from './iconoir/keyframe-minus'
import KeyframePlusIn from './iconoir/keyframe-plus-in'
import KeyframePlus from './iconoir/keyframe-plus'
import KeyframePosition from './iconoir/keyframe-position'
import Keyframe from './iconoir/keyframe'
import KeyframesCouple from './iconoir/keyframes-couple'
import KeyframesMinus from './iconoir/keyframes-minus'
import KeyframesPlus from './iconoir/keyframes-plus'
import Keyframes from './iconoir/keyframes'
import Label from './iconoir/label'
import Lamp from './iconoir/lamp'
import Language from './iconoir/language'
import LaptopCharging from './iconoir/laptop-charging'
import LaptopDevMode from './iconoir/laptop-dev-mode'
import LaptopFix from './iconoir/laptop-fix'
import LaptopWarning from './iconoir/laptop-warning'
import Laptop from './iconoir/laptop'
import LayoutLeft from './iconoir/layout-left'
import LayoutRight from './iconoir/layout-right'
import LeaderboardStar from './iconoir/leaderboard-star'
import Leaderboard from './iconoir/leaderboard'
import Leaf from './iconoir/leaf'
import Learning from './iconoir/learning'
import LensPlus from './iconoir/lens-plus'
import Lens from './iconoir/lens'
import Lifebelt from './iconoir/lifebelt'
import LightBulbOff from './iconoir/light-bulb-off'
import LightBulbOn from './iconoir/light-bulb-on'
import LightBulb from './iconoir/light-bulb'
import LineSpace from './iconoir/line-space'
import Linear from './iconoir/linear'
import LinkSlash from './iconoir/link-slash'
import LinkXmark from './iconoir/link-xmark'
import Link from './iconoir/link'
import Linkedin from './iconoir/linkedin'
import Linux from './iconoir/linux'
import ListSelect from './iconoir/list-select'
import List from './iconoir/list'
import LitecoinCircle from './iconoir/litecoin-circle'
import LitecoinRotateOut from './iconoir/litecoin-rotate-out'
import LockSlash from './iconoir/lock-slash'
import LockSquare from './iconoir/lock-square'
import Lock from './iconoir/lock'
import LoftThreed from './iconoir/loft-3d'
import LogIn from './iconoir/log-in'
import LogNoAccess from './iconoir/log-no-access'
import LogOut from './iconoir/log-out'
import LongArrowDownLeft from './iconoir/long-arrow-down-left'
import LongArrowDownRight from './iconoir/long-arrow-down-right'
import LongArrowLeftDown from './iconoir/long-arrow-left-down'
import LongArrowLeftUp from './iconoir/long-arrow-left-up'
import LongArrowRightDown from './iconoir/long-arrow-right-down'
import LongArrowRightUp from './iconoir/long-arrow-right-up'
import LongArrowUpLeft from './iconoir/long-arrow-up-left'
import LongArrowUpRight from './iconoir/long-arrow-up-right'
import LotOfCash from './iconoir/lot-of-cash'
import Lullaby from './iconoir/lullaby'
import MacControlKey from './iconoir/mac-control-key'
import MacDock from './iconoir/mac-dock'
import MacOptionKey from './iconoir/mac-option-key'
import MacOsWindow from './iconoir/mac-os-window'
import MagicWand from './iconoir/magic-wand'
import MagnetEnergy from './iconoir/magnet-energy'
import Magnet from './iconoir/magnet'
import MailIn from './iconoir/mail-in'
import MailOpen from './iconoir/mail-open'
import MailOut from './iconoir/mail-out'
import Mail from './iconoir/mail'
import Male from './iconoir/male'
import MapPinMinus from './iconoir/map-pin-minus'
import MapPinPlus from './iconoir/map-pin-plus'
import MapPinXmark from './iconoir/map-pin-xmark'
import MapPin from './iconoir/map-pin'
import MapXmark from './iconoir/map-xmark'
import Map from './iconoir/map'
import MapsArrowDiagonal from './iconoir/maps-arrow-diagonal'
import MapsArrowXmark from './iconoir/maps-arrow-xmark'
import MapsArrow from './iconoir/maps-arrow'
import MapsGoStraight from './iconoir/maps-go-straight'
import MapsTurnBack from './iconoir/maps-turn-back'
import MapsTurnLeft from './iconoir/maps-turn-left'
import MapsTurnRight from './iconoir/maps-turn-right'
import MaskSquare from './iconoir/mask-square'
import MastercardCard from './iconoir/mastercard-card'
import Mastodon from './iconoir/mastodon'
import MathBook from './iconoir/math-book'
import Maximize from './iconoir/maximize'
import MedalOnest from './iconoir/medal-1st'
import Medal from './iconoir/medal'
import MediaImageFolder from './iconoir/media-image-folder'
import MediaImageList from './iconoir/media-image-list'
import MediaImagePlus from './iconoir/media-image-plus'
import MediaImageXmark from './iconoir/media-image-xmark'
import MediaImage from './iconoir/media-image'
import MediaVideoFolder from './iconoir/media-video-folder'
import MediaVideoList from './iconoir/media-video-list'
import MediaVideoPlus from './iconoir/media-video-plus'
import MediaVideoXmark from './iconoir/media-video-xmark'
import MediaVideo from './iconoir/media-video'
import Medium from './iconoir/medium'
import Megaphone from './iconoir/megaphone'
import MenuScale from './iconoir/menu-scale'
import Menu from './iconoir/menu'
import MessageAlert from './iconoir/message-alert'
import MessageText from './iconoir/message-text'
import Message from './iconoir/message'
import MeterArrowDownRight from './iconoir/meter-arrow-down-right'
import Metro from './iconoir/metro'
import MicrophoneCheck from './iconoir/microphone-check'
import MicrophoneMinus from './iconoir/microphone-minus'
import MicrophoneMute from './iconoir/microphone-mute'
import MicrophonePlus from './iconoir/microphone-plus'
import MicrophoneSpeaking from './iconoir/microphone-speaking'
import MicrophoneWarning from './iconoir/microphone-warning'
import Microphone from './iconoir/microphone'
import Microscope from './iconoir/microscope'
import MinusCircle from './iconoir/minus-circle'
import MinusHexagon from './iconoir/minus-hexagon'
import MinusSquareDashed from './iconoir/minus-square-dashed'
import MinusSquare from './iconoir/minus-square'
import Minus from './iconoir/minus'
import Mirror from './iconoir/mirror'
import MobileDevMode from './iconoir/mobile-dev-mode'
import MobileFingerprint from './iconoir/mobile-fingerprint'
import MobileVoice from './iconoir/mobile-voice'
import ModernTvFourk from './iconoir/modern-tv-4k'
import ModernTv from './iconoir/modern-tv'
import MoneySquare from './iconoir/money-square'
import MoonSat from './iconoir/moon-sat'
import MoreHorizCircle from './iconoir/more-horiz-circle'
import MoreHoriz from './iconoir/more-horiz'
import MoreVertCircle from './iconoir/more-vert-circle'
import MoreVert from './iconoir/more-vert'
import Motorcycle from './iconoir/motorcycle'
import MouseButtonLeft from './iconoir/mouse-button-left'
import MouseButtonRight from './iconoir/mouse-button-right'
import MouseScrollWheel from './iconoir/mouse-scroll-wheel'
import Movie from './iconoir/movie'
import MpegFormat from './iconoir/mpeg-format'
import MultiBubble from './iconoir/multi-bubble'
import MultiMacOsWindow from './iconoir/multi-mac-os-window'
import MultiWindow from './iconoir/multi-window'
import MultiplePagesEmpty from './iconoir/multiple-pages-empty'
import MultiplePagesMinus from './iconoir/multiple-pages-minus'
import MultiplePagesPlus from './iconoir/multiple-pages-plus'
import MultiplePagesXmark from './iconoir/multiple-pages-xmark'
import MultiplePages from './iconoir/multiple-pages'
import MusicDoubleNotePlus from './iconoir/music-double-note-plus'
import MusicDoubleNote from './iconoir/music-double-note'
import MusicNotePlus from './iconoir/music-note-plus'
import MusicNote from './iconoir/music-note'
import NSquare from './iconoir/n-square'
import NavArrowDown from './iconoir/nav-arrow-down'
import NavArrowLeft from './iconoir/nav-arrow-left'
import NavArrowRight from './iconoir/nav-arrow-right'
import NavArrowUp from './iconoir/nav-arrow-up'
import NavigatorAlt from './iconoir/navigator-alt'
import Navigator from './iconoir/navigator'
import Neighbourhood from './iconoir/neighbourhood'
import NetworkLeft from './iconoir/network-left'
import NetworkReverse from './iconoir/network-reverse'
import NetworkRight from './iconoir/network-right'
import Network from './iconoir/network'
import NewTab from './iconoir/new-tab'
import NintendoSwitch from './iconoir/nintendo-switch'
import NoSmokingCircle from './iconoir/no-smoking-circle'
import NonBinary from './iconoir/non-binary'
import Notes from './iconoir/notes'
import NpmSquare from './iconoir/npm-square'
import Npm from './iconoir/npm'
import NumberZeroSquare from './iconoir/number-0-square'
import NumberOneSquare from './iconoir/number-1-square'
import NumberTwoSquare from './iconoir/number-2-square'
import NumberThreeSquare from './iconoir/number-3-square'
import NumberFourSquare from './iconoir/number-4-square'
import NumberFiveSquare from './iconoir/number-5-square'
import NumberSixSquare from './iconoir/number-6-square'
import NumberSevenSquare from './iconoir/number-7-square'
import NumberEightSquare from './iconoir/number-8-square'
import NumberNineSquare from './iconoir/number-9-square'
import NumberedListLeft from './iconoir/numbered-list-left'
import NumberedListRight from './iconoir/numbered-list-right'
import OSquare from './iconoir/o-square'
import Octagon from './iconoir/octagon'
import OffTag from './iconoir/off-tag'
import OilIndustry from './iconoir/oil-industry'
import Okrs from './iconoir/okrs'
import OnTag from './iconoir/on-tag'
import OneFingerSelectHandGesture from './iconoir/one-finger-select-hand-gesture'
import OnePointCircle from './iconoir/one-point-circle'
import OpenBook from './iconoir/open-book'
import OpenInBrowser from './iconoir/open-in-browser'
import OpenInWindow from './iconoir/open-in-window'
import OpenNewWindow from './iconoir/open-new-window'
import OpenSelectHandGesture from './iconoir/open-select-hand-gesture'
import OpenVpn from './iconoir/open-vpn'
import OrangeHalf from './iconoir/orange-half'
import OrangeSliceAlt from './iconoir/orange-slice-alt'
import OrangeSlice from './iconoir/orange-slice'
import OrganicFoodSquare from './iconoir/organic-food-square'
import OrganicFood from './iconoir/organic-food'
import OrthogonalView from './iconoir/orthogonal-view'
import PackageLock from './iconoir/package-lock'
import Package from './iconoir/package'
import Packages from './iconoir/packages'
import Pacman from './iconoir/pacman'
import PageDown from './iconoir/page-down'
import PageEdit from './iconoir/page-edit'
import PageFlip from './iconoir/page-flip'
import PageLeft from './iconoir/page-left'
import PageMinusIn from './iconoir/page-minus-in'
import PageMinus from './iconoir/page-minus'
import PagePlusIn from './iconoir/page-plus-in'
import PagePlus from './iconoir/page-plus'
import PageRight from './iconoir/page-right'
import PageSearch from './iconoir/page-search'
import PageStar from './iconoir/page-star'
import PageUp from './iconoir/page-up'
import Page from './iconoir/page'
import Palette from './iconoir/palette'
import PanoramaEnlarge from './iconoir/panorama-enlarge'
import PanoramaReduce from './iconoir/panorama-reduce'
import PantsPockets from './iconoir/pants-pockets'
import Pants from './iconoir/pants'
import Parking from './iconoir/parking'
import PasswordCheck from './iconoir/password-check'
import PasswordCursor from './iconoir/password-cursor'
import PasswordXmark from './iconoir/password-xmark'
import PasteClipboard from './iconoir/paste-clipboard'
import PathArrow from './iconoir/path-arrow'
import PauseWindow from './iconoir/pause-window'
import Pause from './iconoir/pause'
import Paypal from './iconoir/paypal'
import PcCheck from './iconoir/pc-check'
import PcFirewall from './iconoir/pc-firewall'
import PcMouse from './iconoir/pc-mouse'
import PcNoEntry from './iconoir/pc-no-entry'
import PcWarning from './iconoir/pc-warning'
import PeaceHand from './iconoir/peace-hand'
import Peerlist from './iconoir/peerlist'
import PenConnectBluetooth from './iconoir/pen-connect-bluetooth'
import PenConnectWifi from './iconoir/pen-connect-wifi'
import PenTabletConnectUsb from './iconoir/pen-tablet-connect-usb'
import PenTabletConnectWifi from './iconoir/pen-tablet-connect-wifi'
import PenTablet from './iconoir/pen-tablet'
import Pentagon from './iconoir/pentagon'
import PeopleTag from './iconoir/people-tag'
import PercentRotateOut from './iconoir/percent-rotate-out'
import PercentageCircle from './iconoir/percentage-circle'
import PercentageSquare from './iconoir/percentage-square'
import Percentage from './iconoir/percentage'
import PerspectiveView from './iconoir/perspective-view'
import PharmacyCrossCircle from './iconoir/pharmacy-cross-circle'
import PharmacyCrossTag from './iconoir/pharmacy-cross-tag'
import PhoneDisabled from './iconoir/phone-disabled'
import PhoneIncome from './iconoir/phone-income'
import PhoneMinus from './iconoir/phone-minus'
import PhoneOutcome from './iconoir/phone-outcome'
import PhonePaused from './iconoir/phone-paused'
import PhonePlus from './iconoir/phone-plus'
import PhoneXmark from './iconoir/phone-xmark'
import Phone from './iconoir/phone'
import PiggyBank from './iconoir/piggy-bank'
import Pillow from './iconoir/pillow'
import PinSlash from './iconoir/pin-slash'
import Pin from './iconoir/pin'
import PineTree from './iconoir/pine-tree'
import Pinterest from './iconoir/pinterest'
import PipeThreed from './iconoir/pipe-3d'
import PizzaSlice from './iconoir/pizza-slice'
import PlanetAlt from './iconoir/planet-alt'
import PlanetSat from './iconoir/planet-sat'
import Planet from './iconoir/planet'
import Planimetry from './iconoir/planimetry'
import Play from './iconoir/play'
import PlaylistPlay from './iconoir/playlist-play'
import PlaylistPlus from './iconoir/playlist-plus'
import Playlist from './iconoir/playlist'
import PlaystationGamepad from './iconoir/playstation-gamepad'
import PlugTypeA from './iconoir/plug-type-a'
import PlugTypeC from './iconoir/plug-type-c'
import PlugTypeG from './iconoir/plug-type-g'
import PlugTypeL from './iconoir/plug-type-l'
import PlusCircle from './iconoir/plus-circle'
import PlusSquareDashed from './iconoir/plus-square-dashed'
import PlusSquare from './iconoir/plus-square'
import Plus from './iconoir/plus'
import PngFormat from './iconoir/png-format'
import Pocket from './iconoir/pocket'
import Podcast from './iconoir/podcast'
import Pokeball from './iconoir/pokeball'
import PolarSh from './iconoir/polar-sh'
import PositionAlign from './iconoir/position-align'
import Position from './iconoir/position'
import Post from './iconoir/post'
import Potion from './iconoir/potion'
import Pound from './iconoir/pound'
import PrecisionTool from './iconoir/precision-tool'
import Presentation from './iconoir/presentation'
import Printer from './iconoir/printer'
import PrintingPage from './iconoir/printing-page'
import PriorityDown from './iconoir/priority-down'
import PriorityHigh from './iconoir/priority-high'
import PriorityMedium from './iconoir/priority-medium'
import PriorityUp from './iconoir/priority-up'
import PrivacyPolicy from './iconoir/privacy-policy'
import PrivateWifi from './iconoir/private-wifi'
import ProfileCircle from './iconoir/profile-circle'
import Prohibition from './iconoir/prohibition'
import ProjectCurveThreed from './iconoir/project-curve-3d'
import Puzzle from './iconoir/puzzle'
import QrCode from './iconoir/qr-code'
import QuestionMark from './iconoir/question-mark'
import QuoteMessage from './iconoir/quote-message'
import Quote from './iconoir/quote'
import Radiation from './iconoir/radiation'
import Radius from './iconoir/radius'
import Rain from './iconoir/rain'
import RawFormat from './iconoir/raw-format'
import ReceiveDollars from './iconoir/receive-dollars'
import ReceiveEuros from './iconoir/receive-euros'
import ReceivePounds from './iconoir/receive-pounds'
import ReceiveYens from './iconoir/receive-yens'
import RedoAction from './iconoir/redo-action'
import RedoCircle from './iconoir/redo-circle'
import Redo from './iconoir/redo'
import Reduce from './iconoir/reduce'
import RefreshCircle from './iconoir/refresh-circle'
import RefreshDouble from './iconoir/refresh-double'
import Refresh from './iconoir/refresh'
import ReloadWindow from './iconoir/reload-window'
import ReminderHandGesture from './iconoir/reminder-hand-gesture'
import RepeatOnce from './iconoir/repeat-once'
import Repeat from './iconoir/repeat'
import ReplyToMessage from './iconoir/reply-to-message'
import Reply from './iconoir/reply'
import ReportColumns from './iconoir/report-columns'
import Reports from './iconoir/reports'
import Repository from './iconoir/repository'
import Restart from './iconoir/restart'
import Rewind from './iconoir/rewind'
import RhombusArrowRight from './iconoir/rhombus-arrow-right'
import Rhombus from './iconoir/rhombus'
import Rings from './iconoir/rings'
import Rocket from './iconoir/rocket'
import Rook from './iconoir/rook'
import RotateCameraLeft from './iconoir/rotate-camera-left'
import RotateCameraRight from './iconoir/rotate-camera-right'
import RoundFlask from './iconoir/round-flask'
import RoundedMirror from './iconoir/rounded-mirror'
import RssFeedTag from './iconoir/rss-feed-tag'
import RssFeed from './iconoir/rss-feed'
import RubikCube from './iconoir/rubik-cube'
import RulerArrows from './iconoir/ruler-arrows'
import RulerCombine from './iconoir/ruler-combine'
import RulerMinus from './iconoir/ruler-minus'
import RulerPlus from './iconoir/ruler-plus'
import Ruler from './iconoir/ruler'
import Running from './iconoir/running'
import Safari from './iconoir/safari'
import SafeArrowLeft from './iconoir/safe-arrow-left'
import SafeArrowRight from './iconoir/safe-arrow-right'
import SafeOpen from './iconoir/safe-open'
import Safe from './iconoir/safe'
import Sandals from './iconoir/sandals'
import ScaleFrameEnlarge from './iconoir/scale-frame-enlarge'
import ScaleFrameReduce from './iconoir/scale-frame-reduce'
import ScanBarcode from './iconoir/scan-barcode'
import ScanQrCode from './iconoir/scan-qr-code'
import Scanning from './iconoir/scanning'
import Scarf from './iconoir/scarf'
import ScissorAlt from './iconoir/scissor-alt'
import Scissor from './iconoir/scissor'
import Screenshot from './iconoir/screenshot'
import SeaAndSun from './iconoir/sea-and-sun'
import SeaWaves from './iconoir/sea-waves'
import SearchEngine from './iconoir/search-engine'
import SearchWindow from './iconoir/search-window'
import Search from './iconoir/search'
import SecureWindow from './iconoir/secure-window'
import SecurityPass from './iconoir/security-pass'
import SelectEdgeThreed from './iconoir/select-edge-3d'
import SelectFaceThreed from './iconoir/select-face-3d'
import SelectPointThreed from './iconoir/select-point-3d'
import SelectWindow from './iconoir/select-window'
import SelectiveTool from './iconoir/selective-tool'
import SendDiagonal from './iconoir/send-diagonal'
import SendDollars from './iconoir/send-dollars'
import SendEuros from './iconoir/send-euros'
import SendMail from './iconoir/send-mail'
import SendPounds from './iconoir/send-pounds'
import SendYens from './iconoir/send-yens'
import Send from './iconoir/send'
import ServerConnection from './iconoir/server-connection'
import Server from './iconoir/server'
import SettingsProfiles from './iconoir/settings-profiles'
import Settings from './iconoir/settings'
import ShareAndroid from './iconoir/share-android'
import ShareIos from './iconoir/share-ios'
import ShieldAlert from './iconoir/shield-alert'
import ShieldAlt from './iconoir/shield-alt'
import ShieldBroken from './iconoir/shield-broken'
import ShieldCheck from './iconoir/shield-check'
import ShieldDownload from './iconoir/shield-download'
import ShieldEye from './iconoir/shield-eye'
import ShieldLoading from './iconoir/shield-loading'
import ShieldMinus from './iconoir/shield-minus'
import ShieldPlusIn from './iconoir/shield-plus-in'
import ShieldQuestion from './iconoir/shield-question'
import ShieldSearch from './iconoir/shield-search'
import ShieldUpload from './iconoir/shield-upload'
import ShieldXmark from './iconoir/shield-xmark'
import Shield from './iconoir/shield'
import ShirtTankTop from './iconoir/shirt-tank-top'
import Shirt from './iconoir/shirt'
import ShopFourTilesWindow from './iconoir/shop-four-tiles-window'
import ShopFourTiles from './iconoir/shop-four-tiles'
import ShopWindow from './iconoir/shop-window'
import Shop from './iconoir/shop'
import ShoppingBagArrowDown from './iconoir/shopping-bag-arrow-down'
import ShoppingBagArrowUp from './iconoir/shopping-bag-arrow-up'
import ShoppingBagCheck from './iconoir/shopping-bag-check'
import ShoppingBagMinus from './iconoir/shopping-bag-minus'
import ShoppingBagPlus from './iconoir/shopping-bag-plus'
import ShoppingBagPocket from './iconoir/shopping-bag-pocket'
import ShoppingBagWarning from './iconoir/shopping-bag-warning'
import ShoppingBag from './iconoir/shopping-bag'
import ShoppingCodeCheck from './iconoir/shopping-code-check'
import ShoppingCodeXmark from './iconoir/shopping-code-xmark'
import ShoppingCode from './iconoir/shopping-code'
import ShortPantsPockets from './iconoir/short-pants-pockets'
import ShortPants from './iconoir/short-pants'
import ShortcutSquare from './iconoir/shortcut-square'
import Shuffle from './iconoir/shuffle'
import SidebarCollapse from './iconoir/sidebar-collapse'
import SidebarExpand from './iconoir/sidebar-expand'
import SigmaFunction from './iconoir/sigma-function'
import SimpleCart from './iconoir/simple-cart'
import SineWave from './iconoir/sine-wave'
import SingleTapGesture from './iconoir/single-tap-gesture'
import Skateboard from './iconoir/skateboard'
import Skateboarding from './iconoir/skateboarding'
import SkipNext from './iconoir/skip-next'
import SkipPrev from './iconoir/skip-prev'
import SlashSquare from './iconoir/slash-square'
import Slash from './iconoir/slash'
import SleeperChair from './iconoir/sleeper-chair'
import Slips from './iconoir/slips'
import SmallLampAlt from './iconoir/small-lamp-alt'
import SmallLamp from './iconoir/small-lamp'
import SmartphoneDevice from './iconoir/smartphone-device'
import Smoking from './iconoir/smoking'
import Snapchat from './iconoir/snapchat'
import SnowFlake from './iconoir/snow-flake'
import Snow from './iconoir/snow'
import Soap from './iconoir/soap'
import SoccerBall from './iconoir/soccer-ball'
import Sofa from './iconoir/sofa'
import SoilAlt from './iconoir/soil-alt'
import Soil from './iconoir/soil'
import SortDown from './iconoir/sort-down'
import SortUp from './iconoir/sort-up'
import Sort from './iconoir/sort'
import SoundHigh from './iconoir/sound-high'
import SoundLow from './iconoir/sound-low'
import SoundMin from './iconoir/sound-min'
import SoundOff from './iconoir/sound-off'
import Spades from './iconoir/spades'
import Spark from './iconoir/spark'
import Sparks from './iconoir/sparks'
import Sphere from './iconoir/sphere'
import Spiral from './iconoir/spiral'
import SplitArea from './iconoir/split-area'
import SplitSquareDashed from './iconoir/split-square-dashed'
import SpockHandGesture from './iconoir/spock-hand-gesture'
import Spotify from './iconoir/spotify'
import SquareThreedCornerToCorner from './iconoir/square-3d-corner-to-corner'
import SquareThreedFromCenter from './iconoir/square-3d-from-center'
import SquareThreedThreePoints from './iconoir/square-3d-three-points'
import SquareCursor from './iconoir/square-cursor'
import SquareDashed from './iconoir/square-dashed'
import SquareWave from './iconoir/square-wave'
import Square from './iconoir/square'
import Stackoverflow from './iconoir/stackoverflow'
import StarDashed from './iconoir/star-dashed'
import StarHalfDashed from './iconoir/star-half-dashed'
import Star from './iconoir/star'
import StatDown from './iconoir/stat-down'
import StatUp from './iconoir/stat-up'
import StatsDownSquare from './iconoir/stats-down-square'
import StatsReport from './iconoir/stats-report'
import StatsUpSquare from './iconoir/stats-up-square'
import Strategy from './iconoir/strategy'
import Stretching from './iconoir/stretching'
import Strikethrough from './iconoir/strikethrough'
import Stroller from './iconoir/stroller'
import StyleBorder from './iconoir/style-border'
import SubmitDocument from './iconoir/submit-document'
import Substract from './iconoir/substract'
import Suggestion from './iconoir/suggestion'
import Suitcase from './iconoir/suitcase'
import SunLight from './iconoir/sun-light'
import SvgFormat from './iconoir/svg-format'
import SweepThreed from './iconoir/sweep-3d'
import Swimming from './iconoir/swimming'
import SwipeDownGesture from './iconoir/swipe-down-gesture'
import SwipeLeftGesture from './iconoir/swipe-left-gesture'
import SwipeRightGesture from './iconoir/swipe-right-gesture'
import SwipeTwoFingersDownGesture from './iconoir/swipe-two-fingers-down-gesture'
import SwipeTwoFingersLeftGesture from './iconoir/swipe-two-fingers-left-gesture'
import SwipeTwoFingersRightGesture from './iconoir/swipe-two-fingers-right-gesture'
import SwipeTwoFingersUpGesture from './iconoir/swipe-two-fingers-up-gesture'
import SwipeUpGesture from './iconoir/swipe-up-gesture'
import SwitchOff from './iconoir/switch-off'
import SwitchOn from './iconoir/switch-on'
import SystemRestart from './iconoir/system-restart'
import SystemShut from './iconoir/system-shut'
import TableTwoColumns from './iconoir/table-2-columns'
import TableRows from './iconoir/table-rows'
import Table from './iconoir/table'
import TaskList from './iconoir/task-list'
import TelegramCircle from './iconoir/telegram-circle'
import Telegram from './iconoir/telegram'
import TemperatureDown from './iconoir/temperature-down'
import TemperatureHigh from './iconoir/temperature-high'
import TemperatureLow from './iconoir/temperature-low'
import TemperatureUp from './iconoir/temperature-up'
import TennisBallAlt from './iconoir/tennis-ball-alt'
import TennisBall from './iconoir/tennis-ball'
import TerminalTag from './iconoir/terminal-tag'
import Terminal from './iconoir/terminal'
import TestTube from './iconoir/test-tube'
import TextArrowsUpDown from './iconoir/text-arrows-up-down'
import TextBox from './iconoir/text-box'
import TextMagnifyingGlass from './iconoir/text-magnifying-glass'
import TextSize from './iconoir/text-size'
import TextSquare from './iconoir/text-square'
import Text from './iconoir/text'
import Threads from './iconoir/threads'
import ThreePointsCircle from './iconoir/three-points-circle'
import ThreeStars from './iconoir/three-stars'
import ThumbsDown from './iconoir/thumbs-down'
import ThumbsUp from './iconoir/thumbs-up'
import Thunderstorm from './iconoir/thunderstorm'
import TifFormat from './iconoir/tif-format'
import TiffFormat from './iconoir/tiff-format'
import Tiktok from './iconoir/tiktok'
import TimeZone from './iconoir/time-zone'
import TimerOff from './iconoir/timer-off'
import Timer from './iconoir/timer'
import Tools from './iconoir/tools'
import Tournament from './iconoir/tournament'
import TowerCheck from './iconoir/tower-check'
import TowerNoAccess from './iconoir/tower-no-access'
import TowerWarning from './iconoir/tower-warning'
import Tower from './iconoir/tower'
import Trademark from './iconoir/trademark'
import Train from './iconoir/train'
import Tram from './iconoir/tram'
import TransitionDown from './iconoir/transition-down'
import TransitionLeft from './iconoir/transition-left'
import TransitionRight from './iconoir/transition-right'
import TransitionUp from './iconoir/transition-up'
import Translate from './iconoir/translate'
import Trash from './iconoir/trash'
import Treadmill from './iconoir/treadmill'
import Tree from './iconoir/tree'
import Trekking from './iconoir/trekking'
import Trello from './iconoir/trello'
import TriangleFlagCircle from './iconoir/triangle-flag-circle'
import TriangleFlagTwoStripes from './iconoir/triangle-flag-two-stripes'
import TriangleFlag from './iconoir/triangle-flag'
import Triangle from './iconoir/triangle'
import Trophy from './iconoir/trophy'
import TruckGreen from './iconoir/truck-green'
import TruckLength from './iconoir/truck-length'
import Truck from './iconoir/truck'
import Tunnel from './iconoir/tunnel'
import TvFix from './iconoir/tv-fix'
import TvWarning from './iconoir/tv-warning'
import Tv from './iconoir/tv'
import Twitter from './iconoir/twitter'
import TwoPointsCircle from './iconoir/two-points-circle'
import TwoSeaterSofa from './iconoir/two-seater-sofa'
import Type from './iconoir/type'
import UTurnArrowLeft from './iconoir/u-turn-arrow-left'
import UTurnArrowRight from './iconoir/u-turn-arrow-right'
import Umbrella from './iconoir/umbrella'
import UnderlineSquare from './iconoir/underline-square'
import Underline from './iconoir/underline'
import UndoAction from './iconoir/undo-action'
import UndoCircle from './iconoir/undo-circle'
import Undo from './iconoir/undo'
import UnionAlt from './iconoir/union-alt'
import UnionHorizAlt from './iconoir/union-horiz-alt'
import Union from './iconoir/union'
import UnityFive from './iconoir/unity-5'
import Unity from './iconoir/unity'
import UnjoinThreed from './iconoir/unjoin-3d'
import UploadDataWindow from './iconoir/upload-data-window'
import UploadSquare from './iconoir/upload-square'
import Upload from './iconoir/upload'
import Usb from './iconoir/usb'
import UserBadgeCheck from './iconoir/user-badge-check'
import UserBag from './iconoir/user-bag'
import UserCart from './iconoir/user-cart'
import UserCircle from './iconoir/user-circle'
import UserCrown from './iconoir/user-crown'
import UserLove from './iconoir/user-love'
import UserPlus from './iconoir/user-plus'
import UserScan from './iconoir/user-scan'
import UserSquare from './iconoir/user-square'
import UserStar from './iconoir/user-star'
import UserXmark from './iconoir/user-xmark'
import User from './iconoir/user'
import VeganCircle from './iconoir/vegan-circle'
import VeganSquare from './iconoir/vegan-square'
import Vegan from './iconoir/vegan'
import VehicleGreen from './iconoir/vehicle-green'
import VerticalMerge from './iconoir/vertical-merge'
import VerticalSplit from './iconoir/vertical-split'
import Vials from './iconoir/vials'
import VideoCameraOff from './iconoir/video-camera-off'
import VideoCamera from './iconoir/video-camera'
import VideoProjector from './iconoir/video-projector'
import ViewThreeSixZero from './iconoir/view-360'
import ViewColumnsTwo from './iconoir/view-columns-2'
import ViewColumnsThree from './iconoir/view-columns-3'
import ViewGrid from './iconoir/view-grid'
import ViewStructureDown from './iconoir/view-structure-down'
import ViewStructureUp from './iconoir/view-structure-up'
import VoiceCheck from './iconoir/voice-check'
import VoiceCircle from './iconoir/voice-circle'
import VoiceLockCircle from './iconoir/voice-lock-circle'
import VoiceScan from './iconoir/voice-scan'
import VoiceSquare from './iconoir/voice-square'
import VoiceXmark from './iconoir/voice-xmark'
import Voice from './iconoir/voice'
import VrTag from './iconoir/vr-tag'
import VueJs from './iconoir/vue-js'
import Waist from './iconoir/waist'
import Walking from './iconoir/walking'
import Wallet from './iconoir/wallet'
import WarningCircle from './iconoir/warning-circle'
import WarningHexagon from './iconoir/warning-hexagon'
import WarningSquare from './iconoir/warning-square'
import WarningTriangle from './iconoir/warning-triangle'
import WarningWindow from './iconoir/warning-window'
import Wash from './iconoir/wash'
import WashingMachine from './iconoir/washing-machine'
import WateringSoil from './iconoir/watering-soil'
import WebWindowEnergyConsumption from './iconoir/web-window-energy-consumption'
import WebWindowXmark from './iconoir/web-window-xmark'
import WebWindow from './iconoir/web-window'
import WebpFormat from './iconoir/webp-format'
import WeightAlt from './iconoir/weight-alt'
import Weight from './iconoir/weight'
import WhiteFlag from './iconoir/white-flag'
import WifiOff from './iconoir/wifi-off'
import WifiSignalNone from './iconoir/wifi-signal-none'
import WifiTag from './iconoir/wifi-tag'
import WifiWarning from './iconoir/wifi-warning'
import WifiXmark from './iconoir/wifi-xmark'
import Wifi from './iconoir/wifi'
import Wind from './iconoir/wind'
import WindowCheck from './iconoir/window-check'
import WindowLock from './iconoir/window-lock'
import WindowNoAccess from './iconoir/window-no-access'
import WindowXmark from './iconoir/window-xmark'
import Windows from './iconoir/windows'
import Wolf from './iconoir/wolf'
import WrapText from './iconoir/wrap-text'
import Wrench from './iconoir/wrench'
import Wristwatch from './iconoir/wristwatch'
import Www from './iconoir/www'
import XSquare from './iconoir/x-square'
import X from './iconoir/x'
import XboxA from './iconoir/xbox-a'
import XboxB from './iconoir/xbox-b'
import XboxX from './iconoir/xbox-x'
import XboxY from './iconoir/xbox-y'
import XmarkCircle from './iconoir/xmark-circle'
import XmarkSquare from './iconoir/xmark-square'
import Xmark from './iconoir/xmark'
import XrayView from './iconoir/xray-view'
import YSquare from './iconoir/y-square'
import Yelp from './iconoir/yelp'
import YenSquare from './iconoir/yen-square'
import Yen from './iconoir/yen'
import Yoga from './iconoir/yoga'
import Youtube from './iconoir/youtube'
import ZSquare from './iconoir/z-square'
import ZoomIn from './iconoir/zoom-in'
import ZoomOut from './iconoir/zoom-out'

const Icons = {
  AccessibilitySign: AccessibilitySign,
  AccessibilityTech: AccessibilityTech,
  Accessibility: Accessibility,
  Activity: Activity,
  AdobeAfterEffects: AdobeAfterEffects,
  AdobeIllustrator: AdobeIllustrator,
  AdobeIndesign: AdobeIndesign,
  AdobeLightroom: AdobeLightroom,
  AdobePhotoshop: AdobePhotoshop,
  AdobeXd: AdobeXd,
  AfricanTree: AfricanTree,
  Agile: Agile,
  AirConditioner: AirConditioner,
  AirplaneHelix45deg: AirplaneHelixFourFivedeg,
  AirplaneHelix: AirplaneHelix,
  AirplaneOff: AirplaneOff,
  AirplaneRotation: AirplaneRotation,
  Airplane: Airplane,
  Airplay: Airplay,
  Alarm: Alarm,
  AlbumCarousel: AlbumCarousel,
  AlbumList: AlbumList,
  AlbumOpen: AlbumOpen,
  Album: Album,
  AlignBottomBox: AlignBottomBox,
  AlignCenter: AlignCenter,
  AlignHorizontalCenters: AlignHorizontalCenters,
  AlignHorizontalSpacing: AlignHorizontalSpacing,
  AlignJustify: AlignJustify,
  AlignLeftBox: AlignLeftBox,
  AlignLeft: AlignLeft,
  AlignRightBox: AlignRightBox,
  AlignRight: AlignRight,
  AlignTopBox: AlignTopBox,
  AlignVerticalCenters: AlignVerticalCenters,
  AlignVerticalSpacing: AlignVerticalSpacing,
  AngleTool: AngleTool,
  AntennaOff: AntennaOff,
  AntennaSignalTag: AntennaSignalTag,
  AntennaSignal: AntennaSignal,
  Antenna: Antenna,
  AppNotification: AppNotification,
  AppStore: AppStore,
  AppWindow: AppWindow,
  AppleHalf: AppleHalf,
  AppleImac2021Side: AppleImacTwoZeroTwoOneSide,
  AppleImac2021: AppleImacTwoZeroTwoOne,
  AppleMac: AppleMac,
  AppleShortcuts: AppleShortcuts,
  AppleSwift: AppleSwift,
  AppleWallet: AppleWallet,
  Apple: Apple,
  ArTag: ArTag,
  Arc3dCenterPoint: ArcThreedCenterPoint,
  Arc3d: ArcThreed,
  Arcade: Arcade,
  ArcheryMatch: ArcheryMatch,
  Archery: Archery,
  Archive: Archive,
  AreaSearch: AreaSearch,
  ArrowArchery: ArrowArchery,
  ArrowDownCircle: ArrowDownCircle,
  ArrowDownLeftCircle: ArrowDownLeftCircle,
  ArrowDownLeftSquare: ArrowDownLeftSquare,
  ArrowDownLeft: ArrowDownLeft,
  ArrowDownRightCircle: ArrowDownRightCircle,
  ArrowDownRightSquare: ArrowDownRightSquare,
  ArrowDownRight: ArrowDownRight,
  ArrowDownTag: ArrowDownTag,
  ArrowDown: ArrowDown,
  ArrowEmailForward: ArrowEmailForward,
  ArrowEnlargeTag: ArrowEnlargeTag,
  ArrowLeftCircle: ArrowLeftCircle,
  ArrowLeftTag: ArrowLeftTag,
  ArrowLeft: ArrowLeft,
  ArrowReduceTag: ArrowReduceTag,
  ArrowRightCircle: ArrowRightCircle,
  ArrowRightTag: ArrowRightTag,
  ArrowRight: ArrowRight,
  ArrowSeparateVertical: ArrowSeparateVertical,
  ArrowSeparate: ArrowSeparate,
  ArrowUnionVertical: ArrowUnionVertical,
  ArrowUnion: ArrowUnion,
  ArrowUpCircle: ArrowUpCircle,
  ArrowUpLeftCircle: ArrowUpLeftCircle,
  ArrowUpLeftSquare: ArrowUpLeftSquare,
  ArrowUpLeft: ArrowUpLeft,
  ArrowUpRightCircle: ArrowUpRightCircle,
  ArrowUpRightSquare: ArrowUpRightSquare,
  ArrowUpRight: ArrowUpRight,
  ArrowUpTag: ArrowUpTag,
  ArrowUp: ArrowUp,
  ArrowsUpFromLine: ArrowsUpFromLine,
  Asana: Asana,
  Asterisk: Asterisk,
  AtSignCircle: AtSignCircle,
  AtSign: AtSign,
  Atom: Atom,
  Attachment: Attachment,
  AugmentedReality: AugmentedReality,
  AutoFlash: AutoFlash,
  AviFormat: AviFormat,
  Axes: Axes,
  Backward15Seconds: BackwardOneFiveSeconds,
  BadgeCheck: BadgeCheck,
  Bag: Bag,
  Balcony: Balcony,
  Bank: Bank,
  Barcode: Barcode,
  BasketballField: BasketballField,
  Basketball: Basketball,
  Bathroom: Bathroom,
  Battery25: BatteryTwoFive,
  Battery50: BatteryFiveZero,
  Battery75: BatterySevenFive,
  BatteryCharging: BatteryCharging,
  BatteryEmpty: BatteryEmpty,
  BatteryFull: BatteryFull,
  BatteryIndicator: BatteryIndicator,
  BatterySlash: BatterySlash,
  BatteryWarning: BatteryWarning,
  Bbq: Bbq,
  BeachBag: BeachBag,
  BedReady: BedReady,
  Bed: Bed,
  BehanceTag: BehanceTag,
  Behance: Behance,
  BellNotification: BellNotification,
  BellOff: BellOff,
  Bell: Bell,
  Bicycle: Bicycle,
  BinFull: BinFull,
  BinHalf: BinHalf,
  BinMinusIn: BinMinusIn,
  BinPlusIn: BinPlusIn,
  Bin: Bin,
  Binocular: Binocular,
  BirthdayCake: BirthdayCake,
  Bishop: Bishop,
  Bitbucket: Bitbucket,
  BitcoinCircle: BitcoinCircle,
  BitcoinRotateOut: BitcoinRotateOut,
  BluetoothTag: BluetoothTag,
  Bluetooth: Bluetooth,
  BoldSquare: BoldSquare,
  Bold: Bold,
  Bonfire: Bonfire,
  BookLock: BookLock,
  BookStack: BookStack,
  Book: Book,
  BookmarkBook: BookmarkBook,
  BookmarkCircle: BookmarkCircle,
  Bookmark: Bookmark,
  BorderBl: BorderBl,
  BorderBottom: BorderBottom,
  BorderBr: BorderBr,
  BorderInner: BorderInner,
  BorderLeft: BorderLeft,
  BorderOut: BorderOut,
  BorderRight: BorderRight,
  BorderTl: BorderTl,
  BorderTop: BorderTop,
  BorderTr: BorderTr,
  BounceLeft: BounceLeft,
  BounceRight: BounceRight,
  BowlingBall: BowlingBall,
  Box3dCenter: BoxThreedCenter,
  Box3dPoint: BoxThreedPoint,
  Box3dThreePoints: BoxThreedThreePoints,
  BoxIso: BoxIso,
  Box: Box,
  BoxingGlove: BoxingGlove,
  BrainElectricity: BrainElectricity,
  BrainResearch: BrainResearch,
  BrainWarning: BrainWarning,
  Brain: Brain,
  BreadSlice: BreadSlice,
  Bridge3d: BridgeThreed,
  BridgeSurface: BridgeSurface,
  BrightCrown: BrightCrown,
  BrightStar: BrightStar,
  BrightnessWindow: BrightnessWindow,
  Brightness: Brightness,
  BubbleDownload: BubbleDownload,
  BubbleIncome: BubbleIncome,
  BubbleOutcome: BubbleOutcome,
  BubbleSearch: BubbleSearch,
  BubbleStar: BubbleStar,
  BubbleUpload: BubbleUpload,
  BubbleWarning: BubbleWarning,
  BubbleXmark: BubbleXmark,
  Building: Building,
  BusGreen: BusGreen,
  BusStop: BusStop,
  Bus: Bus,
  CSquare: CSquare,
  CableTag: CableTag,
  Calculator: Calculator,
  CalendarMinus: CalendarMinus,
  CalendarPlus: CalendarPlus,
  Calendar: Calendar,
  Camera: Camera,
  CandlestickChart: CandlestickChart,
  Car: Car,
  CardLock: CardLock,
  CardNoAccess: CardNoAccess,
  CardReader: CardReader,
  CardShield: CardShield,
  CardWallet: CardWallet,
  CartAlt: CartAlt,
  CartMinus: CartMinus,
  CartPlus: CartPlus,
  Cart: Cart,
  Cash: Cash,
  Cell2x2: CellTwoxTwo,
  Cellar: Cellar,
  CenterAlign: CenterAlign,
  ChatBubbleCheck: ChatBubbleCheck,
  ChatBubbleEmpty: ChatBubbleEmpty,
  ChatBubbleQuestion: ChatBubbleQuestion,
  ChatBubbleTranslate: ChatBubbleTranslate,
  ChatBubbleWarning: ChatBubbleWarning,
  ChatBubbleXmark: ChatBubbleXmark,
  ChatBubble: ChatBubble,
  ChatLines: ChatLines,
  ChatMinusIn: ChatMinusIn,
  ChatPlusIn: ChatPlusIn,
  CheckCircle: CheckCircle,
  Check: Check,
  Chocolate: Chocolate,
  ChromecastActive: ChromecastActive,
  Chromecast: Chromecast,
  ChurchSide: ChurchSide,
  Church: Church,
  CigaretteSlash: CigaretteSlash,
  CinemaOld: CinemaOld,
  CircleSpark: CircleSpark,
  Circle: Circle,
  City: City,
  ClipboardCheck: ClipboardCheck,
  ClockRotateRight: ClockRotateRight,
  Clock: Clock,
  ClosedCaptionsTag: ClosedCaptionsTag,
  Closet: Closet,
  CloudBookmark: CloudBookmark,
  CloudCheck: CloudCheck,
  CloudDesync: CloudDesync,
  CloudDownload: CloudDownload,
  CloudSquare: CloudSquare,
  CloudSunny: CloudSunny,
  CloudSync: CloudSync,
  CloudUpload: CloudUpload,
  CloudXmark: CloudXmark,
  Cloud: Cloud,
  CodeBracketsSquare: CodeBracketsSquare,
  CodeBrackets: CodeBrackets,
  Code: Code,
  Codepen: Codepen,
  CoffeeCup: CoffeeCup,
  CoinSlash: CoinSlash,
  CoinsSwap: CoinsSwap,
  Coins: Coins,
  CollageFrame: CollageFrame,
  Collapse: Collapse,
  ColorFilter: ColorFilter,
  ColorPicker: ColorPicker,
  ColorWheel: ColorWheel,
  Combine: Combine,
  Commodity: Commodity,
  Community: Community,
  CompAlignBottom: CompAlignBottom,
  CompAlignLeft: CompAlignLeft,
  CompAlignRight: CompAlignRight,
  CompAlignTop: CompAlignTop,
  CompactDisc: CompactDisc,
  Compass: Compass,
  Component: Component,
  CompressLines: CompressLines,
  Compress: Compress,
  Computer: Computer,
  ConstrainedSurface: ConstrainedSurface,
  Consumable: Consumable,
  Contactless: Contactless,
  ControlSlider: ControlSlider,
  Cookie: Cookie,
  CoolingSquare: CoolingSquare,
  Copy: Copy,
  Copyright: Copyright,
  CornerBottomLeft: CornerBottomLeft,
  CornerBottomRight: CornerBottomRight,
  CornerTopLeft: CornerTopLeft,
  CornerTopRight: CornerTopRight,
  CpuWarning: CpuWarning,
  Cpu: Cpu,
  CrackedEgg: CrackedEgg,
  CreativeCommons: CreativeCommons,
  CreditCardSlash: CreditCardSlash,
  CreditCard: CreditCard,
  CreditCards: CreditCards,
  Crib: Crib,
  CropRotateBl: CropRotateBl,
  CropRotateBr: CropRotateBr,
  CropRotateTl: CropRotateTl,
  CropRotateTr: CropRotateTr,
  Crop: Crop,
  CrownCircle: CrownCircle,
  Crown: Crown,
  Css3: CssThree,
  CubeBandage: CubeBandage,
  CubeCutWithCurve: CubeCutWithCurve,
  CubeHole: CubeHole,
  CubeReplaceFace: CubeReplaceFace,
  Cube: Cube,
  CursorPointer: CursorPointer,
  CurveArray: CurveArray,
  Cut: Cut,
  Cutlery: Cutlery,
  Cycling: Cycling,
  Cylinder: Cylinder,
  DashFlag: DashFlag,
  DashboardDots: DashboardDots,
  DashboardSpeed: DashboardSpeed,
  Dashboard: Dashboard,
  DataTransferBoth: DataTransferBoth,
  DataTransferCheck: DataTransferCheck,
  DataTransferDown: DataTransferDown,
  DataTransferUp: DataTransferUp,
  DataTransferWarning: DataTransferWarning,
  DatabaseBackup: DatabaseBackup,
  DatabaseCheck: DatabaseCheck,
  DatabaseExport: DatabaseExport,
  DatabaseMonitor: DatabaseMonitor,
  DatabaseRestore: DatabaseRestore,
  DatabaseScriptMinus: DatabaseScriptMinus,
  DatabaseScriptPlus: DatabaseScriptPlus,
  DatabaseScript: DatabaseScript,
  DatabaseSearch: DatabaseSearch,
  DatabaseSettings: DatabaseSettings,
  DatabaseStar: DatabaseStar,
  DatabaseStats: DatabaseStats,
  DatabaseTag: DatabaseTag,
  DatabaseWarning: DatabaseWarning,
  DatabaseXmark: DatabaseXmark,
  Database: Database,
  DeCompress: DeCompress,
  DeliveryTruck: DeliveryTruck,
  Delivery: Delivery,
  Depth: Depth,
  DesignNib: DesignNib,
  DesignPencil: DesignPencil,
  Desk: Desk,
  Developer: Developer,
  DewPoint: DewPoint,
  Dialpad: Dialpad,
  Diameter: Diameter,
  DiceFive: DiceFive,
  DiceFour: DiceFour,
  DiceOne: DiceOne,
  DiceSix: DiceSix,
  DiceThree: DiceThree,
  DiceTwo: DiceTwo,
  DimmerSwitch: DimmerSwitch,
  DirectorChair: DirectorChair,
  Discord: Discord,
  Dishwasher: Dishwasher,
  Display4k: DisplayFourk,
  DivideThree: DivideThree,
  Divide: Divide,
  Dna: Dna,
  Dns: Dns,
  DocMagnifyingGlassIn: DocMagnifyingGlassIn,
  DocMagnifyingGlass: DocMagnifyingGlass,
  DocStarIn: DocStarIn,
  DocStar: DocStar,
  DogecoinCircle: DogecoinCircle,
  DogecoinRotateOut: DogecoinRotateOut,
  DollarCircle: DollarCircle,
  Dollar: Dollar,
  DomoticWarning: DomoticWarning,
  Donate: Donate,
  DotArrowDown: DotArrowDown,
  DotArrowLeft: DotArrowLeft,
  DotArrowRight: DotArrowRight,
  DotArrowUp: DotArrowUp,
  DoubleCheck: DoubleCheck,
  DownloadCircle: DownloadCircle,
  DownloadDataWindow: DownloadDataWindow,
  DownloadSquare: DownloadSquare,
  Download: Download,
  DragHandGesture: DragHandGesture,
  Drag: Drag,
  Drawer: Drawer,
  Dribbble: Dribbble,
  DroneChargeFull: DroneChargeFull,
  DroneChargeHalf: DroneChargeHalf,
  DroneChargeLow: DroneChargeLow,
  DroneCheck: DroneCheck,
  DroneLanding: DroneLanding,
  DroneRefresh: DroneRefresh,
  DroneTakeOff: DroneTakeOff,
  DroneXmark: DroneXmark,
  Drone: Drone,
  DropletCheck: DropletCheck,
  DropletHalf: DropletHalf,
  Droplet: Droplet,
  EaseCurveControlPoints: EaseCurveControlPoints,
  EaseInControlPoint: EaseInControlPoint,
  EaseInOut: EaseInOut,
  EaseIn: EaseIn,
  EaseOutControlPoint: EaseOutControlPoint,
  EaseOut: EaseOut,
  EcologyBook: EcologyBook,
  EditPencil: EditPencil,
  Edit: Edit,
  Egg: Egg,
  Eject: Eject,
  ElectronicsChip: ElectronicsChip,
  ElectronicsTransistor: ElectronicsTransistor,
  Elevator: Elevator,
  Ellipse3dThreePoints: EllipseThreedThreePoints,
  Ellipse3d: EllipseThreed,
  EmojiBall: EmojiBall,
  EmojiBlinkLeft: EmojiBlinkLeft,
  EmojiBlinkRight: EmojiBlinkRight,
  EmojiLookDown: EmojiLookDown,
  EmojiLookLeft: EmojiLookLeft,
  EmojiLookRight: EmojiLookRight,
  EmojiLookUp: EmojiLookUp,
  EmojiPuzzled: EmojiPuzzled,
  EmojiQuite: EmojiQuite,
  EmojiReally: EmojiReally,
  EmojiSad: EmojiSad,
  EmojiSatisfied: EmojiSatisfied,
  EmojiSingLeftNote: EmojiSingLeftNote,
  EmojiSingLeft: EmojiSingLeft,
  EmojiSingRightNote: EmojiSingRightNote,
  EmojiSingRight: EmojiSingRight,
  EmojiSurpriseAlt: EmojiSurpriseAlt,
  EmojiSurprise: EmojiSurprise,
  EmojiTalkingAngry: EmojiTalkingAngry,
  EmojiTalkingHappy: EmojiTalkingHappy,
  EmojiThinkLeft: EmojiThinkLeft,
  EmojiThinkRight: EmojiThinkRight,
  Emoji: Emoji,
  EmptyPage: EmptyPage,
  EnergyUsageWindow: EnergyUsageWindow,
  Enlarge: Enlarge,
  Erase: Erase,
  EthereumCircle: EthereumCircle,
  EthereumRotateOut: EthereumRotateOut,
  EuroSquare: EuroSquare,
  Euro: Euro,
  EvChargeAlt: EvChargeAlt,
  EvCharge: EvCharge,
  EvPlugCharging: EvPlugCharging,
  EvPlugXmark: EvPlugXmark,
  EvPlug: EvPlug,
  EvStation: EvStation,
  EvTag: EvTag,
  Exclude: Exclude,
  ExpandLines: ExpandLines,
  Expand: Expand,
  Extrude: Extrude,
  EyeClosed: EyeClosed,
  Eye: Eye,
  FSquare: FSquare,
  Face3dDraft: FaceThreedDraft,
  FaceId: FaceId,
  FacebookTag: FacebookTag,
  Facebook: Facebook,
  Facetime: Facetime,
  Farm: Farm,
  FastArrowDownSquare: FastArrowDownSquare,
  FastArrowDown: FastArrowDown,
  FastArrowLeftSquare: FastArrowLeftSquare,
  FastArrowLeft: FastArrowLeft,
  FastArrowRightSquare: FastArrowRightSquare,
  FastArrowRight: FastArrowRight,
  FastArrowUpSquare: FastArrowUpSquare,
  FastArrowUp: FastArrowUp,
  FastDownCircle: FastDownCircle,
  FastLeftCircle: FastLeftCircle,
  FastRightCircle: FastRightCircle,
  FastUpCircle: FastUpCircle,
  FavouriteBook: FavouriteBook,
  FavouriteWindow: FavouriteWindow,
  Female: Female,
  Figma: Figma,
  FileNotFound: FileNotFound,
  FillColor: FillColor,
  Fillet3d: FilletThreed,
  FilterAlt: FilterAlt,
  FilterListCircle: FilterListCircle,
  FilterList: FilterList,
  Filter: Filter,
  Finder: Finder,
  FingerprintCheckCircle: FingerprintCheckCircle,
  FingerprintCircle: FingerprintCircle,
  FingerprintLockCircle: FingerprintLockCircle,
  FingerprintScan: FingerprintScan,
  FingerprintSquare: FingerprintSquare,
  FingerprintWindow: FingerprintWindow,
  FingerprintXmarkCircle: FingerprintXmarkCircle,
  Fingerprint: Fingerprint,
  FireFlame: FireFlame,
  Fish: Fish,
  Fishing: Fishing,
  Flare: Flare,
  FlashOff: FlashOff,
  Flash: Flash,
  Flask: Flask,
  FlipReverse: FlipReverse,
  Flip: Flip,
  FloppyDiskArrowIn: FloppyDiskArrowIn,
  FloppyDiskArrowOut: FloppyDiskArrowOut,
  FloppyDisk: FloppyDisk,
  Flower: Flower,
  Fog: Fog,
  FolderMinus: FolderMinus,
  FolderPlus: FolderPlus,
  FolderSettings: FolderSettings,
  FolderWarning: FolderWarning,
  Folder: Folder,
  FontQuestion: FontQuestion,
  FootballBall: FootballBall,
  Football: Football,
  Forward15Seconds: ForwardOneFiveSeconds,
  ForwardMessage: ForwardMessage,
  Forward: Forward,
  FrameAltEmpty: FrameAltEmpty,
  FrameAlt: FrameAlt,
  FrameMinusIn: FrameMinusIn,
  FramePlusIn: FramePlusIn,
  FrameSelect: FrameSelect,
  FrameSimple: FrameSimple,
  FrameTool: FrameTool,
  Frame: Frame,
  Fridge: Fridge,
  FxTag: FxTag,
  Fx: Fx,
  Gamepad: Gamepad,
  Garage: Garage,
  GasTankDroplet: GasTankDroplet,
  GasTank: GasTank,
  Gas: Gas,
  GifFormat: GifFormat,
  Gift: Gift,
  GitBranch: GitBranch,
  GitCherryPickCommit: GitCherryPickCommit,
  GitCommit: GitCommit,
  GitCompare: GitCompare,
  GitFork: GitFork,
  GitMerge: GitMerge,
  GitPullRequestClosed: GitPullRequestClosed,
  GitPullRequest: GitPullRequest,
  GithubCircle: GithubCircle,
  Github: Github,
  GitlabFull: GitlabFull,
  GlassEmpty: GlassEmpty,
  GlassFragile: GlassFragile,
  GlassHalfAlt: GlassHalfAlt,
  GlassHalf: GlassHalf,
  Glasses: Glasses,
  Globe: Globe,
  Golf: Golf,
  GoogleCircle: GoogleCircle,
  GoogleDocs: GoogleDocs,
  GoogleDriveCheck: GoogleDriveCheck,
  GoogleDriveSync: GoogleDriveSync,
  GoogleDriveWarning: GoogleDriveWarning,
  GoogleDrive: GoogleDrive,
  GoogleHome: GoogleHome,
  GoogleOne: GoogleOne,
  Google: Google,
  Gps: Gps,
  GraduationCap: GraduationCap,
  GraphDown: GraphDown,
  GraphUp: GraphUp,
  GridMinus: GridMinus,
  GridPlus: GridPlus,
  GridXmark: GridXmark,
  Group: Group,
  Gym: Gym,
  HSquare: HSquare,
  HalfCookie: HalfCookie,
  HalfMoon: HalfMoon,
  Hammer: Hammer,
  HandBrake: HandBrake,
  HandCard: HandCard,
  HandCash: HandCash,
  HandContactless: HandContactless,
  Handbag: Handbag,
  HardDrive: HardDrive,
  Hashtag: Hashtag,
  Hat: Hat,
  HdDisplay: HdDisplay,
  Hd: Hd,
  Hdr: Hdr,
  HeadsetBolt: HeadsetBolt,
  HeadsetHelp: HeadsetHelp,
  HeadsetWarning: HeadsetWarning,
  Headset: Headset,
  HealthShield: HealthShield,
  Healthcare: Healthcare,
  HeartArrowDown: HeartArrowDown,
  Heart: Heart,
  HeatingSquare: HeatingSquare,
  HeavyRain: HeavyRain,
  HelpCircle: HelpCircle,
  HelpSquare: HelpSquare,
  Heptagon: Heptagon,
  HexagonDice: HexagonDice,
  HexagonPlus: HexagonPlus,
  Hexagon: Hexagon,
  HistoricShieldAlt: HistoricShieldAlt,
  HistoricShield: HistoricShield,
  HomeAltSlimHoriz: HomeAltSlimHoriz,
  HomeAltSlim: HomeAltSlim,
  HomeAlt: HomeAlt,
  HomeHospital: HomeHospital,
  HomeSale: HomeSale,
  HomeSecure: HomeSecure,
  HomeShield: HomeShield,
  HomeSimpleDoor: HomeSimpleDoor,
  HomeSimple: HomeSimple,
  HomeTable: HomeTable,
  HomeTemperatureIn: HomeTemperatureIn,
  HomeTemperatureOut: HomeTemperatureOut,
  HomeUser: HomeUser,
  Home: Home,
  HorizDistributionLeft: HorizDistributionLeft,
  HorizDistributionRight: HorizDistributionRight,
  HorizontalMerge: HorizontalMerge,
  HorizontalSplit: HorizontalSplit,
  HospitalCircle: HospitalCircle,
  Hospital: Hospital,
  HotAirBalloon: HotAirBalloon,
  Hourglass: Hourglass,
  HouseRooms: HouseRooms,
  Html5: HtmlFive,
  IceCream: IceCream,
  Iconoir: Iconoir,
  Import: Import,
  Inclination: Inclination,
  Industry: Industry,
  Infinite: Infinite,
  InfoCircle: InfoCircle,
  InputField: InputField,
  InputOutput: InputOutput,
  InputSearch: InputSearch,
  Instagram: Instagram,
  Internet: Internet,
  IntersectAlt: IntersectAlt,
  Intersect: Intersect,
  IosSettings: IosSettings,
  IpAddressTag: IpAddressTag,
  IrisScan: IrisScan,
  ItalicSquare: ItalicSquare,
  Italic: Italic,
  Jellyfish: Jellyfish,
  JournalPage: JournalPage,
  Journal: Journal,
  JpegFormat: JpegFormat,
  JpgFormat: JpgFormat,
  KanbanBoard: KanbanBoard,
  KeyBack: KeyBack,
  KeyCommand: KeyCommand,
  KeyMinus: KeyMinus,
  KeyPlus: KeyPlus,
  KeyXmark: KeyXmark,
  Key: Key,
  KeyframeAlignCenter: KeyframeAlignCenter,
  KeyframeAlignHorizontal: KeyframeAlignHorizontal,
  KeyframeAlignVertical: KeyframeAlignVertical,
  KeyframeMinusIn: KeyframeMinusIn,
  KeyframeMinus: KeyframeMinus,
  KeyframePlusIn: KeyframePlusIn,
  KeyframePlus: KeyframePlus,
  KeyframePosition: KeyframePosition,
  Keyframe: Keyframe,
  KeyframesCouple: KeyframesCouple,
  KeyframesMinus: KeyframesMinus,
  KeyframesPlus: KeyframesPlus,
  Keyframes: Keyframes,
  Label: Label,
  Lamp: Lamp,
  Language: Language,
  LaptopCharging: LaptopCharging,
  LaptopDevMode: LaptopDevMode,
  LaptopFix: LaptopFix,
  LaptopWarning: LaptopWarning,
  Laptop: Laptop,
  LayoutLeft: LayoutLeft,
  LayoutRight: LayoutRight,
  LeaderboardStar: LeaderboardStar,
  Leaderboard: Leaderboard,
  Leaf: Leaf,
  Learning: Learning,
  LensPlus: LensPlus,
  Lens: Lens,
  Lifebelt: Lifebelt,
  LightBulbOff: LightBulbOff,
  LightBulbOn: LightBulbOn,
  LightBulb: LightBulb,
  LineSpace: LineSpace,
  Linear: Linear,
  LinkSlash: LinkSlash,
  LinkXmark: LinkXmark,
  Link: Link,
  Linkedin: Linkedin,
  Linux: Linux,
  ListSelect: ListSelect,
  List: List,
  LitecoinCircle: LitecoinCircle,
  LitecoinRotateOut: LitecoinRotateOut,
  LockSlash: LockSlash,
  LockSquare: LockSquare,
  Lock: Lock,
  Loft3d: LoftThreed,
  LogIn: LogIn,
  LogNoAccess: LogNoAccess,
  LogOut: LogOut,
  LongArrowDownLeft: LongArrowDownLeft,
  LongArrowDownRight: LongArrowDownRight,
  LongArrowLeftDown: LongArrowLeftDown,
  LongArrowLeftUp: LongArrowLeftUp,
  LongArrowRightDown: LongArrowRightDown,
  LongArrowRightUp: LongArrowRightUp,
  LongArrowUpLeft: LongArrowUpLeft,
  LongArrowUpRight: LongArrowUpRight,
  LotOfCash: LotOfCash,
  Lullaby: Lullaby,
  MacControlKey: MacControlKey,
  MacDock: MacDock,
  MacOptionKey: MacOptionKey,
  MacOsWindow: MacOsWindow,
  MagicWand: MagicWand,
  MagnetEnergy: MagnetEnergy,
  Magnet: Magnet,
  MailIn: MailIn,
  MailOpen: MailOpen,
  MailOut: MailOut,
  Mail: Mail,
  Male: Male,
  MapPinMinus: MapPinMinus,
  MapPinPlus: MapPinPlus,
  MapPinXmark: MapPinXmark,
  MapPin: MapPin,
  MapXmark: MapXmark,
  Map: Map,
  MapsArrowDiagonal: MapsArrowDiagonal,
  MapsArrowXmark: MapsArrowXmark,
  MapsArrow: MapsArrow,
  MapsGoStraight: MapsGoStraight,
  MapsTurnBack: MapsTurnBack,
  MapsTurnLeft: MapsTurnLeft,
  MapsTurnRight: MapsTurnRight,
  MaskSquare: MaskSquare,
  MastercardCard: MastercardCard,
  Mastodon: Mastodon,
  MathBook: MathBook,
  Maximize: Maximize,
  Medal1st: MedalOnest,
  Medal: Medal,
  MediaImageFolder: MediaImageFolder,
  MediaImageList: MediaImageList,
  MediaImagePlus: MediaImagePlus,
  MediaImageXmark: MediaImageXmark,
  MediaImage: MediaImage,
  MediaVideoFolder: MediaVideoFolder,
  MediaVideoList: MediaVideoList,
  MediaVideoPlus: MediaVideoPlus,
  MediaVideoXmark: MediaVideoXmark,
  MediaVideo: MediaVideo,
  Medium: Medium,
  Megaphone: Megaphone,
  MenuScale: MenuScale,
  Menu: Menu,
  MessageAlert: MessageAlert,
  MessageText: MessageText,
  Message: Message,
  MeterArrowDownRight: MeterArrowDownRight,
  Metro: Metro,
  MicrophoneCheck: MicrophoneCheck,
  MicrophoneMinus: MicrophoneMinus,
  MicrophoneMute: MicrophoneMute,
  MicrophonePlus: MicrophonePlus,
  MicrophoneSpeaking: MicrophoneSpeaking,
  MicrophoneWarning: MicrophoneWarning,
  Microphone: Microphone,
  Microscope: Microscope,
  MinusCircle: MinusCircle,
  MinusHexagon: MinusHexagon,
  MinusSquareDashed: MinusSquareDashed,
  MinusSquare: MinusSquare,
  Minus: Minus,
  Mirror: Mirror,
  MobileDevMode: MobileDevMode,
  MobileFingerprint: MobileFingerprint,
  MobileVoice: MobileVoice,
  ModernTv4k: ModernTvFourk,
  ModernTv: ModernTv,
  MoneySquare: MoneySquare,
  MoonSat: MoonSat,
  MoreHorizCircle: MoreHorizCircle,
  MoreHoriz: MoreHoriz,
  MoreVertCircle: MoreVertCircle,
  MoreVert: MoreVert,
  Motorcycle: Motorcycle,
  MouseButtonLeft: MouseButtonLeft,
  MouseButtonRight: MouseButtonRight,
  MouseScrollWheel: MouseScrollWheel,
  Movie: Movie,
  MpegFormat: MpegFormat,
  MultiBubble: MultiBubble,
  MultiMacOsWindow: MultiMacOsWindow,
  MultiWindow: MultiWindow,
  MultiplePagesEmpty: MultiplePagesEmpty,
  MultiplePagesMinus: MultiplePagesMinus,
  MultiplePagesPlus: MultiplePagesPlus,
  MultiplePagesXmark: MultiplePagesXmark,
  MultiplePages: MultiplePages,
  MusicDoubleNotePlus: MusicDoubleNotePlus,
  MusicDoubleNote: MusicDoubleNote,
  MusicNotePlus: MusicNotePlus,
  MusicNote: MusicNote,
  NSquare: NSquare,
  NavArrowDown: NavArrowDown,
  NavArrowLeft: NavArrowLeft,
  NavArrowRight: NavArrowRight,
  NavArrowUp: NavArrowUp,
  NavigatorAlt: NavigatorAlt,
  Navigator: Navigator,
  Neighbourhood: Neighbourhood,
  NetworkLeft: NetworkLeft,
  NetworkReverse: NetworkReverse,
  NetworkRight: NetworkRight,
  Network: Network,
  NewTab: NewTab,
  NintendoSwitch: NintendoSwitch,
  NoSmokingCircle: NoSmokingCircle,
  NonBinary: NonBinary,
  Notes: Notes,
  NpmSquare: NpmSquare,
  Npm: Npm,
  Number0Square: NumberZeroSquare,
  Number1Square: NumberOneSquare,
  Number2Square: NumberTwoSquare,
  Number3Square: NumberThreeSquare,
  Number4Square: NumberFourSquare,
  Number5Square: NumberFiveSquare,
  Number6Square: NumberSixSquare,
  Number7Square: NumberSevenSquare,
  Number8Square: NumberEightSquare,
  Number9Square: NumberNineSquare,
  NumberedListLeft: NumberedListLeft,
  NumberedListRight: NumberedListRight,
  OSquare: OSquare,
  Octagon: Octagon,
  OffTag: OffTag,
  OilIndustry: OilIndustry,
  Okrs: Okrs,
  OnTag: OnTag,
  OneFingerSelectHandGesture: OneFingerSelectHandGesture,
  OnePointCircle: OnePointCircle,
  OpenBook: OpenBook,
  OpenInBrowser: OpenInBrowser,
  OpenInWindow: OpenInWindow,
  OpenNewWindow: OpenNewWindow,
  OpenSelectHandGesture: OpenSelectHandGesture,
  OpenVpn: OpenVpn,
  OrangeHalf: OrangeHalf,
  OrangeSliceAlt: OrangeSliceAlt,
  OrangeSlice: OrangeSlice,
  OrganicFoodSquare: OrganicFoodSquare,
  OrganicFood: OrganicFood,
  OrthogonalView: OrthogonalView,
  PackageLock: PackageLock,
  Package: Package,
  Packages: Packages,
  Pacman: Pacman,
  PageDown: PageDown,
  PageEdit: PageEdit,
  PageFlip: PageFlip,
  PageLeft: PageLeft,
  PageMinusIn: PageMinusIn,
  PageMinus: PageMinus,
  PagePlusIn: PagePlusIn,
  PagePlus: PagePlus,
  PageRight: PageRight,
  PageSearch: PageSearch,
  PageStar: PageStar,
  PageUp: PageUp,
  Page: Page,
  Palette: Palette,
  PanoramaEnlarge: PanoramaEnlarge,
  PanoramaReduce: PanoramaReduce,
  PantsPockets: PantsPockets,
  Pants: Pants,
  Parking: Parking,
  PasswordCheck: PasswordCheck,
  PasswordCursor: PasswordCursor,
  PasswordXmark: PasswordXmark,
  PasteClipboard: PasteClipboard,
  PathArrow: PathArrow,
  PauseWindow: PauseWindow,
  Pause: Pause,
  Paypal: Paypal,
  PcCheck: PcCheck,
  PcFirewall: PcFirewall,
  PcMouse: PcMouse,
  PcNoEntry: PcNoEntry,
  PcWarning: PcWarning,
  PeaceHand: PeaceHand,
  Peerlist: Peerlist,
  PenConnectBluetooth: PenConnectBluetooth,
  PenConnectWifi: PenConnectWifi,
  PenTabletConnectUsb: PenTabletConnectUsb,
  PenTabletConnectWifi: PenTabletConnectWifi,
  PenTablet: PenTablet,
  Pentagon: Pentagon,
  PeopleTag: PeopleTag,
  PercentRotateOut: PercentRotateOut,
  PercentageCircle: PercentageCircle,
  PercentageSquare: PercentageSquare,
  Percentage: Percentage,
  PerspectiveView: PerspectiveView,
  PharmacyCrossCircle: PharmacyCrossCircle,
  PharmacyCrossTag: PharmacyCrossTag,
  PhoneDisabled: PhoneDisabled,
  PhoneIncome: PhoneIncome,
  PhoneMinus: PhoneMinus,
  PhoneOutcome: PhoneOutcome,
  PhonePaused: PhonePaused,
  PhonePlus: PhonePlus,
  PhoneXmark: PhoneXmark,
  Phone: Phone,
  PiggyBank: PiggyBank,
  Pillow: Pillow,
  PinSlash: PinSlash,
  Pin: Pin,
  PineTree: PineTree,
  Pinterest: Pinterest,
  Pipe3d: PipeThreed,
  PizzaSlice: PizzaSlice,
  PlanetAlt: PlanetAlt,
  PlanetSat: PlanetSat,
  Planet: Planet,
  Planimetry: Planimetry,
  Play: Play,
  PlaylistPlay: PlaylistPlay,
  PlaylistPlus: PlaylistPlus,
  Playlist: Playlist,
  PlaystationGamepad: PlaystationGamepad,
  PlugTypeA: PlugTypeA,
  PlugTypeC: PlugTypeC,
  PlugTypeG: PlugTypeG,
  PlugTypeL: PlugTypeL,
  PlusCircle: PlusCircle,
  PlusSquareDashed: PlusSquareDashed,
  PlusSquare: PlusSquare,
  Plus: Plus,
  PngFormat: PngFormat,
  Pocket: Pocket,
  Podcast: Podcast,
  Pokeball: Pokeball,
  PolarSh: PolarSh,
  PositionAlign: PositionAlign,
  Position: Position,
  Post: Post,
  Potion: Potion,
  Pound: Pound,
  PrecisionTool: PrecisionTool,
  Presentation: Presentation,
  Printer: Printer,
  PrintingPage: PrintingPage,
  PriorityDown: PriorityDown,
  PriorityHigh: PriorityHigh,
  PriorityMedium: PriorityMedium,
  PriorityUp: PriorityUp,
  PrivacyPolicy: PrivacyPolicy,
  PrivateWifi: PrivateWifi,
  ProfileCircle: ProfileCircle,
  Prohibition: Prohibition,
  ProjectCurve3d: ProjectCurveThreed,
  Puzzle: Puzzle,
  QrCode: QrCode,
  QuestionMark: QuestionMark,
  QuoteMessage: QuoteMessage,
  Quote: Quote,
  Radiation: Radiation,
  Radius: Radius,
  Rain: Rain,
  RawFormat: RawFormat,
  ReceiveDollars: ReceiveDollars,
  ReceiveEuros: ReceiveEuros,
  ReceivePounds: ReceivePounds,
  ReceiveYens: ReceiveYens,
  RedoAction: RedoAction,
  RedoCircle: RedoCircle,
  Redo: Redo,
  Reduce: Reduce,
  RefreshCircle: RefreshCircle,
  RefreshDouble: RefreshDouble,
  Refresh: Refresh,
  ReloadWindow: ReloadWindow,
  ReminderHandGesture: ReminderHandGesture,
  RepeatOnce: RepeatOnce,
  Repeat: Repeat,
  ReplyToMessage: ReplyToMessage,
  Reply: Reply,
  ReportColumns: ReportColumns,
  Reports: Reports,
  Repository: Repository,
  Restart: Restart,
  Rewind: Rewind,
  RhombusArrowRight: RhombusArrowRight,
  Rhombus: Rhombus,
  Rings: Rings,
  Rocket: Rocket,
  Rook: Rook,
  RotateCameraLeft: RotateCameraLeft,
  RotateCameraRight: RotateCameraRight,
  RoundFlask: RoundFlask,
  RoundedMirror: RoundedMirror,
  RssFeedTag: RssFeedTag,
  RssFeed: RssFeed,
  RubikCube: RubikCube,
  RulerArrows: RulerArrows,
  RulerCombine: RulerCombine,
  RulerMinus: RulerMinus,
  RulerPlus: RulerPlus,
  Ruler: Ruler,
  Running: Running,
  Safari: Safari,
  SafeArrowLeft: SafeArrowLeft,
  SafeArrowRight: SafeArrowRight,
  SafeOpen: SafeOpen,
  Safe: Safe,
  Sandals: Sandals,
  ScaleFrameEnlarge: ScaleFrameEnlarge,
  ScaleFrameReduce: ScaleFrameReduce,
  ScanBarcode: ScanBarcode,
  ScanQrCode: ScanQrCode,
  Scanning: Scanning,
  Scarf: Scarf,
  ScissorAlt: ScissorAlt,
  Scissor: Scissor,
  Screenshot: Screenshot,
  SeaAndSun: SeaAndSun,
  SeaWaves: SeaWaves,
  SearchEngine: SearchEngine,
  SearchWindow: SearchWindow,
  Search: Search,
  SecureWindow: SecureWindow,
  SecurityPass: SecurityPass,
  SelectEdge3d: SelectEdgeThreed,
  SelectFace3d: SelectFaceThreed,
  SelectPoint3d: SelectPointThreed,
  SelectWindow: SelectWindow,
  SelectiveTool: SelectiveTool,
  SendDiagonal: SendDiagonal,
  SendDollars: SendDollars,
  SendEuros: SendEuros,
  SendMail: SendMail,
  SendPounds: SendPounds,
  SendYens: SendYens,
  Send: Send,
  ServerConnection: ServerConnection,
  Server: Server,
  SettingsProfiles: SettingsProfiles,
  Settings: Settings,
  ShareAndroid: ShareAndroid,
  ShareIos: ShareIos,
  ShieldAlert: ShieldAlert,
  ShieldAlt: ShieldAlt,
  ShieldBroken: ShieldBroken,
  ShieldCheck: ShieldCheck,
  ShieldDownload: ShieldDownload,
  ShieldEye: ShieldEye,
  ShieldLoading: ShieldLoading,
  ShieldMinus: ShieldMinus,
  ShieldPlusIn: ShieldPlusIn,
  ShieldQuestion: ShieldQuestion,
  ShieldSearch: ShieldSearch,
  ShieldUpload: ShieldUpload,
  ShieldXmark: ShieldXmark,
  Shield: Shield,
  ShirtTankTop: ShirtTankTop,
  Shirt: Shirt,
  ShopFourTilesWindow: ShopFourTilesWindow,
  ShopFourTiles: ShopFourTiles,
  ShopWindow: ShopWindow,
  Shop: Shop,
  ShoppingBagArrowDown: ShoppingBagArrowDown,
  ShoppingBagArrowUp: ShoppingBagArrowUp,
  ShoppingBagCheck: ShoppingBagCheck,
  ShoppingBagMinus: ShoppingBagMinus,
  ShoppingBagPlus: ShoppingBagPlus,
  ShoppingBagPocket: ShoppingBagPocket,
  ShoppingBagWarning: ShoppingBagWarning,
  ShoppingBag: ShoppingBag,
  ShoppingCodeCheck: ShoppingCodeCheck,
  ShoppingCodeXmark: ShoppingCodeXmark,
  ShoppingCode: ShoppingCode,
  ShortPantsPockets: ShortPantsPockets,
  ShortPants: ShortPants,
  ShortcutSquare: ShortcutSquare,
  Shuffle: Shuffle,
  SidebarCollapse: SidebarCollapse,
  SidebarExpand: SidebarExpand,
  SigmaFunction: SigmaFunction,
  SimpleCart: SimpleCart,
  SineWave: SineWave,
  SingleTapGesture: SingleTapGesture,
  Skateboard: Skateboard,
  Skateboarding: Skateboarding,
  SkipNext: SkipNext,
  SkipPrev: SkipPrev,
  SlashSquare: SlashSquare,
  Slash: Slash,
  SleeperChair: SleeperChair,
  Slips: Slips,
  SmallLampAlt: SmallLampAlt,
  SmallLamp: SmallLamp,
  SmartphoneDevice: SmartphoneDevice,
  Smoking: Smoking,
  Snapchat: Snapchat,
  SnowFlake: SnowFlake,
  Snow: Snow,
  Soap: Soap,
  SoccerBall: SoccerBall,
  Sofa: Sofa,
  SoilAlt: SoilAlt,
  Soil: Soil,
  SortDown: SortDown,
  SortUp: SortUp,
  Sort: Sort,
  SoundHigh: SoundHigh,
  SoundLow: SoundLow,
  SoundMin: SoundMin,
  SoundOff: SoundOff,
  Spades: Spades,
  Spark: Spark,
  Sparks: Sparks,
  Sphere: Sphere,
  Spiral: Spiral,
  SplitArea: SplitArea,
  SplitSquareDashed: SplitSquareDashed,
  SpockHandGesture: SpockHandGesture,
  Spotify: Spotify,
  Square3dCornerToCorner: SquareThreedCornerToCorner,
  Square3dFromCenter: SquareThreedFromCenter,
  Square3dThreePoints: SquareThreedThreePoints,
  SquareCursor: SquareCursor,
  SquareDashed: SquareDashed,
  SquareWave: SquareWave,
  Square: Square,
  Stackoverflow: Stackoverflow,
  StarDashed: StarDashed,
  StarHalfDashed: StarHalfDashed,
  Star: Star,
  StatDown: StatDown,
  StatUp: StatUp,
  StatsDownSquare: StatsDownSquare,
  StatsReport: StatsReport,
  StatsUpSquare: StatsUpSquare,
  Strategy: Strategy,
  Stretching: Stretching,
  Strikethrough: Strikethrough,
  Stroller: Stroller,
  StyleBorder: StyleBorder,
  SubmitDocument: SubmitDocument,
  Substract: Substract,
  Suggestion: Suggestion,
  Suitcase: Suitcase,
  SunLight: SunLight,
  SvgFormat: SvgFormat,
  Sweep3d: SweepThreed,
  Swimming: Swimming,
  SwipeDownGesture: SwipeDownGesture,
  SwipeLeftGesture: SwipeLeftGesture,
  SwipeRightGesture: SwipeRightGesture,
  SwipeTwoFingersDownGesture: SwipeTwoFingersDownGesture,
  SwipeTwoFingersLeftGesture: SwipeTwoFingersLeftGesture,
  SwipeTwoFingersRightGesture: SwipeTwoFingersRightGesture,
  SwipeTwoFingersUpGesture: SwipeTwoFingersUpGesture,
  SwipeUpGesture: SwipeUpGesture,
  SwitchOff: SwitchOff,
  SwitchOn: SwitchOn,
  SystemRestart: SystemRestart,
  SystemShut: SystemShut,
  Table2Columns: TableTwoColumns,
  TableRows: TableRows,
  Table: Table,
  TaskList: TaskList,
  TelegramCircle: TelegramCircle,
  Telegram: Telegram,
  TemperatureDown: TemperatureDown,
  TemperatureHigh: TemperatureHigh,
  TemperatureLow: TemperatureLow,
  TemperatureUp: TemperatureUp,
  TennisBallAlt: TennisBallAlt,
  TennisBall: TennisBall,
  TerminalTag: TerminalTag,
  Terminal: Terminal,
  TestTube: TestTube,
  TextArrowsUpDown: TextArrowsUpDown,
  TextBox: TextBox,
  TextMagnifyingGlass: TextMagnifyingGlass,
  TextSize: TextSize,
  TextSquare: TextSquare,
  Text: Text,
  Threads: Threads,
  ThreePointsCircle: ThreePointsCircle,
  ThreeStars: ThreeStars,
  ThumbsDown: ThumbsDown,
  ThumbsUp: ThumbsUp,
  Thunderstorm: Thunderstorm,
  TifFormat: TifFormat,
  TiffFormat: TiffFormat,
  Tiktok: Tiktok,
  TimeZone: TimeZone,
  TimerOff: TimerOff,
  Timer: Timer,
  Tools: Tools,
  Tournament: Tournament,
  TowerCheck: TowerCheck,
  TowerNoAccess: TowerNoAccess,
  TowerWarning: TowerWarning,
  Tower: Tower,
  Trademark: Trademark,
  Train: Train,
  Tram: Tram,
  TransitionDown: TransitionDown,
  TransitionLeft: TransitionLeft,
  TransitionRight: TransitionRight,
  TransitionUp: TransitionUp,
  Translate: Translate,
  Trash: Trash,
  Treadmill: Treadmill,
  Tree: Tree,
  Trekking: Trekking,
  Trello: Trello,
  TriangleFlagCircle: TriangleFlagCircle,
  TriangleFlagTwoStripes: TriangleFlagTwoStripes,
  TriangleFlag: TriangleFlag,
  Triangle: Triangle,
  Trophy: Trophy,
  TruckGreen: TruckGreen,
  TruckLength: TruckLength,
  Truck: Truck,
  Tunnel: Tunnel,
  TvFix: TvFix,
  TvWarning: TvWarning,
  Tv: Tv,
  Twitter: Twitter,
  TwoPointsCircle: TwoPointsCircle,
  TwoSeaterSofa: TwoSeaterSofa,
  Type: Type,
  UTurnArrowLeft: UTurnArrowLeft,
  UTurnArrowRight: UTurnArrowRight,
  Umbrella: Umbrella,
  UnderlineSquare: UnderlineSquare,
  Underline: Underline,
  UndoAction: UndoAction,
  UndoCircle: UndoCircle,
  Undo: Undo,
  UnionAlt: UnionAlt,
  UnionHorizAlt: UnionHorizAlt,
  Union: Union,
  Unity5: UnityFive,
  Unity: Unity,
  Unjoin3d: UnjoinThreed,
  UploadDataWindow: UploadDataWindow,
  UploadSquare: UploadSquare,
  Upload: Upload,
  Usb: Usb,
  UserBadgeCheck: UserBadgeCheck,
  UserBag: UserBag,
  UserCart: UserCart,
  UserCircle: UserCircle,
  UserCrown: UserCrown,
  UserLove: UserLove,
  UserPlus: UserPlus,
  UserScan: UserScan,
  UserSquare: UserSquare,
  UserStar: UserStar,
  UserXmark: UserXmark,
  User: User,
  VeganCircle: VeganCircle,
  VeganSquare: VeganSquare,
  Vegan: Vegan,
  VehicleGreen: VehicleGreen,
  VerticalMerge: VerticalMerge,
  VerticalSplit: VerticalSplit,
  Vials: Vials,
  VideoCameraOff: VideoCameraOff,
  VideoCamera: VideoCamera,
  VideoProjector: VideoProjector,
  View360: ViewThreeSixZero,
  ViewColumns2: ViewColumnsTwo,
  ViewColumns3: ViewColumnsThree,
  ViewGrid: ViewGrid,
  ViewStructureDown: ViewStructureDown,
  ViewStructureUp: ViewStructureUp,
  VoiceCheck: VoiceCheck,
  VoiceCircle: VoiceCircle,
  VoiceLockCircle: VoiceLockCircle,
  VoiceScan: VoiceScan,
  VoiceSquare: VoiceSquare,
  VoiceXmark: VoiceXmark,
  Voice: Voice,
  VrTag: VrTag,
  VueJs: VueJs,
  Waist: Waist,
  Walking: Walking,
  Wallet: Wallet,
  WarningCircle: WarningCircle,
  WarningHexagon: WarningHexagon,
  WarningSquare: WarningSquare,
  WarningTriangle: WarningTriangle,
  WarningWindow: WarningWindow,
  Wash: Wash,
  WashingMachine: WashingMachine,
  WateringSoil: WateringSoil,
  WebWindowEnergyConsumption: WebWindowEnergyConsumption,
  WebWindowXmark: WebWindowXmark,
  WebWindow: WebWindow,
  WebpFormat: WebpFormat,
  WeightAlt: WeightAlt,
  Weight: Weight,
  WhiteFlag: WhiteFlag,
  WifiOff: WifiOff,
  WifiSignalNone: WifiSignalNone,
  WifiTag: WifiTag,
  WifiWarning: WifiWarning,
  WifiXmark: WifiXmark,
  Wifi: Wifi,
  Wind: Wind,
  WindowCheck: WindowCheck,
  WindowLock: WindowLock,
  WindowNoAccess: WindowNoAccess,
  WindowXmark: WindowXmark,
  Windows: Windows,
  Wolf: Wolf,
  WrapText: WrapText,
  Wrench: Wrench,
  Wristwatch: Wristwatch,
  Www: Www,
  XSquare: XSquare,
  X: X,
  XboxA: XboxA,
  XboxB: XboxB,
  XboxX: XboxX,
  XboxY: XboxY,
  XmarkCircle: XmarkCircle,
  XmarkSquare: XmarkSquare,
  Xmark: Xmark,
  XrayView: XrayView,
  YSquare: YSquare,
  Yelp: Yelp,
  YenSquare: YenSquare,
  Yen: Yen,
  Yoga: Yoga,
  Youtube: Youtube,
  ZSquare: ZSquare,
  ZoomIn: ZoomIn,
  ZoomOut: ZoomOut,
}
export { Icons }
export type NuiIconsType = keyof typeof Icons
