import React, { SVGProps } from 'react'

export default function MicrophoneWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21 14L21 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 22.01L21.01 21.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='7' y='2' width='6' height='12' rx='3' stroke='currentColor' strokeWidth='1.5' />
      <path
        d='M3 10V11C3 14.866 6.13401 18 10 18V18V18C13.866 18 17 14.866 17 11V10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 18V22M10 22H7M10 22H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
