import React, { SVGProps } from 'react'

export default function Screenshot(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 21.4V14.3937C10 14.0623 10.2686 13.7937 10.6 13.7937H11.7728C11.9768 13.7937 12.1667 13.6901 12.2772 13.5186L13.7228 11.275C13.8333 11.1036 14.0232 11 14.2272 11H17.7728C17.9768 11 18.1667 11.1036 18.2772 11.275L19.7228 13.5186C19.8333 13.6901 20.0232 13.7937 20.2272 13.7937H21.4C21.7314 13.7937 22 14.0623 22 14.3937V21.4C22 21.7314 21.7314 22 21.4 22H10.6C10.2686 22 10 21.7314 10 21.4Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 19C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15C14.8954 15 14 15.8954 14 17C14 18.1046 14.8954 19 16 19Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 18V21H5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 9.5L3 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 6V3H6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 3L14.5 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 3H21V5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 10V8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
