import React, { SVGProps } from 'react'

export default function Cloudways(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Cloudways</title>
      <path d='M15.795 5.522a4.938 4.938 0 0 0-2.485.717.434.434 0 0 1-.236.066c-2.717-.002-5.434-.002-8.151-.002a.594.594 0 0 0-.594.45.573.573 0 0 0 .284.66c.112.056.25.082.377.082 2.25.005 4.499.003 6.749.004.044 0 .09.003.152.006l-.09.13a4.942 4.942 0 0 0-.674 1.302c-.03.09-.075.113-.166.113-2.545-.003-4.844-.003-7.193-.002a.602.602 0 0 0-.581.456.584.584 0 0 0 .312.668.875.875 0 0 0 .35.069c1.924.004 3.848.003 5.771.003h.123a.22.22 0 0 1-.074.085c-.5.367-.908.819-1.218 1.356-.046.08-.095.109-.188.109a5042.02 5042.02 0 0 0-7.687-.002.598.598 0 0 0-.566.706c.06.3.3.49.624.49h7.304a5.091 5.091 0 0 0-.074 1.567H7.27c-.233 0-.357.117-.364.349a7.604 7.604 0 0 0 0 .474c.006.236.132.354.37.355.269.001.538.003.807-.001.075-.002.112.023.142.092.186.428.439.816.75 1.165.082.092.168.18.274.292h-5.01a.602.602 0 0 0-.574.48.585.585 0 0 0 .35.664.841.841 0 0 0 .305.051c5.458.004 10.345.01 15.371-.018a4.032 4.032 0 0 0 1.121-.172c2.333-.688 3.704-3.159 3.006-5.566-.434-1.496-1.413-2.504-2.888-3.02-.067-.024-.095-.054-.108-.124a4.072 4.072 0 0 0-.098-.39c-.645-2.184-2.653-3.712-4.929-3.664zM.758 14.556a.31.31 0 0 0-.323.315c-.004.158-.002.316-.002.474.001.274.119.388.396.388h4.163c.203 0 .351-.125.357-.306a9.126 9.126 0 0 0 0-.544c-.006-.192-.14-.327-.327-.327H.758z' />
    </svg>
  )
}
