import React, { SVGProps } from 'react'

export default function Ibmwatson(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>IBM Watson</title>
      <path d='M14.834 20.264c-2.534 0-5.224-1.257-7.136-3.002.989-4.477 4.969-9.45 7.522-9.45.289 0 .533.06.865.256.505.325.963.688 1.349 1.071 1.166 1.157 2.264 3.117 2.264 5.517 0 1.878-.633 3.339-1.307 4.241-.733.981-2.111 1.367-3.557 1.367m-2.97 2.038c-1.65 0-3.016-.569-3.612-.87-.797-.416-1.006-1.171-1.006-2.127 0-.278.018-.563.052-.852 2.011 1.622 4.69 2.765 7.377 2.765.591 0 1.134-.097 1.626-.228l.001.001c-.897.625-2.492 1.311-4.438 1.311M12.458 6a8.36 8.36 0 0 0-2.452.403c-.051.017-.112.047-.112.101 0 .058.046.087.083.087.038 0 .165-.033.249-.046a7.676 7.676 0 0 1 1.455-.111c.789 0 1.599.16 2.375.431-3.099 1.173-6.152 5.54-7.211 9.453-1.125-1.275-1.832-2.723-1.832-4.109 0-2.272 1.846-3.668 4.524-3.668.1 0 .154 0 .197-.011a.083.083 0 0 0 .065-.083c0-.049-.035-.077-.099-.088a6.665 6.665 0 0 0-1.027-.08c-2.658 0-4.593 1.396-4.593 3.936 0 1.728.981 3.601 2.523 5.18a9.178 9.178 0 0 0-.151 1.586c0 .197.02.611.089 1.046-.919-.83-1.633-1.826-2.02-2.876-.076-.206-.22-.585-.258-.743-.019-.077-.046-.104-.103-.104-.049 0-.085.038-.085.097 0 .069.027.241.037.295.526 2.893 3.336 6.511 7.909 6.511 5.074 0 8.582-4.272 8.582-8.718C20.604 9.897 17.159 6 12.458 6m9.072 3.786l2.237-1.315a.48.48 0 0 0 .171-.649.462.462 0 0 0-.629-.178l-.008.005-2.237 1.315a.48.48 0 0 0-.171.648.468.468 0 0 0 .404.238.473.473 0 0 0 .233-.064M17.641 5.54l1.315-2.237a.462.462 0 0 0-.165-.632l-.009-.005a.478.478 0 0 0-.648.171l-1.315 2.237a.462.462 0 0 0 .165.632l.009.005a.482.482 0 0 0 .648-.171m-5.188-1.605V1.273c0-.265-.203-.48-.453-.48s-.453.215-.453.48v2.661c0 .266.203.481.453.481s.453-.215.453-.48M6.782 5.711a.46.46 0 0 0 .178-.628l-.004-.009-1.315-2.237a.48.48 0 0 0-.648-.171.463.463 0 0 0-.179.629l.005.009L6.133 5.54a.476.476 0 0 0 .649.171M2.703 9.849a.461.461 0 0 1-.233-.063L.234 8.471a.48.48 0 0 1-.171-.649.462.462 0 0 1 .628-.179l.009.006 2.237 1.315a.48.48 0 0 1 .171.648.465.465 0 0 1-.405.237' />
    </svg>
  )
}
