import React, { SVGProps } from 'react'

export default function CompAlignTop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M22 3L2 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 19V9C8 7.89543 8.89543 7 10 7H14C15.1046 7 16 7.89543 16 9V19C16 20.1046 15.1046 21 14 21H10C8.89543 21 8 20.1046 8 19Z'
        stroke='currentColor'
      />
    </svg>
  )
}
