import React, { SVGProps } from 'react'

export default function TemperatureLow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6 11.9995C4.78555 12.9117 4 14.3641 4 15.9999C4 18.7613 6.23858 20.9999 9 20.9999C11.7614 20.9999 14 18.7613 14 15.9999C14 14.3641 13.2144 12.9117 12 11.9995'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6 12V3H12V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 3L14 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 6L14 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 9H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19 7C20.1046 7 21 6.10457 21 5C21 3.89543 20.1046 3 19 3C17.8954 3 17 3.89543 17 5C17 6.10457 17.8954 7 19 7Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 14C7.89543 14 7 14.8954 7 16C7 17.1046 7.89543 18 9 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9 14ZM9 14V11'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
