import React, { SVGProps } from 'react'

export default function MobileDevMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 19.01L12.01 18.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M18 18V21.4C18 21.7314 17.7314 22 17.4 22H6.6C6.26863 22 6 21.7314 6 21.4V18'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M18 6V2.6C18 2.26863 17.7314 2 17.4 2H6.6C6.26863 2 6 2.26863 6 2.6V6'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M15.5 8.5L19 12L15.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 8.5L5 12L8.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
