import React, { SVGProps } from 'react'

export default function Jovian(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Jovian</title>
      <path d='M20.25 1.65C20.25.74 19.51 0 18.6 0H5.4c-.91 0-1.65.74-1.65 1.65v20.7c0 .91.74 1.65 1.65 1.65h13.2c.91 0 1.65-.74 1.65-1.65V1.65zm-5.275 4.341a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm.04 9.018a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-6.015 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z' />
    </svg>
  )
}
