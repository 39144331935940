import { Lesson } from "../../../lessons"
import { rechennetzCategory } from "../../category"
import { icBios } from "../../index_cards"

const vBios = `
Auf dem **B**asic **I**nput/**O**utput **S**ystem befindet sich die Firmware, diese beschreibt die Software, die fest in der Hardware implementiert ist.
Es führt den Startprozeduren durch, überprüft die Systemgeräte und sorgt mithilfe des Bootstrapping dafür, dass das Betriebssystem geladen werden kann, es ist das aller erste was bei einem Computer startet.
Der Workflow beim Booten sieht, von der BIOS seite, ungefähr wie zufolgt aus:

1. Prüfen ob alle Hardware Componenten erreichbar sind, dies ist der **P**ower-**O**n-**S**elf-**T**est.
2. Sucht nach einem Vorhanden Betriebssystem und vermittelt dies mit den anderen Hardware komponenten.
3. Das Betriebssystem wird geladen.
4. Das Betriebssystem wird ausgeführt

\`\`\`mermaid
graph TD;
A(Power On) --> B{POST}
B --> |Fehler| C(Peep Töne und eine \\n Fehlermeldung auf  \\n dem Bildschirm \\n \\n)
B --> |Erfolg| D{Sucht nach dem \\n Betriebssystem in \\n der Boot Driver \\n \\n}
D --> |Fehler| B
D --> |Erfolg| E(Load Boot program)
E --> F(Starten des Betriebssystem)
\`\`\`

Der **P**ower-**O**n-**S**elf-**T**est stelltfest, ob die Hardware ordnungsgemäß funktioniert. Wenn bei dem Test ein Fehler auftritt, kann der PC heruntergefahren werden.
Das Motherboards kann eine Reihe von Pieptönen von sich geben, oder verwenden farbwechselnde LEDs oder zeigen einen Fehlercode auf einem eingebauten Display an.

Während des POST können Benutzer einen Hotkey verwenden, um das BIOS-Menü aufzurufen und viele Low-Level-PC-Einstellungen zu ändern.
`
export const lBios = new Lesson('BIOS', 'text')
    .addCategory(rechennetzCategory)
    .addContent({
        id: 'bios:introduction',
        index: 1,
        content: vBios
    })
    .addIndexCard(icBios)