import React, { SVGProps } from 'react'

export default function FaceThreedDraft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 7.77961V15.5763C20 15.7783 19.8983 15.9668 19.7295 16.0778L13.1137 20.4253C13.0388 20.4745 12.954 20.5063 12.8652 20.5184L2.68109 21.9071C2.32083 21.9563 2.00002 21.6762 2.00002 21.3126L2 9.01164C2 8.79805 2.11354 8.60057 2.29813 8.49311L13.087 2.21229C13.3057 2.08493 13.5809 2.10857 13.7747 2.27137L19.7859 7.32017C19.9216 7.43417 20 7.60236 20 7.77961Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 9L12.727 11.922C12.8998 11.9714 13.0857 11.9407 13.2334 11.8384L19.5 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13 20.5L13 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5 14.01L16.51 13.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22 17.01L22.01 16.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
