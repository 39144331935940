import { Course } from "../../course";
import { brs } from "./BRS/brs";
import { cs } from "./cybersecurity/cybersecurity";
import { netzwerk } from "./netzwerk/netzwerk";
import { sql } from "./sql/sql";
import { storage } from "./storage/storage";

export const courses: Course[] = []

courses.push(brs)
courses.push(storage)
courses.push(cs)
courses.push(netzwerk)
courses.push(sql)