import React, { SVGProps } from 'react'

export default function AntennaSignalTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 15V9C2 5.68629 4.68629 3 8 3H16C19.3137 3 22 5.68629 22 9V15C22 18.3137 19.3137 21 16 21H8C4.68629 21 2 18.3137 2 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M15 9C15 9 16 10.125 16 12C16 13.875 15 15 15 15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 12.01L12.01 11.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17 7C17 7 19 8.78571 19 12C19 15.2143 17 17 17 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 9C9 9 8 10.125 8 12C8 13.875 9 15 9 15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 7C7 7 5 8.78571 5 12C5 15.2143 7 17 7 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
