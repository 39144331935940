import React, { SVGProps } from 'react'

export default function Powervirtualagents(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Power Virtual Agents</title>
      <path d='M5.498 6.532H1a1 1 0 0 0-1 .999v9.997a1 1 0 0 0 1 .999h3.998a1 1 0 0 1 1 1v3.188a.75.75 0 0 0 1.28.53l4.425-4.425a1 1 0 0 1 .707-.293h4.584a1 1 0 0 0 .978-.793 1.24 1.24 0 0 1-1.612-.133l-4.425-4.425a.499.499 0 0 0-.353-.146H6.998a1.5 1.5 0 0 1-1.5-1.5V6.532ZM24 9.031l-.006 2.665a1 1 0 0 1-.986.833h-4.015a1 1 0 0 0-.999 1l-.002 3.235-.021.143-.004.013a.75.75 0 0 1-.566.531l-.132.017-.132-.007-.132-.031-.127-.057-.119-.084-.045-.042-4.425-4.425a1.003 1.003 0 0 0-.707-.293h-4.61a1 1 0 0 1-.974-.999V9.031H24Zm-.009-.5V5.033H5.998v3.498h17.993ZM23.728.827a.989.989 0 0 0-.708-.293H6.998a.996.996 0 0 0-.462.113l-.028.015-.018.01-.008.005-.027.017-.016.011L6.43.71l-.026.019-.011.009-.024.018-.012.01-.019.017-.021.019-.018.016-.017.017-.006.006a.988.988 0 0 0-.06.069l-.012.016-.017.023-.018.026-.013.02-.016.024-.01.017-.013.024-.01.018-.008.017a.588.588 0 0 0-.02.044l-.012.028-.009.025a.96.96 0 0 0-.024.076l-.008.028-.006.029-.002.011a1.099 1.099 0 0 0-.017.115L6 1.475l-.002.058v2.999h17.993V1.297a.993.993 0 0 0-.263-.47Z' />
    </svg>
  )
}
