import React, { SVGProps } from 'react'

export default function MicrophoneSpeaking(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='9' y='2' width='6' height='12' rx='3' stroke='currentColor' strokeWidth='1.5' />
      <path d='M5 3V5M1 2V6M19 3V5M23 2V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5 10V11C5 14.866 8.13401 18 12 18V18V18C15.866 18 19 14.866 19 11V10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 18V22M12 22H9M12 22H15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
