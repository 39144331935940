import React, { SVGProps } from 'react'

export default function CpuWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19 2L19 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 10.01L19.01 9.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16 14V15.4C16 15.7314 15.7314 16 15.4 16H8.6C8.26863 16 8 15.7314 8 15.4V8.6C8 8.26863 8.26863 8 8.6 8H14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 14V19.4C20 19.7314 19.7314 20 19.4 20H4.6C4.26863 20 4 19.7314 4 19.4V4.6C4 4.26863 4.26863 4 4.6 4H14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M20 17H22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 20V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 20V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 20V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 17H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 7H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 4V2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 4V2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
