import React, { SVGProps } from 'react'

export default function Googlebard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Google Bard</title>
      <path d='m11.446 16.122-.957 2.196a1.034 1.034 0 0 1-1.906 0l-.957-2.196a8.455 8.455 0 0 0-4.303-4.358l-2.638-1.17c-.839-.372-.839-1.593 0-1.965L3.24 7.494A8.452 8.452 0 0 0 7.605 2.99L8.576.651a1.033 1.033 0 0 1 1.919 0l.972 2.339a8.452 8.452 0 0 0 4.365 4.504l2.555 1.135c.839.372.839 1.593 0 1.965l-2.638 1.17a8.455 8.455 0 0 0-4.303 4.358Zm8.907 6.922-.27.617a.553.553 0 0 1-1.02 0l-.27-.617a4.77 4.77 0 0 0-2.426-2.459l-.83-.368c-.449-.2-.449-.853 0-1.052l.783-.348a4.769 4.769 0 0 0 2.461-2.541l.277-.667a.554.554 0 0 1 1.029 0l.277.667a4.764 4.764 0 0 0 2.46 2.541l.785.348c.448.199.448.852 0 1.052l-.832.368a4.762 4.762 0 0 0-2.424 2.459Z' />
    </svg>
  )
}
