import React, { SVGProps } from 'react'

export default function Motorcycle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 19C7.20914 19 9 17.2091 9 15C9 12.7909 7.20914 11 5 11C2.79086 11 1 12.7909 1 15C1 17.2091 2.79086 19 5 19Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19 15L16 6L17 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 8.5L11.5 8.5L7 11.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 15.5L12 15.5L13 13L16.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 10C6.5 8.5 3.5 8.5 1.5 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19 19C21.2091 19 23 17.2091 23 15C23 12.7909 21.2091 11 19 11C16.7909 11 15 12.7909 15 15C15 17.2091 16.7909 19 19 19Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
