import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NuiMenuPoint, NuiHeader, NuiText } from "nui";
import { Lesson } from ".";
import { useNuiContext } from "../app_context/app_context";

export const LessonDetail = ({ changeNav }: { changeNav: React.Dispatch<React.SetStateAction<NuiMenuPoint[] | undefined>> }): JSX.Element => {
    const { lessons, courses } = useNuiContext();
    const { id, lesson } = useParams();
    const navigate = useNavigate()

    const [lessonValue, setLessonValue] = useState<Lesson>()

    const leftNavContent = () => {
        const foundCourse = courses.find(course => course.id === id);
        if (foundCourse) {
            setLessonValue(foundCourse.lessons.find(les => les.id === lesson))
            let lessonMenu: NuiMenuPoint[] = []
            // foundCourse.lessons.forEach((les) => {
            lessons.forEach((les) => {
                lessonMenu.push({
                    title: les.title,
                    icon: les.id === lesson ? "ArrowRight" : "Keyframe",
                    onClick: () => navigate(`/courses/${les.parent}/lesson/${les.id}`),
                })
                les.contents.forEach((con, index) => {
                    lessonMenu.push({
                        title: con.id,
                        icon: "NavArrowRight",
                        lastItem: index === les.contents.length - 1 ? true : false
                    })
                })
            })
            return lessonMenu
        }
    }

    useEffect(() => {
        changeNav(leftNavContent())
    }, [lesson])

    return (
        <div className="content-page">
            {lessonValue &&
                <>
                    <NuiHeader title={lessonValue.title} />
                    {lessonValue.contents
                        .sort((a, b) => a.index - b.index)
                        .map((content) => {
                            return (
                                <>
                                    <div style={{ boxShadow: 'var(--box-shadow', border: 'var(--border)', width: '70%', margin: "auto" }}>
                                        <NuiText text={content.content} />
                                    </div>
                                    <br />
                                    <br />
                                </>
                            )
                        })
                    }
                </>
            }
        </div >
    )
}