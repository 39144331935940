import React, { SVGProps } from 'react'

export default function ArrowDownLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19 6L6 19M6 19L6 6.52M6 19H18.48' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
