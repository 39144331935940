import React, { SVGProps } from 'react'

export default function UnjoinThreed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11.5 7L9 12H15L12.5 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20.8388 20.8391H17.3033M20.8388 20.8391V17.3035M20.8388 20.8391L17 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.76777 2.76788H6.3033M2.76777 2.76788V6.30341M2.76777 2.76788L6.60657 6.60693'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
