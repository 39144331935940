import React, { SVGProps } from 'react'

export default function Contactless(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15 21.5C19 16 19 8 15 2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5 20C15 15 15 9 11.5 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.5 18C11.1667 14.25 11.1667 9.75 8.5 6'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.5 16C7 13.5 7 10.5 5.5 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
