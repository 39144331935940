import React, { SVGProps } from 'react'

export default function HomeTable(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4 7L4 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 7L23 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 10L20 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 7L20 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
