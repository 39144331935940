import React, { SVGProps } from 'react'

export default function PenTabletConnectWifi(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M17 15.51L17.01 15.4989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 11.9999C14.5 9.00001 19.5 9 22 12'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14 14C15.5 12 18.5 12 20 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M22 7V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2 12H6' stroke='currentColor' strokeWidth='1.5' />
      <path d='M6 3V21' stroke='currentColor' strokeWidth='1.5' />
    </svg>
  )
}
