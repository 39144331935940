import React, { SVGProps } from 'react'

export default function Koa(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Koa</title>
      <path d='M0 5.587v12.757h1.19v-4.099l.46-.527 3.147 4.626h1.19L2.33 12.97l2.773-3.13h-.17L1.19 14.058v-8.47zm11.039 4.185c-1.19 0-2.177.391-2.96 1.173-.77.783-1.156 1.883-1.156 3.3 0 1.418.374 2.467 1.122 3.147.749.68 1.718 1.02 2.909 1.02 1.202 0 2.188-.39 2.96-1.173.782-.783 1.173-1.883 1.173-3.3 0-1.417-.374-2.466-1.122-3.147-.749-.68-1.724-1.02-2.926-1.02zm8.896 0c-1.043 0-2.07.232-3.079.697l.068.12c.261-.148.67-.296 1.225-.443.567-.159 1.06-.238 1.48-.238.42 0 .76.057 1.02.17.26.102.442.227.545.374.102.148.18.3.238.46.068.147.09.272.068.374.007.97 0 1.988 0 2.976a9.269 9.269 0 00-.834-.034c-.306 0-.64.017-1.003.051-1.463.091-2.41.34-2.841.749-.215.204-.346.391-.391.561-.046.17-.068.329-.068.476 0 .136.011.273.034.409.193 1.292 1.003 1.939 2.432 1.939 1.225 0 2.115-.505 2.67-1.514v.357c0 .181.131.397.392.646.283.295.81.443 1.582.443H24v-.136h-.527l-.187-.034c-.136-.012-.272-.103-.409-.273-.124-.181-.187-.436-.187-.765v-5.698c0-.363-.147-.709-.442-1.038-.498-.557-1.564-.624-2.313-.63zm-8.862.136c.862 0 1.553.329 2.075.986.522.658.783 1.673.783 3.045 0 1.36-.284 2.427-.85 3.198-.568.76-1.282 1.14-2.144 1.14-.862 0-1.554-.33-2.075-.987-.522-.658-.783-1.667-.783-3.028 0-1.372.284-2.438.85-3.198.568-.77 1.282-1.156 2.144-1.156zm9.491 4.456c.318 0 .63.012.936.034v2.058c-.034.386-.295.789-.783 1.208a2.416 2.416 0 01-1.616.613c-.907 0-1.411-.601-1.513-1.803a3.155 3.155 0 01-.02-.307c0-.499.131-.89.392-1.173.26-.284.822-.477 1.684-.579.314-.038.64-.039.92-.05z' />
    </svg>
  )
}
