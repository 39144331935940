import React, { Component } from 'react'
import { ProfileSideProps } from '../types.profile'
import { NuiHeader, NuiText } from '../../text'
import { NuiButton } from '../../button'
import '../../../styles/profile.css'

export class ProfileSide extends Component<ProfileSideProps> {
  constructor(props: ProfileSideProps) {
    super(props)
    this.state = {}
  }

  render() {
    const { image, name, username, email } = this.props
    return (
      <div className='nuiProfilePage'>
        <img src={image} className='profile-image' />
        <div className='bio-area'>
          <NuiHeader title={name} />
          <NuiText text={username} />
          <NuiText text={email} />
        </div>
        <NuiButton color='primary' icon='EditPencil' text='Edit Profil' />
      </div>
    )
  }
}
