import React, { SVGProps } from 'react'

export default function Codesandbox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>CodeSandbox</title>
      <path d='M0 24H24V0H0V2.45455H21.5455V21.5455H2.45455V0H0Z' />
    </svg>
  )
}
