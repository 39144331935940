import React, { SVGProps } from 'react'

export default function HorizontalSplit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 12H2M2 12L5.5 8.5M2 12L5.5 15.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 12H22M22 12L18.5 8.5M22 12L18.5 15.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 21L10 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 21L14 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
