import React, { SVGProps } from 'react'

export default function Coronaengine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Corona Engine</title>
      <path d='M13.69 7.499c-.919-.965-1.577-2.105-1.6-3.116.655-.187 2.017.001 3.35.558 1.354.566 2.668 1.514 3.158 2.831.014.039.029.078.041.116a2.337 2.337 0 0 1-.529 1.14c-.26.279-.586.487-.948.605-1.143-.23-2.464-1.074-3.472-2.134zm5.581 2.244c-.957.334-1.839 1.311-2.473 2.482v.001c-.697 1.284-1.093 2.799-.96 3.957.225.307.523.553.869.713.394.163.826.215 1.247.151.038-.027.07-.053.1-.075 1.103-.872 1.6-2.413 1.72-3.875.119-1.437-.122-2.79-.503-3.354zm-7.939-3.389a2.23 2.23 0 0 0-.283-1.088 2.332 2.332 0 0 0-.92-.853l-.123.002C8.6 4.473 7.292 5.428 6.334 6.541 5.393 7.633 4.791 8.87 4.768 9.55c.969.288 2.257.017 3.459-.559v.002c1.32-.631 2.532-1.625 3.105-2.639zM7.96 14.641c-.193-1.449-.765-2.907-1.553-3.767a2.252 2.252 0 0 0-1.123-.067c-.415.099-.794.31-1.097.61l-.036.118c-.38 1.355.125 2.893.886 4.146.749 1.233 1.741 2.185 2.382 2.418.577-.832.718-2.139.54-3.459l.001.001zm5.297 2c-1.439-.264-3.004-.172-4.065.311a2.227 2.227 0 0 0-.411 1.046c-.034.426.05.852.243 1.233.033.025.067.047.1.068 1.172.779 2.793.774 4.223.438 1.405-.332 2.619-.978 3.039-1.515-.614-.807-1.817-1.344-3.128-1.584l-.001.003zM24 12c0 6.622-5.364 11.992-11.985 12C5.387 24.008.008 18.642 0 12.015-.008 5.387 5.358.008 11.985 0h.001C18.617-.004 23.996 5.369 24 12zm-1.547 0c-.003-5.778-4.69-10.459-10.468-10.456-5.774.007-10.45 4.693-10.443 10.468.007 5.775 4.693 10.45 10.468 10.443C17.78 22.448 22.453 17.77 22.453 12z' />
    </svg>
  )
}
