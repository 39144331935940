import React, { SVGProps } from 'react'

export default function CompAlignLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 22L3 2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19 16H9C7.89543 16 7 15.1046 7 14V10C7 8.89543 7.89543 8 9 8H19C20.1046 8 21 8.89543 21 10V14C21 15.1046 20.1046 16 19 16Z'
        stroke='currentColor'
      />
    </svg>
  )
}
