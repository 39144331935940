import React, { useEffect, useState } from "react";
import { NuiActivityHistory, TimeSeriesData, NuiProfileSide, NuiLineChart, DataPoint, NuiScrolling, NuiCard } from "nui";
import { useKeycloak } from "../auth/keycloak";
import gravatar from 'gravatar'
import ProfilePictureStock from '../asstes/images/profile.svg'
import { useNuiContext } from "../app_context/app_context";
import { Course } from "../course";
import { Lesson } from "../lessons";
import { Category } from "../category";
import { useNavigate } from "react-router-dom";
import { NuiButton } from "nui";

export const ProfilePage = (): JSX.Element => {
    const { keycloak } = useKeycloak()
    const { courses, lessons } = useNuiContext();
    const [timeSeriesData, setTimeSeries] = useState<TimeSeriesData[]>([])
    const [courseData, setCourseData] = useState<DataPoint[]>([])
    const [lessonData, setLessonData] = useState<DataPoint[]>([])
    const [myCourses, setMyCourses] = useState<Course[]>([])
    const [myLessons, setMyLessons] = useState<Lesson[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        const dataCourse: DataPoint[] = [];
        const dataLessons: DataPoint[] = [];
        const timeSeries: TimeSeriesData[] = [];

        const myCourses: Course[] = courses.filter((course: Course) => course.myCourse === true);
        const myLessons: Lesson[] = lessons.filter((lesson: Lesson) => lesson.myLesson === true);

        myCourses.forEach((course) => {
            course.activitys.forEach((activity) => {
                setMyCourses((prevState) => [...prevState, course])
                // addToCourseData(activity.start.toDate(), dataCourse);
                addToTimeSeries(activity.start.toDate(), timeSeries);
                dataCourse.push({
                    date: activity.start.toDate(),
                    value: activity.duration
                })
            });
        });

        myLessons.forEach((lesson) => {
            lesson.activitys.forEach((activity) => {
                setMyLessons((prevState) => [...prevState, lesson])
                dataLessons.push({
                    date: activity.start.toDate(),
                    value: activity.duration
                })
                // addToLessonData(activity.start.toDate(), dataLessons)
                addToTimeSeries(activity.start.toDate(), timeSeries);
            });
        });

        setTimeSeries(timeSeries)
        setCourseData(dataCourse)
        setLessonData(dataLessons)
        console.log('[Tracking]', timeSeries, dataCourse, dataLessons)
    }, []);

    const addToTimeSeries = (date: Date, timeSeries: TimeSeriesData[]): void => {
        const dateString = date.toDateString();
        const existingEntryIndex = timeSeries.findIndex(entry => entry.date.toDateString() === dateString);

        if (existingEntryIndex !== -1) {
            timeSeries[existingEntryIndex].value++;
        } else {
            timeSeries.push({
                date: date,
                value: 1
            });
        }
    }

    return (
        <div className="profile-page">
            <div className="header">
                <NuiProfileSide
                    image={keycloak?.profile?.email ? gravatar.url(keycloak?.profile?.email, { s: '32', format: '404', r: 'pg' }) : ProfilePictureStock}
                    name={`${keycloak?.profile?.firstName} ${keycloak?.profile?.lastName}`}
                    username={`${keycloak?.profile?.username}`}
                    email={`${keycloak?.profile?.email}`}
                />
                <NuiActivityHistory
                    data={timeSeriesData}
                    title="Sehe dir deine Activitäten an!"
                    width={800}
                />
                <NuiLineChart
                    title="Sehe dir deine Activitäten an!"
                    data={[courseData, lessonData]}
                    width={400}
                    height={400}
                    colors={["blue", "green"]}
                />
            </div>
            <div className="header">
                <div className="container">
                    {myCourses.length !== 0 ? (
                        <NuiScrolling title="Deine besuchten Kurse">
                            {myCourses.map((course: Course, index: number) => (
                                <NuiCard
                                    key={`profile:mycourse:${course.id}:sub_course:${index}`}
                                    title={course.title}
                                    thumb={course.thumb ? course.thumb : "https://avatars.githubusercontent.com/u/65290559?v=4"}
                                    body={
                                        <div>
                                            {course.description ? course.description : ""}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap"
                                                }}
                                            >
                                                {course.categorys.map((category: Category) => {
                                                    return category.render();
                                                })}
                                            </div>
                                        </div>
                                    }
                                    link={[{
                                        onClick: () => { navigate(`/courses/${course.id}`) },
                                        title: "Kurs anzeigen",
                                    }]}
                                />
                            ))}
                        </NuiScrolling>
                    ) : (
                        <NuiScrolling title="Deine besuchten Kurse">
                            <div>
                                <h3>Du hast noch Keine Kurse besucht.</h3>
                                <NuiButton
                                    text="Explore"
                                    icon="ArrowRight"
                                    color="succsess"
                                    fill
                                    onClick={() => { navigate('/courses') }}
                                />
                            </div>
                        </NuiScrolling>
                    )}
                </div>
                <div className="container">
                    {myLessons.length !== 0 ? (
                        <NuiScrolling title="Deine besuchten Kurse">
                            {myLessons.map((lesson: Lesson, index: number) => (
                                <NuiCard
                                    key={`profile:mylesson:${lesson.id}:sub_course:${index}`}
                                    title={lesson.title}
                                    thumb={lesson.thumb ? lesson.thumb : "https://avatars.githubusercontent.com/u/65290559?v=4"}
                                    body={
                                        <div>
                                            {lesson.description ? lesson.description : ""}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap"
                                                }}
                                            >
                                                {lesson.categorys.map((category: Category) => {
                                                    return category.render();
                                                })}
                                            </div>
                                        </div>
                                    }
                                    link={[{
                                        onClick: () => { navigate(`/courses/${lesson.id}`) },
                                        title: "Lesson starten",
                                    }]}
                                />
                            ))}
                        </NuiScrolling>
                    ) : (
                        <NuiScrolling title="Deine besuchten Kurse">
                            <div>
                                <h3>Du hast noch Keine Lesson besucht.</h3>
                                <NuiButton
                                    text="Explore"
                                    icon="ArrowRight"
                                    color="succsess"
                                    fill
                                    onClick={() => { navigate('/lessons') }}
                                />
                            </div>
                        </NuiScrolling>
                    )}
                </div>
            </div>
        </div>
    )
} 