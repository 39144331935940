import React from 'react';
import { CategoryType } from '../asstes/types/category.types';
import { Category } from '../category';
import { IndexCardStatusType, IndexCardType } from '../asstes/types/index_crad.types';
import moment, { Moment } from 'moment';
import { Activitys } from '../asstes/types/general.types';

export class IndexCard implements IndexCardType {
    id: string
    categorys: Category[]
    description: string
    keyname: string
    explanation: string
    type: IndexCardStatusType
    updated: Moment
    active: boolean
    activitys: Activitys[]

    constructor(keyname: string) {
        this.id = keyname.toLowerCase().replace(/ /g, '_').replace(',', '')
        this.keyname = keyname
        this.categorys = []
        this.description = ""
        this.explanation = ""
        this.type = "new"
        this.updated = moment();
        this.activitys = []
        this.active = false
    }
    // DESCRIPTION
    addDescription = (description: string): IndexCard => {
        this.description = description
        return this
    }
    // KEYNAME
    addKeyname = (keyname: string): IndexCard => {
        this.keyname = keyname
        return this
    }
    // EXPLANATION
    addExplanation = (explanation: string): IndexCard => {
        this.explanation = explanation
        return this
    }
    // TYPE
    changeType = (type: IndexCardStatusType): IndexCard => {
        this.type = type
        return this
    }
    // TIME
    lastUpdate = (): IndexCard => {
        this.updated = moment();
        return this
    }
    // CATEGORYS
    addCategory = (category: Category): IndexCard => {
        const alreadyExist = this.categorys.includes(category)
        if (alreadyExist) {
            console.warn(`[System//Inde Card] The ${category.id} is already in the Lesson ${this.id}`)
            return this
        } else {
            this.categorys.push(category)
            return this
        }
    }
    removeCategory = (category: CategoryType): IndexCard => {
        const index = this.categorys.findIndex(cat => cat.id === category.id);
        if (index !== -1) {
            this.categorys.splice(index, 1);
            console.log(`[System//Index Card] The ${category.id} category has been removed from the Index Card ${this.id}`);
            return this
        } else {
            console.warn(`[System//Index Card] The ${category.id} category does not exist in the Index Card ${this.id}`);
            return this
        }
    }
    // Time
    addTime = (activitys: Activitys): IndexCard => {
        this.activitys.push(activitys)
        return this
    }
    // Active
    activate = (): IndexCard => {
        console.log(`[Index Card // activate] Activate the index Card ${this.id}`)
        this.active = true
        return this
    }
}

