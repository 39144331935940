import React, { SVGProps } from 'react'

export default function NumberEightSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z'
        stroke='currentColor'
      />
      <path
        d='M12 16C10.6193 16 9.5 15.5 9.5 14C9.5 12.5 10.6193 12 12 12C13.3807 12 14.5 12.5 14.5 14C14.5 15.5 13.3807 16 12 16Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 8C10.6193 8 9.5 8.5 9.5 10C9.5 11.5 10.6193 12 12 12C13.3807 12 14.5 11.5 14.5 10C14.5 8.5 13.3807 8 12 8Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
