import React from 'react'
import { NuiIcon } from '../../icons'
import { TDnd } from '../dnd.types'
import '../../../styles/dnd.css'

export const FileDrop = ({ onSubmit, onCancel, onDrop, onDragOver, onDragLeave, onChange }: TDnd) => {
  const dropzoneFileMessage = 'No Files Selected'

  const updateText = (text: string) => {
    const fileInfoElement = document.getElementById('file-info')
    if (fileInfoElement) {
      fileInfoElement.innerText = text
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    if (target.files && target.files.length) {
      updateText(`${target.files[0].name}, ${target.files[0].size} bytes`)
    }
    onChange && onChange(e)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.currentTarget.classList.add('dropzone--over')
    onDragOver && onDragOver(e)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove('dropzone--over')
    onDragLeave && onDragLeave(e)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const inputElement = e.currentTarget.querySelector('input[type="file"]') as HTMLInputElement

    if (e.dataTransfer && e.dataTransfer.files.length && inputElement) {
      inputElement.files = e.dataTransfer.files
      updateText(`${e.dataTransfer.files[0].name}, ${e.dataTransfer.files[0].size} bytes`)
    }

    e.currentTarget.classList.remove('dropzone--over')
    onDrop && onDrop(e)
  }

  return (
    <form className='dropzone-box'>
      <h2>Upload and attach files</h2>
      <p>Click to upload or drag and drop</p>
      <div className='dropzone-area' onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <div className='file-upload-icon'>
          <NuiIcon
            key={`dropzone:file-upload`}
            icon='CloudUpload'
            style={{ width: '75px', height: '75px', viewBox: '0 0 24 24', strokeWidth: '1.75' }}
          />
        </div>
        <input type='file' required id='upload-file' name='uploaded-file' onChange={handleChange} />
        <p className='file-info' id='file-info'>
          {dropzoneFileMessage}
        </p>
      </div>
      <div className='dropzone-actions'>
        <button type='reset' onClick={onCancel}>
          Cancel
        </button>
        <button type='submit' onClick={onSubmit}>
          Save
        </button>
      </div>
    </form>
  )
}
