import React, { SVGProps } from 'react'

export default function OpenInBrowser(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 6L18 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6H7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.5 20.5L12 12M12 12V16M12 12H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
