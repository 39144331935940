import React, { SVGProps } from 'react'

export default function Purism(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Purism</title>
      <path d='M24 19.588H0V4.412h24zM2.824 16.765h18.352v-9.53H2.824Z' />
    </svg>
  )
}
