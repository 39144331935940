import React, { SVGProps } from 'react'

export default function DirectorChair(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19 12L5 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 3L5 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 3V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 12L19 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12L20 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 4L19 4' stroke='currentColor' strokeLinejoin='round' />
      <path d='M5 7L19 7' stroke='currentColor' strokeLinejoin='round' />
    </svg>
  )
}
