import React, { SVGProps } from 'react'

export default function FastArrowDownSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M15.5 7.5L12 11L8.5 7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.5 13.5L12 17L8.5 13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
