import React, { SVGProps } from 'react'

export default function LineSpace(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11 6H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 12H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 18H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5 19V5M5 19L3 16.5M5 19L7 16.5M5 5L3 7M5 5L7 7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
