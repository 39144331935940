import React, { Component, MouseEvent } from 'react'
import '../../../styles/scrolling.css'
import { ScrollingProps, ScrollingState } from '../types.scrolling'
import { NuiButton } from '../../button'

export class Scrolling extends Component<ScrollingProps, ScrollingState> {
  eventsRef: React.RefObject<HTMLDivElement>
  scrollLeftButtonRef: React.RefObject<HTMLButtonElement>
  scrollRightButtonRef: React.RefObject<HTMLButtonElement>

  constructor(props: ScrollingProps) {
    super(props)
    this.state = {
      isDown: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
    }
    this.eventsRef = React.createRef<HTMLDivElement>()
    this.scrollLeftButtonRef = React.createRef<HTMLButtonElement>()
    this.scrollRightButtonRef = React.createRef<HTMLButtonElement>()

    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleScrollButtonClick = this.handleScrollButtonClick.bind(this)
  }

  componentDidMount() {
    const events = this.eventsRef.current
    const scrollLeftButton = this.scrollLeftButtonRef.current
    const scrollRightButton = this.scrollRightButtonRef.current

    if (events && scrollLeftButton && scrollRightButton) {
      events.addEventListener('mousedown', this.handleMouseDown as any)
      events.addEventListener('mouseleave', this.handleMouseLeave)
      events.addEventListener('mouseup', this.handleMouseUp)
      document.addEventListener('mousemove', this.handleMouseMove as any)
      scrollLeftButton.addEventListener('click', this.handleScrollButtonClick as any)
      scrollRightButton.addEventListener('click', this.handleScrollButtonClick as any)
      events.addEventListener('scroll', this.handleScroll)
    }

    return () => {
      if (events && scrollLeftButton && scrollRightButton) {
        events.removeEventListener('mousedown', this.handleMouseDown as any)
        events.removeEventListener('mouseleave', this.handleMouseLeave)
        events.removeEventListener('mouseup', this.handleMouseUp)
        document.removeEventListener('mousemove', this.handleMouseMove as any)
        scrollLeftButton.removeEventListener('click', this.handleScrollButtonClick as any)
        scrollRightButton.removeEventListener('click', this.handleScrollButtonClick as any)
        events.removeEventListener('scroll', this.handleScroll)
      }
    }
  }

  handleMouseDown(e: MouseEvent<HTMLDivElement>) {
    const events = this.eventsRef.current
    if (!events) return
    this.setState((prevState) => ({
      ...prevState,
      isDown: true,
      startX: e.pageX - events.offsetLeft,
      startY: e.pageY - events.offsetTop,
      scrollLeft: events.scrollLeft,
      scrollTop: events.scrollTop,
    }))
    events.style.cursor = 'grabbing'
  }

  handleMouseLeave() {
    const events = this.eventsRef.current
    if (!events) return
    this.setState((prevState) => ({ ...prevState, isDown: false }))
    events.style.cursor = 'grab'
  }

  handleMouseUp() {
    const events = this.eventsRef.current
    if (!events) return
    this.setState((prevState) => ({ ...prevState, isDown: false }))
    events.style.cursor = 'grab'
  }

  handleMouseMove(e: MouseEvent<HTMLDivElement>) {
    if (!this.state.isDown || !this.eventsRef.current) return
    e.preventDefault()
    const events = this.eventsRef.current
    const x = e.pageX - events.offsetLeft
    const y = e.pageY - events.offsetTop
    const walkX = (x - this.state.startX) * 1
    const walkY = (y - this.state.startY) * 1
    events.scrollLeft = this.state.scrollLeft - walkX
    events.scrollTop = this.state.scrollTop - walkY
  }

  handleScrollButtonClick(e: MouseEvent<HTMLButtonElement>) {
    const events = this.eventsRef.current
    if (!events) return
    const direction = parseInt(e.currentTarget.dataset.direction || '0')
    events.scrollBy({
      top: 0,
      left: direction * 200,
      behavior: 'smooth',
    })
  }

  handleScroll() {
    const events = this.eventsRef.current
    const scrollLeftButton = this.scrollLeftButtonRef.current
    const scrollRightButton = this.scrollRightButtonRef.current

    if (!events || !scrollLeftButton || !scrollRightButton) return

    const position = events.scrollLeft
    scrollLeftButton.disabled = position === 0
    scrollRightButton.disabled = Math.round(position) === events.scrollWidth - events.clientWidth
  }

  render() {
    const { children, title } = this.props
    return (
      <div className='NuiScrollingContainer'>
        <div className='top-bar'>
          <h2>{title}</h2>
          <div>
            <NuiButton
              text=''
              icon='NavArrowLeft'
              color='primary'
              style={{ minWidth: '5px' }}
              ref={this.scrollLeftButtonRef}
              onClick={this.handleScrollButtonClick}
              data-direction='-1'
            />
            <NuiButton
              text=''
              icon='NavArrowRight'
              color='primary'
              style={{ minWidth: '5px' }}
              ref={this.scrollRightButtonRef}
              onClick={this.handleScrollButtonClick}
              data-direction='1'
            />
          </div>
        </div>
        <div id='events' className='NuiScrollingEvents' ref={this.eventsRef}>
          {children}
        </div>
      </div>
    )
  }
}
