import React, { SVGProps } from 'react'

export default function Farm(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 20H2V8L8 5L14 8V20H11M5 20V13H11V20M5 20H11'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 14V17V20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 17H18H22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 14H18H22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
