import React from 'react'
import { Navigate } from 'react-router-dom'
import { useKeycloak } from './keycloak'

interface IAuthRoute {
    component: JSX.Element
    roles: string[]
}

const checkRole = (roles: string[], role: string[]) => {
    return roles.includes(role[0]) || roles.includes(role[1])
}

const PrivateRoute = ({ component, roles }: IAuthRoute) => {
    const { keycloak } = useKeycloak()


    if (keycloak && !keycloak?.token) {
        keycloak.login()
    }
    if (
        keycloak &&
        keycloak.token &&
        keycloak.realmAccess &&
        keycloak.authenticated &&
        checkRole(keycloak.realmAccess.roles, roles)
    ) {
        console.warn('Keycloak Check:', checkRole(keycloak.realmAccess.roles, roles))
        return component
    } else if (keycloak && !keycloak.authenticated) {
        console.warn('Keycloak Check:', keycloak?.realmAccess?.roles, roles)
        keycloak.login()
        return <Navigate to={{ pathname: '/' }} />
    } else {
        console.warn('Keycloak Check:', keycloak?.realmAccess?.roles, roles)
        return <Navigate to={{ pathname: '/403' }} />
    }
}

export default PrivateRoute
