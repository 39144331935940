import React, { SVGProps } from 'react'

export default function LongArrowLeftDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.5 13.5L8 17L11.5 13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 17V11C8 8.79086 9.79086 7 12 7H19'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
