import React, { SVGProps } from 'react'

export default function SafeArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 19V5C3 3.89543 3.89543 3 5 3H12C13.1046 3 14 3.89543 14 5V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19Z'
        stroke='currentColor'
      />
      <path
        d='M12 3H19C20.1046 3 21 3.89543 21 5V5.5M12 21H19C20.1046 21 21 20.1046 21 19V18.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 15C7.67157 15 7 13.6569 7 12C7 10.3431 7.67157 9 8.5 9C9.32843 9 10 10.3431 10 12C10 13.6569 9.32843 15 8.5 15Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.5 9.5L10.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 9.5L6.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5 15.5L7.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.5 15.5L9.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 8L3 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 6L3 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 16H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 18H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 12H22M22 12L18.5 8.5M22 12L18.5 15.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
