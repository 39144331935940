import React, { SVGProps } from 'react'

export default function Chocolate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19 6.5C16 6.5 14.5 6 14.5 3L5 3L5 21L19 21L19 6.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19 15L5 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 9L19 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21L12 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
