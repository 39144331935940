import React from 'react'
import '../../../styles/accordions_list.css'
import { TAccordion, TAccordionItem } from '../accordion.types'
import { NuiIcon } from '../../icons'

export const AccordionList = ({ items, key: propKey, style }: TAccordion): JSX.Element => {
  const elements = items.map((item, index) => (
    <AccordionItem key={`${key}:${index}`} header={item.header} body={item.body} />
  ))

  const key = propKey ? propKey : `accordion:single`
  return (
    <div key={key} className={`accoirdion-conatiner ${style}`}>
      {elements}
    </div>
  )
}

export const AccordionItem = ({ header, body }: TAccordionItem): JSX.Element => {
  return (
    <details className='accoirdion-details'>
      <summary className='accoirdion-summary'>
        <span className='accoirdion-item-title'>{header}</span>
        <NuiIcon icon='PlusCircle' size='l' className='accoirdion-item-icon' />
      </summary>
      <div className='accoirdion-item-content-wrapper'>
        <div className='accoirdion-item-content'>{body}</div>
      </div>
    </details>
  )
}
