import React from 'react'
import { NuiIcon } from '../../icons'
import { TBadge } from '../types.badge'

export const Badge = ({ key, content, color, background, icon, style, onClick }: TBadge): JSX.Element => {
  return (
    <div
      key={`badge:${key ? key : content.toLowerCase().replace(/ /g, '_')}`}
      onClick={onClick && onClick}
      style={{
        background: background ? background : 'var(--color-primary)',
        color: color ? color : 'var(--background-primary)',
        width: 'fit-content',
        padding: '2.5px 10px 2.5px 10px',
        margin: '5px',
        cursor: 'default',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        ...style,
      }}
    >
      {icon && <NuiIcon icon={icon} key={color ? color : 'var(--background-primary)'} size='s' />}
      {content}
    </div>
  )
}
