import React, { SVGProps } from 'react'

export default function VueJs(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 20.5L22.5 4H18.5L12 14L5.5 4H1.5L12 20.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18.5 4H14.5L12 7.5L9.5 4H5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
