import React, { SVGProps } from 'react'

export default function Sort(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 14H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 10H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 18H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19 20V4M19 20L22 17M19 20L16 17M19 4L22 7M19 4L16 7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
