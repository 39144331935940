import React from 'react'
import { TCardLarge } from '../cards.types'
import '../../../styles/card_large.css'
import { NuiButton } from '../../button'

export const CardLarge = ({ thumb, title, body, buttons, key }: TCardLarge) => {
  return (
    <div className='nuiCardLarge' key={key ? `card:large:${key}` : `card:large`}>
      <article className='card'>
        <div className='background'>
          <img src={thumb} alt={title} />
        </div>
        <div className='content'>
          <div className='card-header'>
            {buttons?.topRight?.text && (
              <div className='card-type'>
                <NuiButton
                  text={buttons?.topRight?.text}
                  color='var(--background-third)'
                  fill={true}
                  icon={buttons.topRight.icon}
                  onClick={buttons.topRight.onClick}
                  style={{
                    color: 'var(--text)',
                  }}
                />
              </div>
            )}
            {buttons?.topLeft?.text && (
              <div className='latest-article'>
                <NuiButton
                  text={buttons.topLeft?.text}
                  color='var(--background-third)'
                  fill={true}
                  icon={buttons.topLeft.icon}
                  onClick={buttons.topLeft.onClick}
                  style={{
                    color: 'var(--text)',
                  }}
                />
              </div>
            )}
          </div>
          <div className='card-content'>
            <h2>{title}</h2>
            <p>{body}</p>
          </div>
          <div className='blog-preview__bottom'>
            {buttons?.bottomLeft && (
              <div className='blog-author' onClick={buttons.bottomLeft.onClick}>
                <img src={buttons.bottomLeft.image} alt='Avatar' />
                <div className='blog-author__name'>
                  <div className='blog-author__name'>{buttons.bottomLeft.header}</div>
                  <a rel='author' href='mailto:david@atheros.ai' className='blog-author__alias'>
                    {buttons.bottomLeft.body}
                  </a>
                </div>
              </div>
            )}
            {buttons?.bottomRight && (
              <p className='go-to-article-button' title='New Blog' onClick={buttons.bottomRight}>
                <NuiButton
                  text={''}
                  color='var(--background-third)'
                  fill={true}
                  icon='ArrowRight'
                  onClick={buttons.bottomRight}
                  style={{
                    color: 'var(--text)',
                  }}
                  onMouseEnter={(e) => {
                    const target = e.currentTarget as HTMLElement
                    if (target) {
                      target.style.setProperty('border', '1px solid var(--color-primary)')
                      target.style.setProperty('color', 'var(--color-primary)')
                    }
                  }}
                  onMouseLeave={(e) => {
                    const target = e.currentTarget as HTMLElement
                    if (target) {
                      target.style.setProperty('border', 'none')
                      target.style.setProperty('color', 'var(--text)')
                    }
                  }}
                />
              </p>
            )}
          </div>
        </div>
      </article>
    </div>
  )
}
