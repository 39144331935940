import React, { type ReactNode, useState, useEffect, useCallback, createContext, useContext } from 'react'
import Keycloak, { type KeycloakProfile, KeycloakInstance } from 'keycloak-js'
import { Get } from '../api/api'
import UILoading from '../asstes/loading/loading'

export type Profile = KeycloakProfile | undefined
export type TKeycloak = KeycloakInstance | undefined

export const KeycloakContext = createContext<{
    profile: Profile
    keycloak: TKeycloak
}>({
    profile: undefined,
    keycloak: undefined,
})

export interface KeycloakContextProviderProps {
    children: ReactNode
}


export const KeycloakAuth = ({ children }: KeycloakContextProviderProps): JSX.Element => {
    const [profile, _setProfile] = useState<KeycloakProfile | undefined>()
    const [keycloak, _setKeycloak] = useState<Keycloak | undefined>()

    const setUserInfo = (info: any): void => {
        console.log(info)
        if (info) {
            console.log('userInfo', info)
            _setProfile(info)
        } else {
            _setProfile(undefined)
        }
    }
    const setProfile = useCallback((profile: Profile): void => {
        const merged = profile ? ({ ...profile } as any) : null
        if (merged) {
            delete merged.userProfileMetadata
            delete merged.attributes
            setUserInfo(merged)
        } else {
            setUserInfo(undefined)
        }
    }, [])

    useEffect(() => {
        if (profile) {
            return
        }

        const fetchProfile = (keycloak: Keycloak): void => {
            if (!profile) {
                keycloak
                    .loadUserProfile()
                    .then((profile) => {
                        console.debug('[Keycloak] Profile values: ', profile)
                        setProfile(profile)
                    })
                    .catch(function () {
                        console.warn('Failed to load profile')
                        setProfile(undefined)
                    })
            }
        }

        const init = (cfg: any): Keycloak => {
            const result = new Keycloak(cfg)

            result.onAuthSuccess = () => {
                console.debug('keycloak', 'Auth Success')
            }

            result.onAuthError = (errorData) => {
                console.warn('keycloak', JSON.stringify(errorData))
            }
            result.onTokenExpired = () => {
                console.debug('keycloak', 'Access token expired.')
            }

            result.onActionUpdate = (status) => {
                switch (status) {
                    case 'success':
                        console.debug('keycloak', 'Action completed successfully')
                        break
                    case 'cancelled':
                        console.debug('keycloak', 'Action cancelled by user')
                        break
                    case 'error':
                        console.debug('keycloak', 'Action failed')
                        break
                }
            }

            result
                .init({
                    responseMode: 'fragment',
                    flow: 'standard',
                    enableLogging: true,
                })
                .then(async (authenticated) => {
                    console.debug('Init Success (' + (authenticated ? 'Authenticated' : 'Not Authenticated') + ')')
                    _setKeycloak(result)
                    if (!authenticated) {
                        const wantLogin = localStorage.getItem('noerkelit-want-login')
                        if (wantLogin === "true") {
                            console.warn("User want to be login, redircet to keycloak....", wantLogin)
                            result.login()
                        } else {
                            console.log("User not want to be login", wantLogin)
                        }
                    } else {
                        fetchProfile(result)
                    }
                })
                .catch((err: any) => {
                    setProfile(undefined)
                    console.warn('Keyclaok Error', err)
                })

            return result
        }
        if (!keycloak) {
            Get({ path: `config/keycloak` })
                .then((response) => {
                    console.log('KC_INIT: ', response.data);
                    init(response.data)
                })
                .catch((err: any) => {
                    console.error('KC_INIT: ', err);
                });
        } else {
            fetchProfile(keycloak)
        }
    }, [keycloak, profile, setProfile])

    if (keycloak) {
        if (profile !== undefined) {
            return <KeycloakContext.Provider value={{ profile, keycloak }}>{children}</KeycloakContext.Provider>
        }

        return <KeycloakContext.Provider value={{ profile, keycloak }}>{children}</KeycloakContext.Provider>
    } else {
        return <UILoading />
    }
}


export const useKeycloak = () => {
    const context = useContext(KeycloakContext);
    if (!context) {
        throw new Error('useKeycloak must be used within a NuiContextProvider');
    }
    return context;
};
