import React, { SVGProps } from 'react'

export default function Accenture(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Accenture</title>
      <path d='m.66 16.95 13.242-4.926L.66 6.852V0l22.68 9.132v5.682L.66 24Z' />
    </svg>
  )
}
