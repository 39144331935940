import React, { SVGProps } from 'react'

export default function Hashtag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 3L6 21' stroke='currentColor' strokeLinecap='round' />
      <path d='M20.5 16H2.5' stroke='currentColor' strokeLinecap='round' />
      <path d='M22 7H4' stroke='currentColor' strokeLinecap='round' />
      <path d='M18 3L14 21' stroke='currentColor' strokeLinecap='round' />
    </svg>
  )
}
