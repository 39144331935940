import React, { SVGProps } from 'react'

export default function Saltproject(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Salt Project</title>
      <path d='M0 12v10.56h12L18 12h6V1.44H12L6 12Z' />
    </svg>
  )
}
