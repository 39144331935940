import React from 'react';
import { CourseRender, CourseType } from '../asstes/types/course.types';
import { CategoryType } from '../asstes/types/category.types';
import { NuiCard, NuiCardLarge } from 'nui';
import { useNavigate } from 'react-router-dom';
import { Category } from '../category';
import { Lesson } from '../lessons';
import { Activitys } from '../asstes/types/general.types';
import moment from 'moment';

export class Course implements CourseType {
    id: string
    title: string
    categorys: Category[]
    description?: string
    thumb: string
    courses: Course[]
    lessons: Lesson[]
    parent?: string
    activitys: Activitys[]
    myCourse: boolean

    constructor(title: string) {
        this.id = title.toLowerCase().replace(/ /g, '_').replace(',', '')
        this.title = title;
        this.categorys = []
        this.courses = []
        this.thumb = "https://avatars.githubusercontent.com/u/65290559?v=4"
        this.lessons = []
        this.activitys = []
        this.myCourse = false
    }
    // DESCRIPTION
    addDescription = (description: string): Course => {
        this.description = description
        return this
    }
    // Thumb
    addThumb = (thumb: string): Course => {
        this.thumb = thumb
        return this
    }
    // CATEGORYS
    addCategory = (category: Category): Course => {
        const alreadyExist = this.categorys.includes(category)
        if (alreadyExist) {
            console.warn(`[System//Course] The ${category.id} is already in the Course ${this.id}`)
            return this
        } else {
            this.categorys.push(category)
            return this
        }
    }
    removeCategory = (category: CategoryType): Course => {
        const index = this.categorys.findIndex(cat => cat.id === category.id);
        if (index !== -1) {
            this.categorys.splice(index, 1);
            console.log(`[System//Course] The ${category.id} category has been removed from the Course ${this.id}`);
            return this
        } else {
            console.warn(`[System//Course] The ${category.id} category does not exist in the Course ${this.id}`);
            return this
        }
    }
    // COURSES
    addCourse = (course: Course): Course => {
        const alreadyExist = this.courses.includes(course)
        if (alreadyExist) {
            console.warn(`[System//Course] The ${course.id} is already in the Course ${this.id}`)
            return this
        } else {
            course.addParent(this.id)
            this.courses.push(course)
            return this
        }
    }
    removeCourse = (course: Course): Course => {
        const index = this.courses.findIndex(cour => cour.id === course.id);
        if (index !== -1) {
            this.categorys.splice(index, 1);
            console.log(`[System//Course] The ${course.id} course has been removed from the Course ${this.id}`);
            return this
        } else {
            console.warn(`[System//Course] The ${course.id} course does not exist in the Course ${this.id}`);
            return this
        }
    }
    // LESSONS
    addLesson = (lesson: Lesson): Course => {
        const alreadyExist = this.lessons.includes(lesson)
        if (alreadyExist) {
            console.warn(`[System//Course] The Lesson with the ID ${lesson.id} is already in the Course ${this.id}`)
            return this
        } else {
            lesson.categorys.forEach((cate: Category) => {
                this.addCategory(cate)
            })
            lesson.addParent(this.id)
            this.lessons.push(lesson)
            return this
        }
    }
    removeLesson = (lesson: Lesson): Course => {
        const index = this.courses.findIndex(les => les.id === lesson.id);
        if (index !== -1) {
            this.categorys.splice(index, 1);
            console.log(`[System//Course] The Lesson with the ID ${lesson.id} course has been removed from the Course ${this.id}`);
            return this
        } else {
            console.warn(`[System//Course] The Lesson with the ID ${lesson.id} course does not exist in the Course ${this.id}`);
            return this
        }
    }
    addParent = (parent: string): string => {
        return this.parent = parent
    }
    // Time
    trackingTime = (aid: string, action: "start" | "end"): Course => {
        if (action === 'end') {
            const activity = this.activitys.find((activity) => activity.aid === aid);
            if (activity) {
                const startTime = moment(activity.start);
                if (startTime.isValid()) {
                    const durationInSeconds = moment().diff(startTime, 'seconds');
                    console.log('[learnITnow Course] Duration:', durationInSeconds);
                    activity.duration = durationInSeconds;
                } else {
                    console.error('[learnITnow Course] Invalid start time:', activity.start);
                }
            }
        } else {
            this.myCourse = true
            this.activitys.push({
                aid: aid,
                type: "course",
                id: this.id,
                start: moment(),
                duration: 0
            });
        }
        console.log('[learnITnow Course]', this);
        return this;
    }
}