import React, { SVGProps } from 'react'

export default function Telegraph(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Telegraph</title>
      <path d='M0 0v24h24V0H0zm6 6h12v3h-4.5v9h-3V9H6V6Z' />
    </svg>
  )
}
