import React, { SVGProps } from 'react'

export default function Haveibeenpwned(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>haveibeenpwned</title>
      <path d='M3.03 2.37a2.09 2.09 0 0 0-.333.02.905.905 0 0 0-.244.067.527.527 0 0 0-.166.088.5.5 0 0 0-.133.144L.652 4.943a4.084 4.084 0 0 0-.308.532 2.184 2.184 0 0 0-.2.496c-.051.17-.089.351-.11.543A5.53 5.53 0 0 0 0 7.143c0 .199.022.356.066.474a.48.48 0 0 0 .188.266.745.745 0 0 0 .32.123c.133.023.288.031.465.031v.002c.184 0 .342-.01.475-.033a.857.857 0 0 0 .332-.123.56.56 0 0 0 .187-.266c.044-.118.067-.275.067-.474V5.729L3.547 2.71a.418.418 0 0 0 .045-.154.177.177 0 0 0-.067-.112.342.342 0 0 0-.177-.054 2.17 2.17 0 0 0-.319-.022zm4.347 5.61a2.03 2.03 0 0 0-.559.065.647.647 0 0 0-.343.205.813.813 0 0 0-.182.387 3.436 3.436 0 0 0-.043.6c0 .228.014.422.043.58a.883.883 0 0 0 .182.386.75.75 0 0 0 .343.205c.144.036.33.053.559.053a2.2 2.2 0 0 0 .547-.053.75.75 0 0 0 .344-.205.75.75 0 0 0 .183-.387c.036-.157.053-.35.053-.58a2.77 2.77 0 0 0-.053-.6.702.702 0 0 0-.183-.386.647.647 0 0 0-.344-.205 1.937 1.937 0 0 0-.547-.065zm4.05 3.69a.337.337 0 0 0-.308.172c-.068.114-.103.316-.103.605 0 .29.035.496.103.617.069.115.172.17.309.17h4.779c.13 0 .228-.055.297-.17.068-.114.101-.32.101-.617 0-.145-.01-.268-.033-.367a.637.637 0 0 0-.08-.24.25.25 0 0 0-.127-.137.335.335 0 0 0-.158-.033zm7.395 0a.337.337 0 0 0-.308.172c-.069.114-.104.316-.104.605 0 .29.035.496.104.617.068.115.171.17.308.17H23.6c.13 0 .228-.055.296-.17.07-.114.104-.32.104-.617 0-.145-.012-.268-.035-.367a.637.637 0 0 0-.08-.24.247.247 0 0 0-.125-.137.343.343 0 0 0-.16-.033zm-11.32 4.29c-.184 0-.342.012-.475.034a.857.857 0 0 0-.332.123.56.56 0 0 0-.187.266 1.383 1.383 0 0 0-.067.474v1.414L4.994 21.29a.418.418 0 0 0-.045.154c.007.044.03.082.067.112.036.03.096.046.177.054.089.013.195.022.32.022.133 0 .242-.006.33-.022a.905.905 0 0 0 .245-.066.527.527 0 0 0 .166-.088.5.5 0 0 0 .133-.144l1.504-2.254a4.12 4.12 0 0 0 .308-.532c.089-.162.153-.326.197-.496.052-.17.09-.351.112-.543.022-.191.033-.4.033-.629a1.38 1.38 0 0 0-.066-.474.477.477 0 0 0-.188-.266.745.745 0 0 0-.32-.123 2.795 2.795 0 0 0-.465-.031z' />
    </svg>
  )
}
