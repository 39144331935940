import React, { SVGProps } from 'react'

export default function DatabaseTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 15V9C2 5.68629 4.68629 3 8 3H16C19.3137 3 22 5.68629 22 9V15C22 18.3137 19.3137 21 16 21H8C4.68629 21 2 18.3137 2 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M16.3571 12C17.0714 12 18.5 12 18.5 10C18.5 8 17.0714 8 16.3571 8L13.5 8V12M16.3571 12C15.2143 12 13.9762 12 13.5 12M16.3571 12C17.0714 12 18.5 12 18.5 14C18.5 16 17.0714 16 16.3571 16H13.5V12'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.35714 8H5.5V12L5.5 16H8.35714C9.07143 16 10.5 16 10.5 14V10C10.5 8 9.07143 8 8.35714 8Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
