import { Lesson } from "../../../lessons"
import { sqlCategory } from "../../category"

const cSQLenv = `
## Enviroments für Datenbanken

### On prem und Cloud-Datenbank

Cloud-Datenbanken funktionieren auf Basis der erwähnten Datenbankmodelle. Diese können in eine virtuelle Umgebung ausgelagert, wodurch man vorhandene Datenbanken von einem Speicherort zu einem anderen verschieben kann. Der Zugriff kann Endgeräte unabhängig erfolgen und die Server- bzw. Speicherressourcen optimiert werden.

### In-Memory-Datenbank

Eine besondere Form der Datenbank ist die In-Memory-Datenbank, die den RAM eines Computers als Speicherort nutzt. Da im Arbeitsspeicher abgelegte Daten nicht dauerhaft gespeichert werden, erfüllt die In-Memory-Datenbank nicht alle eigentlichen Eigenschaften eines Datenbanksystems.

## Kategorien von SQL

### Data Definition Language

**D**ata **D**efinition **L**anguage wird verwendet um die Struktur von <a href="#datenbankobjekten">Datenbankobjekten</a> in einer Datenbank zu erstellen und zu ändern.

- CREATE: Dieser Befehl wird verwendet, um die Datenbank oder ihre Objekte (wie Tabelle, Index, Funktion, Ansichten, Speicherprozedur und Trigger) zu erstellen.
- DROP: Dieser Befehl wird verwendet, um Objekte aus der Datenbank zu löschen.
- ALTER: Dies wird verwendet, um die Struktur der Datenbank zu ändern.
- TRUNCATE: Dies wird verwendet, um alle Datensätze aus einer Tabelle zu entfernen, einschließlich aller für die Datensätze zugewiesenen Bereiche, die entfernt werden.
- COMMENT: Dies wird verwendet, um Kommentare zum Datenwörterbuch hinzuzufügen.
- RENAME: Dies wird verwendet, um ein in der Datenbank vorhandenes Objekt umzubenennen.

### Data Query Language

DQL-Anweisungen werden zum Durchführen von Abfragen der Daten innerhalb von Schemaobjekten verwendet. Der Zweck des DQL-Befehls besteht darin, eine Schemabeziehung basierend auf der an ihn übergebenen Abfrage zu erhalten.

- SELECT: Es wird verwendet, um Daten aus der Datenbank abzurufen.

### Data Control Language

DCL enthält Befehle wie GRANT und REVOKE, die sich hauptsächlich mit den Rechten, Berechtigungen und anderen Kontrollen des Datenbanksystems befassen.

- GRANT: Dieser Befehl gibt Benutzern Zugriffsrechte auf die Datenbank.
- REVOKE: Dieser Befehl entzieht dem Benutzer die Zugriffsrechte, die ihm durch die Verwendung des GRANT-Befehls erteilt wurden.

### Data Manipulation Language

Die SQL-Befehle, die sich mit der Manipulation von in der Datenbank vorhandenen Daten befassen, gehören zu DML oder Data Manipulation Language, und dazu gehören die meisten SQL-Anweisungen.

- INSERT: Es wird verwendet, um Daten in eine Tabelle einzufügen.
- UPDATE: Es wird verwendet, um vorhandene Daten innerhalb einer Tabelle zu aktualisieren.
- DELETE: Es wird verwendet, um Datensätze aus einer Datenbanktabelle zu löschen.
- LOCK: Parallelität der Tabellensteuerung.
- CALL: Aufruf eines PL/SQL- oder JAVA-Unterprogramms.
- EXPLAIN PLAN: Er beschreibt den Zugangsweg zu Daten.

### Transaction Control Language

Transaktionen gruppieren eine Reihe von Aufgaben in einer einzigen Ausführungseinheit. Jede Transaktion beginnt mit einer bestimmten Aufgabe und endet, wenn alle Aufgaben in der Gruppe erfolgreich abgeschlossen wurden. Wenn eine der Aufgaben fehlschlägt, schlägt die Transaktion fehl. Daher hat eine Transaktion nur zwei Ergebnisse: Erfolg oder Misserfolg.

- BEGIN: Öffnet eine Transaktion.
- COMMIT: Bestätigt eine Transaktion.
- ROLLBACK: Setzt eine Transaktion zurück, falls ein Fehler auftritt.
- SAVEPOINT: Legt einen Speicherpunkt innerhalb einer Transaktion fest.
- SET TRANSACTION: Spezifiziert Eigenschaften für die Transaktion.
`

export const lSQLenv = new Lesson('Enviroments für Datenbanken', 'text')
    .addCategory(sqlCategory)
    .addContent({
        id: 'sql:env',
        index: 1,
        content: cSQLenv
    })
