import React, { SVGProps } from 'react'

export default function Enlarge(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15 9L20 4M20 4V8M20 4H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 15L4 20M4 20V16M4 20H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
