import React, { SVGProps } from 'react'

export default function MacOptionKey(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M14 10H15.125H17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17 14L14.9167 14L12 14L10.3333 10H7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
