import React, { SVGProps } from 'react'

export default function Bank(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 9.5L12 4L21 9.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 20H19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 9L14 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 17L6 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 17L10 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 17L14 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 17L18 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
