import React, { Component } from 'react'
import '../../../styles/card_flip.css'
import { NuiHeader, NuiText } from '../../text'
import { FlipCardProps, FlipCardState } from '../cards.types'

export class FlipCard extends Component<FlipCardProps, FlipCardState> {
  constructor(props: FlipCardProps) {
    super(props)
    this.state = {
      levelCap: null,
      curLevel: null,
      card: null,
    }
  }

  componentDidMount() {
    this.setupCard()
  }

  setupCard() {
    const { identifer } = this.props
    const level = document.getElementById(`level:${identifer}`)
    const card = document.getElementById(`card:${identifer}`)

    if (level !== null && card !== null) {
      const levelCap = parseInt(level.getAttribute('data-level-cap') ?? '0')
      const curLevel = parseInt(level.getAttribute('data-level-current') ?? '0')

      this.setState({
        levelCap: levelCap,
        curLevel: curLevel,
        card: card,
      })
    }
  }

  handleCardClick = () => {
    const { card } = this.state
    if (card !== null) {
      if (card.classList.contains('NuiFlippedCard--flipped')) {
        card.classList.add('NuiFlippedCard--unflip')
        setTimeout(() => {
          card.classList.remove('NuiFlippedCard--flipped', 'NuiFlippedCard--unflip')
        }, 500)
      } else {
        card.classList.add('NuiFlippedCard--flipped')
      }
    }
  }

  render() {
    const { keyword, explanation, identifer, detail, style, onMouseEnter, onMouseLeave, className } = this.props
    return (
      <div
        className={`NuiFlippedCard-scene ${className}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
      >
        <div id={`card:${identifer}`} className='NuiFlippedCard' onClick={this.handleCardClick}>
          <div className='NuiFlippedCard-face NuiFlippedCard-backing'>
            <NuiHeader title={keyword} />
          </div>
          <div className='NuiFlippedCard-face NuiFlippedCard-front'>
            <NuiHeader title={keyword} />
            <div className='main-pane'>
              <NuiText text={explanation} />
            </div>
            <div className='desc'>
              <p>{detail}</p>
              <div id={`level:${identifer}`} data-level-cap='3' data-level-current='1'></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
