import React, { useState } from "react";
import {
    NuiAccordion,
    NuiIcon,
    NuiLogo,
    NuiDropdown,
    NuiDropdownSlide,
    NuiTDropdownItem,
    NuiCard,
    NuiDropZone,
    NuiCardLarge,
    NuiInput,
    NuiLoadingAnimation,
    NuiButton,
    NuiSwitch,
    NuiTable,
    NuiBadge,
    NuiText
} from "nui";
import { NuiIconsType } from "nui/dist/esm/icons";
import Text from "./dev/dev";
import { NuiCardFlip } from "nui";
const testMarkdown = `
\`\`\`mermaid
%%{init: {"flowchart": {"htmlLabels": false}} }%%
flowchart LR
markdown["This **is** _Markdown_"]
newLines["Line1
    Line 2
Line 3"]
    markdown --> newLines
\`\`\`
`;


export const UiLibary = (): JSX.Element => {
    const [showSlideLeft, setShowSlideLeft] = useState(false);
    const [showSlideRight, setShowSlideRight] = useState(false);
    const [showFall, setShowFall] = useState(false);
    const accordionItem = [
        {
            header: "Test One",
            body: "This is just a test!",
        },
        {
            header: "Test Two",
            body: "This is another test!",
        },
    ];
    const dropdwonItem: NuiTDropdownItem[] = [
        {
            key: '1',
            title: "Test One",
            icon: "AppStore",
        },
        {
            key: '2',
            title: "Test Two",
            icon: "AppStore",
        },
    ];

    return (
        <div>
            <NuiIcon icon="AppStore" />
            <NuiIcon icon="AppStore" color="green" size="s" />
            <NuiIcon icon="AppStore" size="m" />
            <NuiIcon icon="AppStore" size="l" />
            <NuiIcon icon="AppStore" size="xl" color="blue" />
            <NuiLogo logo="1password" />
            <NuiAccordion style="list" items={accordionItem} />
            <NuiAccordion style="block" items={accordionItem} />
            <NuiDropdown title={{ title: "Dropdown", icon: "AppStore" }} items={dropdwonItem} />
            <NuiDropdown title={{ title: "Dropdown Block", icon: "AppStore" }} items={dropdwonItem} type="block" />

            <div>
                <NuiButton onClick={() => setShowSlideRight(!showSlideRight)} text="Right" icon="ArrowRight" color="primary" />
                <NuiDropdownSlide
                    visible={showSlideRight}
                    key={'example'}
                    header={dropdwonItem}
                    body={dropdwonItem}
                    footer={dropdwonItem}
                    position="right"
                />
            </div>
            <div>
                <NuiButton onClick={() => setShowSlideLeft(!showSlideLeft)} text="Left" icon="ArrowLeft" color="primary" />
                <NuiDropdownSlide
                    visible={showSlideLeft}
                    key={'example'}
                    header={dropdwonItem}
                    body={dropdwonItem}
                    footer={dropdwonItem}
                    position="left"
                />
            </div>
            <div>
                <NuiButton onClick={() => setShowFall(!showFall)} text="Top" icon="ArrowUp" color="primary" />
                <NuiDropdownSlide
                    visible={showFall}
                    key={'example-two'}
                    header={dropdwonItem}
                    body={dropdwonItem}
                    footer={dropdwonItem}
                    position="top"
                />
            </div>
            <NuiCard
                title="Test One"
                body="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan."
                thumb="https://avatars.githubusercontent.com/u/65290559?v=4"
                link={[{
                    onClick: () => { alert('Click Link') },
                    title: "Read More",
                }]}
            />
            <NuiDropZone />
            <NuiCardLarge
                title="Test One"
                body="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Praesent in mauris eu tortor porttitor accumsan."
                thumb="https://avatars.githubusercontent.com/u/65290559?v=4"
                buttons={{
                    topRight: {
                        text: "Read More",
                        icon: "ArrowRight",
                        onClick: () => { alert('Click Link') },
                    },
                    topLeft: {
                        text: "Latest",
                        icon: "Star",
                        onClick: () => { alert('Click Link') },
                    },
                    bottomLeft: {
                        header: "Noah",
                        body: "@noerkelit",
                        image: "https://avatars.githubusercontent.com/u/65290559?v=4",
                        onClick: () => { alert('Click Link') },
                    },
                    bottomRight: () => { alert('Click Link') },
                }}
            />
            <NuiInput type="password" placeholder="Password" key="test" icon="Eye" />
            <NuiLoadingAnimation />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} fill={false} color="red" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} fill={true} color="red" />

            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} fill={true} color="red" icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Test"} fill={false} color="red" icon="Apple" />

            <NuiButton onClick={() => { alert('Click Link') }} text={"Primary"} fill={true} color="primary" icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Primary"} color="primary" icon="Apple" />

            <NuiButton onClick={() => { alert('Click Link') }} text={"success"} fill={true} color="success" icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"success"} color="success" icon="Apple" />

            <NuiButton onClick={() => { alert('Click Link') }} text={"Warning"} fill={true} color="warning" icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Warning"} color="warning" icon="Apple" />

            <NuiButton onClick={() => { alert('Click Link') }} text={"Error"} fill={true} color="error" icon="Apple" />
            <NuiButton onClick={() => { alert('Click Link') }} text={"Erro"} color="error" icon="Apple" />
            <NuiSwitch text="Hola" />
            <NuiTable
                itemsPerPage={3}
                title="Test Table"
                columns={[
                    { title: 'Test One', icon: 'Apple', render: (item) => <div>{item[0].title} <NuiIcon icon={item[0].icon as NuiIconsType} /></div> },
                    { title: 'Test Two', icon: 'Apple', render: (item) => <div>{item[1].title} <NuiIcon icon={item[1].icon as NuiIconsType} /></div> },
                ]}
                data={[
                    [{
                        title: 'A',
                        icon: 'Battery75',
                    },
                    {
                        title: 'AA',
                        icon: 'Apple',
                    }],
                    [{
                        title: 'B',
                        icon: 'Activity',
                    },
                    {
                        title: 'BB',
                        icon: 'Apple',
                    }],
                    [{
                        title: 'C',
                        icon: 'Battery75',
                    },
                    {
                        title: 'CC',
                        icon: 'Apple',
                    }],
                    [{
                        title: '[Page One] Next Test 7',
                        icon: 'Activity',
                    },
                    {
                        title: '[Page One] Test 8',
                        icon: 'Apple',
                    }],
                    [{
                        title: '[Page One] Test 9',
                        icon: 'Battery75',
                    },
                    {
                        title: '[Page One] Test 10',
                        icon: 'Apple',
                    }],
                    [{
                        title: '[Page two] Test 11',
                        icon: 'Activity',
                    },
                    {
                        title: '[Page two] Test 12',
                        icon: 'Apple',
                    }]
                ]}
            />
            <NuiBadge content="Test" icon="Yoga" />
            <NuiText text={"Hola"} />
            <NuiText text={testMarkdown}
            />
            <NuiCardFlip keyword={'Test 1'} explanation={'Just Test 1'} detail={'Detail Test 1'} identifer={'test:1'} />
        </div>
    )
}