import React, { SVGProps } from 'react'

export default function CssThree(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 3L5.77778 20.0899L12 22L18.2222 20.0899L20 3H4Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 7H16.5L15.5 17L12 18L8.5 17L8.25 14.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16 11.5H7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
