import { Lesson } from "../../../lessons"
import { sqlCategory } from "../../category"

const cSQLeinleitung = `
## Was ist SQL?

**S**tructured **Q**uery **L**anguage, ist eine Sprache, die zur Kommunikation mit einer relationalen Datenbank dient.
Die Syntax von SQL ist relativ einfach und die Semantik basiert auf der englischen Sprache.

Dabei wird SQL in fünf Kategorien aufgeteilt.

- <a href="#data-definition-language">DDL</a> – **D**ata **D**efinition **L**anguage
- <a href="#data-query-language">DQL</a> – **D**ata **Q**uery **L**anguage
- <a href="#data-manipulation-language">DML</a> – **D**ata **M**anipulation **L**anguage
- <a href="#data-control-language">DCL</a> – **D**ata **C**ontrol **L**anguage
- <a href="#transaction-control-language">TCL</a> – **T**ransaction **C**ontrol **L**anguage

## Arten von Datenbanken

### Relationales Datenbankmodell

Eine Tabellen basierte Datenbank, bei der sowohl Datensätze innerhalb einer Tabelle, als auch unterschiedliche Tabellen zueinander in Relation gesetzt werden können. Die Filterung, Kategorisierung und Verknüpfung von Daten wird bei dieser Art Datenbank durch den Einsatz relationaler Algebra möglich.

![Relationle Datenbank](https://upload.wikimedia.org/wikipedia/de/thumb/a/ab/Er-diagramm.svg/1920px-Er-diagramm.svg.png)"

### Hierarchisches Datenbankmodell

Die Beziehung der Daten untereinander erfolgt ausschließlich vertikal, woraus sich eine Baumstruktur ergibt. Dadurch kann jeder Datensatz nur einem Attribut zugewiesen werden. Darum wird bei der streng hierarchischen Darstellung von Datenbanken auch von Eltern-Kind-Beziehungen gesprochen. Hierarchisch höher gestellte Eltern (Attribute) können zwar mehrere Kinder (Daten) haben und so einen Datensatz ausgeben. Doch Kinder können immer nur einen Elternteil haben. Daraus ergibt sich eine reine 1:1-Beziehung. Verbindungen über mehrere Ebenen hinweg oder zwischen unterschiedlichen Hierarchiebäumen sind nicht möglich.

![Hierarchische Datenbank](https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Hierarchisches_Datenbankmodell.svg/1200px-Hierarchisches_Datenbankmodell.svg.png)

### Netzwerkdatenbankmodell

Bei einem Netzwerkdatenbankmodell handelt es sich um eine etwas aufgeweichte Form des hierarchischen Modells. Wobei bei einer netzwerkartigen Datenbank besonders die strenge 1:1-Beziehung zwischen Eltern und Kind aufgebrochen wurde, wodurch jeder Datensatz mehrere Eltern besitzen kann. So können alle Informationen in der Datenbank über verschiedene Suchwege und Zugriffe gefunden, bearbeitet und verwaltet werden.

![Netzwerkdatenbankmodell](https://upload.wikimedia.org/wikipedia/commons/b/b0/Netzwerkdatenbankmodell.png)

### Objektorientiertes Datenbankmodell

Das objektorientierte Datenbankmodell schnürt quasi zusammengehörige Pakete: Ein Datensatz wird mit all seinen Attributen zu einem Objekt zusammengefasst. So sind alle Informationen direkt verfügbar. Statt also alles auf verschiedene Tabellen zu verteilen, sind die Daten gebündelt abrufbar.

![Objektorientierte Datenbank](https://johannajochem.files.wordpress.com/2019/07/image-371.png)

### Dokumentenorientiertes Datenbankmodell

Analog zur objektorientierten Datenbank, werden bei diesem Modell Daten als Dokumente gespeichert, denen jeweils eine individuelle Identifikationsnummer zugewiesen wird. Ein Dokument besteht aus einem Schlüssel und einem Wert und entspricht dem, was in der tabellarischen, relationalen Datenbank ein Datensatz ist.

![Dokumentenorientierte Datenbank](https://johannajochem.files.wordpress.com/2019/07/image-373.png)
`

export const lSQLeinleitung = new Lesson('Einführung SQL', 'text')
    .addCategory(sqlCategory)
    .addContent({
        id: 'sql:einleitung',
        index: 1,
        content: cSQLeinleitung
    })
