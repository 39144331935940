import React, { SVGProps } from 'react'

export default function NoSmokingCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15 12V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 9C15 8 14.2857 7 12.8571 7V7C11.2792 7 10 5.72081 10 4.14286V3'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 9L18 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 12V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 15H6.6C6.26863 15 6 14.7314 6 14.4V12.6C6 12.2686 6.26863 12 6.6 12H12' stroke='currentColor' />
      <path d='M5 5L19 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
