import React, { SVGProps } from 'react'

export default function Shadow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Shadow</title>
      <path d='M12 0C5.3727 0 0 5.3727 0 12c0 3.5145 1.511 6.6754 3.9181 8.8702a4.457 4.457 0 01-.1998-1.3238c0-2.4597 1.9938-4.4535 4.4536-4.4535 2.4596 0 4.4535 1.9938 4.4535 4.4535 0 1.9565-1.262 3.6171-3.016 4.2153C10.382 23.9178 11.1815 24 12 24c6.6273 0 12-5.3727 12-12S18.6273 0 12 0Z' />
    </svg>
  )
}
