import { Lesson } from "../../../lessons"
import { securityCategory } from "../../category"

const cCsAuth = `
## Was ist Authentifizierung?

Authentifizierung beschreibt das Verfahren, bei dem der die behaubtung einer Identität gebprüft, bezihungweise Verifiziert, wird.

## Was für Authentifizierungs Verschlüsselungsverfahren gibt es?

### Authentifizierung

Die Authentifizierung lässt sich in drei Hauptmethoden unterteilen.

<ol>
    <li><a href="#wissen">Wissen</a></li>
    <li><a href="#besitz">Besitz</a></li>
    <li><a href="#biometrie">Biometrie</a></li>
    <li><a href="#biometrie">MFA</a></li>
</ol>

#### Wissen

Beim **Wissen** wird über eine Information verfügt, die Bestätigt, dass man Authorisiert ist die Daten zu erfragen.
Wie zum Beispiel ein Passwort, eine Pin oder eine Sicherheitsfrage.

Dadurch das es, in der Regel, keine Hilfsmittel benötigt, kann die Information leicht an Dritte gelangen, Dupliziert oder erraten werde.

#### Besitz

Beim **Besitz** wird etwas Materielles benötigt, wie zum Beipsiel eine RFID-Karte, um an die Gewünschten Daten zugelangen.
Dies hat den Vorteil das die Zugangsdaten nicht vergessen werden können, allerdings besteht auch hier die Gefahr, dass das Authentifzierungsmedium an dritte gelangt.

#### Biometrie

Bei der **Biometrie** wird zur Authetifizierung etwas benötigt, was die Person ist, wie ein Fingerabdruck oder ein Netzhaut Scan.
Diese Methode ist die einzige wo, im Normalfall, die Methode nicht einfach an Dritte gelangen kann, allerdings ist dies auch die Kostenintensivste methode, da spezielle einrichtungen benötigt werden.
Außerdem besteht eine geringe möglichkeit, eines **false accept** oder einer **false rejection**.
Entweder wird jemanden fälschlicherweise zutritt gewährt oder verweigert, die Chance liegt je nach Qualtität des Systemes bei einem prozent.

#### MFA

Alle drei Methoden haben Vor- und Nachteile und sind nicht zu 100% Sicher.
Um die Sichertheit zu erhöhen wird häufig mit einer **M**ulti-**F**actor **A**uthentication gearbeitet.
Bei dieser werden mehre Methoden kombiniert, als Beispiel:

Um sich bei einem Dienst anzumelden wird ein Passwort (Wissen) und ein sich konstanter ändernder Token (Besitzen) benötigt.
Um diesen Token zu erhalten, muss man sich mit einem Fingerabdruck (Biometrie) bei einer App anmelden.
`

export const lCSauth = new Lesson('Authentifizierung', 'text')
    .addCategory(securityCategory)
    .addContent({
        id: 'sqcurity:auth:einleitung',
        index: 1,
        content: cCsAuth
    })
