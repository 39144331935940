import React, { Component } from 'react'
import { TableProps, TableState } from '../types.tables'
import { NuiIcon } from '../../icons'
import { NuiButton } from '../../button'
import '../../../styles/tables.css'
import { NuiHeader, NuiText } from '../../text'

export class Table<T> extends Component<TableProps<T>, TableState> {
  constructor(props: TableProps<T>) {
    super(props)
    this.state = {
      currentPage: 0,
    }
  }

  renderRows(pageData: T[]): JSX.Element[] {
    const { columns } = this.props
    return pageData.map((item, rowIndex) => (
      <tr key={`table:page:body:row${rowIndex}`}>
        {columns.map(({ render }, j) => (
          <td style={{ width: 'auto' }} key={j}>
            {render(item, rowIndex)}
          </td>
        ))}
      </tr>
    ))
  }

  renderButtons(): JSX.Element[] {
    const { data, itemsPerPage } = this.props
    const { currentPage } = this.state
    const buttons = []
    for (let i = 0; i < Math.ceil(data.length / (itemsPerPage || 1)); i++) {
      buttons.push(
        <NuiButton
          key={`table:page:footer:button${i}`}
          text={(i + 1).toString()}
          style={{ minWidth: '5px', border: 'none', boxShadow: 'none' }}
          onClick={() => this.handlePageChange(i)}
          disabled={i === currentPage}
        />,
      )
    }
    return buttons
  }

  handlePageChange = (wantedPage: number) => {
    const { data, itemsPerPage } = this.props
    const { currentPage } = this.state
    const maxPage = Math.ceil(data.length / (itemsPerPage || 1))
    if (wantedPage >= maxPage) {
      console.warn('Trigger handlePageChange', 'Already Max Page')
      this.setState({ currentPage: maxPage - 1 })
    } else if (wantedPage < 0) {
      console.warn('Trigger handlePageChange', 'Already Min Page')
      this.setState({ currentPage: 0 })
    } else {
      console.warn('Trigger handlePageChange', { maxPage, wantedPage, currentPage })
      this.setState({ currentPage: wantedPage })
    }
  }

  splitData(values: T[]): T[][] {
    const { itemsPerPage } = this.props
    if (!itemsPerPage) return [values]
    const chunkedData = []
    for (let i = 0; i < values.length; i += itemsPerPage) {
      chunkedData.push(values.slice(i, i + itemsPerPage))
    }
    return chunkedData
  }

  render() {
    const { columns, itemsPerPage, data, title } = this.props
    const { currentPage } = this.state
    const pageData = this.splitData(data)[currentPage]

    return (
      <div className='nuiTableWrapper'>
        {title && <NuiHeader title={title} />}
        <table className='nuiTable'>
          <thead>
            <tr>
              {columns.map(({ title, icon }, i) => (
                <th key={i} style={{ width: 'auto' }}>
                  <span>
                    <div>
                      {icon && <NuiIcon icon={icon} />}
                      {title}
                    </div>
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.renderRows(pageData)}</tbody>
        </table>
        <div className='nuiTableFooter'>
          {itemsPerPage && (
            <div className='nuiTableFooterPages'>
              <NuiButton
                icon='NavArrowLeft'
                style={{ minWidth: '5px', border: 'none', boxShadow: 'none', marginRight: '-8px' }}
                text=''
                onClick={() => this.handlePageChange(currentPage - 1)}
                disabled={currentPage === 0}
              />
              {this.renderButtons()}
              <NuiButton
                icon='NavArrowRight'
                style={{ minWidth: '5px', border: 'none', boxShadow: 'none', marginLeft: '-3px' }}
                text=''
                onClick={() => this.handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(this.props.data.length / (itemsPerPage || 1)) - 1}
              />
            </div>
          )}
          <NuiText text={`Items per Page: ${itemsPerPage}`} />
        </div>
      </div>
    )
  }
}
