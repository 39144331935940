import React, { SVGProps } from 'react'

export default function CropRotateBr(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14 20H17C19.2091 20 21 18.2091 21 16V12'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16.5 22.5L14 20L16.5 17.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14 11L14 5C14 4.44772 13.5523 4 13 4L7 4'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2 4H4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 16V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4 2L4 13C4 13.5523 4.44772 14 5 14L16 14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
