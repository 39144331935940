import { Lesson } from "../../../lessons";
import { rechennetzCategory } from "../../category";


const vNeumanText = `
Damit ein Computer als **Universal**rechner gilt müssen sie logisch und räumlich zerlegt sein.
Ein PC von von-Neunmann benötigt folgende Elemente:

- <a href="#rechenwerk">Rechenwerk</a>
- <a href="#speicherwerk">Speicherwerk</a>
- <a href="#steuerwerk">Steuerwerk</a>
- <a href="#kommunikation">Kommunikation</a>

Seine Struktur darf nicht verändert werden, um das Ausführen eines anderen Programmes zu ermöglichen.
Außerdem müssen die Daten und auch der Code der Programme, die er verarbeitet von extern eingegeben werden.

Weiterhin dürfen sich auch alle Programme und Daten nur im selben Speicher des Rechners befinden. Es liegt also keine räumliche Trennung zwischen Daten und Programmen vor. Dieser doch sehr komplexe Speicher muss in eine Anzahl gleich großer Speichereinheiten unterteilt sein, von denen jede ihre eigene Adresse erhält. Unter diesen Adressen müssen die Zellen immer sofort erreichbar sein.
`;
const vrechenwerk = `
### Rechenwerk

#### Prozessoren

Das Rechenwerk für die Ausführung arithmetischer und logischer Operationen zuständig.
Es besteht aus Funktionseinheiten, jede einzellene besitz nur einen einzigen Befehl, der ausgeführt werden kann.

Mit hilfe von **R**educed **I**nstruction **S**et **C**omputer Prozessoren wird immer ein Befehl direkt in ein Steuersignal umgesetzt, was den Vorteil hat, dass es nur einen Takt benötigt und Platz spart.

Alternativ können **C**omplex **I**nstruction **S**et **C**omputing-Prozessoren. Dabei wird ein Befehl durch ein sogenanntes Mikroprogramm realisiert. Das heißt, es werden mehrere feste Schaltungen zusammengeschlossen. Das ermöglicht kürzere Maschinenprogramme, einfachere Compiler und ist wegen der Modularität beliebig um weitere Maschinenbefehle erweiterbar. Allerdings benötigt diese Alternative mehrere Takte zum Umsetzen eines Befehls.

#### Register

Register sind für die Eingabe- und Ausgabedaten einer Operation, als auch für die Ergebnisse durchgeführter Operationen zuständig.
Dabei gibt es vier verschiedene Arten von Registern.

1. <a href="#akkumulator-register">Akkumulator Register</a>
2. <a href="#status-register">Status Register</a>
3. <a href="#adress-memory-port">Adress-Memory-Port</a>
4. <a href="#write-memory-port">Write-Memory-Port</a>

##### Akkumulator Register

Akkumulator-Register sind in jeder Funktionseinheit mindestens einmal enthalten. Sie speichern Operanden, eine Rechengröße, die in einer mathematischen Funktion behandelt wird. Es sind numerische Werte, Ziffern oder ein String, die in einer mathematischen Operation miteinander verknüpft werden, und auch die Resultate der durchgeführten Operationen.
Sie sind also so etwas wie das Blatt, auf dem das Rechenwerk seine Rechnungen aufschreibt.

##### Status Register

Status Register speichern den Zustand der Funktionseinheit, schreibt, lies oder tut diese aktuell nicht.
Jede Funktionseinheit kann nur ein Status-Register besitzt.

##### Adress-Memory-Port

Der Adress-Memory-Port, enthält die Adressen der Speicherzellen, in die Date rein geschrieben oder ausgelesen werdem sollen wollen.
Ohne ihn wäre der Write-Memory-Port überflüssig.

##### Write-Memory-Port

Der Write-Memory-Port stellt mit dem Adress-Memory-Port eine Verbindung zum Speicherwerk her.
Er enthält alle Informationen, die gespeichert werden sollen.
`
const vSteuerwerk = `
### Steuerwerk

Das Steuerwerk ist, der Zentralepunkt von wo aus alles ausgeht, quasi das Gehirn des Computers, und dies ist für die befehligung aller anderen Komponenten zuständig und führt die in einem Programm festgelegten Arbeitsvorgänge durch.
Dies erreicht es durch das Senden von Steuersignalen an die betreffenden Komponenten.

Das ganze lässt sich noch in den **Fetch-Decode-Execute-Zyklus** unterglieder.
Das ganze beginnt mit einem **Fetch** request. Vorerst wird der nächste Befehl mittels der im PC angegebenen Adresse aus dem Speicherwerk über den Read-Memory-Port in das Instruktionsregister geladen.
Anschließend wird das ganze **decoded** und einer Befehlsart zugeordnet.
Der **Execute** wird durch das EVA-Prinzip ausgeführt

\`\`\`mermaid
graph TD;
    Fetch -->|Hole Befehl| Decode;
    Decode -->|Interpretiere Befehl| Execute;
    Execute -->|Führe Befehl aus| Check;
    Check -->|Befehl ausführen?| Execute;
    Execute -->|Speicherzugriff| Memory;
\`\`\`

Das ganze funktioniert auf der Basis des **E**ingabe **V**erarbeitungs **A**usgabe-Prinzip.
Als erstes werden alle benötigten Daten aus dem Speicherwerk gezogen, mit hilfe des **Adress-Memory-Port** werden die richtigen Komponenten angesteuert und der **Read-Memory-Ports** ließt die Daten aus so das der **Write-Memory-Port** die Daten ins **Akkumulator-Register** schreiben kann.
Wenn der Durchlauf fertig ist muss der Programm Counter geupdated werden, dieser kann entweder einfach eins hochgezählt werden, was für sequenzielle Programme der Fall ist, oder auf einen spezifischen Wert gesetzt werden, damit er zu dieser Speicherzelle gehen kann.

\`\`\`mermaid
graph TD;
    subgraph "Eingabe Verarbeitungs Ausgabe-Prinzip"
        subgraph "Daten aus dem Speicherwerk ziehen"
            subgraph "Adress-Memory-Port"
                A[Adress-Memory-Port]
            end
            subgraph "Read-Memory-Port"
                B[Read-Memory-Port]
            end
        end
        C[Akkumulator-Register]
        D[Programm Counter]
    end
    subgraph "Datenfluss"
        A --> B;
        B --> C;
        C --> D;
    end
    subgraph "Programmablauf"
        D -->|Durchlauf fertig?| E[Ja];
        D -->|Durchlauf nicht fertig| F[Nein];
    end
    subgraph "Programm Counter Update"
        E -->|Programm Counter erhöhen| D;
        F -->|Programm Counter auf spezifischen Wert setzen| G;
    end
\`\`\`

Um all diese Aufgaben zu koordinieren, nutzt das Steuerwerk sogenannte Steuersignale. Diese sorgen dafür, dass die Zusammenarbeit zwischen Rechenwerk und Speicherwerk reibungslos funktioniert. Die drei wichtigsten dieser Signale sind

1. der **Adress-Strobe-Befehl**,
2. der **Direction-Befehl** und
3. der **Data Transfer Acknowledge**

Befehl.
Diese treten meist in dieser Reihenfolge auf. Dabei kommt Adress Strobe – oft auch als A abgekürzt – direkt vom Steuerwerk und überbringt dem Speicherwerk die Nachricht, es soll die Daten aus der Adresse lesen, die zurzeit im Adress-Memory-Port steht. Ebenso erhält das Speicherwerk vom Steuerwerk mittels der Direction entweder den Wert 0 oder den Wert 1. Dabei steht 0 für einen Lesezugriff und 1 für einen Schreibzugriff.

Jedes Steuerwerk ist getaktet. Während eines Taktes kann immer genau ein Fetch-Decode-Execute-Zyklus ausgeführt werden. In modernen Rechnern wird dies allerdings mit mehreren Kernen umgangen. Aber unabhängig von der Kernanzahl oder der Leistung des Prozessors laufen alle Vorgänge getaktet ab.
`
const vSpeicherwerk = `
### Speicherwerk

Das Speicherwerk für das Speichern von Programmen und Daten.
Alle Speicherdaten werdne auf Bit ebene geschriben.
Beim Computer gibt es zwei Unterschiedliche Arten von Speicher.

1. <a href="#ram">RAM</a>
2. <a href="#cach">Cach</a>
3. <a href="#festplatten">Festplatten</a>

#### Arbeitsspeicher

##### Ram 

Auf den **R**andom **A**ccess **M**emory kann der Computer äußerst schnell drauf zugreifen und die CPU kann direkt mit ihm komunizieren, weshalb er verwendet wird um Anwendungen zu laden und auszuführen.
Allerdings werden dort keine Daten langfristig gespeichert.
Bei Herunterfahren des Gerätes werden alle Daten auf den Permanenten speicher geschrieben, so dass beim wiederhochfahren der RAM erstmal leer ist, bis wieder Dienste und Programme Starten.
Bei RAM wird zwischen DRAM und SRAM unterschieden.

###### DRAM

Bei einer **D**ynamic **R**andom **A**ccess **M**emory-Speicherzelle wird ein Bit durch die Ladung des Kondensators gespeichert. Das Lesen und Schreiben erfolgt über den Transistor, der als Schalter arbeitet und die Ladung des Kondensators isoliert oder auf die Bitleitung freigibt.

###### SRAM

**S**tatic **R**andom **A**ccess **M**emory ist ein statischer Halbleiterspeicher, was bedeutet, dass der Speicherinhalt mittels Flip-Flops gespeichert wird und so nach dem Abruf des Speicherinhaltes erhalten bleibt. Dadurch ist der Stromverbrauch sehr hoch, was aber zu einem schnellen Arbeiten innerhalb des Speichers führt.
Er ist 100 mal schnelle als der DRAM, allerding sind sie größer, verbrauchen mehr Strom, werden wärmer und sind teurer als DRAM.

##### ROM

Von dem **R**ead **O**nly **M**emory können nur Daten von Gelesen werden, es ist nicht möglich auf ihn zu schreiben.
Dort findet man vorallem Code, den das Gerät zum Starten oder Booten benötigt oder bei jedem Start generiert.

#### Cache

Cach ist ein zwischen Speicher, in diesenm werden Daten nur temporär gespeichert.
Das hat den vorteil das die Unterschiedlichsten Apps, Dienste und Services zum Beispiel Nutzerdaten speichern können, so das sich nicht jedes mal neu angemeldet werden muss.
Dabei kann der Cach als physische Hardware vorliegen oder in Form von Software.
Der wichtigste Vorteil für den Prozessor ist, dass auf diese Weise Leistung eingespart werden kann.

#### Festplatten

Betriebssysteme, Softwareprogramme und andere Dateien lassen sich auf der Festplatten speichern. Festplattenlaufwerke Lesen und Schreiben die zu speichernden Daten über einen Controller. Sie werden als Primären Speicher verwendet.

##### HDD

Eine **H**ard **D**isk **D**rive besteht aus mindestens einer dünnen Aluminium-Platten, die mit einer sehr dünnen magnetisierbaren Schicht überzogen ist. Diese Schicht kann in zwei verschiedenen Richtungen magnetisiert werden. In der Abfolge der verschiedenen Magnetisierungen steckt dann die Information.

##### SSD

**S**olid **S**tate **D**rive haben keine beweglichen Teile und sind daher unempfindlich gegen Stöße, Erschütterungen und Vibrationen. Sie haben außerdem kürzere Zugriffszeiten und arbeiten geräuschlos. Im Gegensatz zu HDD braucht eine SSD nach dem Start keine Anlaufzeit.SSD besitzen keine rotierenden Scheiben und bewegliche Schreib- / Leseköpfe sondern spezielle Chips(siehe bild), um Daten zu speichern.
Sie benötigt weniger Strom und produziert weniger Abwärme.
`
const vKommunikation = `
### Kommunikation

Die Kommunikation erfolgt über Busse dies sind in der Regel Kupferleitung, die auf dem Motherboard verlötet sind. Durch elektrishce stöße wird quasi in Bits gemorst.
Größere Komponenten wie eine GPU oder eine Festplatte werden noch mit einem Kabel verbunden.
Es gibt die folgenden drei Busarten:

- Adressbus
- Datenbus
- Steuerbus
  Desto breiter ein Bus ist desto mehr daten können übertragen werden.
`

export const brsVonNm = new Lesson('Von Neunmann', "text")
    .addCategory(rechennetzCategory)
    .addContent({
        id: 'von_neunmann:intoduction',
        index: 1,
        content: vNeumanText
    })
    .addContent({
        id: 'von_neunmann:intoduction:rechenwerk',
        index: 2,
        content: vrechenwerk
    })
    .addContent({
        id: 'von_neunmann:intoduction:steuerwerk',
        index: 3,
        content: vSteuerwerk
    })
    .addContent({
        id: 'von_neunmann:intoduction:speicherwerk',
        index: 4,
        content: vSpeicherwerk
    })
    .addContent({
        id: 'von_neunmann:intoduction:kommunikation',
        index: 5,
        content: vKommunikation
    })


