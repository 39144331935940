import React, { SVGProps } from 'react'

export default function MessageText(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 12L17 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 8L13 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
