import React, { SVGProps } from 'react'

export default function UserBag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 18C2 14.134 5.13401 11 9 11C10.635 11 12.1391 11.5606 13.3306 12.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.364 16.7069L21.6603 18.7069C21.8393 19.915 20.9032 21 19.6819 21H16.3181C15.0968 21 14.1607 19.915 14.3397 18.7069L14.636 16.7069C14.7813 15.7262 15.6231 15 16.6144 15H19.3856C20.3769 15 21.2187 15.7262 21.364 16.7069Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M17 13H19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
