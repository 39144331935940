import { Lesson } from "../../../lessons"
import { sqlCategory } from "../../category"

const cSQLobject = `
## Datenbankobjekten

- <a href="#tablespace">Tablespace</a>
- <a href="#tabelle">Tabelle</a>
- <a href="#datenbankindex">Datenbankindex</a>
- <a href="#view">View</a>
- <a href="#synonym">Synonym</a>
- <a href="#alias">Alias</a>
- <a href="#stored Procedure">Stored Procedure</a>
- <a href="#stored Function">Stored Function</a>
- <a href="#trigger">Trigger</a>
- <a href="#constraint">Constraint</a>
- <a href="#user">User</a>
- <a href="#schema">Schema</a>
- <a href="#berechtigungen">Berechtigungen</a>

### Tablespace

Ein Tablespace bezeichnet im Datenbankbereich den Speicherort, in den <a href="#tabelle">Tabelle</a>, Indizes und andere Datenobjekte geschrieben werden.

### Tabelle

Tabellen entahlten sämtliche Daten die in einer Datenbank enthaltenen sind. Die Daten in den Tabellen sind logisch in Zeilen und Spalten angeordnet.

### Datenbankindex

Der Datenbankindex ist eine Datenstruktur mit deren Hilfe die Abfrageoptimierung gesteigert werden kann. Mittels einer Indextabelle werden die Daten sortiert auf dem Datenträger abgelegt. Der Index selbst stellt einen Zeiger dar, der entweder auf einen weiteren Index oder auf einen Datensatz zeigt.

### View

Eine View ist eine logische Relation diese wird über eine im Datenbankmanagementsystem (DBMS) gespeicherte Abfrage definiert. Der Datenbankbenutzer kann eine Sicht wie eine normale Tabelle abfragen. Wann immer eine Abfrage diese Sicht benutzt, wird diese zuvor durch das Datenbankmanagementsystem berechnet. Eine Sicht stellt im Wesentlichen einen Alias für eine Abfrage dar.

\`\`\`sql
CREATE VIEW view_name AS
SELECT column1, column2, ...
FROM table_name
WHERE condition;
\`\`\`

### Synonym

ein Synonym ein Alias ​​oder alternativer Name für ein Datenbankobjekt wie eine Tabelle, eine Ansicht, eine gespeicherte Prozedur, eine benutzerdefinierte Funktion und eine Sequenz.

\`\`\`sql
CREATE SYNONYM [schema_name.] synonym_name FOR object
\`\`\`

### Alias

SQL-Aliase werden verwendet, um einer Tabelle oder einer Spalte in einer Tabelle einen temporären Namen zu geben.

Aliase werden häufig verwendet, um Spaltennamen besser lesbar zu machen.

Ein Alias ​​existiert nur für die Dauer dieser Abfrage.

Mit dem Schlüsselwort wird ein Alias ​​erstellt **AS**.

Alias-Spaltensyntax:

\`\`\`sql
SELECT column_name AS alias_name
FROM table_name;
\`\`\`

Syntax der Alias-Tabelle:

\`\`\`sql
SELECT column_name(s)
FROM table_name AS alias_name;
\`\`\`

### Stored Procedure

Eine gespeicherte Prozedur ist ein vorbereiteter SQL-Code, der sich speichern lässt, sodass der Code immer wieder verwendet werden kann.

Syntaxt:

\`\`\`sql
CREATE PROCEDURE procedure_name
AS
sql_statement
GO;
\`\`\`

Ausführung:

\`\`\`sql
EXEC procedure_name;
\`\`\`

### Stored Function

Die CREATE FUNCTION- Anweisung wird zum Erstellen einer Stored Function und benutzerdefinierter Funktionen verwendet. Eine gespeicherte Funktion ist eine Reihe von SQL-Anweisungen, die eine Operation ausführen und einen einzelnen Wert zurückgeben.

\`\`\`sql
CREATE FUNCTION function_name(func_parameter1, func_parameter2, ..)
          RETURN datatype [characteristics]
          func_body
\`\`\`

### Trigger

Bei einer bestimmten Art der Änderungen (z. B. INSERT, UPDATE, DELETE bei SQL) von Daten in einer Tabelle wird ein gespeichertes Programm aufgerufen, das diese Änderung erlaubt, verhindert und/oder weitere Tätigkeiten vornimmt.

\`\`\`sql
CREATE TRIGGER trigger_name
BEFORE/AFTER
INSERT/UPDATE/DELETE
ON tableName
FOR EACH ROW SET operation [trigger_body];
\`\`\`

### Constraint

- NOT NULL- Stellt sicher, dass eine Spalte keinen NULL-Wert haben kann
- UNIQUE- Stellt sicher, dass alle Werte in einer Spalte unterschiedlich sind
- PRIMARY KEY- Eine Kombination aus a NOT NULLund UNIQUE. Identifiziert jede Zeile in einer Tabelle eindeutig
- FOREIGN KEY - Verhindert Aktionen, die Verknüpfungen zwischen Tabellen zerstören würden
- CHECK- Stellt sicher, dass die Werte in einer Spalte eine bestimmte Bedingung erfüllen
- DEFAULT- Legt einen Standardwert für eine Spalte fest, wenn kein Wert angegeben ist
- CREATE INDEX- Wird verwendet, um Daten sehr schnell aus der Datenbank zu erstellen und abzurufen

### User

Ein SQL-User kann sich wie ein User in allen anderen System vorgestellt werden.
Er muss sich mit einem Nutzernamen und Passwort anmelden und darf dann auch nur bestimmte aktion, es seiden er hat **root**rechte, dann darf dieser alles, machen und gewisse Datenbanken sehen.

### Schema

Ein Datenbankschema steht für die logische Konfiguration einer relationalen Datenbank oder eines Teils davon. Es kann sowohl als anschauliche Darstellung als auch als Integritätseinschränkungen, d. h. als Gruppe von Formeln, denen eine Datenbank unterliegt, dargestellt werden. Diese Formeln werden in einer Datendefinitionssprache wie z. B. SQL ausgedrückt. Als Teil eines Datenlexikons gibt ein Datenbankschema an, in welcher Beziehung die Entitäten wie Tabellen, Ansichten, gespeicherte Abläufe usw., aus denen die Datenbank besteht, zueinander stehen.

### Berechtigungen

Berechtigungen Regelen was unterschidliche User machen dürfen und sehen können.
`

const cSQLcli = `

## Befehle

| SQL-Befehl                  | Erklärung                                                                                                                                                   | Beispiel                                                                                                                                                                  |
| --------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------- | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| CREATE DATABASE             | Neue Datenbank anlegen                                                                                                                                      | **CREATE DATABASE Store;**                                                                                                                                                  |
| DROP DATABASE               | Datenbank vollständig löschen                                                                                                                               | **DROP DATABASE Store;**                                                                                                                                                    |
| USE                         | Datenbank für folgende Befehle auswählen                                                                                                                    | **USE Store;**                                                                                                                                                              |
| CREATE TABLE                | Neue Tabelle in Datenbank anlegen; neben dem Namen der Tabelle werden die Namen von Spalten mit ihren Typen festgelegt.                                     | **CREATE TABLE Customers ( CustomerID INT UNSIGNED NOT NULL AUTO_INCREMENT, CustomerName VARCHAR(255) NOT NULL, Country VARCHAR(60) NOT NULL, PRIMARY KEY (CustomerID) );** |
| ALTER TABLE                 | Existierende Tabelle verändern: Spalten hinzufügen / entfernen; Typ oder Name einer Spalte ändern                                                           | **ALTER TABLE Customers ADD Email VARCHAR(50);**                                                                                                                            |
| TRUNCATE TABLE              | Alle Einträge einer Tabelle löschen; dabei Tabellenstruktur beibehalten                                                                                     | **TRUNCATE TABLE Customers;**                                                                                                                                               |
| DROP TABLE                  | Tabelle vollständig löschen; löst beim Ausführen einen Fehler aus, falls die Tabelle nicht existiert                                                        | **DROP TABLE Customers; **                                                                                                                                                  |
| DROP TABLE IF EXISTS        | Tabelle löschen, falls sie existiert.                                                                                                                       | **DROP TABLE IF EXISTS Customers;**                                                                                                                                         |
| ALTER COLUMN                | Datentyp einer existierenden Spalte ändern                                                                                                                  | **ALTER TABLE Customers ALTER COLUMN Email VARCHAR(255);**                                                                                                                  |
| DROP COLUMN                 | Spalte einer Tabelle vollständig löschen                                                                                                                    | **ALTER TABLE customers DROP COLUMN Email;**                                                                                                                                |
| CREATE INDEX                | Benannten Index für Spalten(n) in existierende Tabelle anlegen                                                                                              | **CREATE INDEX IdxEmail ON Customers (Email);**                                                                                                                             |
| DROP INDEX                  | Existierenden Index entfernen                                                                                                                               | **ALTER TABLE Customers DROP INDEX IdxEmail;**                                                                                                                              |
| NOT NULL                    | Definieren, dass der Wert des Feldes nicht NULL sein darf                                                                                                   |                                                                                                                                                                           |
| UNIQUE                      | Definieren, dass der Wert des Feldes innerhalb der Spalte nicht doppelt auftreten darf                                                                      |                                                                                                                                                                           |
| DEFAULT                     | Eine Vorgabe für das Feld setzen; wird beim Erzeugen des Datensatzes kein Wert für das Feld angegeben, kommt der Default zum Einsatz.                       |                                                                                                                                                                           |
| CHECK                       | Eine Bedingung festlegen, die der Wert des Feldes erfüllen muss                                                                                             |                                                                                                                                                                           |
| PRIMARY KEY                 | Festlegen, dass das Feld den Primärschlüssel enthält; impliziert UNIQUE und NOT NULL                                                                        |                                                                                                                                                                           |
| FOREIGN KEY                 | Festlegen, dass der Wert des Feldes ein Primärschlüssel einer anderen Tabelle sein muss                                                                     |                                                                                                                                                                           |
| INSERT INTO                 | Datensatz in Tabelle einfügen                                                                                                                               | **INSERT INTO Customers (CustomerName) VALUES('Tester');**                                                                                                                  |
| UPDATE                      | Felder eines oder mehrerer Datensätze verändern                                                                                                             | **UPDATE Customers SET Email = 'test@example.com' WHERE CustomerName = 'Tester';**                                                                                          |
| DELETE                      | FROM Datensätze aus einer Tabelle löschen                                                                                                                   | **DELETE FROM Customers WHERE CustomerName = 'Tester';**                                                                                                                    |
| SELECT                      | Daten aus der Datenbank abfragen                                                                                                                            | **SELECT CustomerName FROM Customers;**                                                                                                                                     |
| WHERE                       | Abfrage auf Datensätze beschränken, die ein gegebenes Prädikat erfüllen                                                                                     | **SELECT Email FROM Customers WHERE CustomerName = 'Tester';**                                                                                                              |
| AS                          | Alias für Tabelle oder Zeile innerhalb einer Abfrage definieren                                                                                             | **SELECT CustomerID AS ID, CustomerName AS Customer FROM Customers;**                                                                                                       |
| HAVING                      | Abfrage mit Aggregat-Funktion auf zutreffende Datensätze beschränken                                                                                        | **SELECT COUNT(CustomerID), Country FROM Customers HAVING COUNT(CustomerID) >= 1;**                                                                                         |
| DISTINCT                    | Duplikate aus der Ergebnismenge entfernen SELECT DISTINCT Country FROM Customers;                                                                           | **                                                                                                                                                                         |
| LIMIT                       | Die Ergebnismenge auf die obersten Ergebnisse beschränken                                                                                                   | **SELECT \* FROM Customers LIMIT 5;**                                                                                                                                       |
| GROUP BY                    | Ergebnismenge nach einem gemeinsamen Merkmal gruppieren                                                                                                     | **SELECT CustomerName, Country FROM Customers GROUP BY Country;**                                                                                                           |
| ORDER BY                    | Ergebnismenge nach einem Merkmal sortieren                                                                                                                  | **SELECT CustomerName, Email FROM Customers SORT BY CustomerName;**                                                                                                         |
| ASC                         | Aufsteigende („ascending“) Sortierung verwenden                                                                                                             | **SELECT DISTINCT Country FROM Customers SORT BY Country ASC;**                                                                                                             |
| DESC                        | Absteigende („descending“) Sortierung verwenden                                                                                                             | **SELECT DISTINCT Country FROM Customers SORT BY Country DESC;**                                                                                                            |
| UNION                       | Zwei Ergebnismengen vereinigen; die Ergebnismengen müssen über Spalten desselben Typs in derselben Reihenfolge verfügen. Ihre Zeilen werden zusammengefügt. |                                                                                                                                                                           |
| INNER JOIN                  | Zwei Ergebnismengen nach einem gemeinsamen Kriterium filtern                                                                                                |                                                                                                                                                                           |
| LEFT JOIN                   | Die Ergebnismenge der linken Abfrage mit passenden Ergebnissen der rechten Abfrage verknüpfen; nicht passende Felder werden auf NULL gesetzt.               |                                                                                                                                                                           |
| RIGHT JOIN                  | Die Ergebnismenge der rechten Abfrage mit passenden Ergebnissen der linken Abfrage verknüpfen; nicht passende Felder werden auf NULL gesetzt.               |                                                                                                                                                                           |
| FULL OUTER JOIN             | Kombination eines LEFT JOIN und RIGHT J                                                                                                                     |                                                                                                                                                                           |
| CREATE VIEW                 | Eine neue Sicht erzeugen                                                                                                                                    | **CREATE VIEW GermanCustomers AS SELECT CustomerName, Email FROM Customers WHERE Country = "DE";**                                                                          |
| ALTER VIEW                  | Eine existierende Sicht verändern                                                                                                                           | **ALTER VIEW GermanCustomers AS SELECT \* FROM Customers WHERE Country = "DE";**                                                                                            |
| CREATE OR REPLACE VIEW      | Eine neue Sicht erzeugen, bzw. eine ggf. existierende Sicht ersetzen                                                                                        | **CREATE OR REPLACE VIEW GermanCustomers AS SELECT \* FROM Customers WHERE Country = "DE";**                                                                                |
| SHOW CREATE VIEW            | Den zum Erzeugen einer Sicht genutzten SQL-Befehl anzeigen                                                                                                  | **SHOW CREATE VIEW GermanCustomers;**                                                                                                                                       |
| DROP VIEW                   | Eine existierende Sicht löschen                                                                                                                             | **DROP VIEW GermanCustomers;**                                                                                                                                              |
| CREATE PROCEDURE            | Eine neue Prozedur erzeugen                                                                                                                                 |                                                                                                                                                                           |
| ALTER PROCEDURE             | Eine existierende Prozedur verändern                                                                                                                        |                                                                                                                                                                           |
| CREATE OR REPLACE PROCEDURE | Eine neue Prozedur erzeugen, bzw. eine ggf. existierende Prozedur ersetzen                                                                                  |                                                                                                                                                                           |
| DROP PROCEDURE              | Eine existierende Prozedur löschen                                                                                                                          |                                                                                                                                                                           |
| CALL                        | Eine gespeicherte Prozedur ausführen                                                                                                                        |                                                                                                                                                                           |
| GRANT                       | Rechte vergeben                                                                                                                                             | **GRANT ALL ON SomeDB.\* TO 'john'@'localhost';**                                                                                                                           |
| REVOKE                      | Rechte entziehen                                                                                                                                            | **REVOKE INSERT ON _._ FROM 'john'@'localhost';**                                                                                                                           |
| START TRANSACTION           | Den Beginn einer Transaktion markieren                                                                                                                      | **START TRANSACTION;**                                                                                                                                                      |
| COMMIT                      | Eine begonnene Transaktion erfolgreich beenden                                                                                                              | **START TRANSACTION; TRUNCATE TABLE Customers; COMMIT;**                                                                                                                    |
| ROLLBACK                    | Eine begonnene Transaktion abbrechen und den Datenbestand zum Ausgangszustand zurückführen                                                                  | **START TRANSACTION; TRUNCATE TABLE Customers; ROLLBACK;**                                                                                                                  |
| SAVEPOINT                   | Innerhalb einer Transaktion einen benannten Speicherpunkt anlegen                                                                                           | **START TRANSACTION; SAVEPOINT BeforeAddData;**                                                                                                                             |
| ROLLBACK TO                 | Zu einem benannten Speicherpunkt zurückspringen                                                                                                             | **ROLLBACK TO BeforeAddData;**                                                                                                                                              |
`

export const lSQLobject = new Lesson('Objecte', 'text')
    .addCategory(sqlCategory)
    .addContent({
        id: 'sql:object',
        index: 1,
        content: cSQLobject
    })
    .addContent({
        id: 'sql:object:cli',
        index: 2,
        content: cSQLcli
    })

