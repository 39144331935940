import React, { SVGProps } from 'react'

export default function HomeTemperatureOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14 8L4.31143 3.15572C4.11885 3.05943 3.88944 3.07371 3.71029 3.19314L2.5 4'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 11V19C12 20.1046 11.1046 21 10 21H7M7 21H3.6C3.26863 21 3 20.7314 3 20.4V15.6C3 15.2686 3.26863 15 3.6 15H6.4C6.73137 15 7 15.2686 7 15.6V21Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 18C20.1046 18 21 17.1046 21 16C21 14.8954 20.1046 14 19 14C17.8954 14 17 14.8954 17 16C17 17.1046 17.8954 18 19 18Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19 7.5V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 12H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 9H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
