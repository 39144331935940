import React, { SVGProps } from 'react'

export default function Flip(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.5 20H2L9.5 4V20Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.125 20H22L21.0625 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.375 20H14.5V18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 12V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.25 12L19.1875 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.375 8L14.5 4V8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
