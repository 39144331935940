import React, { SVGProps } from 'react'

export default function QrCode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15 12L15 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 3V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 12L18 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 18L21 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 21H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 12H9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6.01111L6.01 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12.0111L12.01 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 12.0111L3.01 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 9.01111L12.01 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 15.0111L12.01 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 21.0111L15.01 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21.0111L12.01 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 12.0111L21.01 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 15.0111L21.01 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 6.01111L18.01 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9 3.6V8.4C9 8.73137 8.73137 9 8.4 9H3.6C3.26863 9 3 8.73137 3 8.4V3.6C3 3.26863 3.26863 3 3.6 3H8.4C8.73137 3 9 3.26863 9 3.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 3.6V8.4C21 8.73137 20.7314 9 20.4 9H15.6C15.2686 9 15 8.73137 15 8.4V3.6C15 3.26863 15.2686 3 15.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6 18.0111L6.01 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9 15.6V20.4C9 20.7314 8.73137 21 8.4 21H3.6C3.26863 21 3 20.7314 3 20.4V15.6C3 15.2686 3.26863 15 3.6 15H8.4C8.73137 15 9 15.2686 9 15.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
