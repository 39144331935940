import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Course } from '../course';
import { IndexCard } from '../index_card';
import { categorys } from '../_index_catalog/category';
import { AppContextType, NuiContextProviderProps, NuiContextType } from '../asstes/types/context.types';
import { lessons } from '../_index_catalog/lessons/_index';
import { courses } from '../_index_catalog';
import { indexCards } from '../_index_catalog/index_cards';

const NuiContext = createContext<NuiContextType | undefined>(undefined);

const NuiContextProvider: React.FC<NuiContextProviderProps> = ({ children }) => {
    const [state, setState] = useState<AppContextType>({
        categorys: categorys,
        courses: courses,
        indexCard: indexCards,
        lessons: lessons,
    });

    const updateCourses = (newCourses: Course[]) => {
        setState(prevState => ({
            ...prevState,
            courses: newCourses
        }));
    };

    const updateIndexCards = (newIndexCard: IndexCard[]) => {
        setState(prevState => {
            const updatedIndexCards = newIndexCard.reduce((acc, newCard) => {
                const existingCardIndex = acc.findIndex(card => card.id === newCard.id);
                if (existingCardIndex !== -1) {
                    acc[existingCardIndex] = newCard;
                } else {
                    acc.push(newCard);
                }
                return acc;
            }, [...prevState.indexCard]);

            return {
                ...prevState,
                indexCard: updatedIndexCards,
            };
        });
    };


    const contextValue: NuiContextType = {
        ...state,
        updateCourses,
        updateIndexCards
    };

    return (
        <NuiContext.Provider value={contextValue}>
            {children}
        </NuiContext.Provider>
    );
};

const useNuiContext = () => {
    const context = useContext(NuiContext);
    if (!context) {
        throw new Error('useNuiContext must be used within a NuiContextProvider');
    }
    return context;
};

export { NuiContext, useNuiContext, NuiContextProvider };