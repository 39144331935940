import React, { SVGProps } from 'react'

export default function Www(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.33789 17C5.06694 19.989 8.29866 22 12.0001 22C15.7015 22 18.9332 19.989 20.6622 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33789 7C5.06694 4.01099 8.29866 2 12.0001 2C15.7015 2 18.9332 4.01099 20.6622 7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 21.9506C13 21.9506 14.4079 20.0966 15.2947 16.9999'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 2.04932C13 2.04932 14.4079 3.90328 15.2947 7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 21.9506C11 21.9506 9.59215 20.0966 8.70532 16.9999'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 2.04932C11 2.04932 9.59215 3.90328 8.70532 7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 10L10.5 15L12 10L13.5 15L15 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 10L2.5 15L4 10L5.5 15L7 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 10L18.5 15L20 10L21.5 15L23 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
