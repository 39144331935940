import React, { SVGProps } from 'react'

export default function Visualstudioappcenter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Visual Studio App Center</title>
      <path d='M18 0v20.625L0 17.94 18 24l6-2.497V2.498L18 0Zm-6.3 5.445L7.8 8.04V4.223h-.09l-2.063-.634L1.5 6.99v1.594l2.685 1.747L1.5 12.079v1.594l4.148 3.408L7.8 16.44v-3.818l3.9 2.595 3.3-1.016v-7.74l-3.3-1.016Zm-6.285 6.161v3.233L2.79 13.001l2.625-1.395Zm6.585.833-4.245-2.108L12 8.224v4.215ZM2.79 7.695l2.625-1.837V9.09L2.79 7.695Z' />
    </svg>
  )
}
