import React, { SVGProps } from 'react'

export default function FileNotFound(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 8L12 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 16.01L12.01 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 3H4V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 11V13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 11V13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 3H20V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 21H4V18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 21H20V18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
