import React, { SVGProps } from 'react'

export default function LitecoinRotateOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.1679 8C19.6247 4.46819 16.1006 2 11.9999 2C6.81459 2 2.55104 5.94668 2.04932 11'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 8H21.4C21.7314 8 22 7.73137 22 7.4V4'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.88146 16C4.42458 19.5318 7.94874 22 12.0494 22C17.2347 22 21.4983 18.0533 22 13'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.04932 16H2.64932C2.31795 16 2.04932 16.2686 2.04932 16.6V20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.5 7V16.4C10.5 16.7314 10.7686 17 11.1 17H15.5' stroke='currentColor' strokeLinecap='round' />
      <path d='M8.5 13L13 11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
