import React, { SVGProps } from 'react'

export default function AlignHorizontalSpacing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 22L3 2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 22V2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 16H9C7.89543 16 7 15.1046 7 14V10C7 8.89543 7.89543 8 9 8H15C16.1046 8 17 8.89543 17 10V14C17 15.1046 16.1046 16 15 16Z'
        stroke='currentColor'
      />
    </svg>
  )
}
