import React, { SVGProps } from 'react'

export default function EaseOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 20C3 20 13 4 21 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
