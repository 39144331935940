import React, { SVGProps } from 'react'

export default function HexagonDice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.7 1.1732C11.8856 1.06603 12.1144 1.06603 12.3 1.17321L21.2263 6.3268C21.4119 6.43397 21.5263 6.63205 21.5263 6.84641V17.1536C21.5263 17.3679 21.4119 17.566 21.2263 17.6732L12.3 22.8268C12.1144 22.934 11.8856 22.934 11.7 22.8268L2.77372 17.6732C2.58808 17.566 2.47372 17.3679 2.47372 17.1536V6.84641C2.47372 6.63205 2.58808 6.43397 2.77372 6.32679L11.7 1.1732Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M17 15L7 15L12 7L17 15Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 6.5L12 7' stroke='currentColor' strokeLinejoin='round' />
      <path d='M2.5 6.5L7 15' stroke='currentColor' strokeLinejoin='round' />
      <path d='M21.5 6.5L17 15' stroke='currentColor' strokeLinejoin='round' />
      <path d='M21.5 6.5L12 6.99995L12 1' stroke='currentColor' strokeLinejoin='round' />
      <path d='M21.5 17.5L17 15' stroke='currentColor' strokeLinejoin='round' />
      <path d='M2.5 17.5L7 15' stroke='currentColor' strokeLinejoin='round' />
      <path d='M7 15L12 23L17 15' stroke='currentColor' strokeLinejoin='round' />
    </svg>
  )
}
