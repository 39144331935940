import React, { SVGProps } from 'react'

export default function MathBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      strokeWidth='1.5'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 19V5C4 3.89543 4.89543 3 6 3H19.4C19.7314 3 20 3.26863 20 3.6V16.7143'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M6 17L20 17' stroke='currentColor' strokeLinecap='round' />
      <path d='M6 21L20 21' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 10H14' stroke='currentColor' strokeLinecap='round' />
      <path d='M12 13.01L12.01 12.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 7.01L12.01 6.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
