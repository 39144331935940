import React, { SVGProps } from 'react'

export default function Linuxserver(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>LinuxServer</title>
      <path d='M6.256.719C.3116 3.7066-1.888 12.1209 1.8164 17.6298h.002a12.0306 12.0306 0 0 0 5.1193 4.5022v-.002c8.7205 4.3354 19.3091-4.3021 16.6433-13.7935-.212-.8906-1.0814-3.8234-3.7424-6.094C18.6023 1.1092 15.6758.6356 13.6294 1.09a.4526.4526 0 0 0-.1485.0644c-.667.4347-1.4822.5953-2.2637.7383a.356.356 0 0 0-.291.3399c-.0107.3518.1813.7607.3125 1.0371.3304.6431.9258 1.0205 1.629 1.1095 2.584.7393 4.5834 3.0838 4.8146 5.8283-1.9676 1.2478-4.6033 1.4143-6.885-.4765-.0361-.03-.072-.0585-.1075-.0899-.7389-.5984-1.8837.3137-1.3887 1.209 1.929 2.527 5.0078 3.4105 7.8421 2.9084-3.1817.5923-7.13 1.0496-10.184-.0176-.7053-1.455-1.7714-2.7333-3.0802-3.6486-.1575-1.5748.2295-3.209 1-4.4982.6043-.926-.7959-1.8365-1.4043-.9356C2.706 5.7782 2.226 7.073 2.0058 8.4087c.019-.1339.0385-.2666.0606-.4004C2.5478 5.1615 3.8621 2.4204 6.256.719Zm10.6684.7187c.047.0044.0606.0113.0606.004.3944.0319.783.2053.8164.4453.0251.1875-.1708.3715-.4355.502a1.873 1.873 0 0 1-.547.168c-.473.0661-.0355-.2467-.078-.5528-.0426-.3062-.5505-.4882-.0762-.5547.1321-.0178.2127-.016.2597-.0118zm-2.8927.4727c.4785.032.4773.4963 0 .5293-.4776-.033-.478-.4973 0-.5293zm-3.9572 4.4768a.6359.6359 0 0 0-.6367.6328v2.1388c.3486-.1712.7823-.1672 1.1876-.0176.5723.3414 1.2689.7013 2.0196.9766a.6359.6359 0 0 0 .5352-.629V7.02a.6362.6362 0 0 0-.6368-.6328Zm-1.8711 3.922a.6362.6362 0 0 0-.6348.6368v2.4688a.6355.6355 0 0 0 .6348.6348h2.4688a.635.635 0 0 0 .6368-.6348v-.1504c-.9005-.518-1.7222-1.2152-2.4064-2.1114l-.0215-.0293-.0175-.0332a1.3752 1.3752 0 0 1-.17-.7813Zm5.0314 0c.6898.1998 1.4073.313 2.1076.2598a.6359.6359 0 0 0-.5098-.2598zm-1.4922 3.1896a.6355.6355 0 0 0 .6211.5508h.7989a8.3544 8.3544 0 0 1-1.42-.5508Z' />
    </svg>
  )
}
