import { Category } from "../../category";

export const categorys: Category[] = []

export const networkCategory = new Category('Netzwerk', 'blue')
categorys.push(networkCategory)

export const storageCategory = new Category('Storage', 'red')
categorys.push(storageCategory)

export const developmentCategory = new Category('Development', 'yellow')
categorys.push(developmentCategory)

export const rechennetzCategory = new Category('Rechennetz', 'purple')
categorys.push(rechennetzCategory)

export const osCategory = new Category('Operationsystem', 'orange')
categorys.push(osCategory)

export const sqlCategory = new Category('SQL', 'violet')
categorys.push(sqlCategory)

export const securityCategory = new Category('Security', 'violet')
categorys.push(securityCategory)
