import React, { SVGProps } from 'react'

export default function BridgeSurface(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 12C3 16 5 21 11 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 6C10 10 12 15 18 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 8.5L3 6.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 3L10 1' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 12L10 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 21L18 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 21H16.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 15H23' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
