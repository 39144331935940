import React, { SVGProps } from 'react'

export default function Spyderide(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Spyder IDE</title>
      <path d='M17.383.3s-.717 1.14-1.798 2.931l-6.274-.035a73.417 73.417 0 0 0-.353-2.74L8.05.59c.138.936.248 1.804.337 2.6h-.02a.524.524 0 0 0-.412.198l-4.377 5.48c-2.193.054-3.49-.07-3.49-.07L0 9.714s1.212.116 3.27.08l1.434 6.47c-1.812 1.535-3.169 2.362-3.169 2.362l.477.784s1.281-.779 3.024-2.225a.5.5 0 0 0 .086.053l6.6 3.249c-.01 2.007.058 3.214.058 3.214l.917-.052s-.07-1.265-.056-3.356l6.218-2.978a.526.526 0 0 0 .215-.19l.245.243c1.149 1.118 1.93 1.598 1.93 1.598l.477-.784s-.67-.406-1.767-1.472a18.93 18.93 0 0 1-.612-.623l1.422-6.16A116.35 116.35 0 0 1 24 8.718l-.306-.866s-1.224.434-3.162 1.183l-4.166-5.322A200.58 200.58 0 0 1 18.16.79zM9.419 4.256l5.532.032c-.586.981-1.237 2.09-1.932 3.297l-3.368.597a52.326 52.326 0 0 0-.232-3.926zm-.91.13c.192 2.06.225 3.46.23 4.087a52.221 52.221 0 0 1-3.766.339zm7.276.294l3.343 4.27a1.83 1.83 0 0 0-.656.086c-.451.14-.857.405-1.22.768-.24.24-.435.498-.582.776-.378.156-.765.317-1.165.485l-1.64-3.109c.692-1.2 1.34-2.304 1.92-3.276zm-2.666 3.824l1.68 3.184-.75.783a1.09 1.09 0 0 1-.122-.102l-1.313-1.313-1.418-1.417a2.9 2.9 0 0 0-.943-.627zM6.984 9.586l-.05.048-2.147 2.147-.448-2.02c.795-.032 1.68-.087 2.645-.175zm2.418.958c.22.051.418.158.576.313l1.312 1.313 1.418 1.418c.046.047.094.09.143.134l-.534.557-3.214-1.582zm-.938.091l-.31 2.247c-.86.98-1.732 1.847-2.549 2.59l-.465-2.1.247.247 2.766-2.766a1.17 1.17 0 0 1 .311-.218zm7.934 1.05l.004.038-.232.232-.087-.137zm2.724.696l-.634 2.743c-.42-.5-.873-1.071-1.334-1.715l.502-.503c.22.009.437-.028.625-.086.3-.093.58-.243.841-.439zm-3.747.033l.1.159a1.29 1.29 0 0 1-.374.126zM8.78 13.563l3.136 1.544a88.99 88.99 0 0 0-.178 4.207L5.9 16.44a33.523 33.523 0 0 0 2.879-2.877zm7.67.437a27.7 27.7 0 0 0 1.782 2.228l-.054.237-5.521 2.644c.025-1.18.078-2.547.18-4.047l.813-.85a3.159 3.159 0 0 0 2.8-.212z' />
    </svg>
  )
}
