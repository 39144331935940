import React, { SVGProps } from 'react'

export default function TemperatureUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 11.9995C2.78555 12.9117 2 14.3641 2 15.9999C2 18.7613 4.23858 20.9999 7 20.9999C9.76142 20.9999 12 18.7613 12 15.9999C12 14.3641 11.2144 12.9117 10 11.9995'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4 12V3H10V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 3L12 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 6L12 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 9H12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7 14C5.89543 14 5 14.8954 5 16C5 17.1046 5.89543 18 7 18C8.10457 18 9 17.1046 9 16C9 14.8954 8.10457 14 7 14ZM7 14V6'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 18V6M19 6L21.5 8.5M19 6L16.5 8.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
