import React, { SVGProps } from 'react'

export default function VehicleGreen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 10L15 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 14L7 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 14L16 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6 18H2V20.4C2 20.7314 2.26863 21 2.6 21H5.4C5.73137 21 6 20.7314 6 20.4V18ZM6 18H13'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M2 18V11.4105C2 11.1397 2.05502 10.8716 2.16171 10.6227L4.4805 5.21216C4.79566 4.47679 5.51874 4 6.31879 4H15.6812C16.4813 4 17.2043 4.47679 17.5195 5.21216L19.8383 10.6227C19.945 10.8716 20 11.1397 20 11.4105V12.5'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M17 23C17 23 17.9 19.8824 20 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19.8022 21.4245L19.6677 21.4372C17.9758 21.5961 16.469 20.3541 16.3021 18.663C16.1353 16.9719 17.3715 15.4721 19.0634 15.3131L22.049 15.0326C22.2406 15.0146 22.4111 15.1552 22.43 15.3466L22.6847 17.9276C22.8589 19.6929 21.5683 21.2586 19.8022 21.4245Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
