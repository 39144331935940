import React, { SVGProps } from 'react'

export default function Radiation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.0001 20.6615C15.5292 21.5123 13.8215 21.9993 12.0001 21.9993C10.1786 21.9993 8.47093 21.5123 7.00006 20.6615L10.0001 16C10.0001 16 11.0001 16.4 12.0001 16.4C13.0001 16.4 14.0001 16 14.0001 16L17.0001 20.6615Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9976 3.33886C18.4699 4.18724 19.7455 5.42266 20.6562 7.00007C21.5669 8.57748 21.999 10.2999 21.9976 11.9991L16.4607 11.7318C16.4607 11.7318 16.3071 10.6657 15.8071 9.79971C15.3071 8.93368 14.4607 8.26766 14.4607 8.26766L16.9976 3.33886Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.99825 11.9994C1.99682 10.3002 2.42894 8.57781 3.33966 7.0004C4.25038 5.42299 5.52596 4.18757 6.99825 3.33918L9.53518 8.26798C9.53518 8.26798 8.68877 8.93401 8.18877 9.80003C7.68877 10.6661 7.53518 11.7321 7.53518 11.7321L1.99825 11.9994Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0001 13.9993C10.8955 13.9993 10.0001 13.1039 10.0001 11.9993C10.0001 10.8947 10.8955 9.99928 12.0001 9.99928C13.1046 9.99928 14.0001 10.8947 14.0001 11.9993C14.0001 13.1039 13.1046 13.9993 12.0001 13.9993Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
