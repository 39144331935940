import React, { SVGProps } from 'react'

export default function Pound(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.6667 13.6C9.55558 16.2666 7.88891 18.9333 5.66669 20H16.2222C16.2222 20 17.8889 20 19 18.9333'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.1111 13.6H4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17.3333 8.8C17.3333 6.14903 15.0947 4 12.3333 4C9.57189 4 7.33331 6.14903 7.33331 8.8C7.33331 11.451 9.57189 13.6 12.3333 13.6'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
