import React, { Component } from 'react'
import * as d3 from 'd3'
import { GraphContainer } from '../container/container'
import { DataPoint, LineChartProps } from '../types.graph'

export class LineChart extends Component<LineChartProps> {
  private svgRef: React.RefObject<SVGSVGElement>

  constructor(props: LineChartProps) {
    super(props)
    this.svgRef = React.createRef()
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }

  drawChart() {
    const { data, width, height, colors } = this.props
    const svg = d3.select(this.svgRef.current)
    const margin = { top: 20, right: 20, bottom: 30, left: 50 }

    const uniqueDates: Date[] = Array.from(new Set(data.flatMap((lineData) => lineData.map((d) => d.date))))

    const x = d3
      .scaleBand()
      .domain(uniqueDates.map((date) => date.toISOString()))
      .range([0, width])
      .padding(0.1)

    const maxYValue = d3.max(data.flatMap((lineData) => lineData.map((d) => d.value))) as number

    const y = d3.scaleLinear().range([height, 0]).domain([0, maxYValue])

    const line = d3
      .line<DataPoint>()
      .x((d) => x(d.date.toISOString())! + x.bandwidth() / 2)
      .y((d) => y(d.value)!)

    svg.selectAll('*').remove()

    data.forEach((lineData, index) => {
      svg
        .append('path')
        .datum(lineData)
        .attr('fill', 'none')
        .attr('stroke', colors[index % colors.length])
        .attr('stroke-width', 1.5)
        .attr('d', line)
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
    })

    svg
      .append('g')
      .attr('transform', `translate(${margin.left},${height + margin.top})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-90)')

    svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`).call(d3.axisLeft(y))
    svg.attr('viewBox', '0 0 960 500')
  }

  render() {
    const { width, title } = this.props
    return (
      <GraphContainer width={width} title={title}>
        <svg ref={this.svgRef}></svg>
      </GraphContainer>
    )
  }
}
