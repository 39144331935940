import { Lesson } from "../../../lessons"
import { storageCategory } from "../../category"

const cParitaetErasureCode = `
## Erasure code

**E**rasure **C**oding ist eine Datenschutzmethode, bei der Daten in Fragmente zerlegt, erweitert und mit redundanten Datenteilen verschlüsselt und über eine Reihe verschiedener Standorte oder Speichermedien hinweg gespeichert werden.

Wenn ein Laufwerk ausfällt oder Daten beschädigt werden, können die Daten aus den auf den anderen Laufwerken gespeicherten Segmenten rekonstruiert werden. Auf diese Weise kann EC dazu beitragen, die Datenredundanz zu erhöhen, ohne den Overhead oder die Einschränkungen, die mit verschiedenen Implementierungen von RAID einhergehen .

Bei der Löschcodierung wird eine Dateneinheit, z. B. eine Datei oder ein Objekt, in mehrere Fragmente aufgeteilt und dann zusätzliche Fragmente (<a href="#parität">Parität</a>sblöcke) erstellt, die für die Datenwiederherstellung verwendet werden können.
`

const cParitaetParitaet = `
## Parität
Die Parität ermöglicht die Erhöhung der Fehlertoleranz des Speichers, im Falle des Ausfalls eines Laufwerks kann dessen Inhalt mithilfe der Daten der verbleibenden Laufwerke auf einem Ersatzlaufwerk wiederhergestellt werden.
Dabei wird dem Fregment ein Paritätsbit hinzugefügt, dies ermöglicht dem Zielgerät die Prüfung, ob alle Daten korrekt empfange wurden.
`
export const lSParitaeten = new Lesson('Parität', 'text')
    .addCategory(storageCategory)
    .addContent({
        id: 'storage:paritaet:erasure_code',
        index: 1,
        content: cParitaetErasureCode
    })
    .addContent({
        id: 'storage:paritaet:paritaet',
        index: 2,
        content: cParitaetParitaet
    })