import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../category";
import { NuiDropdownSearch, NuiCardLarge, NuiInput, NuiTable, NuiButton } from "nui";
import { Lesson } from ".";
import { Course } from "../course";
import { useNuiContext } from "../app_context/app_context";
import { CategoryType } from "../asstes/types/category.types";
export const LessonPage = (): JSX.Element => {
    const { categorys, courses, lessons } = useNuiContext();
    const navigate = useNavigate()
    const [filteredLessons, setfilteredLessons] = useState<Lesson[] | undefined>(undefined)
    // FILTER VALUES
    const [filterText, setFilterText] = useState<string>('')
    // SELECT FILTER VALUES
    const [selectedOptions, setSelectedOptions] = useState<Category[]>([]);
    // DISPLAY VALUES
    const [displayType, setDisplayType] = useState<"table" | "cards">("cards")


    const filterByCategory = (lesson: Lesson[], selectedCategories: Category[]): Lesson[] => {
        if (selectedCategories.length === 0) {
            return lesson;
        }
        return lessons.filter(lesson =>
            lesson.categorys.some(category =>
                selectedCategories.some(selectedCategory =>
                    selectedCategory.id === category.id
                )
            )
        );
    };


    const searchInLessons = (text: string, categorys: Category[]): Lesson[] => {
        const regex = /[a-zA-Z0-9]/;
        const filterRegex = new RegExp(text, 'i');
        let filteredLessons: Lesson[] = [];

        // Filter courses by selected categories
        filteredLessons = filterByCategory(lessons, categorys);

        // If the search text is not valid, return the filtered courses
        if (!regex.test(text)) {
            console.warn('[System//Lesson] No Valid Text Search Request');
            return filteredLessons;
        }

        // Filter courses by title based on the search text
        return filteredLessons.filter((lesson: Lesson) => {
            return filterRegex.test(lesson.title);
        });
    };

    const handleSelectedOptionsChange = (selectedOptions: CategoryType[]) => {
        setSelectedOptions(selectedOptions as Category[])
    }
    useEffect(() => {
        setfilteredLessons(searchInLessons(filterText, selectedOptions))
    }, [filterText, selectedOptions])

    return (
        <div className="content-page">
            <div className="searchBar" style={{
                width: 'calc(100% - 5rem)'
            }}>
                <NuiInput
                    key='search'
                    type='text'
                    placeholder='Search'
                    icon='Search'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.target.value)}
                />
                <NuiDropdownSearch options={categorys} categorys={categorys} onSelectedOptionsChange={handleSelectedOptionsChange} />
                <div>
                    <NuiButton icon="Table" style={{ minWidth: "10px", marginRight: "0px" }} onClick={() => setDisplayType("cards")} />
                    <NuiButton icon="Table2Columns" style={{ minWidth: "10px", marginLeft: "5px" }} onClick={() => setDisplayType("table")} />
                </div>
            </div>
            <div className="content-body"
                style={{
                    marginTop: "95px",
                    width: 'calc(100% - 5rem)'
                }}
            >
                {filteredLessons && filteredLessons.length !== 0 && displayType === "table" ?
                    <div style={{ width: "100vw" }}>
                        <NuiTable
                            itemsPerPage={50}
                            title=""
                            columns={[
                                {
                                    title: 'Title',
                                    render: (item: Lesson) => <div>{item.title}</div>
                                },
                                {
                                    title: 'Beschreibung',
                                    render: (item: Lesson) => <div>{item.description}</div>
                                },
                                {
                                    title: 'Categorys',
                                    render: (item: Lesson) => item.categorys.map((cat: Category) => {
                                        return cat.render()
                                    })
                                },
                                {
                                    title: 'Teil vom Kurs',
                                    render: (item: Lesson) => {
                                        const course = courses.find((course: Course) => course.id === item.parent);
                                        const parentCourse = course?.parent && courses.find((cou: Course) => cou.id === course.parent);
                                        return course ? <div>{parentCourse ? parentCourse.title : course.title}</div> : null;
                                    }
                                },
                                {
                                    title: 'Start',
                                    render: (item: Lesson) => <NuiButton
                                        icon="NavArrowRight"
                                        onClick={() => { navigate(`/courses/${item.parent}/lesson/${item.id}`) }}
                                        style={{
                                            minWidth: "10px"
                                        }}
                                    />
                                },
                            ]}
                            data={filteredLessons}
                        /> </div>
                    : <></>}
                {filteredLessons && filteredLessons.length !== 0 && displayType === "cards" ? filteredLessons.map((lesson: Lesson) => {
                    return (
                        <NuiCardLarge
                            key={`lesson:${lesson.id}`}
                            title={lesson.title}
                            body={
                                <div>
                                    {lesson.description ? lesson.description : ""}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap"
                                        }}
                                    >
                                        {lesson.categorys.map((category: Category) => {
                                            return category.render()
                                        })}
                                    </div>
                                </div>
                            }
                            thumb={lesson.thumb}
                            buttons={{
                                topLeft: {
                                    text: "Favorisieren",
                                    icon: "Star",
                                    onClick: () => { alert('Click Link') },
                                },
                                bottomRight: () => { navigate(`/courses/${lesson.parent}/lesson/${lesson.id}`) },
                            }}
                        />
                    )
                }) : <></>}
            </div>
        </div >
    )
}