import React, { SVGProps } from 'react'

export default function Powerfx(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Power Fx</title>
      <path d='M7.579 23.47H1.092A1.099 1.099 0 0 1 0 22.377c0-.198.054-.392.155-.561l2.575-4.291a4.377 4.377 0 0 0 0-4.497l-1.544-2.572a6.556 6.556 0 0 1-.934-3.372C.252 3.489 3.21.53 6.805.53h11.84c.849 0 1.374.926.937 1.654l-2.639 4.4h-.301a.498.498 0 0 0-.429.242l-.391.651-.072-.12a1.59 1.59 0 0 0-1.365-.773H8.74c-1.239 0-2.004 1.35-1.367 2.411l2.575 4.291a3.87 3.87 0 0 1 0 3.981l-2.575 4.292a1.592 1.592 0 0 0 .206 1.911Zm7.742-.53c-.197.329-.553.53-.936.53H8.739a1.097 1.097 0 0 1-1.092-1.092c0-.198.054-.393.156-.562l2.574-4.292a4.375 4.375 0 0 0 0-4.496l-2.574-4.29a1.092 1.092 0 0 1 .936-1.654h5.646c.383 0 .739.201.936.53l2.574 4.29a6.562 6.562 0 0 1 0 6.745l-2.574 4.291Zm.793-.349 2.21-3.685a7.06 7.06 0 0 0 0-7.259l-2.21-3.684.528-.879h6.264c.849 0 1.374.926.937 1.654l-2.576 4.29-.018.032-.039.066-.018.033-.024.045-.012.021-.017.033-.01.018a4.381 4.381 0 0 0-.407 2.819l.003.016c.012.063.026.127.041.189l.007.026.009.036.007.029.023.084.009.029.004.015c.019.063.039.126.061.189l.027.076.013.035c.078.206.172.408.282.603l.024.042.035.06 2.576 4.292c.102.169.155.364.155.562 0 .599-.493 1.092-1.092 1.092H17.26c-.384 0-.739-.201-.937-.53l-.209-.349Z' />
    </svg>
  )
}
