import React, { SVGProps } from 'react'

export default function KanbanBoard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 3.6V20.4C3 20.7314 3.26863 21 3.6 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6 6L6 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 6V9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 6V13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 6V11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
