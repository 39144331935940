import React, { SVGProps } from 'react'

export default function GitCommit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 12H3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 12L21 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
