import React, { SVGProps } from 'react'

export default function Facebookgaming(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Facebook Gaming</title>
      <path d='M0 0v24h15.67v-7.35H7.35v-9.3H24V0zm8.33 15.68h8.32V24H24V8.32H8.33Z' />
    </svg>
  )
}
