import React, { SVGProps } from 'react'

export default function ColorWheel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 2V8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 16V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 12H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 12H22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.92896 4.92871L9.1716 9.17135' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.8284 14.8286L19.071 19.0713' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.92896 19.0713L9.1716 14.8286' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.8284 9.17139L19.071 4.92875' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
