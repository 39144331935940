import React, { SVGProps } from 'react'

export default function TextArrowsUpDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 21V11M18 21L16 18.5M18 21L20 18.5M18 11L16 13M18 11L20 13'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 5L9 17M9 17H7M9 17H11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 7V5L3 5L3 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
