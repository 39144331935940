import React from 'react';
import { CategoryType } from "../asstes/types/category.types";
import { NuiBadge } from 'nui';

export class Category implements CategoryType {
    id: string;
    color: string;
    title: string;

    constructor(title: string, color: string) {
        this.id = title.toLowerCase().replace(/ /g, '_')
        this.title = title;
        this.color = color
    }

    render = (): JSX.Element => {
        return (<NuiBadge content={this.title} key={this.id} background={this.color} />)
    }
}