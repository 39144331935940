import React, { SVGProps } from 'react'

export default function IntersectAlt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15.0098 3.00001L14.9998 3.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.0098 3.00001L10.9998 3.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.00977 3.00001L6.99977 3.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.00977 3.00001L2.99977 3.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.00977 7.00001L2.99977 7.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.00977 11L2.99977 11.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.00977 15L2.99977 15.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 21.01L9.01 20.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13 21.01L13.01 20.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 21.01L17.01 20.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 21.01L21.01 20.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 17.01L21.01 16.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 13.01L21.01 12.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 9.01L21.01 8.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9 17L9 10C9 9.44772 9.44772 9 10 9L17 9'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 7L15 14C15 14.5523 14.5523 15 14 15L7 15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
