import React, { SVGProps } from 'react'

export default function LoftThreed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M22 17C13 17 11 22.9999 2 22.9999' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22 1C13 1 11 6.9999 2 6.9999' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 16.5V7.5M12 16.5L14.5 14M12 16.5L9.5 14M12 7.5L14.5 10M12 7.5L9.5 10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
