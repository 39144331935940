import React, { SVGProps } from 'react'

export default function Fx(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 17L3 7L10 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 12H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13 17L17 12M17 12L21 7M17 12L13 7M17 12L21 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
