import React, { SVGProps } from 'react'

export default function VoiceCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 3L12 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 8L8 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 9L20 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 9L4 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 6L16 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.5 19.5L17.5 21.5L22.5 16.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
