import React, { SVGProps } from 'react'

export default function TvFix(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 20V9C2 7.89543 2.89543 7 4 7H20C21.1046 7 22 7.89543 22 9V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M13.6569 12.8284L10.8284 15.6568' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16.4853 12.8284C15.7042 13.6095 14.4379 13.6095 13.6569 12.8284C12.8758 12.0474 12.8758 10.781 13.6569 10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.8284 18.4853C11.6094 17.7043 11.6094 16.4379 10.8284 15.6569C10.0473 14.8758 8.78102 14.8758 7.99997 15.6569'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.5 2.5L12 6L15.5 2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
