import React, { SVGProps } from 'react'

export default function Bishop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 17L17 17' stroke='currentColor' strokeLinecap='round' />
      <path d='M9 12L15 12' stroke='currentColor' strokeLinecap='round' />
      <path d='M12 3V2' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M11.5544 3.58151C10.633 4.83327 8.63764 7.82527 9.05721 9.75C9.45139 11.5583 11.019 12 12 12C12.981 12 14.5486 11.5583 14.9428 9.75C15.3624 7.82527 13.367 4.83327 12.4456 3.58151C12.2207 3.27594 11.7793 3.27594 11.5544 3.58151Z'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M17.8 22L6.2 22C5.70557 22 5.41559 21.434 5.7005 21.0299C7.01607 19.1641 9.76293 15.044 10.1929 12.5964C10.2502 12.27 10.5186 12 10.85 12L13.15 12C13.4814 12 13.7498 12.27 13.8071 12.5963C14.237 15.044 16.9839 19.1641 18.2995 21.0299C18.5844 21.434 18.2944 22 17.8 22Z'
        stroke='currentColor'
        strokeLinecap='round'
      />
    </svg>
  )
}
