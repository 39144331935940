import React, { SVGProps } from 'react'

export default function FastArrowRightSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 8.5L11.5 12L8 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 8.5L17.5 12L14 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
