import React, { SVGProps } from 'react'

export default function ExpandLines(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M18 2L6 2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 22L6 22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 14V19M12 19L15 16M12 19L9 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 10V5M12 5L15 8M12 5L9 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
