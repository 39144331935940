import React from 'react'
import '../../../styles/animation_loading.css'

export const LoadingAnimation = () => {
  return (
    <div className='sound-wave'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
