import React, { SVGProps } from 'react'

export default function ShoppingCodeCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 5V7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 5V11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 5V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 10V16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 18.5V19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 18.5V19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 14V16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 13V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14 5V10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 9V13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 19L17 21L21 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
