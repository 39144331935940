import React, { SVGProps } from 'react'

export default function DatabaseBackup(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 6V12C4 12 4 15 11 15C11.5925 15 12.1349 14.9785 12.6313 14.9392'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 6V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11 3C18 3 18 6 18 6C18 6 18 9 11 9C4 9 4 6 4 6C4 6 4 3 11 3Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 21C4 21 4 18 4 18V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M22.6664 17.6667C22.0476 16.097 20.6345 15 18.9901 15C17.2318 15 15.7377 16.2545 15.1968 18'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.9951 17.6667H22.6664V17.6667C22.8507 17.6667 23.0001 17.5173 23.0001 17.333V15.4445'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.3336 20.3333C15.9524 21.903 17.3655 23 19.0099 23C20.7682 23 22.2623 21.7455 22.8032 20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0049 20.3333H15.3336V20.3333C15.1493 20.3333 14.9999 20.4827 14.9999 20.667V22.5555'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
