import React, { SVGProps } from 'react'

export default function BatteryIndicator(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M14 13H16L18 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 13H8M10 13H8M8 13V11M8 13V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6 7H2.6C2.26863 7 2 7.26863 2 7.6V18.4C2 18.7314 2.26863 19 2.6 19H21.4C21.7314 19 22 18.7314 22 18.4V7.6C22 7.26863 21.7314 7 21.4 7H18M6 7V5H8V7M6 7H8M8 7H16M16 7V5H18V7M16 7H18'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
