import React, { SVGProps } from 'react'

export default function Behance(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.19718 11.2174C13.2676 11.2174 13.2676 18 8.19718 18C5.33701 18 2 18 2 18V11.2174M8.19718 11.2174C5.33701 11.2174 2 11.2174 2 11.2174M8.19718 11.2174C13.2676 11.2174 13.2676 5.00001 8.19718 5C5.94366 5 2 5 2 5V11.2174'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 9C15.7909 9 14 11.0147 14 13.5H22C22 11.0147 20.2091 9 18 9Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 13.5C14 15.9853 15.7909 18 18 18C20.7552 18 21.5 16 21.5 16'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M20.5 6L15.5 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
