import React, { SVGProps } from 'react'

export default function Tunnel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21 20L3 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 10V11' stroke='currentColor' strokeLinecap='round' />
      <path d='M12 9V10' stroke='currentColor' strokeLinecap='round' />
      <path d='M8 8V9' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M3 21H21V12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12V21Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
