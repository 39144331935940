import { Lesson } from "../../../lessons"
import { osCategory, rechennetzCategory } from "../../category"

const vBetriebssystem = `
Das **O**perating **S**ystem verwaltet das Zusammenspiel der Hardware- und Software-Komponenten eines Computers, sodass dieser mit einer benutzerfreundlichen Oberfläche verwendet werden kann. verwaltet das Zusammenspiel der Hardware- und Software-Komponenten eines Computers, sodass dieser mit einer benutzerfreundlichen Oberfläche verwendet werden kann.

Das OS hat folgende aufgaben:

- Speicherverwaltung
  - Verwaltung der Systemressource Hauptspeicher.
  - Protokollierung der Speichernutzung.
  - Reservierung und Freigabe von Speicher.
- (Prozess)-Verwaltung
  - Überwachung der Speicherzugriffe und gegebenenfalls Beenden von Prozessen bei einer Schutzverletzung.
  - Erzeugung neuer Prozesse (entweder auf Anforderung des Betriebssystems oder auf Aufforderung anderer schon existierender Prozesse) und Reservierung des von den Prozessen benötigten Speichers.
  - Kommunikation und Synchronisation von Prozessen untereinander (Interprozesskommunikation)
- Geräte- und Dateiverwaltung
  - Effiziente Zuweisung von Ein-/Ausgabegeräten und Vermittlungseinheiten (Datenkanäle, Steuereinheiten), Vermeidung von Konflikten
  - Initiierung, Überwachung der Ausführung, Terminierung von Ein-/Ausgabevorgängen.
  - Verwaltung des Dateisystems. Erzeugung eines Namensraums mit zugehörigen Speicherobjekten und gegebenenfalls weiteren Objekten.
- Rechteverwaltung
  - Voneinander unabhängige Benutzer/Programme dürfen sich gegenseitig nicht stören.
- Abstraktion
  - Verbergen der Komplexität der Maschine vor dem Anwender
  - Abstraktion des Maschinenbegriffes (nach Coy):
    - Reale Maschine = Zentraleinheit + Geräte (Hardware)
    - Abstrakte Maschine = Reale Maschine + Betriebssystem
    - Benutzermaschine = Abstrakte Maschine + Anwendungsprogramm

Bei OS gibt es Unterschiedliche arten, wobei es jeweils ein Single und multi variante gibt.

- Tasking
- User
- Prozessor
`
export const lBetriebssystem = new Lesson('Betriebssystem', 'text')
    .addCategory(rechennetzCategory)
    .addCategory(osCategory)
    .addContent({
        id: 'betriebssystem:introduction',
        index: 1,
        content: vBetriebssystem
    })