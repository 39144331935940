import React, { SVGProps } from 'react'

export default function CalendarPlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 21H5C3.89543 21 3 20.1046 3 19V10H21V13M15 4V2M15 4V6M15 4H10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 10V6C3 4.89543 3.89543 4 5 4H7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 2V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M21 10V6C21 4.89543 20.1046 4 19 4H18.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9922 18H17.9922M21 18H17.9922M17.9922 18V15M17.9922 18V21'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
