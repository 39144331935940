import React, { SVGProps } from 'react'

export default function Fridge(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 14L9 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 6L9 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5 10V2.6C5 2.26863 5.26863 2 5.6 2H18.4C18.7314 2 19 2.26863 19 2.6V10M5 10V21.4C5 21.7314 5.26863 22 5.6 22H18.4C18.7314 22 19 21.7314 19 21.4V10M5 10H19'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
