import React from 'react'
import '../../../styles/dropdown_slide.css'
import { TDropdownItem, TDropdownSlide } from '../dropdown.types'
import { NuiIcon, NuiIconsType } from '../../icons'

export const DropdownSlide = ({ visible, header, footer, body, key, position }: TDropdownSlide): JSX.Element => (
  <div
    key={`dopdown:slide:${key}`}
    style={{
      left: position === 'left' ? '15px' : 'auto',
      right: position === 'right' ? '15px' : 'auto',
    }}
    className={
      visible
        ? `dropdown__wrapper ${position === 'top' ? '--fall-in' : '--fade-in'}`
        : `dropdown__wrapper ${position === 'top' ? '--fall-out' : '--fade-out'}`
    }
  >
    {header &&
      header.map((item, index) => {
        return (
          <>
            <DropdownItem key={`dopdown:slide:${key}:${index}`} title={item.title} icon={item.icon} />
          </>
        )
      })}
    {footer && <hr className='divider' />}

    {body.map((item, index) => {
      return <DropdownItem key={`dopdown:slide:${key}:${index}`} title={item.title} icon={item.icon} />
    })}
    {footer && <hr className='divider' />}
    {footer &&
      footer.map((item, index) => {
        return <DropdownItem key={`dopdown:slide:${key}:${index}`} title={item.title} icon={item.icon} />
      })}
  </div>
)

export const DropdownItem = ({ title, icon, key }: TDropdownItem): JSX.Element => (
  <div key={key} className='textField'>
    <NuiIcon icon={icon as NuiIconsType} size='l' />
    {title}
  </div>
)
