import React, { SVGProps } from 'react'

export default function SigmaFunction(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4 4L12 4L20 4V7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20H12H20V17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20L12 12L4 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
