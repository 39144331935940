import React, { SVGProps } from 'react'

export default function MaskSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 17.6586C10.6256 17.8797 11.2987 18 12 18C15.3137 18 18 15.3137 18 12C18 9.38756 16.3304 7.16508 14 6.34141M10 17.6586C7.66962 16.8349 6 14.6124 6 12C6 8.68629 8.68629 6 12 6C12.7013 6 13.3744 6.12031 14 6.34141M10 17.6586L14 6.34141'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
