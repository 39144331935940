import React, { SVGProps } from 'react'

export default function MagicWand(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 21L13 11M18 6L15.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.5 2L10.4453 4.55468L13 5.5L10.4453 6.44532L9.5 9L8.55468 6.44532L6 5.5L8.55468 4.55468L9.5 2Z'
        stroke='currentColor'
        strokeLinejoin='round'
      />
      <path
        d='M19 10L19.5402 11.4598L21 12L19.5402 12.5402L19 14L18.4598 12.5402L17 12L18.4598 11.4598L19 10Z'
        stroke='currentColor'
        strokeLinejoin='round'
      />
    </svg>
  )
}
