import OneZeroZeroOnetracklists from './simple-icons/1001tracklists'
import Onepassword from './simple-icons/1password'
import Twok from './simple-icons/2k'
import ThreeSixFivedatascience from './simple-icons/365datascience'
import Threem from './simple-icons/3m'
import FourTwo from './simple-icons/42'
import Fourchan from './simple-icons/4chan'
import Fourd from './simple-icons/4d'
import FiveZeroZeropx from './simple-icons/500px'
import Sevenzip from './simple-icons/7zip'
import NineNinedesigns from './simple-icons/99designs'
import Ninegag from './simple-icons/9gag'
import Abbott from './simple-icons/abbott'
import Abbrobotstudio from './simple-icons/abbrobotstudio'
import Abbvie from './simple-icons/abbvie'
import Aboutdotme from './simple-icons/aboutdotme'
import Abstract from './simple-icons/abstract'
import Abusedotch from './simple-icons/abusedotch'
import Academia from './simple-icons/academia'
import Accenture from './simple-icons/accenture'
import Accusoft from './simple-icons/accusoft'
import Acer from './simple-icons/acer'
import Acm from './simple-icons/acm'
import Actigraph from './simple-icons/actigraph'
import Activision from './simple-icons/activision'
import Activitypub from './simple-icons/activitypub'
import Actix from './simple-icons/actix'
import Acura from './simple-icons/acura'
import Adafruit from './simple-icons/adafruit'
import Adblock from './simple-icons/adblock'
import Adblockplus from './simple-icons/adblockplus'
import Adguard from './simple-icons/adguard'
import Adidas from './simple-icons/adidas'
import Adminer from './simple-icons/adminer'
import Adobe from './simple-icons/adobe'
import Adobeacrobatreader from './simple-icons/adobeacrobatreader'
import Adobeaftereffects from './simple-icons/adobeaftereffects'
import Adobeaudition from './simple-icons/adobeaudition'
import Adobecreativecloud from './simple-icons/adobecreativecloud'
import Adobedreamweaver from './simple-icons/adobedreamweaver'
import Adobefonts from './simple-icons/adobefonts'
import Adobeillustrator from './simple-icons/adobeillustrator'
import Adobeindesign from './simple-icons/adobeindesign'
import Adobelightroom from './simple-icons/adobelightroom'
import Adobelightroomclassic from './simple-icons/adobelightroomclassic'
import Adobephotoshop from './simple-icons/adobephotoshop'
import Adobepremierepro from './simple-icons/adobepremierepro'
import Adobexd from './simple-icons/adobexd'
import Adonisjs from './simple-icons/adonisjs'
import Adp from './simple-icons/adp'
import Adroll from './simple-icons/adroll'
import Adventofcode from './simple-icons/adventofcode'
import Adyen from './simple-icons/adyen'
import Aerlingus from './simple-icons/aerlingus'
import Aeroflot from './simple-icons/aeroflot'
import Aeromexico from './simple-icons/aeromexico'
import Aerospike from './simple-icons/aerospike'
import Aew from './simple-icons/aew'
import Affine from './simple-icons/affine'
import Affinity from './simple-icons/affinity'
import Affinitydesigner from './simple-icons/affinitydesigner'
import Affinityphoto from './simple-icons/affinityphoto'
import Affinitypublisher from './simple-icons/affinitypublisher'
import Aframe from './simple-icons/aframe'
import Afterpay from './simple-icons/afterpay'
import Agora from './simple-icons/agora'
import Aib from './simple-icons/aib'
import Aidungeon from './simple-icons/aidungeon'
import Aiohttp from './simple-icons/aiohttp'
import Aiqfome from './simple-icons/aiqfome'
import Airasia from './simple-icons/airasia'
import Airbnb from './simple-icons/airbnb'
import Airbrakedotio from './simple-icons/airbrakedotio'
import Airbus from './simple-icons/airbus'
import Airbyte from './simple-icons/airbyte'
import Aircall from './simple-icons/aircall'
import Aircanada from './simple-icons/aircanada'
import Airchina from './simple-icons/airchina'
import Airfrance from './simple-icons/airfrance'
import Airindia from './simple-icons/airindia'
import Airplayaudio from './simple-icons/airplayaudio'
import Airplayvideo from './simple-icons/airplayvideo'
import Airtable from './simple-icons/airtable'
import Airtel from './simple-icons/airtel'
import Airtransat from './simple-icons/airtransat'
import Ajv from './simple-icons/ajv'
import Akamai from './simple-icons/akamai'
import Akaunting from './simple-icons/akaunting'
import Akiflow from './simple-icons/akiflow'
import Alacritty from './simple-icons/alacritty'
import Alamy from './simple-icons/alamy'
import Albertheijn from './simple-icons/albertheijn'
import Alby from './simple-icons/alby'
import Alchemy from './simple-icons/alchemy'
import Aldinord from './simple-icons/aldinord'
import Aldisud from './simple-icons/aldisud'
import Alfaromeo from './simple-icons/alfaromeo'
import Alfred from './simple-icons/alfred'
import Algolia from './simple-icons/algolia'
import Algorand from './simple-icons/algorand'
import Alibabacloud from './simple-icons/alibabacloud'
import Alibabadotcom from './simple-icons/alibabadotcom'
import Alienware from './simple-icons/alienware'
import Aliexpress from './simple-icons/aliexpress'
import Alipay from './simple-icons/alipay'
import Allegro from './simple-icons/allegro'
import Alliedmodders from './simple-icons/alliedmodders'
import Allocine from './simple-icons/allocine'
import Alltrails from './simple-icons/alltrails'
import Almalinux from './simple-icons/almalinux'
import Alpinedotjs from './simple-icons/alpinedotjs'
import Alpinelinux from './simple-icons/alpinelinux'
import Alteryx from './simple-icons/alteryx'
import Altiumdesigner from './simple-icons/altiumdesigner'
import Alwaysdata from './simple-icons/alwaysdata'
import Alx from './simple-icons/alx'
import Amazon from './simple-icons/amazon'
import Amazonalexa from './simple-icons/amazonalexa'
import Amazonapigateway from './simple-icons/amazonapigateway'
import Amazonaws from './simple-icons/amazonaws'
import Amazoncloudwatch from './simple-icons/amazoncloudwatch'
import Amazondocumentdb from './simple-icons/amazondocumentdb'
import Amazondynamodb from './simple-icons/amazondynamodb'
import AmazonecTwo from './simple-icons/amazonec2'
import Amazonecs from './simple-icons/amazonecs'
import Amazoneks from './simple-icons/amazoneks'
import Amazonfiretv from './simple-icons/amazonfiretv'
import Amazongames from './simple-icons/amazongames'
import Amazoniam from './simple-icons/amazoniam'
import Amazonlumberyard from './simple-icons/amazonlumberyard'
import Amazonluna from './simple-icons/amazonluna'
import Amazonmusic from './simple-icons/amazonmusic'
import Amazonpay from './simple-icons/amazonpay'
import Amazonprime from './simple-icons/amazonprime'
import Amazonrds from './simple-icons/amazonrds'
import Amazonredshift from './simple-icons/amazonredshift'
import AmazonrouteFiveThree from './simple-icons/amazonroute53'
import AmazonsThree from './simple-icons/amazons3'
import Amazonsimpleemailservice from './simple-icons/amazonsimpleemailservice'
import Amazonsqs from './simple-icons/amazonsqs'
import Amd from './simple-icons/amd'
import Ameba from './simple-icons/ameba'
import Americanairlines from './simple-icons/americanairlines'
import Americanexpress from './simple-icons/americanexpress'
import Amg from './simple-icons/amg'
import Amp from './simple-icons/amp'
import Amul from './simple-icons/amul'
import Ana from './simple-icons/ana'
import Anaconda from './simple-icons/anaconda'
import Analogue from './simple-icons/analogue'
import Andela from './simple-icons/andela'
import Android from './simple-icons/android'
import Androidauto from './simple-icons/androidauto'
import Androidstudio from './simple-icons/androidstudio'
import Angular from './simple-icons/angular'
import Anilist from './simple-icons/anilist'
import Animalplanet from './simple-icons/animalplanet'
import Ankermake from './simple-icons/ankermake'
import Ansible from './simple-icons/ansible'
import Answer from './simple-icons/answer'
import Ansys from './simple-icons/ansys'
import Anta from './simple-icons/anta'
import Antdesign from './simple-icons/antdesign'
import AntenaThree from './simple-icons/antena3'
import Anycubic from './simple-icons/anycubic'
import Anydesk from './simple-icons/anydesk'
import Anytype from './simple-icons/anytype'
import Aol from './simple-icons/aol'
import Apache from './simple-icons/apache'
import Apacheairflow from './simple-icons/apacheairflow'
import Apacheant from './simple-icons/apacheant'
import Apachecassandra from './simple-icons/apachecassandra'
import Apachecloudstack from './simple-icons/apachecloudstack'
import Apachecordova from './simple-icons/apachecordova'
import Apachecouchdb from './simple-icons/apachecouchdb'
import Apachedruid from './simple-icons/apachedruid'
import Apacheecharts from './simple-icons/apacheecharts'
import Apacheflink from './simple-icons/apacheflink'
import Apachefreemarker from './simple-icons/apachefreemarker'
import Apachegroovy from './simple-icons/apachegroovy'
import Apacheguacamole from './simple-icons/apacheguacamole'
import Apachehadoop from './simple-icons/apachehadoop'
import Apachehive from './simple-icons/apachehive'
import Apachejmeter from './simple-icons/apachejmeter'
import Apachekafka from './simple-icons/apachekafka'
import Apachekylin from './simple-icons/apachekylin'
import Apachemaven from './simple-icons/apachemaven'
import Apachenetbeanside from './simple-icons/apachenetbeanside'
import Apacheopenoffice from './simple-icons/apacheopenoffice'
import Apacheparquet from './simple-icons/apacheparquet'
import Apachepulsar from './simple-icons/apachepulsar'
import Apacherocketmq from './simple-icons/apacherocketmq'
import Apachesolr from './simple-icons/apachesolr'
import Apachespark from './simple-icons/apachespark'
import Apachestorm from './simple-icons/apachestorm'
import Apachetomcat from './simple-icons/apachetomcat'
import Aparat from './simple-icons/aparat'
import Apifox from './simple-icons/apifox'
import Apmterminals from './simple-icons/apmterminals'
import Apollographql from './simple-icons/apollographql'
import Apostrophe from './simple-icons/apostrophe'
import Appian from './simple-icons/appian'
import Appium from './simple-icons/appium'
import Apple from './simple-icons/apple'
import Applearcade from './simple-icons/applearcade'
import Applemusic from './simple-icons/applemusic'
import Applenews from './simple-icons/applenews'
import Applepay from './simple-icons/applepay'
import Applepodcasts from './simple-icons/applepodcasts'
import Appletv from './simple-icons/appletv'
import Appsignal from './simple-icons/appsignal'
import Appsmith from './simple-icons/appsmith'
import Appstore from './simple-icons/appstore'
import Appveyor from './simple-icons/appveyor'
import Appwrite from './simple-icons/appwrite'
import Aqua from './simple-icons/aqua'
import Aral from './simple-icons/aral'
import Arangodb from './simple-icons/arangodb'
import Arc from './simple-icons/arc'
import Arcgis from './simple-icons/arcgis'
import Archicad from './simple-icons/archicad'
import Archiveofourown from './simple-icons/archiveofourown'
import Archlinux from './simple-icons/archlinux'
import Ardour from './simple-icons/ardour'
import Arduino from './simple-icons/arduino'
import Argo from './simple-icons/argo'
import Argos from './simple-icons/argos'
import Ariakit from './simple-icons/ariakit'
import Arkecosystem from './simple-icons/arkecosystem'
import Arlo from './simple-icons/arlo'
import Arm from './simple-icons/arm'
import Armkeil from './simple-icons/armkeil'
import Arstechnica from './simple-icons/arstechnica'
import Artifacthub from './simple-icons/artifacthub'
import Artixlinux from './simple-icons/artixlinux'
import Artstation from './simple-icons/artstation'
import Arxiv from './simple-icons/arxiv'
import Asahilinux from './simple-icons/asahilinux'
import Asana from './simple-icons/asana'
import Asciidoctor from './simple-icons/asciidoctor'
import Asciinema from './simple-icons/asciinema'
import Asda from './simple-icons/asda'
import Aseprite from './simple-icons/aseprite'
import Askfm from './simple-icons/askfm'
import Assemblyscript from './simple-icons/assemblyscript'
import Astonmartin from './simple-icons/astonmartin'
import Astro from './simple-icons/astro'
import Asus from './simple-icons/asus'
import Atandt from './simple-icons/atandt'
import Atari from './simple-icons/atari'
import Atlassian from './simple-icons/atlassian'
import Auchan from './simple-icons/auchan'
import Audacity from './simple-icons/audacity'
import Audi from './simple-icons/audi'
import Audible from './simple-icons/audible'
import Audioboom from './simple-icons/audioboom'
import Audiomack from './simple-icons/audiomack'
import Audiotechnica from './simple-icons/audiotechnica'
import Aurelia from './simple-icons/aurelia'
import AuthZero from './simple-icons/auth0'
import Authelia from './simple-icons/authelia'
import Authy from './simple-icons/authy'
import Autocad from './simple-icons/autocad'
import Autodesk from './simple-icons/autodesk'
import Autodeskmaya from './simple-icons/autodeskmaya'
import Autodeskrevit from './simple-icons/autodeskrevit'
import Autohotkey from './simple-icons/autohotkey'
import Autoit from './simple-icons/autoit'
import Automattic from './simple-icons/automattic'
import Autoprefixer from './simple-icons/autoprefixer'
import Autozone from './simple-icons/autozone'
import Avajs from './simple-icons/avajs'
import Avast from './simple-icons/avast'
import Avianca from './simple-icons/avianca'
import Avira from './simple-icons/avira'
import Awesomelists from './simple-icons/awesomelists'
import Awesomewm from './simple-icons/awesomewm'
import Awsamplify from './simple-icons/awsamplify'
import Awsfargate from './simple-icons/awsfargate'
import Awslambda from './simple-icons/awslambda'
import Awsorganizations from './simple-icons/awsorganizations'
import Axios from './simple-icons/axios'
import Azureartifacts from './simple-icons/azureartifacts'
import Azuredataexplorer from './simple-icons/azuredataexplorer'
import Azuredevops from './simple-icons/azuredevops'
import Azurefunctions from './simple-icons/azurefunctions'
import Azurepipelines from './simple-icons/azurepipelines'
import Babel from './simple-icons/babel'
import Babelio from './simple-icons/babelio'
import Backblaze from './simple-icons/backblaze'
import Backbonedotjs from './simple-icons/backbonedotjs'
import Backendless from './simple-icons/backendless'
import Backstage from './simple-icons/backstage'
import Badoo from './simple-icons/badoo'
import Baidu from './simple-icons/baidu'
import Bakalari from './simple-icons/bakalari'
import Bamboo from './simple-icons/bamboo'
import Bandcamp from './simple-icons/bandcamp'
import Bandlab from './simple-icons/bandlab'
import Bandrautomation from './simple-icons/bandrautomation'
import Bandsintown from './simple-icons/bandsintown'
import Bankofamerica from './simple-icons/bankofamerica'
import Barclays from './simple-icons/barclays'
import Baremetrics from './simple-icons/baremetrics'
import Barmenia from './simple-icons/barmenia'
import Basecamp from './simple-icons/basecamp'
import Basicattentiontoken from './simple-icons/basicattentiontoken'
import Bastyon from './simple-icons/bastyon'
import Bata from './simple-icons/bata'
import Battledotnet from './simple-icons/battledotnet'
import Bazel from './simple-icons/bazel'
import Beatport from './simple-icons/beatport'
import Beats from './simple-icons/beats'
import Beatsbydre from './simple-icons/beatsbydre'
import Behance from './simple-icons/behance'
import Beijingsubway from './simple-icons/beijingsubway'
import Bem from './simple-icons/bem'
import Bentley from './simple-icons/bentley'
import Bento from './simple-icons/bento'
import Bentobox from './simple-icons/bentobox'
import Bentoml from './simple-icons/bentoml'
import Bereal from './simple-icons/bereal'
import Betfair from './simple-icons/betfair'
import Betterstack from './simple-icons/betterstack'
import Bevy from './simple-icons/bevy'
import Bigbasket from './simple-icons/bigbasket'
import Bigbluebutton from './simple-icons/bigbluebutton'
import Bigcartel from './simple-icons/bigcartel'
import Bigcommerce from './simple-icons/bigcommerce'
import Bilibili from './simple-icons/bilibili'
import Billboard from './simple-icons/billboard'
import Bim from './simple-icons/bim'
import Binance from './simple-icons/binance'
import Biolink from './simple-icons/biolink'
import Biome from './simple-icons/biome'
import Bisecthosting from './simple-icons/bisecthosting'
import Bit from './simple-icons/bit'
import Bitbucket from './simple-icons/bitbucket'
import Bitcoin from './simple-icons/bitcoin'
import Bitcoincash from './simple-icons/bitcoincash'
import Bitcoinsv from './simple-icons/bitcoinsv'
import Bitcomet from './simple-icons/bitcomet'
import Bitdefender from './simple-icons/bitdefender'
import Bitly from './simple-icons/bitly'
import Bitrise from './simple-icons/bitrise'
import Bittorrent from './simple-icons/bittorrent'
import Bitwarden from './simple-icons/bitwarden'
import Bitwig from './simple-icons/bitwig'
import Blackberry from './simple-icons/blackberry'
import Blackmagicdesign from './simple-icons/blackmagicdesign'
import Blazemeter from './simple-icons/blazemeter'
import Blazor from './simple-icons/blazor'
import Blender from './simple-icons/blender'
import Blockbench from './simple-icons/blockbench'
import Blockchaindotcom from './simple-icons/blockchaindotcom'
import Blogger from './simple-icons/blogger'
import Bloglovin from './simple-icons/bloglovin'
import Blueprint from './simple-icons/blueprint'
import Bluesky from './simple-icons/bluesky'
import Bluesound from './simple-icons/bluesound'
import Bluetooth from './simple-icons/bluetooth'
import Bmcsoftware from './simple-icons/bmcsoftware'
import Bmw from './simple-icons/bmw'
import Bnbchain from './simple-icons/bnbchain'
import Boardgamegeek from './simple-icons/boardgamegeek'
import Boat from './simple-icons/boat'
import Boehringeringelheim from './simple-icons/boehringeringelheim'
import Boeing from './simple-icons/boeing'
import Bombardier from './simple-icons/bombardier'
import Bookalope from './simple-icons/bookalope'
import Bookbub from './simple-icons/bookbub'
import Bookmeter from './simple-icons/bookmeter'
import Bookmyshow from './simple-icons/bookmyshow'
import Bookstack from './simple-icons/bookstack'
import Boost from './simple-icons/boost'
import Boosty from './simple-icons/boosty'
import Boots from './simple-icons/boots'
import Bootstrap from './simple-icons/bootstrap'
import Borgbackup from './simple-icons/borgbackup'
import Bosch from './simple-icons/bosch'
import Bose from './simple-icons/bose'
import Botblecms from './simple-icons/botblecms'
import Boulanger from './simple-icons/boulanger'
import Bower from './simple-icons/bower'
import Box from './simple-icons/box'
import Boxysvg from './simple-icons/boxysvg'
import Braintree from './simple-icons/braintree'
import Brandfolder from './simple-icons/brandfolder'
import Brave from './simple-icons/brave'
import Breaker from './simple-icons/breaker'
import Brenntag from './simple-icons/brenntag'
import Brevo from './simple-icons/brevo'
import Brex from './simple-icons/brex'
import Bricks from './simple-icons/bricks'
import Britishairways from './simple-icons/britishairways'
import Broadcom from './simple-icons/broadcom'
import Bsd from './simple-icons/bsd'
import Bspwm from './simple-icons/bspwm'
import Bt from './simple-icons/bt'
import Buddy from './simple-icons/buddy'
import Budibase from './simple-icons/budibase'
import Buefy from './simple-icons/buefy'
import Buffer from './simple-icons/buffer'
import Bugatti from './simple-icons/bugatti'
import Bugcrowd from './simple-icons/bugcrowd'
import Bugsnag from './simple-icons/bugsnag'
import Buildkite from './simple-icons/buildkite'
import Bukalapak from './simple-icons/bukalapak'
import Bulma from './simple-icons/bulma'
import Bun from './simple-icons/bun'
import Bungie from './simple-icons/bungie'
import Bunq from './simple-icons/bunq'
import Burgerking from './simple-icons/burgerking'
import Burpsuite from './simple-icons/burpsuite'
import Burton from './simple-icons/burton'
import Buymeacoffee from './simple-icons/buymeacoffee'
import Buysellads from './simple-icons/buysellads'
import Buzzfeed from './simple-icons/buzzfeed'
import Bvg from './simple-icons/bvg'
import Byjus from './simple-icons/byjus'
import Bytedance from './simple-icons/bytedance'
import C from './simple-icons/c'
import Cachet from './simple-icons/cachet'
import Caddy from './simple-icons/caddy'
import Cadillac from './simple-icons/cadillac'
import Cafepress from './simple-icons/cafepress'
import Caffeine from './simple-icons/caffeine'
import Cairographics from './simple-icons/cairographics'
import Cairometro from './simple-icons/cairometro'
import Cakephp from './simple-icons/cakephp'
import Calendly from './simple-icons/calendly'
import Campaignmonitor from './simple-icons/campaignmonitor'
import Camunda from './simple-icons/camunda'
import Canonical from './simple-icons/canonical'
import Canva from './simple-icons/canva'
import Canvas from './simple-icons/canvas'
import Capacitor from './simple-icons/capacitor'
import Cardano from './simple-icons/cardano'
import Carlsberggroup from './simple-icons/carlsberggroup'
import Carrd from './simple-icons/carrd'
import Carrefour from './simple-icons/carrefour'
import Carthrottle from './simple-icons/carthrottle'
import Carto from './simple-icons/carto'
import Cashapp from './simple-icons/cashapp'
import Castbox from './simple-icons/castbox'
import Castorama from './simple-icons/castorama'
import Castro from './simple-icons/castro'
import Caterpillar from './simple-icons/caterpillar'
import Cbc from './simple-icons/cbc'
import Cbs from './simple-icons/cbs'
import Ccleaner from './simple-icons/ccleaner'
import Cdprojekt from './simple-icons/cdprojekt'
import Celery from './simple-icons/celery'
import Celestron from './simple-icons/celestron'
import Centos from './simple-icons/centos'
import Ceph from './simple-icons/ceph'
import Cesium from './simple-icons/cesium'
import Chai from './simple-icons/chai'
import Chainguard from './simple-icons/chainguard'
import Chainlink from './simple-icons/chainlink'
import Chakraui from './simple-icons/chakraui'
import ChannelFour from './simple-icons/channel4'
import Charles from './simple-icons/charles'
import Chartdotjs from './simple-icons/chartdotjs'
import Chartmogul from './simple-icons/chartmogul'
import Chase from './simple-icons/chase'
import Chatbot from './simple-icons/chatbot'
import Chatwoot from './simple-icons/chatwoot'
import Checkio from './simple-icons/checkio'
import Checkmarx from './simple-icons/checkmarx'
import Checkmk from './simple-icons/checkmk'
import Chedraui from './simple-icons/chedraui'
import Cheerio from './simple-icons/cheerio'
import Chef from './simple-icons/chef'
import Chemex from './simple-icons/chemex'
import Chessdotcom from './simple-icons/chessdotcom'
import Chevrolet from './simple-icons/chevrolet'
import Chianetwork from './simple-icons/chianetwork'
import Chinaeasternairlines from './simple-icons/chinaeasternairlines'
import Chinasouthernairlines from './simple-icons/chinasouthernairlines'
import Chocolatey from './simple-icons/chocolatey'
import Chromatic from './simple-icons/chromatic'
import Chromecast from './simple-icons/chromecast'
import Chrysler from './simple-icons/chrysler'
import Chupachups from './simple-icons/chupachups'
import Cilium from './simple-icons/cilium'
import CinemaFourd from './simple-icons/cinema4d'
import Circle from './simple-icons/circle'
import Circleci from './simple-icons/circleci'
import Circuitverse from './simple-icons/circuitverse'
import Cirrusci from './simple-icons/cirrusci'
import Cisco from './simple-icons/cisco'
import Citrix from './simple-icons/citrix'
import Citroen from './simple-icons/citroen'
import Civicrm from './simple-icons/civicrm'
import Civo from './simple-icons/civo'
import CkeditorFour from './simple-icons/ckeditor4'
import Clarifai from './simple-icons/clarifai'
import Claris from './simple-icons/claris'
import Clarivate from './simple-icons/clarivate'
import Clerk from './simple-icons/clerk'
import Clevercloud from './simple-icons/clevercloud'
import Clickhouse from './simple-icons/clickhouse'
import Clickup from './simple-icons/clickup'
import Clion from './simple-icons/clion'
import Cliqz from './simple-icons/cliqz'
import Clockify from './simple-icons/clockify'
import Clojure from './simple-icons/clojure'
import CloudSixSix from './simple-icons/cloud66'
import Cloudbees from './simple-icons/cloudbees'
import Cloudcannon from './simple-icons/cloudcannon'
import Cloudera from './simple-icons/cloudera'
import Cloudflare from './simple-icons/cloudflare'
import Cloudflarepages from './simple-icons/cloudflarepages'
import Cloudflareworkers from './simple-icons/cloudflareworkers'
import Cloudfoundry from './simple-icons/cloudfoundry'
import Cloudinary from './simple-icons/cloudinary'
import Cloudron from './simple-icons/cloudron'
import Cloudsmith from './simple-icons/cloudsmith'
import Cloudways from './simple-icons/cloudways'
import Clubforce from './simple-icons/clubforce'
import Clubhouse from './simple-icons/clubhouse'
import Clyp from './simple-icons/clyp'
import Cmake from './simple-icons/cmake'
import Cncf from './simple-icons/cncf'
import Cnet from './simple-icons/cnet'
import Cnn from './simple-icons/cnn'
import Cocacola from './simple-icons/cocacola'
import Cockpit from './simple-icons/cockpit'
import Cockroachlabs from './simple-icons/cockroachlabs'
import Cocoapods from './simple-icons/cocoapods'
import Cocos from './simple-icons/cocos'
import Coda from './simple-icons/coda'
import Codacy from './simple-icons/codacy'
import Codeberg from './simple-icons/codeberg'
import Codeblocks from './simple-icons/codeblocks'
import Codecademy from './simple-icons/codecademy'
import Codeceptjs from './simple-icons/codeceptjs'
import Codechef from './simple-icons/codechef'
import Codeclimate from './simple-icons/codeclimate'
import Codecov from './simple-icons/codecov'
import Codefactor from './simple-icons/codefactor'
import Codeforces from './simple-icons/codeforces'
import Codefresh from './simple-icons/codefresh'
import Codeigniter from './simple-icons/codeigniter'
import Codeium from './simple-icons/codeium'
import Codemagic from './simple-icons/codemagic'
import Codementor from './simple-icons/codementor'
import Codemirror from './simple-icons/codemirror'
import Codenewbie from './simple-icons/codenewbie'
import Codepen from './simple-icons/codepen'
import Codeproject from './simple-icons/codeproject'
import Codersrank from './simple-icons/codersrank'
import Coderwall from './simple-icons/coderwall'
import Codesandbox from './simple-icons/codesandbox'
import Codeship from './simple-icons/codeship'
import Codesignal from './simple-icons/codesignal'
import Codestream from './simple-icons/codestream'
import Codewars from './simple-icons/codewars'
import Codingame from './simple-icons/codingame'
import Codingninjas from './simple-icons/codingninjas'
import Codio from './simple-icons/codio'
import Coffeescript from './simple-icons/coffeescript'
import Coggle from './simple-icons/coggle'
import Cognizant from './simple-icons/cognizant'
import Coil from './simple-icons/coil'
import Coinbase from './simple-icons/coinbase'
import Coinmarketcap from './simple-icons/coinmarketcap'
import Comicfury from './simple-icons/comicfury'
import Commerzbank from './simple-icons/commerzbank'
import Commitlint from './simple-icons/commitlint'
import Commodore from './simple-icons/commodore'
import Commonworkflowlanguage from './simple-icons/commonworkflowlanguage'
import Compilerexplorer from './simple-icons/compilerexplorer'
import Composer from './simple-icons/composer'
import Comptia from './simple-icons/comptia'
import Comsol from './simple-icons/comsol'
import Conan from './simple-icons/conan'
import Concourse from './simple-icons/concourse'
import Condaforge from './simple-icons/condaforge'
import Conekta from './simple-icons/conekta'
import Confluence from './simple-icons/confluence'
import ConstructThree from './simple-icons/construct3'
import Consul from './simple-icons/consul'
import Contactlesspayment from './simple-icons/contactlesspayment'
import Containerd from './simple-icons/containerd'
import Contao from './simple-icons/contao'
import Contentful from './simple-icons/contentful'
import Contentstack from './simple-icons/contentstack'
import Contributorcovenant from './simple-icons/contributorcovenant'
import Conventionalcommits from './simple-icons/conventionalcommits'
import Convertio from './simple-icons/convertio'
import Cookiecutter from './simple-icons/cookiecutter'
import Coolermaster from './simple-icons/coolermaster'
import Coop from './simple-icons/coop'
import Coppel from './simple-icons/coppel'
import Cora from './simple-icons/cora'
import Coreldraw from './simple-icons/coreldraw'
import Coronaengine from './simple-icons/coronaengine'
import Coronarenderer from './simple-icons/coronarenderer'
import Corsair from './simple-icons/corsair'
import Couchbase from './simple-icons/couchbase'
import Counterstrike from './simple-icons/counterstrike'
import Countingworkspro from './simple-icons/countingworkspro'
import Coursera from './simple-icons/coursera'
import Coveralls from './simple-icons/coveralls'
import Cpanel from './simple-icons/cpanel'
import Cplusplus from './simple-icons/cplusplus'
import Cplusplusbuilder from './simple-icons/cplusplusbuilder'
import Craftcms from './simple-icons/craftcms'
import Craftsman from './simple-icons/craftsman'
import Cratedb from './simple-icons/cratedb'
import Crayon from './simple-icons/crayon'
import Creality from './simple-icons/creality'
import Createreactapp from './simple-icons/createreactapp'
import Creativecommons from './simple-icons/creativecommons'
import Creativetechnology from './simple-icons/creativetechnology'
import Credly from './simple-icons/credly'
import Crehana from './simple-icons/crehana'
import Crewunited from './simple-icons/crewunited'
import Criticalrole from './simple-icons/criticalrole'
import Crowdin from './simple-icons/crowdin'
import Crowdsource from './simple-icons/crowdsource'
import Crunchbase from './simple-icons/crunchbase'
import Crunchyroll from './simple-icons/crunchyroll'
import Cryengine from './simple-icons/cryengine'
import Cryptpad from './simple-icons/cryptpad'
import Crystal from './simple-icons/crystal'
import Csharp from './simple-icons/csharp'
import CssThree from './simple-icons/css3'
import Cssmodules from './simple-icons/cssmodules'
import Csswizardry from './simple-icons/csswizardry'
import Cts from './simple-icons/cts'
import Cucumber from './simple-icons/cucumber'
import Cultura from './simple-icons/cultura'
import Curl from './simple-icons/curl'
import Curseforge from './simple-icons/curseforge'
import Customink from './simple-icons/customink'
import Cyberdefenders from './simple-icons/cyberdefenders'
import CyclingSevenFour from './simple-icons/cycling74'
import Cypress from './simple-icons/cypress'
import Cytoscapedotjs from './simple-icons/cytoscapedotjs'
import D from './simple-icons/d'
import DThreedotjs from './simple-icons/d3dotjs'
import Dacia from './simple-icons/dacia'
import Daf from './simple-icons/daf'
import Dailydotdev from './simple-icons/dailydotdev'
import Dailymotion from './simple-icons/dailymotion'
import Daimler from './simple-icons/daimler'
import Daisyui from './simple-icons/daisyui'
import Dapr from './simple-icons/dapr'
import Darkreader from './simple-icons/darkreader'
import Dart from './simple-icons/dart'
import Darty from './simple-icons/darty'
import Daserste from './simple-icons/daserste'
import Dash from './simple-icons/dash'
import Dashlane from './simple-icons/dashlane'
import Dask from './simple-icons/dask'
import Dassaultsystemes from './simple-icons/dassaultsystemes'
import Databricks from './simple-icons/databricks'
import Datacamp from './simple-icons/datacamp'
import Datadog from './simple-icons/datadog'
import Datadotai from './simple-icons/datadotai'
import Datagrip from './simple-icons/datagrip'
import Dataiku from './simple-icons/dataiku'
import Datastax from './simple-icons/datastax'
import Dataverse from './simple-icons/dataverse'
import Datefns from './simple-icons/datefns'
import Datocms from './simple-icons/datocms'
import Datto from './simple-icons/datto'
import Davinciresolve from './simple-icons/davinciresolve'
import Dazhongdianping from './simple-icons/dazhongdianping'
import Dazn from './simple-icons/dazn'
import Dbeaver from './simple-icons/dbeaver'
import Dblp from './simple-icons/dblp'
import Dbt from './simple-icons/dbt'
import Dcentertainment from './simple-icons/dcentertainment'
import Debian from './simple-icons/debian'
import Decapcms from './simple-icons/decapcms'
import Decentraland from './simple-icons/decentraland'
import Dedge from './simple-icons/dedge'
import Deepcool from './simple-icons/deepcool'
import Deepgram from './simple-icons/deepgram'
import Deepin from './simple-icons/deepin'
import Deepl from './simple-icons/deepl'
import Deepnote from './simple-icons/deepnote'
import Delicious from './simple-icons/delicious'
import Deliveroo from './simple-icons/deliveroo'
import Dell from './simple-icons/dell'
import Delonghi from './simple-icons/delonghi'
import Delphi from './simple-icons/delphi'
import Delta from './simple-icons/delta'
import Deluge from './simple-icons/deluge'
import Deno from './simple-icons/deno'
import Denon from './simple-icons/denon'
import Dependabot from './simple-icons/dependabot'
import Dependencycheck from './simple-icons/dependencycheck'
import Depositphotos from './simple-icons/depositphotos'
import Derspiegel from './simple-icons/derspiegel'
import Designernews from './simple-icons/designernews'
import Deutschebahn from './simple-icons/deutschebahn'
import Deutschebank from './simple-icons/deutschebank'
import Deutschepost from './simple-icons/deutschepost'
import Devdotto from './simple-icons/devdotto'
import Devexpress from './simple-icons/devexpress'
import Deviantart from './simple-icons/deviantart'
import Devpost from './simple-icons/devpost'
import Devrant from './simple-icons/devrant'
import Dgraph from './simple-icons/dgraph'
import Dhl from './simple-icons/dhl'
import Diagramsdotnet from './simple-icons/diagramsdotnet'
import Dialogflow from './simple-icons/dialogflow'
import Diaspora from './simple-icons/diaspora'
import Digg from './simple-icons/digg'
import Digikeyelectronics from './simple-icons/digikeyelectronics'
import Digitalocean from './simple-icons/digitalocean'
import Dinersclub from './simple-icons/dinersclub'
import Dior from './simple-icons/dior'
import Directus from './simple-icons/directus'
import Discogs from './simple-icons/discogs'
import Discord from './simple-icons/discord'
import Discourse from './simple-icons/discourse'
import Discover from './simple-icons/discover'
import Disqus from './simple-icons/disqus'
import Disroot from './simple-icons/disroot'
import Distrokid from './simple-icons/distrokid'
import Django from './simple-icons/django'
import Dji from './simple-icons/dji'
import Dlib from './simple-icons/dlib'
import Dlna from './simple-icons/dlna'
import Dm from './simple-icons/dm'
import Docker from './simple-icons/docker'
import Docsdotrs from './simple-icons/docsdotrs'
import Docsify from './simple-icons/docsify'
import Docusaurus from './simple-icons/docusaurus'
import Docusign from './simple-icons/docusign'
import Dogecoin from './simple-icons/dogecoin'
import Doi from './simple-icons/doi'
import Dolby from './simple-icons/dolby'
import Doordash from './simple-icons/doordash'
import Dotenv from './simple-icons/dotenv'
import Dotnet from './simple-icons/dotnet'
import Douban from './simple-icons/douban'
import Doubanread from './simple-icons/doubanread'
import Dovecot from './simple-icons/dovecot'
import Doxygen from './simple-icons/doxygen'
import Dpd from './simple-icons/dpd'
import Dragonframe from './simple-icons/dragonframe'
import Draugiemdotlv from './simple-icons/draugiemdotlv'
import Dreamstime from './simple-icons/dreamstime'
import Dribbble from './simple-icons/dribbble'
import Drizzle from './simple-icons/drizzle'
import Drone from './simple-icons/drone'
import Drooble from './simple-icons/drooble'
import Dropbox from './simple-icons/dropbox'
import Drupal from './simple-icons/drupal'
import Dsautomobiles from './simple-icons/dsautomobiles'
import Dts from './simple-icons/dts'
import Dtube from './simple-icons/dtube'
import Ducati from './simple-icons/ducati'
import Duckdb from './simple-icons/duckdb'
import Duckduckgo from './simple-icons/duckduckgo'
import Dungeonsanddragons from './simple-icons/dungeonsanddragons'
import Dunked from './simple-icons/dunked'
import Dunzo from './simple-icons/dunzo'
import Duolingo from './simple-icons/duolingo'
import Dvc from './simple-icons/dvc'
import Dwavesystems from './simple-icons/dwavesystems'
import Dwm from './simple-icons/dwm'
import DynamicsThreeSixFive from './simple-icons/dynamics365'
import Dynatrace from './simple-icons/dynatrace'
import E from './simple-icons/e'
import EThree from './simple-icons/e3'
import Ea from './simple-icons/ea'
import Eagle from './simple-icons/eagle'
import Easyeda from './simple-icons/easyeda'
import Easyjet from './simple-icons/easyjet'
import Ebay from './simple-icons/ebay'
import Ebox from './simple-icons/ebox'
import Eclipseadoptium from './simple-icons/eclipseadoptium'
import Eclipseche from './simple-icons/eclipseche'
import Eclipseide from './simple-icons/eclipseide'
import Eclipsejetty from './simple-icons/eclipsejetty'
import Eclipsemosquitto from './simple-icons/eclipsemosquitto'
import Eclipsevertdotx from './simple-icons/eclipsevertdotx'
import Ecovacs from './simple-icons/ecovacs'
import Edeka from './simple-icons/edeka'
import Edgeimpulse from './simple-icons/edgeimpulse'
import Editorconfig from './simple-icons/editorconfig'
import Edotleclerc from './simple-icons/edotleclerc'
import Educative from './simple-icons/educative'
import Edx from './simple-icons/edx'
import Egghead from './simple-icons/egghead'
import Egnyte from './simple-icons/egnyte'
import Eight from './simple-icons/eight'
import Eightsleep from './simple-icons/eightsleep'
import Ejs from './simple-icons/ejs'
import Elastic from './simple-icons/elastic'
import Elasticcloud from './simple-icons/elasticcloud'
import Elasticsearch from './simple-icons/elasticsearch'
import Elasticstack from './simple-icons/elasticstack'
import Elavon from './simple-icons/elavon'
import Electron from './simple-icons/electron'
import Electronbuilder from './simple-icons/electronbuilder'
import Electronfiddle from './simple-icons/electronfiddle'
import Elegoo from './simple-icons/elegoo'
import Element from './simple-icons/element'
import Elementary from './simple-icons/elementary'
import Elementor from './simple-icons/elementor'
import Eleventy from './simple-icons/eleventy'
import Elgato from './simple-icons/elgato'
import Elixir from './simple-icons/elixir'
import Eljueves from './simple-icons/eljueves'
import Ello from './simple-icons/ello'
import Elm from './simple-icons/elm'
import Elsevier from './simple-icons/elsevier'
import Embarcadero from './simple-icons/embarcadero'
import Embark from './simple-icons/embark'
import Emberdotjs from './simple-icons/emberdotjs'
import Emby from './simple-icons/emby'
import Emirates from './simple-icons/emirates'
import Emlakjet from './simple-icons/emlakjet'
import Empirekred from './simple-icons/empirekred'
import Endeavouros from './simple-icons/endeavouros'
import Engadget from './simple-icons/engadget'
import Enpass from './simple-icons/enpass'
import Enterprisedb from './simple-icons/enterprisedb'
import Envato from './simple-icons/envato'
import Envoyproxy from './simple-icons/envoyproxy'
import Epel from './simple-icons/epel'
import Epicgames from './simple-icons/epicgames'
import Epson from './simple-icons/epson'
import Equinixmetal from './simple-icons/equinixmetal'
import Ericsson from './simple-icons/ericsson'
import Erlang from './simple-icons/erlang'
import Erpnext from './simple-icons/erpnext'
import Esbuild from './simple-icons/esbuild'
import Esea from './simple-icons/esea'
import Eslgaming from './simple-icons/eslgaming'
import Eslint from './simple-icons/eslint'
import Esotericsoftware from './simple-icons/esotericsoftware'
import Esphome from './simple-icons/esphome'
import Espressif from './simple-icons/espressif'
import Esri from './simple-icons/esri'
import Etcd from './simple-icons/etcd'
import Ethereum from './simple-icons/ethereum'
import Ethiopianairlines from './simple-icons/ethiopianairlines'
import Etihadairways from './simple-icons/etihadairways'
import Etsy from './simple-icons/etsy'
import Eventbrite from './simple-icons/eventbrite'
import Eventstore from './simple-icons/eventstore'
import Evernote from './simple-icons/evernote'
import Excalidraw from './simple-icons/excalidraw'
import Exercism from './simple-icons/exercism'
import Exordo from './simple-icons/exordo'
import Exoscale from './simple-icons/exoscale'
import Expedia from './simple-icons/expedia'
import Expensify from './simple-icons/expensify'
import Expertsexchange from './simple-icons/expertsexchange'
import Expo from './simple-icons/expo'
import Express from './simple-icons/express'
import Expressvpn from './simple-icons/expressvpn'
import Eyeem from './simple-icons/eyeem'
import FOne from './simple-icons/f1'
import FFive from './simple-icons/f5'
import Facebook from './simple-icons/facebook'
import Facebookgaming from './simple-icons/facebookgaming'
import Facebooklive from './simple-icons/facebooklive'
import Faceit from './simple-icons/faceit'
import Facepunch from './simple-icons/facepunch'
import Fairphone from './simple-icons/fairphone'
import Falco from './simple-icons/falco'
import Falcon from './simple-icons/falcon'
import Fampay from './simple-icons/fampay'
import Fandango from './simple-icons/fandango'
import Fandom from './simple-icons/fandom'
import Fanfou from './simple-icons/fanfou'
import Fantom from './simple-icons/fantom'
import Farcaster from './simple-icons/farcaster'
import Fareharbor from './simple-icons/fareharbor'
import Farfetch from './simple-icons/farfetch'
import Fastapi from './simple-icons/fastapi'
import Fastify from './simple-icons/fastify'
import Fastlane from './simple-icons/fastlane'
import Fastly from './simple-icons/fastly'
import Fathom from './simple-icons/fathom'
import Fauna from './simple-icons/fauna'
import Favro from './simple-icons/favro'
import Fdroid from './simple-icons/fdroid'
import Feathub from './simple-icons/feathub'
import Fedex from './simple-icons/fedex'
import Fedora from './simple-icons/fedora'
import Feedly from './simple-icons/feedly'
import Ferrari from './simple-icons/ferrari'
import Ferrarinv from './simple-icons/ferrarinv'
import Ferretdb from './simple-icons/ferretdb'
import Ffmpeg from './simple-icons/ffmpeg'
import Fi from './simple-icons/fi'
import Fiat from './simple-icons/fiat'
import Fidoalliance from './simple-icons/fidoalliance'
import Fifa from './simple-icons/fifa'
import Fig from './simple-icons/fig'
import Figma from './simple-icons/figma'
import Figshare from './simple-icons/figshare'
import Fila from './simple-icons/fila'
import Filedotio from './simple-icons/filedotio'
import Files from './simple-icons/files'
import Filezilla from './simple-icons/filezilla'
import Fineco from './simple-icons/fineco'
import Fing from './simple-icons/fing'
import Firebase from './simple-icons/firebase'
import Firefish from './simple-icons/firefish'
import Fireflyiii from './simple-icons/fireflyiii'
import Firefox from './simple-icons/firefox'
import Firefoxbrowser from './simple-icons/firefoxbrowser'
import Fireship from './simple-icons/fireship'
import Firewalla from './simple-icons/firewalla'
import First from './simple-icons/first'
import Fishshell from './simple-icons/fishshell'
import Fitbit from './simple-icons/fitbit'
import Fite from './simple-icons/fite'
import Fivem from './simple-icons/fivem'
import Fiverr from './simple-icons/fiverr'
import Flashforge from './simple-icons/flashforge'
import Flask from './simple-icons/flask'
import Flat from './simple-icons/flat'
import Flathub from './simple-icons/flathub'
import Flatpak from './simple-icons/flatpak'
import Flattr from './simple-icons/flattr'
import Flickr from './simple-icons/flickr'
import Flightaware from './simple-icons/flightaware'
import Flipboard from './simple-icons/flipboard'
import Flipkart from './simple-icons/flipkart'
import Floatplane from './simple-icons/floatplane'
import Flood from './simple-icons/flood'
import Fluentbit from './simple-icons/fluentbit'
import Fluentd from './simple-icons/fluentd'
import Fluke from './simple-icons/fluke'
import Flutter from './simple-icons/flutter'
import Flux from './simple-icons/flux'
import Fluxus from './simple-icons/fluxus'
import Flyway from './simple-icons/flyway'
import Fmod from './simple-icons/fmod'
import Fnac from './simple-icons/fnac'
import Folium from './simple-icons/folium'
import Fonoma from './simple-icons/fonoma'
import Fontawesome from './simple-icons/fontawesome'
import Fontbase from './simple-icons/fontbase'
import Fontforge from './simple-icons/fontforge'
import FoobarTwoZeroZeroZero from './simple-icons/foobar2000'
import Foodpanda from './simple-icons/foodpanda'
import Ford from './simple-icons/ford'
import Forgejo from './simple-icons/forgejo'
import Formspree from './simple-icons/formspree'
import Formstack from './simple-icons/formstack'
import Fortinet from './simple-icons/fortinet'
import Fortran from './simple-icons/fortran'
import Fossa from './simple-icons/fossa'
import Fossilscm from './simple-icons/fossilscm'
import Foursquare from './simple-icons/foursquare'
import Foursquarecityguide from './simple-icons/foursquarecityguide'
import Fox from './simple-icons/fox'
import Foxtel from './simple-icons/foxtel'
import Fozzy from './simple-icons/fozzy'
import Framer from './simple-icons/framer'
import Framework from './simple-icons/framework'
import FrameworkSeven from './simple-icons/framework7'
import Franprix from './simple-icons/franprix'
import Frappe from './simple-icons/frappe'
import Fraunhofergesellschaft from './simple-icons/fraunhofergesellschaft'
import Freebsd from './simple-icons/freebsd'
import Freecodecamp from './simple-icons/freecodecamp'
import Freedesktopdotorg from './simple-icons/freedesktopdotorg'
import Freelancer from './simple-icons/freelancer'
import Freenas from './simple-icons/freenas'
import Freepik from './simple-icons/freepik'
import Frontendmentor from './simple-icons/frontendmentor'
import Frontify from './simple-icons/frontify'
import Fsecure from './simple-icons/fsecure'
import Fsharp from './simple-icons/fsharp'
import Fubo from './simple-icons/fubo'
import Fueler from './simple-icons/fueler'
import Fugacloud from './simple-icons/fugacloud'
import Fujifilm from './simple-icons/fujifilm'
import Fujitsu from './simple-icons/fujitsu'
import Funimation from './simple-icons/funimation'
import Furaffinity from './simple-icons/furaffinity'
import Furrynetwork from './simple-icons/furrynetwork'
import Futurelearn from './simple-icons/futurelearn'
import GTwo from './simple-icons/g2'
import GTwoa from './simple-icons/g2a'
import GTwog from './simple-icons/g2g'
import Galaxus from './simple-icons/galaxus'
import Gameandwatch from './simple-icons/gameandwatch'
import Gamebanana from './simple-icons/gamebanana'
import Gamedeveloper from './simple-icons/gamedeveloper'
import Gamejolt from './simple-icons/gamejolt'
import Gameloft from './simple-icons/gameloft'
import Gamemaker from './simple-icons/gamemaker'
import Garmin from './simple-icons/garmin'
import Gatling from './simple-icons/gatling'
import Gatsby from './simple-icons/gatsby'
import Gdal from './simple-icons/gdal'
import Geant from './simple-icons/geant'
import Geeksforgeeks from './simple-icons/geeksforgeeks'
import Generalelectric from './simple-icons/generalelectric'
import Generalmotors from './simple-icons/generalmotors'
import Genius from './simple-icons/genius'
import Gentoo from './simple-icons/gentoo'
import Geocaching from './simple-icons/geocaching'
import Geopandas from './simple-icons/geopandas'
import Gerrit from './simple-icons/gerrit'
import Getx from './simple-icons/getx'
import Ghost from './simple-icons/ghost'
import Ghostery from './simple-icons/ghostery'
import Gimp from './simple-icons/gimp'
import Gin from './simple-icons/gin'
import Giphy from './simple-icons/giphy'
import Git from './simple-icons/git'
import Gitbook from './simple-icons/gitbook'
import Gitconnected from './simple-icons/gitconnected'
import Gitea from './simple-icons/gitea'
import Gitee from './simple-icons/gitee'
import Gitextensions from './simple-icons/gitextensions'
import Github from './simple-icons/github'
import Githubactions from './simple-icons/githubactions'
import Githubcopilot from './simple-icons/githubcopilot'
import Githubpages from './simple-icons/githubpages'
import Githubsponsors from './simple-icons/githubsponsors'
import Gitignoredotio from './simple-icons/gitignoredotio'
import Gitkraken from './simple-icons/gitkraken'
import Gitlab from './simple-icons/gitlab'
import Gitlfs from './simple-icons/gitlfs'
import Gitpod from './simple-icons/gitpod'
import Gitter from './simple-icons/gitter'
import Glassdoor from './simple-icons/glassdoor'
import Glide from './simple-icons/glide'
import Glitch from './simple-icons/glitch'
import Globus from './simple-icons/globus'
import Glovo from './simple-icons/glovo'
import Gmail from './simple-icons/gmail'
import Gnome from './simple-icons/gnome'
import Gnometerminal from './simple-icons/gnometerminal'
import Gnu from './simple-icons/gnu'
import Gnubash from './simple-icons/gnubash'
import Gnuemacs from './simple-icons/gnuemacs'
import Gnuicecat from './simple-icons/gnuicecat'
import Gnuprivacyguard from './simple-icons/gnuprivacyguard'
import Gnusocial from './simple-icons/gnusocial'
import Go from './simple-icons/go'
import Gocd from './simple-icons/gocd'
import Godaddy from './simple-icons/godaddy'
import Godotengine from './simple-icons/godotengine'
import Gofundme from './simple-icons/gofundme'
import Gogdotcom from './simple-icons/gogdotcom'
import Gojek from './simple-icons/gojek'
import Goland from './simple-icons/goland'
import Goldenline from './simple-icons/goldenline'
import Goldmansachs from './simple-icons/goldmansachs'
import Goodreads from './simple-icons/goodreads'
import Google from './simple-icons/google'
import Googleadmob from './simple-icons/googleadmob'
import Googleads from './simple-icons/googleads'
import Googleadsense from './simple-icons/googleadsense'
import Googleanalytics from './simple-icons/googleanalytics'
import Googleappsscript from './simple-icons/googleappsscript'
import Googleassistant from './simple-icons/googleassistant'
import Googleauthenticator from './simple-icons/googleauthenticator'
import Googlebard from './simple-icons/googlebard'
import Googlebigquery from './simple-icons/googlebigquery'
import Googlebigtable from './simple-icons/googlebigtable'
import Googlecalendar from './simple-icons/googlecalendar'
import GooglecampaignmanagerThreeSixZero from './simple-icons/googlecampaignmanager360'
import Googlecardboard from './simple-icons/googlecardboard'
import Googlechat from './simple-icons/googlechat'
import Googlechrome from './simple-icons/googlechrome'
import Googlechronicle from './simple-icons/googlechronicle'
import Googleclassroom from './simple-icons/googleclassroom'
import Googlecloud from './simple-icons/googlecloud'
import Googlecloudcomposer from './simple-icons/googlecloudcomposer'
import Googlecloudspanner from './simple-icons/googlecloudspanner'
import Googlecloudstorage from './simple-icons/googlecloudstorage'
import Googlecolab from './simple-icons/googlecolab'
import Googlecontaineroptimizedos from './simple-icons/googlecontaineroptimizedos'
import Googledataflow from './simple-icons/googledataflow'
import Googledataproc from './simple-icons/googledataproc'
import Googledatastudio from './simple-icons/googledatastudio'
import GoogledisplayandvideoThreeSixZero from './simple-icons/googledisplayandvideo360'
import Googledocs from './simple-icons/googledocs'
import Googledomains from './simple-icons/googledomains'
import Googledrive from './simple-icons/googledrive'
import Googleearth from './simple-icons/googleearth'
import Googleearthengine from './simple-icons/googleearthengine'
import Googlefit from './simple-icons/googlefit'
import Googlefonts from './simple-icons/googlefonts'
import Googleforms from './simple-icons/googleforms'
import Googlegemini from './simple-icons/googlegemini'
import Googlehangouts from './simple-icons/googlehangouts'
import Googlehome from './simple-icons/googlehome'
import Googlekeep from './simple-icons/googlekeep'
import Googlelens from './simple-icons/googlelens'
import Googlemaps from './simple-icons/googlemaps'
import Googlemarketingplatform from './simple-icons/googlemarketingplatform'
import Googlemeet from './simple-icons/googlemeet'
import Googlemessages from './simple-icons/googlemessages'
import Googlemybusiness from './simple-icons/googlemybusiness'
import Googlenearby from './simple-icons/googlenearby'
import Googlenews from './simple-icons/googlenews'
import Googleoptimize from './simple-icons/googleoptimize'
import Googlepay from './simple-icons/googlepay'
import Googlephotos from './simple-icons/googlephotos'
import Googleplay from './simple-icons/googleplay'
import Googlepodcasts from './simple-icons/googlepodcasts'
import Googlepubsub from './simple-icons/googlepubsub'
import Googlescholar from './simple-icons/googlescholar'
import Googlesearchconsole from './simple-icons/googlesearchconsole'
import Googlesheets from './simple-icons/googlesheets'
import Googleslides from './simple-icons/googleslides'
import Googlestreetview from './simple-icons/googlestreetview'
import Googletagmanager from './simple-icons/googletagmanager'
import Googletasks from './simple-icons/googletasks'
import Googletranslate from './simple-icons/googletranslate'
import Gotomeeting from './simple-icons/gotomeeting'
import Grab from './simple-icons/grab'
import Gradle from './simple-icons/gradle'
import Gradleplaypublisher from './simple-icons/gradleplaypublisher'
import Grafana from './simple-icons/grafana'
import Grammarly from './simple-icons/grammarly'
import Grandfrais from './simple-icons/grandfrais'
import Grapheneos from './simple-icons/grapheneos'
import Graphite from './simple-icons/graphite'
import Graphql from './simple-icons/graphql'
import Grav from './simple-icons/grav'
import Gravatar from './simple-icons/gravatar'
import Graylog from './simple-icons/graylog'
import Greasyfork from './simple-icons/greasyfork'
import Greatlearning from './simple-icons/greatlearning'
import Greenhouse from './simple-icons/greenhouse'
import Greensock from './simple-icons/greensock'
import Griddotai from './simple-icons/griddotai'
import Gridsome from './simple-icons/gridsome'
import Groupme from './simple-icons/groupme'
import Groupon from './simple-icons/groupon'
import Grubhub from './simple-icons/grubhub'
import Grunt from './simple-icons/grunt'
import Gsk from './simple-icons/gsk'
import Gsmarenadotcom from './simple-icons/gsmarenadotcom'
import Gstreamer from './simple-icons/gstreamer'
import Gtk from './simple-icons/gtk'
import Guangzhoumetro from './simple-icons/guangzhoumetro'
import Guilded from './simple-icons/guilded'
import Gulp from './simple-icons/gulp'
import Gumroad from './simple-icons/gumroad'
import Gumtree from './simple-icons/gumtree'
import Gunicorn from './simple-icons/gunicorn'
import Gurobi from './simple-icons/gurobi'
import Gutenberg from './simple-icons/gutenberg'
import HThree from './simple-icons/h3'
import Habr from './simple-icons/habr'
import Hackaday from './simple-icons/hackaday'
import Hackclub from './simple-icons/hackclub'
import Hackerearth from './simple-icons/hackerearth'
import Hackernoon from './simple-icons/hackernoon'
import Hackerone from './simple-icons/hackerone'
import Hackerrank from './simple-icons/hackerrank'
import Hackster from './simple-icons/hackster'
import Hackthebox from './simple-icons/hackthebox'
import Hal from './simple-icons/hal'
import Handlebarsdotjs from './simple-icons/handlebarsdotjs'
import Handm from './simple-icons/handm'
import Handshake from './simple-icons/handshake'
import HandshakeProtocol from './simple-icons/handshake_protocol'
import Happycow from './simple-icons/happycow'
import Harbor from './simple-icons/harbor'
import Harmonyos from './simple-icons/harmonyos'
import Hashicorp from './simple-icons/hashicorp'
import Hashnode from './simple-icons/hashnode'
import Haskell from './simple-icons/haskell'
import Hasura from './simple-icons/hasura'
import Hatenabookmark from './simple-icons/hatenabookmark'
import Haveibeenpwned from './simple-icons/haveibeenpwned'
import Haxe from './simple-icons/haxe'
import Hbo from './simple-icons/hbo'
import Hcl from './simple-icons/hcl'
import Hdfcbank from './simple-icons/hdfcbank'
import Headlessui from './simple-icons/headlessui'
import Headphonezone from './simple-icons/headphonezone'
import Headspace from './simple-icons/headspace'
import Hearth from './simple-icons/hearth'
import Hearthisdotat from './simple-icons/hearthisdotat'
import Hedera from './simple-icons/hedera'
import Helium from './simple-icons/helium'
import Hellofresh from './simple-icons/hellofresh'
import Hellyhansen from './simple-icons/hellyhansen'
import Helm from './simple-icons/helm'
import Helpdesk from './simple-icons/helpdesk'
import Helpscout from './simple-icons/helpscout'
import Hepsiemlak from './simple-icons/hepsiemlak'
import Here from './simple-icons/here'
import Heroku from './simple-icons/heroku'
import Hetzner from './simple-icons/hetzner'
import Hevy from './simple-icons/hevy'
import Hexlet from './simple-icons/hexlet'
import Hexo from './simple-icons/hexo'
import Hey from './simple-icons/hey'
import Hibernate from './simple-icons/hibernate'
import Hibob from './simple-icons/hibob'
import Hilton from './simple-icons/hilton'
import Hiltonhotelsandresorts from './simple-icons/hiltonhotelsandresorts'
import Hitachi from './simple-icons/hitachi'
import Hive from './simple-icons/hive'
import HiveBlockchain from './simple-icons/hive_blockchain'
import Hivemq from './simple-icons/hivemq'
import Homeadvisor from './simple-icons/homeadvisor'
import Homeassistant from './simple-icons/homeassistant'
import Homeassistantcommunitystore from './simple-icons/homeassistantcommunitystore'
import Homebrew from './simple-icons/homebrew'
import Homebridge from './simple-icons/homebridge'
import Homify from './simple-icons/homify'
import Honda from './simple-icons/honda'
import Honey from './simple-icons/honey'
import Hono from './simple-icons/hono'
import Honor from './simple-icons/honor'
import Hootsuite from './simple-icons/hootsuite'
import Hoppscotch from './simple-icons/hoppscotch'
import Hostinger from './simple-icons/hostinger'
import Hotelsdotcom from './simple-icons/hotelsdotcom'
import Hotjar from './simple-icons/hotjar'
import Hotwire from './simple-icons/hotwire'
import Houdini from './simple-icons/houdini'
import Houzz from './simple-icons/houzz'
import Hp from './simple-icons/hp'
import Hsbc from './simple-icons/hsbc'
import HtmlFive from './simple-icons/html5'
import Htmlacademy from './simple-icons/htmlacademy'
import Htmx from './simple-icons/htmx'
import Htop from './simple-icons/htop'
import Httpie from './simple-icons/httpie'
import Huawei from './simple-icons/huawei'
import Hubspot from './simple-icons/hubspot'
import Hugo from './simple-icons/hugo'
import Humblebundle from './simple-icons/humblebundle'
import Hungryjacks from './simple-icons/hungryjacks'
import Husqvarna from './simple-icons/husqvarna'
import Hyper from './simple-icons/hyper'
import Hyperskill from './simple-icons/hyperskill'
import Hypothesis from './simple-icons/hypothesis'
import Hyundai from './simple-icons/hyundai'
import IOneEightnext from './simple-icons/i18next'
import IThree from './simple-icons/i3'
import Iata from './simple-icons/iata'
import Ibeacon from './simple-icons/ibeacon'
import Iberia from './simple-icons/iberia'
import Ibm from './simple-icons/ibm'
import Ibmcloud from './simple-icons/ibmcloud'
import Ibmwatson from './simple-icons/ibmwatson'
import Iced from './simple-icons/iced'
import Iceland from './simple-icons/iceland'
import Icicibank from './simple-icons/icicibank'
import Icinga from './simple-icons/icinga'
import Icloud from './simple-icons/icloud'
import Icomoon from './simple-icons/icomoon'
import Icon from './simple-icons/icon'
import Iconfinder from './simple-icons/iconfinder'
import Iconify from './simple-icons/iconify'
import Iconjar from './simple-icons/iconjar'
import IconsEight from './simple-icons/icons8'
import Icq from './simple-icons/icq'
import Ieee from './simple-icons/ieee'
import Ifixit from './simple-icons/ifixit'
import Ifood from './simple-icons/ifood'
import Ifttt from './simple-icons/ifttt'
import Igdb from './simple-icons/igdb'
import Ign from './simple-icons/ign'
import Iheartradio from './simple-icons/iheartradio'
import Ikea from './simple-icons/ikea'
import Iledefrancemobilites from './simple-icons/iledefrancemobilites'
import Imagedotsc from './simple-icons/imagedotsc'
import Imagej from './simple-icons/imagej'
import Imdb from './simple-icons/imdb'
import Imessage from './simple-icons/imessage'
import Imgur from './simple-icons/imgur'
import Immer from './simple-icons/immer'
import Immich from './simple-icons/immich'
import Imou from './simple-icons/imou'
import Improvmx from './simple-icons/improvmx'
import Indeed from './simple-icons/indeed'
import Indiehackers from './simple-icons/indiehackers'
import Indigo from './simple-icons/indigo'
import Inertia from './simple-icons/inertia'
import Infiniti from './simple-icons/infiniti'
import Influxdb from './simple-icons/influxdb'
import Infoq from './simple-icons/infoq'
import Informatica from './simple-icons/informatica'
import Infosys from './simple-icons/infosys'
import Infracost from './simple-icons/infracost'
import Ingress from './simple-icons/ingress'
import Inkdrop from './simple-icons/inkdrop'
import Inkscape from './simple-icons/inkscape'
import Inoreader from './simple-icons/inoreader'
import Insomnia from './simple-icons/insomnia'
import Inspire from './simple-icons/inspire'
import InstaThreeSixZero from './simple-icons/insta360'
import Instacart from './simple-icons/instacart'
import Instagram from './simple-icons/instagram'
import Instapaper from './simple-icons/instapaper'
import Instatus from './simple-icons/instatus'
import Instructables from './simple-icons/instructables'
import Instructure from './simple-icons/instructure'
import Integromat from './simple-icons/integromat'
import Intel from './simple-icons/intel'
import Intellijidea from './simple-icons/intellijidea'
import Interactiondesignfoundation from './simple-icons/interactiondesignfoundation'
import Interactjs from './simple-icons/interactjs'
import Interbase from './simple-icons/interbase'
import Intercom from './simple-icons/intercom'
import Intermarche from './simple-icons/intermarche'
import Internetarchive from './simple-icons/internetarchive'
import Internetcomputer from './simple-icons/internetcomputer'
import Internetexplorer from './simple-icons/internetexplorer'
import Intigriti from './simple-icons/intigriti'
import Intuit from './simple-icons/intuit'
import Invision from './simple-icons/invision'
import Invoiceninja from './simple-icons/invoiceninja'
import Iobroker from './simple-icons/iobroker'
import Ionic from './simple-icons/ionic'
import Ionos from './simple-icons/ionos'
import Ios from './simple-icons/ios'
import Iota from './simple-icons/iota'
import Ipfs from './simple-icons/ipfs'
import Iris from './simple-icons/iris'
import Irobot from './simple-icons/irobot'
import IscTwo from './simple-icons/isc2'
import Issuu from './simple-icons/issuu'
import Istio from './simple-icons/istio'
import Itchdotio from './simple-icons/itchdotio'
import ItermTwo from './simple-icons/iterm2'
import Itunes from './simple-icons/itunes'
import Itvx from './simple-icons/itvx'
import Iveco from './simple-icons/iveco'
import Jabber from './simple-icons/jabber'
import Jaeger from './simple-icons/jaeger'
import Jaguar from './simple-icons/jaguar'
import Jamboard from './simple-icons/jamboard'
import Jameson from './simple-icons/jameson'
import Jamstack from './simple-icons/jamstack'
import Jasmine from './simple-icons/jasmine'
import Javascript from './simple-icons/javascript'
import Jbl from './simple-icons/jbl'
import Jcb from './simple-icons/jcb'
import Jeep from './simple-icons/jeep'
import Jekyll from './simple-icons/jekyll'
import Jellyfin from './simple-icons/jellyfin'
import Jenkins from './simple-icons/jenkins'
import Jest from './simple-icons/jest'
import Jet from './simple-icons/jet'
import Jetblue from './simple-icons/jetblue'
import Jetbrains from './simple-icons/jetbrains'
import Jetpackcompose from './simple-icons/jetpackcompose'
import Jfrog from './simple-icons/jfrog'
import Jfrogpipelines from './simple-icons/jfrogpipelines'
import Jinja from './simple-icons/jinja'
import Jira from './simple-icons/jira'
import Jirasoftware from './simple-icons/jirasoftware'
import Jitpack from './simple-icons/jitpack'
import Jitsi from './simple-icons/jitsi'
import Johndeere from './simple-icons/johndeere'
import Joomla from './simple-icons/joomla'
import Joplin from './simple-icons/joplin'
import Jordan from './simple-icons/jordan'
import Jouav from './simple-icons/jouav'
import Jovian from './simple-icons/jovian'
import Jpeg from './simple-icons/jpeg'
import Jquery from './simple-icons/jquery'
import Jrgroup from './simple-icons/jrgroup'
import Jsdelivr from './simple-icons/jsdelivr'
import Jsfiddle from './simple-icons/jsfiddle'
import Json from './simple-icons/json'
import Jsonwebtokens from './simple-icons/jsonwebtokens'
import Jss from './simple-icons/jss'
import Juce from './simple-icons/juce'
import Juejin from './simple-icons/juejin'
import Juke from './simple-icons/juke'
import Julia from './simple-icons/julia'
import Junipernetworks from './simple-icons/junipernetworks'
import JunitFive from './simple-icons/junit5'
import Jupyter from './simple-icons/jupyter'
import Justeat from './simple-icons/justeat'
import Justgiving from './simple-icons/justgiving'
import KThrees from './simple-icons/k3s'
import KSix from './simple-icons/k6'
import Kaggle from './simple-icons/kaggle'
import Kahoot from './simple-icons/kahoot'
import Kaios from './simple-icons/kaios'
import Kakao from './simple-icons/kakao'
import Kakaotalk from './simple-icons/kakaotalk'
import Kalilinux from './simple-icons/kalilinux'
import Kamailio from './simple-icons/kamailio'
import Kaniko from './simple-icons/kaniko'
import Karlsruherverkehrsverbund from './simple-icons/karlsruherverkehrsverbund'
import Kasasmart from './simple-icons/kasasmart'
import Kashflow from './simple-icons/kashflow'
import Kaspersky from './simple-icons/kaspersky'
import Katacoda from './simple-icons/katacoda'
import Katana from './simple-icons/katana'
import Kaufland from './simple-icons/kaufland'
import Kde from './simple-icons/kde'
import Kdenlive from './simple-icons/kdenlive'
import Kedro from './simple-icons/kedro'
import Keenetic from './simple-icons/keenetic'
import Keepachangelog from './simple-icons/keepachangelog'
import Keepassxc from './simple-icons/keepassxc'
import Kentico from './simple-icons/kentico'
import Keras from './simple-icons/keras'
import Keybase from './simple-icons/keybase'
import Keycdn from './simple-icons/keycdn'
import Keystone from './simple-icons/keystone'
import Kfc from './simple-icons/kfc'
import Khanacademy from './simple-icons/khanacademy'
import Khronosgroup from './simple-icons/khronosgroup'
import Kia from './simple-icons/kia'
import Kibana from './simple-icons/kibana'
import Kicad from './simple-icons/kicad'
import Kick from './simple-icons/kick'
import Kickstarter from './simple-icons/kickstarter'
import Kik from './simple-icons/kik'
import Kingstontechnology from './simple-icons/kingstontechnology'
import Kinopoisk from './simple-icons/kinopoisk'
import Kinsta from './simple-icons/kinsta'
import Kirby from './simple-icons/kirby'
import Kit from './simple-icons/kit'
import Kitsu from './simple-icons/kitsu'
import Klarna from './simple-icons/klarna'
import Klm from './simple-icons/klm'
import Klook from './simple-icons/klook'
import Knative from './simple-icons/knative'
import Knexdotjs from './simple-icons/knexdotjs'
import Knime from './simple-icons/knime'
import Knowledgebase from './simple-icons/knowledgebase'
import Known from './simple-icons/known'
import Koa from './simple-icons/koa'
import Koc from './simple-icons/koc'
import Kodak from './simple-icons/kodak'
import Kodi from './simple-icons/kodi'
import Kofax from './simple-icons/kofax'
import Kofi from './simple-icons/kofi'
import Komoot from './simple-icons/komoot'
import Konami from './simple-icons/konami'
import Kong from './simple-icons/kong'
import Kongregate from './simple-icons/kongregate'
import Konva from './simple-icons/konva'
import Kotlin from './simple-icons/kotlin'
import Koyeb from './simple-icons/koyeb'
import Krita from './simple-icons/krita'
import Ktm from './simple-icons/ktm'
import Kuaishou from './simple-icons/kuaishou'
import Kubernetes from './simple-icons/kubernetes'
import Kubuntu from './simple-icons/kubuntu'
import Kucoin from './simple-icons/kucoin'
import Kuma from './simple-icons/kuma'
import Kununu from './simple-icons/kununu'
import Kuula from './simple-icons/kuula'
import Kx from './simple-icons/kx'
import Kyocera from './simple-icons/kyocera'
import Labview from './simple-icons/labview'
import Lada from './simple-icons/lada'
import Lamborghini from './simple-icons/lamborghini'
import Landrover from './simple-icons/landrover'
import Lapce from './simple-icons/lapce'
import Laragon from './simple-icons/laragon'
import Laravel from './simple-icons/laravel'
import Laravelhorizon from './simple-icons/laravelhorizon'
import Laravelnova from './simple-icons/laravelnova'
import Lastdotfm from './simple-icons/lastdotfm'
import Lastpass from './simple-icons/lastpass'
import Latex from './simple-icons/latex'
import Launchpad from './simple-icons/launchpad'
import Lazarus from './simple-icons/lazarus'
import Lbry from './simple-icons/lbry'
import Leaderprice from './simple-icons/leaderprice'
import Leaflet from './simple-icons/leaflet'
import Leagueoflegends from './simple-icons/leagueoflegends'
import Leanpub from './simple-icons/leanpub'
import Leetcode from './simple-icons/leetcode'
import Legacygames from './simple-icons/legacygames'
import Leica from './simple-icons/leica'
import Lemmy from './simple-icons/lemmy'
import Lenovo from './simple-icons/lenovo'
import Lens from './simple-icons/lens'
import Leptos from './simple-icons/leptos'
import Lequipe from './simple-icons/lequipe'
import Lerna from './simple-icons/lerna'
import Leroymerlin from './simple-icons/leroymerlin'
import Leslibraires from './simple-icons/leslibraires'
import Less from './simple-icons/less'
import Letsencrypt from './simple-icons/letsencrypt'
import Letterboxd from './simple-icons/letterboxd'
import Levelsdotfyi from './simple-icons/levelsdotfyi'
import Lg from './simple-icons/lg'
import Liberadotchat from './simple-icons/liberadotchat'
import Liberapay from './simple-icons/liberapay'
import Librariesdotio from './simple-icons/librariesdotio'
import Librarything from './simple-icons/librarything'
import Libreoffice from './simple-icons/libreoffice'
import Libreofficebase from './simple-icons/libreofficebase'
import Libreofficecalc from './simple-icons/libreofficecalc'
import Libreofficedraw from './simple-icons/libreofficedraw'
import Libreofficeimpress from './simple-icons/libreofficeimpress'
import Libreofficemath from './simple-icons/libreofficemath'
import Libreofficewriter from './simple-icons/libreofficewriter'
import Libuv from './simple-icons/libuv'
import Lichess from './simple-icons/lichess'
import Lidl from './simple-icons/lidl'
import Lifx from './simple-icons/lifx'
import Lightburn from './simple-icons/lightburn'
import Lighthouse from './simple-icons/lighthouse'
import Lightning from './simple-icons/lightning'
import Line from './simple-icons/line'
import Lineageos from './simple-icons/lineageos'
import Linear from './simple-icons/linear'
import Lining from './simple-icons/lining'
import Linkedin from './simple-icons/linkedin'
import Linkerd from './simple-icons/linkerd'
import Linkfire from './simple-icons/linkfire'
import Linksys from './simple-icons/linksys'
import Linktree from './simple-icons/linktree'
import Lintcode from './simple-icons/lintcode'
import Linux from './simple-icons/linux'
import Linuxcontainers from './simple-icons/linuxcontainers'
import Linuxfoundation from './simple-icons/linuxfoundation'
import Linuxmint from './simple-icons/linuxmint'
import Linuxprofessionalinstitute from './simple-icons/linuxprofessionalinstitute'
import Linuxserver from './simple-icons/linuxserver'
import Lionair from './simple-icons/lionair'
import Liquibase from './simple-icons/liquibase'
import Lit from './simple-icons/lit'
import Litecoin from './simple-icons/litecoin'
import Litiengine from './simple-icons/litiengine'
import Livechat from './simple-icons/livechat'
import Livejournal from './simple-icons/livejournal'
import Livewire from './simple-icons/livewire'
import Llvm from './simple-icons/llvm'
import Lmms from './simple-icons/lmms'
import Local from './simple-icons/local'
import Lodash from './simple-icons/lodash'
import Logitech from './simple-icons/logitech'
import Logitechg from './simple-icons/logitechg'
import Logmein from './simple-icons/logmein'
import Logseq from './simple-icons/logseq'
import Logstash from './simple-icons/logstash'
import Looker from './simple-icons/looker'
import Loom from './simple-icons/loom'
import Loop from './simple-icons/loop'
import Loopback from './simple-icons/loopback'
import Lootcrate from './simple-icons/lootcrate'
import Lospec from './simple-icons/lospec'
import Lotpolishairlines from './simple-icons/lotpolishairlines'
import Ltspice from './simple-icons/ltspice'
import Lua from './simple-icons/lua'
import Lubuntu from './simple-icons/lubuntu'
import Lucia from './simple-icons/lucia'
import Lucid from './simple-icons/lucid'
import Ludwig from './simple-icons/ludwig'
import Lufthansa from './simple-icons/lufthansa'
import Lumen from './simple-icons/lumen'
import Lunacy from './simple-icons/lunacy'
import Lutris from './simple-icons/lutris'
import Lydia from './simple-icons/lydia'
import Lyft from './simple-icons/lyft'
import Maas from './simple-icons/maas'
import Macos from './simple-icons/macos'
import Macpaw from './simple-icons/macpaw'
import Macys from './simple-icons/macys'
import Magasinsu from './simple-icons/magasinsu'
import Magento from './simple-icons/magento'
import Magic from './simple-icons/magic'
import Magisk from './simple-icons/magisk'
import Mahindra from './simple-icons/mahindra'
import Mailchimp from './simple-icons/mailchimp'
import Maildotcom from './simple-icons/maildotcom'
import Maildotru from './simple-icons/maildotru'
import Mailgun from './simple-icons/mailgun'
import Mailtrap from './simple-icons/mailtrap'
import Majorleaguehacking from './simple-icons/majorleaguehacking'
import Make from './simple-icons/make'
import Makerbot from './simple-icons/makerbot'
import Malt from './simple-icons/malt'
import Mambaui from './simple-icons/mambaui'
import Mamp from './simple-icons/mamp'
import Man from './simple-icons/man'
import Manageiq from './simple-icons/manageiq'
import Manjaro from './simple-icons/manjaro'
import Mantine from './simple-icons/mantine'
import Mapbox from './simple-icons/mapbox'
import Maplibre from './simple-icons/maplibre'
import Mariadb from './simple-icons/mariadb'
import Mariadbfoundation from './simple-icons/mariadbfoundation'
import Markdown from './simple-icons/markdown'
import Marketo from './simple-icons/marketo'
import Marko from './simple-icons/marko'
import Marriott from './simple-icons/marriott'
import Marvelapp from './simple-icons/marvelapp'
import Maserati from './simple-icons/maserati'
import Mastercard from './simple-icons/mastercard'
import Mastercomfig from './simple-icons/mastercomfig'
import Mastodon from './simple-icons/mastodon'
import Materialdesign from './simple-icons/materialdesign'
import Materialdesignicons from './simple-icons/materialdesignicons'
import Materialformkdocs from './simple-icons/materialformkdocs'
import Matillion from './simple-icons/matillion'
import Matomo from './simple-icons/matomo'
import Matrix from './simple-icons/matrix'
import Matterdotjs from './simple-icons/matterdotjs'
import Mattermost from './simple-icons/mattermost'
import Matternet from './simple-icons/matternet'
import Mautic from './simple-icons/mautic'
import Max from './simple-icons/max'
import Maxplanckgesellschaft from './simple-icons/maxplanckgesellschaft'
import Maytag from './simple-icons/maytag'
import Mazda from './simple-icons/mazda'
import Mcafee from './simple-icons/mcafee'
import Mcdonalds from './simple-icons/mcdonalds'
import Mclaren from './simple-icons/mclaren'
import Mdbook from './simple-icons/mdbook'
import Mdnwebdocs from './simple-icons/mdnwebdocs'
import Mdx from './simple-icons/mdx'
import Mediafire from './simple-icons/mediafire'
import Mediamarkt from './simple-icons/mediamarkt'
import Mediatek from './simple-icons/mediatek'
import Medibangpaint from './simple-icons/medibangpaint'
import Medium from './simple-icons/medium'
import Medusa from './simple-icons/medusa'
import Meetup from './simple-icons/meetup'
import Mega from './simple-icons/mega'
import Meilisearch from './simple-icons/meilisearch'
import Meituan from './simple-icons/meituan'
import Mendeley from './simple-icons/mendeley'
import Mentorcruise from './simple-icons/mentorcruise'
import Mercadopago from './simple-icons/mercadopago'
import Mercedes from './simple-icons/mercedes'
import Merck from './simple-icons/merck'
import Mercurial from './simple-icons/mercurial'
import Mermaid from './simple-icons/mermaid'
import Messenger from './simple-icons/messenger'
import Meta from './simple-icons/meta'
import Metabase from './simple-icons/metabase'
import Metacritic from './simple-icons/metacritic'
import Metafilter from './simple-icons/metafilter'
import Metasploit from './simple-icons/metasploit'
import Meteor from './simple-icons/meteor'
import Metro from './simple-icons/metro'
import Metrodelaciudaddemexico from './simple-icons/metrodelaciudaddemexico'
import Metrodemadrid from './simple-icons/metrodemadrid'
import Metrodeparis from './simple-icons/metrodeparis'
import Mewe from './simple-icons/mewe'
import Mg from './simple-icons/mg'
import Microbit from './simple-icons/microbit'
import Microdotblog from './simple-icons/microdotblog'
import Microeditor from './simple-icons/microeditor'
import Microgenetics from './simple-icons/microgenetics'
import Micropython from './simple-icons/micropython'
import Microsoft from './simple-icons/microsoft'
import Microsoftacademic from './simple-icons/microsoftacademic'
import Microsoftaccess from './simple-icons/microsoftaccess'
import Microsoftazure from './simple-icons/microsoftazure'
import Microsoftbing from './simple-icons/microsoftbing'
import Microsoftedge from './simple-icons/microsoftedge'
import Microsoftexcel from './simple-icons/microsoftexcel'
import Microsoftexchange from './simple-icons/microsoftexchange'
import Microsoftonedrive from './simple-icons/microsoftonedrive'
import Microsoftonenote from './simple-icons/microsoftonenote'
import Microsoftoutlook from './simple-icons/microsoftoutlook'
import Microsoftpowerpoint from './simple-icons/microsoftpowerpoint'
import Microsoftsharepoint from './simple-icons/microsoftsharepoint'
import Microsoftsqlserver from './simple-icons/microsoftsqlserver'
import Microsoftstore from './simple-icons/microsoftstore'
import Microsoftteams from './simple-icons/microsoftteams'
import Microsofttranslator from './simple-icons/microsofttranslator'
import Microsoftvisio from './simple-icons/microsoftvisio'
import Microsoftword from './simple-icons/microsoftword'
import Microstation from './simple-icons/microstation'
import Microstrategy from './simple-icons/microstrategy'
import Midi from './simple-icons/midi'
import Migadu from './simple-icons/migadu'
import Mikrotik from './simple-icons/mikrotik'
import Milanote from './simple-icons/milanote'
import Milvus from './simple-icons/milvus'
import Minds from './simple-icons/minds'
import Minecraft from './simple-icons/minecraft'
import Minetest from './simple-icons/minetest'
import Mini from './simple-icons/mini'
import Minio from './simple-icons/minio'
import Minutemailer from './simple-icons/minutemailer'
import Miraheze from './simple-icons/miraheze'
import Miro from './simple-icons/miro'
import Misskey from './simple-icons/misskey'
import Mitsubishi from './simple-icons/mitsubishi'
import Mix from './simple-icons/mix'
import Mixcloud from './simple-icons/mixcloud'
import Mixpanel from './simple-icons/mixpanel'
import Mlb from './simple-icons/mlb'
import Mlflow from './simple-icons/mlflow'
import Mobx from './simple-icons/mobx'
import Mobxstatetree from './simple-icons/mobxstatetree'
import Mocha from './simple-icons/mocha'
import Mockserviceworker from './simple-icons/mockserviceworker'
import Modin from './simple-icons/modin'
import Modrinth from './simple-icons/modrinth'
import Modx from './simple-icons/modx'
import Mojangstudios from './simple-icons/mojangstudios'
import Moleculer from './simple-icons/moleculer'
import Momenteo from './simple-icons/momenteo'
import Monero from './simple-icons/monero'
import Moneygram from './simple-icons/moneygram'
import Mongodb from './simple-icons/mongodb'
import Mongoose from './simple-icons/mongoose'
import Mongoosedotws from './simple-icons/mongoosedotws'
import Monica from './simple-icons/monica'
import Monkeytie from './simple-icons/monkeytie'
import Monkeytype from './simple-icons/monkeytype'
import Monogame from './simple-icons/monogame'
import Monoprix from './simple-icons/monoprix'
import Monster from './simple-icons/monster'
import Monzo from './simple-icons/monzo'
import Moo from './simple-icons/moo'
import Moodle from './simple-icons/moodle'
import Moonrepo from './simple-icons/moonrepo'
import Moqups from './simple-icons/moqups'
import Morrisons from './simple-icons/morrisons'
import Moscowmetro from './simple-icons/moscowmetro'
import Motorola from './simple-icons/motorola'
import Mozilla from './simple-icons/mozilla'
import Mpv from './simple-icons/mpv'
import Mqtt from './simple-icons/mqtt'
import Msi from './simple-icons/msi'
import Msibusiness from './simple-icons/msibusiness'
import Mta from './simple-icons/mta'
import Mtr from './simple-icons/mtr'
import Mubi from './simple-icons/mubi'
import Mui from './simple-icons/mui'
import Mulesoft from './simple-icons/mulesoft'
import Muller from './simple-icons/muller'
import Multisim from './simple-icons/multisim'
import Mumble from './simple-icons/mumble'
import Muo from './simple-icons/muo'
import Mural from './simple-icons/mural'
import Musescore from './simple-icons/musescore'
import Musicbrainz from './simple-icons/musicbrainz'
import Mxlinux from './simple-icons/mxlinux'
import Myanimelist from './simple-icons/myanimelist'
import Myget from './simple-icons/myget'
import Myob from './simple-icons/myob'
import Myspace from './simple-icons/myspace'
import Mysql from './simple-icons/mysql'
import NTwoSix from './simple-icons/n26'
import Namebase from './simple-icons/namebase'
import Namecheap from './simple-icons/namecheap'
import Namemc from './simple-icons/namemc'
import Namesilo from './simple-icons/namesilo'
import Namuwiki from './simple-icons/namuwiki'
import Nano from './simple-icons/nano'
import Nasa from './simple-icons/nasa'
import Nationalgrid from './simple-icons/nationalgrid'
import Nationalrail from './simple-icons/nationalrail'
import Nativescript from './simple-icons/nativescript'
import Natsdotio from './simple-icons/natsdotio'
import Naver from './simple-icons/naver'
import Nba from './simple-icons/nba'
import Nbb from './simple-icons/nbb'
import Nbc from './simple-icons/nbc'
import Ndr from './simple-icons/ndr'
import Near from './simple-icons/near'
import Nec from './simple-icons/nec'
import NeoFourj from './simple-icons/neo4j'
import Neovim from './simple-icons/neovim'
import Neptune from './simple-icons/neptune'
import Nestjs from './simple-icons/nestjs'
import Netapp from './simple-icons/netapp'
import Netbsd from './simple-icons/netbsd'
import Netcup from './simple-icons/netcup'
import Netdata from './simple-icons/netdata'
import Neteasecloudmusic from './simple-icons/neteasecloudmusic'
import Netflix from './simple-icons/netflix'
import Netgear from './simple-icons/netgear'
import Netlify from './simple-icons/netlify'
import Nette from './simple-icons/nette'
import Netto from './simple-icons/netto'
import Neutralinojs from './simple-icons/neutralinojs'
import Newbalance from './simple-icons/newbalance'
import Newegg from './simple-icons/newegg'
import Newjapanprowrestling from './simple-icons/newjapanprowrestling'
import Newrelic from './simple-icons/newrelic'
import Newyorktimes from './simple-icons/newyorktimes'
import Nextbilliondotai from './simple-icons/nextbilliondotai'
import Nextcloud from './simple-icons/nextcloud'
import Nextdns from './simple-icons/nextdns'
import Nextdoor from './simple-icons/nextdoor'
import Nextdotjs from './simple-icons/nextdotjs'
import Nextra from './simple-icons/nextra'
import Nextui from './simple-icons/nextui'
import Nexusmods from './simple-icons/nexusmods'
import Nfc from './simple-icons/nfc'
import Nginx from './simple-icons/nginx'
import Nginxproxymanager from './simple-icons/nginxproxymanager'
import Ngrok from './simple-icons/ngrok'
import Ngrx from './simple-icons/ngrx'
import Nhl from './simple-icons/nhl'
import Niantic from './simple-icons/niantic'
import Niconico from './simple-icons/niconico'
import Nike from './simple-icons/nike'
import Nikon from './simple-icons/nikon'
import Nim from './simple-icons/nim'
import Nintendo from './simple-icons/nintendo'
import NintendoThreeds from './simple-icons/nintendo3ds'
import Nintendogamecube from './simple-icons/nintendogamecube'
import Nintendonetwork from './simple-icons/nintendonetwork'
import Nintendoswitch from './simple-icons/nintendoswitch'
import Nissan from './simple-icons/nissan'
import Nixos from './simple-icons/nixos'
import Nodedotjs from './simple-icons/nodedotjs'
import Nodemon from './simple-icons/nodemon'
import Nodered from './simple-icons/nodered'
import Nokia from './simple-icons/nokia'
import Nomad from './simple-icons/nomad'
import Norco from './simple-icons/norco'
import Nordicsemiconductor from './simple-icons/nordicsemiconductor'
import Nordvpn from './simple-icons/nordvpn'
import Normalizedotcss from './simple-icons/normalizedotcss'
import Norton from './simple-icons/norton'
import Norwegian from './simple-icons/norwegian'
import Notepadplusplus from './simple-icons/notepadplusplus'
import Notion from './simple-icons/notion'
import Notist from './simple-icons/notist'
import Nounproject from './simple-icons/nounproject'
import Novu from './simple-icons/novu'
import Now from './simple-icons/now'
import Npm from './simple-icons/npm'
import Nrwl from './simple-icons/nrwl'
import Nsis from './simple-icons/nsis'
import Nubank from './simple-icons/nubank'
import Nucleo from './simple-icons/nucleo'
import Nuget from './simple-icons/nuget'
import Nuke from './simple-icons/nuke'
import Numba from './simple-icons/numba'
import Numpy from './simple-icons/numpy'
import Nunjucks from './simple-icons/nunjucks'
import Nushell from './simple-icons/nushell'
import Nutanix from './simple-icons/nutanix'
import Nuxtdotjs from './simple-icons/nuxtdotjs'
import Nvidia from './simple-icons/nvidia'
import Nx from './simple-icons/nx'
import Nxp from './simple-icons/nxp'
import Nzxt from './simple-icons/nzxt'
import Observable from './simple-icons/observable'
import Obsidian from './simple-icons/obsidian'
import Obsstudio from './simple-icons/obsstudio'
import Ocaml from './simple-icons/ocaml'
import Oclif from './simple-icons/oclif'
import Octanerender from './simple-icons/octanerender'
import Octave from './simple-icons/octave'
import Octobercms from './simple-icons/octobercms'
import Octoprint from './simple-icons/octoprint'
import Octopusdeploy from './simple-icons/octopusdeploy'
import Oculus from './simple-icons/oculus'
import Odnoklassniki from './simple-icons/odnoklassniki'
import Odoo from './simple-icons/odoo'
import Odysee from './simple-icons/odysee'
import Ohdear from './simple-icons/ohdear'
import Okcupid from './simple-icons/okcupid'
import Okta from './simple-icons/okta'
import Oneplus from './simple-icons/oneplus'
import Onlyfans from './simple-icons/onlyfans'
import Onlyoffice from './simple-icons/onlyoffice'
import Onnx from './simple-icons/onnx'
import Onstar from './simple-icons/onstar'
import Opel from './simple-icons/opel'
import Openaccess from './simple-icons/openaccess'
import Openai from './simple-icons/openai'
import Openaigym from './simple-icons/openaigym'
import Openapiinitiative from './simple-icons/openapiinitiative'
import Openbadges from './simple-icons/openbadges'
import Openbsd from './simple-icons/openbsd'
import Openbugbounty from './simple-icons/openbugbounty'
import Opencollective from './simple-icons/opencollective'
import Opencontainersinitiative from './simple-icons/opencontainersinitiative'
import Opencv from './simple-icons/opencv'
import Openfaas from './simple-icons/openfaas'
import Opengl from './simple-icons/opengl'
import Openhab from './simple-icons/openhab'
import Openid from './simple-icons/openid'
import Openjdk from './simple-icons/openjdk'
import Openlayers from './simple-icons/openlayers'
import Openmined from './simple-icons/openmined'
import Opennebula from './simple-icons/opennebula'
import Openproject from './simple-icons/openproject'
import Openscad from './simple-icons/openscad'
import Opensea from './simple-icons/opensea'
import Opensearch from './simple-icons/opensearch'
import Opensourcehardware from './simple-icons/opensourcehardware'
import Opensourceinitiative from './simple-icons/opensourceinitiative'
import Openssl from './simple-icons/openssl'
import Openstack from './simple-icons/openstack'
import Openstreetmap from './simple-icons/openstreetmap'
import Opensuse from './simple-icons/opensuse'
import Opentelemetry from './simple-icons/opentelemetry'
import Opentext from './simple-icons/opentext'
import Opentofu from './simple-icons/opentofu'
import Openverse from './simple-icons/openverse'
import Openvpn from './simple-icons/openvpn'
import Openwrt from './simple-icons/openwrt'
import Openzeppelin from './simple-icons/openzeppelin'
import Openzfs from './simple-icons/openzfs'
import Opera from './simple-icons/opera'
import Operagx from './simple-icons/operagx'
import Opnsense from './simple-icons/opnsense'
import Oppo from './simple-icons/oppo'
import Opsgenie from './simple-icons/opsgenie'
import Opslevel from './simple-icons/opslevel'
import Oracle from './simple-icons/oracle'
import Orange from './simple-icons/orange'
import Orcid from './simple-icons/orcid'
import Oreilly from './simple-icons/oreilly'
import Org from './simple-icons/org'
import Origin from './simple-icons/origin'
import Osano from './simple-icons/osano'
import Osf from './simple-icons/osf'
import Osgeo from './simple-icons/osgeo'
import Oshkosh from './simple-icons/oshkosh'
import Osmc from './simple-icons/osmc'
import Osu from './simple-icons/osu'
import Otto from './simple-icons/otto'
import Overcast from './simple-icons/overcast'
import Overleaf from './simple-icons/overleaf'
import Ovh from './simple-icons/ovh'
import Owasp from './simple-icons/owasp'
import Owncloud from './simple-icons/owncloud'
import Oxygen from './simple-icons/oxygen'
import Oyo from './simple-icons/oyo'
import PFivedotjs from './simple-icons/p5dotjs'
import Packagist from './simple-icons/packagist'
import Packer from './simple-icons/packer'
import Packt from './simple-icons/packt'
import Paddle from './simple-icons/paddle'
import Paddlepaddle from './simple-icons/paddlepaddle'
import Paddypower from './simple-icons/paddypower'
import Pagekit from './simple-icons/pagekit'
import Pagerduty from './simple-icons/pagerduty'
import Pagespeedinsights from './simple-icons/pagespeedinsights'
import Pagseguro from './simple-icons/pagseguro'
import Palantir from './simple-icons/palantir'
import Paloaltonetworks from './simple-icons/paloaltonetworks'
import Paloaltosoftware from './simple-icons/paloaltosoftware'
import Panasonic from './simple-icons/panasonic'
import Pandas from './simple-icons/pandas'
import Pandora from './simple-icons/pandora'
import Pantheon from './simple-icons/pantheon'
import Paperlessngx from './simple-icons/paperlessngx'
import Paperspace from './simple-icons/paperspace'
import Paperswithcode from './simple-icons/paperswithcode'
import Paramountplus from './simple-icons/paramountplus'
import Paritysubstrate from './simple-icons/paritysubstrate'
import Parsedotly from './simple-icons/parsedotly'
import Passport from './simple-icons/passport'
import Pastebin from './simple-icons/pastebin'
import Patreon from './simple-icons/patreon'
import Paychex from './simple-icons/paychex'
import Payhip from './simple-icons/payhip'
import Payloadcms from './simple-icons/payloadcms'
import Payoneer from './simple-icons/payoneer'
import Paypal from './simple-icons/paypal'
import Paytm from './simple-icons/paytm'
import Pcgamingwiki from './simple-icons/pcgamingwiki'
import Pdm from './simple-icons/pdm'
import Pdq from './simple-icons/pdq'
import Peakdesign from './simple-icons/peakdesign'
import Pearson from './simple-icons/pearson'
import Peerlist from './simple-icons/peerlist'
import Peertube from './simple-icons/peertube'
import Pegasusairlines from './simple-icons/pegasusairlines'
import Pelican from './simple-icons/pelican'
import Peloton from './simple-icons/peloton'
import Penny from './simple-icons/penny'
import Penpot from './simple-icons/penpot'
import Percy from './simple-icons/percy'
import Perforce from './simple-icons/perforce'
import Perl from './simple-icons/perl'
import Perplexity from './simple-icons/perplexity'
import Persistent from './simple-icons/persistent'
import Personio from './simple-icons/personio'
import Petsathome from './simple-icons/petsathome'
import Peugeot from './simple-icons/peugeot'
import Pexels from './simple-icons/pexels'
import Pfsense from './simple-icons/pfsense'
import Phabricator from './simple-icons/phabricator'
import Philipshue from './simple-icons/philipshue'
import Phoenixframework from './simple-icons/phoenixframework'
import Phonepe from './simple-icons/phonepe'
import Phosphoricons from './simple-icons/phosphoricons'
import Photobucket from './simple-icons/photobucket'
import Photocrowd from './simple-icons/photocrowd'
import Photon from './simple-icons/photon'
import Photopea from './simple-icons/photopea'
import Php from './simple-icons/php'
import Phpmyadmin from './simple-icons/phpmyadmin'
import Phpstorm from './simple-icons/phpstorm'
import Piaggiogroup from './simple-icons/piaggiogroup'
import Piapro from './simple-icons/piapro'
import Picardsurgeles from './simple-icons/picardsurgeles'
import Picartodottv from './simple-icons/picartodottv'
import Picnic from './simple-icons/picnic'
import Picpay from './simple-icons/picpay'
import Picxy from './simple-icons/picxy'
import Pihole from './simple-icons/pihole'
import Pimcore from './simple-icons/pimcore'
import Pinboard from './simple-icons/pinboard'
import Pinescript from './simple-icons/pinescript'
import Pinetwork from './simple-icons/pinetwork'
import Pingdom from './simple-icons/pingdom'
import Pinterest from './simple-icons/pinterest'
import Pioneerdj from './simple-icons/pioneerdj'
import Piped from './simple-icons/piped'
import Pipx from './simple-icons/pipx'
import Pivotaltracker from './simple-icons/pivotaltracker'
import Piwigo from './simple-icons/piwigo'
import Pix from './simple-icons/pix'
import Pixabay from './simple-icons/pixabay'
import Pixelfed from './simple-icons/pixelfed'
import Pixiv from './simple-icons/pixiv'
import Pkgsrc from './simple-icons/pkgsrc'
import Planet from './simple-icons/planet'
import Planetscale from './simple-icons/planetscale'
import Plangrid from './simple-icons/plangrid'
import Platformdotsh from './simple-icons/platformdotsh'
import Platformio from './simple-icons/platformio'
import Platzi from './simple-icons/platzi'
import Plausibleanalytics from './simple-icons/plausibleanalytics'
import Playcanvas from './simple-icons/playcanvas'
import Playerdotme from './simple-icons/playerdotme'
import Playerfm from './simple-icons/playerfm'
import Playstation from './simple-icons/playstation'
import PlaystationTwo from './simple-icons/playstation2'
import PlaystationThree from './simple-icons/playstation3'
import PlaystationFour from './simple-icons/playstation4'
import PlaystationFive from './simple-icons/playstation5'
import Playstationvita from './simple-icons/playstationvita'
import Playwright from './simple-icons/playwright'
import Pleroma from './simple-icons/pleroma'
import Plesk from './simple-icons/plesk'
import Plex from './simple-icons/plex'
import Plotly from './simple-icons/plotly'
import Pluralsight from './simple-icons/pluralsight'
import Plurk from './simple-icons/plurk'
import Pluscodes from './simple-icons/pluscodes'
import PmTwo from './simple-icons/pm2'
import Pnpm from './simple-icons/pnpm'
import Pocket from './simple-icons/pocket'
import Pocketbase from './simple-icons/pocketbase'
import Pocketcasts from './simple-icons/pocketcasts'
import Podcastaddict from './simple-icons/podcastaddict'
import Podcastindex from './simple-icons/podcastindex'
import Podman from './simple-icons/podman'
import Poe from './simple-icons/poe'
import Poetry from './simple-icons/poetry'
import Pointy from './simple-icons/pointy'
import Pokemon from './simple-icons/pokemon'
import Polars from './simple-icons/polars'
import Polestar from './simple-icons/polestar'
import Polkadot from './simple-icons/polkadot'
import Poly from './simple-icons/poly'
import Polygon from './simple-icons/polygon'
import Polymerproject from './simple-icons/polymerproject'
import Polywork from './simple-icons/polywork'
import PondFive from './simple-icons/pond5'
import Popos from './simple-icons/popos'
import Porkbun from './simple-icons/porkbun'
import Porsche from './simple-icons/porsche'
import Portainer from './simple-icons/portainer'
import Portswigger from './simple-icons/portswigger'
import Postcss from './simple-icons/postcss'
import Postgresql from './simple-icons/postgresql'
import Posthog from './simple-icons/posthog'
import Postman from './simple-icons/postman'
import Postmates from './simple-icons/postmates'
import Powerapps from './simple-icons/powerapps'
import Powerautomate from './simple-icons/powerautomate'
import Powerbi from './simple-icons/powerbi'
import Powerfx from './simple-icons/powerfx'
import Powerpages from './simple-icons/powerpages'
import Powers from './simple-icons/powers'
import Powershell from './simple-icons/powershell'
import Powervirtualagents from './simple-icons/powervirtualagents'
import Prdotco from './simple-icons/prdotco'
import Preact from './simple-icons/preact'
import Precommit from './simple-icons/precommit'
import Prefect from './simple-icons/prefect'
import Premierleague from './simple-icons/premierleague'
import Prepbytes from './simple-icons/prepbytes'
import Prestashop from './simple-icons/prestashop'
import Presto from './simple-icons/presto'
import Prettier from './simple-icons/prettier'
import Pretzel from './simple-icons/pretzel'
import Prevention from './simple-icons/prevention'
import Prezi from './simple-icons/prezi'
import Prime from './simple-icons/prime'
import Primevideo from './simple-icons/primevideo'
import Printables from './simple-icons/printables'
import Prisma from './simple-icons/prisma'
import Prismic from './simple-icons/prismic'
import Privatedivision from './simple-icons/privatedivision'
import Privateinternetaccess from './simple-icons/privateinternetaccess'
import Probot from './simple-icons/probot'
import Processingfoundation from './simple-icons/processingfoundation'
import Processwire from './simple-icons/processwire'
import Producthunt from './simple-icons/producthunt'
import Progate from './simple-icons/progate'
import Progress from './simple-icons/progress'
import Prometheus from './simple-icons/prometheus'
import Prosieben from './simple-icons/prosieben'
import Proteus from './simple-icons/proteus'
import Protocolsdotio from './simple-icons/protocolsdotio'
import Protodotio from './simple-icons/protodotio'
import Proton from './simple-icons/proton'
import Protoncalendar from './simple-icons/protoncalendar'
import Protondb from './simple-icons/protondb'
import Protondrive from './simple-icons/protondrive'
import Protonmail from './simple-icons/protonmail'
import Protonvpn from './simple-icons/protonvpn'
import Protools from './simple-icons/protools'
import Protractor from './simple-icons/protractor'
import Proxmox from './simple-icons/proxmox'
import Pterodactyl from './simple-icons/pterodactyl'
import Pubg from './simple-icons/pubg'
import Publons from './simple-icons/publons'
import Pubmed from './simple-icons/pubmed'
import Pug from './simple-icons/pug'
import Pulumi from './simple-icons/pulumi'
import Puma from './simple-icons/puma'
import Puppet from './simple-icons/puppet'
import Puppeteer from './simple-icons/puppeteer'
import Purescript from './simple-icons/purescript'
import Purgecss from './simple-icons/purgecss'
import Purism from './simple-icons/purism'
import Pushbullet from './simple-icons/pushbullet'
import Pusher from './simple-icons/pusher'
import Pwa from './simple-icons/pwa'
import Pycharm from './simple-icons/pycharm'
import Pycqa from './simple-icons/pycqa'
import Pydantic from './simple-icons/pydantic'
import Pyg from './simple-icons/pyg'
import Pypi from './simple-icons/pypi'
import Pypy from './simple-icons/pypy'
import Pyscaffold from './simple-icons/pyscaffold'
import Pysyft from './simple-icons/pysyft'
import Pytest from './simple-icons/pytest'
import Python from './simple-icons/python'
import Pythonanywhere from './simple-icons/pythonanywhere'
import Pytorch from './simple-icons/pytorch'
import Pyup from './simple-icons/pyup'
import Qantas from './simple-icons/qantas'
import Qase from './simple-icons/qase'
import Qatarairways from './simple-icons/qatarairways'
import Qbittorrent from './simple-icons/qbittorrent'
import Qemu from './simple-icons/qemu'
import Qgis from './simple-icons/qgis'
import Qi from './simple-icons/qi'
import Qiita from './simple-icons/qiita'
import Qiskit from './simple-icons/qiskit'
import Qiwi from './simple-icons/qiwi'
import Qlik from './simple-icons/qlik'
import Qmk from './simple-icons/qmk'
import Qnap from './simple-icons/qnap'
import Qt from './simple-icons/qt'
import Qualcomm from './simple-icons/qualcomm'
import Qualtrics from './simple-icons/qualtrics'
import Qualys from './simple-icons/qualys'
import Quantcast from './simple-icons/quantcast'
import Quantconnect from './simple-icons/quantconnect'
import Quarkus from './simple-icons/quarkus'
import Quasar from './simple-icons/quasar'
import Qubesos from './simple-icons/qubesos'
import Quest from './simple-icons/quest'
import Quickbooks from './simple-icons/quickbooks'
import Quicklook from './simple-icons/quicklook'
import Quicktime from './simple-icons/quicktime'
import Quicktype from './simple-icons/quicktype'
import Quip from './simple-icons/quip'
import Quora from './simple-icons/quora'
import Qwant from './simple-icons/qwant'
import Qwiklabs from './simple-icons/qwiklabs'
import Qzone from './simple-icons/qzone'
import R from './simple-icons/r'
import RThree from './simple-icons/r3'
import Rabbitmq from './simple-icons/rabbitmq'
import Racket from './simple-icons/racket'
import Radar from './simple-icons/radar'
import Radarr from './simple-icons/radarr'
import Radiopublic from './simple-icons/radiopublic'
import Radixui from './simple-icons/radixui'
import Radstudio from './simple-icons/radstudio'
import Railway from './simple-icons/railway'
import Rainmeter from './simple-icons/rainmeter'
import Rakuten from './simple-icons/rakuten'
import Ram from './simple-icons/ram'
import Rancher from './simple-icons/rancher'
import Rapid from './simple-icons/rapid'
import Rarible from './simple-icons/rarible'
import Rasa from './simple-icons/rasa'
import Raspberrypi from './simple-icons/raspberrypi'
import Ravelry from './simple-icons/ravelry'
import Ray from './simple-icons/ray'
import Raycast from './simple-icons/raycast'
import Raylib from './simple-icons/raylib'
import Razer from './simple-icons/razer'
import Razorpay from './simple-icons/razorpay'
import React from './simple-icons/react'
import Reacthookform from './simple-icons/reacthookform'
import Reactiveresume from './simple-icons/reactiveresume'
import Reactivex from './simple-icons/reactivex'
import Reactos from './simple-icons/reactos'
import Reactquery from './simple-icons/reactquery'
import Reactrouter from './simple-icons/reactrouter'
import Reacttable from './simple-icons/reacttable'
import Readdotcv from './simple-icons/readdotcv'
import Readme from './simple-icons/readme'
import Readthedocs from './simple-icons/readthedocs'
import Realm from './simple-icons/realm'
import Reason from './simple-icons/reason'
import Reasonstudios from './simple-icons/reasonstudios'
import Recoil from './simple-icons/recoil'
import Red from './simple-icons/red'
import Redbubble from './simple-icons/redbubble'
import Redbull from './simple-icons/redbull'
import Reddit from './simple-icons/reddit'
import Redhat from './simple-icons/redhat'
import Redhatopenshift from './simple-icons/redhatopenshift'
import Redis from './simple-icons/redis'
import Redmine from './simple-icons/redmine'
import Redox from './simple-icons/redox'
import Redux from './simple-icons/redux'
import Reduxsaga from './simple-icons/reduxsaga'
import Redwoodjs from './simple-icons/redwoodjs'
import Reebok from './simple-icons/reebok'
import Refine from './simple-icons/refine'
import Relay from './simple-icons/relay'
import Relianceindustrieslimited from './simple-icons/relianceindustrieslimited'
import Remark from './simple-icons/remark'
import Remedyentertainment from './simple-icons/remedyentertainment'
import Remix from './simple-icons/remix'
import Renault from './simple-icons/renault'
import Render from './simple-icons/render'
import Renovatebot from './simple-icons/renovatebot'
import Renpy from './simple-icons/renpy'
import Renren from './simple-icons/renren'
import Replit from './simple-icons/replit'
import Republicofgamers from './simple-icons/republicofgamers'
import Rescript from './simple-icons/rescript'
import Rescuetime from './simple-icons/rescuetime'
import Researchgate from './simple-icons/researchgate'
import Resend from './simple-icons/resend'
import Resharper from './simple-icons/resharper'
import Resurrectionremixos from './simple-icons/resurrectionremixos'
import Retool from './simple-icons/retool'
import Retroarch from './simple-icons/retroarch'
import Retropie from './simple-icons/retropie'
import Revanced from './simple-icons/revanced'
import Revealdotjs from './simple-icons/revealdotjs'
import Reverbnation from './simple-icons/reverbnation'
import Revoltdotchat from './simple-icons/revoltdotchat'
import Revolut from './simple-icons/revolut'
import Revue from './simple-icons/revue'
import Rewe from './simple-icons/rewe'
import Rezgo from './simple-icons/rezgo'
import Rhinoceros from './simple-icons/rhinoceros'
import Rich from './simple-icons/rich'
import Rider from './simple-icons/rider'
import Rimacautomobili from './simple-icons/rimacautomobili'
import Ring from './simple-icons/ring'
import Riotgames from './simple-icons/riotgames'
import Ripple from './simple-icons/ripple'
import Riscv from './simple-icons/riscv'
import Riseup from './simple-icons/riseup'
import Ritzcarlton from './simple-icons/ritzcarlton'
import Rive from './simple-icons/rive'
import Roadmapdotsh from './simple-icons/roadmapdotsh'
import Roamresearch from './simple-icons/roamresearch'
import Robinhood from './simple-icons/robinhood'
import Roblox from './simple-icons/roblox'
import Robloxstudio from './simple-icons/robloxstudio'
import Robotframework from './simple-icons/robotframework'
import Rocketdotchat from './simple-icons/rocketdotchat'
import Rocksdb from './simple-icons/rocksdb'
import Rockstargames from './simple-icons/rockstargames'
import Rockwellautomation from './simple-icons/rockwellautomation'
import Rockylinux from './simple-icons/rockylinux'
import Roku from './simple-icons/roku'
import RollTwoZero from './simple-icons/roll20'
import Rollsroyce from './simple-icons/rollsroyce'
import Rollupdotjs from './simple-icons/rollupdotjs'
import Rome from './simple-icons/rome'
import Roon from './simple-icons/roon'
import Rootme from './simple-icons/rootme'
import Roots from './simple-icons/roots'
import Rootsbedrock from './simple-icons/rootsbedrock'
import Rootssage from './simple-icons/rootssage'
import Ros from './simple-icons/ros'
import Rossmann from './simple-icons/rossmann'
import Rotaryinternational from './simple-icons/rotaryinternational'
import Rottentomatoes from './simple-icons/rottentomatoes'
import Roundcube from './simple-icons/roundcube'
import Rsocket from './simple-icons/rsocket'
import Rss from './simple-icons/rss'
import Rstudio from './simple-icons/rstudio'
import Rte from './simple-icons/rte'
import Rtl from './simple-icons/rtl'
import Rtlzwei from './simple-icons/rtlzwei'
import Rtm from './simple-icons/rtm'
import Rubocop from './simple-icons/rubocop'
import Ruby from './simple-icons/ruby'
import Rubygems from './simple-icons/rubygems'
import Rubyonrails from './simple-icons/rubyonrails'
import Rubysinatra from './simple-icons/rubysinatra'
import Ruff from './simple-icons/ruff'
import Rumahweb from './simple-icons/rumahweb'
import Rumble from './simple-icons/rumble'
import Rundeck from './simple-icons/rundeck'
import Runkeeper from './simple-icons/runkeeper'
import Runkit from './simple-icons/runkit'
import Runrundotit from './simple-icons/runrundotit'
import Rust from './simple-icons/rust'
import Rustdesk from './simple-icons/rustdesk'
import Rxdb from './simple-icons/rxdb'
import Ryanair from './simple-icons/ryanair'
import Rye from './simple-icons/rye'
import SSevenairlines from './simple-icons/s7airlines'
import Sabanci from './simple-icons/sabanci'
import Safari from './simple-icons/safari'
import Sage from './simple-icons/sage'
import Sahibinden from './simple-icons/sahibinden'
import Sailfishos from './simple-icons/sailfishos'
import Sailsdotjs from './simple-icons/sailsdotjs'
import Salesforce from './simple-icons/salesforce'
import Salla from './simple-icons/salla'
import Saltproject from './simple-icons/saltproject'
import Samsclub from './simple-icons/samsclub'
import Samsung from './simple-icons/samsung'
import Samsungpay from './simple-icons/samsungpay'
import Sandisk from './simple-icons/sandisk'
import Sanfranciscomunicipalrailway from './simple-icons/sanfranciscomunicipalrailway'
import Sanic from './simple-icons/sanic'
import Sanity from './simple-icons/sanity'
import Saopaulometro from './simple-icons/saopaulometro'
import Sap from './simple-icons/sap'
import Sass from './simple-icons/sass'
import SatOne from './simple-icons/sat1'
import Satellite from './simple-icons/satellite'
import Saturn from './simple-icons/saturn'
import Saucelabs from './simple-icons/saucelabs'
import Saudia from './simple-icons/saudia'
import Scala from './simple-icons/scala'
import Scaleway from './simple-icons/scaleway'
import Scania from './simple-icons/scania'
import Schneiderelectric from './simple-icons/schneiderelectric'
import Scikitlearn from './simple-icons/scikitlearn'
import Scipy from './simple-icons/scipy'
import Scopus from './simple-icons/scopus'
import Scpfoundation from './simple-icons/scpfoundation'
import Scrapbox from './simple-icons/scrapbox'
import Scrapy from './simple-icons/scrapy'
import Scratch from './simple-icons/scratch'
import Screencastify from './simple-icons/screencastify'
import Scribd from './simple-icons/scribd'
import Scrimba from './simple-icons/scrimba'
import Scrollreveal from './simple-icons/scrollreveal'
import Scrumalliance from './simple-icons/scrumalliance'
import Scrutinizerci from './simple-icons/scrutinizerci'
import Scylladb from './simple-icons/scylladb'
import Seagate from './simple-icons/seagate'
import Searxng from './simple-icons/searxng'
import Seat from './simple-icons/seat'
import Seatgeek from './simple-icons/seatgeek'
import Securityscorecard from './simple-icons/securityscorecard'
import Sefaria from './simple-icons/sefaria'
import Sega from './simple-icons/sega'
import Selenium from './simple-icons/selenium'
import Sellfy from './simple-icons/sellfy'
import Semanticrelease from './simple-icons/semanticrelease'
import Semanticscholar from './simple-icons/semanticscholar'
import Semanticui from './simple-icons/semanticui'
import Semanticuireact from './simple-icons/semanticuireact'
import Semanticweb from './simple-icons/semanticweb'
import Semaphoreci from './simple-icons/semaphoreci'
import Semrush from './simple-icons/semrush'
import Semver from './simple-icons/semver'
import Sencha from './simple-icons/sencha'
import Sennheiser from './simple-icons/sennheiser'
import Sensu from './simple-icons/sensu'
import Sentry from './simple-icons/sentry'
import Sepa from './simple-icons/sepa'
import Sequelize from './simple-icons/sequelize'
import Serverfault from './simple-icons/serverfault'
import Serverless from './simple-icons/serverless'
import Session from './simple-icons/session'
import Sessionize from './simple-icons/sessionize'
import Setapp from './simple-icons/setapp'
import Sfml from './simple-icons/sfml'
import Shadcnui from './simple-icons/shadcnui'
import Shadow from './simple-icons/shadow'
import Shanghaimetro from './simple-icons/shanghaimetro'
import Sharex from './simple-icons/sharex'
import Sharp from './simple-icons/sharp'
import Shazam from './simple-icons/shazam'
import Shell from './simple-icons/shell'
import Shelly from './simple-icons/shelly'
import Shenzhenmetro from './simple-icons/shenzhenmetro'
import Shieldsdotio from './simple-icons/shieldsdotio'
import Shikimori from './simple-icons/shikimori'
import Shopee from './simple-icons/shopee'
import Shopify from './simple-icons/shopify'
import Shopware from './simple-icons/shopware'
import Shortcut from './simple-icons/shortcut'
import Shotcut from './simple-icons/shotcut'
import Showpad from './simple-icons/showpad'
import Showtime from './simple-icons/showtime'
import Showwcase from './simple-icons/showwcase'
import Shutterstock from './simple-icons/shutterstock'
import Sidekiq from './simple-icons/sidekiq'
import Sidequest from './simple-icons/sidequest'
import Siemens from './simple-icons/siemens'
import Sifive from './simple-icons/sifive'
import Signal from './simple-icons/signal'
import Similarweb from './simple-icons/similarweb'
import Simkl from './simple-icons/simkl'
import Simpleanalytics from './simple-icons/simpleanalytics'
import Simpleicons from './simple-icons/simpleicons'
import Simplelogin from './simple-icons/simplelogin'
import Simplenote from './simple-icons/simplenote'
import Sinaweibo from './simple-icons/sinaweibo'
import Singaporeairlines from './simple-icons/singaporeairlines'
import Singlestore from './simple-icons/singlestore'
import Sitecore from './simple-icons/sitecore'
import Sitepoint from './simple-icons/sitepoint'
import Siyuan from './simple-icons/siyuan'
import Skaffold from './simple-icons/skaffold'
import Sketch from './simple-icons/sketch'
import Sketchfab from './simple-icons/sketchfab'
import Sketchup from './simple-icons/sketchup'
import Skillshare from './simple-icons/skillshare'
import Skoda from './simple-icons/skoda'
import Sky from './simple-icons/sky'
import Skynet from './simple-icons/skynet'
import Skypack from './simple-icons/skypack'
import Skype from './simple-icons/skype'
import Skypeforbusiness from './simple-icons/skypeforbusiness'
import Skyrock from './simple-icons/skyrock'
import Slack from './simple-icons/slack'
import Slackware from './simple-icons/slackware'
import Slashdot from './simple-icons/slashdot'
import Slickpic from './simple-icons/slickpic'
import Slides from './simple-icons/slides'
import Slideshare from './simple-icons/slideshare'
import Slint from './simple-icons/slint'
import Smart from './simple-icons/smart'
import Smartthings from './simple-icons/smartthings'
import Smashdotgg from './simple-icons/smashdotgg'
import Smashingmagazine from './simple-icons/smashingmagazine'
import Smrt from './simple-icons/smrt'
import Smugmug from './simple-icons/smugmug'
import Snapchat from './simple-icons/snapchat'
import Snapcraft from './simple-icons/snapcraft'
import Sncf from './simple-icons/sncf'
import Snowflake from './simple-icons/snowflake'
import Snowpack from './simple-icons/snowpack'
import Snyk from './simple-icons/snyk'
import Socialblade from './simple-icons/socialblade'
import SocietySix from './simple-icons/society6'
import Socketdotio from './simple-icons/socketdotio'
import Softpedia from './simple-icons/softpedia'
import Sogou from './simple-icons/sogou'
import Solana from './simple-icons/solana'
import Solid from './simple-icons/solid'
import Solidity from './simple-icons/solidity'
import Sololearn from './simple-icons/sololearn'
import Solus from './simple-icons/solus'
import Sonar from './simple-icons/sonar'
import Sonarcloud from './simple-icons/sonarcloud'
import Sonarlint from './simple-icons/sonarlint'
import Sonarqube from './simple-icons/sonarqube'
import Sonarr from './simple-icons/sonarr'
import Sonatype from './simple-icons/sonatype'
import Songkick from './simple-icons/songkick'
import Songoda from './simple-icons/songoda'
import Sonicwall from './simple-icons/sonicwall'
import Sonos from './simple-icons/sonos'
import Sony from './simple-icons/sony'
import Soriana from './simple-icons/soriana'
import Soundcharts from './simple-icons/soundcharts'
import Soundcloud from './simple-icons/soundcloud'
import Sourceengine from './simple-icons/sourceengine'
import Sourceforge from './simple-icons/sourceforge'
import Sourcehut from './simple-icons/sourcehut'
import Sourcetree from './simple-icons/sourcetree'
import Southwestairlines from './simple-icons/southwestairlines'
import Spacemacs from './simple-icons/spacemacs'
import Spaceship from './simple-icons/spaceship'
import Spacex from './simple-icons/spacex'
import Spacy from './simple-icons/spacy'
import Sparkar from './simple-icons/sparkar'
import Sparkasse from './simple-icons/sparkasse'
import Sparkfun from './simple-icons/sparkfun'
import Sparkpost from './simple-icons/sparkpost'
import Spdx from './simple-icons/spdx'
import Speakerdeck from './simple-icons/speakerdeck'
import Spectrum from './simple-icons/spectrum'
import Speedtest from './simple-icons/speedtest'
import Speedypage from './simple-icons/speedypage'
import Sphinx from './simple-icons/sphinx'
import Spigotmc from './simple-icons/spigotmc'
import Spine from './simple-icons/spine'
import Spinnaker from './simple-icons/spinnaker'
import Spinrilla from './simple-icons/spinrilla'
import Splunk from './simple-icons/splunk'
import Spoj from './simple-icons/spoj'
import Spond from './simple-icons/spond'
import Spotify from './simple-icons/spotify'
import Spotlight from './simple-icons/spotlight'
import Spreadshirt from './simple-icons/spreadshirt'
import Spreaker from './simple-icons/spreaker'
import Spring from './simple-icons/spring'
import SpringCreators from './simple-icons/spring_creators'
import Springboot from './simple-icons/springboot'
import Springsecurity from './simple-icons/springsecurity'
import Spyderide from './simple-icons/spyderide'
import Sqlalchemy from './simple-icons/sqlalchemy'
import Sqlite from './simple-icons/sqlite'
import Square from './simple-icons/square'
import Squareenix from './simple-icons/squareenix'
import Squarespace from './simple-icons/squarespace'
import Srgssr from './simple-icons/srgssr'
import Ssrn from './simple-icons/ssrn'
import Sst from './simple-icons/sst'
import Stackbit from './simple-icons/stackbit'
import Stackblitz from './simple-icons/stackblitz'
import Stackedit from './simple-icons/stackedit'
import Stackexchange from './simple-icons/stackexchange'
import Stackhawk from './simple-icons/stackhawk'
import Stackoverflow from './simple-icons/stackoverflow'
import Stackpath from './simple-icons/stackpath'
import Stackshare from './simple-icons/stackshare'
import Stadia from './simple-icons/stadia'
import Staffbase from './simple-icons/staffbase'
import Stagetimer from './simple-icons/stagetimer'
import Standardjs from './simple-icons/standardjs'
import Standardresume from './simple-icons/standardresume'
import Starbucks from './simple-icons/starbucks'
import Stardock from './simple-icons/stardock'
import Starlingbank from './simple-icons/starlingbank'
import Starship from './simple-icons/starship'
import Startrek from './simple-icons/startrek'
import Starz from './simple-icons/starz'
import Statamic from './simple-icons/statamic'
import Statuspage from './simple-icons/statuspage'
import Statuspal from './simple-icons/statuspal'
import Steam from './simple-icons/steam'
import Steamdb from './simple-icons/steamdb'
import Steamdeck from './simple-icons/steamdeck'
import Steamworks from './simple-icons/steamworks'
import Steelseries from './simple-icons/steelseries'
import Steem from './simple-icons/steem'
import Steemit from './simple-icons/steemit'
import Steinberg from './simple-icons/steinberg'
import Stellar from './simple-icons/stellar'
import Stencyl from './simple-icons/stencyl'
import Stimulus from './simple-icons/stimulus'
import Stitcher from './simple-icons/stitcher'
import Stmicroelectronics from './simple-icons/stmicroelectronics'
import Stockx from './simple-icons/stockx'
import Stopstalk from './simple-icons/stopstalk'
import Storyblok from './simple-icons/storyblok'
import Storybook from './simple-icons/storybook'
import Strapi from './simple-icons/strapi'
import Strava from './simple-icons/strava'
import Streamlabs from './simple-icons/streamlabs'
import Streamlit from './simple-icons/streamlit'
import Streamrunners from './simple-icons/streamrunners'
import Stripe from './simple-icons/stripe'
import Strongswan from './simple-icons/strongswan'
import Stryker from './simple-icons/stryker'
import Stubhub from './simple-icons/stubhub'
import StudioThreet from './simple-icons/studio3t'
import Studyverse from './simple-icons/studyverse'
import Styledcomponents from './simple-icons/styledcomponents'
import Stylelint from './simple-icons/stylelint'
import Styleshare from './simple-icons/styleshare'
import Stylus from './simple-icons/stylus'
import Subaru from './simple-icons/subaru'
import Sublimetext from './simple-icons/sublimetext'
import Substack from './simple-icons/substack'
import Subtitleedit from './simple-icons/subtitleedit'
import Subversion from './simple-icons/subversion'
import Suckless from './simple-icons/suckless'
import Sumologic from './simple-icons/sumologic'
import Supabase from './simple-icons/supabase'
import Supercrease from './simple-icons/supercrease'
import Supermicro from './simple-icons/supermicro'
import Superuser from './simple-icons/superuser'
import Surrealdb from './simple-icons/surrealdb'
import Surveymonkey from './simple-icons/surveymonkey'
import Suse from './simple-icons/suse'
import Suzuki from './simple-icons/suzuki'
import Svelte from './simple-icons/svelte'
import Svg from './simple-icons/svg'
import Svgdotjs from './simple-icons/svgdotjs'
import Svgo from './simple-icons/svgo'
import Swagger from './simple-icons/swagger'
import Swarm from './simple-icons/swarm'
import Swc from './simple-icons/swc'
import Swift from './simple-icons/swift'
import Swiggy from './simple-icons/swiggy'
import Swiper from './simple-icons/swiper'
import Swr from './simple-icons/swr'
import Symantec from './simple-icons/symantec'
import Symbolab from './simple-icons/symbolab'
import Symfony from './simple-icons/symfony'
import Symphony from './simple-icons/symphony'
import Sympy from './simple-icons/sympy'
import Synology from './simple-icons/synology'
import SystemSevenSix from './simple-icons/system76'
import Tabelog from './simple-icons/tabelog'
import Tableau from './simple-icons/tableau'
import Tablecheck from './simple-icons/tablecheck'
import Tacobell from './simple-icons/tacobell'
import Tado from './simple-icons/tado'
import Taichigraphics from './simple-icons/taichigraphics'
import Taichilang from './simple-icons/taichilang'
import Tails from './simple-icons/tails'
import Tailwindcss from './simple-icons/tailwindcss'
import Taketwointeractivesoftware from './simple-icons/taketwointeractivesoftware'
import Talend from './simple-icons/talend'
import Talenthouse from './simple-icons/talenthouse'
import Talos from './simple-icons/talos'
import Tamiya from './simple-icons/tamiya'
import Tampermonkey from './simple-icons/tampermonkey'
import Taobao from './simple-icons/taobao'
import Tapas from './simple-icons/tapas'
import Target from './simple-icons/target'
import Task from './simple-icons/task'
import Tasmota from './simple-icons/tasmota'
import Tata from './simple-icons/tata'
import Tauri from './simple-icons/tauri'
import Taxbuzz from './simple-icons/taxbuzz'
import Tcs from './simple-icons/tcs'
import Teal from './simple-icons/teal'
import Teamcity from './simple-icons/teamcity'
import Teamspeak from './simple-icons/teamspeak'
import Teamviewer from './simple-icons/teamviewer'
import Ted from './simple-icons/ted'
import Teepublic from './simple-icons/teepublic'
import Teespring from './simple-icons/teespring'
import Tekton from './simple-icons/tekton'
import TeleFive from './simple-icons/tele5'
import Telegram from './simple-icons/telegram'
import Telegraph from './simple-icons/telegraph'
import Telequebec from './simple-icons/telequebec'
import Temporal from './simple-icons/temporal'
import Tencentqq from './simple-icons/tencentqq'
import Tensorflow from './simple-icons/tensorflow'
import Teradata from './simple-icons/teradata'
import Teratail from './simple-icons/teratail'
import Termius from './simple-icons/termius'
import Terraform from './simple-icons/terraform'
import Tesco from './simple-icons/tesco'
import Tesla from './simple-icons/tesla'
import Testcafe from './simple-icons/testcafe'
import Testin from './simple-icons/testin'
import Testinglibrary from './simple-icons/testinglibrary'
import Testrail from './simple-icons/testrail'
import Tether from './simple-icons/tether'
import Textpattern from './simple-icons/textpattern'
import Tga from './simple-icons/tga'
import Thangs from './simple-icons/thangs'
import Thealgorithms from './simple-icons/thealgorithms'
import Theboringcompany from './simple-icons/theboringcompany'
import Theconversation from './simple-icons/theconversation'
import Thefinals from './simple-icons/thefinals'
import Theirishtimes from './simple-icons/theirishtimes'
import Themighty from './simple-icons/themighty'
import Themodelsresource from './simple-icons/themodelsresource'
import Themoviedatabase from './simple-icons/themoviedatabase'
import Thenorthface from './simple-icons/thenorthface'
import Theodinproject from './simple-icons/theodinproject'
import Theregister from './simple-icons/theregister'
import Thesoundsresource from './simple-icons/thesoundsresource'
import Thespritersresource from './simple-icons/thespritersresource'
import Thewashingtonpost from './simple-icons/thewashingtonpost'
import Thingiverse from './simple-icons/thingiverse'
import Thinkpad from './simple-icons/thinkpad'
import Thirdweb from './simple-icons/thirdweb'
import Threadless from './simple-icons/threadless'
import Threads from './simple-icons/threads'
import Threedotjs from './simple-icons/threedotjs'
import Threema from './simple-icons/threema'
import Thumbtack from './simple-icons/thumbtack'
import Thunderbird from './simple-icons/thunderbird'
import Thunderstore from './simple-icons/thunderstore'
import Thurgauerkantonalbank from './simple-icons/thurgauerkantonalbank'
import Thymeleaf from './simple-icons/thymeleaf'
import Ticketmaster from './simple-icons/ticketmaster'
import Ticktick from './simple-icons/ticktick'
import Tidal from './simple-icons/tidal'
import Tiddlywiki from './simple-icons/tiddlywiki'
import Tide from './simple-icons/tide'
import Tidyverse from './simple-icons/tidyverse'
import Tietoevry from './simple-icons/tietoevry'
import Tiktok from './simple-icons/tiktok'
import Tildapublishing from './simple-icons/tildapublishing'
import Tile from './simple-icons/tile'
import Timescale from './simple-icons/timescale'
import Tina from './simple-icons/tina'
import Tinder from './simple-icons/tinder'
import Tindie from './simple-icons/tindie'
import Tinkercad from './simple-icons/tinkercad'
import Tinyletter from './simple-icons/tinyletter'
import Tistory from './simple-icons/tistory'
import Tmobile from './simple-icons/tmobile'
import Tmux from './simple-icons/tmux'
import Todoist from './simple-icons/todoist'
import Toggl from './simple-icons/toggl'
import Toggltrack from './simple-icons/toggltrack'
import Tokyometro from './simple-icons/tokyometro'
import Toll from './simple-icons/toll'
import Toml from './simple-icons/toml'
import Tomorrowland from './simple-icons/tomorrowland'
import Ton from './simple-icons/ton'
import Topcoder from './simple-icons/topcoder'
import Topdotgg from './simple-icons/topdotgg'
import Toptal from './simple-icons/toptal'
import Torbrowser from './simple-icons/torbrowser'
import Torproject from './simple-icons/torproject'
import Toshiba from './simple-icons/toshiba'
import Totvs from './simple-icons/totvs'
import Tourbox from './simple-icons/tourbox'
import Toyota from './simple-icons/toyota'
import Tplink from './simple-icons/tplink'
import Tqdm from './simple-icons/tqdm'
import Tradingview from './simple-icons/tradingview'
import Traefikmesh from './simple-icons/traefikmesh'
import Traefikproxy from './simple-icons/traefikproxy'
import Trailforks from './simple-icons/trailforks'
import Trainerroad from './simple-icons/trainerroad'
import Trakt from './simple-icons/trakt'
import Transifex from './simple-icons/transifex'
import Transmission from './simple-icons/transmission'
import Transportforireland from './simple-icons/transportforireland'
import Transportforlondon from './simple-icons/transportforlondon'
import Travisci from './simple-icons/travisci'
import Treehouse from './simple-icons/treehouse'
import Trello from './simple-icons/trello'
import Trendmicro from './simple-icons/trendmicro'
import Treyarch from './simple-icons/treyarch'
import Tricentis from './simple-icons/tricentis'
import Trilium from './simple-icons/trilium'
import Triller from './simple-icons/triller'
import Trillertv from './simple-icons/trillertv'
import Trino from './simple-icons/trino'
import Tripadvisor from './simple-icons/tripadvisor'
import Tripdotcom from './simple-icons/tripdotcom'
import Trivy from './simple-icons/trivy'
import Trove from './simple-icons/trove'
import Trpc from './simple-icons/trpc'
import Truenas from './simple-icons/truenas'
import Trueup from './simple-icons/trueup'
import Trulia from './simple-icons/trulia'
import Trustedshops from './simple-icons/trustedshops'
import Trustpilot from './simple-icons/trustpilot'
import Tryhackme from './simple-icons/tryhackme'
import Tryitonline from './simple-icons/tryitonline'
import Tsnode from './simple-icons/tsnode'
import Tubi from './simple-icons/tubi'
import Tui from './simple-icons/tui'
import Tumblr from './simple-icons/tumblr'
import Tunein from './simple-icons/tunein'
import Turbo from './simple-icons/turbo'
import Turborepo from './simple-icons/turborepo'
import Turbosquid from './simple-icons/turbosquid'
import Turkishairlines from './simple-icons/turkishairlines'
import Turso from './simple-icons/turso'
import Tutanota from './simple-icons/tutanota'
import TvFourplay from './simple-icons/tv4play'
import Tvtime from './simple-icons/tvtime'
import Twilio from './simple-icons/twilio'
import Twinkly from './simple-icons/twinkly'
import Twitch from './simple-icons/twitch'
import Twitter from './simple-icons/twitter'
import Typeform from './simple-icons/typeform'
import Typer from './simple-icons/typer'
import Typescript from './simple-icons/typescript'
import TypoThree from './simple-icons/typo3'
import Typst from './simple-icons/typst'
import Uber from './simple-icons/uber'
import Ubereats from './simple-icons/ubereats'
import Ubiquiti from './simple-icons/ubiquiti'
import Ubisoft from './simple-icons/ubisoft'
import Ublockorigin from './simple-icons/ublockorigin'
import Ubuntu from './simple-icons/ubuntu'
import Ubuntumate from './simple-icons/ubuntumate'
import Udacity from './simple-icons/udacity'
import Udemy from './simple-icons/udemy'
import Ufc from './simple-icons/ufc'
import Uikit from './simple-icons/uikit'
import Ulule from './simple-icons/ulule'
import Umami from './simple-icons/umami'
import Umbraco from './simple-icons/umbraco'
import Uml from './simple-icons/uml'
import Unacademy from './simple-icons/unacademy'
import Underarmour from './simple-icons/underarmour'
import Underscoredotjs from './simple-icons/underscoredotjs'
import Undertale from './simple-icons/undertale'
import Unicode from './simple-icons/unicode'
import Unilever from './simple-icons/unilever'
import Uniqlo from './simple-icons/uniqlo'
import UniqloJa from './simple-icons/uniqlo_ja'
import Unitedairlines from './simple-icons/unitedairlines'
import Unitednations from './simple-icons/unitednations'
import Unity from './simple-icons/unity'
import Unlicense from './simple-icons/unlicense'
import Unocss from './simple-icons/unocss'
import Unraid from './simple-icons/unraid'
import Unrealengine from './simple-icons/unrealengine'
import Unsplash from './simple-icons/unsplash'
import Untappd from './simple-icons/untappd'
import Upcloud from './simple-icons/upcloud'
import Uphold from './simple-icons/uphold'
import Uplabs from './simple-icons/uplabs'
import Upptime from './simple-icons/upptime'
import Ups from './simple-icons/ups'
import Upstash from './simple-icons/upstash'
import Uptimekuma from './simple-icons/uptimekuma'
import Uptobox from './simple-icons/uptobox'
import Upwork from './simple-icons/upwork'
import Usps from './simple-icons/usps'
import Utorrent from './simple-icons/utorrent'
import V from './simple-icons/v'
import VTwoex from './simple-icons/v2ex'
import VEight from './simple-icons/v8'
import Vaadin from './simple-icons/vaadin'
import Vagrant from './simple-icons/vagrant'
import Vala from './simple-icons/vala'
import Valorant from './simple-icons/valorant'
import Valve from './simple-icons/valve'
import Vapor from './simple-icons/vapor'
import Vault from './simple-icons/vault'
import Vaultwarden from './simple-icons/vaultwarden'
import Vauxhall from './simple-icons/vauxhall'
import Vbulletin from './simple-icons/vbulletin'
import Vectary from './simple-icons/vectary'
import Vectorlogozone from './simple-icons/vectorlogozone'
import Vectorworks from './simple-icons/vectorworks'
import Veeam from './simple-icons/veeam'
import Veepee from './simple-icons/veepee'
import Vega from './simple-icons/vega'
import Vegas from './simple-icons/vegas'
import Velog from './simple-icons/velog'
import Venmo from './simple-icons/venmo'
import Vercel from './simple-icons/vercel'
import Verdaccio from './simple-icons/verdaccio'
import Veritas from './simple-icons/veritas'
import Verizon from './simple-icons/verizon'
import Vespa from './simple-icons/vespa'
import Vexxhost from './simple-icons/vexxhost'
import Vfairs from './simple-icons/vfairs'
import Viadeo from './simple-icons/viadeo'
import Viaplay from './simple-icons/viaplay'
import Viber from './simple-icons/viber'
import Viblo from './simple-icons/viblo'
import Victoriametrics from './simple-icons/victoriametrics'
import Victronenergy from './simple-icons/victronenergy'
import Vim from './simple-icons/vim'
import Vimeo from './simple-icons/vimeo'
import Vimeolivestream from './simple-icons/vimeolivestream'
import Virgin from './simple-icons/virgin'
import Virginatlantic from './simple-icons/virginatlantic'
import Virginmedia from './simple-icons/virginmedia'
import Virtualbox from './simple-icons/virtualbox'
import Virustotal from './simple-icons/virustotal'
import Visa from './simple-icons/visa'
import Visualbasic from './simple-icons/visualbasic'
import Visualstudio from './simple-icons/visualstudio'
import Visualstudioappcenter from './simple-icons/visualstudioappcenter'
import Visualstudiocode from './simple-icons/visualstudiocode'
import Vite from './simple-icons/vite'
import Vitepress from './simple-icons/vitepress'
import Vitess from './simple-icons/vitess'
import Vitest from './simple-icons/vitest'
import Vivaldi from './simple-icons/vivaldi'
import Vivawallet from './simple-icons/vivawallet'
import Vivino from './simple-icons/vivino'
import Vivint from './simple-icons/vivint'
import Vivo from './simple-icons/vivo'
import Vk from './simple-icons/vk'
import Vlcmediaplayer from './simple-icons/vlcmediaplayer'
import Vmware from './simple-icons/vmware'
import Vodafone from './simple-icons/vodafone'
import Voidlinux from './simple-icons/voidlinux'
import Voipdotms from './simple-icons/voipdotms'
import Volkswagen from './simple-icons/volkswagen'
import Volvo from './simple-icons/volvo'
import Vonage from './simple-icons/vonage'
import Vorondesign from './simple-icons/vorondesign'
import Vowpalwabbit from './simple-icons/vowpalwabbit'
import Vox from './simple-icons/vox'
import Vsco from './simple-icons/vsco'
import Vscodium from './simple-icons/vscodium'
import Vtex from './simple-icons/vtex'
import Vuedotjs from './simple-icons/vuedotjs'
import Vuetify from './simple-icons/vuetify'
import Vulkan from './simple-icons/vulkan'
import Vultr from './simple-icons/vultr'
import Vyond from './simple-icons/vyond'
import WThreeschools from './simple-icons/w3schools'
import Wacom from './simple-icons/wacom'
import Wagtail from './simple-icons/wagtail'
import Wails from './simple-icons/wails'
import Wakatime from './simple-icons/wakatime'
import Walkman from './simple-icons/walkman'
import Wallabag from './simple-icons/wallabag'
import Walletconnect from './simple-icons/walletconnect'
import Walmart from './simple-icons/walmart'
import Wantedly from './simple-icons/wantedly'
import Wappalyzer from './simple-icons/wappalyzer'
import Warnerbros from './simple-icons/warnerbros'
import Warp from './simple-icons/warp'
import Wasabi from './simple-icons/wasabi'
import Wasmcloud from './simple-icons/wasmcloud'
import Wasmer from './simple-icons/wasmer'
import Watchtower from './simple-icons/watchtower'
import Wattpad from './simple-icons/wattpad'
import Wayland from './simple-icons/wayland'
import Waze from './simple-icons/waze'
import Wazirx from './simple-icons/wazirx'
import Wearos from './simple-icons/wearos'
import Weasyl from './simple-icons/weasyl'
import WebThreedotjs from './simple-icons/web3dotjs'
import Webassembly from './simple-icons/webassembly'
import Webauthn from './simple-icons/webauthn'
import Webcomponentsdotorg from './simple-icons/webcomponentsdotorg'
import Webdriverio from './simple-icons/webdriverio'
import Webex from './simple-icons/webex'
import Webflow from './simple-icons/webflow'
import Webgl from './simple-icons/webgl'
import Weblate from './simple-icons/weblate'
import Webmin from './simple-icons/webmin'
import Webmoney from './simple-icons/webmoney'
import Webpack from './simple-icons/webpack'
import Webrtc from './simple-icons/webrtc'
import Webstorm from './simple-icons/webstorm'
import Webtoon from './simple-icons/webtoon'
import Webtrees from './simple-icons/webtrees'
import Wechat from './simple-icons/wechat'
import Wegame from './simple-icons/wegame'
import Weightsandbiases from './simple-icons/weightsandbiases'
import Welcometothejungle from './simple-icons/welcometothejungle'
import Wellfound from './simple-icons/wellfound'
import Wellsfargo from './simple-icons/wellsfargo'
import Wemo from './simple-icons/wemo'
import Westerndigital from './simple-icons/westerndigital'
import Westernunion from './simple-icons/westernunion'
import Wetransfer from './simple-icons/wetransfer'
import Wezterm from './simple-icons/wezterm'
import Whatsapp from './simple-icons/whatsapp'
import Wheniwork from './simple-icons/wheniwork'
import Wii from './simple-icons/wii'
import Wiiu from './simple-icons/wiiu'
import Wikidata from './simple-icons/wikidata'
import Wikidotgg from './simple-icons/wikidotgg'
import Wikidotjs from './simple-icons/wikidotjs'
import Wikimediacommons from './simple-icons/wikimediacommons'
import Wikimediafoundation from './simple-icons/wikimediafoundation'
import Wikipedia from './simple-icons/wikipedia'
import Wikiquote from './simple-icons/wikiquote'
import Wikivoyage from './simple-icons/wikivoyage'
import Winamp from './simple-icons/winamp'
import Windows from './simple-icons/windows'
import WindowsOneZero from './simple-icons/windows10'
import WindowsOneOne from './simple-icons/windows11'
import WindowsNineFive from './simple-icons/windows95'
import Windowsterminal from './simple-icons/windowsterminal'
import Windowsxp from './simple-icons/windowsxp'
import Wipro from './simple-icons/wipro'
import Wire from './simple-icons/wire'
import Wireguard from './simple-icons/wireguard'
import Wireshark from './simple-icons/wireshark'
import Wise from './simple-icons/wise'
import Wish from './simple-icons/wish'
import Wistia from './simple-icons/wistia'
import Wix from './simple-icons/wix'
import Wizzair from './simple-icons/wizzair'
import Wolfram from './simple-icons/wolfram'
import Wolframlanguage from './simple-icons/wolframlanguage'
import Wolframmathematica from './simple-icons/wolframmathematica'
import Wondershare from './simple-icons/wondershare'
import Wondersharefilmora from './simple-icons/wondersharefilmora'
import Woo from './simple-icons/woo'
import Woocommerce from './simple-icons/woocommerce'
import Wordpress from './simple-icons/wordpress'
import Workplace from './simple-icons/workplace'
import Worldhealthorganization from './simple-icons/worldhealthorganization'
import Wpengine from './simple-icons/wpengine'
import Wpexplorer from './simple-icons/wpexplorer'
import Wprocket from './simple-icons/wprocket'
import Writedotas from './simple-icons/writedotas'
import Wwe from './simple-icons/wwe'
import Wwise from './simple-icons/wwise'
import Wyze from './simple-icons/wyze'
import X from './simple-icons/x'
import Xamarin from './simple-icons/xamarin'
import Xaml from './simple-icons/xaml'
import Xampp from './simple-icons/xampp'
import Xbox from './simple-icons/xbox'
import Xcode from './simple-icons/xcode'
import Xdadevelopers from './simple-icons/xdadevelopers'
import Xdotorg from './simple-icons/xdotorg'
import Xendit from './simple-icons/xendit'
import Xero from './simple-icons/xero'
import Xfce from './simple-icons/xfce'
import Xiaohongshu from './simple-icons/xiaohongshu'
import Xiaomi from './simple-icons/xiaomi'
import Xing from './simple-icons/xing'
import Xmpp from './simple-icons/xmpp'
import Xo from './simple-icons/xo'
import Xrp from './simple-icons/xrp'
import Xsplit from './simple-icons/xsplit'
import Xstate from './simple-icons/xstate'
import Yale from './simple-icons/yale'
import Yamahacorporation from './simple-icons/yamahacorporation'
import Yamahamotorcorporation from './simple-icons/yamahamotorcorporation'
import Yaml from './simple-icons/yaml'
import Yammer from './simple-icons/yammer'
import Yandexcloud from './simple-icons/yandexcloud'
import Yarn from './simple-icons/yarn'
import Ycombinator from './simple-icons/ycombinator'
import Yelp from './simple-icons/yelp'
import Yeti from './simple-icons/yeti'
import Yoast from './simple-icons/yoast'
import Youtube from './simple-icons/youtube'
import Youtubegaming from './simple-icons/youtubegaming'
import Youtubekids from './simple-icons/youtubekids'
import Youtubemusic from './simple-icons/youtubemusic'
import Youtubeshorts from './simple-icons/youtubeshorts'
import Youtubestudio from './simple-icons/youtubestudio'
import Youtubetv from './simple-icons/youtubetv'
import Yr from './simple-icons/yr'
import Yubico from './simple-icons/yubico'
import Zabka from './simple-icons/zabka'
import Zaim from './simple-icons/zaim'
import Zalando from './simple-icons/zalando'
import Zalo from './simple-icons/zalo'
import Zap from './simple-icons/zap'
import Zapier from './simple-icons/zapier'
import Zara from './simple-icons/zara'
import Zazzle from './simple-icons/zazzle'
import Zcash from './simple-icons/zcash'
import Zcool from './simple-icons/zcool'
import Zdf from './simple-icons/zdf'
import Zebpay from './simple-icons/zebpay'
import Zebratechnologies from './simple-icons/zebratechnologies'
import Zedindustries from './simple-icons/zedindustries'
import Zelle from './simple-icons/zelle'
import Zend from './simple-icons/zend'
import Zendesk from './simple-icons/zendesk'
import Zenn from './simple-icons/zenn'
import Zenodo from './simple-icons/zenodo'
import Zensar from './simple-icons/zensar'
import Zerodha from './simple-icons/zerodha'
import Zerply from './simple-icons/zerply'
import Zettlr from './simple-icons/zettlr'
import Zhihu from './simple-icons/zhihu'
import Zig from './simple-icons/zig'
import Zigbee from './simple-icons/zigbee'
import Ziggo from './simple-icons/ziggo'
import Zilch from './simple-icons/zilch'
import Zillow from './simple-icons/zillow'
import Zincsearch from './simple-icons/zincsearch'
import Zingat from './simple-icons/zingat'
import Zod from './simple-icons/zod'
import Zoho from './simple-icons/zoho'
import Zoiper from './simple-icons/zoiper'
import Zomato from './simple-icons/zomato'
import Zoom from './simple-icons/zoom'
import Zorin from './simple-icons/zorin'
import Zotero from './simple-icons/zotero'
import Zsh from './simple-icons/zsh'
import Zulip from './simple-icons/zulip'
import Zyte from './simple-icons/zyte'

const Logos = {
  '1001tracklists': OneZeroZeroOnetracklists,
  '1password': Onepassword,
  '2k': Twok,
  '365datascience': ThreeSixFivedatascience,
  '3m': Threem,
  '42': FourTwo,
  '4chan': Fourchan,
  '4d': Fourd,
  '500px': FiveZeroZeropx,
  '7zip': Sevenzip,
  '99designs': NineNinedesigns,
  '9gag': Ninegag,
  Abbott: Abbott,
  Abbrobotstudio: Abbrobotstudio,
  Abbvie: Abbvie,
  Aboutdotme: Aboutdotme,
  Abstract: Abstract,
  Abusedotch: Abusedotch,
  Academia: Academia,
  Accenture: Accenture,
  Accusoft: Accusoft,
  Acer: Acer,
  Acm: Acm,
  Actigraph: Actigraph,
  Activision: Activision,
  Activitypub: Activitypub,
  Actix: Actix,
  Acura: Acura,
  Adafruit: Adafruit,
  Adblock: Adblock,
  Adblockplus: Adblockplus,
  Adguard: Adguard,
  Adidas: Adidas,
  Adminer: Adminer,
  Adobe: Adobe,
  Adobeacrobatreader: Adobeacrobatreader,
  Adobeaftereffects: Adobeaftereffects,
  Adobeaudition: Adobeaudition,
  Adobecreativecloud: Adobecreativecloud,
  Adobedreamweaver: Adobedreamweaver,
  Adobefonts: Adobefonts,
  Adobeillustrator: Adobeillustrator,
  Adobeindesign: Adobeindesign,
  Adobelightroom: Adobelightroom,
  Adobelightroomclassic: Adobelightroomclassic,
  Adobephotoshop: Adobephotoshop,
  Adobepremierepro: Adobepremierepro,
  Adobexd: Adobexd,
  Adonisjs: Adonisjs,
  Adp: Adp,
  Adroll: Adroll,
  Adventofcode: Adventofcode,
  Adyen: Adyen,
  Aerlingus: Aerlingus,
  Aeroflot: Aeroflot,
  Aeromexico: Aeromexico,
  Aerospike: Aerospike,
  Aew: Aew,
  Affine: Affine,
  Affinity: Affinity,
  Affinitydesigner: Affinitydesigner,
  Affinityphoto: Affinityphoto,
  Affinitypublisher: Affinitypublisher,
  Aframe: Aframe,
  Afterpay: Afterpay,
  Agora: Agora,
  Aib: Aib,
  Aidungeon: Aidungeon,
  Aiohttp: Aiohttp,
  Aiqfome: Aiqfome,
  Airasia: Airasia,
  Airbnb: Airbnb,
  Airbrakedotio: Airbrakedotio,
  Airbus: Airbus,
  Airbyte: Airbyte,
  Aircall: Aircall,
  Aircanada: Aircanada,
  Airchina: Airchina,
  Airfrance: Airfrance,
  Airindia: Airindia,
  Airplayaudio: Airplayaudio,
  Airplayvideo: Airplayvideo,
  Airtable: Airtable,
  Airtel: Airtel,
  Airtransat: Airtransat,
  Ajv: Ajv,
  Akamai: Akamai,
  Akaunting: Akaunting,
  Akiflow: Akiflow,
  Alacritty: Alacritty,
  Alamy: Alamy,
  Albertheijn: Albertheijn,
  Alby: Alby,
  Alchemy: Alchemy,
  Aldinord: Aldinord,
  Aldisud: Aldisud,
  Alfaromeo: Alfaromeo,
  Alfred: Alfred,
  Algolia: Algolia,
  Algorand: Algorand,
  Alibabacloud: Alibabacloud,
  Alibabadotcom: Alibabadotcom,
  Alienware: Alienware,
  Aliexpress: Aliexpress,
  Alipay: Alipay,
  Allegro: Allegro,
  Alliedmodders: Alliedmodders,
  Allocine: Allocine,
  Alltrails: Alltrails,
  Almalinux: Almalinux,
  Alpinedotjs: Alpinedotjs,
  Alpinelinux: Alpinelinux,
  Alteryx: Alteryx,
  Altiumdesigner: Altiumdesigner,
  Alwaysdata: Alwaysdata,
  Alx: Alx,
  Amazon: Amazon,
  Amazonalexa: Amazonalexa,
  Amazonapigateway: Amazonapigateway,
  Amazonaws: Amazonaws,
  Amazoncloudwatch: Amazoncloudwatch,
  Amazondocumentdb: Amazondocumentdb,
  Amazondynamodb: Amazondynamodb,
  Amazonec2: AmazonecTwo,
  Amazonecs: Amazonecs,
  Amazoneks: Amazoneks,
  Amazonfiretv: Amazonfiretv,
  Amazongames: Amazongames,
  Amazoniam: Amazoniam,
  Amazonlumberyard: Amazonlumberyard,
  Amazonluna: Amazonluna,
  Amazonmusic: Amazonmusic,
  Amazonpay: Amazonpay,
  Amazonprime: Amazonprime,
  Amazonrds: Amazonrds,
  Amazonredshift: Amazonredshift,
  Amazonroute53: AmazonrouteFiveThree,
  Amazons3: AmazonsThree,
  Amazonsimpleemailservice: Amazonsimpleemailservice,
  Amazonsqs: Amazonsqs,
  Amd: Amd,
  Ameba: Ameba,
  Americanairlines: Americanairlines,
  Americanexpress: Americanexpress,
  Amg: Amg,
  Amp: Amp,
  Amul: Amul,
  Ana: Ana,
  Anaconda: Anaconda,
  Analogue: Analogue,
  Andela: Andela,
  Android: Android,
  Androidauto: Androidauto,
  Androidstudio: Androidstudio,
  Angular: Angular,
  Anilist: Anilist,
  Animalplanet: Animalplanet,
  Ankermake: Ankermake,
  Ansible: Ansible,
  Answer: Answer,
  Ansys: Ansys,
  Anta: Anta,
  Antdesign: Antdesign,
  Antena3: AntenaThree,
  Anycubic: Anycubic,
  Anydesk: Anydesk,
  Anytype: Anytype,
  Aol: Aol,
  Apache: Apache,
  Apacheairflow: Apacheairflow,
  Apacheant: Apacheant,
  Apachecassandra: Apachecassandra,
  Apachecloudstack: Apachecloudstack,
  Apachecordova: Apachecordova,
  Apachecouchdb: Apachecouchdb,
  Apachedruid: Apachedruid,
  Apacheecharts: Apacheecharts,
  Apacheflink: Apacheflink,
  Apachefreemarker: Apachefreemarker,
  Apachegroovy: Apachegroovy,
  Apacheguacamole: Apacheguacamole,
  Apachehadoop: Apachehadoop,
  Apachehive: Apachehive,
  Apachejmeter: Apachejmeter,
  Apachekafka: Apachekafka,
  Apachekylin: Apachekylin,
  Apachemaven: Apachemaven,
  Apachenetbeanside: Apachenetbeanside,
  Apacheopenoffice: Apacheopenoffice,
  Apacheparquet: Apacheparquet,
  Apachepulsar: Apachepulsar,
  Apacherocketmq: Apacherocketmq,
  Apachesolr: Apachesolr,
  Apachespark: Apachespark,
  Apachestorm: Apachestorm,
  Apachetomcat: Apachetomcat,
  Aparat: Aparat,
  Apifox: Apifox,
  Apmterminals: Apmterminals,
  Apollographql: Apollographql,
  Apostrophe: Apostrophe,
  Appian: Appian,
  Appium: Appium,
  Apple: Apple,
  Applearcade: Applearcade,
  Applemusic: Applemusic,
  Applenews: Applenews,
  Applepay: Applepay,
  Applepodcasts: Applepodcasts,
  Appletv: Appletv,
  Appsignal: Appsignal,
  Appsmith: Appsmith,
  Appstore: Appstore,
  Appveyor: Appveyor,
  Appwrite: Appwrite,
  Aqua: Aqua,
  Aral: Aral,
  Arangodb: Arangodb,
  Arc: Arc,
  Arcgis: Arcgis,
  Archicad: Archicad,
  Archiveofourown: Archiveofourown,
  Archlinux: Archlinux,
  Ardour: Ardour,
  Arduino: Arduino,
  Argo: Argo,
  Argos: Argos,
  Ariakit: Ariakit,
  Arkecosystem: Arkecosystem,
  Arlo: Arlo,
  Arm: Arm,
  Armkeil: Armkeil,
  Arstechnica: Arstechnica,
  Artifacthub: Artifacthub,
  Artixlinux: Artixlinux,
  Artstation: Artstation,
  Arxiv: Arxiv,
  Asahilinux: Asahilinux,
  Asana: Asana,
  Asciidoctor: Asciidoctor,
  Asciinema: Asciinema,
  Asda: Asda,
  Aseprite: Aseprite,
  Askfm: Askfm,
  Assemblyscript: Assemblyscript,
  Astonmartin: Astonmartin,
  Astro: Astro,
  Asus: Asus,
  Atandt: Atandt,
  Atari: Atari,
  Atlassian: Atlassian,
  Auchan: Auchan,
  Audacity: Audacity,
  Audi: Audi,
  Audible: Audible,
  Audioboom: Audioboom,
  Audiomack: Audiomack,
  Audiotechnica: Audiotechnica,
  Aurelia: Aurelia,
  Auth0: AuthZero,
  Authelia: Authelia,
  Authy: Authy,
  Autocad: Autocad,
  Autodesk: Autodesk,
  Autodeskmaya: Autodeskmaya,
  Autodeskrevit: Autodeskrevit,
  Autohotkey: Autohotkey,
  Autoit: Autoit,
  Automattic: Automattic,
  Autoprefixer: Autoprefixer,
  Autozone: Autozone,
  Avajs: Avajs,
  Avast: Avast,
  Avianca: Avianca,
  Avira: Avira,
  Awesomelists: Awesomelists,
  Awesomewm: Awesomewm,
  Awsamplify: Awsamplify,
  Awsfargate: Awsfargate,
  Awslambda: Awslambda,
  Awsorganizations: Awsorganizations,
  Axios: Axios,
  Azureartifacts: Azureartifacts,
  Azuredataexplorer: Azuredataexplorer,
  Azuredevops: Azuredevops,
  Azurefunctions: Azurefunctions,
  Azurepipelines: Azurepipelines,
  Babel: Babel,
  Babelio: Babelio,
  Backblaze: Backblaze,
  Backbonedotjs: Backbonedotjs,
  Backendless: Backendless,
  Backstage: Backstage,
  Badoo: Badoo,
  Baidu: Baidu,
  Bakalari: Bakalari,
  Bamboo: Bamboo,
  Bandcamp: Bandcamp,
  Bandlab: Bandlab,
  Bandrautomation: Bandrautomation,
  Bandsintown: Bandsintown,
  Bankofamerica: Bankofamerica,
  Barclays: Barclays,
  Baremetrics: Baremetrics,
  Barmenia: Barmenia,
  Basecamp: Basecamp,
  Basicattentiontoken: Basicattentiontoken,
  Bastyon: Bastyon,
  Bata: Bata,
  Battledotnet: Battledotnet,
  Bazel: Bazel,
  Beatport: Beatport,
  Beats: Beats,
  Beatsbydre: Beatsbydre,
  Behance: Behance,
  Beijingsubway: Beijingsubway,
  Bem: Bem,
  Bentley: Bentley,
  Bento: Bento,
  Bentobox: Bentobox,
  Bentoml: Bentoml,
  Bereal: Bereal,
  Betfair: Betfair,
  Betterstack: Betterstack,
  Bevy: Bevy,
  Bigbasket: Bigbasket,
  Bigbluebutton: Bigbluebutton,
  Bigcartel: Bigcartel,
  Bigcommerce: Bigcommerce,
  Bilibili: Bilibili,
  Billboard: Billboard,
  Bim: Bim,
  Binance: Binance,
  Biolink: Biolink,
  Biome: Biome,
  Bisecthosting: Bisecthosting,
  Bit: Bit,
  Bitbucket: Bitbucket,
  Bitcoin: Bitcoin,
  Bitcoincash: Bitcoincash,
  Bitcoinsv: Bitcoinsv,
  Bitcomet: Bitcomet,
  Bitdefender: Bitdefender,
  Bitly: Bitly,
  Bitrise: Bitrise,
  Bittorrent: Bittorrent,
  Bitwarden: Bitwarden,
  Bitwig: Bitwig,
  Blackberry: Blackberry,
  Blackmagicdesign: Blackmagicdesign,
  Blazemeter: Blazemeter,
  Blazor: Blazor,
  Blender: Blender,
  Blockbench: Blockbench,
  Blockchaindotcom: Blockchaindotcom,
  Blogger: Blogger,
  Bloglovin: Bloglovin,
  Blueprint: Blueprint,
  Bluesky: Bluesky,
  Bluesound: Bluesound,
  Bluetooth: Bluetooth,
  Bmcsoftware: Bmcsoftware,
  Bmw: Bmw,
  Bnbchain: Bnbchain,
  Boardgamegeek: Boardgamegeek,
  Boat: Boat,
  Boehringeringelheim: Boehringeringelheim,
  Boeing: Boeing,
  Bombardier: Bombardier,
  Bookalope: Bookalope,
  Bookbub: Bookbub,
  Bookmeter: Bookmeter,
  Bookmyshow: Bookmyshow,
  Bookstack: Bookstack,
  Boost: Boost,
  Boosty: Boosty,
  Boots: Boots,
  Bootstrap: Bootstrap,
  Borgbackup: Borgbackup,
  Bosch: Bosch,
  Bose: Bose,
  Botblecms: Botblecms,
  Boulanger: Boulanger,
  Bower: Bower,
  Box: Box,
  Boxysvg: Boxysvg,
  Braintree: Braintree,
  Brandfolder: Brandfolder,
  Brave: Brave,
  Breaker: Breaker,
  Brenntag: Brenntag,
  Brevo: Brevo,
  Brex: Brex,
  Bricks: Bricks,
  Britishairways: Britishairways,
  Broadcom: Broadcom,
  Bsd: Bsd,
  Bspwm: Bspwm,
  Bt: Bt,
  Buddy: Buddy,
  Budibase: Budibase,
  Buefy: Buefy,
  Buffer: Buffer,
  Bugatti: Bugatti,
  Bugcrowd: Bugcrowd,
  Bugsnag: Bugsnag,
  Buildkite: Buildkite,
  Bukalapak: Bukalapak,
  Bulma: Bulma,
  Bun: Bun,
  Bungie: Bungie,
  Bunq: Bunq,
  Burgerking: Burgerking,
  Burpsuite: Burpsuite,
  Burton: Burton,
  Buymeacoffee: Buymeacoffee,
  Buysellads: Buysellads,
  Buzzfeed: Buzzfeed,
  Bvg: Bvg,
  Byjus: Byjus,
  Bytedance: Bytedance,
  C: C,
  Cachet: Cachet,
  Caddy: Caddy,
  Cadillac: Cadillac,
  Cafepress: Cafepress,
  Caffeine: Caffeine,
  Cairographics: Cairographics,
  Cairometro: Cairometro,
  Cakephp: Cakephp,
  Calendly: Calendly,
  Campaignmonitor: Campaignmonitor,
  Camunda: Camunda,
  Canonical: Canonical,
  Canva: Canva,
  Canvas: Canvas,
  Capacitor: Capacitor,
  Cardano: Cardano,
  Carlsberggroup: Carlsberggroup,
  Carrd: Carrd,
  Carrefour: Carrefour,
  Carthrottle: Carthrottle,
  Carto: Carto,
  Cashapp: Cashapp,
  Castbox: Castbox,
  Castorama: Castorama,
  Castro: Castro,
  Caterpillar: Caterpillar,
  Cbc: Cbc,
  Cbs: Cbs,
  Ccleaner: Ccleaner,
  Cdprojekt: Cdprojekt,
  Celery: Celery,
  Celestron: Celestron,
  Centos: Centos,
  Ceph: Ceph,
  Cesium: Cesium,
  Chai: Chai,
  Chainguard: Chainguard,
  Chainlink: Chainlink,
  Chakraui: Chakraui,
  Channel4: ChannelFour,
  Charles: Charles,
  Chartdotjs: Chartdotjs,
  Chartmogul: Chartmogul,
  Chase: Chase,
  Chatbot: Chatbot,
  Chatwoot: Chatwoot,
  Checkio: Checkio,
  Checkmarx: Checkmarx,
  Checkmk: Checkmk,
  Chedraui: Chedraui,
  Cheerio: Cheerio,
  Chef: Chef,
  Chemex: Chemex,
  Chessdotcom: Chessdotcom,
  Chevrolet: Chevrolet,
  Chianetwork: Chianetwork,
  Chinaeasternairlines: Chinaeasternairlines,
  Chinasouthernairlines: Chinasouthernairlines,
  Chocolatey: Chocolatey,
  Chromatic: Chromatic,
  Chromecast: Chromecast,
  Chrysler: Chrysler,
  Chupachups: Chupachups,
  Cilium: Cilium,
  Cinema4d: CinemaFourd,
  Circle: Circle,
  Circleci: Circleci,
  Circuitverse: Circuitverse,
  Cirrusci: Cirrusci,
  Cisco: Cisco,
  Citrix: Citrix,
  Citroen: Citroen,
  Civicrm: Civicrm,
  Civo: Civo,
  Ckeditor4: CkeditorFour,
  Clarifai: Clarifai,
  Claris: Claris,
  Clarivate: Clarivate,
  Clerk: Clerk,
  Clevercloud: Clevercloud,
  Clickhouse: Clickhouse,
  Clickup: Clickup,
  Clion: Clion,
  Cliqz: Cliqz,
  Clockify: Clockify,
  Clojure: Clojure,
  Cloud66: CloudSixSix,
  Cloudbees: Cloudbees,
  Cloudcannon: Cloudcannon,
  Cloudera: Cloudera,
  Cloudflare: Cloudflare,
  Cloudflarepages: Cloudflarepages,
  Cloudflareworkers: Cloudflareworkers,
  Cloudfoundry: Cloudfoundry,
  Cloudinary: Cloudinary,
  Cloudron: Cloudron,
  Cloudsmith: Cloudsmith,
  Cloudways: Cloudways,
  Clubforce: Clubforce,
  Clubhouse: Clubhouse,
  Clyp: Clyp,
  Cmake: Cmake,
  Cncf: Cncf,
  Cnet: Cnet,
  Cnn: Cnn,
  Cocacola: Cocacola,
  Cockpit: Cockpit,
  Cockroachlabs: Cockroachlabs,
  Cocoapods: Cocoapods,
  Cocos: Cocos,
  Coda: Coda,
  Codacy: Codacy,
  Codeberg: Codeberg,
  Codeblocks: Codeblocks,
  Codecademy: Codecademy,
  Codeceptjs: Codeceptjs,
  Codechef: Codechef,
  Codeclimate: Codeclimate,
  Codecov: Codecov,
  Codefactor: Codefactor,
  Codeforces: Codeforces,
  Codefresh: Codefresh,
  Codeigniter: Codeigniter,
  Codeium: Codeium,
  Codemagic: Codemagic,
  Codementor: Codementor,
  Codemirror: Codemirror,
  Codenewbie: Codenewbie,
  Codepen: Codepen,
  Codeproject: Codeproject,
  Codersrank: Codersrank,
  Coderwall: Coderwall,
  Codesandbox: Codesandbox,
  Codeship: Codeship,
  Codesignal: Codesignal,
  Codestream: Codestream,
  Codewars: Codewars,
  Codingame: Codingame,
  Codingninjas: Codingninjas,
  Codio: Codio,
  Coffeescript: Coffeescript,
  Coggle: Coggle,
  Cognizant: Cognizant,
  Coil: Coil,
  Coinbase: Coinbase,
  Coinmarketcap: Coinmarketcap,
  Comicfury: Comicfury,
  Commerzbank: Commerzbank,
  Commitlint: Commitlint,
  Commodore: Commodore,
  Commonworkflowlanguage: Commonworkflowlanguage,
  Compilerexplorer: Compilerexplorer,
  Composer: Composer,
  Comptia: Comptia,
  Comsol: Comsol,
  Conan: Conan,
  Concourse: Concourse,
  Condaforge: Condaforge,
  Conekta: Conekta,
  Confluence: Confluence,
  Construct3: ConstructThree,
  Consul: Consul,
  Contactlesspayment: Contactlesspayment,
  Containerd: Containerd,
  Contao: Contao,
  Contentful: Contentful,
  Contentstack: Contentstack,
  Contributorcovenant: Contributorcovenant,
  Conventionalcommits: Conventionalcommits,
  Convertio: Convertio,
  Cookiecutter: Cookiecutter,
  Coolermaster: Coolermaster,
  Coop: Coop,
  Coppel: Coppel,
  Cora: Cora,
  Coreldraw: Coreldraw,
  Coronaengine: Coronaengine,
  Coronarenderer: Coronarenderer,
  Corsair: Corsair,
  Couchbase: Couchbase,
  Counterstrike: Counterstrike,
  Countingworkspro: Countingworkspro,
  Coursera: Coursera,
  Coveralls: Coveralls,
  Cpanel: Cpanel,
  Cplusplus: Cplusplus,
  Cplusplusbuilder: Cplusplusbuilder,
  Craftcms: Craftcms,
  Craftsman: Craftsman,
  Cratedb: Cratedb,
  Crayon: Crayon,
  Creality: Creality,
  Createreactapp: Createreactapp,
  Creativecommons: Creativecommons,
  Creativetechnology: Creativetechnology,
  Credly: Credly,
  Crehana: Crehana,
  Crewunited: Crewunited,
  Criticalrole: Criticalrole,
  Crowdin: Crowdin,
  Crowdsource: Crowdsource,
  Crunchbase: Crunchbase,
  Crunchyroll: Crunchyroll,
  Cryengine: Cryengine,
  Cryptpad: Cryptpad,
  Crystal: Crystal,
  Csharp: Csharp,
  Css3: CssThree,
  Cssmodules: Cssmodules,
  Csswizardry: Csswizardry,
  Cts: Cts,
  Cucumber: Cucumber,
  Cultura: Cultura,
  Curl: Curl,
  Curseforge: Curseforge,
  Customink: Customink,
  Cyberdefenders: Cyberdefenders,
  Cycling74: CyclingSevenFour,
  Cypress: Cypress,
  Cytoscapedotjs: Cytoscapedotjs,
  D: D,
  D3dotjs: DThreedotjs,
  Dacia: Dacia,
  Daf: Daf,
  Dailydotdev: Dailydotdev,
  Dailymotion: Dailymotion,
  Daimler: Daimler,
  Daisyui: Daisyui,
  Dapr: Dapr,
  Darkreader: Darkreader,
  Dart: Dart,
  Darty: Darty,
  Daserste: Daserste,
  Dash: Dash,
  Dashlane: Dashlane,
  Dask: Dask,
  Dassaultsystemes: Dassaultsystemes,
  Databricks: Databricks,
  Datacamp: Datacamp,
  Datadog: Datadog,
  Datadotai: Datadotai,
  Datagrip: Datagrip,
  Dataiku: Dataiku,
  Datastax: Datastax,
  Dataverse: Dataverse,
  Datefns: Datefns,
  Datocms: Datocms,
  Datto: Datto,
  Davinciresolve: Davinciresolve,
  Dazhongdianping: Dazhongdianping,
  Dazn: Dazn,
  Dbeaver: Dbeaver,
  Dblp: Dblp,
  Dbt: Dbt,
  Dcentertainment: Dcentertainment,
  Debian: Debian,
  Decapcms: Decapcms,
  Decentraland: Decentraland,
  Dedge: Dedge,
  Deepcool: Deepcool,
  Deepgram: Deepgram,
  Deepin: Deepin,
  Deepl: Deepl,
  Deepnote: Deepnote,
  Delicious: Delicious,
  Deliveroo: Deliveroo,
  Dell: Dell,
  Delonghi: Delonghi,
  Delphi: Delphi,
  Delta: Delta,
  Deluge: Deluge,
  Deno: Deno,
  Denon: Denon,
  Dependabot: Dependabot,
  Dependencycheck: Dependencycheck,
  Depositphotos: Depositphotos,
  Derspiegel: Derspiegel,
  Designernews: Designernews,
  Deutschebahn: Deutschebahn,
  Deutschebank: Deutschebank,
  Deutschepost: Deutschepost,
  Devdotto: Devdotto,
  Devexpress: Devexpress,
  Deviantart: Deviantart,
  Devpost: Devpost,
  Devrant: Devrant,
  Dgraph: Dgraph,
  Dhl: Dhl,
  Diagramsdotnet: Diagramsdotnet,
  Dialogflow: Dialogflow,
  Diaspora: Diaspora,
  Digg: Digg,
  Digikeyelectronics: Digikeyelectronics,
  Digitalocean: Digitalocean,
  Dinersclub: Dinersclub,
  Dior: Dior,
  Directus: Directus,
  Discogs: Discogs,
  Discord: Discord,
  Discourse: Discourse,
  Discover: Discover,
  Disqus: Disqus,
  Disroot: Disroot,
  Distrokid: Distrokid,
  Django: Django,
  Dji: Dji,
  Dlib: Dlib,
  Dlna: Dlna,
  Dm: Dm,
  Docker: Docker,
  Docsdotrs: Docsdotrs,
  Docsify: Docsify,
  Docusaurus: Docusaurus,
  Docusign: Docusign,
  Dogecoin: Dogecoin,
  Doi: Doi,
  Dolby: Dolby,
  Doordash: Doordash,
  Dotenv: Dotenv,
  Dotnet: Dotnet,
  Douban: Douban,
  Doubanread: Doubanread,
  Dovecot: Dovecot,
  Doxygen: Doxygen,
  Dpd: Dpd,
  Dragonframe: Dragonframe,
  Draugiemdotlv: Draugiemdotlv,
  Dreamstime: Dreamstime,
  Dribbble: Dribbble,
  Drizzle: Drizzle,
  Drone: Drone,
  Drooble: Drooble,
  Dropbox: Dropbox,
  Drupal: Drupal,
  Dsautomobiles: Dsautomobiles,
  Dts: Dts,
  Dtube: Dtube,
  Ducati: Ducati,
  Duckdb: Duckdb,
  Duckduckgo: Duckduckgo,
  Dungeonsanddragons: Dungeonsanddragons,
  Dunked: Dunked,
  Dunzo: Dunzo,
  Duolingo: Duolingo,
  Dvc: Dvc,
  Dwavesystems: Dwavesystems,
  Dwm: Dwm,
  Dynamics365: DynamicsThreeSixFive,
  Dynatrace: Dynatrace,
  E: E,
  E3: EThree,
  Ea: Ea,
  Eagle: Eagle,
  Easyeda: Easyeda,
  Easyjet: Easyjet,
  Ebay: Ebay,
  Ebox: Ebox,
  Eclipseadoptium: Eclipseadoptium,
  Eclipseche: Eclipseche,
  Eclipseide: Eclipseide,
  Eclipsejetty: Eclipsejetty,
  Eclipsemosquitto: Eclipsemosquitto,
  Eclipsevertdotx: Eclipsevertdotx,
  Ecovacs: Ecovacs,
  Edeka: Edeka,
  Edgeimpulse: Edgeimpulse,
  Editorconfig: Editorconfig,
  Edotleclerc: Edotleclerc,
  Educative: Educative,
  Edx: Edx,
  Egghead: Egghead,
  Egnyte: Egnyte,
  Eight: Eight,
  Eightsleep: Eightsleep,
  Ejs: Ejs,
  Elastic: Elastic,
  Elasticcloud: Elasticcloud,
  Elasticsearch: Elasticsearch,
  Elasticstack: Elasticstack,
  Elavon: Elavon,
  Electron: Electron,
  Electronbuilder: Electronbuilder,
  Electronfiddle: Electronfiddle,
  Elegoo: Elegoo,
  Element: Element,
  Elementary: Elementary,
  Elementor: Elementor,
  Eleventy: Eleventy,
  Elgato: Elgato,
  Elixir: Elixir,
  Eljueves: Eljueves,
  Ello: Ello,
  Elm: Elm,
  Elsevier: Elsevier,
  Embarcadero: Embarcadero,
  Embark: Embark,
  Emberdotjs: Emberdotjs,
  Emby: Emby,
  Emirates: Emirates,
  Emlakjet: Emlakjet,
  Empirekred: Empirekred,
  Endeavouros: Endeavouros,
  Engadget: Engadget,
  Enpass: Enpass,
  Enterprisedb: Enterprisedb,
  Envato: Envato,
  Envoyproxy: Envoyproxy,
  Epel: Epel,
  Epicgames: Epicgames,
  Epson: Epson,
  Equinixmetal: Equinixmetal,
  Ericsson: Ericsson,
  Erlang: Erlang,
  Erpnext: Erpnext,
  Esbuild: Esbuild,
  Esea: Esea,
  Eslgaming: Eslgaming,
  Eslint: Eslint,
  Esotericsoftware: Esotericsoftware,
  Esphome: Esphome,
  Espressif: Espressif,
  Esri: Esri,
  Etcd: Etcd,
  Ethereum: Ethereum,
  Ethiopianairlines: Ethiopianairlines,
  Etihadairways: Etihadairways,
  Etsy: Etsy,
  Eventbrite: Eventbrite,
  Eventstore: Eventstore,
  Evernote: Evernote,
  Excalidraw: Excalidraw,
  Exercism: Exercism,
  Exordo: Exordo,
  Exoscale: Exoscale,
  Expedia: Expedia,
  Expensify: Expensify,
  Expertsexchange: Expertsexchange,
  Expo: Expo,
  Express: Express,
  Expressvpn: Expressvpn,
  Eyeem: Eyeem,
  F1: FOne,
  F5: FFive,
  Facebook: Facebook,
  Facebookgaming: Facebookgaming,
  Facebooklive: Facebooklive,
  Faceit: Faceit,
  Facepunch: Facepunch,
  Fairphone: Fairphone,
  Falco: Falco,
  Falcon: Falcon,
  Fampay: Fampay,
  Fandango: Fandango,
  Fandom: Fandom,
  Fanfou: Fanfou,
  Fantom: Fantom,
  Farcaster: Farcaster,
  Fareharbor: Fareharbor,
  Farfetch: Farfetch,
  Fastapi: Fastapi,
  Fastify: Fastify,
  Fastlane: Fastlane,
  Fastly: Fastly,
  Fathom: Fathom,
  Fauna: Fauna,
  Favro: Favro,
  Fdroid: Fdroid,
  Feathub: Feathub,
  Fedex: Fedex,
  Fedora: Fedora,
  Feedly: Feedly,
  Ferrari: Ferrari,
  Ferrarinv: Ferrarinv,
  Ferretdb: Ferretdb,
  Ffmpeg: Ffmpeg,
  Fi: Fi,
  Fiat: Fiat,
  Fidoalliance: Fidoalliance,
  Fifa: Fifa,
  Fig: Fig,
  Figma: Figma,
  Figshare: Figshare,
  Fila: Fila,
  Filedotio: Filedotio,
  Files: Files,
  Filezilla: Filezilla,
  Fineco: Fineco,
  Fing: Fing,
  Firebase: Firebase,
  Firefish: Firefish,
  Fireflyiii: Fireflyiii,
  Firefox: Firefox,
  Firefoxbrowser: Firefoxbrowser,
  Fireship: Fireship,
  Firewalla: Firewalla,
  First: First,
  Fishshell: Fishshell,
  Fitbit: Fitbit,
  Fite: Fite,
  Fivem: Fivem,
  Fiverr: Fiverr,
  Flashforge: Flashforge,
  Flask: Flask,
  Flat: Flat,
  Flathub: Flathub,
  Flatpak: Flatpak,
  Flattr: Flattr,
  Flickr: Flickr,
  Flightaware: Flightaware,
  Flipboard: Flipboard,
  Flipkart: Flipkart,
  Floatplane: Floatplane,
  Flood: Flood,
  Fluentbit: Fluentbit,
  Fluentd: Fluentd,
  Fluke: Fluke,
  Flutter: Flutter,
  Flux: Flux,
  Fluxus: Fluxus,
  Flyway: Flyway,
  Fmod: Fmod,
  Fnac: Fnac,
  Folium: Folium,
  Fonoma: Fonoma,
  Fontawesome: Fontawesome,
  Fontbase: Fontbase,
  Fontforge: Fontforge,
  Foobar2000: FoobarTwoZeroZeroZero,
  Foodpanda: Foodpanda,
  Ford: Ford,
  Forgejo: Forgejo,
  Formspree: Formspree,
  Formstack: Formstack,
  Fortinet: Fortinet,
  Fortran: Fortran,
  Fossa: Fossa,
  Fossilscm: Fossilscm,
  Foursquare: Foursquare,
  Foursquarecityguide: Foursquarecityguide,
  Fox: Fox,
  Foxtel: Foxtel,
  Fozzy: Fozzy,
  Framer: Framer,
  Framework: Framework,
  Framework7: FrameworkSeven,
  Franprix: Franprix,
  Frappe: Frappe,
  Fraunhofergesellschaft: Fraunhofergesellschaft,
  Freebsd: Freebsd,
  Freecodecamp: Freecodecamp,
  Freedesktopdotorg: Freedesktopdotorg,
  Freelancer: Freelancer,
  Freenas: Freenas,
  Freepik: Freepik,
  Frontendmentor: Frontendmentor,
  Frontify: Frontify,
  Fsecure: Fsecure,
  Fsharp: Fsharp,
  Fubo: Fubo,
  Fueler: Fueler,
  Fugacloud: Fugacloud,
  Fujifilm: Fujifilm,
  Fujitsu: Fujitsu,
  Funimation: Funimation,
  Furaffinity: Furaffinity,
  Furrynetwork: Furrynetwork,
  Futurelearn: Futurelearn,
  G2: GTwo,
  G2a: GTwoa,
  G2g: GTwog,
  Galaxus: Galaxus,
  Gameandwatch: Gameandwatch,
  Gamebanana: Gamebanana,
  Gamedeveloper: Gamedeveloper,
  Gamejolt: Gamejolt,
  Gameloft: Gameloft,
  Gamemaker: Gamemaker,
  Garmin: Garmin,
  Gatling: Gatling,
  Gatsby: Gatsby,
  Gdal: Gdal,
  Geant: Geant,
  Geeksforgeeks: Geeksforgeeks,
  Generalelectric: Generalelectric,
  Generalmotors: Generalmotors,
  Genius: Genius,
  Gentoo: Gentoo,
  Geocaching: Geocaching,
  Geopandas: Geopandas,
  Gerrit: Gerrit,
  Getx: Getx,
  Ghost: Ghost,
  Ghostery: Ghostery,
  Gimp: Gimp,
  Gin: Gin,
  Giphy: Giphy,
  Git: Git,
  Gitbook: Gitbook,
  Gitconnected: Gitconnected,
  Gitea: Gitea,
  Gitee: Gitee,
  Gitextensions: Gitextensions,
  Github: Github,
  Githubactions: Githubactions,
  Githubcopilot: Githubcopilot,
  Githubpages: Githubpages,
  Githubsponsors: Githubsponsors,
  Gitignoredotio: Gitignoredotio,
  Gitkraken: Gitkraken,
  Gitlab: Gitlab,
  Gitlfs: Gitlfs,
  Gitpod: Gitpod,
  Gitter: Gitter,
  Glassdoor: Glassdoor,
  Glide: Glide,
  Glitch: Glitch,
  Globus: Globus,
  Glovo: Glovo,
  Gmail: Gmail,
  Gnome: Gnome,
  Gnometerminal: Gnometerminal,
  Gnu: Gnu,
  Gnubash: Gnubash,
  Gnuemacs: Gnuemacs,
  Gnuicecat: Gnuicecat,
  Gnuprivacyguard: Gnuprivacyguard,
  Gnusocial: Gnusocial,
  Go: Go,
  Gocd: Gocd,
  Godaddy: Godaddy,
  Godotengine: Godotengine,
  Gofundme: Gofundme,
  Gogdotcom: Gogdotcom,
  Gojek: Gojek,
  Goland: Goland,
  Goldenline: Goldenline,
  Goldmansachs: Goldmansachs,
  Goodreads: Goodreads,
  Google: Google,
  Googleadmob: Googleadmob,
  Googleads: Googleads,
  Googleadsense: Googleadsense,
  Googleanalytics: Googleanalytics,
  Googleappsscript: Googleappsscript,
  Googleassistant: Googleassistant,
  Googleauthenticator: Googleauthenticator,
  Googlebard: Googlebard,
  Googlebigquery: Googlebigquery,
  Googlebigtable: Googlebigtable,
  Googlecalendar: Googlecalendar,
  Googlecampaignmanager360: GooglecampaignmanagerThreeSixZero,
  Googlecardboard: Googlecardboard,
  Googlechat: Googlechat,
  Googlechrome: Googlechrome,
  Googlechronicle: Googlechronicle,
  Googleclassroom: Googleclassroom,
  Googlecloud: Googlecloud,
  Googlecloudcomposer: Googlecloudcomposer,
  Googlecloudspanner: Googlecloudspanner,
  Googlecloudstorage: Googlecloudstorage,
  Googlecolab: Googlecolab,
  Googlecontaineroptimizedos: Googlecontaineroptimizedos,
  Googledataflow: Googledataflow,
  Googledataproc: Googledataproc,
  Googledatastudio: Googledatastudio,
  Googledisplayandvideo360: GoogledisplayandvideoThreeSixZero,
  Googledocs: Googledocs,
  Googledomains: Googledomains,
  Googledrive: Googledrive,
  Googleearth: Googleearth,
  Googleearthengine: Googleearthengine,
  Googlefit: Googlefit,
  Googlefonts: Googlefonts,
  Googleforms: Googleforms,
  Googlegemini: Googlegemini,
  Googlehangouts: Googlehangouts,
  Googlehome: Googlehome,
  Googlekeep: Googlekeep,
  Googlelens: Googlelens,
  Googlemaps: Googlemaps,
  Googlemarketingplatform: Googlemarketingplatform,
  Googlemeet: Googlemeet,
  Googlemessages: Googlemessages,
  Googlemybusiness: Googlemybusiness,
  Googlenearby: Googlenearby,
  Googlenews: Googlenews,
  Googleoptimize: Googleoptimize,
  Googlepay: Googlepay,
  Googlephotos: Googlephotos,
  Googleplay: Googleplay,
  Googlepodcasts: Googlepodcasts,
  Googlepubsub: Googlepubsub,
  Googlescholar: Googlescholar,
  Googlesearchconsole: Googlesearchconsole,
  Googlesheets: Googlesheets,
  Googleslides: Googleslides,
  Googlestreetview: Googlestreetview,
  Googletagmanager: Googletagmanager,
  Googletasks: Googletasks,
  Googletranslate: Googletranslate,
  Gotomeeting: Gotomeeting,
  Grab: Grab,
  Gradle: Gradle,
  Gradleplaypublisher: Gradleplaypublisher,
  Grafana: Grafana,
  Grammarly: Grammarly,
  Grandfrais: Grandfrais,
  Grapheneos: Grapheneos,
  Graphite: Graphite,
  Graphql: Graphql,
  Grav: Grav,
  Gravatar: Gravatar,
  Graylog: Graylog,
  Greasyfork: Greasyfork,
  Greatlearning: Greatlearning,
  Greenhouse: Greenhouse,
  Greensock: Greensock,
  Griddotai: Griddotai,
  Gridsome: Gridsome,
  Groupme: Groupme,
  Groupon: Groupon,
  Grubhub: Grubhub,
  Grunt: Grunt,
  Gsk: Gsk,
  Gsmarenadotcom: Gsmarenadotcom,
  Gstreamer: Gstreamer,
  Gtk: Gtk,
  Guangzhoumetro: Guangzhoumetro,
  Guilded: Guilded,
  Gulp: Gulp,
  Gumroad: Gumroad,
  Gumtree: Gumtree,
  Gunicorn: Gunicorn,
  Gurobi: Gurobi,
  Gutenberg: Gutenberg,
  H3: HThree,
  Habr: Habr,
  Hackaday: Hackaday,
  Hackclub: Hackclub,
  Hackerearth: Hackerearth,
  Hackernoon: Hackernoon,
  Hackerone: Hackerone,
  Hackerrank: Hackerrank,
  Hackster: Hackster,
  Hackthebox: Hackthebox,
  Hal: Hal,
  Handlebarsdotjs: Handlebarsdotjs,
  Handm: Handm,
  Handshake: Handshake,
  HandshakeProtocol: HandshakeProtocol,
  Happycow: Happycow,
  Harbor: Harbor,
  Harmonyos: Harmonyos,
  Hashicorp: Hashicorp,
  Hashnode: Hashnode,
  Haskell: Haskell,
  Hasura: Hasura,
  Hatenabookmark: Hatenabookmark,
  Haveibeenpwned: Haveibeenpwned,
  Haxe: Haxe,
  Hbo: Hbo,
  Hcl: Hcl,
  Hdfcbank: Hdfcbank,
  Headlessui: Headlessui,
  Headphonezone: Headphonezone,
  Headspace: Headspace,
  Hearth: Hearth,
  Hearthisdotat: Hearthisdotat,
  Hedera: Hedera,
  Helium: Helium,
  Hellofresh: Hellofresh,
  Hellyhansen: Hellyhansen,
  Helm: Helm,
  Helpdesk: Helpdesk,
  Helpscout: Helpscout,
  Hepsiemlak: Hepsiemlak,
  Here: Here,
  Heroku: Heroku,
  Hetzner: Hetzner,
  Hevy: Hevy,
  Hexlet: Hexlet,
  Hexo: Hexo,
  Hey: Hey,
  Hibernate: Hibernate,
  Hibob: Hibob,
  Hilton: Hilton,
  Hiltonhotelsandresorts: Hiltonhotelsandresorts,
  Hitachi: Hitachi,
  Hive: Hive,
  HiveBlockchain: HiveBlockchain,
  Hivemq: Hivemq,
  Homeadvisor: Homeadvisor,
  Homeassistant: Homeassistant,
  Homeassistantcommunitystore: Homeassistantcommunitystore,
  Homebrew: Homebrew,
  Homebridge: Homebridge,
  Homify: Homify,
  Honda: Honda,
  Honey: Honey,
  Hono: Hono,
  Honor: Honor,
  Hootsuite: Hootsuite,
  Hoppscotch: Hoppscotch,
  Hostinger: Hostinger,
  Hotelsdotcom: Hotelsdotcom,
  Hotjar: Hotjar,
  Hotwire: Hotwire,
  Houdini: Houdini,
  Houzz: Houzz,
  Hp: Hp,
  Hsbc: Hsbc,
  Html5: HtmlFive,
  Htmlacademy: Htmlacademy,
  Htmx: Htmx,
  Htop: Htop,
  Httpie: Httpie,
  Huawei: Huawei,
  Hubspot: Hubspot,
  Hugo: Hugo,
  Humblebundle: Humblebundle,
  Hungryjacks: Hungryjacks,
  Husqvarna: Husqvarna,
  Hyper: Hyper,
  Hyperskill: Hyperskill,
  Hypothesis: Hypothesis,
  Hyundai: Hyundai,
  I18next: IOneEightnext,
  I3: IThree,
  Iata: Iata,
  Ibeacon: Ibeacon,
  Iberia: Iberia,
  Ibm: Ibm,
  Ibmcloud: Ibmcloud,
  Ibmwatson: Ibmwatson,
  Iced: Iced,
  Iceland: Iceland,
  Icicibank: Icicibank,
  Icinga: Icinga,
  Icloud: Icloud,
  Icomoon: Icomoon,
  Icon: Icon,
  Iconfinder: Iconfinder,
  Iconify: Iconify,
  Iconjar: Iconjar,
  Icons8: IconsEight,
  Icq: Icq,
  Ieee: Ieee,
  Ifixit: Ifixit,
  Ifood: Ifood,
  Ifttt: Ifttt,
  Igdb: Igdb,
  Ign: Ign,
  Iheartradio: Iheartradio,
  Ikea: Ikea,
  Iledefrancemobilites: Iledefrancemobilites,
  Imagedotsc: Imagedotsc,
  Imagej: Imagej,
  Imdb: Imdb,
  Imessage: Imessage,
  Imgur: Imgur,
  Immer: Immer,
  Immich: Immich,
  Imou: Imou,
  Improvmx: Improvmx,
  Indeed: Indeed,
  Indiehackers: Indiehackers,
  Indigo: Indigo,
  Inertia: Inertia,
  Infiniti: Infiniti,
  Influxdb: Influxdb,
  Infoq: Infoq,
  Informatica: Informatica,
  Infosys: Infosys,
  Infracost: Infracost,
  Ingress: Ingress,
  Inkdrop: Inkdrop,
  Inkscape: Inkscape,
  Inoreader: Inoreader,
  Insomnia: Insomnia,
  Inspire: Inspire,
  Insta360: InstaThreeSixZero,
  Instacart: Instacart,
  Instagram: Instagram,
  Instapaper: Instapaper,
  Instatus: Instatus,
  Instructables: Instructables,
  Instructure: Instructure,
  Integromat: Integromat,
  Intel: Intel,
  Intellijidea: Intellijidea,
  Interactiondesignfoundation: Interactiondesignfoundation,
  Interactjs: Interactjs,
  Interbase: Interbase,
  Intercom: Intercom,
  Intermarche: Intermarche,
  Internetarchive: Internetarchive,
  Internetcomputer: Internetcomputer,
  Internetexplorer: Internetexplorer,
  Intigriti: Intigriti,
  Intuit: Intuit,
  Invision: Invision,
  Invoiceninja: Invoiceninja,
  Iobroker: Iobroker,
  Ionic: Ionic,
  Ionos: Ionos,
  Ios: Ios,
  Iota: Iota,
  Ipfs: Ipfs,
  Iris: Iris,
  Irobot: Irobot,
  Isc2: IscTwo,
  Issuu: Issuu,
  Istio: Istio,
  Itchdotio: Itchdotio,
  Iterm2: ItermTwo,
  Itunes: Itunes,
  Itvx: Itvx,
  Iveco: Iveco,
  Jabber: Jabber,
  Jaeger: Jaeger,
  Jaguar: Jaguar,
  Jamboard: Jamboard,
  Jameson: Jameson,
  Jamstack: Jamstack,
  Jasmine: Jasmine,
  Javascript: Javascript,
  Jbl: Jbl,
  Jcb: Jcb,
  Jeep: Jeep,
  Jekyll: Jekyll,
  Jellyfin: Jellyfin,
  Jenkins: Jenkins,
  Jest: Jest,
  Jet: Jet,
  Jetblue: Jetblue,
  Jetbrains: Jetbrains,
  Jetpackcompose: Jetpackcompose,
  Jfrog: Jfrog,
  Jfrogpipelines: Jfrogpipelines,
  Jinja: Jinja,
  Jira: Jira,
  Jirasoftware: Jirasoftware,
  Jitpack: Jitpack,
  Jitsi: Jitsi,
  Johndeere: Johndeere,
  Joomla: Joomla,
  Joplin: Joplin,
  Jordan: Jordan,
  Jouav: Jouav,
  Jovian: Jovian,
  Jpeg: Jpeg,
  Jquery: Jquery,
  Jrgroup: Jrgroup,
  Jsdelivr: Jsdelivr,
  Jsfiddle: Jsfiddle,
  Json: Json,
  Jsonwebtokens: Jsonwebtokens,
  Jss: Jss,
  Juce: Juce,
  Juejin: Juejin,
  Juke: Juke,
  Julia: Julia,
  Junipernetworks: Junipernetworks,
  Junit5: JunitFive,
  Jupyter: Jupyter,
  Justeat: Justeat,
  Justgiving: Justgiving,
  K3s: KThrees,
  K6: KSix,
  Kaggle: Kaggle,
  Kahoot: Kahoot,
  Kaios: Kaios,
  Kakao: Kakao,
  Kakaotalk: Kakaotalk,
  Kalilinux: Kalilinux,
  Kamailio: Kamailio,
  Kaniko: Kaniko,
  Karlsruherverkehrsverbund: Karlsruherverkehrsverbund,
  Kasasmart: Kasasmart,
  Kashflow: Kashflow,
  Kaspersky: Kaspersky,
  Katacoda: Katacoda,
  Katana: Katana,
  Kaufland: Kaufland,
  Kde: Kde,
  Kdenlive: Kdenlive,
  Kedro: Kedro,
  Keenetic: Keenetic,
  Keepachangelog: Keepachangelog,
  Keepassxc: Keepassxc,
  Kentico: Kentico,
  Keras: Keras,
  Keybase: Keybase,
  Keycdn: Keycdn,
  Keystone: Keystone,
  Kfc: Kfc,
  Khanacademy: Khanacademy,
  Khronosgroup: Khronosgroup,
  Kia: Kia,
  Kibana: Kibana,
  Kicad: Kicad,
  Kick: Kick,
  Kickstarter: Kickstarter,
  Kik: Kik,
  Kingstontechnology: Kingstontechnology,
  Kinopoisk: Kinopoisk,
  Kinsta: Kinsta,
  Kirby: Kirby,
  Kit: Kit,
  Kitsu: Kitsu,
  Klarna: Klarna,
  Klm: Klm,
  Klook: Klook,
  Knative: Knative,
  Knexdotjs: Knexdotjs,
  Knime: Knime,
  Knowledgebase: Knowledgebase,
  Known: Known,
  Koa: Koa,
  Koc: Koc,
  Kodak: Kodak,
  Kodi: Kodi,
  Kofax: Kofax,
  Kofi: Kofi,
  Komoot: Komoot,
  Konami: Konami,
  Kong: Kong,
  Kongregate: Kongregate,
  Konva: Konva,
  Kotlin: Kotlin,
  Koyeb: Koyeb,
  Krita: Krita,
  Ktm: Ktm,
  Kuaishou: Kuaishou,
  Kubernetes: Kubernetes,
  Kubuntu: Kubuntu,
  Kucoin: Kucoin,
  Kuma: Kuma,
  Kununu: Kununu,
  Kuula: Kuula,
  Kx: Kx,
  Kyocera: Kyocera,
  Labview: Labview,
  Lada: Lada,
  Lamborghini: Lamborghini,
  Landrover: Landrover,
  Lapce: Lapce,
  Laragon: Laragon,
  Laravel: Laravel,
  Laravelhorizon: Laravelhorizon,
  Laravelnova: Laravelnova,
  Lastdotfm: Lastdotfm,
  Lastpass: Lastpass,
  Latex: Latex,
  Launchpad: Launchpad,
  Lazarus: Lazarus,
  Lbry: Lbry,
  Leaderprice: Leaderprice,
  Leaflet: Leaflet,
  Leagueoflegends: Leagueoflegends,
  Leanpub: Leanpub,
  Leetcode: Leetcode,
  Legacygames: Legacygames,
  Leica: Leica,
  Lemmy: Lemmy,
  Lenovo: Lenovo,
  Lens: Lens,
  Leptos: Leptos,
  Lequipe: Lequipe,
  Lerna: Lerna,
  Leroymerlin: Leroymerlin,
  Leslibraires: Leslibraires,
  Less: Less,
  Letsencrypt: Letsencrypt,
  Letterboxd: Letterboxd,
  Levelsdotfyi: Levelsdotfyi,
  Lg: Lg,
  Liberadotchat: Liberadotchat,
  Liberapay: Liberapay,
  Librariesdotio: Librariesdotio,
  Librarything: Librarything,
  Libreoffice: Libreoffice,
  Libreofficebase: Libreofficebase,
  Libreofficecalc: Libreofficecalc,
  Libreofficedraw: Libreofficedraw,
  Libreofficeimpress: Libreofficeimpress,
  Libreofficemath: Libreofficemath,
  Libreofficewriter: Libreofficewriter,
  Libuv: Libuv,
  Lichess: Lichess,
  Lidl: Lidl,
  Lifx: Lifx,
  Lightburn: Lightburn,
  Lighthouse: Lighthouse,
  Lightning: Lightning,
  Line: Line,
  Lineageos: Lineageos,
  Linear: Linear,
  Lining: Lining,
  Linkedin: Linkedin,
  Linkerd: Linkerd,
  Linkfire: Linkfire,
  Linksys: Linksys,
  Linktree: Linktree,
  Lintcode: Lintcode,
  Linux: Linux,
  Linuxcontainers: Linuxcontainers,
  Linuxfoundation: Linuxfoundation,
  Linuxmint: Linuxmint,
  Linuxprofessionalinstitute: Linuxprofessionalinstitute,
  Linuxserver: Linuxserver,
  Lionair: Lionair,
  Liquibase: Liquibase,
  Lit: Lit,
  Litecoin: Litecoin,
  Litiengine: Litiengine,
  Livechat: Livechat,
  Livejournal: Livejournal,
  Livewire: Livewire,
  Llvm: Llvm,
  Lmms: Lmms,
  Local: Local,
  Lodash: Lodash,
  Logitech: Logitech,
  Logitechg: Logitechg,
  Logmein: Logmein,
  Logseq: Logseq,
  Logstash: Logstash,
  Looker: Looker,
  Loom: Loom,
  Loop: Loop,
  Loopback: Loopback,
  Lootcrate: Lootcrate,
  Lospec: Lospec,
  Lotpolishairlines: Lotpolishairlines,
  Ltspice: Ltspice,
  Lua: Lua,
  Lubuntu: Lubuntu,
  Lucia: Lucia,
  Lucid: Lucid,
  Ludwig: Ludwig,
  Lufthansa: Lufthansa,
  Lumen: Lumen,
  Lunacy: Lunacy,
  Lutris: Lutris,
  Lydia: Lydia,
  Lyft: Lyft,
  Maas: Maas,
  Macos: Macos,
  Macpaw: Macpaw,
  Macys: Macys,
  Magasinsu: Magasinsu,
  Magento: Magento,
  Magic: Magic,
  Magisk: Magisk,
  Mahindra: Mahindra,
  Mailchimp: Mailchimp,
  Maildotcom: Maildotcom,
  Maildotru: Maildotru,
  Mailgun: Mailgun,
  Mailtrap: Mailtrap,
  Majorleaguehacking: Majorleaguehacking,
  Make: Make,
  Makerbot: Makerbot,
  Malt: Malt,
  Mambaui: Mambaui,
  Mamp: Mamp,
  Man: Man,
  Manageiq: Manageiq,
  Manjaro: Manjaro,
  Mantine: Mantine,
  Mapbox: Mapbox,
  Maplibre: Maplibre,
  Mariadb: Mariadb,
  Mariadbfoundation: Mariadbfoundation,
  Markdown: Markdown,
  Marketo: Marketo,
  Marko: Marko,
  Marriott: Marriott,
  Marvelapp: Marvelapp,
  Maserati: Maserati,
  Mastercard: Mastercard,
  Mastercomfig: Mastercomfig,
  Mastodon: Mastodon,
  Materialdesign: Materialdesign,
  Materialdesignicons: Materialdesignicons,
  Materialformkdocs: Materialformkdocs,
  Matillion: Matillion,
  Matomo: Matomo,
  Matrix: Matrix,
  Matterdotjs: Matterdotjs,
  Mattermost: Mattermost,
  Matternet: Matternet,
  Mautic: Mautic,
  Max: Max,
  Maxplanckgesellschaft: Maxplanckgesellschaft,
  Maytag: Maytag,
  Mazda: Mazda,
  Mcafee: Mcafee,
  Mcdonalds: Mcdonalds,
  Mclaren: Mclaren,
  Mdbook: Mdbook,
  Mdnwebdocs: Mdnwebdocs,
  Mdx: Mdx,
  Mediafire: Mediafire,
  Mediamarkt: Mediamarkt,
  Mediatek: Mediatek,
  Medibangpaint: Medibangpaint,
  Medium: Medium,
  Medusa: Medusa,
  Meetup: Meetup,
  Mega: Mega,
  Meilisearch: Meilisearch,
  Meituan: Meituan,
  Mendeley: Mendeley,
  Mentorcruise: Mentorcruise,
  Mercadopago: Mercadopago,
  Mercedes: Mercedes,
  Merck: Merck,
  Mercurial: Mercurial,
  Mermaid: Mermaid,
  Messenger: Messenger,
  Meta: Meta,
  Metabase: Metabase,
  Metacritic: Metacritic,
  Metafilter: Metafilter,
  Metasploit: Metasploit,
  Meteor: Meteor,
  Metro: Metro,
  Metrodelaciudaddemexico: Metrodelaciudaddemexico,
  Metrodemadrid: Metrodemadrid,
  Metrodeparis: Metrodeparis,
  Mewe: Mewe,
  Mg: Mg,
  Microbit: Microbit,
  Microdotblog: Microdotblog,
  Microeditor: Microeditor,
  Microgenetics: Microgenetics,
  Micropython: Micropython,
  Microsoft: Microsoft,
  Microsoftacademic: Microsoftacademic,
  Microsoftaccess: Microsoftaccess,
  Microsoftazure: Microsoftazure,
  Microsoftbing: Microsoftbing,
  Microsoftedge: Microsoftedge,
  Microsoftexcel: Microsoftexcel,
  Microsoftexchange: Microsoftexchange,
  Microsoftonedrive: Microsoftonedrive,
  Microsoftonenote: Microsoftonenote,
  Microsoftoutlook: Microsoftoutlook,
  Microsoftpowerpoint: Microsoftpowerpoint,
  Microsoftsharepoint: Microsoftsharepoint,
  Microsoftsqlserver: Microsoftsqlserver,
  Microsoftstore: Microsoftstore,
  Microsoftteams: Microsoftteams,
  Microsofttranslator: Microsofttranslator,
  Microsoftvisio: Microsoftvisio,
  Microsoftword: Microsoftword,
  Microstation: Microstation,
  Microstrategy: Microstrategy,
  Midi: Midi,
  Migadu: Migadu,
  Mikrotik: Mikrotik,
  Milanote: Milanote,
  Milvus: Milvus,
  Minds: Minds,
  Minecraft: Minecraft,
  Minetest: Minetest,
  Mini: Mini,
  Minio: Minio,
  Minutemailer: Minutemailer,
  Miraheze: Miraheze,
  Miro: Miro,
  Misskey: Misskey,
  Mitsubishi: Mitsubishi,
  Mix: Mix,
  Mixcloud: Mixcloud,
  Mixpanel: Mixpanel,
  Mlb: Mlb,
  Mlflow: Mlflow,
  Mobx: Mobx,
  Mobxstatetree: Mobxstatetree,
  Mocha: Mocha,
  Mockserviceworker: Mockserviceworker,
  Modin: Modin,
  Modrinth: Modrinth,
  Modx: Modx,
  Mojangstudios: Mojangstudios,
  Moleculer: Moleculer,
  Momenteo: Momenteo,
  Monero: Monero,
  Moneygram: Moneygram,
  Mongodb: Mongodb,
  Mongoose: Mongoose,
  Mongoosedotws: Mongoosedotws,
  Monica: Monica,
  Monkeytie: Monkeytie,
  Monkeytype: Monkeytype,
  Monogame: Monogame,
  Monoprix: Monoprix,
  Monster: Monster,
  Monzo: Monzo,
  Moo: Moo,
  Moodle: Moodle,
  Moonrepo: Moonrepo,
  Moqups: Moqups,
  Morrisons: Morrisons,
  Moscowmetro: Moscowmetro,
  Motorola: Motorola,
  Mozilla: Mozilla,
  Mpv: Mpv,
  Mqtt: Mqtt,
  Msi: Msi,
  Msibusiness: Msibusiness,
  Mta: Mta,
  Mtr: Mtr,
  Mubi: Mubi,
  Mui: Mui,
  Mulesoft: Mulesoft,
  Muller: Muller,
  Multisim: Multisim,
  Mumble: Mumble,
  Muo: Muo,
  Mural: Mural,
  Musescore: Musescore,
  Musicbrainz: Musicbrainz,
  Mxlinux: Mxlinux,
  Myanimelist: Myanimelist,
  Myget: Myget,
  Myob: Myob,
  Myspace: Myspace,
  Mysql: Mysql,
  N26: NTwoSix,
  Namebase: Namebase,
  Namecheap: Namecheap,
  Namemc: Namemc,
  Namesilo: Namesilo,
  Namuwiki: Namuwiki,
  Nano: Nano,
  Nasa: Nasa,
  Nationalgrid: Nationalgrid,
  Nationalrail: Nationalrail,
  Nativescript: Nativescript,
  Natsdotio: Natsdotio,
  Naver: Naver,
  Nba: Nba,
  Nbb: Nbb,
  Nbc: Nbc,
  Ndr: Ndr,
  Near: Near,
  Nec: Nec,
  Neo4j: NeoFourj,
  Neovim: Neovim,
  Neptune: Neptune,
  Nestjs: Nestjs,
  Netapp: Netapp,
  Netbsd: Netbsd,
  Netcup: Netcup,
  Netdata: Netdata,
  Neteasecloudmusic: Neteasecloudmusic,
  Netflix: Netflix,
  Netgear: Netgear,
  Netlify: Netlify,
  Nette: Nette,
  Netto: Netto,
  Neutralinojs: Neutralinojs,
  Newbalance: Newbalance,
  Newegg: Newegg,
  Newjapanprowrestling: Newjapanprowrestling,
  Newrelic: Newrelic,
  Newyorktimes: Newyorktimes,
  Nextbilliondotai: Nextbilliondotai,
  Nextcloud: Nextcloud,
  Nextdns: Nextdns,
  Nextdoor: Nextdoor,
  Nextdotjs: Nextdotjs,
  Nextra: Nextra,
  Nextui: Nextui,
  Nexusmods: Nexusmods,
  Nfc: Nfc,
  Nginx: Nginx,
  Nginxproxymanager: Nginxproxymanager,
  Ngrok: Ngrok,
  Ngrx: Ngrx,
  Nhl: Nhl,
  Niantic: Niantic,
  Niconico: Niconico,
  Nike: Nike,
  Nikon: Nikon,
  Nim: Nim,
  Nintendo: Nintendo,
  Nintendo3ds: NintendoThreeds,
  Nintendogamecube: Nintendogamecube,
  Nintendonetwork: Nintendonetwork,
  Nintendoswitch: Nintendoswitch,
  Nissan: Nissan,
  Nixos: Nixos,
  Nodedotjs: Nodedotjs,
  Nodemon: Nodemon,
  Nodered: Nodered,
  Nokia: Nokia,
  Nomad: Nomad,
  Norco: Norco,
  Nordicsemiconductor: Nordicsemiconductor,
  Nordvpn: Nordvpn,
  Normalizedotcss: Normalizedotcss,
  Norton: Norton,
  Norwegian: Norwegian,
  Notepadplusplus: Notepadplusplus,
  Notion: Notion,
  Notist: Notist,
  Nounproject: Nounproject,
  Novu: Novu,
  Now: Now,
  Npm: Npm,
  Nrwl: Nrwl,
  Nsis: Nsis,
  Nubank: Nubank,
  Nucleo: Nucleo,
  Nuget: Nuget,
  Nuke: Nuke,
  Numba: Numba,
  Numpy: Numpy,
  Nunjucks: Nunjucks,
  Nushell: Nushell,
  Nutanix: Nutanix,
  Nuxtdotjs: Nuxtdotjs,
  Nvidia: Nvidia,
  Nx: Nx,
  Nxp: Nxp,
  Nzxt: Nzxt,
  Observable: Observable,
  Obsidian: Obsidian,
  Obsstudio: Obsstudio,
  Ocaml: Ocaml,
  Oclif: Oclif,
  Octanerender: Octanerender,
  Octave: Octave,
  Octobercms: Octobercms,
  Octoprint: Octoprint,
  Octopusdeploy: Octopusdeploy,
  Oculus: Oculus,
  Odnoklassniki: Odnoklassniki,
  Odoo: Odoo,
  Odysee: Odysee,
  Ohdear: Ohdear,
  Okcupid: Okcupid,
  Okta: Okta,
  Oneplus: Oneplus,
  Onlyfans: Onlyfans,
  Onlyoffice: Onlyoffice,
  Onnx: Onnx,
  Onstar: Onstar,
  Opel: Opel,
  Openaccess: Openaccess,
  Openai: Openai,
  Openaigym: Openaigym,
  Openapiinitiative: Openapiinitiative,
  Openbadges: Openbadges,
  Openbsd: Openbsd,
  Openbugbounty: Openbugbounty,
  Opencollective: Opencollective,
  Opencontainersinitiative: Opencontainersinitiative,
  Opencv: Opencv,
  Openfaas: Openfaas,
  Opengl: Opengl,
  Openhab: Openhab,
  Openid: Openid,
  Openjdk: Openjdk,
  Openlayers: Openlayers,
  Openmined: Openmined,
  Opennebula: Opennebula,
  Openproject: Openproject,
  Openscad: Openscad,
  Opensea: Opensea,
  Opensearch: Opensearch,
  Opensourcehardware: Opensourcehardware,
  Opensourceinitiative: Opensourceinitiative,
  Openssl: Openssl,
  Openstack: Openstack,
  Openstreetmap: Openstreetmap,
  Opensuse: Opensuse,
  Opentelemetry: Opentelemetry,
  Opentext: Opentext,
  Opentofu: Opentofu,
  Openverse: Openverse,
  Openvpn: Openvpn,
  Openwrt: Openwrt,
  Openzeppelin: Openzeppelin,
  Openzfs: Openzfs,
  Opera: Opera,
  Operagx: Operagx,
  Opnsense: Opnsense,
  Oppo: Oppo,
  Opsgenie: Opsgenie,
  Opslevel: Opslevel,
  Oracle: Oracle,
  Orange: Orange,
  Orcid: Orcid,
  Oreilly: Oreilly,
  Org: Org,
  Origin: Origin,
  Osano: Osano,
  Osf: Osf,
  Osgeo: Osgeo,
  Oshkosh: Oshkosh,
  Osmc: Osmc,
  Osu: Osu,
  Otto: Otto,
  Overcast: Overcast,
  Overleaf: Overleaf,
  Ovh: Ovh,
  Owasp: Owasp,
  Owncloud: Owncloud,
  Oxygen: Oxygen,
  Oyo: Oyo,
  P5dotjs: PFivedotjs,
  Packagist: Packagist,
  Packer: Packer,
  Packt: Packt,
  Paddle: Paddle,
  Paddlepaddle: Paddlepaddle,
  Paddypower: Paddypower,
  Pagekit: Pagekit,
  Pagerduty: Pagerduty,
  Pagespeedinsights: Pagespeedinsights,
  Pagseguro: Pagseguro,
  Palantir: Palantir,
  Paloaltonetworks: Paloaltonetworks,
  Paloaltosoftware: Paloaltosoftware,
  Panasonic: Panasonic,
  Pandas: Pandas,
  Pandora: Pandora,
  Pantheon: Pantheon,
  Paperlessngx: Paperlessngx,
  Paperspace: Paperspace,
  Paperswithcode: Paperswithcode,
  Paramountplus: Paramountplus,
  Paritysubstrate: Paritysubstrate,
  Parsedotly: Parsedotly,
  Passport: Passport,
  Pastebin: Pastebin,
  Patreon: Patreon,
  Paychex: Paychex,
  Payhip: Payhip,
  Payloadcms: Payloadcms,
  Payoneer: Payoneer,
  Paypal: Paypal,
  Paytm: Paytm,
  Pcgamingwiki: Pcgamingwiki,
  Pdm: Pdm,
  Pdq: Pdq,
  Peakdesign: Peakdesign,
  Pearson: Pearson,
  Peerlist: Peerlist,
  Peertube: Peertube,
  Pegasusairlines: Pegasusairlines,
  Pelican: Pelican,
  Peloton: Peloton,
  Penny: Penny,
  Penpot: Penpot,
  Percy: Percy,
  Perforce: Perforce,
  Perl: Perl,
  Perplexity: Perplexity,
  Persistent: Persistent,
  Personio: Personio,
  Petsathome: Petsathome,
  Peugeot: Peugeot,
  Pexels: Pexels,
  Pfsense: Pfsense,
  Phabricator: Phabricator,
  Philipshue: Philipshue,
  Phoenixframework: Phoenixframework,
  Phonepe: Phonepe,
  Phosphoricons: Phosphoricons,
  Photobucket: Photobucket,
  Photocrowd: Photocrowd,
  Photon: Photon,
  Photopea: Photopea,
  Php: Php,
  Phpmyadmin: Phpmyadmin,
  Phpstorm: Phpstorm,
  Piaggiogroup: Piaggiogroup,
  Piapro: Piapro,
  Picardsurgeles: Picardsurgeles,
  Picartodottv: Picartodottv,
  Picnic: Picnic,
  Picpay: Picpay,
  Picxy: Picxy,
  Pihole: Pihole,
  Pimcore: Pimcore,
  Pinboard: Pinboard,
  Pinescript: Pinescript,
  Pinetwork: Pinetwork,
  Pingdom: Pingdom,
  Pinterest: Pinterest,
  Pioneerdj: Pioneerdj,
  Piped: Piped,
  Pipx: Pipx,
  Pivotaltracker: Pivotaltracker,
  Piwigo: Piwigo,
  Pix: Pix,
  Pixabay: Pixabay,
  Pixelfed: Pixelfed,
  Pixiv: Pixiv,
  Pkgsrc: Pkgsrc,
  Planet: Planet,
  Planetscale: Planetscale,
  Plangrid: Plangrid,
  Platformdotsh: Platformdotsh,
  Platformio: Platformio,
  Platzi: Platzi,
  Plausibleanalytics: Plausibleanalytics,
  Playcanvas: Playcanvas,
  Playerdotme: Playerdotme,
  Playerfm: Playerfm,
  Playstation: Playstation,
  Playstation2: PlaystationTwo,
  Playstation3: PlaystationThree,
  Playstation4: PlaystationFour,
  Playstation5: PlaystationFive,
  Playstationvita: Playstationvita,
  Playwright: Playwright,
  Pleroma: Pleroma,
  Plesk: Plesk,
  Plex: Plex,
  Plotly: Plotly,
  Pluralsight: Pluralsight,
  Plurk: Plurk,
  Pluscodes: Pluscodes,
  Pm2: PmTwo,
  Pnpm: Pnpm,
  Pocket: Pocket,
  Pocketbase: Pocketbase,
  Pocketcasts: Pocketcasts,
  Podcastaddict: Podcastaddict,
  Podcastindex: Podcastindex,
  Podman: Podman,
  Poe: Poe,
  Poetry: Poetry,
  Pointy: Pointy,
  Pokemon: Pokemon,
  Polars: Polars,
  Polestar: Polestar,
  Polkadot: Polkadot,
  Poly: Poly,
  Polygon: Polygon,
  Polymerproject: Polymerproject,
  Polywork: Polywork,
  Pond5: PondFive,
  Popos: Popos,
  Porkbun: Porkbun,
  Porsche: Porsche,
  Portainer: Portainer,
  Portswigger: Portswigger,
  Postcss: Postcss,
  Postgresql: Postgresql,
  Posthog: Posthog,
  Postman: Postman,
  Postmates: Postmates,
  Powerapps: Powerapps,
  Powerautomate: Powerautomate,
  Powerbi: Powerbi,
  Powerfx: Powerfx,
  Powerpages: Powerpages,
  Powers: Powers,
  Powershell: Powershell,
  Powervirtualagents: Powervirtualagents,
  Prdotco: Prdotco,
  Preact: Preact,
  Precommit: Precommit,
  Prefect: Prefect,
  Premierleague: Premierleague,
  Prepbytes: Prepbytes,
  Prestashop: Prestashop,
  Presto: Presto,
  Prettier: Prettier,
  Pretzel: Pretzel,
  Prevention: Prevention,
  Prezi: Prezi,
  Prime: Prime,
  Primevideo: Primevideo,
  Printables: Printables,
  Prisma: Prisma,
  Prismic: Prismic,
  Privatedivision: Privatedivision,
  Privateinternetaccess: Privateinternetaccess,
  Probot: Probot,
  Processingfoundation: Processingfoundation,
  Processwire: Processwire,
  Producthunt: Producthunt,
  Progate: Progate,
  Progress: Progress,
  Prometheus: Prometheus,
  Prosieben: Prosieben,
  Proteus: Proteus,
  Protocolsdotio: Protocolsdotio,
  Protodotio: Protodotio,
  Proton: Proton,
  Protoncalendar: Protoncalendar,
  Protondb: Protondb,
  Protondrive: Protondrive,
  Protonmail: Protonmail,
  Protonvpn: Protonvpn,
  Protools: Protools,
  Protractor: Protractor,
  Proxmox: Proxmox,
  Pterodactyl: Pterodactyl,
  Pubg: Pubg,
  Publons: Publons,
  Pubmed: Pubmed,
  Pug: Pug,
  Pulumi: Pulumi,
  Puma: Puma,
  Puppet: Puppet,
  Puppeteer: Puppeteer,
  Purescript: Purescript,
  Purgecss: Purgecss,
  Purism: Purism,
  Pushbullet: Pushbullet,
  Pusher: Pusher,
  Pwa: Pwa,
  Pycharm: Pycharm,
  Pycqa: Pycqa,
  Pydantic: Pydantic,
  Pyg: Pyg,
  Pypi: Pypi,
  Pypy: Pypy,
  Pyscaffold: Pyscaffold,
  Pysyft: Pysyft,
  Pytest: Pytest,
  Python: Python,
  Pythonanywhere: Pythonanywhere,
  Pytorch: Pytorch,
  Pyup: Pyup,
  Qantas: Qantas,
  Qase: Qase,
  Qatarairways: Qatarairways,
  Qbittorrent: Qbittorrent,
  Qemu: Qemu,
  Qgis: Qgis,
  Qi: Qi,
  Qiita: Qiita,
  Qiskit: Qiskit,
  Qiwi: Qiwi,
  Qlik: Qlik,
  Qmk: Qmk,
  Qnap: Qnap,
  Qt: Qt,
  Qualcomm: Qualcomm,
  Qualtrics: Qualtrics,
  Qualys: Qualys,
  Quantcast: Quantcast,
  Quantconnect: Quantconnect,
  Quarkus: Quarkus,
  Quasar: Quasar,
  Qubesos: Qubesos,
  Quest: Quest,
  Quickbooks: Quickbooks,
  Quicklook: Quicklook,
  Quicktime: Quicktime,
  Quicktype: Quicktype,
  Quip: Quip,
  Quora: Quora,
  Qwant: Qwant,
  Qwiklabs: Qwiklabs,
  Qzone: Qzone,
  R: R,
  R3: RThree,
  Rabbitmq: Rabbitmq,
  Racket: Racket,
  Radar: Radar,
  Radarr: Radarr,
  Radiopublic: Radiopublic,
  Radixui: Radixui,
  Radstudio: Radstudio,
  Railway: Railway,
  Rainmeter: Rainmeter,
  Rakuten: Rakuten,
  Ram: Ram,
  Rancher: Rancher,
  Rapid: Rapid,
  Rarible: Rarible,
  Rasa: Rasa,
  Raspberrypi: Raspberrypi,
  Ravelry: Ravelry,
  Ray: Ray,
  Raycast: Raycast,
  Raylib: Raylib,
  Razer: Razer,
  Razorpay: Razorpay,
  React: React,
  Reacthookform: Reacthookform,
  Reactiveresume: Reactiveresume,
  Reactivex: Reactivex,
  Reactos: Reactos,
  Reactquery: Reactquery,
  Reactrouter: Reactrouter,
  Reacttable: Reacttable,
  Readdotcv: Readdotcv,
  Readme: Readme,
  Readthedocs: Readthedocs,
  Realm: Realm,
  Reason: Reason,
  Reasonstudios: Reasonstudios,
  Recoil: Recoil,
  Red: Red,
  Redbubble: Redbubble,
  Redbull: Redbull,
  Reddit: Reddit,
  Redhat: Redhat,
  Redhatopenshift: Redhatopenshift,
  Redis: Redis,
  Redmine: Redmine,
  Redox: Redox,
  Redux: Redux,
  Reduxsaga: Reduxsaga,
  Redwoodjs: Redwoodjs,
  Reebok: Reebok,
  Refine: Refine,
  Relay: Relay,
  Relianceindustrieslimited: Relianceindustrieslimited,
  Remark: Remark,
  Remedyentertainment: Remedyentertainment,
  Remix: Remix,
  Renault: Renault,
  Render: Render,
  Renovatebot: Renovatebot,
  Renpy: Renpy,
  Renren: Renren,
  Replit: Replit,
  Republicofgamers: Republicofgamers,
  Rescript: Rescript,
  Rescuetime: Rescuetime,
  Researchgate: Researchgate,
  Resend: Resend,
  Resharper: Resharper,
  Resurrectionremixos: Resurrectionremixos,
  Retool: Retool,
  Retroarch: Retroarch,
  Retropie: Retropie,
  Revanced: Revanced,
  Revealdotjs: Revealdotjs,
  Reverbnation: Reverbnation,
  Revoltdotchat: Revoltdotchat,
  Revolut: Revolut,
  Revue: Revue,
  Rewe: Rewe,
  Rezgo: Rezgo,
  Rhinoceros: Rhinoceros,
  Rich: Rich,
  Rider: Rider,
  Rimacautomobili: Rimacautomobili,
  Ring: Ring,
  Riotgames: Riotgames,
  Ripple: Ripple,
  Riscv: Riscv,
  Riseup: Riseup,
  Ritzcarlton: Ritzcarlton,
  Rive: Rive,
  Roadmapdotsh: Roadmapdotsh,
  Roamresearch: Roamresearch,
  Robinhood: Robinhood,
  Roblox: Roblox,
  Robloxstudio: Robloxstudio,
  Robotframework: Robotframework,
  Rocketdotchat: Rocketdotchat,
  Rocksdb: Rocksdb,
  Rockstargames: Rockstargames,
  Rockwellautomation: Rockwellautomation,
  Rockylinux: Rockylinux,
  Roku: Roku,
  Roll20: RollTwoZero,
  Rollsroyce: Rollsroyce,
  Rollupdotjs: Rollupdotjs,
  Rome: Rome,
  Roon: Roon,
  Rootme: Rootme,
  Roots: Roots,
  Rootsbedrock: Rootsbedrock,
  Rootssage: Rootssage,
  Ros: Ros,
  Rossmann: Rossmann,
  Rotaryinternational: Rotaryinternational,
  Rottentomatoes: Rottentomatoes,
  Roundcube: Roundcube,
  Rsocket: Rsocket,
  Rss: Rss,
  Rstudio: Rstudio,
  Rte: Rte,
  Rtl: Rtl,
  Rtlzwei: Rtlzwei,
  Rtm: Rtm,
  Rubocop: Rubocop,
  Ruby: Ruby,
  Rubygems: Rubygems,
  Rubyonrails: Rubyonrails,
  Rubysinatra: Rubysinatra,
  Ruff: Ruff,
  Rumahweb: Rumahweb,
  Rumble: Rumble,
  Rundeck: Rundeck,
  Runkeeper: Runkeeper,
  Runkit: Runkit,
  Runrundotit: Runrundotit,
  Rust: Rust,
  Rustdesk: Rustdesk,
  Rxdb: Rxdb,
  Ryanair: Ryanair,
  Rye: Rye,
  S7airlines: SSevenairlines,
  Sabanci: Sabanci,
  Safari: Safari,
  Sage: Sage,
  Sahibinden: Sahibinden,
  Sailfishos: Sailfishos,
  Sailsdotjs: Sailsdotjs,
  Salesforce: Salesforce,
  Salla: Salla,
  Saltproject: Saltproject,
  Samsclub: Samsclub,
  Samsung: Samsung,
  Samsungpay: Samsungpay,
  Sandisk: Sandisk,
  Sanfranciscomunicipalrailway: Sanfranciscomunicipalrailway,
  Sanic: Sanic,
  Sanity: Sanity,
  Saopaulometro: Saopaulometro,
  Sap: Sap,
  Sass: Sass,
  Sat1: SatOne,
  Satellite: Satellite,
  Saturn: Saturn,
  Saucelabs: Saucelabs,
  Saudia: Saudia,
  Scala: Scala,
  Scaleway: Scaleway,
  Scania: Scania,
  Schneiderelectric: Schneiderelectric,
  Scikitlearn: Scikitlearn,
  Scipy: Scipy,
  Scopus: Scopus,
  Scpfoundation: Scpfoundation,
  Scrapbox: Scrapbox,
  Scrapy: Scrapy,
  Scratch: Scratch,
  Screencastify: Screencastify,
  Scribd: Scribd,
  Scrimba: Scrimba,
  Scrollreveal: Scrollreveal,
  Scrumalliance: Scrumalliance,
  Scrutinizerci: Scrutinizerci,
  Scylladb: Scylladb,
  Seagate: Seagate,
  Searxng: Searxng,
  Seat: Seat,
  Seatgeek: Seatgeek,
  Securityscorecard: Securityscorecard,
  Sefaria: Sefaria,
  Sega: Sega,
  Selenium: Selenium,
  Sellfy: Sellfy,
  Semanticrelease: Semanticrelease,
  Semanticscholar: Semanticscholar,
  Semanticui: Semanticui,
  Semanticuireact: Semanticuireact,
  Semanticweb: Semanticweb,
  Semaphoreci: Semaphoreci,
  Semrush: Semrush,
  Semver: Semver,
  Sencha: Sencha,
  Sennheiser: Sennheiser,
  Sensu: Sensu,
  Sentry: Sentry,
  Sepa: Sepa,
  Sequelize: Sequelize,
  Serverfault: Serverfault,
  Serverless: Serverless,
  Session: Session,
  Sessionize: Sessionize,
  Setapp: Setapp,
  Sfml: Sfml,
  Shadcnui: Shadcnui,
  Shadow: Shadow,
  Shanghaimetro: Shanghaimetro,
  Sharex: Sharex,
  Sharp: Sharp,
  Shazam: Shazam,
  Shell: Shell,
  Shelly: Shelly,
  Shenzhenmetro: Shenzhenmetro,
  Shieldsdotio: Shieldsdotio,
  Shikimori: Shikimori,
  Shopee: Shopee,
  Shopify: Shopify,
  Shopware: Shopware,
  Shortcut: Shortcut,
  Shotcut: Shotcut,
  Showpad: Showpad,
  Showtime: Showtime,
  Showwcase: Showwcase,
  Shutterstock: Shutterstock,
  Sidekiq: Sidekiq,
  Sidequest: Sidequest,
  Siemens: Siemens,
  Sifive: Sifive,
  Signal: Signal,
  Similarweb: Similarweb,
  Simkl: Simkl,
  Simpleanalytics: Simpleanalytics,
  Simpleicons: Simpleicons,
  Simplelogin: Simplelogin,
  Simplenote: Simplenote,
  Sinaweibo: Sinaweibo,
  Singaporeairlines: Singaporeairlines,
  Singlestore: Singlestore,
  Sitecore: Sitecore,
  Sitepoint: Sitepoint,
  Siyuan: Siyuan,
  Skaffold: Skaffold,
  Sketch: Sketch,
  Sketchfab: Sketchfab,
  Sketchup: Sketchup,
  Skillshare: Skillshare,
  Skoda: Skoda,
  Sky: Sky,
  Skynet: Skynet,
  Skypack: Skypack,
  Skype: Skype,
  Skypeforbusiness: Skypeforbusiness,
  Skyrock: Skyrock,
  Slack: Slack,
  Slackware: Slackware,
  Slashdot: Slashdot,
  Slickpic: Slickpic,
  Slides: Slides,
  Slideshare: Slideshare,
  Slint: Slint,
  Smart: Smart,
  Smartthings: Smartthings,
  Smashdotgg: Smashdotgg,
  Smashingmagazine: Smashingmagazine,
  Smrt: Smrt,
  Smugmug: Smugmug,
  Snapchat: Snapchat,
  Snapcraft: Snapcraft,
  Sncf: Sncf,
  Snowflake: Snowflake,
  Snowpack: Snowpack,
  Snyk: Snyk,
  Socialblade: Socialblade,
  Society6: SocietySix,
  Socketdotio: Socketdotio,
  Softpedia: Softpedia,
  Sogou: Sogou,
  Solana: Solana,
  Solid: Solid,
  Solidity: Solidity,
  Sololearn: Sololearn,
  Solus: Solus,
  Sonar: Sonar,
  Sonarcloud: Sonarcloud,
  Sonarlint: Sonarlint,
  Sonarqube: Sonarqube,
  Sonarr: Sonarr,
  Sonatype: Sonatype,
  Songkick: Songkick,
  Songoda: Songoda,
  Sonicwall: Sonicwall,
  Sonos: Sonos,
  Sony: Sony,
  Soriana: Soriana,
  Soundcharts: Soundcharts,
  Soundcloud: Soundcloud,
  Sourceengine: Sourceengine,
  Sourceforge: Sourceforge,
  Sourcehut: Sourcehut,
  Sourcetree: Sourcetree,
  Southwestairlines: Southwestairlines,
  Spacemacs: Spacemacs,
  Spaceship: Spaceship,
  Spacex: Spacex,
  Spacy: Spacy,
  Sparkar: Sparkar,
  Sparkasse: Sparkasse,
  Sparkfun: Sparkfun,
  Sparkpost: Sparkpost,
  Spdx: Spdx,
  Speakerdeck: Speakerdeck,
  Spectrum: Spectrum,
  Speedtest: Speedtest,
  Speedypage: Speedypage,
  Sphinx: Sphinx,
  Spigotmc: Spigotmc,
  Spine: Spine,
  Spinnaker: Spinnaker,
  Spinrilla: Spinrilla,
  Splunk: Splunk,
  Spoj: Spoj,
  Spond: Spond,
  Spotify: Spotify,
  Spotlight: Spotlight,
  Spreadshirt: Spreadshirt,
  Spreaker: Spreaker,
  Spring: Spring,
  SpringCreators: SpringCreators,
  Springboot: Springboot,
  Springsecurity: Springsecurity,
  Spyderide: Spyderide,
  Sqlalchemy: Sqlalchemy,
  Sqlite: Sqlite,
  Square: Square,
  Squareenix: Squareenix,
  Squarespace: Squarespace,
  Srgssr: Srgssr,
  Ssrn: Ssrn,
  Sst: Sst,
  Stackbit: Stackbit,
  Stackblitz: Stackblitz,
  Stackedit: Stackedit,
  Stackexchange: Stackexchange,
  Stackhawk: Stackhawk,
  Stackoverflow: Stackoverflow,
  Stackpath: Stackpath,
  Stackshare: Stackshare,
  Stadia: Stadia,
  Staffbase: Staffbase,
  Stagetimer: Stagetimer,
  Standardjs: Standardjs,
  Standardresume: Standardresume,
  Starbucks: Starbucks,
  Stardock: Stardock,
  Starlingbank: Starlingbank,
  Starship: Starship,
  Startrek: Startrek,
  Starz: Starz,
  Statamic: Statamic,
  Statuspage: Statuspage,
  Statuspal: Statuspal,
  Steam: Steam,
  Steamdb: Steamdb,
  Steamdeck: Steamdeck,
  Steamworks: Steamworks,
  Steelseries: Steelseries,
  Steem: Steem,
  Steemit: Steemit,
  Steinberg: Steinberg,
  Stellar: Stellar,
  Stencyl: Stencyl,
  Stimulus: Stimulus,
  Stitcher: Stitcher,
  Stmicroelectronics: Stmicroelectronics,
  Stockx: Stockx,
  Stopstalk: Stopstalk,
  Storyblok: Storyblok,
  Storybook: Storybook,
  Strapi: Strapi,
  Strava: Strava,
  Streamlabs: Streamlabs,
  Streamlit: Streamlit,
  Streamrunners: Streamrunners,
  Stripe: Stripe,
  Strongswan: Strongswan,
  Stryker: Stryker,
  Stubhub: Stubhub,
  Studio3t: StudioThreet,
  Studyverse: Studyverse,
  Styledcomponents: Styledcomponents,
  Stylelint: Stylelint,
  Styleshare: Styleshare,
  Stylus: Stylus,
  Subaru: Subaru,
  Sublimetext: Sublimetext,
  Substack: Substack,
  Subtitleedit: Subtitleedit,
  Subversion: Subversion,
  Suckless: Suckless,
  Sumologic: Sumologic,
  Supabase: Supabase,
  Supercrease: Supercrease,
  Supermicro: Supermicro,
  Superuser: Superuser,
  Surrealdb: Surrealdb,
  Surveymonkey: Surveymonkey,
  Suse: Suse,
  Suzuki: Suzuki,
  Svelte: Svelte,
  Svg: Svg,
  Svgdotjs: Svgdotjs,
  Svgo: Svgo,
  Swagger: Swagger,
  Swarm: Swarm,
  Swc: Swc,
  Swift: Swift,
  Swiggy: Swiggy,
  Swiper: Swiper,
  Swr: Swr,
  Symantec: Symantec,
  Symbolab: Symbolab,
  Symfony: Symfony,
  Symphony: Symphony,
  Sympy: Sympy,
  Synology: Synology,
  System76: SystemSevenSix,
  Tabelog: Tabelog,
  Tableau: Tableau,
  Tablecheck: Tablecheck,
  Tacobell: Tacobell,
  Tado: Tado,
  Taichigraphics: Taichigraphics,
  Taichilang: Taichilang,
  Tails: Tails,
  Tailwindcss: Tailwindcss,
  Taketwointeractivesoftware: Taketwointeractivesoftware,
  Talend: Talend,
  Talenthouse: Talenthouse,
  Talos: Talos,
  Tamiya: Tamiya,
  Tampermonkey: Tampermonkey,
  Taobao: Taobao,
  Tapas: Tapas,
  Target: Target,
  Task: Task,
  Tasmota: Tasmota,
  Tata: Tata,
  Tauri: Tauri,
  Taxbuzz: Taxbuzz,
  Tcs: Tcs,
  Teal: Teal,
  Teamcity: Teamcity,
  Teamspeak: Teamspeak,
  Teamviewer: Teamviewer,
  Ted: Ted,
  Teepublic: Teepublic,
  Teespring: Teespring,
  Tekton: Tekton,
  Tele5: TeleFive,
  Telegram: Telegram,
  Telegraph: Telegraph,
  Telequebec: Telequebec,
  Temporal: Temporal,
  Tencentqq: Tencentqq,
  Tensorflow: Tensorflow,
  Teradata: Teradata,
  Teratail: Teratail,
  Termius: Termius,
  Terraform: Terraform,
  Tesco: Tesco,
  Tesla: Tesla,
  Testcafe: Testcafe,
  Testin: Testin,
  Testinglibrary: Testinglibrary,
  Testrail: Testrail,
  Tether: Tether,
  Textpattern: Textpattern,
  Tga: Tga,
  Thangs: Thangs,
  Thealgorithms: Thealgorithms,
  Theboringcompany: Theboringcompany,
  Theconversation: Theconversation,
  Thefinals: Thefinals,
  Theirishtimes: Theirishtimes,
  Themighty: Themighty,
  Themodelsresource: Themodelsresource,
  Themoviedatabase: Themoviedatabase,
  Thenorthface: Thenorthface,
  Theodinproject: Theodinproject,
  Theregister: Theregister,
  Thesoundsresource: Thesoundsresource,
  Thespritersresource: Thespritersresource,
  Thewashingtonpost: Thewashingtonpost,
  Thingiverse: Thingiverse,
  Thinkpad: Thinkpad,
  Thirdweb: Thirdweb,
  Threadless: Threadless,
  Threads: Threads,
  Threedotjs: Threedotjs,
  Threema: Threema,
  Thumbtack: Thumbtack,
  Thunderbird: Thunderbird,
  Thunderstore: Thunderstore,
  Thurgauerkantonalbank: Thurgauerkantonalbank,
  Thymeleaf: Thymeleaf,
  Ticketmaster: Ticketmaster,
  Ticktick: Ticktick,
  Tidal: Tidal,
  Tiddlywiki: Tiddlywiki,
  Tide: Tide,
  Tidyverse: Tidyverse,
  Tietoevry: Tietoevry,
  Tiktok: Tiktok,
  Tildapublishing: Tildapublishing,
  Tile: Tile,
  Timescale: Timescale,
  Tina: Tina,
  Tinder: Tinder,
  Tindie: Tindie,
  Tinkercad: Tinkercad,
  Tinyletter: Tinyletter,
  Tistory: Tistory,
  Tmobile: Tmobile,
  Tmux: Tmux,
  Todoist: Todoist,
  Toggl: Toggl,
  Toggltrack: Toggltrack,
  Tokyometro: Tokyometro,
  Toll: Toll,
  Toml: Toml,
  Tomorrowland: Tomorrowland,
  Ton: Ton,
  Topcoder: Topcoder,
  Topdotgg: Topdotgg,
  Toptal: Toptal,
  Torbrowser: Torbrowser,
  Torproject: Torproject,
  Toshiba: Toshiba,
  Totvs: Totvs,
  Tourbox: Tourbox,
  Toyota: Toyota,
  Tplink: Tplink,
  Tqdm: Tqdm,
  Tradingview: Tradingview,
  Traefikmesh: Traefikmesh,
  Traefikproxy: Traefikproxy,
  Trailforks: Trailforks,
  Trainerroad: Trainerroad,
  Trakt: Trakt,
  Transifex: Transifex,
  Transmission: Transmission,
  Transportforireland: Transportforireland,
  Transportforlondon: Transportforlondon,
  Travisci: Travisci,
  Treehouse: Treehouse,
  Trello: Trello,
  Trendmicro: Trendmicro,
  Treyarch: Treyarch,
  Tricentis: Tricentis,
  Trilium: Trilium,
  Triller: Triller,
  Trillertv: Trillertv,
  Trino: Trino,
  Tripadvisor: Tripadvisor,
  Tripdotcom: Tripdotcom,
  Trivy: Trivy,
  Trove: Trove,
  Trpc: Trpc,
  Truenas: Truenas,
  Trueup: Trueup,
  Trulia: Trulia,
  Trustedshops: Trustedshops,
  Trustpilot: Trustpilot,
  Tryhackme: Tryhackme,
  Tryitonline: Tryitonline,
  Tsnode: Tsnode,
  Tubi: Tubi,
  Tui: Tui,
  Tumblr: Tumblr,
  Tunein: Tunein,
  Turbo: Turbo,
  Turborepo: Turborepo,
  Turbosquid: Turbosquid,
  Turkishairlines: Turkishairlines,
  Turso: Turso,
  Tutanota: Tutanota,
  Tv4play: TvFourplay,
  Tvtime: Tvtime,
  Twilio: Twilio,
  Twinkly: Twinkly,
  Twitch: Twitch,
  Twitter: Twitter,
  Typeform: Typeform,
  Typer: Typer,
  Typescript: Typescript,
  Typo3: TypoThree,
  Typst: Typst,
  Uber: Uber,
  Ubereats: Ubereats,
  Ubiquiti: Ubiquiti,
  Ubisoft: Ubisoft,
  Ublockorigin: Ublockorigin,
  Ubuntu: Ubuntu,
  Ubuntumate: Ubuntumate,
  Udacity: Udacity,
  Udemy: Udemy,
  Ufc: Ufc,
  Uikit: Uikit,
  Ulule: Ulule,
  Umami: Umami,
  Umbraco: Umbraco,
  Uml: Uml,
  Unacademy: Unacademy,
  Underarmour: Underarmour,
  Underscoredotjs: Underscoredotjs,
  Undertale: Undertale,
  Unicode: Unicode,
  Unilever: Unilever,
  Uniqlo: Uniqlo,
  UniqloJa: UniqloJa,
  Unitedairlines: Unitedairlines,
  Unitednations: Unitednations,
  Unity: Unity,
  Unlicense: Unlicense,
  Unocss: Unocss,
  Unraid: Unraid,
  Unrealengine: Unrealengine,
  Unsplash: Unsplash,
  Untappd: Untappd,
  Upcloud: Upcloud,
  Uphold: Uphold,
  Uplabs: Uplabs,
  Upptime: Upptime,
  Ups: Ups,
  Upstash: Upstash,
  Uptimekuma: Uptimekuma,
  Uptobox: Uptobox,
  Upwork: Upwork,
  Usps: Usps,
  Utorrent: Utorrent,
  V: V,
  V2ex: VTwoex,
  V8: VEight,
  Vaadin: Vaadin,
  Vagrant: Vagrant,
  Vala: Vala,
  Valorant: Valorant,
  Valve: Valve,
  Vapor: Vapor,
  Vault: Vault,
  Vaultwarden: Vaultwarden,
  Vauxhall: Vauxhall,
  Vbulletin: Vbulletin,
  Vectary: Vectary,
  Vectorlogozone: Vectorlogozone,
  Vectorworks: Vectorworks,
  Veeam: Veeam,
  Veepee: Veepee,
  Vega: Vega,
  Vegas: Vegas,
  Velog: Velog,
  Venmo: Venmo,
  Vercel: Vercel,
  Verdaccio: Verdaccio,
  Veritas: Veritas,
  Verizon: Verizon,
  Vespa: Vespa,
  Vexxhost: Vexxhost,
  Vfairs: Vfairs,
  Viadeo: Viadeo,
  Viaplay: Viaplay,
  Viber: Viber,
  Viblo: Viblo,
  Victoriametrics: Victoriametrics,
  Victronenergy: Victronenergy,
  Vim: Vim,
  Vimeo: Vimeo,
  Vimeolivestream: Vimeolivestream,
  Virgin: Virgin,
  Virginatlantic: Virginatlantic,
  Virginmedia: Virginmedia,
  Virtualbox: Virtualbox,
  Virustotal: Virustotal,
  Visa: Visa,
  Visualbasic: Visualbasic,
  Visualstudio: Visualstudio,
  Visualstudioappcenter: Visualstudioappcenter,
  Visualstudiocode: Visualstudiocode,
  Vite: Vite,
  Vitepress: Vitepress,
  Vitess: Vitess,
  Vitest: Vitest,
  Vivaldi: Vivaldi,
  Vivawallet: Vivawallet,
  Vivino: Vivino,
  Vivint: Vivint,
  Vivo: Vivo,
  Vk: Vk,
  Vlcmediaplayer: Vlcmediaplayer,
  Vmware: Vmware,
  Vodafone: Vodafone,
  Voidlinux: Voidlinux,
  Voipdotms: Voipdotms,
  Volkswagen: Volkswagen,
  Volvo: Volvo,
  Vonage: Vonage,
  Vorondesign: Vorondesign,
  Vowpalwabbit: Vowpalwabbit,
  Vox: Vox,
  Vsco: Vsco,
  Vscodium: Vscodium,
  Vtex: Vtex,
  Vuedotjs: Vuedotjs,
  Vuetify: Vuetify,
  Vulkan: Vulkan,
  Vultr: Vultr,
  Vyond: Vyond,
  W3schools: WThreeschools,
  Wacom: Wacom,
  Wagtail: Wagtail,
  Wails: Wails,
  Wakatime: Wakatime,
  Walkman: Walkman,
  Wallabag: Wallabag,
  Walletconnect: Walletconnect,
  Walmart: Walmart,
  Wantedly: Wantedly,
  Wappalyzer: Wappalyzer,
  Warnerbros: Warnerbros,
  Warp: Warp,
  Wasabi: Wasabi,
  Wasmcloud: Wasmcloud,
  Wasmer: Wasmer,
  Watchtower: Watchtower,
  Wattpad: Wattpad,
  Wayland: Wayland,
  Waze: Waze,
  Wazirx: Wazirx,
  Wearos: Wearos,
  Weasyl: Weasyl,
  Web3dotjs: WebThreedotjs,
  Webassembly: Webassembly,
  Webauthn: Webauthn,
  Webcomponentsdotorg: Webcomponentsdotorg,
  Webdriverio: Webdriverio,
  Webex: Webex,
  Webflow: Webflow,
  Webgl: Webgl,
  Weblate: Weblate,
  Webmin: Webmin,
  Webmoney: Webmoney,
  Webpack: Webpack,
  Webrtc: Webrtc,
  Webstorm: Webstorm,
  Webtoon: Webtoon,
  Webtrees: Webtrees,
  Wechat: Wechat,
  Wegame: Wegame,
  Weightsandbiases: Weightsandbiases,
  Welcometothejungle: Welcometothejungle,
  Wellfound: Wellfound,
  Wellsfargo: Wellsfargo,
  Wemo: Wemo,
  Westerndigital: Westerndigital,
  Westernunion: Westernunion,
  Wetransfer: Wetransfer,
  Wezterm: Wezterm,
  Whatsapp: Whatsapp,
  Wheniwork: Wheniwork,
  Wii: Wii,
  Wiiu: Wiiu,
  Wikidata: Wikidata,
  Wikidotgg: Wikidotgg,
  Wikidotjs: Wikidotjs,
  Wikimediacommons: Wikimediacommons,
  Wikimediafoundation: Wikimediafoundation,
  Wikipedia: Wikipedia,
  Wikiquote: Wikiquote,
  Wikivoyage: Wikivoyage,
  Winamp: Winamp,
  Windows: Windows,
  Windows10: WindowsOneZero,
  Windows11: WindowsOneOne,
  Windows95: WindowsNineFive,
  Windowsterminal: Windowsterminal,
  Windowsxp: Windowsxp,
  Wipro: Wipro,
  Wire: Wire,
  Wireguard: Wireguard,
  Wireshark: Wireshark,
  Wise: Wise,
  Wish: Wish,
  Wistia: Wistia,
  Wix: Wix,
  Wizzair: Wizzair,
  Wolfram: Wolfram,
  Wolframlanguage: Wolframlanguage,
  Wolframmathematica: Wolframmathematica,
  Wondershare: Wondershare,
  Wondersharefilmora: Wondersharefilmora,
  Woo: Woo,
  Woocommerce: Woocommerce,
  Wordpress: Wordpress,
  Workplace: Workplace,
  Worldhealthorganization: Worldhealthorganization,
  Wpengine: Wpengine,
  Wpexplorer: Wpexplorer,
  Wprocket: Wprocket,
  Writedotas: Writedotas,
  Wwe: Wwe,
  Wwise: Wwise,
  Wyze: Wyze,
  X: X,
  Xamarin: Xamarin,
  Xaml: Xaml,
  Xampp: Xampp,
  Xbox: Xbox,
  Xcode: Xcode,
  Xdadevelopers: Xdadevelopers,
  Xdotorg: Xdotorg,
  Xendit: Xendit,
  Xero: Xero,
  Xfce: Xfce,
  Xiaohongshu: Xiaohongshu,
  Xiaomi: Xiaomi,
  Xing: Xing,
  Xmpp: Xmpp,
  Xo: Xo,
  Xrp: Xrp,
  Xsplit: Xsplit,
  Xstate: Xstate,
  Yale: Yale,
  Yamahacorporation: Yamahacorporation,
  Yamahamotorcorporation: Yamahamotorcorporation,
  Yaml: Yaml,
  Yammer: Yammer,
  Yandexcloud: Yandexcloud,
  Yarn: Yarn,
  Ycombinator: Ycombinator,
  Yelp: Yelp,
  Yeti: Yeti,
  Yoast: Yoast,
  Youtube: Youtube,
  Youtubegaming: Youtubegaming,
  Youtubekids: Youtubekids,
  Youtubemusic: Youtubemusic,
  Youtubeshorts: Youtubeshorts,
  Youtubestudio: Youtubestudio,
  Youtubetv: Youtubetv,
  Yr: Yr,
  Yubico: Yubico,
  Zabka: Zabka,
  Zaim: Zaim,
  Zalando: Zalando,
  Zalo: Zalo,
  Zap: Zap,
  Zapier: Zapier,
  Zara: Zara,
  Zazzle: Zazzle,
  Zcash: Zcash,
  Zcool: Zcool,
  Zdf: Zdf,
  Zebpay: Zebpay,
  Zebratechnologies: Zebratechnologies,
  Zedindustries: Zedindustries,
  Zelle: Zelle,
  Zend: Zend,
  Zendesk: Zendesk,
  Zenn: Zenn,
  Zenodo: Zenodo,
  Zensar: Zensar,
  Zerodha: Zerodha,
  Zerply: Zerply,
  Zettlr: Zettlr,
  Zhihu: Zhihu,
  Zig: Zig,
  Zigbee: Zigbee,
  Ziggo: Ziggo,
  Zilch: Zilch,
  Zillow: Zillow,
  Zincsearch: Zincsearch,
  Zingat: Zingat,
  Zod: Zod,
  Zoho: Zoho,
  Zoiper: Zoiper,
  Zomato: Zomato,
  Zoom: Zoom,
  Zorin: Zorin,
  Zotero: Zotero,
  Zsh: Zsh,
  Zulip: Zulip,
  Zyte: Zyte,
}
export { Logos }
export type NuiLogosType = keyof typeof Logos
