import React, { SVGProps } from 'react'

export default function Sepa(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>SEPA</title>
      <path d='M12.553 8.72v6.56h1.766v-1.694h1.052l.293-.01c.579-.009.907-.02.984-.033.656-.086 1.09-.418 1.3-.994.106-.29.16-.769.16-1.434 0-.553-.049-.968-.145-1.243-.185-.524-.548-.871-1.09-1.04-.236-.074-.58-.111-1.031-.111zm6.79 0l-1.971 6.56h1.826l.317-1.134h2.36l.337 1.133H24l-1.995-6.558zm-9.339.031a3.36 3.36 0 0 0-1.888.574 3.272 3.272 0 0 0-1.199 1.455h-.742l-.464.996h.969a3.17 3.17 0 0 0 .004.526h-.467l-.465.995H6.94a3.333 3.333 0 0 0 3.064 1.973 3.37 3.37 0 0 0 1.876-.564l.013-.009v-1.241l-.05.056a2.293 2.293 0 0 1-3.618-.215h2.396l.465-.995H7.838a2.356 2.356 0 0 1-.012-.526h3.505l.008-.017.438-.939.019-.04H8.154a2.308 2.308 0 0 1 1.963-1.108c.694 0 1.344.31 1.783.85l.028.035.409-.875-.015-.014a3.359 3.359 0 0 0-2.318-.917zm-7.2.004c-.439 0-.837.016-1.196.048-.43.034-.785.15-1.062.348a1.082 1.082 0 0 0-.41.565c-.086.248-.128.578-.128.99 0 .54.079.943.236 1.21.179.314.537.52 1.074.621.205.035.57.071 1.094.109.654.044 1.02.078 1.098.103.211.063.316.239.316.528a1.1 1.1 0 0 1-.037.315.48.48 0 0 1-.298.287c-.084.032-.281.048-.589.048h-.604a.963.963 0 0 1-.41-.09c-.164-.081-.246-.264-.246-.549v-.136H0c0 .533.04.915.118 1.143.142.412.435.685.879.82.362.11 1.003.164 1.922.164.551 0 .96-.025 1.228-.075.64-.12 1.05-.404 1.233-.853.101-.245.151-.632.151-1.16 0-.23-.01-.435-.033-.617a1.186 1.186 0 0 0-.355-.74c-.252-.242-.66-.392-1.22-.448-.177-.019-.62-.052-1.33-.1l-.331-.023a.768.768 0 0 1-.35-.094c-.124-.075-.185-.233-.185-.471 0-.264.07-.435.208-.514.139-.079.423-.118.852-.118.441 0 .71.068.808.205.063.092.095.272.095.542h1.642c.01-.145.016-.253.016-.326 0-.884-.357-1.421-1.072-1.613-.293-.079-.784-.119-1.473-.119zm17.894 1.27l.827 2.843h-1.63zm-6.38.13h1.116c.18 0 .294.004.342.01.24.042.393.157.456.345.045.134.067.34.067.617a2.9 2.9 0 0 1-.033.512c-.035.172-.105.287-.21.344-.076.042-.288.069-.637.082h-1.1Z' />
    </svg>
  )
}
