import React, { SVGProps } from 'react'

export default function WarningWindow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V13'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M2 7L22 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 5.01L5.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 5.01L8.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 5.01L11.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 16L21 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 22.01L21.01 21.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
