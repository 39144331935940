import React, { SVGProps } from 'react'

export default function AlignTopBox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4 16L4.01 15.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20L4.01 19.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 20L8.01 19.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 20L12.01 19.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 20L16.01 19.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 20L20.01 19.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 16L20.01 15.9889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12V4H20V12H4Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
