import React, { SVGProps } from 'react'

export default function AppleHalf(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.1471 21.2646L12 21.2351L11.8529 21.2646C9.47627 21.7399 7.23257 21.4756 5.59352 20.1643C3.96312 18.86 2.75 16.374 2.75 12C2.75 7.52684 3.75792 5.70955 5.08541 5.04581C5.77977 4.69863 6.67771 4.59759 7.82028 4.72943C8.96149 4.86111 10.2783 5.21669 11.7628 5.71153L12.0235 5.79841L12.2785 5.69638C14.7602 4.70367 16.9909 4.3234 18.5578 5.05463C20.0271 5.7403 21.25 7.59326 21.25 12C21.25 16.374 20.0369 18.86 18.4065 20.1643C16.7674 21.4756 14.5237 21.7399 12.1471 21.2646Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M12 5.5C12 3 11 2 9 2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 6V21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M15 12L15 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
