import React, { SVGProps } from 'react'

export default function ReceiveEuros(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21 12H13M13 12L16.84 8M13 12L16.84 16'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.50265C10.3565 7.18066 9.63357 7 8.86957 7C6.18018 7 4 9.23858 4 12C4 14.7614 6.18018 17 8.86957 17C9.63357 17 10.3565 16.8193 11 16.4974'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 11H9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 13H9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
