import React, { SVGProps } from 'react'

export default function Microsoftaccess(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Microsoft Access</title>
      <path d='M15 .75q1.605 0 3.21.176.282.035.815.111.534.076 1.178.211.645.135 1.318.328.674.194 1.225.457.55.264.902.598.352.334.352.744v17.25q0 .41-.352.744-.351.334-.902.598-.55.263-1.225.457-.673.193-1.318.328-.644.135-1.178.217-.533.082-.814.105-1.606.176-3.211.176-1.617 0-3.21-.176-.282-.023-.815-.105-.534-.082-1.178-.217-.645-.135-1.318-.328-.674-.194-1.225-.457-.55-.264-.902-.598Q6 21.035 6 20.625V18H.996q-.41 0-.703-.293T0 17.004V6.996q0-.41.293-.703T.996 6H6V3.375q0-.41.352-.744.351-.334.902-.598.55-.263 1.225-.457.673-.193 1.318-.328.644-.135 1.178-.21.533-.077.814-.112Q13.383.75 15 .75zm0 1.5q-.762 0-1.7.053-.937.052-1.904.181-.966.13-1.88.346-.914.217-1.618.545.727.34 1.635.557.908.216 1.863.34.956.123 1.887.175.932.053 1.717.053t1.717-.053q.931-.052 1.887-.176.955-.123 1.863-.34.908-.216 1.635-.556-.704-.328-1.618-.545-.914-.217-1.88-.346-.967-.129-1.905-.181Q15.762 2.25 15 2.25zM4.723 14.145h2.543l.597 1.793h2.004l-2.87-7.876H5.038l-2.906 7.876h1.992zM22.5 20.379v-3.55q-.773.339-1.729.562-.955.222-1.962.357-1.008.135-1.993.193Q15.832 18 15 18q-.855 0-1.705-.053-.85-.052-1.693-.146-.27.199-.598.199H7.5v2.379q.34.27.914.48.574.211 1.272.364.697.152 1.459.252.761.1 1.482.164.72.064 1.342.088.62.023 1.031.023.41 0 1.031-.023.621-.024 1.342-.088.72-.065 1.482-.164.762-.1 1.46-.252.697-.153 1.27-.364.575-.21.915-.48zm0-5.227v-4.324q-.773.34-1.729.563-.955.222-1.962.357-1.008.135-1.993.193Q15.832 12 15 12q-.75 0-1.5-.041t-1.5-.111v4.5q.75.082 1.5.117t1.5.035q.434 0 1.055-.023.62-.024 1.33-.088.709-.065 1.459-.164.75-.1 1.441-.252.692-.153 1.272-.352.58-.2.943-.469zm0-6V4.828q-.773.34-1.729.563-.955.222-1.962.357-1.008.135-1.993.193Q15.832 6 15 6q-.832 0-1.816-.059-.985-.058-1.993-.193-1.007-.135-1.962-.357-.956-.223-1.729-.563V6h3.504q.41 0 .703.293t.293.703v3.352q.75.082 1.5.117t1.5.035q.434 0 1.055-.023.62-.024 1.33-.088.709-.065 1.459-.164.75-.1 1.441-.252.692-.153 1.266-.352.574-.2.949-.469zm-17.32 3.47l.808-2.45.785 2.45Z' />
    </svg>
  )
}
