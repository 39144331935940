import React, { SVGProps } from 'react'

export default function Yen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 12H18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 4L12 12M18 4L12 12M12 12V20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 16H18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
