import React, { SVGProps } from 'react'

export default function Skyrock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Skyrock</title>
      <path d='M11.416 14.39A3.189 3.189 0 0 0 9.81 12.8a10.005 10.005 0 0 0-1.877-.637l-2.964-.804c-.463-.133-.907-.315-1.276-.638-.247-.215-.4-.479-.401-.817-.002-.738.447-1.333 1.196-1.552.697-.204 1.407-.196 2.116-.064.775.144 1.37.527 1.728 1.261.238.488.249.736.249.736l2.684-.003v-.097a4.437 4.437 0 0 0-.088-.726c-.333-1.59-1.214-2.718-2.771-3.266-1.011-.356-2.06-.41-3.118-.356-.755.039-1.497.152-2.21.413-.996.364-1.775.98-2.24 1.951-.332.692-.414 1.428-.385 2.187.026.654.222 1.254.516 1.832.357.7.974 1.082 1.66 1.375.857.367 1.778.513 2.67.756.622.169 1.25.311 1.854.542.465.178.916.384 1.303.709.414.347.546.776.413 1.3-.15.584-.544.937-1.091 1.103-1.09.331-2.226.308-3.288-.126-.45-.184-.819-.479-1.052-.92a2.857 2.857 0 0 1-.28-.837s-.027-.148-.025-.196H1.695c-.444 0-1.014.003-1.458-.001l.009.12c.051.735.198 1.448.55 2.102.646 1.196 1.675 1.896 2.97 2.233.893.232 1.803.285 2.72.255.778-.025 1.545-.122 2.289-.364.87-.284 1.632-.73 2.183-1.487.457-.63.693-1.339.774-2.105.085-.789.025-1.56-.316-2.288Zm4.513.434 7.834-5.667-9.693.003L11.086 0l-1.7 5.172.102.048c.643.305 1.2.727 1.669 1.262.765.87 1.205 1.888 1.37 3.032.067.471.07.946.092 1.42l.006.174c1.579-.005 3.537-.003 5.116-.002l-4.127 2.968 1.4 4.27.177.537s-1.477-1.064-2.2-1.587a5.378 5.378 0 0 1-.667 1.804l-.053.09 5.803 4.196.848.616-1.485-4.555-1.508-4.62Z' />
    </svg>
  )
}
