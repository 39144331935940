import React, { Component } from 'react'
import { DropdownSearchProps, DropdownSearchStates } from '../text.types'
import { NuiBadge } from '../../../badge'

export class DropdownSearch extends Component<DropdownSearchProps, DropdownSearchStates> {
  constructor(props: DropdownSearchProps) {
    super(props)
    this.state = {
      selectedOption: null,
      selectedOptions: [],
      options: this.props.options,
      categorys: this.props.categorys,
    }
  }

  handleSelectChange = (selectedValue: string) => {
    const { categorys } = this.state
    const wantedOptions = categorys.filter((category) => category.id === selectedValue)

    if (wantedOptions.length > 0) {
      this.setState((prevState) => {
        const newSelectedOptions = [...prevState.selectedOptions, wantedOptions[0]]
        this.props.onSelectedOptionsChange(newSelectedOptions)
        return {
          selectedOption: wantedOptions[0],
          selectedOptions: newSelectedOptions,
          options: prevState.options.filter((option) => option.id !== selectedValue),
        }
      })
    }
  }

  handleRemoveOption = (index: number) => {
    const { selectedOptions } = this.state
    const removedOption = selectedOptions[index]
    this.setState((prevState) => {
      const newSelectedOptions = prevState.selectedOptions.filter((_, i) => i !== index)
      this.props.onSelectedOptionsChange(newSelectedOptions)
      return {
        options: [...prevState.options, removedOption],
        selectedOptions: newSelectedOptions,
      }
    })
  }

  render() {
    const { options } = this.state
    const { selectedOptions } = this.state

    return (
      <div className='select-dropdown'>
        <div className='select-dropdown-placeholder'>
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option, index) => (
              <NuiBadge
                content={option.title}
                key={`courses:filter:category:selected:${index}`}
                background={option.color}
                style={{
                  height: 'fit-content',
                }}
                icon='Xmark'
                onClick={() => {
                  this.handleRemoveOption(index)
                }}
              />
            ))
          ) : (
            <p>Select Options...</p>
          )}
        </div>
        <div className='select-dropdown-content'>
          {options.length > 0 ? (
            options.map((option, index) => (
              <NuiBadge
                style={{
                  height: 'fit-content',
                }}
                icon='ListSelect'
                content={option.title}
                background={option.color}
                key={`lessons:filter:category:options:${index}`}
                onClick={() => {
                  this.handleSelectChange(option.id)
                }}
              />
            ))
          ) : (
            <p>Keine weiteren Kategorien</p>
          )}
        </div>
      </div>
    )
  }
}
