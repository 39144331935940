import React, { SVGProps } from 'react'

export default function Vials(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21 21H3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 12H5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 12H15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7 18C5.89543 18 5 17.1046 5 16V3H9V16C9 17.1046 8.10457 18 7 18Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 18C15.8954 18 15 17.1046 15 16V3H19V16C19 17.1046 18.1046 18 17 18Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
