import React from 'react'
import { TCard } from '../cards.types'
import '../../../styles/card_simple.css'

export const Card = ({ title, body, thumb, link, key, onClick }: TCard): JSX.Element => {
  return (
    <div className='nuiCardSimple' onClick={onClick}>
      <article className='nuiCardSimpleArticle' key={key ? `card:simple:${key}` : `card:simple`}>
        <figure className='nuiCardSimpleFigure'>
          <img src={thumb} alt='Preview' className='nuiCardSimpleArticleImg' />
        </figure>
        <div className='article-preview'>
          <h2>{title}</h2>
          <p>
            {body}
            {link?.map((item, index) => {
              return (
                <p
                  key={key ? `card:simple:${key}:link:${index}` : `card:simple:link:${index}`}
                  onClick={item.onClick}
                  className='read-more'
                  title={item.title}
                >
                  {item.title}
                </p>
              )
            })}
          </p>
        </div>
      </article>
    </div>
  )
}
