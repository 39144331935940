import React, { SVGProps } from 'react'

export default function OrthogonalView(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 21V3H21V21H3Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 16.5H12H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 12H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 7.5H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5 3V12V21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 3V21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 3V21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
