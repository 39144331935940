import React, { SVGProps } from 'react'

export default function HandBrake(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 16V14V12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 9V8.5V8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <circle cx='12' cy='12' r='8' stroke='currentColor' strokeWidth='1.5' />
      <path
        d='M3.9532 4.5C2.12105 6.46488 1 9.10152 1 12C1 14.8985 2.12105 17.5351 3.9532 19.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.0469 4.5C21.879 6.46488 23.0001 9.10152 23.0001 12C23.0001 14.8985 21.879 17.5351 20.0469 19.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
