import React, { Component } from "react";
import { NuiBadge, NuiButton, NuiCardFlip } from "nui";
import "./index.css";
import { IndexCard } from "../index_card";
import { IndexCardStatusType } from "../asstes/types/index_crad.types";
import { NuiIconsType } from "nui/dist/esm/icons";
import moment from "moment";
import { Category } from "../category";

interface CardContainerProps {
    cards: IndexCard[]
    updateFunction: (newValues: any) => void
    row: IndexCardStatusType
    leftButton?: NuiIconsType
    rightButton?: NuiIconsType
}

interface CardContainerState {
    cards: IndexCard[];
    touchStartX: number;
    touchEndX: number;
    swipedRight: boolean;
}

export class CardContainer extends Component<CardContainerProps, CardContainerState> {
    constructor(props: CardContainerProps) {
        super(props);
        this.state = {
            cards: props.cards,
            touchStartX: 0,
            touchEndX: 0,
            swipedRight: false,
        };
    }

    handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        console.log('Touch Start', event.touches)
        this.setState({
            touchStartX: event.touches[0].clientX,
            swipedRight: false,
        });
    };
    handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        console.log('Touch Move', event.touches)
        this.setState({
            touchEndX: event.touches[0].clientX
        });
    };
    handleTouchEnd = () => {
        console.log('Handle Touch')
        const { touchStartX, touchEndX } = this.state;
        const deltaX = touchEndX - touchStartX;

        if (deltaX > 50) {
            this.handleSwipe(true);
        } else if (deltaX < -50) {
            this.handleSwipe(false);
        }
    };
    findOldestCardInRow = (row: IndexCardStatusType) => {
        const { cards } = this.state;
        const cardsInRow = cards.filter(card => card.type === row);
        if (cardsInRow.length === 0) {
            return null;
        }
        return cardsInRow.reduce((oldestCard, currentCard) =>
            moment(currentCard.updated).isBefore(moment(oldestCard.updated)) ? currentCard : oldestCard
        );
    };
    handleSwipe = (like: boolean) => {
        this.setState(prevState => {
            const oldestCardInRow = this.findOldestCardInRow(this.props.row);
            console.log('oldestCardInRow', oldestCardInRow)
            if (!oldestCardInRow) {
                return prevState;
            }
            oldestCardInRow.changeType(like ? "success" : "failed");
            oldestCardInRow.lastUpdate();

            if ((like && oldestCardInRow.type === "success") || (!like && oldestCardInRow.type === "failed")) {
                this.props.updateFunction(prevState.cards);
                return {
                    ...prevState,
                    swipedRight: like,
                };
            } else {
                this.props.updateFunction(prevState.cards.filter(card => card !== oldestCardInRow));
                return {
                    ...prevState,
                    swipedRight: like,
                    cards: prevState.cards.filter(card => card !== oldestCardInRow),
                };
            }
        });
    };
    render() {
        const { cards } = this.state;
        const { row, leftButton, rightButton } = this.props;

        return (
            <div className="nuiSwipeContainer">
                <div
                    className="nuiSwipeContainer--cards"
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                >
                    {cards?.filter((card) => card.type === row)
                        .sort((a, b) => moment(a.updated).diff(b.updated))
                        .map((card, index) => (
                            <NuiCardFlip
                                keyword={card.keyname}
                                explanation={card.explanation}
                                detail={card.description}
                                identifer={`${card.type}:${index + 1}`}
                                style={{ zIndex: cards.length - index, marginLeft: index === 0 ? 0 : -10 * index } as React.CSSProperties}
                            />
                        ))}
                </div>

                <div className="nuiSwipeContainer--buttons">
                    <NuiButton
                        icon={leftButton ? leftButton : "NavArrowLeft"}
                        disabled={cards.length === 0}
                        style={{ minWidth: "5px" }}
                        text=""
                        onClick={() => this.handleSwipe(false)}
                    />
                    <NuiButton
                        icon={rightButton ? rightButton : "NavArrowRight"}
                        disabled={cards.length === 0}
                        style={{ minWidth: "5px" }}
                        text=""
                        onClick={() => this.handleSwipe(true)}
                    />
                </div>
            </div>
        );
    }
}


type DropdownSearchProps = {
    options: Category[]
    categorys: Category[]
    onSelectedOptionsChange: (selectedOptions: Category[]) => void
}
type DropdownSearchStates = {
    selectedOption: Category | null
    selectedOptions: Category[]
    options: Category[]
    categorys: Category[]
}

export class DropdownSearch extends Component<DropdownSearchProps, DropdownSearchStates> {

    constructor(props: DropdownSearchProps) {
        super(props);
        this.state = {
            selectedOption: null,
            selectedOptions: [],
            options: this.props.options,
            categorys: this.props.categorys
        };
    }

    handleSelectChange = (selectedValue: string) => {
        const { categorys } = this.state;
        const wantedOptions = categorys.filter(category => category.id === selectedValue);

        if (wantedOptions.length > 0) {
            this.setState(prevState => {
                const newSelectedOptions = [...prevState.selectedOptions, wantedOptions[0]];
                this.props.onSelectedOptionsChange(newSelectedOptions);
                return {
                    selectedOption: wantedOptions[0],
                    selectedOptions: newSelectedOptions,
                    options: prevState.options.filter(option => option.id !== selectedValue)
                };
            });
        }
    };

    handleRemoveOption = (index: number) => {
        const { selectedOptions } = this.state;
        const removedOption = selectedOptions[index];
        this.setState(prevState => {
            const newSelectedOptions = prevState.selectedOptions.filter((_, i) => i !== index);
            this.props.onSelectedOptionsChange(newSelectedOptions);
            return {
                options: [...prevState.options, removedOption],
                selectedOptions: newSelectedOptions
            };
        });
    };


    render() {
        const { options } = this.state;
        const { selectedOptions } = this.state;

        return (
            <div className="select-dropdown">
                <div className="select-dropdown-placeholder">
                    {selectedOptions.length > 0 ? selectedOptions.map((option, index) => (
                        <NuiBadge
                            content={option.title}
                            key={`courses:filter:category:selected:${index}`}
                            background={option.color}
                            style={{
                                height: "fit-content"
                            }}
                            icon="Xmark"
                            onClick={() => { this.handleRemoveOption(index) }}
                        />
                    )) : <p>Select Options...</p>}
                </div>
                <div className="select-dropdown-content">
                    {options.length > 0 ? options.map((option, index) => (
                        <NuiBadge
                            style={{
                                height: "fit-content"
                            }}
                            icon="ListSelect"
                            content={option.title}
                            background={option.color}
                            key={`lessons:filter:category:options:${index}`}
                            onClick={() => { this.handleSelectChange(option.id) }}
                        />
                    )) : <p>Keine weiteren Kategorien</p>}
                </div>
            </div>
        );
    }
}