import React, { SVGProps } from 'react'

export default function Orange(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Orange</title>
      <path d='M0 0h24v24H0V0Zm3.43 20.572h17.143v-3.429H3.43v3.429Z' />
    </svg>
  )
}
