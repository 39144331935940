import React, { SVGProps } from 'react'

export default function ArrowsUpFromLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 20L21 20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 17L6 4M6 4L2 8M6 4L10 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 17V4M18 4L14 8M18 4L22 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
