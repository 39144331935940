import React, { SVGProps } from 'react'

export default function WebWindowEnergyConsumption(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M5 7H6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 17.7143V6.28571C2 5.02335 2.99492 4 4.22222 4H19.7778C21.0051 4 22 5.02335 22 6.28571V17.7143C22 18.9767 21.0051 20 19.7778 20H4.22222C2.99492 20 2 18.9767 2 17.7143Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M11.6667 9L10 12H14L12.3333 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
