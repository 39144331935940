import React, { SVGProps } from 'react'

export default function LongArrowLeftUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.5 10.5L8 7L11.5 10.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 7V13C8 15.2091 9.79086 17 12 17H19'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
