import React, { SVGProps } from 'react'

export default function KeyframeAlignCenter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.6799 12.3841L12.4609 17.4469C12.2211 17.7347 11.7789 17.7347 11.5391 17.4469L7.32009 12.3841C7.13467 12.1616 7.13467 11.8384 7.32009 11.6159L11.5391 6.55312C11.7789 6.26527 12.2211 6.26527 12.4609 6.55312L16.6799 11.6159C16.8653 11.8384 16.8653 12.1616 16.6799 12.3841Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 22V20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 4V2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22 12H20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
