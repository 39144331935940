import React, { SVGProps } from 'react'

export default function IceCream(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14 18V20C14 21.1046 13.1046 22 12 22V22C10.8954 22 10 21.1046 10 20V18'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M5 12H19' stroke='currentColor' strokeWidth='1.5' />
      <path
        d='M7.00084 17.9994C5.89594 17.9996 5 17.1041 5 15.9992L5 8.99975C5 5.13375 8.13401 1.99975 12 1.99975C15.866 1.99975 19 5.13375 19 8.99975V15.9997C19 17.1043 18.1046 17.9997 17 17.9997C13.6669 17.9994 10.3339 17.9986 7.00084 17.9994Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
