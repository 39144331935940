import React, { SVGProps } from 'react'

export default function FastArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11 6L5 12L11 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 6L13 12L19 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
