import React, { SVGProps } from 'react'

export default function Position(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 19V21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 12H3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 5V3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 12H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
