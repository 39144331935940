import React, { Component } from "react";
import "../styles/flyout.css";
import { NuiButton } from "nui";
import { Category } from "../../category";

interface FlyoutProps {
    title: string
    onClose: () => void
    children: React.ReactNode
    categoryArea: Category[]
    style?: React.CSSProperties
}

export class NuiFlyout extends Component<FlyoutProps> {
    constructor(props: FlyoutProps) {
        super(props);
    }

    render() {
        const { title, onClose, children, style, categoryArea } = this.props
        return (
            <div
                className="flyout"
                style={style}
            >
                <div className="flyout-header">
                    <></>
                    <h3>{title}</h3>
                    <NuiButton
                        icon="Xmark"
                        text=""
                        color="secondary"
                        style={{
                            minWidth: "15px"
                        }}
                        onClick={() => onClose()}
                    />
                </div>
                <div className="flyoutTags">
                    {categoryArea.map((category: Category) => {
                        return category.render();
                    })}
                </div>
                {children}
            </div>
        );
    }
}