import React, { SVGProps } from 'react'

export default function FilterList(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 6H21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 12L17 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 18L13 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
