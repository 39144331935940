import React, { SVGProps } from 'react'

export default function Archive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 6L17 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 9L17 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 17H15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 12H2.6C2.26863 12 2 12.2686 2 12.6V21.4C2 21.7314 2.26863 22 2.6 22H21.4C21.7314 22 22 21.7314 22 21.4V12.6C22 12.2686 21.7314 12 21.4 12H21M3 12V2.6C3 2.26863 3.26863 2 3.6 2H20.4C20.7314 2 21 2.26863 21 2.6V12M3 12H21'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
