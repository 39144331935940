import React, { SVGProps } from 'react'

export default function Radar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Radar</title>
      <path d='M12 0L2.197 23.975 12 19.952 21.803 24z' />
    </svg>
  )
}
