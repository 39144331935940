import React, { SVGProps } from 'react'

export default function GoogleDriveWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.14286 3.00375L14.8571 3M9.14286 3.00375L2 15.0038M9.14286 3.00375L14.0446 12.5M14.8571 3L5.575 21M14.8571 3L21.5 14M5.575 21L2 15.0038M5.575 21H12.0036M2 15.0038H12.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 16L18 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 22.01L18.01 21.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
