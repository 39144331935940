import React, { SVGProps } from 'react'

export default function CompAlignRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21 22V2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 16H5C3.89543 16 3 15.1046 3 14L3 10C3 8.89543 3.89543 8 5 8H15C16.1046 8 17 8.89543 17 10V14C17 15.1046 16.1046 16 15 16Z'
        stroke='currentColor'
      />
    </svg>
  )
}
