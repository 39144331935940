import React, { SVGProps } from 'react'

export default function CloudSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M12 8C8.72727 8 8.72727 10 8.72727 11C7.81818 11 6 11.5 6 13.5C6 15.5 7.81818 16 8.72727 16H15.2727C16.1818 16 18 15.5 18 13.5C18 11.5 16.1818 11 15.2727 11C15.2727 10 15.2727 8 12 8Z'
        stroke='currentColor'
        strokeLinejoin='round'
      />
    </svg>
  )
}
