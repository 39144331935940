import React, { SVGProps } from 'react'

export default function PipeThreed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 20C6.68629 20 4 17.3137 4 14C4 10.6863 6.68629 8 10 8C13.3137 8 16 10.6863 16 14C16 17.3137 13.3137 20 10 20Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.773 4.74173C11.8576 3.66513 13.3511 3 15 3C18.3137 3 21 5.68629 21 9C21 10.5367 20.4223 11.9385 19.4722 13'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 21L9.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 3L19.5 4.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 9.5L10.5 5L10.75 4.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 18L19.2188 13.2812' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
