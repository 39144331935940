import React, { SVGProps } from 'react'

export default function EcologyBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 19V5C4 3.89543 4.89543 3 6 3H19.4C19.7314 3 20 3.26863 20 3.6V16.7143'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M10 14C10 14 10.9 10.8824 13 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.8022 12.4245L12.6677 12.4372C10.9758 12.5961 9.469 11.3541 9.30214 9.66298C9.13527 7.97187 10.3715 6.47208 12.0634 6.31311L15.049 6.03257C15.2406 6.01457 15.4111 6.15518 15.43 6.34663L15.6847 8.92756C15.8589 10.6929 14.5683 12.2586 12.8022 12.4245Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6 17L20 17' stroke='currentColor' strokeLinecap='round' />
      <path d='M6 21L20 21' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
