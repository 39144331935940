import React, { SVGProps } from 'react'

export default function PrecisionTool(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 6L12 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 16L12 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 12H6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 12H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
