import React, { SVGProps } from 'react'

export default function FxTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 15V9C2 5.68629 4.68629 3 8 3H16C19.3137 3 22 5.68629 22 9V15C22 18.3137 19.3137 21 16 21H8C4.68629 21 2 18.3137 2 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M6 15V9L11 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.99998 12H9.57141' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13 15L15.5 12M15.5 12L18 9M15.5 12L13 9M15.5 12L18 15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
