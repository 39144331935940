import React, { SVGProps } from 'react'

export default function Euro(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.5 4.80423C17.4428 4.28906 16.2552 4 15 4C10.5817 4 7 7.58172 7 12C7 16.4183 10.5817 20 15 20C16.2552 20 17.4428 19.7109 18.5 19.1958'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5 10H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 14H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
