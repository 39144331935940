import React, { SVGProps } from 'react'

export default function SidebarCollapse(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      strokeWidth='1.5'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19 21L5 21C3.89543 21 3 20.1046 3 19L3 5C3 3.89543 3.89543 3 5 3L19 3C20.1046 3 21 3.89543 21 5L21 19C21 20.1046 20.1046 21 19 21Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.25 10L5.5 12L7.25 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 21V3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
