import React, { SVGProps } from 'react'

export default function Codepen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M21 9V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 15V9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 3V9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 15L3 9L12 3L21 9L12 15Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21L3 15L12 9L21 15L12 21Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
