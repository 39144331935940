import { Lesson } from "../../lessons";
import { lBios } from "./brs/bios";
import { lHardwarekomponenten } from "./brs/hardwareko";
import { lBetriebssystem } from "./brs/os";
import { brsVonNm } from "./brs/vnm";
import { lCSauth } from "./cybersecurity/authentifizierung";
import { lCSCrypto } from "./cybersecurity/verschluesselungsverfahren";
import { lNw } from "./network/network";
import { lSQLobject } from "./sql/objects";
import { lSQLeinleitung } from "./sql/sql";
import { lSQLenv } from "./sql/umgebungen";
import { lSArchitecture } from "./storage/architecture";
import { lSFestplattenverbund } from "./storage/festplatten";
import { lSParitaeten } from "./storage/paritaet";
import { lSProtokolle } from "./storage/protokolle";

export const lessons: Lesson[] = []

export { brsVonNm } from './brs/vnm'
lessons.push(brsVonNm)

export { lBios } from './brs/bios'
lessons.push(lBios)

export { lHardwarekomponenten } from './brs/hardwareko'
lessons.push(lHardwarekomponenten)

export { lBetriebssystem } from './brs/os'
lessons.push(lBetriebssystem)

export { lSArchitecture } from './storage/architecture'
lessons.push(lSArchitecture)

export { lSParitaeten } from './storage/paritaet'
lessons.push(lSParitaeten)

export { lSFestplattenverbund } from './storage/festplatten'
lessons.push(lSFestplattenverbund)

export { lSProtokolle } from './storage/protokolle'
lessons.push(lSProtokolle)

export { lSQLeinleitung } from './sql/sql'
lessons.push(lSQLeinleitung)

export { lSQLenv } from './sql/umgebungen'
lessons.push(lSQLenv)

export { lSQLobject } from './sql/objects'
lessons.push(lSQLobject)

export { lCSauth } from './cybersecurity/authentifizierung'
lessons.push(lCSauth)

export { lCSCrypto } from './cybersecurity/verschluesselungsverfahren'
lessons.push(lCSCrypto)

export { lNw } from './network/network'
lessons.push(lNw)