import React, { SVGProps } from 'react'

export default function HeartArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.47752 14.8833L3.65376 12.9929C1.44875 10.7072 1.44875 7.01723 3.65376 4.73157C5.88044 2.42345 9.50794 2.42345 11.7346 4.73157L11.9998 5.00642L12.2648 4.73173C13.3324 3.6245 14.7864 3 16.3053 3C17.8242 3 19.2781 3.62444 20.3458 4.73157C21.4063 5.83045 22 7.31577 22 8.86222C22 10.4087 21.4062 11.8941 20.3458 12.9929C19.7427 13.618 19.1442 14.2497 18.5465 14.8833'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 21.5V11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16 17.5C14.4379 19.0621 13.5621 19.9379 12 21.5C10.4379 19.9379 9.5621 19.0621 8 17.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
