import React, { SVGProps } from 'react'

export default function Linux(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.5 20C3.5 20 4.5 19.2 4.5 18C4.5 16.8 4.5 13.3333 4.5 11C4.5 7.5 7.6 4 12 4'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M21.5 20C20.5 20 19.5 19.2 19.5 18V11C19.5 7.5 16.4 4 12 4'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M12 19C14.7614 19 17 17.8807 17 16.5C17 15.1193 14.7614 14 12 14C9.23858 14 7 15.1193 7 16.5C7 17.8807 9.23858 19 12 19Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75036 15C7.28731 14.3647 7 13.4793 7 12.5C7 10.567 8.11929 9 9.5 9C10.8807 9 12 10.567 12 12.5C12 12.9554 11.9379 13.3904 11.8249 13.7895'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2496 15C16.7127 14.3647 17 13.4793 17 12.5C17 10.567 15.8807 9 14.5 9C13.1193 9 12 10.567 12 12.5C12 12.9554 12.0621 13.3904 12.1751 13.7895'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.5 12V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 12V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
