import React, { SVGProps } from 'react'

export default function BatterySevenFive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M23 10V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M1 16V8C1 6.89543 1.89543 6 3 6H18C19.1046 6 20 6.89543 20 8V16C20 17.1046 19.1046 18 18 18H3C1.89543 18 1 17.1046 1 16Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M4 14.4V9.6C4 9.26863 4.26863 9 4.6 9H13.4C13.7314 9 14 9.26863 14 9.6V14.4C14 14.7314 13.7314 15 13.4 15H4.6C4.26863 15 4 14.7314 4 14.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
