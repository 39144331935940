import React, { SVGProps } from 'react'

export default function SmartphoneDevice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 16.01L12.01 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7 19.4V4.6C7 4.26863 7.26863 4 7.6 4H16.4C16.7314 4 17 4.26863 17 4.6V19.4C17 19.7314 16.7314 20 16.4 20H7.6C7.26863 20 7 19.7314 7 19.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
