import React, { SVGProps } from 'react'

export default function FavouriteWindow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V13'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M22 17.2798C22 17.8812 21.7625 18.4588 21.3383 18.8861C20.3619 19.8701 19.415 20.8961 18.4021 21.8443C18.17 22.0585 17.8017 22.0507 17.5795 21.8268L14.6615 18.8861C13.7795 17.9972 13.7795 16.5623 14.6615 15.6734C15.5522 14.7758 17.0032 14.7758 17.8938 15.6734L17.9999 15.7803L18.1059 15.6734C18.533 15.2429 19.1146 15 19.7221 15C20.3297 15 20.9113 15.2428 21.3383 15.6734C21.7625 16.1007 22 16.6784 22 17.2798Z'
        stroke='currentColor'
        strokeLinejoin='round'
      />
      <path d='M2 7L22 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 5.01L5.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 5.01L8.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 5.01L11.01 4.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
