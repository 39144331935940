import React, { SVGProps } from 'react'

export default function Bandcamp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Bandcamp</title>
      <path d='M0 18.75l7.437-13.5H24l-7.438 13.5H0z' />
    </svg>
  )
}
