import React, { useContext, useEffect, useState } from "react";
import { TimeTrackingProps } from "../asstes/types/time_tracking.types";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "../auth/keycloak";
import { useNuiContext } from "../app_context/app_context";
import { Course } from "../course";
import { Lesson } from "../lessons";
import { v4 as uuidv4 } from 'uuid';


export const TimeTracking = ({ children }: TimeTrackingProps) => {
    const { courses, lessons } = useNuiContext();
    const location = useLocation();

    const { keycloak } = useKeycloak()
    const savedTimeTracking = localStorage.getItem('learnitnow:timetracking')
    const savedTimeTrackingID = savedTimeTracking ? JSON.parse(savedTimeTracking) : null;


    const tarckingTime = () => {
        if (location.pathname.includes("/courses")) {
            const coursesPath = "/courses/";
            const lessonPath = "/lesson/";

            if (location.pathname.includes(coursesPath)) {
                const courseID = location.pathname.substring(location.pathname.indexOf(coursesPath) + coursesPath.length).split('/')[0];
                const lessonID = location.pathname.substring(location.pathname.indexOf(lessonPath) + lessonPath.length).split('/')[0];

                const course: Course | undefined = courses.find((course: Course) => course.id === courseID);
                const lesson: Lesson | undefined = course?.lessons.find((lesson: Lesson) => lesson.id === lessonID);

                const setNewTime = () => {
                    if (lesson) {
                        console.log(`[Time Tracking // Lesson] Visit the Course ${course?.title} and have the lesson ${lesson?.title}`);
                        const timeId: string = uuidv4()
                        localStorage.setItem('learnitnow:timetracking', JSON.stringify({ aid: timeId, type: "lesson", id: lesson.id }))
                        lesson.trackingTime(timeId, "start")
                    } else {
                        const timeId: string = uuidv4()
                        if (savedTimeTrackingID !== null) {
                            console.log(`[Time Tracking // Courses] Stop the last activity: ${savedTimeTrackingID} and start the new ${timeId}`);
                            const lastCourse: Course | undefined = courses.find((course: Course) => course.id === courseID);
                            lastCourse?.trackingTime(savedTimeTrackingID, "end")
                        }
                        console.log(`[Time Tracking // Courses] Visit the Course ${course?.title}`);
                        localStorage.setItem('learnitnow:timetracking', JSON.stringify({ aid: timeId, type: "course", id: course?.id }))
                        course?.trackingTime(timeId, "start")

                    }
                }
                console.log(`[Time Tracking]`, savedTimeTrackingID);
                if (savedTimeTrackingID?.type === "course") {
                    if (savedTimeTrackingID.id !== course?.id) {
                        courses.find(course => course.id === savedTimeTrackingID.id)?.trackingTime(savedTimeTrackingID.aid, "end")
                        setNewTime()
                    }
                } else if (savedTimeTrackingID?.type === "lesson") {
                    if (savedTimeTrackingID.id !== lesson?.id) {
                        lessons?.find((lesson) => lesson.id === savedTimeTrackingID.id)?.trackingTime(savedTimeTrackingID.aid, "end")
                        setNewTime()
                    }
                } else {
                    setNewTime()
                }
            } else {
                console.log('[Time Tracking // Courses] Visit Course Page');
            }
        } else {
            console.log('[Time Tracking]', location)
        }
    }

    useEffect(() => {
        if (keycloak?.profile?.email) {
            tarckingTime()
        } else {
            console.log('[Time Tracking] Not Loggin')
        }
    }, [location])

    return (
        <div>
            {children}
        </div>
    );
}
