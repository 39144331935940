import React, { SVGProps } from 'react'

export default function NonBinary(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 9C15.3137 9 18 11.6863 18 15C18 18.3137 15.3137 21 12 21C8.68629 21 6 18.3137 6 15C6 11.6863 8.68629 9 12 9ZM12 9V3'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 4L15 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 4L9 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
