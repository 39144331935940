import React, { SVGProps } from 'react'

export default function Kedro(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Kedro</title>
      <path d='m12 0 12 12-12 12L0 12 12 0z' />
    </svg>
  )
}
