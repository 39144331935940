import React, { useEffect, useState } from "react";
import { Course } from ".";
import { useNavigate, useParams } from "react-router-dom";
import { Category } from "../category";
import { NuiButton, NuiCard, NuiScrolling, NuiTable, NuiInput, NuiCardLarge, NuiSwitch, NuiBadge, NuiMenuPoint } from "nui";
import { Lesson } from "../lessons";
import { useNuiContext } from "../app_context/app_context";
import { NuiFlyout } from "../asstes/flyout/flyout";
import { isMobile } from 'react-device-detect';

export const CoursesPage = ({ changeNav }: { changeNav: React.Dispatch<React.SetStateAction<NuiMenuPoint[] | undefined>> }): JSX.Element => {
    const { categorys, courses } = useNuiContext();
    const { id, lesson } = useParams();
    const navigate = useNavigate()
    const [showFlyout, setShowFlyout] = useState<boolean>(id ? true : false);
    const [detailCourse, setDetailCourse] = useState<Course | undefined>(undefined);
    const [filteredCourses, setFilteredCourses] = useState<Course[] | undefined>(undefined)
    const [displayType, setDisplayType] = useState<"table" | "cards">("cards")
    // SELECT FILTER VALUES

    const [options, setOptions] = useState<Category[]>(categorys);
    const [selectedOption, setSelectedOption] = useState<Category>();
    const [selectedOptions, setSelectedOptions] = useState<Category[]>([]);
    // FILTER VALUES
    const [filterText, setFilterText] = useState<string>('')
    const [hasCourses, setHasCourses] = useState<boolean>(false)

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        const wantedOptions = categorys.filter(category => category.id === selectedValue);
        setSelectedOption(wantedOptions[0]);
        setSelectedOptions([...selectedOptions, wantedOptions[0]]);
        setOptions(options.filter(option => option.id !== selectedValue));
    };

    const handleRemoveOption = (index: number) => {
        const removedOption = selectedOptions[index];
        setOptions([...options, removedOption]);
        setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
    };

    const filterByCategory = (courses: Course[], selectedCategories: Category[]): Course[] => {
        if (selectedCategories.length === 0) {
            return courses;
        }
        return courses.filter(course =>
            course.categorys.some(category =>
                selectedCategories.some(selectedCategory =>
                    selectedCategory.id === category.id
                )
            )
        );
    };


    const searchInCourses = (text: string, children: boolean, categorys: Category[]): Course[] => {
        const regex = /[a-zA-Z0-9]/;
        const filterRegex = new RegExp(text, 'i');
        let filteredCourses: Course[] = [];

        // Filter courses by selected categories
        filteredCourses = filterByCategory(courses, categorys);

        // Filter courses by whether they have children or not
        if (children === true) {
            filteredCourses = filteredCourses.filter((course: Course) => {
                return course.courses.length !== 0;
            });
        } else {
            console.warn('[System//Course] No Course have sub Courses');
        }

        // If the search text is not valid, return the filtered courses
        if (!regex.test(text)) {
            console.warn('[System//Course] No Valid Text Search Request');
            return filteredCourses;
        }

        // Filter courses by title based on the search text
        return filteredCourses.filter((course: Course) => {
            return filterRegex.test(course.title);
        });
    };

    useEffect(() => {
        setFilteredCourses(searchInCourses(filterText, hasCourses, selectedOptions))
    }, [filterText, hasCourses, selectedOptions])

    useEffect(() => {
        if (id) {
            setShowFlyout(true);
            const foundCourse = courses.find(course => course.id === id);
            if (foundCourse) {
                setDetailCourse(foundCourse);
            }
        } else {
            setShowFlyout(false);
            setDetailCourse(undefined);
        }
        if (!lesson) {
            changeNav(undefined)
        }
    }, [id]);

    return (
        <div className="content-page">
            <div className="searchBar" style={{ width: !isMobile && showFlyout ? '50%' : 'calc(100% - 5rem)' }}>
                <NuiInput
                    key='search'
                    type='text'
                    placeholder='Search'
                    icon='Search'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.target.value)}
                />
                <NuiSwitch
                    key="courses:filter:sub_course"
                    text={`Hat ${hasCourses ? '' : 'keine'} Sub Kurse`}
                    onClick={() => { setHasCourses(!hasCourses) }}
                    defaultChecked={hasCourses}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: "row"
                }}>
                    <select onChange={handleSelectChange}>
                        <option key={`courses:filter:category:options:default`} defaultValue="" selected disabled>Filter nach Kategorien</option>
                        {options.map((option, index) => (
                            <option key={`courses:filter:category:options:${index}`} value={option.id}>{option.title}</option>
                        ))}
                    </select>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}>
                        {selectedOptions.map((option, index) => (
                            <NuiBadge
                                content={option.title}
                                key={`courses:filter:category:selected:${index}`}
                                background={option.color}
                                style={{
                                    height: "fit-content"
                                }}
                                icon="NavArrowLeft"
                                onClick={() => { handleRemoveOption(index) }}
                            />
                        ))}
                    </div>
                </div>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr"
                }}>
                    <NuiButton icon="Table" style={{ minWidth: "10px", marginRight: "0px" }} onClick={() => setDisplayType("cards")} />
                    <NuiButton icon="Table2Columns" style={{ minWidth: "10px", marginLeft: "5px" }} onClick={() => setDisplayType("table")} />
                </div>
            </div>
            <div className="content-body"
                style={{
                    marginTop: "95px",
                    width: !isMobile && showFlyout ? '50%' : 'calc(100% - 5rem)'
                }}
            >
                {filteredCourses && filteredCourses.length !== 0 && displayType === "table" ?
                    <div style={{ width: "100vw" }}>
                        <NuiTable
                            itemsPerPage={50}
                            title=""
                            columns={[
                                {
                                    title: 'Title',
                                    render: (item: Course) => <div>{item.title}</div>
                                },
                                {
                                    title: 'Beschreibung',
                                    render: (item: Course) => <div>{item.description}</div>
                                },
                                {
                                    title: 'Categorys',
                                    // icon: 'Apple',
                                    render: (item: Course) => item.categorys.map((cat: Category) => {
                                        return cat.render()
                                    })
                                },
                                {
                                    title: 'Anzahl an Kursen',
                                    render: (item: Course) => <div>{item.courses.length}</div>
                                },
                                {
                                    title: 'Anzahl an Stunden',
                                    render: (item: Course) => <div>{item.lessons.length}</div>
                                },
                                {
                                    title: 'Details',
                                    render: (item: Course) => <NuiButton
                                        icon="NavArrowRight"
                                        onClick={() => { navigate(`/courses/${item.id}`) }}
                                        style={{
                                            minWidth: "10px"
                                        }}
                                    />
                                },
                            ]}
                            data={filteredCourses}
                        /> </div>
                    : <></>}
                {filteredCourses && filteredCourses.length !== 0 && displayType === "cards" ? filteredCourses.map((course: Course) => {
                    return (
                        <NuiCardLarge
                            key={`course:${course.id}`}
                            title={course.title}
                            body={
                                <div>
                                    {course.description ? course.description : ""}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap"
                                        }}
                                    >
                                        {course.categorys.map((category: Category) => {
                                            return category.render()
                                        })}
                                    </div>
                                </div>
                            }
                            thumb={course.thumb}
                            buttons={{
                                topRight: {
                                    text: " Show Details!",
                                    icon: "ArrowRight",
                                    onClick: () => { navigate(`/courses/${course.id}`) },
                                },
                                topLeft: {
                                    text: "Favorisieren",
                                    icon: "Star",
                                    onClick: () => { alert('Click Link') },
                                },
                            }}
                        />
                    )
                }) : <></>}
            </div>

            {
                detailCourse && (
                    <NuiFlyout
                        title={detailCourse.title}
                        style={{
                            minWidth: !isMobile && showFlyout ? '40%' : '0%'
                        }}
                        onClose={() => navigate('/courses')}
                        categoryArea={detailCourse.categorys}
                    >
                        <div className="flyout-body">
                            {detailCourse.courses.length !== 0 ? (
                                <div key={`course:${id}:sub_course`}>
                                    <NuiScrolling title="Sub Kurse">
                                        {detailCourse.courses.map((course: Course, index: number) => (
                                            <NuiCard
                                                key={`course:${course.id}:sub_course:${index}`}
                                                title={course.title}
                                                thumb={course.thumb ? course.thumb : "https://avatars.githubusercontent.com/u/65290559?v=4"}
                                                body={
                                                    <div>
                                                        {course.description ? course.description : ""}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                flexWrap: "wrap"
                                                            }}
                                                        >
                                                            {course.categorys.map((category: Category) => {
                                                                return category.render();
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                                link={[{
                                                    onClick: () => { navigate(`/courses/${course.id}`) },
                                                    title: "Kurs anzeigen",
                                                }]}
                                            />
                                        ))}
                                    </NuiScrolling>
                                </div>
                            ) : (
                                <div key={`course:${id}:sub_course`}>
                                    <NuiScrolling title="Sub Kurse">
                                        <h3>Dieser Kurse besitzt keine Sub Kurse</h3>
                                    </NuiScrolling>
                                </div>
                            )}
                        </div>

                        <div className="flyout-body">
                            {detailCourse.lessons.length !== 0 ? (
                                <div key={`course:${id}:lessons`}>
                                    <NuiScrolling title="Stunden">
                                        {detailCourse.lessons.map((lesson: Lesson, index: number) => (
                                            <NuiCard
                                                onClick={() => navigate(`/courses/${id}/lesson/${lesson.id}`)}
                                                key={`course:${id}:lessons:${lesson.id}:${index}`}
                                                title={lesson.title}
                                                thumb={lesson.thumb ? lesson.thumb : "https://avatars.githubusercontent.com/u/65290559?v=4"}
                                                body={
                                                    <div>
                                                        {lesson.description ? lesson.description : ""}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                flexWrap: "wrap"
                                                            }}
                                                        >
                                                            {lesson.categorys.map((category: Category) => {
                                                                return category.render()
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </NuiScrolling>
                                </div>
                            ) : (
                                <div key={`course:${id}:lessons`}>
                                    <NuiScrolling title="Stunden">
                                        <h3>Dieser Kurse besitzt noch keine Sub Kurse</h3>
                                    </NuiScrolling>
                                </div>
                            )}
                        </div>
                    </NuiFlyout>
                )
            }
        </div >
    );
};
