import React, { SVGProps } from 'react'

export default function Acura(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Acura</title>
      <path d='M21.2227 20.927c-2.0316 2.2464-6.0946 2.9691-9.2395 2.9496-3.145 0-7.208-.7032-9.2396-2.9496C.634 18.6415-.1669 14.598.0284 10.8866c.215-3.9458 1.5627-6.8369 3.399-8.4191C5.4197.7681 9.1311.1039 12.0026.1235c2.8715-.0196 6.5829.6446 8.5753 2.344 1.8362 1.5822 3.184 4.4733 3.399 8.419.1757 3.692-.6447 7.7355-2.7543 10.0405zM11.2995 9.7536h1.3673l-.0195-8.634c-.4102-.0195-.4883-.0195-.6641-.0195-.1759 0-.2735 0-.6642.0196-.0195 0-.0195 8.634-.0195 8.634zm-5.2937 12.072c.1172-2.0316.5274-6.7002.7618-8.5754.3712-2.2855.7423-3.0082 1.172-4.278.3517-1.0352 2.1683-5.6843 3.0473-7.8525 0 0-2.2659.039-4.1607.7032C5.4588 2.2917 4.111 3.1903 3.193 4.538c-2.1487 3.0863-1.6994 8.4972-1.5822 9.9818.332 3.7895 1.8557 6.0945 4.3951 7.3056zm11.6812.1171c-.586-1.5627-3.3989-8.2823-4.9225-10.8998h-1.5822C9.639 13.6604 6.8458 20.38 6.2597 21.9427c1.7385.7423 3.9263.9181 5.704.9377 1.797-.0196 3.9653-.1758 5.7233-.9377zm3.0668-17.4046c-.918-1.3283-2.2659-2.2464-3.6332-2.7152-1.8948-.6642-4.1608-.7032-4.1608-.7032.879 2.1682 2.6957 6.8173 3.0473 7.8526.4298 1.2697.801 1.9729 1.172 4.2779.2345 1.8752.6447 6.5438.7619 8.5753 2.5394-1.2306 4.063-3.516 4.395-7.3251.1173-1.465.5666-6.876-1.5822-9.9623z' />
    </svg>
  )
}
