import React, { SVGProps } from 'react'

export default function Paypal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 17.5L6 3L13 3C19 3 19 12 13 12H8.7L7.5 17.5H3Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.80005 21L9.80005 6.5L16.8 6.5C22.8 6.5 22.8 15.5 16.8 15.5H12.5L11.3 21H6.80005Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
