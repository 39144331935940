import React, { SVGProps } from 'react'

export default function CandlestickChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M5 16V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21V19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 13V11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 8V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 13V11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 5V3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7 8.6V13.4C7 13.7314 6.73137 14 6.4 14H3.6C3.26863 14 3 13.7314 3 13.4V8.6C3 8.26863 3.26863 8 3.6 8H6.4C6.73137 8 7 8.26863 7 8.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 13.6V18.4C14 18.7314 13.7314 19 13.4 19H10.6C10.2686 19 10 18.7314 10 18.4V13.6C10 13.2686 10.2686 13 10.6 13H13.4C13.7314 13 14 13.2686 14 13.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 5.6V10.4C21 10.7314 20.7314 11 20.4 11H17.6C17.2686 11 17 10.7314 17 10.4V5.6C17 5.26863 17.2686 5 17.6 5H20.4C20.7314 5 21 5.26863 21 5.6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
