import React, { SVGProps } from 'react'

export default function Here(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>HERE</title>
      <path d='M19.29 1.007c-.697.036-1.367.384-2.008 1.036-.97.987-1.104 2.206-.679 3.316l-1.091-1.312c-.106.041-.306.203-.506.406-.572.596-.705 1.516-.558 2.125l-.734-.635-1.45 1.475 1.996 2.03c-1.583-1.326-3.007-1.259-4.323.082-1.331 1.353-1.252 3.006-.147 4.385l-.186-.189c-1.184-1.205-2.328-1.463-3.46-.298-.666.663-.772 1.314-.706 1.897l-2.794-2.83-1.625 1.651 5.372 5.48H0l3.312 3.37 3.282-3.34h3.128l-2.275-2.314c-.798-.8-1.039-1.354-.547-1.855.36-.379.773-.367 1.278.148l2.741 2.803 1.65-1.679-2.049-2.084c1.623 1.368 3.393 1.03 4.738-.351.706-.704 1.09-1.355 1.278-1.801l-1.452-.825a6.544 6.544 0 0 1-.878 1.218c-.799.812-1.543.812-2.048.392l2.94-2.992L17 12.25l1.65-1.678-1.782-1.815c-1.45-1.476-1.025-2.26-.691-2.599.212-.217.425-.378.572-.46a4.183 4.183 0 0 0 .797 1.096c1.728 1.774 3.62 1.53 5.07.042.865-.88 1.279-1.692 1.384-2.099l-1.424-.799a5.902 5.902 0 0 1-1.023 1.489c-.786.812-1.532.813-2.037.394l2.97-3.007-.374-.379C21.13 1.43 20.188.961 19.291 1.007zm-.055 1.918c.273-.002.558.113.838.35l-1.596 1.623c-.452-.527-.465-1.136 0-1.623.226-.23.485-.349.758-.35zm-7.357 7.486c.275-.002.564.113.85.35l-1.61 1.626c-.452-.528-.466-1.151 0-1.626.227-.23.486-.349.76-.35z' />
    </svg>
  )
}
