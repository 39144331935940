import React, { SVGProps } from 'react'

export default function Vercel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Vercel</title>
      <path d='M24 22.525H0l12-21.05 12 21.05z' />
    </svg>
  )
}
