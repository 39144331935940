import React, { SVGProps } from 'react'

export default function Lucid(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Lucid</title>
      <path d='M12 0 3.694 4.8V24L12 19.2Zm0 19.2v4.502h8.305V14.4Z' />
    </svg>
  )
}
