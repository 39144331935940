import React from 'react'
import '../../../styles/text.css'
import { CodeProps, TText } from '../types.text'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark as Style } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkMermaidPlugin from 'remark-mermaid-plugin'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import rehypeStringify from 'rehype-stringify'

const Code = ({ inline, className, children }: CodeProps) => {
  if (inline) {
    return <code className={className}>{children}</code>
  }

  const match = /language-(\w+)/.exec(className || '')
  const language = match ? match[1] : ''

  if (language === 'mermaid') {
    return <div className='mermaid'>{children}</div>
  }

  return (
    <SyntaxHighlighter style={Style} language={language} PreTag='div'>
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  )
}

export const Text = ({ text }: TText) => {
  return (
    <Markdown
      className='markdown-body'
      remarkPlugins={[remarkGfm, [remarkMermaidPlugin as any, { theme: 'light' }]]}
      rehypePlugins={[rehypeRaw, rehypeStringify]}
      components={{ code: Code }}
    >
      {text}
    </Markdown>
  )
}

export default Text
