import React, { SVGProps } from 'react'

export default function Mewe(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>MeWe</title>
      <path d='M3.029 7.712a.768.768 0 100 1.536.768.768 0 000-1.536zm7.883 0a.768.768 0 100 1.536.768.768 0 000-1.536zm4.414 0a.768.768 0 100 1.536.768.768 0 000-1.536zm4.418 0a.768.768 0 100 1.537.768.768 0 000-1.537zm-4.447 2.307c-.27 0-.453.175-.54.428l-1.378 4.175-1.403-4.193a.546.546 0 00-.532-.392c-.296 0-.55.227-.55.523 0 .07.018.148.044.21l1.805 5.056c.105.279.296.444.558.444h.105c.261 0 .461-.165.557-.444l1.36-3.985 1.36 3.985c.096.279.288.444.55.444h.105c.261 0 .462-.174.558-.444l1.804-5.057a.617.617 0 00.052-.218.525.525 0 00-.531-.514c-.262 0-.445.166-.523.383l-1.404 4.202-1.377-4.175c-.079-.253-.27-.428-.541-.428zM.54 10.045a.538.538 0 00-.54.541v5.117c0 .297.227.523.523.523a.52.52 0 00.532-.523V12.05l1.482 2.224c.113.174.253.279.444.279.201 0 .34-.105.454-.28l1.49-2.24v3.661a.54.54 0 00.533.532.53.53 0 00.54-.532v-5.108a.538.538 0 00-.54-.54h-.114a.54.54 0 00-.488.278L3 13.227 1.15 10.333a.565.565 0 00-.497-.288zm8.368 1.439c-1.316 0-2.25 1.081-2.25 2.397v.018c0 1.42 1.03 2.389 2.363 2.389.715 0 1.239-.236 1.657-.61a.46.46 0 00.14-.323.415.415 0 00-.419-.427.391.391 0 00-.27.096 1.655 1.655 0 01-1.09.4c-.68 0-1.212-.418-1.325-1.168h2.885c.27 0 .497-.209.497-.505 0-1.064-.723-2.267-2.188-2.267zm12.904 0c-1.317 0-2.25 1.081-2.25 2.397v.018c0 1.42 1.029 2.389 2.363 2.389.715 0 1.238-.236 1.657-.61a.46.46 0 00.139-.323.415.415 0 00-.418-.427.392.392 0 00-.271.096 1.652 1.652 0 01-1.09.4c-.68 0-1.211-.418-1.325-1.168h2.886c.27 0 .497-.209.497-.505 0-1.064-.724-2.267-2.188-2.267zm-12.913.863c.698 0 1.099.532 1.169 1.212H7.705c.096-.715.549-1.212 1.194-1.212zm12.904 0c.697 0 1.098.532 1.168 1.212h-2.363c.096-.715.55-1.212 1.195-1.212z' />
    </svg>
  )
}
