import React, { SVGProps } from 'react'

export default function SquareWave(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 12H6L6 4L12 4L12 20L18 20L18 12H21'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14.5 12H15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 12H9.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
