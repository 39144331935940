
import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom'
import "./asstes/styles/App.css";
import { UiLibary } from './ui_libary/ui';
import { CoursesPage } from './course/page';
import { NuiMenu, NuiMenuPoint } from 'nui';
import { LessonDetail } from './lessons/details';
import { LessonPage } from './lessons/pages';
import { IndexCardsPage } from './index_card/pages';
import { useKeycloak } from './auth/keycloak';
import gravatar from 'gravatar'
import ProfilePictureStock from './asstes/images/profile.svg'
import { ProfilePage } from './profile';
import PrivateRoute from './auth/privateRoute';

const App = (): JSX.Element => {
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()

  const [leftNav, setLeftNav] = useState<NuiMenuPoint[] | undefined>(undefined)
  const [filteredLeftNav, setFilteredLeftNav] = useState<NuiMenuPoint[] | undefined>(undefined)

  return (
    <div className="App">
      <NuiMenu
        key={'main'}
        mainPoint={{ title: "learnITnow", logo: "https://avatars.githubusercontent.com/u/65290559?v=4", onClick: () => { navigate('/') } }}
        navPoint={[{
          title: "Learning",
          icon: "Learning",
          dropdown: [{
            title: "Courses",
            icon: "Book",
            body: "Sieh dir alle verfügbaren Kurse an!",
            onClick: () => { navigate('/courses') }
          },
          {
            title: "Lessons",
            icon: "Clock",
            body: "Sieh dir alle verfügbaren Stunden an!",
            onClick: () => { navigate('/lessons') }
          },
          {
            title: "Karteikarten",
            icon: "CreditCards",
            body: "Finde hier alle Karteikarten!",
            onClick: () => { navigate('/index-cards') }
          }],
        },
        {
          title: "UI",
          icon: "BookStack",
          onClick: () => navigate('/ui')
        }]}
        profile={{
          username: `${keycloak?.profile?.username}`,
          showenName: `${keycloak?.profile?.firstName} ${keycloak?.profile?.lastName}`,
          picture: keycloak?.profile?.email ? gravatar.url(keycloak?.profile?.email, { s: '32', format: '404', r: 'pg' }) : ProfilePictureStock,
          noActiveClick: () => { localStorage.setItem('noerkelit-want-login', 'true'); keycloak?.login() },
          active: keycloak && keycloak?.token ? true : false
        }}
        profileMenu={[
          {
            title: "Profile",
            icon: "User",
            lastItem: false,
            onClick: () => navigate(`/profile`)
          },
          {
            title: "Bearbeite dein Profile",
            icon: "EditPencil",
            lastItem: true,
            onClick: () => keycloak?.accountManagement()
          },
          {
            title: "Abmelden",
            icon: "LogOut",
            lastItem: false,
            onClick: () => {
              keycloak?.logout()
              localStorage.setItem('noerkelit-want-login', 'false')
            }
          }
        ]}
        leftMenu={filteredLeftNav || leftNav}
        leftMenuSearch={(e: React.ChangeEvent<HTMLInputElement>) => {
          const regex = /[a-zA-Z0-9]/;
          const filterRegex = new RegExp(e.target.value, 'i');
          if (!regex.test(e.target.value)) {
            console.warn('[System//Course] No Valid Text Search Request');
            setFilteredLeftNav(leftNav);
          } else {
            const filteredMenu = leftNav?.filter((point: NuiMenuPoint) => filterRegex.test(point.title));
            setFilteredLeftNav(filteredMenu);
          }
        }}

      />
      <Routes>
        <Route
          path='/courses'
          element={<CoursesPage changeNav={setLeftNav} />}
        >
          <Route
            path='/courses/:id'
            element={<UiLibary />}
          />
        </Route>
        <Route
          path='/courses/:id/lesson/:lesson'
          element={
            <PrivateRoute
              component={<LessonDetail changeNav={setLeftNav} />}
              roles={['default-roles-noerkelit']}
            />
          }
        />
        <Route
          path='/lessons'
          element={<LessonPage />}
        />
        <Route
          path='/index-cards'
          element={
            <PrivateRoute
              component={<IndexCardsPage changeNav={setLeftNav} />}
              roles={['default-roles-noerkelit']}
            />
          }
        />
        <Route
          path='/ui'
          element={
            <PrivateRoute
              component={<UiLibary />}
              roles={['learnitnow_ui_libary']}
            />
          }
        />
        <Route
          path='/profile'
          element={
            <PrivateRoute
              component={<ProfilePage />}
              roles={['default-roles-noerkelit']}
            />}
        />
      </Routes>

    </div>
  );
}

export default App;
