import React, { SVGProps } from 'react'

export default function Npm(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 8L23 8V15L11 15V17L7.5 17V15L1 15V8Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.5 8L7.5 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5 8V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 11V15' stroke='currentColor' strokeLinecap='round' />
      <path d='M5 11V15' stroke='currentColor' strokeLinecap='round' />
      <path d='M11 11V12' stroke='currentColor' strokeLinecap='round' />
      <path d='M20.5 11V15' stroke='currentColor' strokeLinecap='round' />
    </svg>
  )
}
