import React, { SVGProps } from 'react'

export default function Fidoalliance(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Fido Alliance</title>
      <path d='M7.849 7.513a1.085 1.085 0 1 0 1.085 1.086v-.001c0-.599-.486-1.085-1.085-1.085zM4.942 10.553v1.418H6.89v4.793h.704V14.04h.509v2.724h.71v-6.211H4.941zM14.122 11.089H14.1c-.287-.416-.862-.702-1.639-.702-1.489 0-2.797 1.224-2.786 3.319 0 1.936 1.181 3.201 2.659 3.201.797 0 1.56-.361 1.935-1.04l.117.893h1.669V7.651h-1.934zm0 2.904c0 .158-.012.313-.034.465l.002-.017c-.11.532-.574.925-1.13.925h-.014.001c-.797 0-1.318-.659-1.318-1.723 0-.978.446-1.767 1.329-1.767.606 0 1.022.437 1.138.947.014.09.023.194.023.3l-.001.054v-.003zM4.802 8.89l.475-1.6a2.914 2.914 0 0 0-.384-.101l-.019-.003a3.654 3.654 0 0 0-.829-.092 3.73 3.73 0 0 0-1.084.159l.027-.007a2.022 2.022 0 0 0-.38.153l.011-.005a2.624 2.624 0 0 0-.663.475c-.5.49-.754 1.155-.754 1.975v.708H-.001v1.418h1.199v4.793h1.921V11.97h1.199v-1.416H3.119v-.75a1.019 1.019 0 0 1 .23-.713l-.001.002a.736.736 0 0 1 .063-.062l.001-.001s.414-.41 1.389-.14zM20.306 10.388c-2.01 0-3.327 1.286-3.327 3.307s1.393 3.212 3.213 3.212c1.664 0 3.276-1.04 3.276-3.327-.002-1.874-1.267-3.192-3.162-3.192zm-.063 5.126c-.832 0-1.276-.797-1.276-1.871 0-.915.361-1.861 1.276-1.861.871 0 1.234.936 1.234 1.851 0 1.137-.482 1.882-1.234 1.882zM22.493 9.761h.232v.589h.14v-.589h.231v-.117h-.603v.117zM23.799 9.644l-.182.505-.181-.505h-.203v.707h.13V9.78l.198.571h.113l.195-.571v.571h.13v-.707h-.201z' />
    </svg>
  )
}
