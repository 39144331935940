import React from 'react'
import { TMenu } from '../menu.types'
import { NuiIcon } from '../../icons'
import '../../../styles/menu.css'
import { NuiInput } from '../../input'
import { NuiButton } from '../../button'

export const Menu = ({ mainPoint, navPoint, profile, profileMenu, leftMenu, leftMenuSearch }: TMenu) => {
  return (
    <div className='content-body'>
      <nav className='menu__wrapper'>
        {leftMenu && (
          <button
            className={`action-button`}
            onClick={() => {
              if (
                document.querySelector('.navigation__menu_left')?.classList.contains('hide') ||
                document.querySelector('.navigation__menu_left')?.classList.contains('none')
              ) {
                document.querySelector('.navigation__menu_left')?.classList.remove('hide')
                document.querySelector('.navigation__menu_left')?.classList.remove('none')
              } else {
                document.querySelector('.navigation__menu_left')?.classList.add('hide')
              }
            }}
          >
            <NuiIcon icon='Menu' size='l' />
          </button>
        )}
        <div className='logo__wrapper'>
          <img src={mainPoint.logo} title='Logo' className='logo' />
          <p className='page__title'>{mainPoint.title}</p>
        </div>
        <div className='action-buttons'>
          {navPoint.map((menu, navIndex) => {
            return (
              <div key={`menu:nav:item:${navIndex}`} className={`action-button-wrapper`}>
                <button
                  className={`action-button`}
                  onClick={menu.onClick}
                  onMouseEnter={() => {
                    const dropdownEl = document.getElementById(`menu:nav:item:${navIndex}:dropdown`)
                    if (dropdownEl && menu.dropdown) {
                      dropdownEl.style.display = 'block'
                    }
                  }}
                >
                  {menu.icon ? <NuiIcon icon={menu.icon} size='l' /> : <p>{menu.title}</p>}
                </button>
                {menu.dropdown && (
                  <div
                    id={`menu:nav:item:${navIndex}:dropdown`}
                    className='dropdown__wrapper_nav_main'
                    onMouseLeave={() => {
                      const dropdownEl = document.getElementById(`menu:nav:item:${navIndex}:dropdown`)
                      if (dropdownEl && menu.dropdown) {
                        dropdownEl.style.display = 'none'
                      }
                    }}
                  >
                    <ul className='list-items-with-description'>
                      {menu.dropdown.map((menu, index) => {
                        return (
                          <li key={`menu:nav:item:${navIndex}:dropdown:${index}`} onClick={menu.onClick}>
                            <div className='icon-wrapper'>{menu.icon && <NuiIcon icon={menu.icon} size='l' />}</div>
                            <div className='item-title'>
                              <h3>{menu.title}</h3>
                              <p>{menu.body}</p>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
              </div>
            )
          })}
          {profile.active === true ? (
            <img
              id='avatar-navbar'
              className='avatar-profile'
              src={profile.picture}
              alt='Profile'
              onClick={() => {
                document.querySelector('.navigation__menu')?.classList.remove('none')
                document.querySelector('.navigation__menu')?.classList.remove('hide')
              }}
            />
          ) : (
            <NuiButton
              onClick={profile.noActiveClick}
              icon='LogIn'
              color='primary'
              fill={true}
              style={{ width: '25px' }}
            />
          )}
        </div>
      </nav>
      {leftMenu && (
        <div className='navigation__menu_left none'>
          {profile.active === true && (
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <NuiIcon
                key='nav:side:left:close'
                icon='Xmark'
                onClick={() => {
                  document.querySelector('.navigation__menu_left')?.classList.add('hide')
                }}
                size='xl'
                style={{ cursor: 'pointer' }}
              />
              <NuiInput
                key='nav:side:left:seacrh'
                placeholder='Search'
                icon='Search'
                type='text'
                onChange={(e) => leftMenuSearch && leftMenuSearch(e)}
              />
            </div>
          )}
          {profile.active === true && (
            <ul className='navigation__menu__items'>
              {leftMenu.map((menu, index) => {
                return (
                  <>
                    <li onClick={menu.onClick} key={`menu:profile:item:${index}`}>
                      {menu.icon && <NuiIcon icon={menu.icon} />}
                      {menu.title}
                    </li>
                    {menu.lastItem && (
                      <li className='separator'>
                        <hr />
                      </li>
                    )}
                  </>
                )
              })}
            </ul>
          )}
        </div>
      )}
      <div className='navigation__menu none'>
        <div className='avatar-wrapper'>
          <img className='avatar-profile' src={profile.picture} alt='Profile' />
          <div className='avatar-name-wrapper'>
            <div className='avatar-name-alias'>{profile.showenName}</div>
            <div className='avatar-name'>{profile.username}</div>
          </div>
          <NuiIcon
            icon='Xmark'
            className='close-button'
            onClick={() => {
              document.querySelector('.navigation__menu')?.classList.add('hide')
            }}
            size='l'
          />
        </div>
        <ul className='navigation__menu__items'>
          {profileMenu.map((menu, index) => {
            return (
              <>
                <li onClick={menu.onClick} key={`menu:profile:item:${index}`}>
                  {menu.icon && <NuiIcon icon={menu.icon} />}
                  {menu.title}
                </li>
                {menu.lastItem && (
                  <li className='separator'>
                    <hr />
                  </li>
                )}
              </>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
