import React, { SVGProps } from 'react'

export default function Box(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 12L14 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 3L21 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M21 7V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
