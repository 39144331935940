import React, { SVGProps } from 'react'

export default function VoiceScan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 6L12 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 9L9 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 11L18 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 11L6 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 7L15 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 3H3V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 3H21V6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 21H3V18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 21H21V18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
