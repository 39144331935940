import { Lesson } from "../../../lessons"
import { securityCategory } from "../../category"

const cCsCrypto = `
### Verschlüsselungsverfahren

Auch bei den Verschlüsselungsverfahren, gibt es drei essenzielle Prinzipen.

<ol>
    <li><a href="#symmetrische">Symmetrische</a></li>
    <li><a href="#asymmetrische">Asymmetrische</a></li>
    <li><a href="#id-basierte">ID-basierte</a></li>
</ol>

#### Symmetrische

Bei symmetrischen Verfahren werden Ver- und Entschlüsselung mit demselben Schlüssel durchgeführt. Das heißt, Sender und Empfänger müssen vorab diesen geheimen Schlüssel vereinbart haben und der Schlüssel muss natürlich geheim gehalten werden, um die Information zu schützen. Ein Sicherheitsproblem stellt hier die Übergabe des geheimen Schlüssels dar.

Außer den beiden Kommunikationspartnern darf niemand den Schlüssel kennen (Secret-Key-Verfahren). Symetrische Schlüssel erreichen akzeptabel hohe Verschlüsselungsraten. Sie eignen sich deshalb besonders gut zur Verschlüsselung großer Datenmengen.
Der gräßte Nachteil der Symmetrischen verschlüsslung ist, um die Nachricht verwerten zu können, muss der Empfänger den Schlüssel bereits besitzen. Die Übertragung des Schlüssels stellt einen Schwachpunkt dar.

Verschlüsselungsverfahren

- Zeichenchiffrierung (überholt)
- Stromchiffrierung verschlüsselt den Klartext Bit-weise über XOR-Operation ( RC4-Algoritmus/ WEP)
- Blockchiffrierung; fasst Bitgruppen des Klartextes in Blöcken zusammen. (Trippel DES/AES)

![symmetrische_verschlüsselungsverfahren](https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Orange_blue_symmetric_cryptography_de.svg/1200px-Orange_blue_symmetric_cryptography_de.svg.png)

#### Asymmetrische

Bei asymmetrischen Methoden gibt es nicht einen Schlüssel zum ver- und entschlüsseln, sondern zwei; einen public key, der für jeden zugänglich ist, und einen private key, der geheim gehalten werden muss. Der Text wird mit dem public key verschlüsselt und kann nur mit dem private key entschlüsselt werden. Hier muss der Verschlüsselungsschlüssel nicht geheim gehalten werden, es muss aber sichergestellt werden, dass der public key auch wirklich zu dem Empfänger gehört.

| Vorteil                                                                                                                                      | Nachteil                                                                             |
| -------------------------------------------------------------------------------------------------------------------------------------------- | ------------------------------------------------------------------------------------ |
| Nur der private Key muss geheim gehalten werden                                                                                              | Operationen werden durch das Verfahren wesentlich komplexer                          |
| Die Verschlüsslung wird vom Sender mit dem public-Key generiert, diese Nachricht kann nur mit dem passenden private-Key entschlüsselt werden | Im Vergleich zur Symetrischen Verschlüsselung ist das Public-Key-Verfahren langsamer |

![symmetrische_verschlüsselungsverfahren](https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Orange_blue_public_private_keygeneration_de.svg/170px-Orange_blue_public_private_keygeneration_de.svg.png)

Um sicher zustellen, dass das Key Paar von einer Vertraunswürdigen Quelle stammt wird durch die **P**ublic-**K**ey-**I**nfrastructure folgende möglichkeiten zur verfügng gestellt:

- Zertifizierungseinrichtung

es besteht die Möglichkeit sich die Identität des Schlüsselinhabers von einer Zertifizierungsstelle bestätigen zu lassen (VeriSign, Deutsche Post, usw.) Die Überprüfung der Schlüsselinhaber geschieht durch eine Certification Authority (CA), manchmal als Trust Center bezeichnet.

- Web of Trust

Die Authentifizierung eines public Keys durch viele bereits bekannte Kommunikationspartner bestätigen lassen, <a href="pgp">PGP</a>.

#### Hybride Verschlüsselungsverfahren

Aufgrund der Langsamkeit des asymetrischen und die Verwundbarkeit des symetrischen Verfahrens, ist man dazu übergegangen, in Verschlüsselungssystemen beide Verfahren zu kombinieren.

- zunächst wird durch eine zufällige Generierung vom Absender ein Sitzungsschlüssel für das symetrische Verschlüsselungsverfahren erzeugt.
- dann wird dieser Sitzungsschlüssel mit Hilfe des öffentlichen Schlüssels des Empfängers verschlüsselt. Dadurch wird ein sicherer Kanal für den Schlüsselaustausch geschaffen.
- die eigentlichen "Nutzdaten" werden nun mittels des Sitzungsschlüssels mit symetrischen Verfahren chiffriert und übertragen
- der Empfänger entschlüsselt zunächst den Sitzungsschlüssel mit Hilfe seines privaten Schlüssels für das asymetrische Verfahren und kann dann mit diesem Schlüssel die chifrierte Nachricht entschlüsseln und einsehen.

1. Diffie und Hellmann-Verfahren
2. RSA Verfahren (Erzeugung von asymetrischen Schlüsseln)

![hybride-verschlüsselungsverfahren](https://www.elektronik-kompendium.de/sites/net/bilder/09080714.png)

#### ID-basierte

ID-basierte Verschlüsselungsverfahren sind eine besondere Teilmenge der asymmetrischen Verfahren, bei denen es nur einen geheimen Entschlüsselungsschlüssel gibt. Zur Verschlüsselung müssen nur die öffentlichen Parameter des Verfahrens und die Identität des Empfängers bekannt sein. Der Empfänger kann sich bei einer zentralen Instanz, die über eine Art Superschlüssel verfügt, einen zu seiner Identität gehörigen Entschlüsselungsschlüssel ausstellen lassen. Die Besonderheit bei diesem Verfahren ist, dass kein Schlüssel mehr übertragen wird, die Verschlüsselung kann sogar stattfinden, bevor der Empfänger seinen geheimen Schlüssel kennt.
`
const cCsHash = `
## Hash und Fingerprint

- Hash leitet sich von "to hash up" (zerhacken, zerkleiner,...) ab
- Synonymn = (MD)Message Digest (Auswahl, Auslese,...)

Eine Hashfunktion generiert aus einer Zeichenfolge beliebiger Länge eine zweite Zeichenfolge fixer Länge, meist 128 Bit.
Diese zweite Zeichenfolge bezeichnet man als Message Authentication Code (MAC).

Eine Hashfunktion muss dabei folgende Anforderungen erfüllen:

- Sie muss eindeutig sein. Eine identische Eingangszeichenfolge muss zur selben Zeichenfolge am Ausgang führen.
- Sie muss einfach zu berechnen sein.
- Ihre inverse Funktion muss schwierig zu berechen sein.
- Sie muss kollisionsresistent sein. d.h. zwei unterschiedliche Einganszeichenfolgen dürfen nicht die gleiche Ausgabe hervorrufen.

Aktuelle Version MD 5 (Message Digest Version5 -128Bit) Aktuelle Version SHA 1 (Secure Hash Algorithm1 -160Bit) Aktuelle Version SHA 256 (Secure Hash Algorithm -256Bit)
Da Hashes nicht reversibel sind, lassen sie sich nicht zur Verschlüsselung einsetzen. Dagegen lassen sie sich bei der Authentifizierung einsetzen. Passwörter werden gerne als MAC abgelegt, damit auch der Administrator sie nicht im Klartext lessen kann. Das Login überprüft dann lediglich ob der MAC des gesendeten Passwortes mit dem abgelegten Hash-Wert übereinstimmt. Auch für digitale Unterschriften (Digital Signatur) lässt sich ein MAC bestens einsetzen.
`
const cCsPgp = `
## PGP

**P**retty **G**ood **P**rivacy bietet ein Freeware Komplett System, mit dem Verschlüsselungsaufgaben des Alltags problemlos bewältigt werden können:

- Verschlüsslung von Emails
- Verschlüsseln von Daten
- Verschlüsseln von Netzwerkverbindungen

Hierzu bietet PGP:

- generierung von Public-Privat-Keys
- verwaltung von Schlüsseln
- Einsatz gängiger symetrischer Verschlüsselungsverfahren
- Digitale Signaturen
- Zertifizierung (Web of Trust)
`
const cCsPap = `
## PAP

Das **P**assword **A**uthentication **P**rotocol ist eine Peer to Peer Authentifizierungsmethode, die Passwörter zur Validierung von Benutzern verwendet.
Die Daten werden als Klartext versendet und mit hilfe eines Handshakes validiert.
PAP ist ein passwortbasiertes Client-Server-Authentifizierungsprotokoll, es ist einfach zu implementieren und eine einfache Authentifizierungsmethode .

Das Verfahren lässt sich in zwie einfache Schritte unterteilen:

1. Der Benutzer oder Client, der versucht, eine PPP-Sitzung mit dem Server einzurichten, sendet einen Benutzernamen und ein Kennwort über ein Authentifizierungsanforderungspaket an den Server.
2. Der Server überprüft, ob sie mit den im System gespeicherten Daten übereinstimmen. Wenn eine Übereinstimmung verifiziert wird, wird ein Authentifizierungsbestätigungs-Antwortpaket an den Benutzer zurückgesendet und der Server baut die PPP-Sitzung zwischen dem Server und dem Benutzer auf. Wenn die Anmeldeinformationen nicht übereinstimmen, wird die PPP-Sitzung nicht eingerichtet und ein Autorisierungs-nak-Antwortpaket wird an den Benutzer zurückgesendet.

PAP besitz folgende Risiken:

- Passwort wird im Klartext übertragen, könnte ein Opfer von einem Packet-Sniffer-Angriffe werden.
- unbegrenzte Anzahl an Anmeldeversuchen, ermöglicht einen Brute Force Angriff.
- es ist möglich, falls Name und Passwort abgefangen werden, diese erneut zur Anmeldung zu verwenden, ermöglicht eine Replay Attack.
`
const cCsChap = `
## CHAP

**C**hallenge-**H**andshake **A**uthentication **P**rotocol verwendet einen Drei-Wege-Handshake-Prozess. Dies fügt dem Authentifizierungsprozess über das Kennwortauthentifizierungsprotokoll eine zusätzliche Sicherheitsebene hinzu und trägt dazu bei, Anmeldeinformationen vor böswilligen Akteuren zu schützen.
Es wurde entwickelt um die Sicherheitslücken von PAP zuschließen.
Im Gegensatz zu PAP sendet CHAP das Kennwort nicht über das Netzwerk. Stattdessen verwendet CHAP kryptografische Methoden, die die Verwendung eines verschlüsselten Hashs umfassen, für den sowohl der Server als auch der Client den geheimen Schlüssel haben.

CHAP kann auch so eingerichtet werden, dass während der Sitzung wiederholt Authentifizierungen ausgeführt werden, um Angreifer davon abzuhalten, eine PPP-Verbindung während der Sitzung aufzubauen, wenn ein Port offen gelassen wurde, nachdem das Remote-Gerät getrennt wurde. CHAP fügt der PPP-Sitzung Sicherheitsfunktionen hinzu, die PAP nicht hat.

1. Der Authentifikator sendet eine Authentifizierungsaufforderung, nachdem die Verbindung hergestellt wurde. Der Netzwerkzugriffsserver führt beim Benutzer eine Hostnamensuche durch und sendet eine „Ask-Challenge“, die eine zufällig generierte Challenge-Zeichenfolge enthält, an den Benutzer, um die CHAP-Authentifizierung zu initiieren.
2. Der Benutzer führt dann eine Hostnamensuche durch. Der Benutzer verwendet das Kennwort, das sowohl dem Benutzer als auch dem Server bekannt ist, um einen verschlüsselten Einweg-Hash zu erstellen. Dieser verschlüsselte Hash basiert auf der Challenge-Zeichenfolge.
3. Als Nächstes überprüft der Server den Hash, indem er ihn entschlüsselt, um sicherzustellen, dass er mit der anfänglichen Abfragezeichenfolge übereinstimmt. Wenn die Zeichenfolgen übereinstimmen, wird eine Authentifizierungserfolgsmeldung gesendet und die PPP-Sitzung aufgebaut. Wenn die Zeichenfolgen nicht übereinstimmen, wird eine Authentifizierungsfehlermeldung generiert und die Sitzung beendet.
`
const cCsDigitaleSignatur = `
## Digitale Signatur

Eine digitale Signatur dient dazu, elektronische Dokumente digital zu unterzeichnen und abzusichern. Hierfür werden kryptografische Verfahren genutzt, die auf den Private und Public Keys basieren.

Vorgehen bei der digitalen Signatur

1. Der Absender unterzeichnet den Hashwert seiner Nachricht mit seinem Private Key.
2. Dieser verschlüssele Hashwert der Nachricht bildet den Fingerprint.
3. Nun werden Nachricht, Public-Key und Fingerprint an den Empfänger gesendet.
4. Jetzt kann der Empfänger aus der empfangenen Nachricht einen Hashwert bilden.
5. Der Empfänger kann den Fingerprint mit dem Public-Key des Senders entschlüsseln.
6. Die beiden Hashwerte werden nun verglichen.
7. So kann der Empfänger die Echtheit des Sender sowie die Unversehrtheit der Nachricht prüfen.
`
const cCsHttps = `
## SSL/TLS

**S**ecure **S**ocket **L**ayer und **T**ransport **L**ayer **S**ecurity sind Verschlüsselungsprotokolle für die Transportschicht des Internets. Ihre Aufgabe ist es, die Datenströme zwischen Client und Server zu verschlüsseln.
TSL ist dabei die weiter entwicklung von SSL.

### HTTPS

Ein Klassisches beispiel ist HTTPS, wo bei Internetseiten mit hilfe von einem SSL/TLS Certificat vershen sind.

#### Datenkommunikation

Der Vorgang bei einer Datenkommunikation mit einer Https Verbindung ist:

1. Der HTTPS Server bildet aus der Server-ID einen Hash
2. Der Hash wird vom Server mit seinem Privat-Key verschlüsselt
3. Nun sendet der HTTPS Server Server-ID, Public-Key und verschlüsselten Hashwert an den Client
4. Der Client bildet nun aus der Server-ID ebenfalls einen Hashwert
5. Der Client entschlüsselt mit dem Public-Key des HTTPS Servers den empfangenen verschlüsselten Hashwert.
6. Die beiden Hashwerte werden verglichen und bei Übereinstimmung kann ein symmetrischer Session-Key für die Datenübertragung ausgetauscht werden.

#### Zertifizierung des Public-Keys

Das Problem bei HTTPS Verbindungen besteht darin, dass es möglich ist den Public-Key des Servers "zu fälschen". Hier wird dem Client der Public-Key eines Angreifers untergeschoben. Um dies zu verhindern wird der Hash des Public-Key des Servers mit dem Private-Key einer sogenannten CA (Trust Center) verschlüsselt. Der Client kann diesen mit dem Public-Key der CA entschlüsseln.

1. Der Server sendet den mit dem Private Key der CA verschlüsselten Hashwert seines Public-Keys und seinen Public-Key.
2. Der Client entschlüsselt den verschlüsselten Hashwert des Servers mit dem Public-Key der CA.
3. aus dem Public-Key des Servers bildet der Client einen Hashwert.
4. die Hashwerte werden nun verglichen und bei Übereinstimmung kann ein symmetrischer Session-Key für die Datenübertragung ausgetauscht werden.

`

export const lCSCrypto = new Lesson('Verschlüdsslungsverfahren', 'text')
    .addCategory(securityCategory)
    .addContent({
        id: 'curity:crypto:einleitung',
        index: 1,
        content: cCsCrypto
    })
    .addContent({
        id: 'curity:crypto:hash',
        index: 2,
        content: cCsHash
    })
    .addContent({
        id: 'curity:crypto:pgp',
        index: 3,
        content: cCsPgp
    })
    .addContent({
        id: 'curity:crypto:pap',
        index: 4,
        content: cCsPap
    })
    .addContent({
        id: 'curity:crypto:chap',
        index: 5,
        content: cCsChap
    })
    .addContent({
        id: 'curity:crypto:chap',
        index: 6,
        content: cCsDigitaleSignatur
    })
    .addContent({
        id: 'curity:crypto:https',
        index: 7,
        content: cCsHttps
    })