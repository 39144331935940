import React, { SVGProps } from 'react'

export default function CornerBottomRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M20.01 8.00001L20 8.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.01 4.00001L20 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.01 4.00001L16 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.01 4.00001L12 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.00999 4.00001L7.99999 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 4.00001L3.99999 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 8.00001L3.99999 8.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 12L3.99999 12.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 16L3.99999 16.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 20L3.99999 20.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.00999 20L7.99999 20.0111' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20.01 12.01V20.01H12.01V12.01H20.01Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
