import React, { SVGProps } from 'react'

export default function BrightCrown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M22 12L23 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 2V1' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 23V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 20L19 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 4L19 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20L5 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 4L5 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 12L2 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16.8 15.5L18 8.5L13.8 10.6L12 8.5L10.2 10.6L6 8.5L7.2 15.5H16.8Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
