import React from 'react';
import { CategoryType } from '../asstes/types/category.types';
import { Category } from '../category';
import { LessonType, LessonTypes, TConetnt } from '../asstes/types/lessons.types';
import { Activitys } from '../asstes/types/general.types';
import moment from 'moment';
import { IndexCard } from '../index_card';

export class Lesson implements LessonType {
    id: string
    parent: string
    title: string
    categorys: Category[]
    description?: string
    thumb: string
    type: LessonTypes
    contents: TConetnt[]
    activitys: Activitys[]
    myLesson: boolean
    indexCards: IndexCard[]

    constructor(title: string, type: LessonTypes) {
        this.id = title.toLowerCase().replace(/ /g, '_').replace(',', '')
        this.parent = ""
        this.title = title;
        this.categorys = []
        this.thumb = "https://avatars.githubusercontent.com/u/65290559?v=4"
        this.type = type
        this.contents = []
        this.activitys = []
        this.myLesson = false
        this.indexCards = []
    }
    // Parent
    addParent = (parent: string): Lesson => {
        this.parent = parent
        return this;
    }
    // DESCRIPTION
    addDescription = (description: string): Lesson => {
        this.description = description
        return this
    }
    // CATEGORYS
    addCategory = (category: Category): Lesson => {
        const alreadyExist = this.categorys.includes(category)
        if (alreadyExist) {
            console.warn(`[System//Lesson] The ${category.id} is already in the Lesson ${this.id}`)
            return this
        } else {
            this.categorys.push(category)
            return this
        }
    }
    removeCategory = (category: CategoryType): Lesson => {
        const index = this.categorys.findIndex(cat => cat.id === category.id);
        if (index !== -1) {
            this.categorys.splice(index, 1);
            console.log(`[System//Lesson] The ${category.id} category has been removed from the Lesson ${this.id}`);
            return this
        } else {
            console.warn(`[System//Lesson] The ${category.id} category does not exist in the Lesson ${this.id}`);
            return this
        }
    }
    // CONTENT
    addContent = (content: TConetnt): Lesson => {
        const alreadyExist = this.contents.some(item => item.id === content.id);
        if (alreadyExist) {
            console.warn(`[System//Lesson] The content ${content.id} is already in the Content ${this.id}`);
        } else {
            this.contents.push(content);
        }
        return this;
    }
    removeContent = (content: TConetnt): Lesson => {
        const index = this.contents.findIndex(con => con.id === content.id);
        if (index !== -1) {
            this.contents.splice(index, 1);
            console.log(`[System//Lesson] The ${content.id} content has been removed from the Lesson ${this.id}`);
            return this
        } else {
            console.warn(`[System//Lesson] The ${content.id} content does not exist in the Lesson ${this.id}`);
            return this
        }
    }
    // INDEX CARDS
    addIndexCard = (indexCard: IndexCard): Lesson => {
        const alreadyExist = this.indexCards.some(item => item.id === indexCard.id);
        if (alreadyExist) {
            console.warn(`[System//Lesson] The Index Card ${indexCard.id} is already in the Content ${this.id}`);
        } else {
            this.indexCards.push(indexCard);
        }
        return this;
    }
    removeIndexCard = (indexCard: IndexCard): Lesson => {
        const index = this.indexCards.findIndex(ind => ind.id === ind.id);
        if (index !== -1) {
            this.contents.splice(index, 1);
            console.log(`[System//Lesson] The ${indexCard.id} content has been removed from the Lesson ${this.id}`);
            return this
        } else {
            console.warn(`[System//Lesson] The ${indexCard.id} content does not exist in the Lesson ${this.id}`);
            return this
        }
    }
    // Time
    trackingTime = (aid: string, action: "start" | "end"): Lesson => {
        if (action === 'end') {
            const activity = this.activitys.find((activity) => activity.aid === aid);
            if (activity) {
                const startTime = moment(activity.start);
                if (startTime.isValid()) {
                    const durationInSeconds = moment().diff(startTime, 'seconds');
                    console.log('[learnITnow Lesson] Duration:', durationInSeconds);
                    activity.duration = durationInSeconds;
                } else {
                    console.error('[learnITnow Lesson] Invalid start time:', activity.start);
                }
            }
        } else {
            this.myLesson = true
            this.activitys.push({
                aid: aid,
                type: "lesson",
                id: this.id,
                start: moment(),
                duration: 0
            });
        }
        this.indexCards.forEach((ic: IndexCard) => {
            ic.activate()
        })
        console.log('[learnITnow Lesson]', this);
        return this;
    }
}