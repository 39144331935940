import React, { SVGProps } from 'react'

export default function ConstrainedSurface(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 23C1.89543 23 1 22.1046 1 21C1 19.8954 1.89543 19 3 19C4.10457 19 5 19.8954 5 21C5 22.1046 4.10457 23 3 23Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 23C19.8954 23 19 22.1046 19 21C19 19.8954 19.8954 19 21 19C22.1046 19 23 19.8954 23 21C23 22.1046 22.1046 23 21 23Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3C5 4.10457 4.10457 5 3 5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 5C19.8954 5 19 4.10457 19 3C19 1.89543 19.8954 1 21 1C22.1046 1 23 1.89543 23 3C23 4.10457 22.1046 5 21 5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 12.0002C13 10.7536 11.8415 9.5 10.4285 9.5H9.5715C8.1515 9.5 7 10.6196 7 12.0002C7 13.1898 7.855 14.1853 9 14.438C9.18769 14.4793 9.37932 14.5001 9.5715 14.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 12.0003C11 13.2464 12.1585 14.5 13.5715 14.5H14.4285C15.8485 14.5 17 13.3809 17 12.0003C17 10.8102 16.145 9.81416 15 9.56203C14.8123 9.5207 14.6207 9.4999 14.4285 9.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M21 19L21 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 19L3 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 3L19 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 21L19 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
