import React, { SVGProps } from 'react'

export default function Darty(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Darty</title>
      <path d='M0 0v24h24V0zm11.83 1.503A10.56 10.56 0 0 1 22.62 12.06c.06 5.76-4.68 10.5-10.56 10.5A10.56 10.56 0 0 1 1.5 12.06a10.56 10.56 0 0 1 9.77-10.53 10.56 10.56 0 0 1 .56-.027zM2.4 9.24s.18 1.2.18 2.82c0 1.56-.18 2.76-.18 2.76h2.04c.66 0 1.86-.48 1.86-2.76-.06-2.34-1.2-2.82-1.86-2.82zm4.68 0s-.12 1.44-.42 2.82c-.3 1.32-.72 2.76-.72 2.76h1.8s-.06-.24 0-.72.18-.72.18-.72h.84s.12.3.18.72c.06.48 0 .72 0 .72h3.42s-.12-.36-.18-.96 0-.96 0-.96.24.36.48.96c.18.6.18.96.18.96h1.98s-.3-.66-.72-1.38c-.42-.72-.66-1.08-.66-1.08s.96-.3.96-1.56v-.3c.12-.06.42-.12.9-.12 0 0 .06 1.02 0 1.98s-.18 2.52-.18 2.52h1.8s-.12-1.56-.18-2.52 0-1.98 0-1.98.48 0 .96.18V9.42c.18.36.72 1.5.96 2.64.3 1.44.12 2.76.12 2.76h1.8s-.18-1.38.12-2.76 1.08-2.82 1.08-2.82h-1.62l-.42 1.62-.42-1.62h-4.98v.96a1.35 1.35 0 0 0-1.2-.96h-2.52s.18 1.02.18 2.82c0 1.08-.06 1.92-.12 2.34-.18-.54-.42-1.44-.6-2.34-.3-1.32-.42-2.82-.42-2.82zm5.16.96s.6 0 .6.72c.06.72-.6.72-.6.72zm-8.187.115c.15-.009.747.058.747 1.685 0 1.92-.78 1.68-.78 1.68v-3.36s.012-.003.033-.005zm4.346.065.36 1.86h-.718z' />
    </svg>
  )
}
