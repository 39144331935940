import React, { SVGProps } from 'react'

export default function CornerTopRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M20.01 16.01L20 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.01 20.01L20 19.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.01 20.01L16 19.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.01 20.01L12 19.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.00999 20.01L7.99999 19.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 20.01L3.99999 19.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 16.01L3.99999 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 12.01L3.99999 11.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 8.01L3.99999 7.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.00999 4.01L3.99999 3.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.00999 4.01L7.99999 3.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.01 12V4H12.01V12H20.01Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
