import { Lesson } from "../../../lessons"
import { rechennetzCategory } from "../../category"

const vHardware = `
Damit ein Computer so funktioniert, wie wir es gewöhnt sind, werden unterschiedliche komponenten benötigt.
Die folgenden sind diese Komponeten.

- Mainboard
- <a href="#cpu">CPU</a>
- <a href="#ram">RAM</a>
- <a href="#gpu">GPU</a>
- <a href="#festplatten">Festplatten</a>
`
const vMainboard = `
### Mainboard

Das Main- / Motherboard ist die wichtigste Leiterplatte in einem Computer. Auf ihr werden alle Komponenten und externen Peripheriegeräte angeschlossen.
`
const vCpu = `
### CPU 

Auf der **C**entral **P**rocessing **U**nit, ist das <a href="#steuerwerk">Steuerwerk</a> als auch das <a href="#rechenwerk">Rechenwerk</a> zuhause. Somit ist die CPU die zentralle Steuerung für alle Geräte, ohne Sie würde eine Computer nicht arbeiten können.
`
const vGpu = `
### GPU

Die **G**raphics **P**rocessing **U**nit berechnet berechnet die Datenstreame um daraus ein Bild zu generieren.
Früher wurde diese aufgabe von der CPU ausgeführt, allerdings waren damit keine Hochauflösende Bilder möglich.
Eine GPU kann mehrer Berechnungen parallel machen, was Sie somit stark von einer CPU unterscheidet, allerdings hat eine CPU eine höhere Herzrate womit einzellene Brechungen schneller funktionieren.
`
export const lHardwarekomponenten = new Lesson('Hardwarekomponenten', 'text')
    .addCategory(rechennetzCategory)
    .addContent({
        id: 'hardwarekomponenten:introduction',
        index: 1,
        content: vHardware
    })
    .addContent({
        id: 'hardwarekomponenten:mainboard',
        index: 2,
        content: vMainboard
    })
    .addContent({
        id: 'hardwarekomponenten:cpu',
        index: 3,
        content: vCpu
    })
    .addContent({
        id: 'hardwarekomponenten:gpu',
        index: 4,
        content: vGpu
    })