import React, { SVGProps } from 'react'

export default function SendDiagonal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_2476_13290)'>
        <path
          d='M22.1525 3.55321L11.1772 21.0044L9.50686 12.4078L2.00002 7.89795L22.1525 3.55321Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M9.45557 12.4436L22.1524 3.55321' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>
  )
}
