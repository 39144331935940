import React, { SVGProps } from 'react'

export default function EuroSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M16 7.50265C15.3565 7.18066 14.6336 7 13.8696 7C11.1802 7 9 9.23858 9 12C9 14.7614 11.1802 17 13.8696 17C14.6336 17 15.3565 16.8193 16 16.4974'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 11H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 13H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
