import React, { SVGProps } from 'react'

export default function MailIn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M5 9L9.5 12L14 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5H16C17.1046 5 18 5.89543 18 7V9'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M23 14H17M17 14L20 11M17 14L20 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
