import React, { SVGProps } from 'react'

export default function RssFeed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 19C12 14.8 9.2 12 5 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 19C19 10.6 13.4 5 5 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 19.01L5.01 18.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
