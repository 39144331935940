import React, { SVGProps } from 'react'

export default function Gamepad(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.5 17.5C20 21 23.9486 18.4151 23 15C21.5753 9.87113 20.8001 7.01556 20.3969 5.50793C20.1597 4.62136 19.3562 4 18.4384 4L5.56155 4C4.64382 4 3.844 4.62481 3.62085 5.515C2.7815 8.86349 2.0326 11.8016 1.14415 15C0.195501 18.4151 4.14415 21 6.64415 17.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 8.5L18.0111 8.51' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.49 7L16.5011 7.01' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.49 10L16.5011 10.01' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 8.5L15.0111 8.51' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 7V10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 8.5H8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 16C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14C6 15.1046 6.89543 16 8 16Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 16C17.1046 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8954 12 14 12.8954 14 14C14 15.1046 14.8954 16 16 16Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
