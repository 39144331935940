import React, { SVGProps } from 'react'

export default function Breaker(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Breaker</title>
      <path d='M13.556,12.924c0.21,0,0.386,0.017,0.585,0.056c1.043,0.206,1.898,0.964,2.246,1.956 c0.101,0.319,0.234,0.797,0.319,1.125l0.111,0.433l0.446-0.009c3.552-0.075,6.456-2.965,6.456-6.546C23.72,4.513,19.195,0,12.281,0 C9.429,0.009,6.725,1.046,4.678,2.715c-2.683,2.2-4.397,5.543-4.397,9.286c0.002,0.96,0.118,1.926,0.334,2.818 c0.788,3.268,2.91,6.015,5.759,7.628c1.723,0.971,3.784,1.547,5.909,1.553c0.521,0,1.052-0.036,1.554-0.099 c3.126-0.405,5.873-2.012,7.765-4.339l0.124-0.152l-0.24-0.868l-0.463,0.122c-1.436,0.381-2.908,0.578-4.463,0.576 c-1.243,0-2.428-0.128-3.598-0.375c-1.054-0.212-1.911-0.979-2.248-1.98c-0.109-0.315-0.158-0.608-0.156-0.958 c-0.011-0.782,0.257-1.425,0.741-1.975C11.848,13.321,12.656,12.924,13.556,12.924z M12.281,1.125 c6.398,0,10.313,4.11,10.313,8.813c0,2.811-2.168,5.119-4.919,5.397c-0.073-0.261-0.152-0.536-0.219-0.746 C15.715,9.132,11.567,4.907,6.23,2.957C7.965,1.78,10.01,1.118,12.281,1.125z M1.405,12.001c0-3.283,1.455-6.227,3.756-8.223 c4.377,1.403,7.964,4.397,10.058,8.371c-0.272-0.12-0.559-0.212-0.859-0.272c-1.219-0.253-2.511-0.394-3.807-0.394 c-3.24,0.006-6.345,0.859-9.001,2.325C1.454,13.222,1.405,12.625,1.405,12.001z M12.729,19.967 c1.224,0.257,2.526,0.398,3.829,0.398c0.996,0,1.986-0.083,2.948-0.234c-1.519,1.352-3.418,2.282-5.519,2.614 c-0.799-0.938-1.493-1.95-2.072-3.032C12.174,19.822,12.446,19.908,12.729,19.967z M12.645,22.871 c-0.12,0.004-0.242,0.006-0.364,0.006c-1.825,0.006-3.486-0.424-4.994-1.209c0.433-1.907,1.172-3.675,2.171-5.277 c0.034,0.294,0.099,0.587,0.186,0.848C10.314,19.28,11.347,21.202,12.645,22.871z M10.453,13.207 c-1.954,2.19-3.43,4.913-4.176,7.864c-2.143-1.421-3.752-3.582-4.467-6.117c2.586-1.508,5.532-2.351,8.744-2.346 c0.18,0,0.358,0.002,0.536,0.008C10.858,12.79,10.644,12.987,10.453,13.207z' />
    </svg>
  )
}
