import React from 'react'
import { TButton } from '../types.button'
import { NuiIcon } from '../../icons'
import '../../../styles/button.css'

export const Button = ({
  text,
  onClick,
  fill,
  color,
  icon,
  style,
  onMouseEnter,
  onMouseLeave,
  disabled,
  ...props
}: TButton) => {
  const getColor = () => {
    if (!color) {
      return 'var(--text)'
    } else if (color === 'primary') {
      return 'var(--color-primary)'
    } else if (color === 'succsess') {
      return 'var(--color-succsess)'
    } else if (color === 'warning') {
      return 'var(--color-warning)'
    } else if (color === 'error') {
      return 'var(--color-error)'
    } else {
      return color
    }
  }

  return (
    <button
      className='nuiButton'
      onClick={onClick}
      style={{
        backgroundColor: fill ? getColor() : 'transparent',
        border: fill ? 'none' : color ? `1px solid ${getColor()}` : 'var(--border)',
        color: fill ? 'var(--background-primary)' : getColor(),
        ...style,
      }}
      {...props}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
    >
      <span className={`nuiButtonContent`}>
        {icon && <NuiIcon icon={icon} size='l' />}
        {text}
      </span>
    </button>
  )
}
