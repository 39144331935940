import React, { RefObject, SVGProps } from 'react'
import { NuiIconsType, Icons } from './Icons'

export type TIconSize = 's' | 'm' | 'l' | 'xl'

export type TIcon = {
  icon: NuiIconsType
  color?: string
  size?: TIconSize
  style?: SVGProps<SVGSVGElement>
  className?: string
  ref?: RefObject<HTMLImageElement>
  onClick?: () => void
}

const getIconComponent = (
  IconComponent: React.ElementType,
  size: number,
  color: string,
  style?: SVGProps<SVGSVGElement>,
  className?: string,
  ref?: RefObject<HTMLImageElement>,
  onClick?: () => void,
): JSX.Element => {
  return (
    <IconComponent style={{ width: size, height: size, color: color, ...style, className, ref }} onClick={onClick} />
  )
}

export const Icon: React.FC<TIcon> = ({ icon, size, color, style, className, ref, onClick }: TIcon): JSX.Element => {
  let iconSize: number
  switch (size) {
    case 's':
      iconSize = 16
      break
    case 'm':
      iconSize = 20
      break
    case 'l':
      iconSize = 24
      break
    case 'xl':
      iconSize = 32
      break
    default:
      iconSize = 16
      break
  }

  const IconComponent = Icons[icon]
  return getIconComponent(IconComponent, iconSize, color ? color : 'currentColor', style, className, ref, onClick)
}
