import React, { SVGProps } from 'react'

export default function Server(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 18.01L6.01 17.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6.01L6.01 5.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 9.4V2.6C2 2.26863 2.26863 2 2.6 2H21.4C21.7314 2 22 2.26863 22 2.6V9.4C22 9.73137 21.7314 10 21.4 10H2.6C2.26863 10 2 9.73137 2 9.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M2 21.4V14.6C2 14.2686 2.26863 14 2.6 14H21.4C21.7314 14 22 14.2686 22 14.6V21.4C22 21.7314 21.7314 22 21.4 22H2.6C2.26863 22 2 21.7314 2 21.4Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
