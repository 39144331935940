import React from 'react'
import '../../../styles/dropdown.css'
import '../../../styles/dropdown_block.css'
import { TDropdown, TDropdownItem } from '../dropdown.types'
import { NuiIcon } from '../../icons'

export const Dropdown = ({ title, items, key, type }: TDropdown): JSX.Element => {
  return (
    <nav
      key={`dropdown:${type === 'block' ? 'block:' : ''}${key}`}
      className={`accordion-container${type === 'block' ? '-block' : ''}`}
    >
      <div
        className={`main-item`}
        onClick={(event) => {
          const target = event.currentTarget as HTMLDivElement
          target.classList.toggle(`main-item--open`)
          target.classList.toggle(`main-item`, !target.classList.contains(`main-item--open`))
        }}
      >
        {title.icon ? <NuiIcon icon={title.icon} size='l' /> : null}
        {title.title}
        <NuiIcon icon='NavArrowRight' size='l' className='expand-icon' />
      </div>
      <ul>
        {type === 'block'
          ? items.map((item, index) => {
              return <DropdownBlockItem item={item} key={index} />
            })
          : items.map((item, index) => <DropdownItem item={item} key={index} />)}
      </ul>
    </nav>
  )
}

const DropdownItem = ({ item, key }: { item: TDropdownItem; key: number }) => {
  return (
    <li key={`dropdown:item:${key}:${item.key}`} onClick={item.onClick}>
      {item.icon && <NuiIcon icon={item.icon} size='l' />}
      {item.title}
    </li>
  )
}

const DropdownBlockItem = ({ item, key }: { item: TDropdownItem; key: number }): JSX.Element => {
  return (
    <li key={`dropdown:block:item:${key}:${item.key}`}>
      <div className='line-vertical'></div>
      <div className='line-horizontal'></div>
      <p title={item.title}>
        {item.icon && <NuiIcon icon={item.icon} size='l' />}
        {item.title}
      </p>
    </li>
  )
}
