import { Lesson } from "../../../lessons"
import { storageCategory } from "../../category"

const cProtokolleAta = `
### ATA

#### SATA
Serial-ATA arbeitet mit seriellen Punkt-zu-Punkt-Verbindungen und ermöglicht höhere Datenraten. Die Kabel benötigen weniger Datenleitungen und sind dünner und flexibler.

#### PATA
Parallel-ATA ist ein Standard, mit dem sich Daten zwischen einem Computer und einem Speicherlaufwerk wie einer Festplatte übertragen lassen. Im Gegensatz zum neueren SATA-Standard werden die Daten nicht seriell, sondern parallel über die Schnittstelle gesendet.

### AoE

**A**TA **o**ver **E**thernet verwendet kein IPv4/IPv6; Es arbeitet direkt auf Ethernet und ist auf das lokale Subnetz beschränkt. Es reicht aus, um sicherzustellen, dass die Schnittstelle aktiv ist.
Die Daten werden auf in Blöcke augfegteilt und über das Ethernet Protokoll versendet.
`

const cProtokolleScsi = `
### SCSI

**S**mall **C**omputer **S**ystem **I**nterface ist ein Protokoll zur Steuerung der Kommunikation zwischen Massenspeicher und Controller. In seiner ursprünglichen Ausführung war SCSI auch ein Bus-System, das eine interne und externe Schnittstelle für Peripherie zur Verfügung stellte. Die Besonderheit dabei, gerätspezifische Eigenschaften verbleiben in den Geräten. Das Betriebssystem selber fragt nur die technischen Daten und veränderbaren Parameter ab.

SCSI besteht grundsätzlich immer aus zwei Teilen: dem Kabelbus, der das Kabel, die Stecker und die elektrischen Signale festlegt. Und das Software-Protokoll, das die Übertragung von Daten und den Zugriff auf den Bus regelt.

#### ISCSI

**I**nternet **S**mall **C**omputer **S**ystem **I**nterface beschreibt einen Netzwerk-Standard, der auf drei standardisierten Protokollen basiert:

- TCP/IP,
- Ethernet,
- SCSI.

Die Technologie ermöglicht es, zentral verfügbare Speicherressourcen zu verbinden (SAN) und auf diese zuzugreifen.

![iscsi](https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/ISCSI-Schema.svg/1200px-ISCSI-Schema.svg.png)

#### SAS

**S**erial **A**ttached **S**CSI ist eine Massenspeicher-Schnittstelle, die SCSI-Kommandos über eine serielle Schnittstelle transportiert.
`
const cProtokolleFc = `
### FC

**F**ibre **C**hannel ist eine logische Erweiterung des SCSI-Busses. Esüb erträgt SCSI-Befehle und Daten in serieller Form in einem SAN.
Im Gegensatz zum SCSI-Bus ist Fibre Channel ein serielles Netz mit

- Knoten,
- Switches

und anderen Kopplungselementen.
Im Gegensatz zur Protokoll-Kombination aus TCP/IP/Ethernet besteht das FC-Protokoll aus einem Guss. Dadurch ist es sehr effizient. Eine weitere Besonderheit ist die Möglichkeit weite Entfernungen mit hoher Übertragungsgeschwindigkeit zu überbrücken.

#### Topologie

##### Fibre Channel Point-to-Point

Die Point-to-Point-Topologie sieht vor, ein Speichergerät an nur einen Server anzubinden. Dafür verwendet man üblicherweise SAS oder SATA.

![fc_p2p](https://www.elektronik-kompendium.de/sites/net/bilder/09050712.gif)

##### Fibre Channel Arbitrated Loop

FC-AL definiert einen unidirektionalen Ring mit bis zu 126 Fibre-Channel-Geräten. Dazu zählen Server und Speichergeräte. Das AL-Protokoll erlaubt immer nur den Datenaustausch zwischen zwei Geräten. Die anderen Geräte müssen warten. Deshalb setzt man den Arbitrated Loop auch nur innerhalb von Disksubsystemen ein, um Festplatten und Bandlaufwerke anzubinden.

![fc_al](https://www.elektronik-kompendium.de/sites/net/bilder/09050713.gif)

##### Fibre Channel Switched Fabric

Die Switched-Fabric-Topologie sieht den Einsatz von Hubs und Switches zum Koppeln der einzelnen Geräte vor. Im Gegensatz zum Arbitrated Loop unterstützen sie den zeitgleichen Datenverkehr. Werden die Switches kaskadiert spricht man von einer Fabric. Im Innern eines solchen Netzwerks werden ausfallsichere Core-Switches eingesetzt. Die Endgeräte werden über Edge-Switches mit den Core-Switches verbunden.
Innerhalb der Fabric können die Endgeräte beliebig miteinander verbunden werden und aufeinander zugreifen.

![fc_al](https://www.elektronik-kompendium.de/sites/net/bilder/09050711.gif)

#### FCoe

Bei Fibre Channel over Ethernet geht es darum, FC-Frames über Ethernet zu übertragen. FCoE kapselt ein FC-Frame und eine kleine Prüfsumme in ein Ethernet-Frame. Dadurch wird Fragmentierung der FC-Frames vermieden.

#### FCIP

FCIP ist eine Ergänzung zu Fibre Channel, um ein vorhandenes IP-Netzwerk mit zu benutzen.
FCIP ist ein Tunneling-Protokoll, um FC-Daten in einem IP-Netzwerk zu übertragen. In der Regel werden die FC-Frames mit IP-Paketen zwischen zwei FC-Switche getunnelt, um zwei Speichernetze miteinander über ein vorhandenes IP-Netzwerk zu verbinden.
`
const cProtokolleNetwerk = `
### Netzwerk

## SMB

Beim **S**erver-**M**essage-**B**lock-Protokoll handelt es sich um ein Client-Server-Kommunikationsprotokoll für den gemeinsamen Zugriff auf Dateien, Drucker, serielle Schnittstellen und andere Ressourcen in einem Netzwerk.

## NAS

Ein **N**etwork **A**ttached **S**torage ist ein Festplattenspeicher, der einem Netzwerk zugänglich gemacht werden kann.
Dieser Speicher kann für die externe Datenverwaltung (Speichererweiterung und Backup-Lösung) genutzt werden.

## SAN

Ein **S**torage **A**rea **N**etwork ist ein dediziertes, unabhängiges Highspeed-Netzwerk, das gemeinsam genutzte Pools von Storage-Geräten verbindet und mehreren Servern bereitstellt. Jeder Server kann auf gemeinsam genutzten Storage zugreifen, als würde es sich um ein direkt an den Server angeschlossenes Laufwerk handeln.

## DAS

**D**irect **A**ttached **S**torage bezeichnet an einen einzelnen Rechner angeschlossene Festplatten, die sich in einem separaten Gehäuse befinden.

## Routing

Ein Storage-Router dient dazu, Daten inerhalb eines Netzwerks zurverfügung zustellen.
Hierzu können diese Daten entweder selbst auf dem Router liegen oder über eine externe Festplatte an ihn angeschlossen sein.
Vereinfacht gesagt, ist ein solcher Router also etwas wie ein lokaler Server. Im Privatbereich sind entsprechende Lösungen weiterhin ausgesprochen beliebt. Geht es um das Business, hat die Cloud ihnen jedoch den Rang abgelaufen, da sie noch mehr Flexibilität bietet, es aber keine Sorgen bezüglich des Backups gibt.
`

export const lSProtokolle = new Lesson('Storage Protokolle', 'text')
    .addCategory(storageCategory)
    .addContent({
        id: 'storage:protokolle:ata',
        index: 1,
        content: cProtokolleAta
    })
    .addContent({
        id: 'storage:protokolle:scsi',
        index: 2,
        content: cProtokolleScsi
    })
    .addContent({
        id: 'storage:protokolle:fc',
        index: 3,
        content: cProtokolleFc
    })
    .addContent({
        id: 'storage:protokolle:network',
        index: 4,
        content: cProtokolleNetwerk
    })