import React, { SVGProps } from 'react'

export default function SunLight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M22 12L23 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 2V1' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 23V22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 20L19 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 4L19 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20L5 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 4L5 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 12L2 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
