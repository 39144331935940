import React, { SVGProps } from 'react'

export default function Cash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 17V7C2 5.89543 2.89543 5 4 5H10H14H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H14H10H4C2.89543 19 2 18.1046 2 17Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18.5 12.01L18.51 11.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 12.01L5.51 11.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
