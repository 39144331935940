import React, { SVGProps } from 'react'

export default function Lospec(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Lospec</title>
      <path d='M4.23 0v24h15.541v-8.4004h-7.1719v3.5996H11.402V0z' />
    </svg>
  )
}
