import React, { SVGProps } from 'react'

export default function Timer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9 2L15 2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 10L12 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
