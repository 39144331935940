import React, { SVGProps } from 'react'

export default function Notes(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 14L16 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 10L10 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 18L12 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3H14.5M10 3V1M10 3V5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
