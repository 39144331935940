import React, { SVGProps } from 'react'

export default function TvWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 11V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 18.01L12.01 17.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 20V9C2 7.89543 2.89543 7 4 7H20C21.1046 7 22 7.89543 22 9V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M8.5 2.5L12 6L15.5 2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
