import React, { SVGProps } from 'react'

export default function ArrowSeparate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 7L3 12L8 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 7L21 12L16 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
