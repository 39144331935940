import React, { SVGProps } from 'react'

export default function AlignVerticalSpacing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M22 3L2 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22 21L2 21' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 15V9C8 7.89543 8.89543 7 10 7H14C15.1046 7 16 7.89543 16 9V15C16 16.1046 15.1046 17 14 17H10C8.89543 17 8 16.1046 8 15Z'
        stroke='currentColor'
      />
    </svg>
  )
}
