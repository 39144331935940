import React, { SVGProps } from 'react'

export default function SquareThreedThreePoints(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 21V3.6C3 3.26863 3.26863 3 3.6 3H21' stroke='currentColor' />
      <path
        d='M17 21H20.4C20.7314 21 21 20.7314 21 20.4V17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M21 7V9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 12V14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 21H9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 21H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 4C3.55228 4 4 3.55228 4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 22C3.55228 22 4 21.5523 4 21C4 20.4477 3.55228 20 3 20C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 4C21.5523 4 22 3.55228 22 3C22 2.44772 21.5523 2 21 2C20.4477 2 20 2.44772 20 3C20 3.55228 20.4477 4 21 4Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
