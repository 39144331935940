import React, { SVGProps } from 'react'

export default function StatDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4 10L12 18L15 15L20 20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 4V12M16 12L19 9M16 12L13 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
