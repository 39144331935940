import React, { Component } from 'react'
import '../../../styles/graph_container.css'
import { GraphContainerProps, GraphContainerState } from '../types.graph'
import { NuiHeader } from '../../text'
import { NuiButton } from '../../button'

export class GraphContainer extends Component<GraphContainerProps, GraphContainerState> {
  constructor(props: GraphContainerProps) {
    super(props)
    this.state = { zoom: false }
  }

  handleButtonClick = () => {
    const { zoom } = this.state
    const { onWidthChange } = this.props

    this.setState({ zoom: !zoom }, () => {
      if (onWidthChange) {
        onWidthChange()
      }
    })
  }

  render() {
    const { zoom } = this.state
    const { width, title, children } = this.props

    return (
      <div
        className={zoom ? 'graphContainer modal' : 'graphContainer'}
        style={{
          width: zoom ? 'auto' : width,
        }}
      >
        <div className='graphContainerHeader'>
          <NuiHeader title={title} />
          <NuiButton
            color='primary'
            icon={zoom ? 'ScaleFrameReduce' : 'ScaleFrameEnlarge'}
            fill
            style={{
              minWidth: '25px',
            }}
            onClick={() => {
              this.handleButtonClick()
            }}
          />
        </div>
        {children}
      </div>
    )
  }
}
