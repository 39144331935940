import React, { SVGProps } from 'react'

export default function Verizon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Verizon</title>
      <path d='M18.302 0H22v.003L10.674 24H7.662L2 12h3.727l3.449 7.337z' />
    </svg>
  )
}
