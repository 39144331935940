import React from 'react';
import ReactDOM from 'react-dom/client';
import './asstes/styles/index.css';
import App from './App';
import reportWebVitals from './asstes/react_default/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { NuiContextProvider } from './app_context/app_context';
import { KeycloakAuth } from './auth/keycloak';
import { TimeTracking } from './time_tracking/time_tracking';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <NuiContextProvider>
      <KeycloakAuth>
        <TimeTracking>
          <App />
        </TimeTracking>
      </KeycloakAuth>
    </NuiContextProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
