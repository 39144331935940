import React, { SVGProps } from 'react'

export default function Foxtel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Foxtel</title>
      <path d='M2.867 10.631l.341-1.024H0v4.779h1.16v-1.72h1.434l.341-1.024H1.16v-1.01zm3.03-1.085a2.454 2.454 0 1 0-.006 4.908 2.454 2.454 0 0 0 .007-4.908zm0 3.74a1.287 1.287 0 1 1-.007-2.574 1.287 1.287 0 0 1 .008 2.575zm6.506-3.679h-1.297l-.812 1.304-.82-1.304H8.177l1.468 2.335-1.536 2.444h1.297l.888-1.405.88 1.405h1.297l-1.529-2.444zm.102 1.024h1.413v3.755h1.16V10.63h1.23V9.607h-3.16zm7.304 0l.341-1.024h-3.208v4.779h2.867l.341-1.024h-2.046v-.915h1.432l.341-1.024h-1.773v-.792zm2.143 2.73V9.608h-1.16v4.779h2.867L24 13.362Z' />
    </svg>
  )
}
