import React, { SVGProps } from 'react'

export default function ArrowEnlargeTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8.5 9.5L6 12L8.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.5 9.5L18 12L15.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 15V9C2 6.79086 3.79086 5 6 5H18C20.2091 5 22 6.79086 22 9V15C22 17.2091 20.2091 19 18 19H6C3.79086 19 2 17.2091 2 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  )
}
