import React, { SVGProps } from 'react'

export default function List(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 6L20 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 6.01L4.01 5.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12.01L4.01 11.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 18.01L4.01 17.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 12L20 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 18L20 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
