import React, { SVGProps } from 'react'

export default function EmojiSingLeftNote(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.8 8.1C2.8 8.59706 2.39706 9 1.9 9C1.40294 9 1 8.59706 1 8.1C1 7.60294 1.40294 7.2 1.9 7.2C2.39706 7.2 2.8 7.60294 2.8 8.1Z'
        fill='currentColor'
      />
      <path
        d='M2.8 8.1C2.8 8.59706 2.39706 9 1.9 9C1.40294 9 1 8.59706 1 8.1C1 7.60294 1.40294 7.2 1.9 7.2C2.39706 7.2 2.8 7.60294 2.8 8.1ZM2.8 8.1V3.6C2.8 3.26863 3.06863 3 3.4 3H5'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M8 17C9.10457 17 10 16.1046 10 15C10 13.8954 9.10457 13 8 13C6.89543 13 6 13.8954 6 15C6 16.1046 6.89543 17 8 17Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.04938 13C2.5511 18.0533 6.81465 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C10.5778 2 9.22492 2.2969 8 2.83209'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 9C15.7761 9 16 8.77614 16 8.5C16 8.22386 15.7761 8 15.5 8C15.2239 8 15 8.22386 15 8.5C15 8.77614 15.2239 9 15.5 9Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 9C8.77614 9 9 8.77614 9 8.5C9 8.22386 8.77614 8 8.5 8C8.22386 8 8 8.22386 8 8.5C8 8.77614 8.22386 9 8.5 9Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
