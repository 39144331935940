import React from 'react'
import '../../../../styles/input_text.css'
import { TInput } from '../text.types'
import { NuiIcon } from '../../../icons'

export const Input = ({ type, placeholder, key, icon, onChange, onClick }: TInput) => {
  return (
    <div className='box' key={`input:${type}:${key}`}>
      <div className='input__wrapper'>
        <input
          id={`input:${type}:${key}`}
          type={type}
          name={`input:${type}:${key}`}
          placeholder={placeholder}
          required
          className='input__field'
          onChange={onChange}
          onClick={onClick}
        />
        <label htmlFor={`input:${type}:${key}`} className='input__label'>
          {placeholder}
        </label>
        {icon && (
          <div className='input__icon'>
            <NuiIcon icon={icon} size='l' />
          </div>
        )}
      </div>
    </div>
  )
}
