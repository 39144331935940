import React, { SVGProps } from 'react'

export default function TruckGreen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7 17C8.10457 17 9 16.1046 9 15C9 13.8954 8.10457 13 7 13C5.89543 13 5 13.8954 5 15C5 16.1046 5.89543 17 7 17Z'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 15V4.6C14 4.26863 13.7314 4 13.4 4H2.6C2.26863 4 2 4.26863 2 4.6V14.4C2 14.7314 2.26863 15 2.6 15H4.65'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M13.9998 15H9.0498' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M14 7H19.6101C19.8472 7 20.0621 7.13964 20.1584 7.35632L21.4483 10.2586C21.4824 10.3354 21.5 10.4184 21.5 10.5023V12'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M17 23C17 23 17.9 19.8824 20 18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19.8022 21.4245L19.6677 21.4372C17.9758 21.5961 16.469 20.3541 16.3021 18.663C16.1353 16.9719 17.3715 15.4721 19.0634 15.3131L22.049 15.0326C22.2406 15.0146 22.4111 15.1552 22.43 15.3466L22.6847 17.9276C22.8589 19.6929 21.5683 21.2586 19.8022 21.4245Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
