import React, { SVGProps } from 'react'

export default function LayoutRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V20.4C3 20.7314 3.26863 21 3.6 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path d='M14.25 9.75V21' stroke='currentColor' strokeWidth='1.5' />
      <path d='M21 9.75H14.25H3' stroke='currentColor' strokeWidth='1.5' />
    </svg>
  )
}
