import React, { SVGProps } from 'react'

export default function MobileVoice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 17.01L8.01 16.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 5H3.6C3.26863 5 3 5.26863 3 5.6V20.4C3 20.7314 3.26863 21 3.6 21H12.4C12.7314 21 13 20.7314 13 20.4V16'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16 3L16 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13 5L13 11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22 7L22 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 7L10 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 4L19 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
