import React, { SVGProps } from 'react'

export default function Kx(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>KX</title>
      <path d='M0 5.873h3.59v12.254H0V5.873Zm11.746 0L7.65 12l4.097 6.127H7.65L3.59 12l4.06-6.127h4.097Zm12.254 0L19.903 12 24 18.127h-4.097l-2.03-3.045-2.03 3.045h-4.097L15.843 12l-4.097-6.127h4.097l2.03 3.082 2.03-3.082H24Z' />
    </svg>
  )
}
