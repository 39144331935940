import React, { SVGProps } from 'react'

export default function UnityFive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.672 20.7858C11.8713 20.916 12.1287 20.916 12.328 20.7858L21.6116 14.7239C21.8403 14.5745 21.9393 14.2894 21.8523 14.0304L18.2848 3.40896C18.2028 3.16462 17.9738 3 17.7161 3H6.22057C5.96168 3 5.73198 3.16606 5.65082 3.4119L2.14466 14.032C2.05937 14.2904 2.15858 14.5738 2.38637 14.7225L11.672 20.7858Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 6H10L9.5 11C10.0187 10.1033 10.9882 9.5 12.0987 9.5C13.7555 9.5 15.0987 10.8431 15.0987 12.5C15.0987 13.327 14.764 14.0758 14.2228 14.6185C13.6797 15.163 12.9285 15.5 12.0987 15.5C10.9882 15.5 10.0187 14.8967 9.5 14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
