import React, { SVGProps } from 'react'

export default function Tournament(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 3L8.00001 3L8.00001 9H3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 6L15 6L15 18H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 12L22 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 15L8.00001 15L8.00001 21H3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
