import React, { SVGProps } from 'react'

export default function Collapse(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M20 20L15 15M15 15V19M15 15H19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 20L9 15M9 15V19M9 15H5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 4L15 9M15 9V5M15 9H19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 4L9 9M9 9V5M9 9H5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
