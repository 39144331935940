import React, { SVGProps } from 'react'

export default function Code(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M13.5 6L10 18.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5 8.5L3 12L6.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5 8.5L21 12L17.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
