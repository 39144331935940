import { Lesson } from "../../../lessons"
import { storageCategory } from "../../category"

const cArchitectureBlock = `
Es wird in drei Arten unterschieden wie daten gespeichert werden.

- Block
- File
- Object

### Block

Der Zugriff auf die Daten erfolgt **Block**basiert. Die Daten werden in kleiner Blöcke/Segmente aufgeteilt, diese bekommen dann eine Adresse, mit dieser sich die Pakete zuordnen lassen.
Es ist nicht möglich über die Adresse Information aus dem Paket abzufangen.
Die Fregmente müssen dabei nicht auf das gleiche Medium geschrieben werden, wodurch auch einzellne Platten ausfallen können, ohne einen Datenverlusst.

\`\`\`mermaid
flowchart TD
    B[Block Storage] --> B1[Fixed-sized Blocks]
    B --> B2[Direct access]
    B --> B3[Optimized for performance]
\`\`\`
`
const cArchitectureFile = `
### File

Die Daten werden hier bei in Dateien geschriben und als solche gespeichert, ein beispiel dafür ist der Windows Explorer.
Die Datein werden dabei hiraiche geordnet.
Es lässt sich bei Windows in folgende zwei typen unterkategorisieren:

- <a href="#nfts">New Technology File System</a>
- <a href="#fat">File Allocation Table</a>

Linux hingegen nutz in der Regel <a href="#ext4">EXT4</a> mit der <a href="inodes">iNodes</a> methode, NFTS und FAT32 würden in der Theorie auch bin Linux gehen.

Obwohl MacOS auf Linux basiert nutzen sie ihr eigenes <a href="hfs+">HFS+</a>.

\`\`\`mermaid
flowchart TD
    C[File Storage] --> C1[Organized in hierarchical structure]
    C --> C2[Access via File System]
    C --> C3[Suited for shared access]
\`\`\`

#### NTFS

NTFS ist das Dateisystem, das das Windows NT-Betriebssystem zum Speichern und Abrufen von Dateien auf HDDs und SSDs verwendet.
Es bietet unter anderem einen gezielten Zugriffsschutz auf Dateiebene sowie größere Datensicherheit durch Journaling. Ein weiterer Vorteil von NTFS ist, dass die Dateigröße nicht wie bei FAT auf 4 GiB beschränkt ist.

| Pro                                                                                                                                                                                                           | Contra                                                    |
| ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | --------------------------------------------------------- |
| Unterstützung für riesige Dateigrößen.                                                                                                                                                                        | Inkompatibel mit sehr alten Betriebssystemen.             |
| Fast keine realistische Begrenzung der Partitionsgröße und Dateigröße durch Änderung der Clustergröße.                                                                                                        | Einschränkungen bei der Dateibenennung                    |
| Ermöglicht Benutzern, die Dateikomprimierung einzustellen, wenn der Speicherplatz knapp wird.                                                                                                                 | Unterstützt keine Diskettenformatierung                   |
| Unterstützung der transparenten und starken Verschlüsselung aller Dateien und Ordner auf NTFS-Volume.                                                                                                         | Es braucht viel „Platz Overhead“, um richtig zu arbeiten. |
| Verwendet Journaling-Dateisystem und Prüfpunktinformationen, um die Konsistenz des Dateisystems automatisch wiederherzustellen.                                                                               |                                                           |
| Es werden CompactOS-Algorithmen verwendet, was die gesamte Systempartition komprimiert und eine Fragmentierung vermeidet, indem komprimierte Daten in zusammenhängend zugewiesenen Chunks geschrieben werden. |                                                           |
| Fügt es die Funktion „Größe ändern“ hinzu, um die Partition zu verkleinern oder zu erweitern.                                                                                                                 |                                                           |
| Durch Verwendung des Änderungsprotokoll, um Informationen wiederherzustellen, wenn ein Stromausfall oder andere Systemprobleme auftreten.                                                                     |                                                           |
| Es erlaubt Benutzern, dass Festplattenkontingente festlegen und den Speicherplatz, den Benutzer verwenden können, begrenzt.                                                                                   |                                                           |

#### FAT

Die FAT wird verwendet, um sowohl den Zuordnungsstatus der Cluster (die Grundeinheiten des logischen Speichers auf einer Festplatte) in einem Dateisystem, als auch die Verknüpfungsbeziehung zwischen jedem, zu beschreiben. Es fungiert als Inhaltsverzeichnis für das Betriebssystem und zeigt an, wo Verzeichnisse und Dateien auf der Festplatte gespeichert sind.

#### EXT4

Dieses Dateisystem hat große Fortschritte bei der Speichergröße gemacht. Es kann Volume-Größen bis zu 1 EB und Einzeldateien bis zu 16 TB mit der standardmäßigen 4K-Blockgröße unterstützen. Darüber hinaus ist die Anzahl der Unterverzeichnisse in einem einzelnen Verzeichnis unbegrenzt.

| Pro                                                                                              | Contra                                                                   |
| ------------------------------------------------------------------------------------------------ | ------------------------------------------------------------------------ |
| Unterstützung für riesige Dateigrößen und Laufwerke                                              | Das Dateiattribut „sicheres Löschen“ nicht unterstützen                  |
| Gute Leistung bei der Behandlung mit großen Dateien                                              | Nicht ursprünglich von Windows und MacOS unterstützt werden              |
| Erhebliche Reduzierung der Festplattenfragmentierung                                             | Verzögerte Zuweisungsfunktion kann zu potenziellen Datenverlusten führen |
| Unterstützung von einer unbegrenzten Anzahl von Unterverzeichnissen                              |                                                                          |
| Verzögerte Zuweisung, Protokollprüfsumme, Vorabzuweisung und einige erweiterte Funktionen bieten |                                                                          |

#### iNode

Jede Partition enthält ein Dateisystem, dieses Dateisystem wiederum enthält eine Art Inhaltsverzeichnis, die iNode-Liste. Die einzelnen Elemente der iNode-Liste sind die Dateiköpfe, also die Orte wo Dateiattribute, Größe usw. gespeichert sind.
Anders als unter DOS werden diese Blöcke aber nicht in einer Tabelle (FAT) zusammengefasst, sondern die iNodes enthalten selbst die Verweise auf diese Blöcke. Das Format eines typischen iNodes sieht etwa so aus:

![iNodes](https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Ext2-inode.svg/1200px-Ext2-inode.svg.png)

Zu beachten ist, dass der Dateiname nicht in der iNode auftaucht. Die iNode ist sozusagen nur die Referenz auf die Datenblöcke, die eine Datei benutzt und der Ort, an dem die Attribute wie Eigentümer, Gruppe, Größe und Zugriffsdaten gespeichert sind.

#### HFS+

Auf dem HFS+-Dateisystem werden das Volume in Sektoren unterteilt, die normalerweise 512 Byte groß sind, und dann werden die Sektoren in Zuordnungsblöcke gruppiert. Die maximale Dateigröße und Volume-Größe kann 8 EB erreichen.

HFS+ verwendet 32-Bit-Blockadressen, um große Dateien zu unterstützen, und verwendet Unicode, um Elemente zu benennen. Ähnlich wie NTFS unterstützt HFS+ auch Feste-Links zu Verzeichnissen und erlaubt Dateinamen mit einer Länge zu 255 Zeichen. Durch die Verwendung einer vollständigen 32-Bit-Zuordnungs-Mappingtabelle wird die Speicherplatznutzung großer Festplatten verbessert.

| Pro                                                                               | Contra                                                                                                                                   |
| --------------------------------------------------------------------------------- | ---------------------------------------------------------------------------------------------------------------------------------------- |
| Unterstützung für riesige Datei- und Volumegröße.                                 | Mangel an vielen erweiterten Funktionen wie Snapshots, Prüfsummen und Nanosekunden-Zeitstempel                                           |
| Verschlüsseltes Volume und darauf Zugriff auf jede Version von Mac OS X und MacOS | Außerhalb der Verwendung der Software von Drittanbietern, kann es nicht vom Windows gelesen werden.                                      |
| Dateien und Ordner komprimieren                                                   | HFS+-Laufwerke oder Partitionen, die größer als 2 TB sind, können nicht auf Linux-Distributionen wie Debian und Ubuntu gemountet werden. |
| Durch Verwendung des Unicodes die Elemente zu benennen                            | Die Unterstützung von HFS+ wird am 6. Februar 2040 eingestellt.  
`
const cArchitectureObject = `
Es wird in drei Arten unterschieden wie daten gespeichert werden.

- Block
- File
- Object

### Block

Objektspeicher werden für die Verarbeitung von großen Mengen unstrukturierter Daten benutzt. Im Gegensatz zu anderen Architekturen werden Daten hier als unterschiedliche Einheiten identifiziert, die mit Metadaten und einer eindeutigen Kennung gebündelt sind, dank derer jede Dateneinheit gesucht und darauf zugegriffen werden kann.

\`\`\`mermaid
flowchart TD
    D[Object Storage] --> D1[Metadata-rich Objects]
    D --> D2[Access via unique identifiers]
    D --> D3[Flexible scalability]
\`\`\`
`
const cArchitectureBreakdown = `
### Metapher

Das ganze lässt sich mit hilfe eines Beispieles relativ gut erklären.

Folgendes Szenario:

Es wird eine Party besucht, wo man mit dem Auto hin fährt. Wie macht man es nun mit dem Auto?
Parkt man es wie merkt man sich das ganze?
In der **Block** methode sieht es ungefähr so aus:

Man begibt sich in die Tiefgarage, fährt auf den Vorgesehnen Parkplatz und merkt sich die genau Position, wo man saß.

Dies sind relativ viele Schritte, was natürlich mehr ressourcen benötigt.

Mit dem **File** System würde man auch wieder in die Tiefgarage fahren und sich nur den Parkplatz merken.
Die genau Sitz Position ist erst einmal egal.

In dem letzen Szenario, den **Object** Storage, geben wir denn Schlüssel einen Wagenmeister, wenn wir das Auto wider benötigen gehen wir einfach zum Wagenmeister und er gibt uns das Auto wieder.
`
export const lSArchitecture = new Lesson('Storage Architecture', 'text')
    .addCategory(storageCategory)
    .addContent({
        id: 'storage:architecture:block',
        index: 1,
        content: cArchitectureBlock
    })
    .addContent({
        id: 'storage:architecture:file',
        index: 2,
        content: cArchitectureFile
    })
    .addContent({
        id: 'storage:architecture:object',
        index: 3,
        content: cArchitectureObject
    })
    .addContent({
        id: 'storage:architecture:breakdown',
        index: 4,
        content: cArchitectureBreakdown
    })