import React, { SVGProps } from 'react'

export default function TwoPointsCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 20C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18C18.4477 18 18 18.4477 18 19C18 19.5523 18.4477 20 19 20Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19 19L17.5 17.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.5 15.5L14.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 12.5L11.5 11.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.5 9.5L8.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5 6.5L5 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
