import React, { SVGProps } from 'react'

export default function PlaylistPlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 18H20M22 18H20M20 18V16M20 18V20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2 11L20 11' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 17L14 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 5L20 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
