import React, { SVGProps } from 'react'

export default function Flashforge(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Flashforge</title>
      <path d='m.025 12 11.98-12 11.97 12-11.97 12Zm14.544 2.672-2.574 2.584v5.916L23.177 11.97 11.995.76v5.136L9.402 8.53v6.123l5.157-5.196Z' />
    </svg>
  )
}
