import React, { SVGProps } from 'react'

export default function GoogleDriveSync(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.14286 3.00375L14.8571 3M9.14286 3.00375L2 15.0038M9.14286 3.00375L14.0446 12.5M14.8571 3L5.575 21M14.8571 3L20.2126 12M5.575 21L2 15.0038M5.575 21H12.0036M2 15.0038H12.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.6663 17.6667C22.0476 16.097 20.6344 15 18.9901 15C17.2317 15 15.7376 16.2545 15.1967 18'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.9951 17.7722H22.4C22.7314 17.7722 23 17.5036 23 17.1722V15.55'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.3337 20.3333C15.9524 21.903 17.3656 23 19.0099 23C20.7683 23 22.2624 21.7455 22.8033 20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0049 20.2278H15.6C15.2686 20.2278 15 20.4964 15 20.8278V22.45'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
