import React, { SVGProps } from 'react'

export default function Visualbasic(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Visual Basic</title>
      <path d='M6.363 17.719a7.57 7.57 0 0 1 .34-1.348L10.887 4.5h1.863l-5.473 15h-1.91L0 4.5h1.934l4.09 11.895c.078.218.14.437.187.656s.098.441.152.668zM20.66 11.53c.5.063.957.188 1.371.375s.766.442 1.055.762.512.691.668 1.113.238.89.246 1.406c0 .696-.133 1.31-.398 1.84s-.622.98-1.067 1.348-.96.645-1.547.832-1.203.285-1.851.293h-4.371v-15h4.265c.54 0 1.059.066 1.559.2s.95.335 1.348.609.714.628.949 1.066.355.957.363 1.559c0 .859-.227 1.601-.68 2.226s-1.09 1.067-1.91 1.324v.047zm-4.137-.597h1.793c.43 0 .832-.051 1.207-.153s.704-.258.985-.469.5-.488.656-.832.238-.746.246-1.207c0-.43-.078-.785-.234-1.066s-.367-.504-.633-.668-.57-.277-.914-.34-.707-.097-1.09-.105h-2.016v4.84zm2.38 6.972c.437 0 .85-.05 1.242-.152s.738-.262 1.043-.48.539-.5.703-.844.254-.762.27-1.254c0-.54-.102-.98-.306-1.324s-.476-.614-.82-.809-.73-.332-1.16-.41-.875-.117-1.336-.117h-2.016v5.39h2.38z' />
    </svg>
  )
}
