import React, { SVGProps } from 'react'

export default function SweepThreed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 22L8.5 16.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 5L17.5 7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 21H15.5L8.49996 9L2.66663 19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 3L21.5 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 9L14.5 3' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.5 21L21.5 15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
