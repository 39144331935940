import React, { SVGProps } from 'react'

export default function VeganCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.5 11.5C12.75 13.3824 11 18 11 18C11 18 8.5 11.5 6 10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0155 7.73006L18.3121 10.81C18.5045 12.8081 17.0064 14.5871 15.0083 14.7795C13.0478 14.9683 11.2718 13.5352 11.083 11.5747C10.8942 9.61421 12.3305 7.87187 14.291 7.68309L17.5749 7.36689C17.7969 7.34552 17.9941 7.50812 18.0155 7.73006Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
