import React, { SVGProps } from 'react'

export default function Revue(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Revue</title>
      <path d='M4.3 7.13c-1.62 0-3.42.871-4.196 2.558-.49 1.065.918 1.397.988 1.065.23-1.09 1.447-2.805 3.22-2.805.936 0 1.483.616 1.483 1.704 0 1.349-1.559 2.06-2.25 2.29.307-1.41.415-1.836.415-2.087 0-.554-.321-.646-.648-.646-.397 0-.684.29-.684.29.23.418-.159 1.904-.327 2.506-.203.73-.485 1.81-.661 2.496-.177.685 1.052.5 1.135.298.09-.22.326-1.027.54-1.876.358-.112.768-.27.768-.27.18 1.192 1.138 4.216 3.874 4.216 2.346 0 2.196-1.488 2.002-1.392-.235.118-.647.325-1.527.325-2.735 0-3.298-3.506-3.298-3.506s2.07-.873 2.07-2.644c0-1.774-1.445-2.521-2.903-2.521zM14.223 10c-.443 0-.781.383-.781.792 0 .674.552.693.542 1.236-.01.542-.876 2.082-1.46 2.082-.217 0-.251-.219-.251-.321 0-1.038.55-2.52.55-3.019 0-.47-.286-.59-.628-.59a.974.974 0 0 0-.703.287c.157.325.003 1.1-.172 1.846-.303.858-1.285 1.89-2.163 1.893-1.147.007-.98-1.2-.98-1.2s2.698.06 2.614-1.81c-.036-.787-.796-1.125-1.479-1.095-1.392.063-2.5 1.385-2.427 3.02.058 1.291 1.025 1.906 2.053 1.86.91-.04 1.59-.451 2.079-.93.064.537.402.937 1.049.937 1.687 0 3.021-2.429 3.021-3.935 0-.55-.289-1.052-.864-1.052zm8.326.117c-1.393.029-2.57 1.325-2.536 2.961.004.157.018.305.043.443-.18.236-.5.59-.789.59-.159 0-.218-.14-.218-.299 0-1.202.555-2.385.555-3.084 0-.424-.29-.546-.648-.546-.442 0-.688.288-.688.288.241.663-.616 3.641-1.518 3.641-.213 0-.25-.167-.25-.303 0-.893.551-2.388.551-3.057 0-.444-.296-.576-.646-.576-.414 0-.69.295-.69.295.298.572-.484 2.757-.484 3.39 0 .632.351 1.131.97 1.131 1.003 0 1.585-1.258 1.585-1.258s-.134 1.258 1.085 1.258c.625 0 1.122-.53 1.383-.884.321.602.95.897 1.803.88.612-.013 1.053-.189 1.393-.494.524-.472.175-1.195-.02-.982-.346.376-.781.664-1.263.674-.926.02-.814-1.161-.814-1.161s2.686.094 2.647-1.777c-.016-.788-.768-1.144-1.451-1.13zm-13.21.721c.165-.007.281.076.29.275.05 1.108-1.463 1.224-1.463 1.224.065-.542.574-1.472 1.174-1.499zm13.17.016c.184-.004.28.083.285.283.022 1.088-1.445 1.186-1.445 1.186.077-.54.496-1.455 1.16-1.469z' />
    </svg>
  )
}
