import React, { SVGProps } from 'react'

export default function BusStop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M16 16.01L16.01 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 16.01L6.01 15.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 22V15V8M20 8H18L18 2H22V8H20Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16 20H2.6C2.26863 20 2 19.7314 2 19.4V12.6C2 12.2686 2.26863 12 2.6 12H16'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 8H6M14 2H6C3.79086 2 2 3.79086 2 6V8'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 20V21.9C3.5 22.2314 3.76863 22.5 4.1 22.5H6.9C7.23137 22.5 7.5 22.2314 7.5 21.9V20'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M14.5 20V21.9C14.5 22.2314 14.7686 22.5 15.1 22.5H16' stroke='currentColor' strokeLinecap='round' />
    </svg>
  )
}
