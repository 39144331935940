import React, { SVGProps } from 'react'

export default function FilterListCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 9L18 9' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 13L16 13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 17L14 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
