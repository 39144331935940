import React, { SVGProps } from 'react'

export default function BehanceTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.09859 11.8261C11.6338 11.8261 11.6338 16 9.09859 16C7.6685 16 6 16 6 16V11.8261M9.09859 11.8261C7.6685 11.8261 6 11.8261 6 11.8261M9.09859 11.8261C11.6338 11.8261 11.6338 8 9.09859 8C7.97183 8 6 8 6 8V11.8261'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 11C14.1193 11 13 12.1193 13 13.5H18C18 12.1193 16.8807 11 15.5 11Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 13.5C13 14.8807 14.1193 16 15.5 16C16.4276 16 16.9905 15.6776 17.3126 15.3802'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M17 8.5H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
