import React, { SVGProps } from 'react'

export default function Safe(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19Z'
        stroke='currentColor'
      />
      <path
        d='M10 15C8.34315 15 7 13.6569 7 12C7 10.3431 8.34315 9 10 9C11.6569 9 13 10.3431 13 12C13 13.6569 11.6569 15 10 15Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18 14L18 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 9.5L13.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 9.5L6.5 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5 15.5L7.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5 15.5L12.5 14.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 8L3 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 6L3 6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 16H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 18H2' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
