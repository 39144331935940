import React, { SVGProps } from 'react'

export default function PcCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 22L17 22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 17V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z'
        stroke='currentColor'
      />
      <path d='M9 10.5L11 12.5L15 8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
