import React, { SVGProps } from 'react'

export default function Drag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 12L4 4M4 4V8M4 4H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12L20 4M20 4V8M20 4H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12L4 20M4 20V16M4 20H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12L20 20M20 20V16M20 20H16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
