import React, { SVGProps } from 'react'

export default function Pocket(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21 6V11C21 15.9706 16.9706 20 12 20C7.02944 20 3 15.9706 3 11V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 10L12 14L16 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
