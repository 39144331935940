import React, { Component } from 'react'
import moment from 'moment'
import { ActivityHistoryProps, ActivityHistoryState } from '../types.graph'
import '../../../styles/grap_activity_hostory.css'
import { GraphContainer } from '../container/container'
const legendItems = [
  { color: '#ebedf0', meaning: 'No activity' },
  { color: '#c6e48b', meaning: '1-3 activities' },
  { color: '#7bc96f', meaning: '4-6 activities' },
  { color: '#239a3b', meaning: '7-9 activities' },
  { color: '#196127', meaning: '10+ activities' },
]

export class ActivityHistory extends Component<ActivityHistoryProps, ActivityHistoryState> {
  constructor(props: ActivityHistoryProps) {
    super(props)
    this.state = {
      actualWidth: undefined,
    }
  }

  render() {
    const { data, title, width } = this.props

    const activityCounts: { [key: string]: number } = {}

    data.forEach((item) => {
      const date = moment(item.date)
      const dayOfWeek = date.day()
      const weekOfYear = date.isoWeek()
      const key = `${dayOfWeek}-${weekOfYear}`
      activityCounts[key] = (activityCounts[key] || 0) + 1
    })

    const weekdays = moment.weekdaysShort()
    const months = moment.monthsShort()

    const monthRow = (
      <div className='month-row'>
        <div className='month-name'></div>
        {months.map((month, index) => (
          <div key={index} className='weekday month-name'>
            {month}
          </div>
        ))}
      </div>
    )

    const rows: JSX.Element[] = weekdays.map((weekday, index) => {
      const cells: JSX.Element[] = []

      for (let week = 0; week < 52; week++) {
        const key = `${index}-${week}`
        const activityCount = activityCounts[key] || 0
        let colorIntensity = '#ebedf0'
        if (activityCount > 0 && activityCount <= 3) {
          colorIntensity = '#c6e48b'
        } else if (activityCount > 3 && activityCount <= 6) {
          colorIntensity = '#7bc96f'
        } else if (activityCount > 6 && activityCount <= 9) {
          colorIntensity = '#239a3b'
        } else if (activityCount > 9) {
          colorIntensity = '#196127'
        }

        const currentDate = moment().startOf('year').day(index).add(week, 'weeks')

        cells.push(
          <span data-text={currentDate.format('DD/MM/YYYY')} className='tooltip'>
            <td key={key} className='grid-cell' style={{ backgroundColor: colorIntensity }}></td>
          </span>,
        )
      }

      return (
        <tr key={index}>
          <td className='weekday'>{weekday}</td>
          {cells}
        </tr>
      )
    })

    const legend = (
      <div className='legend'>
        {legendItems.map((item, index) => (
          <div key={index}>
            <div className='grid-cell' style={{ backgroundColor: item.color }}></div>
            {item.meaning}
          </div>
        ))}
      </div>
    )
    const selfItem = document.getElementById(`nuiTableGraph:activity:${title}`)
    const parent = selfItem && selfItem.parentElement
    const maxTrasnlate = parent && parent.offsetWidth / 1250 >= 0.9
    const maxScale = parent && parent.offsetWidth / 1250 >= 2
    console.log(`nuiTableGraph:activity:${title}`, parent ? parent.offsetWidth / 1250 : 'Not set', maxScale)
    return (
      <GraphContainer
        width={width}
        title={title}
        onWidthChange={() => {
          const parentWidth = document.getElementById(`nuiTableGraph:activity:${title}`)?.parentElement?.style.width

          this.setState({
            actualWidth: parentWidth ? parseInt(parentWidth) / 1250 : undefined,
          })
        }}
      >
        <div
          className={`nuiTableGraph`}
          id={`nuiTableGraph:activity:${title}`}
          style={{
            width: '1250px',
            transform: `scale(${parent ? (maxScale ? '2' : (parent.offsetWidth - 60) / 1250) : '0.6'}) ${
              parent ? (maxTrasnlate ? 'translateX(15%) translateY(50%)' : 'translateX(-30%)') : ''
            }`,
            transformOrigin: 'center',
          }}
        >
          {monthRow}
          <table>
            <tbody>{rows}</tbody>
          </table>
          {legend}
        </div>
      </GraphContainer>
    )
  }
}
