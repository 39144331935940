import React, { SVGProps } from 'react'

export default function Dialpad(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.5 5C5.22386 5 5 4.77614 5 4.5C5 4.22386 5.22386 4 5.5 4C5.77614 4 6 4.22386 6 4.5C6 4.77614 5.77614 5 5.5 5Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 10C5.22386 10 5 9.77614 5 9.5C5 9.22386 5.22386 9 5.5 9C5.77614 9 6 9.22386 6 9.5C6 9.77614 5.77614 10 5.5 10Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 15C5.22386 15 5 14.7761 5 14.5C5 14.2239 5.22386 14 5.5 14C5.77614 14 6 14.2239 6 14.5C6 14.7761 5.77614 15 5.5 15Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5C11.7239 5 11.5 4.77614 11.5 4.5C11.5 4.22386 11.7239 4 12 4C12.2761 4 12.5 4.22386 12.5 4.5C12.5 4.77614 12.2761 5 12 5Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 10C11.7239 10 11.5 9.77614 11.5 9.5C11.5 9.22386 11.7239 9 12 9C12.2761 9 12.5 9.22386 12.5 9.5C12.5 9.77614 12.2761 10 12 10Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15C11.7239 15 11.5 14.7761 11.5 14.5C11.5 14.2239 11.7239 14 12 14C12.2761 14 12.5 14.2239 12.5 14.5C12.5 14.7761 12.2761 15 12 15Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 20C11.7239 20 11.5 19.7761 11.5 19.5C11.5 19.2239 11.7239 19 12 19C12.2761 19 12.5 19.2239 12.5 19.5C12.5 19.7761 12.2761 20 12 20Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 5C18.2239 5 18 4.77614 18 4.5C18 4.22386 18.2239 4 18.5 4C18.7761 4 19 4.22386 19 4.5C19 4.77614 18.7761 5 18.5 5Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 10C18.2239 10 18 9.77614 18 9.5C18 9.22386 18.2239 9 18.5 9C18.7761 9 19 9.22386 19 9.5C19 9.77614 18.7761 10 18.5 10Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 15C18.2239 15 18 14.7761 18 14.5C18 14.2239 18.2239 14 18.5 14C18.7761 14 19 14.2239 19 14.5C19 14.7761 18.7761 15 18.5 15Z'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
