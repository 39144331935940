import React, { SVGProps } from 'react'

export default function ItermTwo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>iTerm2</title>
      <path d='m7.806 3.845h2.563v5.126h-2.563zm-1.531 1.65c-.14-.104-.272-.183-.397-.239-.126-.056-.28-.084-.463-.084-.167 0-.298.034-.391.102-.094.067-.141.161-.141.28 0 .092.045.168.135.227.089.06.202.117.337.17.135.054.282.11.439.168.157.057.304.128.439.212.135.083.248.186.337.307.09.121.135.274.135.457 0 .255-.091.471-.272.648s-.435.289-.761.337v.878h-.478v-.866c-.207-.02-.412-.073-.615-.158-.203-.086-.378-.19-.526-.314l.311-.471c.171.115.344.209.519.28.176.072.367.108.574.108.191 0 .335-.036.433-.108.097-.071.146-.167.146-.286 0-.104-.045-.189-.135-.257-.089-.068-.202-.13-.337-.188s-.281-.116-.436-.173c-.155-.058-.3-.129-.436-.212-.135-.084-.247-.184-.337-.302-.09-.117-.134-.266-.134-.445 0-.258.087-.469.262-.633.176-.163.412-.264.711-.304v-.782h.478v.776c.211.024.391.078.54.161.149.084.286.187.409.311zm-2.165-2.442c-.456.244-.813.601-1.057 1.057s-.373.908-.373 2.154v11.472c0 1.246.129 1.698.373 2.154s.601.813 1.057 1.057.908.373 2.154.373h11.472c1.246 0 1.698-.129 2.154-.373s.813-.601 1.057-1.057.373-.908.373-2.154v-11.472c0-1.246-.129-1.698-.373-2.154s-.601-.813-1.057-1.057-.908-.373-2.154-.373h-11.472c-1.246 0-1.698.129-2.154.373zm2.199-2.121h11.382c1.87 0 2.548.195 3.231.56.684.366 1.22.902 1.586 1.586.365.683.56 1.361.56 3.231v11.382c0 1.87-.195 2.548-.56 3.231-.366.684-.902 1.22-1.586 1.586-.683.365-1.361.56-3.231.56h-11.382c-1.87 0-2.548-.195-3.231-.56-.684-.366-1.22-.902-1.586-1.586-.365-.683-.56-1.361-.56-3.231v-11.382c0-1.87.195-2.548.56-3.231.366-.684.902-1.22 1.586-1.586.683-.365 1.361-.56 3.231-.56zm-3.567-.216c-.874.467-1.559 1.152-2.026 2.026-.467.873-.716 1.739-.716 4.128v10.26c0 2.389.249 3.255.716 4.128.467.874 1.152 1.559 2.026 2.026.873.467 1.739.716 4.128.716h10.26c2.389 0 3.255-.249 4.128-.716.874-.467 1.559-1.152 2.026-2.026.467-.873.716-1.739.716-4.128v-10.26c0-2.389-.249-3.255-.716-4.128-.467-.874-1.152-1.559-2.026-2.026-.873-.467-1.739-.716-4.128-.716h-10.26c-2.389 0-3.255.249-4.128.716z' />
    </svg>
  )
}
