import React, { SVGProps } from 'react'

export default function MultiplePages(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 18H10.5H14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 14H7.5H8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 10H8.5H10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 2L16.5 2L21 6.5V19' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 20.5V6.5C3 5.67157 3.67157 5 4.5 5H14.2515C14.4106 5 14.5632 5.06321 14.6757 5.17574L17.8243 8.32426C17.9368 8.43679 18 8.5894 18 8.74853V20.5C18 21.3284 17.3284 22 16.5 22H4.5C3.67157 22 3 21.3284 3 20.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 5V8.4C14 8.73137 14.2686 9 14.6 9H18'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
