import React, { SVGProps } from 'react'

export default function Abbvie(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>Abbvie</title>
      <path d='M23.903 13.932c0-.25-.154-.347-.386-.347h-1.778c-.87 0-1.082-.522-1.12-.85h2.415c.734 0 .966-.542.966-.89 0-.367-.232-.888-.966-.888H21.7c-1.256 0-1.661.85-1.661 1.545 0 .754.463 1.546 1.661 1.546h2.203v-.116zM21.74 11.42h1.16c.425 0 .521.251.521.425 0 .155-.096.426-.521.426h-2.32c.04-.271.29-.85 1.16-.85zm-5.16 2.454c-.135.174-.193.252-.328.252-.174 0-.212-.078-.328-.252-.27-.386-1.894-2.898-1.894-2.898h.232c.386 0 .502.174.618.367.097.155 1.41 2.164 1.41 2.164s1.238-1.913 1.411-2.183c.116-.174.271-.348.638-.348h.174s-1.7 2.59-1.932 2.898zm-12.193.194c-.29 0-.405-.116-.444-.348l-.077-.329c-.078.136-.464.677-1.314.677h-.87C.367 14.068 0 13.178 0 12.522c0-.735.444-1.546 1.681-1.546h.928c.947 0 1.468.54 1.584 1.217.097.56.348 1.875.348 1.875h-.155zm-1.913-2.61H1.74c-.889 0-1.179.561-1.179 1.064 0 .502.29 1.063 1.18 1.063h.733c.928 0 1.18-.58 1.18-1.063 0-.425-.233-1.063-1.18-1.063zm16.638-.985c.135 0 .27-.077.27-.25v-.097c0-.155-.135-.252-.27-.252s-.27.078-.27.252v.096c0 .174.135.251.27.251zm-.251.503h.116c.251 0 .406.135.406.406v2.686h-.116c-.252 0-.406-.155-.406-.425v-2.667zm-13.45.444c.117-.116.464-.444 1.14-.444h.87c1.314 0 1.7.889 1.7 1.546 0 .734-.463 1.546-1.7 1.546h-.927c-.947 0-1.623-.6-1.623-1.546v-2.59h.154c.27 0 .387.136.387.368v1.12zm1.218 2.165h.734c.87 0 1.18-.56 1.18-1.063 0-.503-.29-1.063-1.18-1.063h-.734c-.928 0-1.179.58-1.179 1.063-.019.425.232 1.063 1.179 1.063zm3.633-2.165c.116-.116.464-.444 1.14-.444h.87c1.314 0 1.68.889 1.68 1.546 0 .734-.444 1.546-1.68 1.546h-.928c-.947 0-1.623-.6-1.623-1.546v-2.59h.154c.27 0 .387.136.387.368v1.12zm1.217 2.165h.735c.888 0 1.178-.56 1.178-1.063 0-.503-.29-1.063-1.178-1.063h-.735c-.927 0-1.178.58-1.178 1.063-.02.425.231 1.063 1.178 1.063z' />
    </svg>
  )
}
