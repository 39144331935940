import React, { SVGProps } from 'react'

export default function Waist(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.4001 4C18.4001 4 16.8 7.75071 16.8 10.8571C16.8 11.8517 17.1409 12.684 17.6 13.5129C18.1285 14.4669 18.8137 15.4163 19.3166 16.6035C19.7158 17.5456 20.0001 18.6374 20.0001 20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6 4C5.6 4 7.2 7.75071 7.2 10.8571C7.2 11.8517 6.85919 12.684 6.40001 13.5129C5.87156 14.4669 5.18633 15.4163 4.68341 16.6035C4.2843 17.5456 4 18.6374 4 20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6.40002 13.5129H17.6' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.68335 16.6035C4.68335 16.6035 10.4 17.7143 12 20C13.6 17.7143 19.3165 16.6035 19.3165 16.6035'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
