import React, { SVGProps } from 'react'

export default function Treadmill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 6C14.1046 6 15 5.10457 15 4C15 2.89543 14.1046 2 13 2C11.8954 2 11 2.89543 11 4C11 5.10457 11.8954 6 13 6Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6132 7.26691L7.30493 11.4021L11.4402 15.5374L9.37255 20.0861'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.4104 8.5075L7.79728 5.19931L10.6132 7.26692L13.508 10.5752H15.2297'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.89152 14.7103L5.65095 15.5374H2.34277'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.34277 21.5374L18.651 19.5374V8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.8915 6L18.651 8L17 9.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.8914 21.7103L18.6509 19.5374' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
