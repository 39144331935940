import React, { SVGProps } from 'react'

export default function Flower(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.9996 14.9995C13.6565 14.9995 14.9996 13.6564 14.9996 11.9995C14.9996 10.3427 13.6565 8.99951 11.9996 8.99951C10.3428 8.99951 8.99963 10.3427 8.99963 11.9995C8.99963 13.6564 10.3428 14.9995 11.9996 14.9995Z'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 9C13 9 14 7 14 5C14 3 12 1 12 1C12 1 10 3 10 5C10 7 11 9 11 9'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 11C9 11 7 10 5 10C3 10 1 12 1 12C1 12 3 14 5 14C7 14 9 13 9 13'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 15C13 15 14 17 14 19C14 21 12 23 12 23C12 23 10 21 10 19C10 17 11 15 11 15'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 11C15 11 17 10 19 10C21 10 23 12 23 12C23 12 21 14 19 14C17 14 15 13 15 13'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5858 9.17176C10.5858 9.17176 9.87868 7.05044 8.46447 5.63623C7.05026 4.22202 4.22183 4.22202 4.22183 4.22202C4.22183 4.22202 4.22183 7.05044 5.63604 8.46466C7.05026 9.87887 9.17158 10.586 9.17158 10.586'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.17152 13.4142C9.17152 13.4142 7.0502 14.1213 5.63599 15.5355C4.22177 16.9497 4.22177 19.7782 4.22177 19.7782C4.22177 19.7782 7.0502 19.7782 8.46441 18.364C9.87863 16.9497 10.5857 14.8284 10.5857 14.8284'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8285 13.4142C14.8285 13.4142 16.9498 14.1213 18.364 15.5355C19.7782 16.9497 19.7782 19.7782 19.7782 19.7782C19.7782 19.7782 16.9498 19.7782 15.5356 18.364C14.1214 16.9497 13.4143 14.8284 13.4143 14.8284'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4142 9.17176C13.4142 9.17176 14.1213 7.05044 15.5355 5.63623C16.9497 4.22202 19.7782 4.22202 19.7782 4.22202C19.7782 4.22202 19.7782 7.05044 18.364 8.46466C16.9497 9.87887 14.8284 10.586 14.8284 10.586'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
