import React, { SVGProps } from 'react'

export default function DroneRefresh(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.5 12.5L14.926 8.66626C14.9655 8.31084 14.6872 8 14.3296 8L9.67036 8C9.31275 8 9.03454 8.31084 9.07403 8.66626L9.9407 16.4663C9.97446 16.7701 10.2313 17 10.537 17H11'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7Z'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4.5 4.5L9 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.49988 19.5L9.5 15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.5 4.5L15 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.5 22C5.88071 22 7 20.8807 7 19.5C7 18.1193 5.88071 17 4.5 17C3.11929 17 2 18.1193 2 19.5C2 20.8807 3.11929 22 4.5 22Z'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 7C20.8807 7 22 5.88071 22 4.5C22 3.11929 20.8807 2 19.5 2C18.1193 2 17 3.11929 17 4.5C17 5.88071 18.1193 7 19.5 7Z'
        stroke='currentColor'
        strokeMiterlimit='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6664 16.6667C21.0476 15.097 19.6345 14 17.9901 14C16.2318 14 14.7377 15.2545 14.1968 17'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9951 16.7723H21.4001C21.7314 16.7723 22.0001 16.5036 22.0001 16.1723V14.55'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.3336 19.3333C14.9524 20.903 16.3655 22 18.0099 22C19.7682 22 21.2623 20.7455 21.8032 19'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0049 19.2277H14.5999C14.2686 19.2277 13.9999 19.4964 13.9999 19.8277V21.45'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
