import React, { SVGProps } from 'react'

export default function DataTransferUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 4L7 5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 9L7 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 20V4M17 4L20 7M17 4L14 7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 14V20M7 20L10 17M7 20L4 17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
