import React, { SVGProps } from 'react'

export default function TerminalTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M13 16H18' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 8L10 12L6 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
