import React, { SVGProps } from 'react'

export default function FilletThreed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 20V16C2 8.26801 8.26801 2 16 2H20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8388 20.8391H17.3033M20.8388 20.8391V17.3035M20.8388 20.8391L18 18.0003'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 9L16 16' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeDasharray='2 3' />
    </svg>
  )
}
