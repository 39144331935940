import React, { SVGProps } from 'react'

export default function Cratedb(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>CrateDB</title>
      <path d='M18 9V3h-6v6H0v6h6v6h6v-6h12V9h-6z' />
    </svg>
  )
}
