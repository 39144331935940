import React, { SVGProps } from 'react'

export default function SplitSquareDashed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M2 12H22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 4H4V7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 4H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 4H20V7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 20H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 20H4V17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 20H20V17' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
