import React, { SVGProps } from 'react'

export default function DogecoinRotateOut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.1679 8C19.6247 4.46819 16.1006 2 11.9999 2C6.81459 2 2.55104 5.94668 2.04932 11'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 8H21.4C21.7314 8 22 7.73137 22 7.4V4'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.88146 16C4.42458 19.5318 7.94874 22 12.0494 22C17.2347 22 21.4983 18.0533 22 13'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.04932 16H2.64932C2.31795 16 2.04932 16.2686 2.04932 16.6V20'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 16.4021L10 7.59836C10 7.26698 10.2679 6.99903 10.5992 6.9943C13.09 6.95876 16.5 6.9218 16.5 12.0001C16.5 17.0784 13.09 17.0416 10.5992 17.0061C10.2679 17.0014 10 16.7334 10 16.4021Z'
        stroke='currentColor'
      />
      <path d='M8 12L12 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
