import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Category } from "../category";
import { NuiInput, NuiBadge, NuiMenuPoint } from "nui";
import { IndexCard } from ".";
import { NuiHeader } from "nui";
import { CardContainer } from "../dev";
import { useNuiContext } from "../app_context/app_context";

export const IndexCardsPage = ({ changeNav }: { changeNav: React.Dispatch<React.SetStateAction<NuiMenuPoint[] | undefined>> }): JSX.Element => {
    const { id, lesson } = useParams();
    const [filteredIndexCards, setFilteredIndexCards] = useState<IndexCard[] | undefined>(undefined)
    const { categorys, courses, indexCard, updateIndexCards } = useNuiContext();
    // FILTER VALUES
    const [filterText, setFilterText] = useState<string>('')
    // SELECT FILTER VALUES
    const [options, setOptions] = useState<Category[]>(categorys);
    const [selectedOption, setSelectedOption] = useState<Category>();
    const [selectedOptions, setSelectedOptions] = useState<Category[]>([]);
    const activeIndexCards: IndexCard[] = indexCard.filter(ic => ic.active === true)

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        const wantedOptions = categorys.filter(category => category.id === selectedValue);
        setSelectedOption(wantedOptions[0]);
        setSelectedOptions([...selectedOptions, wantedOptions[0]]);
        setOptions(options.filter(option => option.id !== selectedValue));
    };

    const handleRemoveOption = (index: number) => {
        const removedOption = selectedOptions[index];
        setOptions([...options, removedOption]);
        setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
    };

    const filterByCategory = (indexCard: IndexCard[], selectedCategories: Category[]): IndexCard[] => {
        if (selectedCategories.length === 0) {
            return indexCard;
        }
        return indexCard.filter(inCard =>
            inCard.categorys.some(category =>
                selectedCategories.some(selectedCategory =>
                    selectedCategory.id === category.id
                )
            )
        );
    };

    const searchInCourses = (text: string, categorys: Category[]): IndexCard[] => {
        const regex = /[a-zA-Z0-9]/;
        const filterRegex = new RegExp(text, 'i');
        let filteredIndexCard: IndexCard[] = [];

        filteredIndexCard = filterByCategory(activeIndexCards, categorys);

        if (!regex.test(text)) {
            console.warn('[System//Index Card] No Valid Text Search Request');
            return filteredIndexCard;
        }

        // Filter courses by title based on the search text
        return filteredIndexCard.filter((inCard: IndexCard) => {
            return filterRegex.test(inCard.keyname);
        });
    };

    useEffect(() => {
        setFilteredIndexCards(searchInCourses(filterText, selectedOptions))
    }, [filterText, selectedOptions])

    useEffect(() => {
        if (filteredIndexCards === undefined) {
            setFilteredIndexCards(activeIndexCards)
        }
        if (!lesson) {
            changeNav(undefined)
        }
    }, [id]);

    return (
        <div className="content-page">
            <div className="searchBar" style={{
                width: 'calc(100% - 5rem)'
            }}>
                <NuiInput
                    key='search'
                    type='text'
                    placeholder='Search'
                    icon='Search'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.target.value)}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: "row"
                }}>
                    <select onChange={handleSelectChange}>
                        <option key={`courses:filter:category:options:default`} defaultValue="" selected disabled>Filter nach Kategorien</option>
                        {options.map((option, index) => (
                            <option key={`courses:filter:category:options:${index}`} value={option.id}>{option.title}</option>
                        ))}
                    </select>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}>
                        {selectedOptions.map((option, index) => (
                            <NuiBadge
                                content={option.title}
                                key={`courses:filter:category:selected:${index}`}
                                background={option.color}
                                style={{
                                    height: "fit-content"
                                }}
                                icon="NavArrowLeft"
                                onClick={() => { handleRemoveOption(index) }}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="content-body indexCardPage"
                style={{
                    marginTop: "95px",
                    width: 'calc(100% - 5rem)'
                }}
            >
                <div className="stack">
                    <div className="header">
                        <NuiHeader title="Neu" />
                    </div>
                    <div className="body">
                        <CardContainer
                            cards={activeIndexCards}
                            row="new"
                            updateFunction={updateIndexCards}
                            leftButton="Xmark"
                            rightButton="Check"
                        />
                    </div>
                </div>
                <div className="stack">
                    <div className="header">
                        <NuiHeader title="Wiederholen" />
                    </div>
                    <div className="body">
                        <CardContainer
                            cards={activeIndexCards}
                            row="failed"
                            updateFunction={updateIndexCards}
                            leftButton="Xmark"
                            rightButton="Check"
                        />
                    </div>
                </div>
                <div className="stack">
                    <div className="header">
                        <NuiHeader title="Erfolg" />
                    </div>
                    <div className="body">
                        <CardContainer
                            cards={activeIndexCards}
                            row="success"
                            updateFunction={updateIndexCards}
                            leftButton="Xmark"
                            rightButton="Check"
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};
