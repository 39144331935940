import React, { SVGProps } from 'react'

export default function Wifi(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12 19.51L12.01 19.4989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2 8C8 3.5 16 3.5 22 8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 12C9 9 15 9 19 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.5 15.5C10.7504 14.1 13.2498 14.0996 15.5001 15.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
