import React, { SVGProps } from 'react'

export default function EvPlugCharging(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10 13.1538V21' stroke='currentColor' strokeLinecap='round' />
      <path
        d='M15 8.38452V11.1538C15 12.2583 14.1046 13.1538 13 13.1538H7C5.89543 13.1538 5 12.2583 5 11.1538V8.38452C5 7.27995 5.89543 6.38452 7 6.38452H13C14.1046 6.38452 15 7.27995 15 8.38452Z'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path d='M13.3334 6.38462V3' stroke='currentColor' strokeLinecap='round' />
      <path d='M6.66663 6.38462V3' stroke='currentColor' strokeLinecap='round' />
      <path d='M16.6667 16L15 19H19L17.3333 22' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
