import React, { SVGProps } from 'react'

export default function DropletHalf(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.5 16.5L18.5 10' stroke='currentColor' />
      <path
        d='M20 14C20 9.58172 12 2 12 2C12 2 4 9.58172 4 14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14Z'
        stroke='currentColor'
      />
    </svg>
  )
}
