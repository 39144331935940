import React, { SVGProps } from 'react'
import { NuiLogosType, Logos } from './Logos'

export type TLogoSize = 's' | 'm' | 'l' | 'xl'

export type TLogo = {
  logo: NuiLogosType
  color?: string
  size?: TLogoSize
  style?: SVGProps<SVGSVGElement>
}

const getLogoComponent = (
  LogoComponent: React.ElementType,
  size: number,
  color: string,
  style?: SVGProps<SVGSVGElement>,
): JSX.Element => {
  return <LogoComponent style={{ width: size, height: size, color: color, ...style }} />
}

export const Logo: React.FC<TLogo> = ({ logo, size, color, style }: TLogo): JSX.Element => {
  let iconSize: number
  switch (size) {
    case 's':
      iconSize = 16
      break
    case 'm':
      iconSize = 20
      break
    case 'l':
      iconSize = 24
      break
    case 'xl':
      iconSize = 32
      break
    default:
      iconSize = 16
      break
  }

  const LogoComponent = Logos[logo]
  return getLogoComponent(LogoComponent, iconSize, color ? color : 'currentColor', style)
}
