import React, { SVGProps } from 'react'

export default function PlugTypeL(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 3H5.6C5.26863 3 5 3.26863 5 3.6V20.4C5 20.7314 5.26863 21 5.6 21H10'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 7H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 12H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 17H13' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14 3H18.4C18.7314 3 19 3.26863 19 3.6V20.4C19 20.7314 18.7314 21 18.4 21H14'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
