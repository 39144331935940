import React, { SVGProps } from 'react'

export default function Gnome(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>GNOME</title>
      <path d='M17.865 0c-3.8 0-4.513 5.414-2.375 5.414S21.666 0 17.865 0zm-5.873.664c-.06 0-.124.003-.191.012-2.14.277-1.385 3.997-.246 4.066 1.103.068 2.308-4.071.437-4.078zm-3.705 1.47a1.039 1.039 0 00-.371.087c-1.72.71-.262 3.636.701 3.44.889-.182 1.004-3.556-.33-3.526zM5.547 4.43a.801.801 0 00-.483.164c-1.376.95.462 3.25 1.272 2.892.708-.312.257-3.02-.79-3.056zm7.637 1.758c-2.92.025-6.636 1.506-7.284 4.404-.718 3.218 2.96 7.68 6.797 7.68 1.888 0 4.066-1.705 4.473-3.865.311-1.647-3.662-.986-3.52.104.171 1.306-.958 1.95-2.064 1.107-3.52-2.68 5.83-4.017 5.213-7.679-.2-1.187-1.744-1.768-3.615-1.752zM5.268 20.191c-.536 0-.958.17-1.266.51-.308.34-.463.805-.463 1.397 0 .585.153 1.048.455 1.39.303.341.713.512 1.23.512.258 0 .873.004 1.288-.344v-1.765H5.264v.634h.644v.635c-.098.08-.282.154-.58.154-.293 0-.544-.106-.71-.318-.167-.211-.25-.51-.25-.898 0-.392.087-.693.259-.903.173-.211.419-.318.738-.318a1.602 1.602 0 01.817.225l.216-.637a2.245 2.245 0 00-.537-.205 2.507 2.507 0 00-.593-.07zm6.562 0c-.47.013-.842.182-1.115.506-.283.338-.426.806-.426 1.4 0 .594.143 1.06.426 1.397.284.338.675.506 1.174.506.5 0 .89-.168 1.174-.506.283-.338.423-.803.423-1.396 0-.595-.14-1.063-.424-1.4-.283-.338-.674-.507-1.173-.507zm-4.77.067v3.67h.682v-2.205l1.598 2.205h.506v-3.67h-.682v2.207l-1.598-2.207h-.505zm7.108 0l-.389 3.67h.76l.234-2.373.715 2.384h.508l.717-2.306.234 2.295h.758l-.39-3.67h-.862l-.713 2.29-.707-2.29zm4.084 0v3.67h2.209v-.715h-1.408v-.84H20.1v-.64h-1.047v-.76h1.361v-.715zm-6.387.619h.024a.64.64 0 01.57.32c.135.213.201.514.201.9s-.066.684-.201.897a.639.639 0 01-.57.32.637.637 0 01-.569-.32c-.134-.213-.203-.511-.203-.896 0-.387.069-.688.203-.9a.635.635 0 01.545-.321z' />
    </svg>
  )
}
