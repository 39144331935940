import React, { SVGProps } from 'react'

export default function AlignBottomBox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4 8.00001L4.01 8.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 4.00001L4.01 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 4.00001L8.01 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 4.00001L12.01 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 4.00001L16.01 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 4.00001L20.01 4.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 8.00001L20.01 8.01112' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 12V20H20V12H4Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
