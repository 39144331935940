import React, { SVGProps } from 'react'

export default function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 20L18 20' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
