import React from 'react'
import { TSwicth } from '../input.types'
import '../../../styles/input_switch.css'

export const Switch = ({ text, defaultChecked, onClick }: TSwicth) => {
  return (
    <div className='nuiSwitchWrapper'>
      <label className='nuiSwitch'>
        <input type='checkbox' defaultChecked={defaultChecked} onClick={onClick} />
        <span className='nuiSlider round'></span>
      </label>
      <span className='nuiSwitchText'>{text}</span>
    </div>
  )
}
