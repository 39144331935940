import React, { SVGProps } from 'react'

export default function VerticalSplit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 14V22M12 22L15.5 18.5M12 22L8.5 18.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 10V2M12 2L15.5 5.5M12 2L8.5 5.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 14L21 14' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 10L21 10' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
