import React, { SVGProps } from 'react'

export default function PizzaSlice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M14 9.01L14.01 8.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 8.01L8.01 7.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 14.01L8.01 13.9989' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6 19L2.23626 3.0041C2.13087 2.55618 2.54815 2.16122 2.98961 2.29106L19 7'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M22.198 8.42467C22.4324 7.48703 21.8623 6.5369 20.9247 6.30249C19.987 6.06808 19.0369 6.63816 18.8025 7.5758C18.4106 9.14318 16.9015 11.6241 14.5753 13.9503C12.2743 16.2513 9.42714 18.1442 6.60672 18.7951C5.66497 19.0124 5.07771 19.952 5.29504 20.8937C5.51236 21.8355 6.45198 22.4227 7.39373 22.2054C11.0734 21.3563 14.4762 18.9991 17.0502 16.4252C19.5989 13.8764 21.5898 10.8573 22.198 8.42467Z'
        stroke='currentColor'
        strokeLinecap='round'
      />
    </svg>
  )
}
