import React, { SVGProps } from 'react'

export default function RoundFlask(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      strokeWidth='1.5'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M19 15L5 15' stroke='currentColor' strokeLinejoin='round' />
      <path d='M16 4L8 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 4.5V8.75309C15 9.51628 15.4449 10.1982 16.0783 10.6241C18.2866 12.1088 20 14.6173 20 17.4616C20 18.2738 19.8864 19.0585 19.6747 19.7999C19.4602 20.5507 18.7299 21 17.949 21H6.05096C5.27012 21 4.53978 20.5507 4.32535 19.7999C4.11362 19.0585 4 18.2738 4 17.4616C4 14.6173 5.7134 12.1088 7.92172 10.6241C8.55506 10.1982 9 9.51628 9 8.75309V4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13 7.01L13.01 6.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 2.01L11.01 1.99889' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
