import React, { SVGProps } from 'react'

export default function Delicious(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>del.icio.us</title>
      <path d='M12 12H0v12h12V12zM24 0H12v12h12V0z' />
    </svg>
  )
}
