import React, { SVGProps } from 'react'

export default function Eljueves(props: SVGProps<SVGSVGElement>) {
  return (
    <svg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <title>El Jueves</title>
      <path d='M15.2718 14.6722a1.1453 1.9092 0 01-1.1452 1.9092 1.1453 1.9092 0 01-1.1453-1.9092 1.1453 1.9092 0 011.1453-1.9092 1.1453 1.9092 0 011.1452 1.9092zm-3.8177 0a1.1453 1.9092 0 01-1.1453 1.9092 1.1453 1.9092 0 01-1.1453-1.9092 1.1453 1.9092 0 011.1453-1.9092 1.1453 1.9092 0 011.1453 1.9092zM15.3655 2.073a1.7777 1.8407 0 00-1.775 1.7777c-2.5466.4872-4.0567 2.173-4.5407 5.0449a7.6188 6.8373 0 00-.515.2363C7.4214 8.107 6.3713 7.614 5.3837 7.6602c-.9043.0424-1.7564.537-2.5553 1.4772a1.7777 1.8407 0 00-1.0508-.3581A1.7777 1.8407 0 000 10.6199a1.7777 1.8407 0 001.7777 1.8406A1.7777 1.8407 0 003.5554 10.62a1.7777 1.8407 0 00-.2063-.8567c1.054-.4705 2.054-.1291 2.9993 1.0152a7.6188 6.8373 0 00-1.7135 4.311 7.6188 6.8373 0 007.6192 6.8377 7.6188 6.8373 0 007.618-6.8376 7.6188 6.8373 0 00-2.0675-4.676c.5393-1.5004 1.4322-2.1684 2.6768-2.0058a1.7777 1.8407 0 00-.0368.3716 1.7777 1.8407 0 001.7777 1.8406A1.7777 1.8407 0 0024 8.7793a1.7777 1.8407 0 00-1.7777-1.842 1.7777 1.8407 0 00-1.3446.6382c-.8736-.4223-1.6929-.6431-2.4514-.6395-1.2713.006-2.3817.6123-3.33 1.8146a7.6188 6.8373 0 00-2.547-.485c-.0668-1.6928.333-2.8897 1.1997-3.5897a1.7777 1.8407 0 001.6165 1.0781 1.7777 1.8407 0 001.7777-1.8405 1.7777 1.8407 0 00-1.7776-1.8406zm-3.1114 8.6044c3.615 0 6.5453 2.2229 6.5453 4.9642 0 2.7415-2.9302 4.963-6.5453 4.963-3.615 0-6.5465-2.2215-6.5465-4.963 0-2.7413 2.9315-4.9642 6.5465-4.9642z' />
    </svg>
  )
}
